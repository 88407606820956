import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Form, Flex, Input, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import {
    type CompanyCorporateShareHolderDetails,
    type CompanyShareholder,
    type CS_ShareHolder,
} from '../../../Types/CompanyHouse/CompanyOverView'
import {
    GetCompanyOverViewData,
    GetShareHolderCorporateDetails,
    GetShareHolderDetails,
    SetCompanyShareholder,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import { GetContactInfoPersonDropDown } from '../../../Services/Corporate'
interface addsshareHolder {
    contactInfoPersonDropDownList: ListFrontEndInterface[]
}

interface terminatePSCData extends addsshareHolder {
    onClosemodel: () => void
    editData: CS_ShareHolder
    CorporateId: number
    serviceProcessId: number
    businessId: number
}
const defaultCompanyData = {
    companyReferenceId: 0,
    divisionType: 0,
    companyId: 0,
    corporateId: 0,
    shareClass: 0,
    noofShares: 0,
    additionalDataOne: '',
    additionalDataTwo: '',
    additionalDataThree: '',
    additionalDataFour: '',
    additionalDataFive: '',
}
const AddShareHoldes = (props: terminatePSCData): React.ReactElement => {
    const {
        onClosemodel,
        editData,
        CorporateId,
        serviceProcessId,
        businessId,
        contactInfoPersonDropDownList,
    } = props
    const [form] = Form.useForm()
    const [MeberType, setMemberType] = useState(1)
    const dispatch = useDispatch()
    const [shareHolderData, setshareHolderData] =
        useState<CompanyShareholder>(defaultCompanyData)
    const [shareHolderCorporateData, setshareHolderCorporateData] =
        useState<CompanyCorporateShareHolderDetails | null>(null)
    const [memberId, setMemberId] = useState(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        setMemberType(editData.divisionType === 0 ? 1 : editData.divisionType)
        setMemberId(editData.companyReferenceId)
        form.setFieldsValue({
            shareClass: editData?.shareClass ?? '',
            noofShares: editData?.noofShares ?? '',
        })
    }, [editData])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetContactInfoPersonDropDown(dispatch, CorporateId, MeberType)
    }, [MeberType, CorporateId])

    useEffect(() => {
        const fetchAuditData = async (): Promise<void> => {
            try {
                if (MeberType === 1) {
                    if (memberId !== 0) {
                        const data = await GetShareHolderDetails(
                            dispatch,
                            MeberType,
                            memberId
                        )
                        setshareHolderData(data)
                    } else {
                        setshareHolderData(defaultCompanyData)
                    }
                } else if (MeberType === 2) {
                    if (memberId !== 0) {
                        const data = await GetShareHolderCorporateDetails(
                            dispatch,
                            MeberType,
                            memberId
                        )
                        setshareHolderCorporateData(data)
                    }
                }
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        void fetchAuditData()
    }, [memberId, MeberType])

    useEffect(() => {
        if (MeberType === 1) {
            form.setFieldsValue({
                additionalDataOne: shareHolderData?.additionalDataOne ?? '',
                additionalDataTwo: shareHolderData?.additionalDataTwo ?? '',
                additionalDataThree: shareHolderData?.additionalDataThree ?? '',
                additionalDataFour: shareHolderData?.additionalDataFour ?? '',
                shareClass: shareHolderData?.shareClass ?? '',
                noofShares: shareHolderData?.noofShares ?? '',
            })
        } else {
            form.setFieldsValue({
                additionalDataOne: shareHolderCorporateData?.companyName ?? '',
                additionalDataTwo:
                    shareHolderCorporateData?.companyNumber ?? '',
            })
        }
    }, [shareHolderData, shareHolderCorporateData])

    const handelDatasave = (): void => {
        void submitData()
    }
    const submitData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                companyReferenceId: editData.companyReferenceId ?? 0,
                divisionType: MeberType,
                companyId: 0,
                corporateId: CorporateId,
                shareClass: formData.shareClass,
                noofShares: formData.noofShares,
                additionalDataOne: formData.additionalDataOne ?? '',
                additionalDataTwo: formData.additionalDataTwo ?? '',
                additionalDataThree: formData.additionalDataThree ?? '',
                additionalDataFour: formData.additionalDataFour ?? '',
                additionalDataFive: formData.additionalDataFive ?? '',
            }
            void SetCompanyShareholder(dispatch, dataToSave)
            setTimeout(() => {
                GetCompanyOverViewData(
                    dispatch,
                    businessId,
                    CorporateId,
                    serviceProcessId,
                    5
                )
                onClosemodel()
                setMemberId(0)
                form.resetFields()
            }, 1000)
        } catch (error) {}
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const memberTypeChnage = (value: number): void => {
        setMemberType(value)
    }
    const handelMeberChange = (value: number): void => {
        setMemberId(value)
    }
    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Member Type" name="divisionType">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Please select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' && // Ensure option.children is a string
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        memberTypeChnage(value)
                                    }}
                                >
                                    <Select.Option key={0} value={0}>
                                        Please select
                                    </Select.Option>
                                    <Select.Option key={1} value={1}>
                                        Person
                                    </Select.Option>
                                    <Select.Option key={2} value={2}>
                                        Corporate
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {' '}
                            <Form.Item
                                label={`Member ${
                                    MeberType === 1 ? `Person` : `Corporate`
                                }`}
                                name="membertype"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        handelMeberChange(value)
                                    }}
                                    optionFilterProp="children"
                                    options={contactInfoPersonDropDownList}
                                    filterOption={(input, option) =>
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            {MeberType === 1 ? (
                                <Form.Item
                                    label="Title"
                                    name="additionalDataOne"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Please select title"
                                        onChange={() => {}}
                                        defaultValue={'UNKNOWN'}
                                        filterOption={(input, option) =>
                                            typeof option?.children ===
                                                'string' && // Ensure option.children is a string
                                            (option.children as string)
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        showSearch
                                        disabled={
                                            memberId !== 0 &&
                                            editData.companyReferenceId === 0
                                        }
                                    >
                                        <Select.Option value="UNKNOWN">
                                            Unknown
                                        </Select.Option>
                                        <Select.Option value="MR.">
                                            MR.
                                        </Select.Option>
                                        <Select.Option value="MISS.">
                                            MISS.
                                        </Select.Option>
                                        <Select.Option value="MRS.">
                                            MRS.
                                        </Select.Option>
                                        <Select.Option value="MS.">
                                            MS.
                                        </Select.Option>
                                        <Select.Option value="DR.">
                                            DR.
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    label="Company Name"
                                    name="additionalDataOne"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please Enter Company Name',
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={
                                            memberId !== 0 &&
                                            editData.companyReferenceId === 0
                                        }
                                    />
                                </Form.Item>
                            )}
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Share Class" name="shareClass">
                                <Select
                                    allowClear
                                    placeholder="Please select ShareClass"
                                    onChange={() => {}}
                                    defaultValue={0}
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' && // Ensure option.children is a string
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    showSearch
                                >
                                    <Select.Option value={0}>
                                        PLEASE SELECT
                                    </Select.Option>
                                    <Select.Option value={2}>
                                        Preference
                                    </Select.Option>
                                    <Select.Option value={1}>
                                        Ordinary
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            {MeberType === 1 ? (
                                <Form.Item
                                    label="First Name"
                                    name="additionalDataTwo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter First Name',
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={
                                            memberId !== 0 &&
                                            editData.companyReferenceId === 0
                                        }
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    label="Company Number"
                                    name="additionalDataTwo"
                                    rules={
                                        (MeberType === 2 && memberId === 0) ||
                                        editData.companyReferenceId !== 0
                                            ? [
                                                  {
                                                      required: true,
                                                      message:
                                                          'Please Enter Company Number',
                                                  },
                                              ]
                                            : []
                                    }
                                >
                                    <Input
                                        disabled={
                                            memberId !== 0 &&
                                            editData.companyReferenceId === 0
                                        }
                                    />
                                </Form.Item>
                            )}
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Number of Shares "
                                name="noofShares"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Number of Shares',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    {MeberType !== 2 && (
                        <>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {' '}
                                    <Form.Item
                                        label="Middle Name"
                                        name="additionalDataThree"
                                    >
                                        <Input
                                            disabled={
                                                memberId !== 0 &&
                                                editData.companyReferenceId ===
                                                    0
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Surename"
                                        name="additionalDataFour"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please Enter Surename',
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={
                                                memberId !== 0 &&
                                                editData.companyReferenceId ===
                                                    0
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    <br />

                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#175ca9'}
                                name={'Save Changes'}
                                icon={<SaveOutlined />}
                                clickAction={handelDatasave}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): addsshareHolder => {
    return {
        contactInfoPersonDropDownList:
            state.client.contactInfoPersonDropDownList,
    }
}
export default connect(mapStateToProps)(AddShareHoldes)
