import React, { useState } from 'react'
import styles from './login.less'
import {
    type changePinInterface,
    type changePasswdDataInterface,
} from '../../Types/Auth'
import { Col, Row, Form, Button, Input } from 'antd'
import commonStyles from '../../Utils/Common.less'
import { ChangePasswdApi } from '../../Services/Auth'
import { useDispatch } from 'react-redux'
import InputField from '../../Components/InputField'
import { useNavigate } from 'react-router-dom'
import profileCss from '../Profile/profile.less'
const ChangePassword = (props: changePinInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { code } = props
    const [criteria, setCriteria] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
    })
    const [passwordInput, setPasswordInput] = useState('')
    const onSubmit = (data: changePasswdDataInterface): void => {
        const newData = {
            resetCode: code,
            emailAddress: data.emailAddress,
            password: data.password,
            confirmPassword: data.confirmPassword,
        }
        ChangePasswdApi(dispatch, newData, () => {
            navigate(`/`)
            window.location.reload()
        })
    }

    const handlePasswordChange = (e: { target: { value: any } }): void => {
        const value = e.target.value
        setPasswordInput(value)
        setCriteria({
            length: value.length >= 8,
            lowercase: /[a-z]/.test(value),
            uppercase: /[A-Z]/.test(value),
            number: /\d/.test(value),
            specialChar: /[!@#$%^&*":|<>]/.test(value),
        })
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>
                                Create Account
                            </div>
                            <div className={styles.loginTittleDesc}>
                                Reset your password to access your account
                            </div>
                        </div>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                        >
                            <InputField
                                name="emailAddress"
                                placeHolder="ex. email@domain.com"
                                label="EmailAddress"
                                reuireMessage="Please input your Email!"
                            />
                            <Form.Item
                                label="New Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input your New Password!',
                                    },
                                    {
                                        pattern:
                                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*.?"|<>]).{8,}$/,
                                        message:
                                            'Must contain at least one number and one uppercase and lowercase letter and one special characters , and at least 8 or more characters',
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Enter your password"
                                    onChange={handlePasswordChange}
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        async validator(_, value: string) {
                                            const password = getFieldValue(
                                                'password'
                                            ) as string

                                            if (password !== value) {
                                                return await Promise.reject(
                                                    new Error(
                                                        'Passwords not match!'
                                                    )
                                                )
                                            }
                                            await Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm your password" />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                className={styles.loginFormButton}
                            >
                                Sign Up
                            </Button>
                        </Form>
                        {passwordInput?.length > 0 && (
                            <Row>
                                <Col span={12}>
                                    <div
                                        className={profileCss.validatemssageCom}
                                    >
                                        <h3 style={{ fontSize: '13px' }}>
                                            Password must contain the following:
                                        </h3>
                                        <p
                                            className={
                                                criteria.lowercase
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>lowercase</b> letter
                                        </p>
                                        <p
                                            className={
                                                criteria.uppercase
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>capital (uppercase)</b> letter
                                        </p>
                                        <p
                                            className={
                                                criteria.number
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>number</b>
                                        </p>
                                        <p
                                            className={
                                                criteria.length
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            Minimum <b>8 characters</b>
                                        </p>
                                        <p
                                            className={
                                                criteria.specialChar
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>special character</b>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                    {/* <div className={styles.doNotHaveAccountText}>
                            Don’t have an account?
                            <a href="">SignIn</a>
                        </div> */}

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ChangePassword
