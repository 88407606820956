import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import type { TableListInterface } from '../../Types/CommonType'
import { setLoadingStatus } from '../../Reducer/Common'
import {
    PerformInvoice,
    GetAddressInfo,
    GetBankInfo,
    ArchivedInvoice,
} from '../../Reducer/Invoice'

export const GetPerformInvoice = (
    dispatch: Dispatch,
    searchString: string,
    statuFlag: number,
    clientCategoryId: number,
    serviceId: number,
    pageNo: number,
    pageSize: number
): void => {
    if (searchString !== '=NA=') {
        pageNo = 1
    }
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/AccountInvoice/GetCompleteList/${searchString}/${pageNo}/${pageSize}/${statuFlag}/${serviceId}/${clientCategoryId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: any) => {
                    return {
                        ...value,
                        key: value.invoiceId,
                    }
                })
                response.data.data = newData
                if (statuFlag === 1) {
                    dispatch(PerformInvoice({ ...response.data }))
                } else {
                    dispatch(ArchivedInvoice({ ...response.data }))
                }
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getAddressInformation = (
    dispatch: Dispatch,
    companyType: number,
    referenceId: number,
    addressType: number
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(
            `/api/GetCompanyAddressInformation/${companyType}/${referenceId}/${addressType}`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(GetAddressInfo(response?.data))
            } else {
                console.error('Error:', response)
            }
        })
        .catch((error) => {
            console.error('Error fetching address information:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getBankInfo = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingTwo/2/=NA=`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: any) => ({
                    ...value,
                    key: value.id,
                }))
                const getBankInfo = { ...response.data, data: newData }
                dispatch(GetBankInfo(getBankInfo))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetInvoiceStatus = async (
    dispatch: Dispatch,
    data: any
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/AccountInvoice/SetInvoiceComplete`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetInvoiceDetails = async (
    dispatch: Dispatch,
    data: any
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/AccountInvoice/SetInvoice`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetArchived = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize } = data
    axios
        .get(
            `/api/AccountInvoice/GetCompleteList/=NA=/${pageNo}/${pageSize}/2/0/1`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: any) => {
                    return {
                        ...value,
                        key: value.invoiceId,
                    }
                })
                response.data.data = newData
                dispatch(ArchivedInvoice({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetSetMark = async (
    dispatch: Dispatch,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetMarkasArchived/1/${referenceId}/0`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                // SuccessMessage('Archive Successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
