import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetGroupAndPartnerList,
    saveGroupAndPartner,
    DeleteGroupAndPartner,
    ActiveInactiveGroupAndPartner,
} from '../../../Services/GroupAndPartners'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import GroupAndPartnerForm from './groupAndPartnerForm'
import type {
    GroupAndPartnerFormDataInterface,
    GroupAndPartnerListInterface,
    GroupAndPartnerListDataTypeInterface,
} from '../../../Types/GroupAndPartners'
import GroupAndPartnerExpandView from './groupAndPartnerExpandView'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const GroupAndPartnerItemDefaultData = {
    clientCategoryName: '',
    clientCategoryId: 0,
    addressOne: '',
    addressTwo: '',
    town: '',
    country: '0',
    postCode: '',
    mobileNumber: '',
    workNumber: '',
    homeNumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    businessLogoPath: '',
    signaturePath: '',
    parentCategoryId: 0,
    rubberStampLogo: '',
    website: '',
    documentFooterText: '',
}

const GroupAndPartnerList = (
    props: GroupAndPartnerListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { groupAndPartnerList, groupAndPartnerListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [groupAndPartnerId, setGroupAndPartnerId] = useState('')
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('Active')
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Group & Partners'
    )
    const [editData, setEditData] = useState<GroupAndPartnerFormDataInterface>(
        GroupAndPartnerItemDefaultData
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetGroupAndPartnerList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: groupAndPartnerListCount,
            },
        })
    }, [groupAndPartnerListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        groupAndPartnerListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<GroupAndPartnerListDataTypeInterface> = [
        {
            title: 'Company Name',
            dataIndex: 'clientCategoryName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Address',
            dataIndex: 'addressOne',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileNumber',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: GroupAndPartnerListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1000', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Group & Partner">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(
                                            (record?.clientCategoryId).toString()
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        (record?.clientCategoryId).toString(),
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1000', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            (record?.clientCategoryId).toString()
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<GroupAndPartnerListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'clientCategoryName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let groupAndPartnerId = ''
        if (!deleteModalStatus) {
            groupAndPartnerId = id
        }
        setGroupAndPartnerId(groupAndPartnerId)
    }

    const deleteGroupAndPartners = (): void => {
        DeleteGroupAndPartner(dispatch, groupAndPartnerId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let groupAndPartnerId = ''
        if (!activeInactiveModalStatus) {
            groupAndPartnerId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setGroupAndPartnerId(groupAndPartnerId)
    }

    const updateActiveInactiveGroupAndPartners = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveGroupAndPartner(
            dispatch,
            groupAndPartnerId,
            status,
            getTableData
        )
        activeInactiveModalFunction('', '')
    }

    const openEditModal = (id: string): void => {
        const data = groupAndPartnerList.find(
            (data: GroupAndPartnerFormDataInterface) => {
                return data.clientCategoryId.toString() === id
            }
        )

        let tittle = 'Create Group & Partners'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit Group & Partners'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? GroupAndPartnerItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveGroupAndPartners = (
        data: GroupAndPartnerFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveGroupAndPartnerData(data, callBack)
    }

    const saveGroupAndPartnerData = async (
        data: GroupAndPartnerFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        setIsLoadingModal(true)
        await saveGroupAndPartner(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                    setIsLoadingModal(false)
                }
            })
            .then(() => {})
    }

    const expandableData = (
        record: GroupAndPartnerListDataTypeInterface
    ): React.ReactElement => {
        return <GroupAndPartnerExpandView groupAndPartnerData={record} />
    }

    return (
        <>
            <Card
                title="Group & Partners"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Group & Partners by keywords'}
                    onChange={onChangeText}
                    onSearch={onChangeFilter}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={groupAndPartnerList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Group & Partners. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteGroupAndPartners}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Group & Partners. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveGroupAndPartners}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <GroupAndPartnerForm
                        onSave={onSaveGroupAndPartners}
                        editData={editData}
                        isLoadingModal={isLoadingModal}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): GroupAndPartnerListInterface => {
    return {
        groupAndPartnerList: state.initial.groupAndPartnerList,
        groupAndPartnerListCount: state.initial.groupAndPartnerListCount,
        parentCategoryList: state.initial.parentCategoryList,
    }
}

export default connect(mapStateToProps)(GroupAndPartnerList)
