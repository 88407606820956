import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetOtherPersonList,
    SaveOtherPerson,
} from '../../../Services/OtherPerson'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import OtherPersonForm from './otherPersonForm'
import type {
    OtherPersonListInterface,
    OtherPersonListDataTypeInterface,
    OtherPersonFormDataInterface,
} from '../../../Types/OtherPerson'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import calculatePagination from '../../../Utils/Pagination'
// Default data
export const OtherPersonItemDefaultData = {
    clientCategoryName: '',
    clientCategoryId: null,
    otherPersonId: '0',
    activeStatus: '',
    emailAddress: '',
    contactName: '',
    contactNumber: '',
}

const OtherPersonList = (
    props: OtherPersonListInterface
): React.ReactElement => {
    const dispatch = useDispatch()

    // Props restructuring
    const { otherPersonList, otherPersonListCount } = props

    // States initialization
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Create OtherPerson')
    const [editData, setEditData] = useState<OtherPersonFormDataInterface>(
        OtherPersonItemDefaultData
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [])

    // Fetches table data based on current parameters
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetOtherPersonList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    // Updates the table parameters with the total count of items.
    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: otherPersonListCount,
            },
        })
    }, [otherPersonListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        otherPersonListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<OtherPersonListDataTypeInterface> = [
        {
            title: 'Company Group',
            dataIndex: 'clientCategoryName',
        },
        {
            title: 'Name',
            dataIndex: 'contactName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: OtherPersonListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit OtherPerson">
                            <EditIcon
                                onClick={() => {
                                    openEditModal(record?.otherPersonId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<OtherPersonListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    // Search and Modal Handling
    // onChangeText Function: Calls onChangeFilter if the input is cleared.
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value?.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    // onChangeFilter Function: Updates the table parameters with the new filter.
    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'contactName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    // openEditModal Function: Opens the modal and sets the data for editing
    const openEditModal = (id: string): void => {
        const data = otherPersonList?.find(
            (data: OtherPersonFormDataInterface) => {
                return data.otherPersonId === id
            }
        )

        let tittle = 'Create OtherPerson'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit OtherPerson'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? OtherPersonItemDefaultData)
        changeCreateEditModalStatus()
    }

    // changeCreateEditModalStatus Function: Toggles the modal visibility
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveOtherPerson = (
        data: OtherPersonFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveOtherPersonData(data, callBack)
    }

    const saveOtherPersonData = async (
        data: OtherPersonFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveOtherPerson(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Other Business Associates"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search OtherPerson by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={otherPersonList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <OtherPersonForm
                        onSave={onSaveOtherPerson}
                        editData={editData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): OtherPersonListInterface => {
    return {
        otherPersonList: state.initial.otherPersonList,
        otherPersonListCount: state.initial.otherPersonListCount,
    }
}

export default connect(mapStateToProps)(OtherPersonList)
