import React, { useEffect, useRef, useState } from 'react'
import {
    Chart,
    type ChartData,
    type ChartOptions,
    registerables,
} from 'chart.js'
import styles from './BPO_and_others_index.less'
import { Card, Col, Empty, Row, Select } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'

import { GetUserList } from '../../../Services/User'
import { type BPOStaffProgressStateInteface } from '../../../Types/ITDashbord'
import { GetBPOStaffProgressDetails } from '../../../Services/ITDashboard'

Chart.register(...registerables)

const TaskProgressChart = (
    props: BPOStaffProgressStateInteface
): React.ReactElement => {
    const { userList, BPOANDotherStaffProgressList } = props
    const chartRef = useRef<HTMLCanvasElement>(null)
    const chartInstance = useRef<Chart | null>(null) // Store the chart instance
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    const departmentId = useSelector(
        (state: any) => state.dashboardFlag.departmentId
    )
    const [departmentIds, setDepartmetId] = useState(0)
    const clientDynCategoryId = Number(localStorage.getItem('departmetId'))
    useEffect(() => {
        // console.log(departmentId)
        setDepartmetId(clientDynCategoryId)
    }, [clientDynCategoryId, departmentId])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [dispatch])

    useEffect(() => {
        GetBPOStaffProgressDetails(dispatch, departmentIds)
    }, [departmentIds])
    const updateChart = (filteredData: ChartData<'bar'>): void => {
        const options: ChartOptions<'bar'> = {
            indexAxis: 'y',
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        stepSize: 20,
                    },
                },
                y: {
                    stacked: true,
                },
            },
            layout: {
                padding: {
                    top: 20,
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        padding: 10, // Add space between legend items
                    },
                },
            },
        }
        if (chartInstance.current != null) {
            chartInstance.current.destroy()
        }
        if (chartRef.current != null) {
            chartInstance.current = new Chart(chartRef.current, {
                type: 'bar',
                data: filteredData,
                options,
            })
        }
    }

    const handleUserChange = (value: number | null): void => {
        const userName =
            userList.find((user) => user?.value === value)?.label ?? ''

        // Filter tasks by selected user
        const filteredProjects = BPOANDotherStaffProgressList.filter(
            (project) => project.firstName === userName
        )

        // Prepare data for the chart
        const data: ChartData<'bar'> = {
            labels: filteredProjects?.map((project) => project?.projectName),
            datasets: [
                {
                    label: 'To Do',
                    data: filteredProjects.map(
                        (project) => project.openTaskCount
                    ),
                    backgroundColor: '#2340e4',
                },
                {
                    label: 'In Progress',
                    data: filteredProjects.map(
                        (project) => project.inProgressTaskCount
                    ),
                    backgroundColor: '#7E57C2',
                },
                {
                    label: 'Done',
                    data: filteredProjects.map(
                        (project) => project.doneTaskCount
                    ),
                    backgroundColor: '#66BB6A',
                },
            ],
        }

        // Update the chart
        updateChart(data)
    }

    useEffect(() => {
        // Initialize chart with all data (optional)
        const data: ChartData<'bar'> = {
            labels: BPOANDotherStaffProgressList?.map(
                (project) => project?.projectName
            ),
            datasets: [
                {
                    label: 'To Do',
                    data: BPOANDotherStaffProgressList.map(
                        (project) => project.openTaskCount
                    ),
                    backgroundColor: '#2340e4',
                },
                {
                    label: 'In Progress',
                    data: BPOANDotherStaffProgressList.map(
                        (project) => project.inProgressTaskCount
                    ),
                    backgroundColor: '#7E57C2',
                },
                {
                    label: 'Done',
                    data: BPOANDotherStaffProgressList.map(
                        (project) => project.doneTaskCount
                    ),
                    backgroundColor: '#66BB6A',
                },
            ],
        }
        updateChart(data)
    }, [BPOANDotherStaffProgressList])

    return (
        <div className={styles.marginRight10}>
            <Card className={`${styles.card} ${styles.client__card__height}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={18}>
                            <span className={styles.clients__first__left}>
                                Staff Progress
                            </span>
                        </Col>
                    </Row>
                </div>
                {BPOANDotherStaffProgressList?.length !== 0 && (
                    <Row style={{ marginTop: 20 }}>
                        <Col span={4}></Col>
                        <Col span={18}>
                            <Select
                                style={{
                                    width: '188px',
                                }}
                                allowClear
                                placeholder="Select & search staff"
                                onChange={handleUserChange}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    {BPOANDotherStaffProgressList?.length === 0 ? (
                        <Col
                            span={24}
                            style={{ textAlign: 'center', padding: '20px' }}
                        >
                            <Empty description="No data available" />
                        </Col>
                    ) : (
                        <canvas ref={chartRef}></canvas>
                    )}
                </Row>
            </Card>
        </div>
    )
}

const mapStateToProps = (state: any): BPOStaffProgressStateInteface => {
    return {
        userList: state.user.userList,
        BPOANDotherStaffProgressList:
            state.itdashboardInfo.BPOANDotherStaffProgressList,
    }
}

export default connect(mapStateToProps)(TaskProgressChart)
