import React, { useEffect, useRef, useState } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import './dairylayout.css'
import { Card, Modal } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type EventlistFormDataInterface,
    type EventStateType,
} from '../../../Types/HelpDesk/ManageDiary'
import { GetEventList } from '../../../Services/Helpdesk/ManageDiary'
import ManageDiaryForm from './ManagrDiaryForm'
import ManageDiaryList from './ManageDiaryList'
import CustomToolbar from './dairyHederRender'
// Configure localizer
const localizer = momentLocalizer(moment)

interface Event {
    title: string
    start: Date
    end: Date
    description: string
    eventId: number
}

const ManageDiaryView = (props: EventStateType): React.ReactElement => {
    const { EventList } = props
    const hasFetchedOnce = useRef(false)
    const dispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null)
    const [selectedEvent, setSelectedEvent] =
        useState<EventlistFormDataInterface | null>(null)
    const [restData, setResetData] = useState('false')
    const [modalTitle, setModalTitle] = useState('')
    const [modalEVentVisible, setModaleventVisible] = useState(false)
    const [selectedEvents, setSelectedEvents] = useState<
        EventlistFormDataInterface[]
    >([])

    const EventUpdateList = EventList.map((event) => ({
        ...event,
        startFrom: moment(event.startFrom, 'M/D/YYYY h:mm:ss A').format(
            'DD/MM/YYYY hh:mm:ss A'
        ),
        endTo: moment(event.endTo, 'M/D/YYYY h:mm:ss A').format(
            'DD/MM/YYYY hh:mm:ss A'
        ),
    }))
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }

        GetEventList(dispatch)
    }, [])

    const handleSelectSlot = ({ start }: { start: Date }): void => {
        console.log('start', start)
        setSelectedEvent(null)
        setSelectedDate(moment(start))
        setIsModalVisible(true)
        setResetData('true')
        const eventsOnSelectedDate = EventUpdateList.filter(
            (event) =>
                moment(event.startFrom, 'DD/MM/YYYY h:mm:ss A').toDate() ===
                start
        )
        setSelectedEvents(eventsOnSelectedDate)
    }
    const calendarEvents: Event[] = EventUpdateList.map((event) => ({
        eventId: event.eventId,
        title: event.eventTitle,
        start: moment(event.startFrom, 'DD/MM/YYYY h:mm:ss A').toDate(),
        end: moment(event.endTo, 'DD/MM/YYYY h:mm:ss A').toDate(),
        description: event.description,
    }))

    console.log('calendarEvents', calendarEvents)
    const closeModal = (): void => {
        setIsModalVisible(false)
    }
    const handleEventClick = (eventRocord: Event): void => {
        const eventData = EventUpdateList?.find(
            (event) => event.eventId === eventRocord.eventId
        )

        if (eventData !== undefined) {
            setSelectedEvent(eventData)
            setModalTitle(eventData.eventTitle)
            setModaleventVisible(true)
            setSelectedDate(moment(eventRocord.start))
        }
    }

    const oncloseEventModal = (): void => {
        setModaleventVisible(false)
    }
    const handleDeleteEvent = (eventId: number): void => {
        const updatedEvents = selectedEvents.filter(
            (event) => event.eventId !== eventId
        )
        setSelectedEvents(updatedEvents)
        if (updatedEvents.length === 0) {
            setModaleventVisible(false)
        }
    }

    const handleShowMore = (events: any, date: any): void => {
        // alert(`Show More clicked for ${events.length} events on ${date}`);
        // // Handle the Show More functionality here
        // For example, you can show a modal with all events on that day
    }
    return (
        <div style={{ marginTop: 30 }}>
            <Card title={'Manage Diary'}>
                <BigCalendar
                    localizer={localizer}
                    events={calendarEvents}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView="month"
                    style={{ height: 600 }}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    components={{
                        toolbar: CustomToolbar, // Apply custom toolbar
                    }}
                    showMoreComponent={({ events, date, onShowMore }) => (
                        <div>
                            <button
                                onClick={() => {
                                    onShowMore(events, date)
                                }}
                            >
                                Show More Events ({events.length})
                            </button>
                        </div>
                    )}
                    onShowMore={handleShowMore}
                    onSelectEvent={(event) => {
                        handleEventClick(event)
                    }}
                />
            </Card>
            <Modal
                title={'Create Event'}
                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false)
                }} // Close modal
                footer={null}
                width={'50%'}
            >
                {selectedDate !== null && (
                    <ManageDiaryForm
                        date={selectedDate}
                        oncloseEventModal={closeModal}
                        initialFormData={selectedEvent}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                )}
            </Modal>
            <Modal
                title={modalTitle}
                open={modalEVentVisible}
                onCancel={oncloseEventModal}
                footer={null}
            >
                {selectedDate !== null && (
                    <ManageDiaryList
                        selectedDate={selectedDate}
                        selectedEvent={selectedEvent}
                        onDeleteEvent={handleDeleteEvent}
                        setModaleventVisible={setModaleventVisible}
                    />
                )}
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): EventStateType => {
    return {
        EventList: state.helpdesk.EventList,
        EventListCount: state.helpdesk.EventListCount,
    }
}

export default connect(mapStateToProps)(ManageDiaryView)
