import { Col, Row, Input, Form, Card, Modal, Button } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import AddNewButton from '../../../../Components/AddNewButton'
import { SyncCompanyHouse } from '../../../../Services/Corporate'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState, type ReactElement } from 'react'
import type {
    CorporateData,
    CorporateFormDataInterface,
} from '../../../../Types/Client/RegisteredClients/Corporate'
import CorporateTabs from './corporateTabs'
import { SyncOutlined } from '@ant-design/icons'
import CreateTask from '../../../../Components/CreateTask'
export const defaultCorporateData = {
    corporateid: '',
    companyname: '',
    companyregisternumber: '',
    clientType: '',
    clientcategoryid: 0,
    clientcategory: '',
    clientnumber: '',
    activestatus: '',
    serviceIds: '',
    contactName: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const CorporateView = (props: CorporateData): ReactElement => {
    const [corporateData, setCorporateData] =
        useState<CorporateFormDataInterface>(defaultCorporateData)
    const dispatch = useDispatch()
    const [companyNumber, setCompanyNumber] = useState<string>('1')
    const [tabIndex, setTabIndex] = useState(1)
    const clientTypeId = 1
    const [isEditModalVisible, setEditModalVisible] = useState(false)
    const [restData, setResetData] = useState('false')
    useEffect(() => {
        const storedCorporateData = localStorage.getItem('corporateData')
        if (storedCorporateData !== null) {
            const parsedData: CorporateFormDataInterface =
                JSON.parse(storedCorporateData)
            setCorporateData(parsedData)
            setCompanyNumber(parsedData.companyregisternumber)
        }
    }, [])
    const openEditModal = (): void => {
        setEditModalVisible(true)
    }

    const closeEditModal = (): void => {
        setEditModalVisible(false)
    }

    const handleCloseModal = (): void => {
        setEditModalVisible(false)
    }

    const setTabIndexAndSync = async (index: number): Promise<void> => {
        setTabIndex(index)
        try {
            await SyncCompanyHouse(dispatch, {
                companyNumber,
                tabIndex: index,
                clientTypeId,
            })
        } catch (error) {
            console.error('Error syncing company house:', error)
        }
    }

    return (
        <>
            <div>
                <h2>Corporate Registration</h2>
            </div>

            <Card
                title={`${
                    corporateData?.companyname !== null &&
                    corporateData?.companyname !== undefined
                        ? corporateData?.companyname
                        : ''
                } - ${
                    corporateData?.clientnumber !== null &&
                    corporateData?.clientnumber !== undefined
                        ? corporateData?.clientnumber
                        : ''
                }`}
                extra={
                    <AddNewButton
                        name="Create Task"
                        clickAction={() => {
                            openEditModal()
                            setResetData('true')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Type" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Client No" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={corporateData?.clientType}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={corporateData?.clientnumber}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Registration Number" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Company Name" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item>
                                <Input
                                    value={corporateData?.companyregisternumber}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <Button
                                    icon={<SyncOutlined />}
                                    onClick={() => {
                                        void setTabIndexAndSync(tabIndex)
                                    }}
                                    type="primary"
                                >
                                    {' '}
                                    Synchronize
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={corporateData?.companyname}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <a
                        href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            marginLeft: '8px',
                            textDecoration: 'underline',
                        }}
                    >
                        Link To Company House
                    </a>
                </Form>
                <Row>
                    <CorporateTabs />
                </Row>
                <Modal
                    footer={null}
                    onCancel={closeEditModal}
                    width={'50%'}
                    title="Create Task"
                    open={isEditModalVisible}
                >
                    <CreateTask
                        onClose={handleCloseModal}
                        setResetData={setResetData}
                        resetdata={restData}
                    />
                </Modal>
            </Card>
        </>
    )
}

export default CorporateView
