import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Flex, Select, Table, type TableProps } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import {
    type InvoiceTableIntrface,
    type CDClinetInvoiceProps,
    type ClientDashboardInvoiceStateIntrface,
    type Invoice,
} from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import { connect, useDispatch } from 'react-redux'
import { GetCilentInvoiceList } from '../../../Services/GlobelSearch'
import calculatePagination from '../../../Utils/Pagination'
import { type ColumnsType } from 'antd/es/table'
import style from './taps.less'
const InvoicesList = (props: CDClinetInvoiceProps): React.ReactElement => {
    const { businessTypeId, referenceId, CDInvoiceList, CDInvoiceListCount } =
        props
    const [flagId, setFlegId] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    const data = [
        {
            value: 0,
            label: 'All',
        },
        {
            value: 1,
            label: 'Unpaid',
        },
        {
            value: 2,
            label: 'Paid',
        },
    ]

    const getTableData = useCallback(() => {
        GetCilentInvoiceList(
            dispatch,
            businessTypeId,
            referenceId,
            flagId,
            '=NA=',
            currentPage,
            itemsPerPage
        )
    }, [businessTypeId, flagId, referenceId, currentPage, itemsPerPage])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        CDInvoiceListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<InvoiceTableIntrface> = [
        {
            title: 'Invoice No',
            dataIndex: 'invoiceNo',
        },
        {
            title: 'Date',
            dataIndex: 'invoiceDate',
        },
        {
            title: 'Amount',
            key: 'assignTo',
            render: (_, record) => {
                return <div>£ {record.netTotal}</div>
            },
        },
        {
            title: 'Current Status',
            dataIndex: 'currentStatus',
        },
        {
            title: 'Communication',
            dataIndex: 'lastCommunicationStatus',
        },
    ]

    const onChangeTableParams: TableProps<InvoiceTableIntrface>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
    }

    const mappedData: InvoiceTableIntrface[] = CDInvoiceList?.map(
        (item: Invoice) => ({
            ...item,
            key: item.no,
        })
    )

    const onChangeFlag = (value: number): void => {
        setFlegId(value)
    }
    return (
        <>
            <div style={{ marginTop: '20px', width: '100%' }}>
                <Flex justify="flex-end">
                    <Select
                        className={commonStyles.topdropdown2}
                        placeholder="Please select"
                        options={data}
                        value={flagId}
                        onChange={onChangeFlag}
                    />
                </Flex>

                {CDInvoiceListCount > 0 ? (
                    <div className={commonStyles.table}>
                        <div className={style.table}>
                            <Table
                                columns={columns}
                                dataSource={mappedData}
                                onChange={onChangeTableParams}
                                pagination={pagination}
                            />
                        </div>
                    </div>
                ) : (
                    <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        No Record Found
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state: any): ClientDashboardInvoiceStateIntrface => {
    return {
        CDInvoiceList: state.golobelSearch.CDInvoiceList,
        CDInvoiceListCount: state.golobelSearch.CDInvoiceListCount,
    }
}

export default connect(mapStateToProps)(InvoicesList)
