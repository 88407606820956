import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import PayeEditForm from './PayeEditPage'
import {
    type PayeServiceDataSS,
    type PayeViewPagePropData,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

export const Payedefaultdata = {
    payeId: 0,
    companyType: 0,
    referenceId: 0,
    payeReference: '',
    accountOfficeReference: '',
    payrollFrequency: '',
    schemeStartDate: '01/01/1900',
    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    gateWayId: '',
    password: '',
    status: '',
    csiFiling: 0,
    payeProcess: 0,
    filingMethod: 0,
    payeProcessName: '',
    filingMethodName: '',
    hmrcPaymentFrequency: 0,
    hmrcPaymentFrequencyName: '',
}
const PAYEviewPage = (props: PayeViewPagePropData): React.ReactElement => {
    const { PayeServiceData, datareFetch, partnershipId } = props
    const [isEditMode, setIsEditMode] = useState(false)
    const [editData, setEditData] = useState<PayeServiceDataSS>(Payedefaultdata)

    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(PayeServiceData ?? Payedefaultdata)
    }

    const handleChildFormSave = (): void => {
        datareFetch()
        setIsEditMode(false)
    }
    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    return (
        <>
            <Row gutter={16} justify="end">
                <Col>
                    <Button type="primary" onClick={handleEditClick}>
                        {isEditMode ? (
                            <EyeOutlined />
                        ) : (
                            ActivePremission('3000', PermissionType.UPDATE) && (
                                <EditOutlined />
                            )
                        )}
                    </Button>
                </Col>
            </Row>
            {isEditMode ? (
                <div>
                    <PayeEditForm
                        PayeeditData={editData}
                        handleChildFormSave={handleChildFormSave}
                        partnershipId={partnershipId}
                    />
                </div>
            ) : (
                <Row gutter={[24, 8]}>
                    <Col span={6}>
                        <p>PAYE Reference</p>
                        <p>Account Office Reference</p>
                        <p>Payroll Frequency</p>
                        <p>Scheme Start Date</p>
                        <p>Address 1 </p>
                        <p>CIS Filing</p>
                        <p>PAYE Process Date</p>
                    </Col>
                    <Col span={6}>
                        <p>: {PayeServiceData?.payeReference}</p>
                        <p>: {PayeServiceData?.accountOfficeReference}</p>
                        <p>: {PayeServiceData?.payrollFrequency}</p>
                        <p>: {formatdate(PayeServiceData?.schemeStartDate)}</p>
                        <p>: {PayeServiceData?.addressLineOne}</p>
                        <p>
                            :{' '}
                            {PayeServiceData?.csiFiling !== 0
                                ? 'Not Applicable'
                                : 'Applicable'}
                        </p>
                        <p>: {PayeServiceData?.payeProcessName}</p>
                    </Col>
                    <Col span={5}>
                        <p>Address 2</p>
                        <p>Town</p>
                        <p>Country</p>
                        <p>Post Code</p>
                        <p>HMRC Gateway ID</p>
                        <p>Password</p>
                        <p>Filing Method</p>
                    </Col>
                    <Col span={7}>
                        <p>: {PayeServiceData?.addressLineTwo}</p>
                        <p>: {PayeServiceData?.town}</p>
                        <p>: {PayeServiceData?.country}</p>
                        <p>: {PayeServiceData?.postCode}</p>
                        <p>: {PayeServiceData?.gateWayId}</p>
                        <p>: {PayeServiceData?.password}</p>
                        <p>: {PayeServiceData?.filingMethod}</p>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default PAYEviewPage
