import React, { useEffect, useState } from 'react'
import CommonButton from '../../../Components/Commonbutton'
import { Card, Col, Flex, Row, Switch, Table } from 'antd'
import CompanyDetails from '../../CompanyhouseandCSFillingButtons/ButtonFiles/companyDetails'
import PSCPage from '../../CompanyhouseandCSFillingButtons/ButtonFiles/pscsPage'
import ShareCapital from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareCapital'
import SAILAddersss from '../../CompanyhouseandCSFillingButtons/ButtonFiles/sailAddress'
import Officers from '../../CompanyhouseandCSFillingButtons/ButtonFiles/ofiicer'
import Shareholders from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareHolders'
import { DeleteOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {
    GetCompanyOverViewData,
    RevetAll,
    SetCSSummary,
    SyncCompanyHouseCH,
} from '../../../Services/CompanyHouse/CompanyOverView'
import {
    type SubmissionInfo,
    type CompanyHouseOverviewSteteIntrface,
} from '../../../Types/CompanyHouse/CompanyOverView'
import style from '../tingtodo.less'
import FullWidthModal from '../../../Components/FullWidthModal'
const CSFillingList = (
    props: CompanyHouseOverviewSteteIntrface
): React.ReactElement => {
    const { companyHouseOverViewList } = props
    const location = useLocation()
    const { pageIdentyFyId, ClientId } = location.state
    const [activeButton, setActiveButton] = useState(1)
    const [CompanyDetailsBtn, setCompanyDetailsBtn] = useState(true)
    const [OfficerBtn, setOfficerBtn] = useState(false)
    const [SAILAddressBtn, setSAILAddressBtn] = useState(false)
    const [ShareCapitalBtn, setShareCapitalBtn] = useState(false)
    const [ShareholdersBtn, setShareholdersBtn] = useState(false)
    const [PscBtn, setPscBtn] = useState(false)
    const [RevetModalStatus, setRevetModelStatus] = useState(false)
    const dispatch = useDispatch()
    const splitValues = ClientId?.split('-')
    const businessId = splitValues[0]
    const referenceId = splitValues[1]
    const navigate = useNavigate()
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [selectedValue, setSelectedValue] = useState<number | null>(null)
    const [showError, setShowError] = useState<boolean>(false)
    const [cardOpenmodal, setCardOpenModal] = useState(false)
    useEffect(() => {
        const serviceProcessId = 0
        GetCompanyOverViewData(
            dispatch,
            businessId,
            referenceId,
            serviceProcessId,
            activeButton
        )
    }, [ClientId, activeButton])
    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)
        switch (buttonName) {
            case 1:
                setCompanyDetailsBtn(true)
                break
            case 2:
                setOfficerBtn(true)
                break
            case 3:
                setSAILAddressBtn(true)
                break
            case 4:
                setShareCapitalBtn(true)
                break
            case 5:
                setShareholdersBtn(true)
                break
            case 6:
                setPscBtn(true)
                break
            default:
                break
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return <></>
        } else if (activeButton === 2) {
            return <></>
        }
        if (activeButton === 3) {
            return <></>
        }
        if (activeButton === 4) {
            return <></>
        }
        if (activeButton === 5) {
            return <></>
        }
        if (activeButton === 6) {
            return <></>
        }
        return <></>
    }

    const handleSwitchChange = (
        submissionTypeId: number,
        checked: boolean
    ): void => {
        setSelectedIds((prevSelectedIds) => {
            if (checked) {
                // Add the id if switch is checked
                return [...prevSelectedIds, submissionTypeId]
            } else {
                // Remove the id if switch is unchecked
                return prevSelectedIds.filter((id) => id !== submissionTypeId)
            }
        })
    }
    const columns = [
        {
            title: 'Submission Type',
            key: 'submissionType',
            render: (record: SubmissionInfo) => {
                return (
                    <Flex gap={10}>
                        <Switch
                            onChange={(checked) => {
                                handleSwitchChange(
                                    record.submissionTypeId,
                                    checked
                                )
                            }}
                        />
                        <div> {record.submissionType}</div>
                    </Flex>
                )
            },
        },
        {
            title: 'Last Update Date',
            key: 'lastUpdateDate',
            render: (record: SubmissionInfo) => {
                return <div> {record.lastUpdateDate}</div>
            },
        },
        {
            title: 'Last Update By',
            dataIndex: 'lastUpdateBy',
        },
    ]

    const dataSource =
        companyHouseOverViewList?.cS_Submisssion?.recentModificationsList?.map(
            (item) => ({
                ...item,
                key: item.submissionTypeId.toString(), // or any unique identifier for the key
            })
        )
    const NavigateEditCSPage = (): void => {
        navigate(`/confirmation-Statement/details`, {
            state: { ClientId },
        })
    }
    useEffect(() => {
        if (companyHouseOverViewList?.isFileWithChanges === 1) {
            NavigateEditCSPage()
        }
    }, [companyHouseOverViewList?.isFileWithChanges])
    const OpenRevetActionModal = (): void => {
        setRevetModelStatus(!RevetModalStatus)
    }
    const ChangeModalFunction = (): void => {
        setRevetModelStatus(!RevetModalStatus)
    }
    const RevertAction = (): void => {
        void RevetAll(dispatch, referenceId)
        setCardOpenModal(true)
        setRevetModelStatus(!RevetModalStatus)
    }
    const SubmitCsFilling = (): void => {
        try {
            const submissionTypeIdsString = selectedIds.join(',')
            const dataTosubmit = {
                corporateId: referenceId,
                isFileWithChanges: 1,
                submissionTypeIds: submissionTypeIdsString,
                reviewDate: companyHouseOverViewList?.reviewDate,
            }
            void SetCSSummary(dispatch, dataTosubmit)
        } catch (error) {}
    }

    const handleSwitchChangeC2 = (value: number): void => {
        if (selectedValue === value) {
            setSelectedValue(null) // Deselect the switch if it is already selected
        } else {
            setSelectedValue(value) // Set the selected value
        }
        setShowError(false) // Reset error when a switch is selected
    }

    const ContineButtonFunction = (): void => {
        if (selectedValue === null) {
            setShowError(true)
        } else {
            // Proceed with your logic (e.g., submitting a form)
            const dataTosend = {
                companyNumber: companyHouseOverViewList?.companyRegNumber,
                tabIndex: 1,
                clientTypeId: companyHouseOverViewList?.clientTypeId,
            }
            void SyncCompanyHouseCH(dispatch, dataTosend)
            setCardOpenModal(false)
            if (selectedValue !== 1) {
                const pageIdentyFyId = 1
                navigate(`/confirmation-Statement/summary`, {
                    state: { pageIdentyFyId, ClientId },
                })
            } else {
                navigate(`/confirmation-Statement/details`, {
                    state: { ClientId },
                })
            }
        }
    }
    return (
        <>
            <div>
                <h1>Summary of Confirmation Statement Filing</h1>
            </div>
            {!cardOpenmodal ? (
                <Card
                    title={`
                    ${companyHouseOverViewList?.clientName} -
                    ${companyHouseOverViewList?.companyRegNumber}`}
                    extra={
                        companyHouseOverViewList?.isFileWithChanges === 1 && (
                            <div onClick={NavigateEditCSPage}>
                                <a> Edit CS Filling</a>
                            </div>
                        )
                    }
                >
                    <div style={{ marginBottom: '15px' }}>
                        {pageIdentyFyId === 1 && (
                            <Row gutter={[24, 8]}>
                                <Col span={24}>
                                    <b>
                                        If you file a confirmation today, it
                                        will show the date as
                                    </b>{' '}
                                    : {companyHouseOverViewList?.reviewDate}
                                </Col>
                            </Row>
                        )}
                        {pageIdentyFyId === 2 && (
                            <>
                                <Row gutter={[24, 8]}>
                                    <Col span={10}>Next Statement Date </Col>
                                    <Col span={8}>:</Col>
                                </Row>
                                <Row gutter={[24, 8]}>
                                    <Col span={10}>
                                        If you file a confirmation today, it
                                        will show the date as
                                    </Col>
                                    <Col span={8}>
                                        : {companyHouseOverViewList?.reviewDate}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <CommonButton
                        name="Company Details"
                        isActive={activeButton === 1}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(1)
                        }}
                    />
                    <CommonButton
                        name="Officer"
                        isActive={activeButton === 2}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(2)
                        }}
                    />
                    <CommonButton
                        name="SAIL Address"
                        isActive={activeButton === 3}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(3)
                        }}
                    />
                    <CommonButton
                        name="Share Capital"
                        isActive={activeButton === 4}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(4)
                        }}
                    />
                    <CommonButton
                        name="Shareholders"
                        isActive={activeButton === 5}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(5)
                        }}
                    />
                    <CommonButton
                        name="PSC's"
                        isActive={activeButton === 6}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(6)
                        }}
                    />
                    {CompanyDetailsBtn && activeButton === 1 && (
                        <div>
                            <CompanyDetails
                                companyHousId={2}
                                businessId={businessId}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={0}
                            />
                        </div>
                    )}
                    {OfficerBtn && activeButton === 2 && (
                        <div>
                            <Officers
                                companyHousId={2}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={0}
                            />
                        </div>
                    )}
                    {SAILAddressBtn && activeButton === 3 && (
                        <div>
                            <SAILAddersss
                                businessId={businessId}
                                companyHousId={2}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={0}
                            />
                        </div>
                    )}
                    {ShareCapitalBtn && activeButton === 4 && (
                        <div>
                            <ShareCapital
                                companyHousId={2}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={0}
                                businessId={businessId}
                            />
                        </div>
                    )}
                    {ShareholdersBtn && activeButton === 5 && (
                        <div>
                            <Shareholders
                                companyHousId={2}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={0}
                                businessId={businessId}
                            />
                        </div>
                    )}
                    {PscBtn && activeButton === 6 && (
                        <div>
                            <PSCPage
                                companyHousId={2}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={0}
                                businessId={businessId}
                            />
                        </div>
                    )}
                    <h5>This is your first submission from IYKONECT</h5>
                    {dataSource?.length > 0 && (
                        <>
                            <h3>Pending Submission</h3>
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                            />
                            <br />
                            <div className={style.misstingInfo}>
                                You have modified above details after the last
                                submission. If you wish to submit this changes
                                this time to company house, please choose those
                                to submit or you will loose this changes next
                                time.
                            </div>
                        </>
                    )}
                    <br />
                    <div style={{ marginTop: '15px' }}>
                        <Flex justify="center" align="flex-end" gap={5}>
                            {companyHouseOverViewList?.isFileWithChanges ===
                                1 && (
                                <CusttomBackgroundButton
                                    color={'#175ca9'}
                                    name={'Edit CS Filling'}
                                    icon={<EditOutlined />}
                                    clickAction={NavigateEditCSPage}
                                />
                            )}
                            <CusttomBackgroundButton
                                color={'#ff4d4f'}
                                name={'Revet All'}
                                icon={<DeleteOutlined />}
                                clickAction={OpenRevetActionModal}
                            />

                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit all & CS Filling'}
                                icon={<CheckOutlined />}
                                clickAction={SubmitCsFilling}
                            />
                        </Flex>
                    </div>
                    <FullWidthModal
                        modalStatus={RevetModalStatus}
                        closeModal={ChangeModalFunction}
                        tittle="You are about to revert all changes. Click 'Yes' to continue."
                        smallTittle="Warning: This action cannot be undone!"
                        yesButtonFunction={RevertAction}
                    />
                </Card>
            ) : (
                <Card
                    title={`
                ${companyHouseOverViewList?.clientName} -
                ${companyHouseOverViewList?.companyRegNumber}`}
                >
                    <h3>Would you like to:</h3>
                    <Row>
                        <Col span={2}>
                            <Switch
                                checked={selectedValue === 1}
                                onChange={() => {
                                    handleSwitchChangeC2(1)
                                }}
                            />
                        </Col>
                        <Col span={20}>
                            Check and confirm your company details are up to
                            date before submitting the confirmation statement
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={2}>
                            <Switch
                                checked={selectedValue === 2}
                                onChange={() => {
                                    handleSwitchChangeC2(2)
                                }}
                            />
                        </Col>
                        <Col span={20}>
                            File Confirmation statement without any changes
                        </Col>
                    </Row>
                    {showError && (
                        <p style={{ color: 'red' }}>
                            Please complete the below requirements to continue
                        </p>
                    )}

                    <Flex justify="flex-end" align="flex-end" gap={5}>
                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'Contine'}
                            icon={<CheckOutlined />}
                            clickAction={ContineButtonFunction}
                        />
                    </Flex>
                </Card>
            )}
        </>
    )
}

const mapStateToProps = (state: any): CompanyHouseOverviewSteteIntrface => {
    return {
        companyHouseOverViewList: state.companyHouse.companyHouseOverViewList,
    }
}

export default connect(mapStateToProps)(CSFillingList)
