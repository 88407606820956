import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Select,
    Switch,
    Flex,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import FullWidthModal from '../../../Components/FullWidthModal'
import dayjs from 'dayjs'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { type CompanyResolution } from '../../../Types/CompanyHouse/CompanyOverView'
import {
    GetCompanyOverViewData,
    GetCompanyResolutionData,
    SetCompanyName,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { GetCHEndingName } from '../../../Services/CompanyHouse/CompanyEligiblity'
import { type CompanyAvailabityStateInterface } from '../../../Types/CompanyHouse/CompanyEligiblity'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import moment from 'moment'
interface AddressProps {
    corporateId: number
    companyName: string
    companyNumber: string
    changeNameChangeModel: () => void
    endingNameList: ListFrontEndInterface[]
    businessId: number
    serviceProcessId: number
}
const NameChangePage = (props: AddressProps): React.ReactElement => {
    const {
        corporateId,
        companyName,
        companyNumber,
        changeNameChangeModel,
        endingNameList,
        businessId,
        serviceProcessId,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const [resolutionData, setResolutionData] =
        useState<CompanyResolution | null>(null)

    const [isSubmitToCH, setIsSubmitToCH] = useState<number>(0)
    const [isNameHasResolved, setNameHasResolved] = useState<number>(0)
    const [submitswitchstatus, setsubmitswitchstatus] = useState<boolean>(false)
    const NameHasResolvedSwitchChange = (checked: boolean): void => {
        setNameHasResolved(checked ? 1 : 0)
    }
    useEffect(() => {
        GetCHEndingName(dispatch, 1, 0)
    }, [])
    useEffect(() => {
        const fetchAuditData = async (): Promise<void> => {
            try {
                const data = await GetCompanyResolutionData(
                    dispatch,
                    corporateId
                )
                setResolutionData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        void fetchAuditData()

        const combineData = `${companyName} - ${companyNumber}`
        form.setFieldValue('companyNameandReg', combineData)
    }, [corporateId])

    useEffect(() => {
        form.setFieldsValue(resolutionData)
        setSelectedDateAp(resolutionData?.dateofResolution ?? '')
    }, [resolutionData])
    useEffect(() => {
        form.setFieldValue('dateofResolution', selectedDateAp)
    }, [selectedDateAp])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const handleSwitchChange = (checked: boolean): void => {
        setsubmitswitchstatus(checked)
        setSwithModelStatus(!swithModalStatus)
    }
    const submitSwitchFunction = (): void => {
        setIsSubmitToCH(submitswitchstatus ? 1 : 0)
        setSwithModelStatus(!swithModalStatus)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const handelDatasave = (): void => {
        void submitData()
    }
    const submitData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                companyId: resolutionData?.companyId ?? 0,
                corporateId: resolutionData?.corporateId ?? 0,
                companyName: formData?.companyName ?? '',
                isCompanyNamesubmittoCH: isSubmitToCH,
                dateofResolution: moment(formData.dateofResolution).format(
                    'DD/MM/YYYY'
                ),
                isCompanyNameHasResolved: isNameHasResolved,
            }
            void SetCompanyName(dispatch, dataToSave)
            setTimeout(() => {
                GetCompanyOverViewData(
                    dispatch,
                    businessId,
                    corporateId,
                    serviceProcessId,
                    1
                )
                changeNameChangeModel()
            }, 1000)
        } catch (error) {}
    }
    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Company & Reg Number"
                                name="companyNameandReg"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Company Name"
                                name="companyName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Company Name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Ending Name"
                                name="endingName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select ending name!',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={endingNameList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Date of Resolution"
                                name="dateofResolution"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your date!',
                                    },
                                ]}
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedDateAp.length > 0
                                                ? dayjs(selectedDateAp)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Row>
                                <Col span={2}>
                                    <Switch
                                        disabled
                                        checked={isNameHasResolved === 1}
                                        onChange={NameHasResolvedSwitchChange}
                                    />
                                </Col>
                                <Col span={20}>
                                    <span style={{ marginLeft: 5 }}>
                                        Tick to confirm that the notice is given
                                        that this company has resolved to change
                                        the company name to the proposed new
                                        name displayed above
                                    </span>
                                </Col>
                            </Row>{' '}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 20 }}>
                        <Col span={24}>
                            <Switch
                                checked={isSubmitToCH === 1}
                                onChange={handleSwitchChange}
                            />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={changeNameChangeModel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                                clickAction={handelDatasave}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={submitSwitchFunction}
            />
        </>
    )
}
const mapStateToProps = (state: any): CompanyAvailabityStateInterface => {
    return {
        chCompanyType: state.companyHouse.chCompanyType,
        endingNameList: state.companyHouse.endingNameList,
    }
}

export default connect(mapStateToProps)(NameChangePage)
