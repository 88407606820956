// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileUploadButton_tRNZ7 {
  width: 100%;
}
.uploadComponent_O_YN3 > div {
  width: 100% !important;
}
.uploadContainer_JOH5i {
  display: inline-block;
  position: relative;
}
.dashedBox_exexz {
  border: 2px dashed #ccc;
  /* Dashed border style */
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.uploadButton_d83hJ {
  margin-top: 10px;
  /* Adjust the margin as needed */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/s3Upload.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEA;EACI,sBAAA;AAAJ;AAGA;EACI,qBAAA;EACA,kBAAA;AADJ;AAIA;EACI,uBAAA;EAFF,wBAAwB;EAGtB,kBAAA;EACA,aAAA;EACA,kBAAA;AADJ;AAIA;EACI,gBAAA;EAFF,gCAAgC;AAClC","sourcesContent":[".fileUploadButton {\n    width: 100%;\n}\n\n.uploadComponent > div {\n    width: 100% !important;\n}\n\n.uploadContainer {\n    display: inline-block;\n    position: relative;\n}\n\n.dashedBox {\n    border: 2px dashed #ccc; /* Dashed border style */\n    border-radius: 5px;\n    padding: 20px;\n    text-align: center;\n}\n\n.uploadButton {\n    margin-top: 10px; /* Adjust the margin as needed */\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadButton": `fileUploadButton_tRNZ7`,
	"uploadComponent": `uploadComponent_O_YN3`,
	"uploadContainer": `uploadContainer_JOH5i`,
	"dashedBox": `dashedBox_exexz`,
	"uploadButton": `uploadButton_d83hJ`
};
export default ___CSS_LOADER_EXPORT___;
