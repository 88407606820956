import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../../Utils/Common.less'
import { type InduvidualExpandViewData } from '../../../../Types/ServiceSetup/ClientServiceUser'

const InduviualExpandView = (
    props: InduvidualExpandViewData
): React.ReactElement => {
    const { induvidalData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Trading Name :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {induvidalData.tradingName}
                </div>
            </Row>
            <Row className={commontStyles.expandRow}>
                Employment Type :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {induvidalData.typeOfSelfEmployment}
                </div>
            </Row>
        </div>
    )
}

export default InduviualExpandView
