import React from 'react'
import { Table } from 'antd'

import commontStyles from '../../../../Utils/Common.less'
import type { onboardingPartnershipExpandViewInterface } from '../../../../Types/Client/OnboardingClients/OnBoardingPartnership'

const PartnershipExpandView = (
    props: onboardingPartnershipExpandViewInterface
): React.ReactElement => {
    const { PartnershipData } = props

    const columns = [
        {
            title: 'Name',
            dataIndex: 'contactName',
        },
        {
            title: 'Contect No',
            dataIndex: 'mobilenumber',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
    ]

    const dataSource = [
        {
            key: '1',
            mobilenumber: PartnershipData.mobilenumber,
            emailAddress: PartnershipData.emailAddress,
            contactName: PartnershipData.contactName,
        },
    ]

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default PartnershipExpandView
