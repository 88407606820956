import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Input, Form, Button, Select, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { StaffDesignationItemDefaultData } from './staffDesignationList'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import type {
    StaffDesignationFormInterface,
    StaffDesignationFormDataInterface,
    StaffDesignationFormStateInterface,
} from '../../../Types/StaffDesignations'
import { CheckStaffDesignationNameExist } from '../../../Services/StaffDesignation'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
import { GetDYNAMICClientDetails } from '../../../Services/Common'

const { TextArea } = Input

const StaffDesignationForm = (
    props: StaffDesignationFormInterface
): React.ReactElement => {
    const {
        onSave,
        editData,
        categoryDropDownList,
        dynamicClientCategoryList,
        isLoadingModal,
        resetdata,
        setResetData,
    } = props
    const [clientCategoryId, setClientCategoryId] = useState<number | null>(
        null
    )
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    let timeout: any

    useEffect(() => {
        form.setFieldsValue({
            designationId: '0',
            clientCategoryId: null,
            clientDynCategoryId: null,
        })
    }, [])

    useEffect(() => {
        if (resetdata === 'true') {
            resetForm()
            form.resetFields()
        }
        setResetData('false')
    }, [resetdata])
    useEffect(() => {
        const clientCategoryData = categoryDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.clientCategoryName
        )
        const departmentData = dynamicClientCategoryList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.departmentName
        )
        const newData = {
            ...editData,
            clientCategoryId: clientCategoryData?.value ?? null,
            clientDynCategoryId: departmentData?.value ?? null,
        }
        form.setFieldsValue(newData)
        setClientCategoryId(clientCategoryData?.value ?? null)
    }, [editData])

    useEffect(() => {
        const departmentData = dynamicClientCategoryList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.departmentName
        )
        const newData = {
            ...form.getFieldsValue(),
            clientDynCategoryId: departmentData?.value ?? null,
        }
        form.setFieldsValue(newData)
    }, [dynamicClientCategoryList])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetDYNAMICClientDetails(dispatch)
    }, [clientCategoryId])

    const resetForm = (): void => {
        form.setFieldsValue({
            StaffDesignationItemDefaultData,
            clientCategoryId: undefined,
            clientDynCategoryId: undefined,
            designationName: undefined,
        })
    }

    const onNameChange = (event: any): void => {
        const clientCategoryId = form.getFieldValue('clientCategoryId') ?? null
        const clientDynCategoryId =
            form.getFieldValue('clientDynCategoryId') ?? null

        if (clientDynCategoryId !== null && clientCategoryId !== null) {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                CheckStaffDesignationNameExist(
                    dispatch,
                    editData?.designationId,
                    clientDynCategoryId,
                    clientCategoryId,
                    event?.target?.value
                )
            }, 1000)
        }
    }

    const onChangeClientCategory = (value: number): void => {
        setClientCategoryId(value)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout="vertical"
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<StaffDesignationFormDataInterface>
                    name="designationId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<StaffDesignationFormDataInterface>
                            label="Company Group"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the company group!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={onChangeClientCategory}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...categoryDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<StaffDesignationFormDataInterface>
                            label="Departments"
                            name="clientDynCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the department!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                options={[
                                    { label: 'Please Select', value: 0 },
                                    { label: 'Accounting', value: 1 },
                                    ...dynamicClientCategoryList.map(
                                        (item) => ({
                                            label: item.label,
                                            value: item.value,
                                        })
                                    ),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<StaffDesignationFormDataInterface>
                            label="Staff Designation"
                            name="designationName"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your designation name!',
                                },
                            ]}
                        >
                            <Input onChange={onNameChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<StaffDesignationFormDataInterface>
                            label="Description"
                            name="description"
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1004', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={Boolean(isLoadingModal)}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): StaffDesignationFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        departmentDropDownList: state.initial.departmentDropDownList,
        dynamicClientCategoryList: state.common.dynamicClientCategoryList,
    }
}

export default connect(mapStateToProps)(StaffDesignationForm)
