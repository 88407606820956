import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import {
    type ClientDashboardTaskStateIntrface,
    type ClientTaskPageProps,
} from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import DraggableTable from './draggableTable'
// import style from './taps.less'
import { connect, useDispatch } from 'react-redux'
import { GetCilentTaskList } from '../../../Services/GlobelSearch'
const TaskList = (props: ClientTaskPageProps): React.ReactElement => {
    const [flagId, setFlagId] = useState(1)
    const { businessTypeId, referenceId, CDTaskList, CDTaskListCount } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    const data = [
        {
            value: 1,
            label: 'In Progress',
        },
        {
            value: 2,
            label: 'Complete',
        },
    ]

    const getTableData = useCallback(() => {
        GetCilentTaskList(
            dispatch,
            businessTypeId,
            referenceId,
            flagId,
            '=NA=',
            currentPage,
            itemsPerPage
        )
    }, [businessTypeId, flagId, referenceId, currentPage, itemsPerPage])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const onchangeFlag = (value: number): void => {
        setFlagId(value)
    }

    return (
        <>
            <Space>
                <Select
                    className={commonStyles.topdropdown2}
                    placeholder="Please select"
                    options={data}
                    onChange={onchangeFlag}
                    value={flagId}
                />
            </Space>

            <br />
            {CDTaskListCount > 0 ? (
                <div style={{ width: '100%', marginTop: '50px' }}>
                    <DraggableTable
                        setPageSize={setItemsPerPage}
                        setPageNo={setCurrentPage}
                        CDTaskList={CDTaskList}
                        CDTaskListCount={CDTaskListCount}
                    />
                </div>
            ) : (
                <div
                    style={{
                        textAlign: 'left',
                        marginTop: '30px',
                        width: '100%',
                    }}
                >
                    No Record Found
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state: any): ClientDashboardTaskStateIntrface => {
    return {
        CDTaskList: state.golobelSearch.CDTaskList,
        CDTaskListCount: state.golobelSearch.CDTaskListCount,
    }
}

export default connect(mapStateToProps)(TaskList)
