import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { ChangeDueDate } from '../../../Services/ToDoViewAllList'

interface changeDudateModelType {
    serviceProcessId: number
    changeModelState: () => void
}
const ChangeDueDateModel = (
    props: changeDudateModelType
): React.ReactElement => {
    const { serviceProcessId, changeModelState } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')

    useEffect(() => {
        form.setFieldValue('dueDate', selectedDate)
    }, [serviceProcessId, selectedDate])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                serviceProcessId,
                dueDate: selectedDate,
                description: formData.description,
            }

            await ChangeDueDate(dispatch, dataToSave)

            form.setFieldsValue({
                description: '',
                historyDate: '',
            })

            changeModelState()
            setSelectedDate('')
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Due Date"
                                name="dueDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Due Date!',
                                    },
                                ]}
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChange(date, dateString)
                                        }}
                                        value={
                                            selectedDate.length > 0
                                                ? dayjs(selectedDate)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        className={commonStyles.dateWidth}
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`Comments`}
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Comments!',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default ChangeDueDateModel
