import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    DatePicker,
    Select,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    GetWorkFlow,
    GetLeaveMode,
    SaveLeave,
    GetTeamCalender,
    GetPersonalCalender,
} from '../../../Services/HR/LeavePermission'
import { GetUserList } from '../../../Services/User'
import {
    type FormStaeInterface,
    type FormComponentProps,
    type LeaveListType,
} from '../../../Types/Hr/LeavePermission'
import dayjs, { type Dayjs } from 'dayjs'
import 'dayjs/locale/en'

dayjs.locale('en')
const { TextArea } = Input

const FormComponent = (props: FormComponentProps): React.ReactElement => {
    const {
        LeaveType,
        workflowDropDownList,
        LeaveModeDropDownList,
        userList,
        LeaveEditRecord,
        onFinish,
        resetdata,
        setResetData,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [formHeight, setFormHeight] = useState('auto')
    // const [saveEnable, setSaveEnable] = useState(false)
    const LeaveTypeId = LeaveEditRecord.leaveId
    const hasFetchedOnce = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    const [fromDate, setFromDate] = useState<Dayjs | null>(null)
    const [todate, setToDate] = useState<Dayjs | null>(null)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetWorkFlow(dispatch)
        GetLeaveMode(dispatch)
        GetUserList(dispatch)
    }, [dispatch, LeaveTypeId])

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
        }

        setResetData('false')
    }, [resetdata])
    const handleFinish = async (): Promise<void> => {
        try {
            await form.validateFields()
            setIsLoadingModal(true)
            const formdata = await form.getFieldsValue()
            const formatleaveFrom = dayjs(
                formdata.leaveFrom,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('DD/MM/YYYY')
            const formatleaveTo = dayjs(
                formdata.leaveTo,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('DD/MM/YYYY')

            const leaveApprovalDescription = ''
            const leaveApprovalStatus = 'Saved'
            const formDataWithLeaveType = {
                ...formdata,
                LeaveType,
                leaveApprovalDescription,
                leaveApprovalStatus,
            }
            form.setFieldsValue(formDataWithLeaveType)

            const api = {
                ...formDataWithLeaveType,
                leaveFrom: formatleaveFrom,
                leaveTo: formatleaveTo,
            }

            await SaveLeave(dispatch, api)
                .then((result) => {
                    if (result !== null && result !== undefined) {
                        setIsLoadingModal(false)
                        GetTeamCalender(dispatch)
                        GetPersonalCalender(dispatch)
                    }
                })
                .catch(() => {})

            onFinish()

            form.resetFields()
        } catch (err) {
            console.error('Validation errors:', err)
        }
    }

    useEffect(() => {
        if (LeaveTypeId > 0) {
            // const parsedLeaveFrom = dayjs(LeaveEditRecord.leaveFrom).format(
            //     'DD/MM/YYYY'
            // )
            // const parsedLeaveTo = dayjs(LeaveEditRecord.leaveTo, 'YYYY/MM/DD')
            form.setFieldsValue({
                leaveId: LeaveEditRecord.leaveId,
                leaveFrom:
                    LeaveEditRecord.leaveFrom !== null
                        ? dayjs(LeaveEditRecord.leaveFrom)
                        : null,
                leaveTo:
                    LeaveEditRecord.leaveTo !== null
                        ? dayjs(LeaveEditRecord.leaveTo)
                        : null,
                leaveMode: LeaveEditRecord.leaveMode,
                description: LeaveEditRecord.description,
                coveringPersonId:
                    LeaveEditRecord.coveringPersonId !== 0
                        ? LeaveEditRecord.coveringPersonId
                        : 0,
                workflowId:
                    LeaveEditRecord.workflowId !== 0
                        ? LeaveEditRecord.workflowId
                        : 0,
            })
            setFromDate(
                LeaveEditRecord.leaveFrom !== null
                    ? dayjs(LeaveEditRecord.leaveFrom)
                    : null
            )
        }
    }, [LeaveTypeId, LeaveEditRecord])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const disabledToDate = (current: any): any => {
        const fromDate = form.getFieldValue('leaveFrom')
        return fromDate !== null && Boolean(current)
            ? current.isBefore(fromDate, 'day')
            : false
    }

    const disabledFromDate = (current: Dayjs | null): boolean => {
        return !(todate == null) && current != null
            ? current.isAfter(todate, 'day')
            : false
    }
    const handleFromDateChange = (date: Dayjs | null): void => {
        setFromDate(date) // Update state when "From Date" changes
    }
    const handleToDateChange = (date: Dayjs | null): void => {
        setToDate(date) // Update state when "From Date" changes
    }

    return (
        <div
            className={commonStyles.formWrapper}
            style={{ minHeight: formHeight }}
        >
            <Form
                name="complex-form"
                initialValues={{ remember: true }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                onFieldsChange={() => {
                    setFormHeight('auto')
                }}
                onValuesChange={() => {
                    setTimeout(() => {
                        setFormHeight(`${form.getFieldsValue().length * 50}px`)
                    }, 0)
                }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Form.Item<LeaveListType>
                        label="leaveId"
                        name="leaveId"
                        hidden={true}
                    />

                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="From Date"
                            name="leaveFrom"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the From Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                                onChange={handleFromDateChange}
                                disabledDate={disabledFromDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="To Date"
                            name="leaveTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'please select the To date',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                                disabledDate={disabledToDate}
                                disabled={fromDate === null}
                                onChange={handleToDateChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Leave Mode"
                            name="leaveMode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Leave Mode!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...LeaveModeDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Purpose"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter the purpose!',
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Covering Person"
                            name="coveringPersonId"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: 0 },
                                    ...userList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Work Flow"
                            name="workflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Leave Mode!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: 0 },
                                    ...workflowDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                handleFinish()
                                    .then(() => {})
                                    .catch(() => {})
                            }}
                            loading={isLoadingModal}
                        >
                            <Tooltip title="Save Leave">
                                <SaveOutlined />
                            </Tooltip>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): FormStaeInterface => {
    return {
        workflowDropDownList: state.hr.workflowDropDownList,
        LeaveModeDropDownList: state.hr.LeaveModeDropDownList,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(FormComponent)
