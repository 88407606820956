// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabsWidth_XDoyI {
  width: 100%;
}
.flexContainer_o4zR6 {
  display: 'flex';
  justify-content: 'space-between';
  width: '100%';
}
.contactinfo_NZ8vc {
  width: '1024px';
}
.rowform_HNbp_ {
  margin-bottom: 20px;
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/Less/Clients/RegisteredClients/Corporate/CorporateTabs.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEA;EACI,eAAA;EACA,gCAAA;EACA,aAAA;AAAJ;AAGA;EACI,eAAA;AADJ;AAIA;EACI,mBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".tabsWidth {\n    width: 100%;\n}\n\n.flexContainer {\n    display: 'flex';\n    justify-content: 'space-between';\n    width: '100%';\n}\n\n.contactinfo {\n    width: '1024px';\n}\n\n.rowform {\n    margin-bottom: 20px;\n    margin-top: 40px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsWidth": `tabsWidth_XDoyI`,
	"flexContainer": `flexContainer_o4zR6`,
	"contactinfo": `contactinfo_NZ8vc`,
	"rowform": `rowform_HNbp_`
};
export default ___CSS_LOADER_EXPORT___;
