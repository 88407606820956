import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Input, Button, Tooltip } from 'antd'
import commonStyles from '../../../../../../Utils/Common.less'
import 'react-quill/dist/quill.snow.css'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import CustomQuill from '../../../../../../Components/CustomQuill'
import { GetEmaiDetailsByReferenceID } from '../../../../../../Services/Common'
import { SaveEmailRemider } from '../../../../../../Services/Corporate'
interface EmailDatas {
    serviceId: number
    contactName: string
    fromAddress: string
    toAddress: string
    ccAddress: string
    bccAddress: string
    subject: string
    emailAttachmentList: any[] // Replace 'any[]' with a more specific type if possible
    description: string // Assuming 'description' is an HTML string
}

const extractTextFromHtml = (htmlString: any): string => {
    const tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.innerHTML
}

const convertHtmlToText = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent ?? ''
}

const EmailReminder = (props: {
    referenceId: number
    businessId: number
    onEmailCloseModel: () => void
}): React.ReactElement => {
    const { referenceId, businessId, onEmailCloseModel } = props
    const tempId = 1043
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [emailText, setEmailText] = useState('')
    const [emaildata, setEmaildata] = useState<EmailDatas>({
        serviceId: 0,
        contactName: '',
        fromAddress: '',
        toAddress: '',
        ccAddress: '',
        bccAddress: '',
        subject: '',
        emailAttachmentList: [],
        description: '',
    })

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    // useEffect(() => {
    //     if (EmailDetails !== null && typeof EmailDetails === 'object') {
    //         const newFieldValues = {
    //             toAddress: EmailDetails?.emailAddress,
    //             ccAddress: EmailDetails?.alternativeEmailAddress,
    //             bccAddress: EmailDetails?.bccEmailAddress,
    //         }

    //         form.setFieldsValue(newFieldValues)
    //     }
    // }, [EmailDetails, form])

    useEffect(() => {
        if (tempId !== null && tempId !== undefined) {
            const GetEmailDeatils = async (): Promise<void> => {
                const emaildata = await GetEmaiDetailsByReferenceID(
                    dispatch,
                    businessId,
                    referenceId,
                    tempId
                )
                setEmaildata(emaildata)
                const newFieldValues = {
                    toAddress: emaildata?.toAddress,
                    ccAddress: emaildata?.ccAddress,
                    bccAddress: emaildata?.bccAddress,
                }

                form.setFieldsValue(newFieldValues)
                form.setFieldsValue(emaildata)
                const description = extractTextFromHtml(emaildata?.description)
                const task = convertHtmlToText(description)
                setEmailText(task)
            }
            void GetEmailDeatils()
        }
    }, [tempId])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataTosave = {
                clientEmailId: 0,
                templateId: tempId,
                serviceProcessId: 0,
                companyTypeId: businessId,
                referenceId,
                serviceActionId: 0,
                serviceActionStepId: 0,
                fromAddress: 'info@iykons.com',
                toAddress: formData.emailAddress,
                ccAddress: formData.emailCC,
                bccAddress: formData.emailBcc,
                subject: formData.subject,
                description: emailText,
                emailStatus: 'Sucsss',
                serviceId: 0,
                contactName: emaildata?.contactName,
                invoiceId: 0,
            }

            await SaveEmailRemider(dispatch, dataTosave, uploadFileList)

            form.resetFields()
            onEmailCloseModel()
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }
    return (
        <>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email To"
                            name="toAddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email CC"
                            name="ccAddress"
                            rules={
                                [
                                    // {
                                    //     type: 'email',
                                    //     message: 'Please input Valid email!',
                                    // },
                                ]
                            }
                        >
                            <Input />
                        </Form.Item>
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Email BCC " name="bccAddress">
                            <Input />
                        </Form.Item>
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Subject',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description">
                            <CustomQuill
                                onDescriptionChange={onDescriptinChange}
                                emailText={emailText}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Attachment" name="imageUpload">
                            <S3UploadMultiple
                                name={'imageUpload'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col offset={22} span={2}>
                        <Form.Item>
                            <Tooltip title="Send Email">
                                <Button
                                    type="primary"
                                    size="middle"
                                    onClick={onSave}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EmailReminder
