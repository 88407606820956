import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

interface InitialStateInterface {
    flag: number
    servicedataId: ServicedataIdInterface
    isHeaderAlt: number
    fixedMenu: number
    fixedSize: number
    siderClientID: number
    routeData: RouteDataIntrface
    departmentId: number
}

interface ServicedataIdInterface {
    serviceProcesssId: number
    serviceId: number
    modeId: number
}

interface RouteDataIntrface {
    dynamicPath: string
    perntName: string
}
const DashboardFlagReducer = createSlice({
    name: 'dashboardFlag',
    initialState: {
        flag: 1,

        servicedataId: {
            serviceProcesssId: 0,
            serviceId: 0,
            modeId: 1,
        },
        isHeaderAlt: 1,
        fixedMenu: 1,
        fixedSize: 1,
        siderClientID: 0,
        routeData: {
            dynamicPath: '',
            perntName: '',
        },
        departmentId: 0,
    },
    reducers: {
        flag(state: InitialStateInterface, action: PayloadAction<number>) {
            state.flag = action.payload
        },

        servicedataId(state, action: PayloadAction<ServicedataIdInterface>) {
            state.servicedataId = action.payload
        },
        isHeaderAlt(state, action: PayloadAction<number>) {
            state.isHeaderAlt = action.payload
        },
        fixedMenu(state, action: PayloadAction<number>) {
            state.fixedMenu = action.payload
        },
        fixedSize(state, action: PayloadAction<number>) {
            state.fixedSize = action.payload
        },
        siderClientID(state, action: PayloadAction<number>) {
            state.siderClientID = action.payload
        },
        routeData(
            state: InitialStateInterface,
            action: PayloadAction<RouteDataIntrface>
        ) {
            state.routeData = action.payload
        },
        departmentId(state, action: PayloadAction<number>) {
            state.departmentId = action.payload
        },
    },
})

export const {
    flag,
    servicedataId,
    isHeaderAlt,
    fixedMenu,
    fixedSize,
    siderClientID,
    routeData,
    departmentId,
} = DashboardFlagReducer.actions
export default DashboardFlagReducer.reducer
