/* eslint-disable */
// prettier-ignore

import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import Quill styles
interface ClickShot {
    src: string
    label: string
}

function captureElementWithMinSize(
    element: HTMLElement,
    minWidth = 160,
    minHeight = 20
): Promise<string> {
    return new Promise(async (resolve, reject) => {
        try {
            // Scroll into view so it's not off-screen
            element.scrollIntoView({ block: 'nearest', inline: 'nearest' })
            // Wait a moment for the scroll to happen
            await new Promise((res) => setTimeout(res, 50))

            const rect = element.getBoundingClientRect()
            // Enforce minimum dimensions
            const captureWidth = Math.max(rect.width, minWidth)
            const captureHeight = Math.max(rect.height, minHeight)

            const canvas = await html2canvas(document.body, {
                useCORS: true,
                x: rect.left,
                y: rect.top,
                width: captureWidth,
                height: captureHeight,
                // Ensure the entire page is available
                windowWidth: document.documentElement.scrollWidth,
                windowHeight: document.documentElement.scrollHeight,
            })
            const imgData = canvas.toDataURL('image/png')
            resolve(imgData)
        } catch (err) {
            reject(err)
        }
    })
}

// Whether to show the final popup of steps
interface LogEntry {
    timestamp: string
    message: string
}

const QARecorder: React.FC = () => {
    // Ref for the recorder UI container
    const recorderRef = useRef<HTMLDivElement>(null)
    const [showStepsPopup, setShowStepsPopup] = useState<boolean>(false)

    // Toggles display of the QA Recorder UI
    const [showQAUI, setShowQAUI] = useState<boolean>(false)

    // Add new state to handle "minimized" UI
    const [isMinimized, setIsMinimized] = useState<boolean>(false)
    type ActionLog = { type: 'text' | 'image'; content: string }

    // Screen Recording State
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const mediaRecorderRef = useRef<MediaRecorder | null>(null)
    const recordedChunks = useRef<BlobPart[]>([])
    const [recordedVideoBlob, setRecordedVideoBlob] = useState<Blob | null>(
        null
    )
    const [videoURL, setVideoURL] = useState<string | null>(null)

    // State for storing step screenshots
    // Logs
    const [logs, setLogs] = useState<LogEntry[]>([])
    const [clickLog, setClickLog] = useState<string>('')

    // Screenshots (base64)
    const [screenshots, setScreenshots] = useState<string[]>([])
    const [showVideoPreview, setShowVideoPreview] = useState(false)
    const [sprintBoardId, setSprintBoardId] = useState<string>('')

    // Jira Fields
    const [summary, setSummary] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    // Jira ticket URL after creation
    const [ticketUrl, setTicketUrl] = useState<string | null>(null)
    const [isAnnotating, setIsAnnotating] = useState(false)
    const [annotationIndex, setAnnotationIndex] = useState<number | null>(null)
    // State to handle sending ticket
    const [isCreatingTicket, setIsCreatingTicket] = useState<boolean>(false)
    const [sprintBoardNumber, setSprintBoardNumber] = useState<string>('')

    // Thumbnails for each clicked element (label + base64 screenshot)

    // Controls whether we show the popup with all click screenshots
    const [showClickShotsPopup, setShowClickShotsPopup] =
        useState<boolean>(false)

    // Store small screenshots (thumbnails) of each clicked element
    const [clickShots, setClickShots] = useState<
        { src: string; label: string }[]
    >([])
    // A list of textual steps (no screenshots).
    const [clickSteps, setClickSteps] = useState<string[]>([])

    // Whether to show a pop-up with all steps after stopping.
    function findElementLabel(element: HTMLElement): string {
        let current: HTMLElement | null = element
        while (current) {
            // 1. Check for a "title" attribute.
            const titleAttr = current.getAttribute('title')
            if (titleAttr && titleAttr.trim().length > 0) {
                return titleAttr.trim()
            }
            // 2. Check for an "aria-label" attribute.
            const ariaLabel = current.getAttribute('aria-label')
            if (ariaLabel && ariaLabel.trim().length > 0) {
                return ariaLabel.trim()
            }
            // 3. Check for visible text via innerText.
            if (current.innerText && current.innerText.trim().length > 0) {
                return current.innerText.trim()
            }
            // 4. As a fallback, remove any HTML tags from innerHTML.
            const strippedHTML = current.innerHTML
                .replace(/<[^>]+>/g, '')
                .trim()
            if (strippedHTML.length > 0) {
                return strippedHTML
            }
            // Move up to the parent element.
            current = current.parentElement
        }
        return ''
    }
    /**
     * Walks up the DOM from the clicked element until we find:
     *  1) A title attribute, or
     *  2) A data-tip/data-tooltip attribute, or
     *  3) Some non-empty .innerText
     * If none are found by the time we reach <body>, returns null.
     */

    function findNearestTextOrTooltip(el: HTMLElement): string | null {
        let current: HTMLElement | null = el
        while (current && current !== document.body) {
            // 1) Title attribute?
            const title = current.getAttribute('title')?.trim()
            if (title) {
                return title
            }
            // 2) data-tip or data-tooltip attribute?
            const dataTip =
                current.getAttribute('data-tip')?.trim() ||
                current.getAttribute('data-tooltip')?.trim()
            if (dataTip) {
                return dataTip
            }
            // 3) Visible text
            const text = current.innerText?.trim()
            if (text) {
                return text
            }
            // Move up the tree
            current = current.parentElement
        }
        // If we reach here, no meaningful text found
        return null
    }
    function getDescriptiveLabel(element: HTMLElement): string {
        let current: HTMLElement | null = element
        while (current) {
            // 1. Check for a 'title' attribute.
            const titleAttr = current.getAttribute('title')
            if (titleAttr && titleAttr.trim().length > 0) {
                let label = titleAttr.trim()
                if (label.length > 40) {
                    label = label.substring(0, 40) + '...'
                }
                return label
            }
            // 2. Check for an 'aria-label' attribute.
            const ariaLabel = current.getAttribute('aria-label')
            if (ariaLabel && ariaLabel.trim().length > 0) {
                let label = ariaLabel.trim()
                if (label.length > 40) {
                    label = label.substring(0, 40) + '...'
                }
                return label
            }
            // 3. Check for visible text in innerText.
            if (current.innerText && current.innerText.trim().length > 0) {
                let label = current.innerText.trim()
                if (label.length > 40) {
                    label = label.substring(0, 40) + '...'
                }
                return label
            }
            // Move up to the parent element.
            current = current.parentElement
        }
        return ''
    }
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (!isRecording) return
            // Skip clicks inside the recorder UI
            if (
                recorderRef.current &&
                recorderRef.current.contains(e.target as Node)
            ) {
                return
            }
            const target = e.target as HTMLElement
            const label = getDescriptiveLabel(target)
            if (label) {
                setClickLog((prev) => prev + `<p>Clicked on: ${label}</p>`)
                setClickSteps((prev) => [...prev, label])
            }
        }

        // Attach to document.body with capture
        document.body.addEventListener('click', handleClick, true)
        return () => {
            document.body.removeEventListener('click', handleClick, true)
        }
    }, [isRecording])

    // 2) Toggle QA UI with Ctrl+Shift+J
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() == 'j') {
                e.preventDefault()
                setShowQAUI((prev) => !prev)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    // NEW: Auto-Fill hotkey with Ctrl+Shift+I
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() == 'i') {
                e.preventDefault()
                autoFillForms() // call our auto-fill helper
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    // Quill config
    const quillModules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
        ],
    }

    const quillFormats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
    ]

    // Helper: create ADF from plain text
    const createADFFromText = (text: string): any => ({
        type: 'doc',
        version: 1,
        content: [
            {
                type: 'paragraph',
                content: [
                    {
                        type: 'text',
                        text,
                    },
                ],
            },
        ],
    })

    // 3) Start recording
    const startRecording = async () => {
        const recorderElement = recorderRef.current
        const originalDisplay = recorderElement
            ? recorderElement.style.display
            : ''

        // Hide the QA Recorder UI from capture
        if (recorderElement) {
            recorderElement.style.display = 'none'
        }

        // Check for Chrome tabCapture support
        if (
            typeof window !== 'undefined' &&
            (window as any).chrome &&
            (window as any).chrome.tabCapture &&
            (window as any).chrome.tabCapture.capture
        ) {
            const chromeInstance = (window as any).chrome
            chromeInstance.tabCapture.capture(
                { audio: true, video: true },
                (stream: any) => {
                    if (!stream) {
                        console.error('Failed to capture tab')
                        if (recorderElement) {
                            recorderElement.style.display = originalDisplay
                        }
                        return
                    }
                    const mediaRecorder = new MediaRecorder(stream, {
                        mimeType: 'video/webm',
                    })
                    mediaRecorderRef.current = mediaRecorder
                    recordedChunks.current = []

                    mediaRecorder.ondataavailable = (e: BlobEvent) => {
                        if (e.data.size > 0) {
                            recordedChunks.current.push(e.data)
                        }
                    }

                    mediaRecorder.onstop = () => {
                        const blob = new Blob(recordedChunks.current, {
                            type: 'video/webm',
                        })
                        setRecordedVideoBlob(blob)
                        const url = URL.createObjectURL(blob)
                        setVideoURL(url)
                        // Open the video preview modal automatically for review
                        setShowVideoPreview(true)

                        if (recorderElement) {
                            recorderElement.style.display = originalDisplay
                        }
                    }

                    mediaRecorder.start()
                    setIsRecording(true)
                }
            )
        } else {
            // Fallback to getDisplayMedia
            try {
                const stream = await navigator.mediaDevices.getDisplayMedia({
                    video: true,
                    audio: true,
                })
                const mediaRecorder = new MediaRecorder(stream, {
                    mimeType: 'video/webm',
                })
                mediaRecorderRef.current = mediaRecorder
                recordedChunks.current = []

                mediaRecorder.ondataavailable = (e: BlobEvent) => {
                    if (e.data.size > 0) {
                        recordedChunks.current.push(e.data)
                    }
                }

                mediaRecorder.onstop = () => {
                    const blob = new Blob(recordedChunks.current, {
                        type: 'video/webm',
                    })
                    setRecordedVideoBlob(blob)
                    const url = URL.createObjectURL(blob)
                    setVideoURL(url)
                    // Open the video preview modal automatically for review
                    setShowVideoPreview(true)

                    if (recorderElement) {
                        recorderElement.style.display = originalDisplay
                    }
                }

                mediaRecorder.start()
                setIsRecording(true)
            } catch (err) {
                console.error('Error starting screen capture:', err)
                if (recorderElement) {
                    recorderElement.style.display = originalDisplay
                }
            }
        }
    }
    const buildStepsSnippet = (): string => {
        // We combine each step with label + screenshot
        // Then the user can paste into Jira or Quill
        let snippet = `<h3>Steps to Reproduce</h3>\n`
        clickShots.forEach((shot, i) => {
            snippet += `
        <div style="margin-bottom:15px;">
          <p><strong>Step ${i + 1}:</strong> ${shot.label}</p>
          <img src="${shot.src}" style="max-width:300px; border:1px solid #ccc;" alt="Clicked element ${i + 1}" />
        </div>
      `
        })
        return snippet
    }

    const copyStepsToClipboard = () => {
        // Build a snippet
        let snippet = '<h3>Steps to Reproduce</h3>\n'
        clickSteps.forEach((step, i) => {
            snippet += `<p><strong>Step ${i + 1}:</strong> ${step}</p>\n<hr/>\n`
        })

        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = snippet
        document.body.appendChild(tempDiv)

        const range = document.createRange()
        range.selectNodeContents(tempDiv)
        const sel = window.getSelection()
        if (sel) {
            sel.removeAllRanges()
            sel.addRange(range)
        }
        document.execCommand('copy')
        document.body.removeChild(tempDiv)

        alert('Steps copied to clipboard!')
    }
    // 4) Stop recording
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop()
        }
        setIsRecording(false)

        // Show the steps pop-up
        setShowStepsPopup(true)
    }

    // 5) Capture screenshot
    const captureScreenshot = async () => {
        try {
            const recorderElement = recorderRef.current
            const originalDisplay = recorderElement
                ? recorderElement.style.display
                : ''
            if (recorderElement) {
                recorderElement.style.display = 'none'
            }

            // Wait briefly for the UI to hide
            await new Promise((resolve) => setTimeout(resolve, 100))

            // Capture only the visible viewport
            const canvas = await html2canvas(document.body, {
                useCORS: true,
                scrollX: window.scrollX,
                scrollY: window.scrollY,
                width: window.innerWidth,
                height: window.innerHeight,
            })

            const imgData = canvas.toDataURL('image/png')

            // Restore the QA UI
            if (recorderElement) {
                recorderElement.style.display = originalDisplay
            }

            // Automatically add screenshot and open annotation modal
            setScreenshots((prev) => {
                const newScreenshots = [...prev, imgData]
                setAnnotationIndex(newScreenshots.length - 1)
                setIsAnnotating(true)
                return newScreenshots
            })
        } catch (err) {
            console.error('Screenshot capture failed:', err)
            if (recorderRef.current) {
                recorderRef.current.style.display = ''
            }
        }
    }

    /**
     * 6) Send Jira ticket & attachments in a SINGLE request:
     *    We'll add an "attachments" array in our POST body.
     */
    const sendJiraTicket = async () => {
        try {
            setIsCreatingTicket(true)

            // Convert the Quill HTML to ADF.
            const adfDescription = convertHtmlToAdf(description)

            // Build the Jira fields.
            const bugReportFields = {
                project: { key: 'I30' },
                summary,
                description: adfDescription,
                issuetype: { name: 'Bug' },
                environment: createADFFromText('QA'),
            }

            // Build the attachments array.
            const attachments: Array<{ filename: string; fileData: string }> =
                []
            screenshots.forEach((base64, i) => {
                attachments.push({
                    filename: `screenshot-${i + 1}.png`,
                    fileData: base64,
                })
            })
            if (recordedVideoBlob) {
                const videoBase64 = await blobToBase64(recordedVideoBlob)
                attachments.push({
                    filename: 'recorded-video.webm',
                    fileData: videoBase64,
                })
            }

            // Build the payload.
            // If sprintBoardId is empty, it won't be sent.
            const payload = {
                fields: bugReportFields,
                attachments,
                sprintBoardId, // sprintBoardId is a state variable (string). If empty, the API will skip sprint assignment.
            }

            const response = await fetch(
                'https://reactapi.iykons.com/api/Project/CreateJiraTicket/create-jira',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                }
            )

            if (!response.ok) {
                const err = await response.text()
                console.error('Failed to create Jira ticket:', err)
                alert('Failed to create Jira ticket')
                return
            }

            const result = await response.json()
            console.log('Ticket created successfully:', result)
            if (result.key && !sprintBoardId) {
                const newTicketUrl = `https://iykone.atlassian.net/browse/${result.key}`
                setTicketUrl(newTicketUrl)
            } else {
                const newTicketUrl = `https://iykone.atlassian.net/jira/software/projects/I30/boards/16?selectedIssue=${result.key}`
                setTicketUrl(newTicketUrl)
            }

            // Clear attachments and recorded data.
            setScreenshots([])
            setRecordedVideoBlob(null)
            setVideoURL(null)
        } catch (error) {
            console.error('Error creating Jira ticket:', error)
            alert('Error creating Jira ticket')
        } finally {
            setIsCreatingTicket(false)
        }
    }

    const blobToBase64 = async (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result)
                } else {
                    reject('Conversion failed')
                }
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    }
    // Utility: convert Blob to base64

    // Remove the recorded video
    const removeVideo = (): void => {
        setVideoURL(null)
        setRecordedVideoBlob(null)
    }

    // Remove a screenshot
    const removeScreenshot = (index: number): void => {
        setScreenshots((prev) => prev.filter((_, idx) => idx !== index))
    }

    // Reset handler to clear video and screenshots (attachments)
    const handleReset = () => {
        setScreenshots([])
        setRecordedVideoBlob(null)
        setVideoURL(null)
        setSummary('')
        setDescription('')
        setClickLog('')
    }

    // Dummy data generators
    const getRandomName = (): string => {
        const names = [
            'John Doe',
            'Jane Smith',
            'Alice Johnson',
            'Bob Brown',
            'Charlie Davis',
        ]
        return names[Math.floor(Math.random() * names.length)]
    }

    const getRandomAddress = (): string => {
        const addresses = [
            '123 Main St, Springfield',
            '456 Elm St, Metropolis',
            '789 Maple Ave, Gotham',
            '101 Oak St, Smallville',
            '202 Pine St, Star City',
        ]
        return addresses[Math.floor(Math.random() * addresses.length)]
    }

    // ==== Global Sample Data (Place these near the top of your file) ====

    let sampleSetCounter = 0

    const sampleNames = [
        'John Doe',
        'Jane Smith',
        'Alice Johnson',
        'Bob Brown',
        'Charlie Davis',
        'Emily White',
        'Michael Green',
        'Sarah Black',
        'David Gray',
        'Laura Blue',
    ]

    const sampleAddresses = [
        '123 Main St, Springfield',
        '456 Elm St, Metropolis',
        '789 Maple Ave, Gotham',
        '101 Oak St, Smallville',
        '202 Pine St, Star City',
        '303 Birch Rd, Riverdale',
        '404 Pine Ave, Lakeview',
        '505 Cedar Blvd, Forest Hill',
        '606 Maple St, Hilltown',
        '707 Oak Lane, Westside',
    ]

    const sampleTexts = [
        'Lorem ipsum dolor sit amet',
        'Consectetur adipiscing elit',
        'Sed do eiusmod tempor incididunt',
        'Dolore magna aliqua',
        'Ut enim ad minim veniam',
        'Quis nostrud exercitation ullamco',
        'Nisi ut aliquip ex ea commodo',
        'Duis aute irure dolor in reprehenderit',
        'In voluptate velit esse cillum',
        'Eu fugiat nulla pariatur',
    ]

    const getSampleName = (index: number): string =>
        sampleNames[index % sampleNames.length]
    const getSampleAddress = (index: number): string =>
        sampleAddresses[index % sampleAddresses.length]
    const getSampleText = (index: number): string =>
        sampleTexts[index % sampleTexts.length]

    // ==== Helper: Format Date as DD/MM/YYYY ====

    const formatDateDDMMYYYY = (date: Date): string => {
        const dd = String(date.getDate()).padStart(2, '0')
        const mm = String(date.getMonth() + 1).padStart(2, '0')
        const yyyy = date.getFullYear()
        return `${dd}/${mm}/${yyyy}`
    }

    // ==== Helper: simulatePaste ====

    const simulatePaste = (
        element: HTMLInputElement | HTMLTextAreaElement,
        text: string
    ): void => {
        element.focus()
        const valueSetter = Object.getOwnPropertyDescriptor(
            Object.getPrototypeOf(element),
            'value'
        )?.set
        if (valueSetter) {
            valueSetter.call(element, text)
        } else {
            element.value = text
        }
        let clipboardData: DataTransfer | undefined
        try {
            clipboardData = new DataTransfer()
            clipboardData.setData('text', text)
        } catch (err) {
            clipboardData = undefined
        }
        const pasteEvent = new ClipboardEvent('paste', {
            bubbles: true,
            cancelable: true,
            clipboardData: clipboardData,
        })
        element.dispatchEvent(pasteEvent)
        const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
            inputType: 'insertFromPaste',
            data: text,
        })
        element.dispatchEvent(inputEvent)
        element.dispatchEvent(new Event('change', { bubbles: true }))
        element.dispatchEvent(new Event('blur', { bubbles: true }))
    }

    // ----- Handle Ant Design Dropdowns -----
    // For each Ant Design dropdown container that shows a placeholder,
    // simulate a click on its arrow to open the dropdown, wait until options render, then click the first option.
    // ----- New: Handle Ant Design Dropdowns via Keyboard Events -----
    const handleAntDropdownsWithKeys = () => {
        const antSelects = document.querySelectorAll<HTMLDivElement>(
            '.ant-select.ant-select-single'
        )
        antSelects.forEach((container) => {
            const placeholderElem = container.querySelector(
                '.ant-select-selection-placeholder'
            )
            if (
                placeholderElem &&
                placeholderElem.textContent &&
                placeholderElem.textContent
                    .toLowerCase()
                    .includes('please select')
            ) {
                const inputElem = container.querySelector<HTMLInputElement>(
                    'input.ant-select-selection-search-input'
                )
                if (inputElem) {
                    inputElem.focus()
                    // First, send ArrowDown to open the dropdown.
                    const arrowDownEvent = new KeyboardEvent('keydown', {
                        key: 'ArrowDown',
                        code: 'ArrowDown',
                        keyCode: 40,
                        which: 40,
                        bubbles: true,
                    })
                    inputElem.dispatchEvent(arrowDownEvent)
                    // Wait a short time to allow options to render.
                    setTimeout(() => {
                        // Now, simulate the "End" key to move to the last option.
                        const endEvent = new KeyboardEvent('keydown', {
                            key: 'End',
                            code: 'End',
                            keyCode: 35,
                            which: 35,
                            bubbles: true,
                        })
                        inputElem.dispatchEvent(endEvent)
                        // Then simulate Enter to select the last option.
                        const enterEvent = new KeyboardEvent('keydown', {
                            key: 'Enter',
                            code: 'Enter',
                            keyCode: 13,
                            which: 13,
                            bubbles: true,
                        })
                        inputElem.dispatchEvent(enterEvent)
                        inputElem.blur()
                    }, 200)
                }
            }
        })
    }
    // ==== Edited autoFillForms Method ====

    const copyShotsToClipboard = () => {
        // Build an HTML snippet with all screenshots
        const snippet = clickShots
            .map((shot, idx) => {
                return `
      <p><strong>Step ${idx + 1}:</strong> ${shot.label}</p>
      <img src="${shot.src}" alt="Clicked element #${idx + 1}" style="max-width:200px; border:1px solid #ccc;"/>
      <hr/>
    `
            })
            .join('')

        // Create a temporary element to copy HTML from
        const tempEl = document.createElement('div')
        tempEl.innerHTML = snippet
        document.body.appendChild(tempEl)

        // Use the Selection/Range API to select & copy
        const range = document.createRange()
        range.selectNodeContents(tempEl)
        const sel = window.getSelection()
        if (sel) {
            sel.removeAllRanges()
            sel.addRange(range)
        }
        document.execCommand('copy')

        // Cleanup
        document.body.removeChild(tempEl)

        alert('Screenshots copied to clipboard as HTML!')
    }

    const autoFillForms = async (): Promise<void> => {
        // Cycle through 10 sample sets on each call.
        const currentSampleIndex = sampleSetCounter
        sampleSetCounter = (sampleSetCounter + 1) % 10

        // For native date inputs, use ISO format; for datepicker placeholders, use DD/MM/YYYY.
        const todayISO: string = new Date().toISOString().split('T')[0]
        const todayFormatted: string = formatDateDDMMYYYY(new Date())

        const fillPromises: Promise<void>[] = []

        // Process all forms on the page.
        const forms = document.querySelectorAll<HTMLFormElement>('form')
        forms.forEach((form) => {
            const elements = form.querySelectorAll('input, textarea, select')
            elements.forEach((el) => {
                // ----- For INPUT elements -----
                if (el instanceof HTMLInputElement) {
                    if (
                        [
                            'button',
                            'submit',
                            'reset',
                            'hidden',
                            'file',
                        ].includes(el.type)
                    )
                        return
                    if (el.type == 'search' && el.closest('.ant-select')) return

                    const fieldName: string = (
                        el.name ||
                        el.id ||
                        ''
                    ).toLowerCase()
                    let dummy: string = ''

                    if (el.type == 'date') {
                        dummy = todayISO
                    } else if (
                        el.placeholder &&
                        el.placeholder.toLowerCase().includes('date')
                    ) {
                        dummy = todayFormatted
                    } else if (
                        el.type == 'email' ||
                        fieldName.includes('mail') ||
                        fieldName.includes('from') ||
                        fieldName.includes('to address') ||
                        fieldName.includes(' cc ') ||
                        fieldName.includes('bcc')
                    ) {
                        dummy = 'imasha@iykons.com'
                    } else if (
                        el.type == 'tel' ||
                        fieldName.includes('phone') ||
                        fieldName.includes('no') ||
                        fieldName.includes('mobile') ||
                        fieldName.includes('home') ||
                        fieldName.includes('work')
                    ) {
                        dummy = '070393344403'
                    } else if (
                        el.type == 'url' ||
                        fieldName.includes('website') ||
                        fieldName.includes('url')
                    ) {
                        dummy = 'http://google.com'
                    } else if (el.type == 'number') {
                        dummy = '12345'
                    } else if (fieldName.includes('name')) {
                        dummy = getSampleName(currentSampleIndex)
                    } else if (fieldName.includes('address')) {
                        dummy = getSampleAddress(currentSampleIndex)
                    } else {
                        dummy = getSampleText(currentSampleIndex)
                    }

                    fillPromises.push(Promise.resolve(simulatePaste(el, dummy)))
                }
                // ----- For TEXTAREA elements -----
                else if (el instanceof HTMLTextAreaElement) {
                    const fieldName: string = (
                        el.name ||
                        el.id ||
                        ''
                    ).toLowerCase()
                    let dummy: string = ''
                    if (fieldName.includes('mail')) {
                        dummy = 'imasha@iykons.com'
                    } else if (fieldName.includes('name')) {
                        dummy = getSampleName(currentSampleIndex)
                    } else if (fieldName.includes('address')) {
                        dummy = getSampleAddress(currentSampleIndex)
                    } else {
                        dummy = getSampleText(currentSampleIndex)
                    }
                    fillPromises.push(Promise.resolve(simulatePaste(el, dummy)))
                }
                // ----- For SELECT elements (native dropdowns) -----
                else if (el instanceof HTMLSelectElement) {
                    const options = Array.from(el.options).filter(
                        (opt) => !opt.disabled
                    )
                    if (options.length) {
                        el.value = options[0].value
                        el.dispatchEvent(new Event('change', { bubbles: true }))
                    }
                }
            })
        })

        await Promise.all(fillPromises)

        // ----- Handle Ant Design Dropdowns -----
        // First, for each Ant Design select container, if the visible portion shows a placeholder,
        // simulate a click on the arrow to open the dropdown.
        const antSelectContainers = document.querySelectorAll<HTMLDivElement>(
            '.ant-select.ant-select-single'
        )
        antSelectContainers.forEach((container) => {
            const placeholderElem = container.querySelector(
                '.ant-select-selection-placeholder'
            )
            if (
                placeholderElem &&
                placeholderElem.textContent
                    ?.trim()
                    .toLowerCase()
                    .includes('please select')
            ) {
                const arrow = container.querySelector('.ant-select-arrow')
                if (arrow) {
                    // Simulate a complete click sequence
                    arrow.dispatchEvent(
                        new MouseEvent('mousedown', { bubbles: true })
                    )
                    arrow.dispatchEvent(
                        new MouseEvent('mouseup', { bubbles: true })
                    )
                    arrow.dispatchEvent(
                        new MouseEvent('click', { bubbles: true })
                    )
                }
            }
        })

        // Wait 500ms to allow the dropdown to render, then select an option.
        setTimeout(() => {
            // Query open dropdowns from document body.
            const openDropdowns = document.querySelectorAll<HTMLDivElement>(
                '.ant-select-dropdown:not(.ant-select-dropdown-hidden)'
            )
            openDropdowns.forEach((dropdown) => {
                const virtualHolder = dropdown.querySelector<HTMLDivElement>(
                    '.rc-virtual-list-holder-inner'
                )
                if (virtualHolder) {
                    const options =
                        virtualHolder.querySelectorAll<HTMLDivElement>(
                            '.ant-select-item-option'
                        )
                    if (options.length) {
                        if (currentSampleIndex % 2 === 0) {
                            options[0].dispatchEvent(
                                new MouseEvent('click', { bubbles: true })
                            )
                        } else {
                            options[options.length - 1].dispatchEvent(
                                new MouseEvent('click', { bubbles: true })
                            )
                        }
                    }
                }
            })
        }, 500)
        handleAntDropdownsWithKeys()
    }
    // Return null if the QA UI is not shown
    if (!showQAUI) return null

    // If minimized, show only a small bar with an "Expand" button
    if (isMinimized) {
        return (
            <div
                ref={recorderRef}
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    background: '#fff',
                    border: '1px solid #ccc',
                    padding: '5px',
                    zIndex: 10000,
                }}
            >
                <button onClick={() => setIsMinimized(false)}>
                    Expand QA Recorder
                </button>
            </div>
        )
    }

    // Otherwise, show the full QA Recorder UI
    return (
        <>
            <div
                ref={recorderRef}
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    background: '#fff',
                    border: '1px solid #ccc',
                    padding: '10px',
                    zIndex: 10000,
                    maxWidth: '350px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                }}
            >
                <h4>QA Bug Recorder</h4>
                <div>
                    <h5>Sprint Board ID (optional):</h5>
                    <input
                        type="text"
                        value={sprintBoardId}
                        onChange={(e) => setSprintBoardId(e.target.value)}
                        placeholder="Enter Sprint Board ID"
                        style={{
                            width: '100%',
                            marginBottom: '10px',
                            padding: '5px',
                            border: '1px solid #ddd',
                        }}
                    />
                </div>
                <div>
                    <h5>Summary (editable):</h5>
                    <textarea
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                        style={{
                            width: '100%',
                            height: '40px',
                            border: '1px solid #ddd',
                            padding: '5px',
                        }}
                    />
                </div>

                <div style={{ marginBottom: '10px' }}>
                    {isRecording ? (
                        <button onClick={stopRecording}>Stop Recording</button>
                    ) : (
                        <button onClick={startRecording}>
                            Start Recording
                        </button>
                    )}
                    <button
                        onClick={captureScreenshot}
                        style={{ marginLeft: '5px' }}
                    >
                        Capture Screenshot
                    </button>
                </div>

                <div>
                    <h5>Auto-Generated Click Log (read-only):</h5>
                    <div
                        style={{
                            width: '100%',
                            height: '150px',
                            border: '1px solid #ddd',
                            padding: '5px',
                            overflowY: 'auto',
                            background: '#f9f9f9',
                        }}
                        dangerouslySetInnerHTML={{ __html: clickLog }}
                    />
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(
                                clickLog.replace(/<[^>]+>/g, '\n')
                            )
                        }}
                    >
                        Copy Log to Clipboard
                    </button>
                    <button onClick={() => setDescription(clickLog)}>
                        Copy Log to Editor
                    </button>
                </div>

                <div>
                    <h5>Description (editable rich text):</h5>
                    <ReactQuill
                        value={description}
                        onChange={setDescription}
                        modules={quillModules}
                        formats={quillFormats}
                        theme="snow"
                        style={{
                            height: 'auto',
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            marginBottom: '20px',
                        }}
                    />
                </div>

                {/* Video */}
                {videoURL && (
                    <div style={{ marginTop: '10px' }}>
                        <h5>Recorded Video:</h5>
                        <video
                            src={videoURL}
                            controls
                            style={{ width: '100%' }}
                        />
                        <a
                            href={videoURL}
                            download="recorded-video.webm"
                            style={{ display: 'block', marginTop: '5px' }}
                        >
                            Download Video
                        </a>
                        <button
                            onClick={removeVideo}
                            style={{ marginTop: '5px' }}
                        >
                            Remove Video
                        </button>
                    </div>
                )}

                {/* Screenshots */}
                {screenshots.length > 0 && (
                    <div style={{ marginTop: 10 }}>
                        <h5>Screenshots:</h5>
                        {screenshots.map((src, index) => (
                            <div key={index} style={{ marginBottom: 10 }}>
                                <img
                                    src={src}
                                    alt={`Screenshot ${index + 1}`}
                                    style={{ width: '100%', marginBottom: 5 }}
                                />
                                <button
                                    onClick={() => {
                                        setAnnotationIndex(index)
                                        setIsAnnotating(true)
                                    }}
                                >
                                    Annotate
                                </button>
                                <button
                                    onClick={() => removeScreenshot(index)}
                                    style={{ marginLeft: 5 }}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                )}

                {/* Buttons: Send to Jira / Reset */}
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <button
                        onClick={sendJiraTicket}
                        disabled={isCreatingTicket}
                        style={{ flex: 1, marginRight: '5px' }}
                    >
                        {isCreatingTicket ? 'Sending...' : 'Send Jira Ticket'}
                    </button>
                    <button onClick={handleReset} style={{ flex: 1 }}>
                        Reset
                    </button>
                </div>

                {/* Auto Fill Button */}
                <div style={{ marginTop: '10px' }}>
                    <button onClick={autoFillForms} style={{ width: '100%' }}>
                        Auto Fill All Forms (QA Testing)
                    </button>
                </div>

                {/* Minimize button */}
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    <button onClick={() => setIsMinimized(true)}>
                        Minimize
                    </button>
                </div>
            </div>

            {/* Steps Popup (if you want to show them after recording stops) */}
            {showStepsPopup && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 11000,
                    }}
                >
                    <div
                        style={{
                            background: '#fff',
                            padding: '20px',
                            borderRadius: '4px',
                            maxHeight: '80vh',
                            overflowY: 'auto',
                            minWidth: '350px',
                        }}
                    >
                        <h4>Steps to Reproduce</h4>
                        <p>
                            Here are the steps (text only) for each element you
                            clicked:
                        </p>

                        {clickSteps.map((step, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <p>
                                    <strong>Step {index + 1}:</strong> {step}
                                </p>
                                <hr
                                    style={{
                                        border: 'none',
                                        borderTop: '1px dashed #ccc',
                                    }}
                                />
                            </div>
                        ))}

                        <div style={{ textAlign: 'right' }}>
                            <button
                                onClick={() => setShowStepsPopup(false)}
                                style={{ marginRight: '10px' }}
                            >
                                Close
                            </button>
                            <button onClick={copyStepsToClipboard}>
                                Copy Steps
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showVideoPreview && videoURL && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 11000,
                    }}
                >
                    <div
                        style={{
                            width: '60vw', // 60% of viewport width
                            height: '60vh', // 60% of viewport height
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                        }}
                    >
                        {/* Close button at the top */}
                        <div
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 1,
                            }}
                        >
                            <button onClick={() => setShowVideoPreview(false)}>
                                Close Preview
                            </button>
                        </div>

                        {/* Main Video Area */}
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '40px', // leave space for the close button
                            }}
                        >
                            <video
                                src={videoURL}
                                controls
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    backgroundColor: '#000',
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {/* Ticket URL Modal */}
            {ticketUrl && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 11000,
                    }}
                >
                    <div
                        style={{
                            background: '#fff',
                            padding: '20px',
                            borderRadius: '4px',
                            textAlign: 'center',
                        }}
                    >
                        <p>Jira ticket created successfully!</p>
                        <a
                            href={ticketUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {ticketUrl}
                        </a>
                        <br />
                        <button
                            onClick={() => setTicketUrl(null)}
                            style={{ marginTop: '10px' }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Annotation Modal */}
            {isAnnotating && annotationIndex !== null && (
                <ScreenshotAnnotationModal
                    screenshotSrc={screenshots[annotationIndex]}
                    onClose={() => {
                        setIsAnnotating(false)
                        setAnnotationIndex(null)
                    }}
                    onSave={(annotatedSrc) => {
                        // Replace the old screenshot with the new one
                        setScreenshots((prev) => {
                            const updated = [...prev]
                            updated[annotationIndex] = annotatedSrc
                            return updated
                        })
                        setIsAnnotating(false)
                        setAnnotationIndex(null)
                    }}
                />
            )}
        </>
    )
}

export default QARecorder
// ─────────────────────────────────────────────────────────────────────────────
// 8) Child Component: ScreenshotAnnotationModal
//    Allows user to draw circles or rectangles on top of a screenshot
// ─────────────────────────────────────────────────────────────────────────────

interface ScreenshotAnnotationModalProps {
    screenshotSrc: string
    onClose: () => void
    onSave: (annotatedSrc: string) => void
}

type ShapeType = 'rect' | 'circle' | 'arrow'

interface Shape {
    type: ShapeType
    startX: number
    startY: number
    endX: number
    endY: number
}
interface ScreenshotAnnotationModalProps {
    screenshotSrc: string
    onClose: () => void
    onSave: (annotatedSrc: string) => void
}

interface Shape {
    type: ShapeType
    startX: number
    startY: number
    endX: number
    endY: number
}

const ScreenshotAnnotationModal: React.FC<ScreenshotAnnotationModalProps> = ({
    screenshotSrc,
    onClose,
    onSave,
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const imgRef = useRef<HTMLImageElement | null>(null)

    // The canvas dimensions (in CSS/display pixels).
    const [canvasWidth, setCanvasWidth] = useState<number>(0)
    const [canvasHeight, setCanvasHeight] = useState<number>(0)

    const [shapes, setShapes] = useState<Shape[]>([])
    const [isDrawing, setIsDrawing] = useState(false)
    const [currentShape, setCurrentShape] = useState<Shape | null>(null)
    const [selectedShapeType, setSelectedShapeType] =
        useState<ShapeType>('rect')

    useEffect(() => {
        // Load the screenshot into an Image object
        const img = new Image()
        img.src = screenshotSrc
        img.onload = () => {
            // Instead of preserving aspect ratio, we forcibly fill 60vw x 60vh.
            const modalWidth = window.innerWidth * 0.6
            const modalHeight = window.innerHeight * 0.6

            // We will simply set the canvas to the full 60% container size:
            setCanvasWidth(modalWidth)
            setCanvasHeight(modalHeight)

            // Keep a reference to the full image so we can draw it
            imgRef.current = img
        }
    }, [screenshotSrc])

    useEffect(() => {
        redrawCanvas()
    }, [shapes, canvasWidth, canvasHeight])

    function redrawCanvas() {
        const canvas = canvasRef.current
        if (!canvas) return
        const ctx = canvas.getContext('2d')
        if (!ctx || !imgRef.current) return

        // Clear the entire canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Draw the image *stretched* to fill the entire canvas
        // ignoring aspect ratio:
        ctx.drawImage(imgRef.current, 0, 0, canvas.width, canvas.height)

        // Now draw any shapes on top
        shapes.forEach((shape) => drawShape(ctx, shape))
    }

    function drawShape(ctx: CanvasRenderingContext2D, shape: Shape) {
        ctx.strokeStyle = 'red'
        ctx.lineWidth = 3
        const { startX, startY, endX, endY } = shape

        if (shape.type === 'rect') {
            ctx.strokeRect(startX, startY, endX - startX, endY - startY)
        } else if (shape.type === 'circle') {
            const radius =
                Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2) / 2
            ctx.beginPath()
            ctx.arc(
                startX + (endX - startX) / 2,
                startY + (endY - startY) / 2,
                radius,
                0,
                2 * Math.PI
            )
            ctx.stroke()
        } else if (shape.type === 'arrow') {
            ctx.beginPath()
            ctx.moveTo(startX, startY)
            ctx.lineTo(endX, endY)
            ctx.stroke()

            // Draw arrowhead
            const arrowSize = 10
            const angle = Math.atan2(endY - startY, endX - startX)
            ctx.beginPath()
            ctx.moveTo(endX, endY)
            ctx.lineTo(
                endX - arrowSize * Math.cos(angle - Math.PI / 6),
                endY - arrowSize * Math.sin(angle - Math.PI / 6)
            )
            ctx.moveTo(endX, endY)
            ctx.lineTo(
                endX - arrowSize * Math.cos(angle + Math.PI / 6),
                endY - arrowSize * Math.sin(angle + Math.PI / 6)
            )
            ctx.stroke()
        }
    }

    function handleMouseDown(e: React.MouseEvent<HTMLCanvasElement>) {
        setIsDrawing(true)
        const rect = e.currentTarget.getBoundingClientRect()
        setCurrentShape({
            type: selectedShapeType,
            startX: e.clientX - rect.left,
            startY: e.clientY - rect.top,
            endX: e.clientX - rect.left,
            endY: e.clientY - rect.top,
        })
    }

    function handleMouseMove(e: React.MouseEvent<HTMLCanvasElement>) {
        if (!isDrawing || !currentShape) return
        const rect = e.currentTarget.getBoundingClientRect()
        setCurrentShape({
            ...currentShape,
            endX: e.clientX - rect.left,
            endY: e.clientY - rect.top,
        })
    }

    function handleMouseUp() {
        if (!isDrawing || !currentShape) return
        setIsDrawing(false)
        setShapes((prev) => [...prev, currentShape!])
        setCurrentShape(null)
    }

    function handleSave() {
        const canvas = canvasRef.current
        if (!canvas) return
        onSave(canvas.toDataURL('image/png'))
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    background: '#fff',
                    borderRadius: 8,
                    width: '60vw',
                    height: '60vh',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 15,
                }}
            >
                <h3>Annotate Screenshot</h3>

                {/* Shape selection toolbar */}
                <div style={{ marginBottom: 10 }}>
                    <button onClick={() => setSelectedShapeType('rect')}>
                        Rectangle
                    </button>
                    <button onClick={() => setSelectedShapeType('circle')}>
                        Circle
                    </button>
                    <button onClick={() => setSelectedShapeType('arrow')}>
                        Arrow
                    </button>
                </div>

                {/* Flexible container for the canvas */}
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <canvas
                        ref={canvasRef}
                        width={canvasWidth}
                        height={canvasHeight}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        style={{
                            border: '1px solid #ccc',
                            cursor: 'crosshair',
                            // Force the canvas to fill the container
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </div>

                {/* Footer buttons */}
                <div style={{ marginTop: 10, textAlign: 'right' }}>
                    <button onClick={onClose} style={{ marginRight: 10 }}>
                        Cancel
                    </button>
                    <button onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    )
}

// ─────────────────────────────────────────────────────────────────────────────
// 9) Utility: Convert an HTML string into Atlassian Document Format (ADF)
// ─────────────────────────────────────────────────────────────────────────────
export function convertHtmlToAdf(html: string): any {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    function convertNode(node: Node): any[] {
        let results: any[] = []

        // TEXT NODE
        if (node.nodeType === Node.TEXT_NODE) {
            const text = node.textContent || ''
            if (text.trim() !== '') {
                results.push({ type: 'text', text })
            }
            return results
        }

        // ELEMENT NODE
        if (node.nodeType === Node.ELEMENT_NODE) {
            const el = node as HTMLElement
            const tag = el.tagName.toLowerCase()
            let childBlocks: any[] = []
            el.childNodes.forEach((child) => {
                childBlocks = childBlocks.concat(convertNode(child))
            })

            switch (tag) {
                case 'p':
                case 'div':
                    if (childBlocks.length === 0) {
                        childBlocks = [{ type: 'text', text: ' ' }]
                    }
                    results.push({ type: 'paragraph', content: childBlocks })
                    break

                case 'br':
                    results.push({ type: 'hardBreak' })
                    break

                case 'h1':
                case 'h2':
                case 'h3':
                case 'h4':
                case 'h5':
                case 'h6': {
                    const level = parseInt(tag.charAt(1), 10) || 1
                    results.push({
                        type: 'heading',
                        attrs: { level },
                        content: childBlocks,
                    })
                    break
                }

                case 'strong':
                case 'b':
                    results = childBlocks.map((block) => {
                        if (block.type === 'text') {
                            block.marks = (block.marks || []).concat([
                                { type: 'strong' },
                            ])
                        }
                        return block
                    })
                    break

                case 'em':
                case 'i':
                    results = childBlocks.map((block) => {
                        if (block.type === 'text') {
                            block.marks = (block.marks || []).concat([
                                { type: 'em' },
                            ])
                        }
                        return block
                    })
                    break

                case 'u':
                    results = childBlocks.map((block) => {
                        if (block.type === 'text') {
                            block.marks = (block.marks || []).concat([
                                { type: 'underline' },
                            ])
                        }
                        return block
                    })
                    break

                case 'del':
                case 's':
                    results = childBlocks.map((block) => {
                        if (block.type === 'text') {
                            block.marks = (block.marks || []).concat([
                                { type: 'strike' },
                            ])
                        }
                        return block
                    })
                    break

                case 'code':
                    if (
                        el.parentElement &&
                        el.parentElement.tagName.toLowerCase() !== 'pre'
                    ) {
                        results = childBlocks.map((block) => {
                            if (block.type === 'text') {
                                block.marks = (block.marks || []).concat([
                                    { type: 'code' },
                                ])
                            }
                            return block
                        })
                    } else {
                        results = childBlocks
                    }
                    break

                case 'pre':
                    results.push({
                        type: 'codeBlock',
                        content: childBlocks.length
                            ? childBlocks
                            : [{ type: 'text', text: el.textContent || '' }],
                    })
                    break

                case 'blockquote':
                    results.push({ type: 'blockquote', content: childBlocks })
                    break

                case 'ul': {
                    const items: any[] = []
                    el.querySelectorAll(':scope > li').forEach((li) => {
                        const converted = convertNode(li)
                        items.push({ type: 'listItem', content: converted })
                    })
                    results.push({ type: 'bulletList', content: items })
                    break
                }

                case 'ol': {
                    const items: any[] = []
                    el.querySelectorAll(':scope > li').forEach((li) => {
                        const converted = convertNode(li)
                        items.push({ type: 'listItem', content: converted })
                    })
                    results.push({
                        type: 'orderedList',
                        attrs: { order: 1 },
                        content: items,
                    })
                    break
                }

                case 'li':
                    // If no block-level node, wrap children in paragraph
                    const hasBlock = childBlocks.some(
                        (b) =>
                            b.type === 'paragraph' ||
                            b.type === 'heading' ||
                            b.type === 'blockquote' ||
                            b.type === 'codeBlock' ||
                            b.type === 'orderedList' ||
                            b.type === 'bulletList'
                    )
                    if (!hasBlock && childBlocks.length > 0) {
                        childBlocks = [
                            { type: 'paragraph', content: childBlocks },
                        ]
                    }
                    results = results.concat(childBlocks)
                    break

                case 'a': {
                    const href = el.getAttribute('href') || ''
                    const title = el.getAttribute('title') || ''
                    results = childBlocks.map((block) => {
                        if (block.type === 'text') {
                            block.marks = (block.marks || []).concat([
                                { type: 'link', attrs: { href, title } },
                            ])
                        }
                        return block
                    })
                    break
                }

                case 'img': {
                    const src = el.getAttribute('src') || ''
                    results.push({ type: 'image', attrs: { src } })
                    break
                }

                case 'table': {
                    const rows: any[] = []
                    el.querySelectorAll('tr').forEach((tr) => {
                        const cells: any[] = []
                        tr.querySelectorAll('th, td').forEach((cell) => {
                            cells.push({
                                type: 'tableCell',
                                content: convertNode(cell),
                            })
                        })
                        rows.push({ type: 'tableRow', content: cells })
                    })
                    results.push({ type: 'table', content: rows })
                    break
                }

                case 'tbody':
                case 'thead':
                case 'tfoot':
                    results = results.concat(childBlocks)
                    break

                case 'tr':
                    results.push({ type: 'tableRow', content: childBlocks })
                    break

                case 'th':
                case 'td':
                    results.push({ type: 'tableCell', content: childBlocks })
                    break

                default:
                    results = results.concat(childBlocks)
                    break
            }
        }
        return results
    }

    const content = convertNode(doc.body)

    const cleanedContent = content.filter((block) => {
        if (block.type === 'paragraph' && block.content) {
            const textNodes = block.content.filter(
                (n: any) => n.type === 'text' && n.text.trim() !== ''
            )
            return textNodes.length > 0
        }
        return true
    })

    return {
        type: 'doc',
        version: 1,
        content: cleanedContent,
    }
}
