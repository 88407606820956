import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Layout, Menu, type MenuProps } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { ConfigProvider } from 'antd'
import { useNavigate } from 'react-router-dom'
import styles from './Less/style.less'
import { ReactComponent as Dashboard } from '../Assest/Svg/Dashboard.svg'
import { ReactComponent as InitialSetup } from '../Assest/Svg/InitialSetup.svg'
import { ReactComponent as ServiceSetup } from '../Assest/Svg/ServiceSetup.svg'
import { ReactComponent as ClientIcon } from '../Assest/Svg/ClientIcon.svg'
import { ReactComponent as MissingInformationIcon } from '../Assest/Svg/MissingInformationIcon.svg'
import { ReactComponent as InvoiceIcon } from '../Assest/Svg/InvoiceIcon.svg'
// import { ReactComponent as AdHocServiceIcon } from '../Assest/Svg/AdHocServiceIcon.svg'
import { ReactComponent as HelpDeskIcon } from '../Assest/Svg/HelpDeskIcon.svg'
import { ReactComponent as HrIcon } from '../Assest/Svg/HrIcon.svg'
import { ReactComponent as UserIcon } from '../Assest/Svg/UserIcon.svg'
import { ReactComponent as ReportIcon } from '../Assest/Svg/ReportIcon.svg'
import { ReactComponent as ClientCommunicationIcon } from '../Assest/Svg/ClientCommunicationIcon.svg'
import { ReactComponent as WikiIcon } from '../Assest/Svg/WikiIcon.svg'
import { ReactComponent as CompanyHouseIcon } from '../Assest/Svg/CompanyHouseIcon.svg'
// import { ReactComponent as HmrcIcon } from '../Assest/Svg/HmrcIcon.svg'
import { type CollapseType } from 'antd/es/layout/Sider'
import ActivePremission from '../Utils/premissins'
import { PermissionType } from '../Types/Enum/PermissionType'
import { connect, useDispatch } from 'react-redux'
import {
    type TableParamsInterface,
    type sideLayoutProps,
} from '../Types/CommonType'
import { type createCategoryParentInterface } from '../Types/CreateCategory'
import { GetParentCategoryss } from '../Services/CreateCatogry'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../Utils/CommontTable'
import { siderClientID } from '../Reducer/DashboardFlagReducer'

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
    }
}
type handleOnCollapseInterface = (a: boolean, b: CollapseType) => void

const { Sider, Header } = Layout

const SiderMenu = (props: {
    handleOnCollapse: handleOnCollapseInterface
    collapsed: boolean
    fixedMenus: number
    clientParentCategory: createCategoryParentInterface[]
}): React.ReactElement => {
    type SiderTheme = 'light' | 'dark'
    const navigate = useNavigate()
    const [collapsedWidth, setCollapsedWidth] = useState('70')
    const [theme, setTheme] = useState<SiderTheme>('light')
    const [tableParams] = useState<TableParamsInterface>(commonTableParams)
    const [isInvoiceIconVisible, setIsInvoiceIconVisible] =
        useState<boolean>(true)
    const dispatch = useDispatch()
    const [fixedMenu, setMenu] = useState<number>(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        return storedMenu != null ? JSON.parse(storedMenu) : 0
    })
    const { fixedMenus, clientParentCategory } = props
    const hasFetchedOnce = useRef(false)
    const parentCategories = clientParentCategory?.filter(
        (category) => category.clientDynCategoryParentId === 0
    )
    const getChildren = (parentId: number): MenuItem[] => {
        const children = clientParentCategory
            .filter(
                (category) => category.clientDynCategoryParentId === parentId
            )
            .map((category) =>
                getItem(
                    <span className={styles.navText}>
                        - {category.clientDynCategoryName}
                    </span>,
                    category.clientDynCategoryId.toString()
                )
            )

        return children
    }

    const clientItems = parentCategories.map((category) => {
        const children = getChildren(category.clientDynCategoryId)

        return getItem(
            <span className={styles.navText}>
                - {category.clientDynCategoryName}
            </span>,
            category.clientDynCategoryId.toString(),
            null,
            children.length > 0 ? children : undefined
        )
    })

    const getTableData = useCallback(() => {
        const { pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetParentCategoryss(dispatch, 0, {
            pageNo,
            pageSize: 1000,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
        hasFetchedOnce.current = false
    }, [getTableData])

    useEffect(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        if (storedMenu != null) {
            setMenu(JSON.parse(storedMenu))
        }
    }, [fixedMenus])

    const items: MenuItem[] = [
        getItem(
            <span className={styles.navText}>Dashboard</span>,
            'dashboard',
            isInvoiceIconVisible ? <Dashboard /> : ''
        ),
        getItem(
            <span className={styles.navText}>System Configuration</span>,
            'systemConfiguration',
            isInvoiceIconVisible ? <InitialSetup /> : '',
            [
                ActivePremission('1000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Group & Partners
                          </span>,
                          'groupAndPartners'
                      )
                    : null,
                ActivePremission('1001', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Bank Details
                          </span>,
                          'bankDetails'
                      )
                    : null,
                ActivePremission('1002', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Departments</span>,
                          'departments'
                      )
                    : null,
                ActivePremission('1003', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Staff Designations
                          </span>,
                          'staffDesignations'
                      )
                    : null,

                ActivePremission('1005', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Leave Type</span>,
                          'leaveType'
                      )
                    : null,
                ActivePremission('1006', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Holiday</span>,
                          'holiday'
                      )
                    : null,
                ActivePremission('1007', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Work Flow</span>,
                          'workFlow'
                      )
                    : null,
                ActivePremission('1004', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Email Template Type
                          </span>,
                          'templateType'
                      )
                    : null,
                ActivePremission('1008', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Email Template
                          </span>,
                          'emailTemplate'
                      )
                    : null,
                getItem(
                    <span className={styles.navText}>- Dashboard News</span>,
                    'dashboardNews'
                ),
                getItem(
                    <span className={styles.navText}>
                        - Project Management
                    </span>,
                    'projectManagement'
                ),
                getItem(
                    <span className={styles.navText}>- Create category</span>,
                    'createCategory'
                ),
            ]
        ),
        getItem(
            <span className={styles.navText}>Service Setup</span>,
            'serviceSetup',
            isInvoiceIconVisible ? <ServiceSetup /> : '',
            [
                ActivePremission('2000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Service User
                          </span>,
                          'serviceUser'
                      )
                    : null,
                ActivePremission('2001', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Client Service User
                          </span>,
                          'clientServiceUser'
                      )
                    : null,
                getItem(
                    <span className={styles.navText}>- Supplier</span>,
                    'supplier'
                ),
            ]
        ),
        ActivePremission('3000', PermissionType.VIEW)
            ? getItem(
                  <span className={styles.navText}>Clients</span>,
                  'clients',
                  isInvoiceIconVisible ? <ClientIcon /> : '',
                  [
                      getItem(
                          <span className={styles.navText}> - Accounting</span>,
                          'accounting',
                          null,
                          [
                              getItem(
                                  <span className={styles.navText}>
                                      - Portfolio
                                  </span>,
                                  'portfolioManager'
                              ),
                              getItem(
                                  <span className={styles.navText}>
                                      - Registered Clients
                                  </span>,
                                  'registeredClients'
                              ),
                              getItem(
                                  <span className={styles.navText}>
                                      - Onboarding Clients
                                  </span>,
                                  'onboardingClients'
                              ),
                              getItem(
                                  <span
                                      className={styles.navText}
                                      onClick={() => {
                                          dispatch(siderClientID(0))
                                      }}
                                  >
                                      - Prospect Clients
                                  </span>,
                                  'prospectClients'
                              ),
                          ]
                      ),
                      ...clientItems,
                  ]
              )
            : null,
        getItem(
            <span className={styles.navText}>Missing Information</span>,
            'missingInformation',
            isInvoiceIconVisible ? <MissingInformationIcon /> : '',
            [
                // getItem(
                //     <span className={styles.navText}>- Awaiting Approval</span>,
                //     'awaitingApproval'
                // ),
                ActivePremission('5000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Property View
                          </span>,
                          'propertyView'
                      )
                    : null,
                ActivePremission('5000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Client View</span>,
                          'clientView'
                      )
                    : null,
            ]
        ),
        getItem(
            <span className={styles.navText}>Invoicing</span>,
            'invoicingMenu',
            isInvoiceIconVisible ? <InvoiceIcon /> : '',
            [
                ActivePremission('6000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Preparation</span>,
                          'invoicePreparation'
                      )
                    : null,
                ActivePremission('6001', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Status</span>,
                          'invoicing'
                      )
                    : null,
                getItem(
                    <span className={styles.navText}>- CC Report</span>,
                    'creditControl',
                    <></>,
                    [
                        getItem(
                            <span className={styles.navText}>
                                - Invoice Type Report
                            </span>,
                            'invoiceTypeReport'
                        ),

                        getItem(
                            <span className={styles.navText}>
                                - Payment Summary
                            </span>,
                            'paidReport'
                        ),
                    ]
                ),
            ]
        ),
        // getItem(
        //     <span className={styles.navText}>Ad Hoc Services</span>,
        //     'adHocServices',
        //     <AdHocServiceIcon />
        // ),
        getItem(
            <span className={styles.navText}>Help Desk</span>,
            'helpDesk',
            isInvoiceIconVisible ? <HelpDeskIcon /> : '',
            [
                ActivePremission('7000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Task</span>,
                          'createTask'
                      )
                    : null,
                // ActivePremission(26, PermissionType.VIEW)
                //   ? getItem(
                //         <span className={styles.navText}>- My Task</span>,
                //          'myTask'
                //     )
                //    : null,
                getItem(
                    <span className={styles.navText}>- Mobile Request</span>,
                    'mobileRequest'
                ),
                getItem(
                    <span className={styles.navText}>- Forms To Clients</span>,
                    'formToClients'
                ),
                getItem(
                    <span className={styles.navText}>- Manage Diary</span>,
                    'manageDiary'
                ),

                getItem(
                    <span className={styles.navText}>- Backlog</span>,
                    'backLog'
                ),
            ]
        ),
        getItem(
            <span className={styles.navText}>HR</span>,
            'hr',
            isInvoiceIconVisible ? <HrIcon /> : '',
            [
                getItem(
                    <span className={styles.navText}>- Leave-Permission</span>,
                    'leavePermission'
                ),
                getItem(
                    <span className={styles.navText}>- Timesheet</span>,
                    'timesheet'
                ),
                ActivePremission('10003', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>- Reports</span>,
                          'hrReports',
                          <></>,
                          [
                              getItem(
                                  <span className={styles.navText}>
                                      - Timesheet
                                  </span>,
                                  'reportTimesheet'
                              ),
                              getItem(
                                  <span className={styles.navText}>
                                      - Leave
                                  </span>,
                                  'reportLeave'
                              ),
                              getItem(
                                  <span className={styles.navText}>
                                      - Permission
                                  </span>,
                                  'reportPermission'
                              ),
                          ]
                      )
                    : null,
            ]
        ),
        ActivePremission('9000', PermissionType.VIEW)
            ? getItem(
                  <span className={styles.navText}>User Management</span>,
                  'userManagement',
                  isInvoiceIconVisible ? <UserIcon /> : '',
                  [
                      getItem(
                          <span className={styles.navText}>
                              - Manage Users
                          </span>,
                          'manageUsers'
                      ),
                      getItem(
                          <span className={styles.navText}>- User Rights</span>,
                          'userRights'
                      ),
                  ]
              )
            : null,
        getItem(
            <span className={styles.navText}>Reports</span>,
            'reports',
            isInvoiceIconVisible ? <ReportIcon /> : '',
            [
                ActivePremission('10000', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Service-Status
                          </span>,
                          'serviceStatus'
                      )
                    : null,
                ActivePremission('10001', PermissionType.VIEW)
                    ? getItem(
                          <span className={styles.navText}>
                              - Service-Client
                          </span>,
                          'serviceClient'
                      )
                    : null,
                getItem(
                    <span className={styles.navText}>- Month Wise</span>,
                    'monthWise'
                ),
                getItem(
                    <span className={styles.navText}>- Missing Info</span>,
                    'missingInfo'
                ),
                getItem(
                    <span className={styles.navText}>- Client-Status</span>,
                    'clientStatus'
                ),
                getItem(
                    <span className={styles.navText}>- Onboarding</span>,
                    'onboarding'
                ),
            ]
        ),
        getItem(
            <span className={styles.navText}>Client Communication</span>,
            'clientCommunication',
            isInvoiceIconVisible ? <ClientCommunicationIcon /> : '',
            [
                getItem(
                    <span className={styles.navText}>- Bulk Mail</span>,
                    'bulkMail'
                ),
                getItem(
                    <span className={styles.navText}>- Bulk SMS</span>,
                    'bulkSms'
                ),
                getItem(
                    <span className={styles.navText}>
                        - Other Business Associates
                    </span>,
                    'otherBusinessAssociates'
                ),
            ]
        ),
        getItem(
            <span className={styles.navText}>WIKI</span>,
            'wiki',
            isInvoiceIconVisible ? <WikiIcon /> : '',
            [
                getItem(
                    <span className={styles.navText}>- Article Group</span>,
                    'articalGroup'
                ),
                getItem(
                    <span className={styles.navText}>- Article</span>,
                    'artical'
                ),
            ]
        ),
        getItem(
            <span className={styles.navText}>Company House</span>,
            'companyHouse',
            isInvoiceIconVisible ? <CompanyHouseIcon /> : '',
            [
                getItem(
                    <span className={styles.navText}>
                        - Company Eligibility
                    </span>,
                    'companyEligibility'
                ),
                getItem(
                    <span className={styles.navText}>- Company Formation</span>,
                    'companyFormation'
                ),
                getItem(
                    <span className={styles.navText}>- Company Overview</span>,
                    'companyOverview'
                ),
                getItem(
                    <span className={styles.navText}>
                        - Confirmation Statement Filing
                    </span>,
                    'confirmationStatementFiling'
                ),
                getItem(
                    <span className={styles.navText}>- CH Filing History</span>,
                    'chFilingHistory'
                ),
            ]
        ),
        // getItem(
        //     <span className={styles.navText}>HMRC</span>,
        //     'hmrc',
        //     isInvoiceIconVisible ? <HmrcIcon /> : '',
        //     [
        //         getItem(
        //             <span className={styles.navText}>- HMRC 64-8</span>,
        //             'hmrc648'
        //         ),
        //         getItem(
        //             <span className={styles.navText}>- VAT Validator</span>,
        //             'vatValidator'
        //         ),
        //     ]
        // ),
    ]
    const handleSiderMenuClick = (action: any): void => {
        window.scrollTo(0, 0)
        const matchingCategory = clientParentCategory?.find(
            (category) => category.clientDynCategoryId.toString() === action.key
        )

        const id = matchingCategory?.clientDynCategoryId ?? 0

        if (matchingCategory != null) {
            if (matchingCategory.clientDynCategoryName === 'Prospect Clients') {
                navigate(`/clients/prospect-clients`)
                dispatch(siderClientID(id))
            }
            if (
                matchingCategory.clientDynCategoryName === 'Registered Clients'
            ) {
                navigate(`/clients/category`)
                dispatch(siderClientID(id))
            }
            if (
                matchingCategory.clientDynCategoryName === 'OnBoarding Clients'
            ) {
                navigate(`/clients/category`)
                dispatch(siderClientID(id))
            }
            return
        }
        switch (action.key) {
            case 'dashboard':
                navigate('/')
                break
            case 'groupAndPartners':
                navigate('/system-configuration/company-group')
                break
            case 'bankDetails':
                navigate('/system-configuration/payment-option')
                break
            case 'departments':
                navigate('/system-configuration/department')
                break
            case 'staffDesignations':
                navigate('/system-configuration/designation')
                break
            case 'templateType':
                navigate('/system-configuration/email_template-type')
                break
            case 'leaveType':
                navigate('/system-configuration/leave-type')
                break
            case 'holiday':
                navigate('/system-configuration/holiday')
                break
            case 'workFlow':
                navigate('/system-configuration/work-flow')
                break
            case 'emailTemplate':
                navigate('/system-configuration/email-template')
                break
            case 'dashboardNews':
                navigate('/system-configuration/news')
                break
            case 'supplierInitial':
                navigate('/system-configuration/supplier')
                break
            case 'otherPerson':
                navigate('/system-configuration/other-person')
                break
            case 'projectManagement':
                navigate('/system-configuration/project-management')
                break
            case 'createCategory':
                navigate('/system-configuration/createCategory')
                break
            case 'serviceUser':
                // navigate('/ServiceConfiguration/ServiceSetup')
                navigate('/service-setup/service-user')
                break
            case 'clientServiceUser':
                // navigate('/ServiceConfiguration/ServiceClient')
                navigate('/service-setup/client-service-user')
                break
            case 'supplier':
                // navigate('/InitialSetup/Supplier')
                navigate('/service-setup/supplier')
                break
            case 'portfolioManager':
                // navigate('/ClientCompany/Index')
                navigate('/clients/portfolio-Manager')
                break
            case 'registeredClients':
                // navigate('/ClientCompany/Index')
                navigate('/clients/registered-clients')
                break
            case 'onboardingClients':
                // navigate('/ClientCompany/Onboard')
                navigate('/clients/onboarding-clients')
                break
            case 'prospectClients':
                // navigate('/Prospect/Index')
                navigate('/clients/prospect-clients')
                break
            case 'newpage':
                // navigate('/Prospect/Index')
                navigate('/clients/newpage')
                break
            case 'awaitingApproval':
                // navigate('/')
                navigate('/missing-information/awaiting-approval')
                break
            case 'propertyView':
                // navigate('/MissedInfo/index')
                navigate('/missing-information/property-view')
                break
            case 'clientView':
                // navigate('/MissedInfo/ClientView')
                navigate('/missing-information/client-view')
                break
            case 'invoicePreparation':
                // navigate('/Invoice/InvoicePreparation')
                navigate('/invoicing/invoice-preparation')
                break
            case 'invoicing':
                // navigate('/Invoice/Index')
                navigate('/invoicing/invoic-status')
                break
            case 'statusReport':
                // navigate('/Report/ReportParam?rt=3')
                navigate('/invoicing/credit-control/status-report')
                break
            case 'clientReport':
                // navigate('Report/ReportParam?rt=4')
                navigate('/invoicing/credit-control/client-report')
                break
            case 'invoiceTypeReport':
                // navigate('/Report/InvoiceServiceList')
                navigate('/invoicing/credit-control/invoice-type-report')
                break
            case 'unsendReport':
                // navigate('/Report/InvoiceUnsend')
                navigate('/invoicing/credit-control/unsend-report')
                break
            case 'unpaidReport':
                // navigate('/Report/InvoiceUnpaid')
                navigate('/invoicing/credit-control/unpaid-report')
                break
            case 'paidReport':
                // navigate('/Report/InvoicePaid')
                navigate('/invoicing/credit-control/paid-report')
                break
            case 'adHocServices':
                // navigate('/ServiceProcess/Other')
                navigate('/ad-hoc-services')
                break
            case 'createTask':
                // navigate('/Task/Index')
                navigate('/help-desk/create-task')
                break
            case 'myTask':
                // navigate('/Task/MyTask')
                navigate('/help-desk/my-task')
                break
            case 'mobileRequest':
                // navigate('/')
                navigate('/help-desk/mobile-request')
                break
            case 'formToClients':
                // navigate('/Management/FormAmendment')
                navigate('/help-desk/forms-to-clients')
                break
            case 'manageDiary':
                // navigate('/Management/ManageDiary')
                navigate('/help-desk/manage-diary')
                break
            case 'backLog':
                // navigate('/help-desk/back-log')
                navigate('/help-desk/sprint')
                break
            case 'leavePermission':
                // navigate('/Leave/index')
                navigate('/hr/leave-permission')
                break
            case 'timesheet':
                // navigate('/Timesheet/index')
                navigate('/hr/time-sheet')
                break
            case 'reportTimesheet':
                // navigate('/Report/ReportParam')
                navigate('/hr/reports/time-sheet')
                break
            case 'reportLeave':
                // navigate('/Report/Leave')
                navigate('/hr/reports/leave')
                break
            case 'reportPermission':
                // navigate('/Report/Permission')
                navigate('/hr/reports/permission')
                break
            case 'manageUsers':
                // navigate('/UserManagement/UserInformation')
                navigate('/user-management/manage-user')
                break
            case 'userRights':
                navigate('/user-management/user-rights')
                break
            case 'serviceStatus':
                // navigate('/Report/ReportParam?rt=1')
                navigate('/report/service-status')
                break
            case 'serviceClient':
                // navigate('/Report/ReportParam?rt=2')
                navigate('/report/service-client')
                break
            case 'monthWise':
                // navigate('/Home/MonthwiseSearch')
                navigate('/report/month-wise')
                break
            case 'missingInfo':
                navigate('/report/missing-info')
                break
            case 'clientStatus':
                navigate('/report/client-status')
                break
            case 'onboarding':
                navigate('/report/onboarding')
                break
            case 'bulkMail':
                // navigate('/Management/BulkMail')
                navigate('/client-comunication/bulk-mail')
                break
            case 'bulkSms':
                // navigate('/Management/BulkSMS')
                navigate('/client-comunication/bulk-sms')
                break
            case 'otherBusinessAssociates':
                // navigate('/InitialSetup/OtherPerson')
                navigate('/client-comunication/other-business-associatiates')
                break
            case 'articalGroup':
                navigate('/wiki/article-group')
                break
            case 'artical':
                navigate('/wiki/article')
                break
            case 'companyEligibility':
                // navigate('/CompanyHouse/Availability')
                navigate('/company-house/company-eligibility')
                break
            case 'companyFormation':
                // navigate('/CompanyHouse/CompanyList')
                navigate('/company-house/company-formation')
                break
            case 'companyOverview':
                // navigate('/ConfirmationStatement/ClientwiseIndividual')
                navigate('/company-house/company-overview')
                break
            case 'confirmationStatementFiling':
                // navigate('/ConfirmationStatement/Clientwise')
                navigate('/company-house/confimation-statement-filing')
                break
            case 'chFilingHistory':
                // navigate('/ConfirmationStatement/FilingHistory')
                navigate('/company-house/ch-filing-history')
                break
            case 'hmrc648':
                // navigate('/')
                navigate('/hrmc/hrmc-64-8')
                break
            case 'vatValidator':
                navigate('/hrmc/vat-validator')
                break
            case 'hmrc':
                navigate('/hmrc/hmrc-page1')
                break
            default:
                navigate('/')
        }
    }

    useEffect(() => {
        if (fixedMenu === 3) {
            setCollapsedWidth('0')
        }
        if (fixedMenu === 0) {
            setCollapsedWidth('70')
            setIsInvoiceIconVisible(true)
            setTheme('light')
        }
        if (fixedMenu === 7) {
            setIsInvoiceIconVisible(false)
        }
        if (fixedMenu === 8) {
            setTheme('dark')
        }
    }, [fixedMenu])

    const menuRef = useRef<HTMLDivElement | null>(null)
    const [scrollPosition, setScrollPosition] = useState(0)

    const scrollLeft = (): void => {
        const container = menuRef.current
        if (container != null) {
            container.scrollBy({ left: -150, behavior: 'smooth' })
            setScrollPosition(container.scrollLeft)
        }
    }

    const scrollRight = (): void => {
        const container = menuRef.current
        if (container != null) {
            container.scrollBy({ left: 150, behavior: 'smooth' })
            setScrollPosition(container.scrollLeft)
        }
    }

    const theme1 = {
        token: {
            colorPrimary: '#006400', // Primary color
            colorBgBase: '#2FB153', // Base background color
            colorTextBase: '#ffffff', // Base text color
            // colorMenuItemSelected: 'black', // Selected menu item color
            // colorMenuItemSelectedBg: '#6aa84f', // Background color of selected menu item
            // colorMenuItemHover: '#e6f7ff', // Hover color for menu items
            // colorMenuItemHoverBg: '#edffe678', // Hover background color for menu items
        },
    }
    return (
        <>
            {fixedMenu === 4 ? (
                <Header className={styles.headerChangedSide}>
                    <div className={styles.menuContainer} ref={menuRef}>
                        <Button
                            icon={<LeftOutlined />}
                            onClick={scrollLeft}
                            disabled={scrollPosition === 0}
                            className={styles.scrollButton}
                        />
                        <Menu
                            mode="horizontal"
                            onClick={handleSiderMenuClick}
                            items={items}
                            className={styles.scrollableMenu}
                        />
                        <Button
                            icon={<RightOutlined />}
                            onClick={scrollRight}
                            className={styles.scrollButton}
                        />
                    </div>
                </Header>
            ) : (
                <ConfigProvider theme={theme1}>
                    <Sider
                        breakpoint="lg"
                        collapsedWidth={collapsedWidth}
                        onCollapse={props.handleOnCollapse}
                        collapsed={props.collapsed}
                        width="320"
                        theme={theme}
                    >
                        <Menu
                            onClick={handleSiderMenuClick}
                            defaultSelectedKeys={['1']}
                            mode="inline"
                            items={items}
                            theme={theme}
                        />
                    </Sider>
                </ConfigProvider>
            )}
        </>
    )
}

const mapStateToProps = (state: any): sideLayoutProps => {
    return {
        fixedMenus: state.dashboardFlag.fixedMenu,
        clientParentCategory: state.initial.clientParentCategory,
    }
}
export default connect(mapStateToProps)(SiderMenu)
