import { Button, Card, Col, Collapse, Flex, Modal, Row } from 'antd'
import React, { useState } from 'react'
import roleStyle from '../../Clients/RegisteredClients/Corporate/Buttons/OfficerButton/ofiicer.less'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import style from '../../ThingToDoViewAll/tingtodo.less'
import AddNewOfficerForm from '../CompanyHouseRelatedThings/OfficerAddNewForm'
import {
    type CompanyDetailsprops,
    type CompanyOfficerInformation,
} from '../../../Types/CompanyHouse/CompanyOverView'
import TeminateOfficer from '../CompanyHouseRelatedThings/TeminateOfficer'

const defaultMemberInfo = {
    altEmailaddress: '',
    referenceId: 0,
    type: '',
    titleOrCompanyName: '',
    firstNameOrBRNo: '',
    middleName: '',
    surName: '',
    dateOfBirth: '',
    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    corrAddressLineOne: '',
    corrAddressLineTwo: '',
    corrTown: '',
    corrCountry: '',
    corrPostCode: '',
    mobileNo: '',
    homeNo: '',
    workNo: '',
    email: '',
    alternativeEmail: '',
    niNumber: '',
    nationality: '',
    occupation: '',
    countryOfResidence: '',
    memberRoleId: 0,
    dateofAppointment: '',
    memberTypeId: 0,
    officerRole: '',
}
const Officers = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId, companyHouseOverViewList } = props
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [teminateOfficerModel, setTeminateOfficerModel] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Officer Details'
    )
    const [memberId, setMemberId] = useState(0)
    const [membreRoleId, setMembreRoleId] = useState(0)
    const [typeId, setTypeId] = useState(0)
    const [editData, setEditData] =
        useState<CompanyOfficerInformation>(defaultMemberInfo)
    const getRoleBackgroundColor = (role: string): React.ReactNode => {
        if (role !== '' && typeof role === 'string') {
            const roles = role.split(',')?.map((r) => r.trim())

            return (
                <div className={roleStyle.roleBadge}>
                    {roles?.map((r, index) => (
                        <span
                            key={index}
                            className={`${roleStyle.roleSpan} ${
                                roleStyle[r.toLowerCase()] ??
                                roleStyle.defaultColor
                            }`}
                        >
                            {r.substring(0, 3)}
                        </span>
                    ))}
                </div>
            )
        }
    }

    const openEditModal = (id: number, typeId: number): void => {
        const data =
            companyHouseOverViewList?.companyOfficerInformationList?.find(
                (data) => {
                    return data.referenceId === id
                }
            )

        let tittle = 'Create Officer Details'
        if (id !== 0) {
            tittle = 'Edit Officer Details'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? defaultMemberInfo)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    const openTeminateOfficerModel = (
        memberId: number,
        membreRole: number,
        typeId: number
    ): void => {
        setMemberId(memberId)
        setMembreRoleId(membreRole)
        setTypeId(typeId)
        setTeminateOfficerModel(!teminateOfficerModel)
    }
    const changeTeminateModalStatus = (): void => {
        setTeminateOfficerModel(!teminateOfficerModel)
    }
    return (
        <>
            <div>
                <h3>Officer Information</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <>
                        <div className={style.misstingInfo}>
                            If you want to do changes required in officers
                            details, should be filed using respectively the
                            <b>&quot;Edit Officer Address&quot;</b>,{' '}
                            <b>&quot;Add New Officer&quot;</b>,
                            <b>&quot;Terminate Officer&quot;</b> pages before
                            you file the confirmation statement.
                        </div>
                        <div
                            style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                            <Flex justify="flex-end" gap={5}>
                                <CusttomBackgroundButton
                                    color={'#175ca9'}
                                    name={'Appoint New Officer'}
                                    icon={<PlusOutlined />}
                                    clickAction={() => {
                                        openEditModal(0, 0)
                                    }}
                                />
                            </Flex>
                        </div>
                    </>
                )}
                {companyHouseOverViewList?.companyOfficerInformationList?.map(
                    (officer, index) => (
                        <Collapse
                            defaultActiveKey={['']}
                            key={index + 1}
                            expandIconPosition={'end'}
                        >
                            <Collapse.Panel
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <span>
                                            {`${officer.titleOrCompanyName} ${officer.firstNameOrBRNo} ${officer.middleName} ${officer.surName}`}
                                        </span>
                                        <span>
                                            {getRoleBackgroundColor(
                                                officer.officerRole
                                            )}
                                        </span>
                                    </div>
                                }
                                key={1}
                            >
                                {companyHousId === 1 && (
                                    <div
                                        style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        <Flex justify="flex-end" gap={5}>
                                            <Button
                                                type="primary"
                                                size="middle"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    openTeminateOfficerModel(
                                                        officer.referenceId,
                                                        officer.memberRoleId,
                                                        officer.memberTypeId
                                                    )
                                                }}
                                            >
                                                Terminate Officer
                                            </Button>

                                            <CusttomBackgroundButton
                                                color={'#175ca9'}
                                                name={'Edit Officer Details'}
                                                icon={<EditOutlined />}
                                                clickAction={() => {
                                                    openEditModal(
                                                        officer.referenceId,
                                                        officer.memberTypeId
                                                    )
                                                }}
                                            />
                                        </Flex>
                                    </div>
                                )}
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Date of Birth</Col>
                                    <Col span={8}>: {officer.dateOfBirth}</Col>
                                    <Col span={5}>Date Of Appointment</Col>
                                    <Col span={6}>
                                        : {officer.dateofAppointment}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Home Address</Col>
                                    <Col span={8}>
                                        : {officer?.addressLineOne}
                                        {officer?.addressLineTwo},
                                        {officer?.town},{officer.postCode},
                                        {officer.country}
                                    </Col>
                                    <Col span={5}>Corr Address</Col>
                                    <Col span={6}>
                                        : {officer.corrAddressLineOne},
                                        {officer.corrAddressLineTwo},
                                        {officer.corrTown},{officer.postCode},
                                        {officer.corrCountry}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Nationality</Col>
                                    <Col span={8}>: {officer.nationality}</Col>
                                    <Col span={5}>Residence Country</Col>
                                    <Col span={6}>
                                        : {officer.countryOfResidence}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Occupation</Col>
                                    <Col span={8}>: {officer.occupation}</Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    )
                )}

                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <AddNewOfficerForm
                        companyName={companyHouseOverViewList?.clientName ?? ''}
                        companyNumber={
                            companyHouseOverViewList?.companyRegNumber ?? ''
                        }
                        corporateId={companyHouseOverViewList?.corporateId ?? 0}
                        onClosemodel={changeCreateEditModalStatus}
                        businessId={companyHouseOverViewList?.clientTypeId ?? 0}
                        memberRoleId={membreRoleId}
                        memberTypeId={typeId}
                        editData={editData}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeTeminateModalStatus}
                    width={'50%'}
                    title={'Termination of Officer'}
                    open={teminateOfficerModel}
                >
                    <TeminateOfficer
                        refernceId={memberId}
                        memberRoleId={membreRoleId}
                        memberTypeId={typeId}
                        onClosemodel={changeTeminateModalStatus}
                        corporateId={companyHouseOverViewList?.corporateId ?? 0}
                        businessId={companyHouseOverViewList?.clientTypeId ?? 0}
                    />
                </Modal>
            </Card>
        </>
    )
}

export default Officers
