import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    GetDashboardResponse,
    GetITDashboardResponse,
    InitialStateInterface,
    PermissionApproval,
} from '../Types/Dashboard'
import type {
    GetOverviewResponse,
    InitialOverviewStateInterface,
} from '../Types/Overview'
import type {
    GeneralEvent,
    InitialEventListByUserStateInterface,
} from '../Types/Event'
import {
    type ThingstoDoAndFollowPaylod,
    type GetServicesDetals,
    type ServiceProcessCheckListResponse,
    type GetServiceProcessHistoryPayload,
    type GetServiceProcessDataPayload,
    type ServiceProcessIdData,
    type GetServiceProcessDetilsPayload,
    type ServiceActionGroup,
} from '../Types/ToDoViewAllList'
import {
    type CommunicationHistory,
    type ProspectClientHistoryPayloadInterface,
} from '../Types/Client/ProspectClients/prospectClient'
import {
    type LeaveRequest,
    type LeaveRequestPayloadInterface,
} from '../Types/ToApproveViewAll/LeaveApproval'
import { type PermissonPayloadInterface } from '../Types/ToApproveViewAll/PermissionApproval'
import {
    type TimesheetApprovalPayloadInterface,
    type TimeSheetData,
} from '../Types/ToApproveViewAll/Timesheet'

export interface TodoIntiallist {
    thingsTodoList: GetServicesDetals[]
    thingsTodoListCount: number
    serviceProcessHistoryList: ServiceProcessCheckListResponse | null
    communicationHistoryList: CommunicationHistory[]
    communicationHistoryListCount: number
    serviceProcessInduvidualData: ServiceProcessIdData | null
    serviceProcessDetailsList: ServiceActionGroup[]
    leaveApproveList: LeaveRequest[]
    leaveApproveListCount: number
    permissionApproveList: PermissionApproval[]
    permissionApproveListCount: number
    timeSheetApproveList: TimeSheetData[]
    timesheetApproveListCount: number
}
const DashboardReducer = createSlice({
    name: 'dashboardInfo',
    initialState: {
        dashboard: null,
        overview: null,
        eventListByUser: [],
        thingsTodoList: [],
        thingsTodoListCount: 0,
        serviceProcessHistoryList: null,
        communicationHistoryList: [],
        communicationHistoryListCount: 0,
        serviceProcessInduvidualData: null,
        serviceProcessDetailsList: [],
        leaveApproveList: [],
        leaveApproveListCount: 0,
        permissionApproveList: [],
        permissionApproveListCount: 0,
        timeSheetApproveList: [],
        timesheetApproveListCount: 0,
        itdashboard: null,
    },
    reducers: {
        dashboard(
            state: InitialStateInterface,
            action: PayloadAction<GetDashboardResponse>
        ) {
            state.dashboard = action?.payload
        },
        overview(
            state: InitialOverviewStateInterface,
            action: PayloadAction<GetOverviewResponse>
        ) {
            state.overview = action?.payload
        },
        eventListByUser(
            state: InitialEventListByUserStateInterface,
            action: PayloadAction<GeneralEvent[] | []>
        ) {
            state.eventListByUser = action?.payload
        },
        thingsTodoList(
            state: TodoIntiallist,
            action: PayloadAction<ThingstoDoAndFollowPaylod>
        ) {
            state.thingsTodoList = action?.payload?.data
            state.thingsTodoListCount = action?.payload?.noOfRecords
        },
        serviceProcessHistory(
            state: TodoIntiallist,
            action: PayloadAction<GetServiceProcessHistoryPayload>
        ) {
            state.serviceProcessHistoryList = action?.payload?.data
        },
        communicationHistoryList(
            state: TodoIntiallist,
            action: PayloadAction<ProspectClientHistoryPayloadInterface>
        ) {
            state.communicationHistoryList = action?.payload?.data
            state.communicationHistoryListCount = action?.payload?.noOfRecords
        },
        serviceProcessInduvidualData(
            state: TodoIntiallist,
            action: PayloadAction<GetServiceProcessDataPayload>
        ) {
            state.serviceProcessInduvidualData = action?.payload?.data
        },
        serviceProcessDetailsList(
            state: TodoIntiallist,
            action: PayloadAction<GetServiceProcessDetilsPayload>
        ) {
            state.serviceProcessDetailsList = action?.payload?.data
        },
        leaveApproveList(
            state: TodoIntiallist,
            action: PayloadAction<LeaveRequestPayloadInterface>
        ) {
            state.leaveApproveList = action?.payload?.data
            state.leaveApproveListCount = action?.payload?.noOfRecords
        },
        permissionApproveList(
            state: TodoIntiallist,
            action: PayloadAction<PermissonPayloadInterface>
        ) {
            state.permissionApproveList = action?.payload?.data
            state.permissionApproveListCount = action?.payload?.noOfRecords
        },
        timeSheetApproveList(
            state: TodoIntiallist,
            action: PayloadAction<TimesheetApprovalPayloadInterface>
        ) {
            state.timeSheetApproveList = action?.payload?.data
            state.timesheetApproveListCount = action?.payload?.noOfRecords
        },
        itdashboard(
            state: InitialStateInterface,
            action: PayloadAction<GetITDashboardResponse>
        ) {
            state.itdashboard = action?.payload
        },
    },
})

export const { dashboard } = DashboardReducer.actions
export const { overview } = DashboardReducer.actions
export const {
    eventListByUser,
    thingsTodoList,
    serviceProcessHistory,
    communicationHistoryList,
    serviceProcessInduvidualData,
    serviceProcessDetailsList,
    leaveApproveList,
    permissionApproveList,
    timeSheetApproveList,
    itdashboard,
} = DashboardReducer.actions
export default DashboardReducer.reducer
