import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import {
    type ProspectClientFormDataInterface,
    type ProspectSmsProps,
} from '../../../../Types/Client/ProspectClients/prospectClient'
import {
    GetInvidualProsPectDetails,
    SaveCommuniCationProspectClient,
} from '../../../../Services/ProspectClient'

const ProspectSms = (props: ProspectSmsProps): React.ReactElement => {
    const {
        prospectClientId,
        changeSMSModalStatus,
        phonecallModelClick,
        setPhoneCallModelClick,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [induvidualSmsDetails, setInduvidualSmsDetails] =
        useState<ProspectClientFormDataInterface | null>(null)
    const [buttonloading, setButtonLoading] = useState(false)
    useEffect(() => {
        const fetchData = (): void => {
            void GetInvidualProsPectDetails(
                dispatch,
                prospectClientId,
                (details: ProspectClientFormDataInterface) => {
                    setInduvidualSmsDetails(details)
                }
            )
        }
        fetchData()
        setPhoneCallModelClick(false)
    }, [prospectClientId, phonecallModelClick])

    useEffect(() => {
        if (
            induvidualSmsDetails !== null &&
            typeof induvidualSmsDetails === 'object'
        ) {
            const newFieldValues = {
                firstName: induvidualSmsDetails?.firstName,
                mobileNumber: induvidualSmsDetails?.mobileNumber,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [induvidualSmsDetails, form])

    const onSave = (): void => {
        void onSaveSms()
    }
    const onSaveSms = async (): Promise<void> => {
        try {
            await form.validateFields()
            setButtonLoading(true)
            const formData = form.getFieldsValue()

            const dataTosave = {
                otherCommunicationHistoryId: 0,
                communicationModeId: 2,
                communicationTypeId: 2,
                businessTypeId: 0,
                referenceId: prospectClientId,
                contactNumber: formData.mobileNumber,
                description: formData.description,
                historyDate: '',
            }

            await SaveCommuniCationProspectClient(dispatch, dataTosave).then(
                (result) => {
                    if (result !== null && result !== undefined) {
                        form.setFieldsValue({
                            description: '',
                        })

                        changeSMSModalStatus()

                        setButtonLoading(false)
                    }
                }
            )
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<ProspectClientFormDataInterface>
                    name="prospectClientId"
                    hidden={true}
                    initialValue={prospectClientId}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your firstName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Phone Number"
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="SMS Content "
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Message',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save">
                            <Button
                                type="primary"
                                onClick={onSave}
                                size="middle"
                                loading={buttonloading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ProspectSms
