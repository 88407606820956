import React, { useEffect, useRef } from 'react'
import commonStyles from '../../../Utils/Common.less'
import { Button, Col, Form, Row, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { type ProspectChatStateInterface } from '../../../Types/Client/ProspectClients/prospectClient'
import { connect, useDispatch } from 'react-redux'
import { GetUserList } from '../../../Services/User'
import { type ModelProps } from '../../../Types/ToDoViewAllList'
import { SetProcessLevelswitchUser } from '../../../Services/ToDoViewAllList'

const SwitchUserPage = (props: ModelProps): React.ReactElement => {
    const {
        userList,
        serviceProcessId,
        changeModalStatus,
        serviceId,
        updateRefetchData,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [dispatch, form])

    const onSave = (): void => {
        void onSaveChat()
    }
    const onSaveChat = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                switchUserType: 2,
                serviceProcessId,
                existUserId: 0,
                newUserId: formData.userId ?? 0,
                existUserState: 0,
                serviceId: serviceId ?? 0,
                newUserState: 0,
            }

            await SetProcessLevelswitchUser(dispatch, dataToSave)
            changeModalStatus()
            if (updateRefetchData !== null && updateRefetchData !== undefined) {
                updateRefetchData()
            }

            form.resetFields()
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="User"
                            name="userId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select at least one User!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                options={userList}
                                size="large"
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                onChange={() => {}}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" size="large" onClick={onSave}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ProspectChatStateInterface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(SwitchUserPage)
