import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
    Card,
    Table,
    Space,
    Tooltip,
    Modal,
    Form,
    Row,
    Col,
    Input,
    Button,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import {
    CheckOutlined,
    MinusCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import calculatePagination from '../../../Utils/Pagination'
import PermissionExpandView from './permissonListExpandview'
import { connect, useDispatch } from 'react-redux'
import { SetApprovalStatus } from '../../../Services/Dashboard'
import {
    type PermissionStateIntrface,
    type PermissionApproveTableData,
} from '../../../Types/ToApproveViewAll/PermissionApproval'
import { GetPermissionApproveList } from '../../../Services/ToApproveList'
import { formatdate } from '../../../Utils/dateFormat'
const { TextArea } = Input
const PermissionApprovalList = (
    props: PermissionStateIntrface
): React.ReactElement => {
    const { permissionApproveList, permissionApproveListCount } = props

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [title, setTitle] = useState('')
    const [isOpenModal, setOpenModal] = useState(false)
    const [approvalRequestId, setApprovalRequestId] = useState<number>(0)
    const [action, setAction] = useState<number>(0)
    const hasFetchedOnce = useRef(false)
    const [buttonLoading, setButtonloading] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetPermissionApproveList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: permissionApproveListCount,
            },
        })
    }, [permissionApproveListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        permissionApproveListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<PermissionApproveTableData> = [
        {
            title: 'Applied By',
            dataIndex: 'appliedBy',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date',
            render: (record) => {
                return <div>{formatdate(record?.startFrom)}</div>
            },
        },
        {
            title: 'Start',
            render: (record) => {
                return <div>{record?.endTime}</div>
            },
        },
        {
            title: 'End',
            render: (record) => {
                return <div>{record?.endTime}</div>
            },
        },
        {
            title: 'Descriptiond',
            dataIndex: 'description',
        },
        {
            title: 'Applied On',
            render: (record) => {
                return <div>{formatdate(record?.appliedOn)}</div>
            },
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Approve Times">
                            <CheckOutlined
                                onClick={() => {
                                    openModal(
                                        'Approved',
                                        record.approvalRequestId,
                                        2
                                    )
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <MinusCircleOutlined
                                onClick={() => {
                                    openModal(
                                        'Rejected',
                                        record.approvalRequestId,
                                        2
                                    )
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PermissionApproveTableData>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: '',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const handleCancel = (): void => {
        setOpenModal(false)
        setTitle('')
    }

    const openModal = (isApprove: string, id: number, action: number): void => {
        form.resetFields()
        setOpenModal(true)
        setApprovalRequestId(id)
        setAction(action)
        setTitle(isApprove)
    }
    const onFinish = (): void => {
        void onFinishAction()
    }
    const onFinishAction = async (): Promise<void> => {
        try {
            setButtonloading(true)
            await form.validateFields()
            if (approvalRequestId !== undefined && approvalRequestId > 0) {
                const isApprove: number = title === 'Approved' ? 1 : 2
                await SetApprovalStatus(
                    dispatch,
                    action,
                    approvalRequestId,
                    isApprove,
                    form.getFieldValue('approvalComment')
                )
                    .then((result) => {
                        if (result !== null && result !== undefined) {
                            getTableData()
                            handleCancel()
                            setButtonloading(false)
                        }
                    })
                    .catch(() => {})
            }
        } catch {}
    }

    const expandableData = (
        record: PermissionApproveTableData
    ): React.ReactElement => {
        return <PermissionExpandView expandData={record} />
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const leaveApproveListss = permissionApproveList?.map((item) => ({
        ...item,
        key: item.approvalRequestId,
    }))
    return (
        <>
            <Card title="Permission Approval" className={commonStyles.card}>
                <SearchInput
                    placeHolder={'Search by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={leaveApproveListss}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
            </Card>
            <Modal
                width={600}
                title={title}
                open={isOpenModal}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Comments"
                                name="approvalComment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter the comment',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={2} offset={21}>
                            <Button
                                type="primary"
                                onClick={onFinish}
                                loading={buttonLoading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): PermissionStateIntrface => {
    return {
        permissionApproveList: state.dashboardInfo.permissionApproveList,
        permissionApproveListCount:
            state.dashboardInfo.permissionApproveListCount,
    }
}

export default connect(mapStateToProps)(PermissionApprovalList)
