import React, { useEffect, useState } from 'react'
import {
    Table,
    Space,
    Tooltip,
    Row,
    Col,
    Select,
    Button,
    Modal,
    Drawer,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    UnorderedListOutlined,
    CommentOutlined,
    MailOutlined,
    MessageOutlined,
    PhoneOutlined,
    UserSwitchOutlined,
    HistoryOutlined,
    CalendarOutlined,
} from '@ant-design/icons'
import {
    type ThingsTodoTableList,
    type VatPageProps,
} from '../../../Types/ToDoViewAllList'
import calculatePagination from '../../../Utils/Pagination'
import SearchInput from '../../../Components/SearchInput'
import style from '../tingtodo.less'
import { useNavigate } from 'react-router-dom'
import StaffCommunication from '../CommunicationModel/staffCommunication'
import ServiceProcessHistory from '../ProcessModel/serviceProcessHistoryModel'
import CommunicationHistory from '../CommunicationModel/CommunicationHistory'
import ToDoPhoneCallModel from '../CommunicationModel/phoneCallModel'
import SMSModelPage from '../CommunicationModel/smsModel'
import MailTempalteModel from '../CommunicationModel/mailModel'
import SwitchUserPage from '../CommunicationModel/switchUserModel'
import ChangeDueDateModel from '../ProcessModel/changedueDateModel'
import { useDispatch } from 'react-redux'
import { servicedataId } from '../../../Reducer/DashboardFlagReducer'

const PayeList = (props: VatPageProps): React.ReactElement => {
    const {
        thingsTodoList,
        thingsTodoListCount,
        setPagesize,
        setPageNumber,
        taskViewId,
        serviceId,
        setDropdownId,
        id,
        setSearchString,
        statusId,
        updateRefetchData,
    } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [dropdownItem, setDropdownItem] = useState(0)
    const [Historymodel, setHistorymodel] = useState(false)
    const [serviceProcessId, setServiceProcessId] = useState<number>(0)
    const [open, setOpen] = useState(false)
    const [clientName, setClientName] = useState('')
    const [datedetails, setDatedetails] = useState('')
    const [comHistorymodel, setCommunicationHistorymodel] = useState(false)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const [PhoneCallModel, setPhoneCallmodel] = useState(false)
    const [SMSModel, setSMSmodel] = useState(false)
    const [mailmodel, setMailmodel] = useState(false)
    const [switchUserModel, setSwitchUserModel] = useState(false)
    const [businessTypeId, setBusinessTypeId] = useState(0)
    const [changeDueDateModel, setchangeDueDateModel] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchData, setSearchData] = useState('')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        setPagesize(itemsPerPage)
        setPageNumber(currentPage)
        if (setDropdownId !== undefined) {
            setDropdownId(dropdownItem)
        }
        setSearchString(searchData)
    }, [currentPage, itemsPerPage, dropdownItem, searchData])

    useEffect(() => {
        setCurrentPage(1)
        setItemsPerPage(10)
    }, [statusId])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        thingsTodoListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const formatdate = (date: string): string => {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()
        return `${day}/${month}/${year}`
    }
    const openHistoryModel = (id: number): void => {
        setServiceProcessId(id)
        setCommunicationHistorymodel(!comHistorymodel)
    }

    const changeCommunictionHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setCommunicationHistorymodel(!comHistorymodel)
    }

    const openPhoneCallModel = (id: number, bIds: number): void => {
        setBusinessTypeId(bIds)
        setServiceProcessId(id)
        setPhoneCallmodel(!PhoneCallModel)
    }

    const changePhoneCallModalStatus = (): void => {
        setPhoneCallmodel(!PhoneCallModel)
    }

    const openSMSModel = (id: number): void => {
        setServiceProcessId(id)
        setSMSmodel(!SMSModel)
    }

    const changeSMSModalStatus = (): void => {
        setSMSmodel(!SMSModel)
    }

    const openMailModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setMailmodel(!mailmodel)
    }

    const changeMailModalStatus = (): void => {
        setMailmodel(!mailmodel)
    }

    const openSwitchUserModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setSwitchUserModel(!switchUserModel)
    }

    const changeSwitchUserModalStatus = (): void => {
        setSwitchUserModel(!switchUserModel)
    }

    const openChangeDuedateModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setchangeDueDateModel(!changeDueDateModel)
    }

    const ChengeDueDateModelClose = (): void => {
        setchangeDueDateModel(!changeDueDateModel)
    }
    const columns: ColumnsType<ThingsTodoTableList> = [
        {
            title: 'Number',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Client',
            key: 'clientName',
            render: (_, record) => {
                return (
                    <div>
                        {record.clientName}
                        <div className={style.currentstatus3}>
                            {record.additionalDataThree}
                        </div>{' '}
                        <br /> Period of
                        {formatdate(record.periodStartDate)} to
                        {formatdate(record.periodEndDate)}
                        <br />
                        {statusId === 1 ? (
                            <span style={{ color: 'red' }}>
                                {record.dueDays} days passed from due date to
                                submit the file
                            </span>
                        ) : statusId === 0 ? (
                            <span
                                style={{
                                    color:
                                        record.dueDays > 10
                                            ? `green`
                                            : `#f9d35d`,
                                }}
                            >
                                {record.dueDays !== 1
                                    ? `${record.dueDays}  days more to submit the file`
                                    : 'Tomorrow is the last due date to submit the file'}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                )
            },
        },
        {
            width: 250,
            title: 'Assign Info',
            key: 'assignTo',
            render: (_, record) => {
                return (
                    <div>
                        Assign by : {record.assignBy} <br /> Assign to :{' '}
                        {record.assignTo} <br />
                        {record.additionalDataOne === 'MONTHLY' ? (
                            <div className={style.AssignInfoMontly}>
                                {record.additionalDataOne}(
                                {record.additionalDataTwo})
                            </div>
                        ) : (
                            <div className={style.currentstatus2}>
                                {record.additionalDataOne}(
                                {record.additionalDataTwo})
                            </div>
                        )}
                        <br /> Assign Date : {formatdate(record.assignDate)}
                        <br />
                        Target Date : {formatdate(record.dueDate)}
                        <br />
                        {id === 2 && taskViewId === 1 && (
                            <Tooltip title={'Change Due Date'}>
                                <CalendarOutlined
                                    onClick={() => {
                                        openChangeDuedateModel(
                                            record.serviceProcessId
                                        )
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Action Status',
            key: 'action',
            render: (_, record: ThingsTodoTableList) => {
                return (
                    <>
                        <div>{record.actionName}</div>
                        {record.currentStatus !== 'Complete' ? (
                            <div className={style.currentstatus}>
                                {record.currentStatus}
                            </div>
                        ) : (
                            <div className={style.currentstatus4}>
                                {record.currentStatus}
                            </div>
                        )}
                        <Space size="middle">
                            <Tooltip title="Service Process">
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        const serviceprocessId =
                                            record.serviceProcessId
                                        dispatch(
                                            servicedataId({
                                                serviceProcesssId:
                                                    serviceprocessId,
                                                serviceId: serviceId ?? 0,
                                                modeId: id ?? 1,
                                            })
                                        )

                                        const servicedataIds = {
                                            serviceProcesssId:
                                                serviceprocessId ?? 0,
                                            serviceId: serviceId ?? 0,
                                            modeId: id ?? 1,
                                        }
                                        localStorage.setItem(
                                            'servicedataId',
                                            JSON.stringify(servicedataIds)
                                        )
                                        navigate(
                                            id === 1
                                                ? `/thing-to-do/service-process`
                                                : `/Things-to-Follow/service-process`
                                        )
                                    }}
                                >
                                    Process
                                </Button>
                            </Tooltip>
                            <Tooltip title="Service Process History">
                                <UnorderedListOutlined
                                    onClick={() => {
                                        onHistoryModelOpen(
                                            record.serviceProcessId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>

                            <Tooltip title="Staff Communication">
                                <CommentOutlined
                                    onClick={() => {
                                        showDrawer(
                                            record.serviceProcessId,
                                            record.clientName,
                                            `Next accounts made up to
                                                
                                                ${record.periodStartDate} due by
                                                ${record.periodEndDate}
                                                `
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                            {taskViewId === 1 && (
                                <>
                                    <Tooltip title={'Switch User'}>
                                        <UserSwitchOutlined
                                            onClick={() => {
                                                openSwitchUserModel(
                                                    record.serviceProcessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                </>
                            )}
                        </Space>
                    </>
                )
            },
        },
        ...(taskViewId === 1
            ? [
                  {
                      title: 'Communication',
                      key: 'action',

                      render: (record: ThingsTodoTableList) => {
                          return (
                              <Space size="middle" direction="vertical">
                                  <span style={{ display: 'block' }}>
                                      {record.lastCommunicationStatus}
                                  </span>

                                  <span>
                                      <Space size="middle">
                                          <Tooltip title="Communication History">
                                              <HistoryOutlined
                                                  onClick={() => {
                                                      openHistoryModel(
                                                          record.serviceProcessId
                                                      )
                                                  }}
                                                  className={
                                                      commonStyles.clickableIcon
                                                  }
                                              />
                                          </Tooltip>
                                          <Tooltip title="Send Mail">
                                              <MailOutlined
                                                  onClick={() => {
                                                      openMailModel(
                                                          record.serviceProcessId
                                                      )
                                                  }}
                                                  className={
                                                      commonStyles.clickableIcon
                                                  }
                                              />
                                          </Tooltip>
                                          <Tooltip title="Send SMS">
                                              <MessageOutlined
                                                  onClick={() => {
                                                      openSMSModel(
                                                          record.serviceProcessId
                                                      )
                                                  }}
                                                  className={
                                                      commonStyles.clickableIcon
                                                  }
                                              />
                                          </Tooltip>
                                          <Tooltip title="Phone Calls">
                                              <PhoneOutlined
                                                  onClick={() => {
                                                      openPhoneCallModel(
                                                          record.serviceProcessId,
                                                          record.businessTypeId
                                                      )
                                                  }}
                                                  className={
                                                      commonStyles.clickableIcon
                                                  }
                                              />
                                          </Tooltip>
                                      </Space>
                                  </span>
                              </Space>
                          )
                      },
                  },
              ]
            : []),
    ]

    const onChangeTableParams: TableProps<ThingsTodoTableList>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
    }

    const mappedData: ThingsTodoTableList[] = thingsTodoList.map((item) => ({
        ...item,
        key: item.serviceProcessId,
    }))

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length === 0) {
            onChangeFilter('')
        } else {
            onChangeFilter(data?.target?.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setSearchData(data)
    }

    const onHistoryModelOpen = (serviceprocesId: number): void => {
        setHistorymodel(!Historymodel)
        setServiceProcessId(serviceprocesId)
    }
    const changeHistoryModalStatus = (): void => {
        setServiceProcessId(0)
        setHistorymodel(!Historymodel)
    }

    const showDrawer = (
        id: number,
        name: string,
        dateDetails: string
    ): void => {
        setServiceProcessId(id)
        setClientName(name)
        setDatedetails(dateDetails)
        setOpen(true)
    }

    const onClose = (): void => {
        setOpen(false)
    }
    const onChangeDropDown = (value: number): void => {
        setDropdownItem(value)
    }
    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <SearchInput
                        placeHolder={'Search by keywords'}
                        onSearch={onChangeFilter}
                        onChange={onChangeText}
                        width="478"
                    />
                </Col>
                <Col span={12}>
                    <Select
                        className={style.payeDrowpDownWidth}
                        value={dropdownItem}
                        onChange={onChangeDropDown}
                        options={[
                            { value: 0, label: 'All' },
                            { value: 1, label: 'Weekly' },
                            { value: 2, label: 'Bi Weekly' },
                            { value: 3, label: 'Four Weekly' },
                            { value: 4, label: 'Monthly' },
                        ]}
                    />
                </Col>
            </Row>

            <div className={commonStyles.table}>
                {thingsTodoListCount > 0 ? (
                    <Table
                        columns={columns}
                        onChange={onChangeTableParams}
                        dataSource={mappedData}
                        pagination={pagination}
                    />
                ) : (
                    <div>No Record Found</div>
                )}
            </div>
            <Modal
                footer={null}
                onCancel={changeHistoryModalStatus}
                width={'60%'}
                title={'Service Process History'}
                open={Historymodel}
            >
                <ServiceProcessHistory serviceprocessId={serviceProcessId} />
            </Modal>
            <Drawer
                title={`${clientName} ,${datedetails}`}
                width={500}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication serviceProcessId={serviceProcessId} />
            </Drawer>
            <Modal
                footer={null}
                onCancel={changeCommunictionHistoryModalStatus}
                width={'50%'}
                title={'Commication History'}
                open={comHistorymodel}
            >
                <CommunicationHistory
                    serviceProcessId={serviceProcessId}
                    seletedButton={selectedButtonId}
                    setButtonID={setSelectedButtonId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changePhoneCallModalStatus}
                width={'35%'}
                title={'Phone Call'}
                open={PhoneCallModel}
            >
                <ToDoPhoneCallModel
                    serviceProcessId={serviceProcessId}
                    changePhoneCallModalStatus={changePhoneCallModalStatus}
                    businessTypeId={businessTypeId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeSMSModalStatus}
                width={'35%'}
                title={'Send SMS'}
                open={SMSModel}
            >
                <SMSModelPage
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeSMSModalStatus}
                    modeId={id}
                />
            </Modal>

            <Modal
                footer={null}
                onCancel={changeMailModalStatus}
                width={'35%'}
                title={'Send Email'}
                open={mailmodel}
            >
                <MailTempalteModel
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeMailModalStatus}
                    serviceId={serviceId}
                    emailPageShow={1}
                    modeId={id}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeSwitchUserModalStatus}
                width={'35%'}
                title={'Switch User'}
                open={switchUserModel}
            >
                <SwitchUserPage
                    serviceId={3}
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeSwitchUserModalStatus}
                    updateRefetchData={updateRefetchData}
                    modeId={id}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={ChengeDueDateModelClose}
                width={'45%'}
                title={`Change Due Date`}
                open={changeDueDateModel}
            >
                <ChangeDueDateModel
                    serviceProcessId={serviceProcessId}
                    changeModelState={ChengeDueDateModelClose}
                />
            </Modal>
        </>
    )
}

export default PayeList
