import React, { useEffect, useRef, useState } from 'react'
import commonStyles from '../Utils/Common.less'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { type ProspectChatInterface } from '../Types/Invoicing/Invoice'
import { connect, useDispatch } from 'react-redux'
import {
    type ProcessDetailChat,
    type DataCHAT,
    type ProspectChatStateInterface,
} from '../Types/Client/ProspectClients/prospectClient'
import { GetUserList } from '../Services/User'
import { GetChat, SaveChat } from '../Services/Common'
import { SaveChatClientStaffChat } from '../Services/GlobelSearch'

const StaffCommunication = (
    props: ProspectChatInterface
): React.ReactElement => {
    const { userList, refernceId, messageTypeId, businessId } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [chatData, setChatData] = useState<DataCHAT | null>(null)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const chatdatarefetch = (): void => {
        void GetChat(
            dispatch,
            messageTypeId,
            refernceId,
            businessId,
            (chatdatass: DataCHAT) => {
                setChatData(chatdatass)
            }
        )
    }

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        chatdatarefetch()
    }, [refernceId, form])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [dispatch, form])

    const onSave = (): void => {
        void onSaveChat()
    }
    const onSaveChat = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()
            if (messageTypeId === 1) {
                const dataToSave = {
                    chatId: 0,
                    businessTypeId: businessId ?? 0,
                    referenceId: refernceId,
                    isClientCommunication: 0,
                    description: formData.message,
                    userIds: formData.userIds,
                }

                await SaveChatClientStaffChat(dispatch, dataToSave)
            } else {
                const dataToSave = {
                    chatId: 0,
                    msgType: messageTypeId,
                    msgReferenceId: refernceId,
                    businessTypeId: businessId,
                    isPinned: 0,
                    description: formData.message,
                    userIds: formData.userIds,
                }

                await SaveChat(dispatch, dataToSave)
            }
            chatdatarefetch()
            form.resetFields(['message', 'userIds'])
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const chatUserData = chatData?.processDetailChatList

    return (
        <div className={commonStyles.main}>
            <div className={commonStyles.scrollContainer}>
                {chatUserData != null && chatUserData?.length > 0 ? (
                    chatUserData?.map((item: ProcessDetailChat) => (
                        <div
                            className={commonStyles.contener}
                            key={item.chatId}
                        >
                            <div className={commonStyles.imagemain}>
                                <img
                                    src={item.imageUrl}
                                    alt="Your Image"
                                    className={commonStyles.image}
                                />
                            </div>
                            <div className={commonStyles.chattextMain}>
                                <p className={commonStyles.chatheding}>
                                    {item.description}
                                </p>
                                <p className={commonStyles.chatsubheading}>
                                    By {item.createdBy}, {item.dateInfo} <br />
                                    {item.userList}
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className={commonStyles.chatheding}>No Records Found</p>
                )}
            </div>
            <div className={commonStyles.formspace}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        size="small"
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Message"
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Message',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        rows={4}
                                        placeholder="Type your message here..."
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Message To"
                                    name="userIds"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select at least one User!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Please select"
                                        options={userList}
                                        size="large"
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={() => {}}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={onSave}
                                >
                                    <SendOutlined
                                        className={commonStyles.buttonimageIcon}
                                    />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any): ProspectChatStateInterface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(StaffCommunication)
