import React from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import {
    GetProtfolioRemarksList,
    setRamrksData,
} from '../../../Services/Portfolio'

interface SaveRemarksData {
    changeModalStatus: () => void
    portfolioId: number
    clientNumber: number
    businessId: number
}
const AddRemarks = (props: SaveRemarksData): React.ReactElement => {
    const { changeModalStatus, portfolioId, clientNumber, businessId } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                portfolioId,
                remarks: formData.remarks,
                businessTypeId: businessId,
                referenceId: clientNumber,
            }

            await setRamrksData(dispatch, dataToSave)
            GetProtfolioRemarksList(
                dispatch,
                portfolioId,
                businessId,
                clientNumber
            )
            changeModalStatus()
            form.resetFields()
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input your Comments',
                            //     },
                            // ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save Remarks">
                            <Button
                                type="primary"
                                onClick={onSave}
                                size="middle"
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddRemarks
