// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchDiv_WzfSL {
  display: flex;
}
.searchStyles_bkAbs input,
.searchStyles_bkAbs button {
  background: #eeeff2;
}
.customSearchButton_tse6k {
  background-color: #1890ff !important;
  /* Ant Design primary blue color */
  border-color: #1890ff;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/searchInput.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;;EAEI,mBAAA;AAAJ;AAGA;EACI,oCAAA;EADF,kCAAkC;EAEhC,qBAAA;EACA,YAAA;AAAJ","sourcesContent":[".searchDiv {\n    display: flex;\n}\n\n.searchStyles input,\n.searchStyles button {\n    background: #eeeff2;\n}\n\n.customSearchButton {\n    background-color: #1890ff !important; /* Ant Design primary blue color */\n    border-color: #1890ff;\n    color: white;\n\n    // &:hover {\n    //     background-color: #40a9ff; /* Lighten the blue on hover */\n    //     border-color: #40a9ff;\n    // }\n\n    // &:focus {\n    //     background-color: #096dd9; /* Darken the blue when focused */\n    //     border-color: #096dd9;\n    // }\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchDiv": `searchDiv_WzfSL`,
	"searchStyles": `searchStyles_bkAbs`,
	"customSearchButton": `customSearchButton_tse6k`
};
export default ___CSS_LOADER_EXPORT___;
