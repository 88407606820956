import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip, Drawer } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import AddNewButton from '../../../../Components/AddNewButton'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import {
    EyeOutlined,
    MessageOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'
import {
    type SoleTraderListInterface,
    type SoleTraderListDataTypeInterface,
    type SoleTraderListStateInterface,
} from '../../../../Types/Client/RegisteredClients/SoleTrader'
import {
    GetSoleTraderList,
    ActiveInactiveSoleTrader,
    DeleteSoleTrader,
} from '../../../../Services/SoleTrader'
import SoleTraderExpandView from './soleTraderExpandView'
import calculatePagination from '../../../../Utils/Pagination'
import PortfolioView from '../../../../Components/viewProfolioDetails'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import StaffCommunication from '../../../../Components/StaffChat'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
import { GetChat } from '../../../../Services/Common'
export const SoleTraderItemDefaultData = {
    soleTraderId: 0,
    clientCategoryId: 0,
    natureOfBusiness: '',
    BusinessTradingName: '',
    clientNumber: '',
    activestatus: '',
    serviceIds: '',
    ContactName: '',
    MobileNumber: '',
    EmailAddress: '',
    AlternativeEmailAddress: '',
    DefaultUser: '',
    DefaultAssigneeUser: '',
}

const SoleTradersList = (
    props: SoleTraderListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        soleTraderList,
        soleTraderListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [soleTraderId, setsoleTraderId] = useState('')

    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [portfolioModel, setportfolioModel] = useState(false)
    const [refernceId, setrefernceId] = useState(0)

    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [resetData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (Number(soleTraderId) !== 0) {
            void GetChat(
                dispatch,
                7,
                Number(soleTraderId),
                2,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [soleTraderId])

    const openChatModal = (soleTraderId: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setsoleTraderId(soleTraderId)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetSoleTraderList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: soleTraderListCount,
            },
        })
    }, [soleTraderListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const viewSoleTraderDetails = (id: string): void => {
        const SoleTraderData = soleTraderList.find(
            (item) => item.soleTraderId === id
        )
        const onbordingId = 0
        navigate(`/clients/registered-clients/soletrader-view`, {
            state: { SoleTraderData, onbordingId },
        })
    }

    const expandableData = (
        record: SoleTraderListDataTypeInterface
    ): React.ReactNode => {
        return <SoleTraderExpandView soleTraderData={record} />
    }

    const columns: ColumnsType<SoleTraderListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: ' Business Trading Name ',
            render: (_, record: SoleTraderListDataTypeInterface) => {
                return (
                    <div>
                        {record.businessTradingName}
                        {record.activestatus !== 'Active' && (
                            <span
                                style={{
                                    backgroundColor: '#f7e9f1',
                                    padding: '3px',
                                    fontSize: '11px',
                                    color: '#fb61a9',
                                    borderRadius: 2,
                                    fontWeight: 500,
                                }}
                            >
                                {record.activestatus}
                            </span>
                        )}
                    </div>
                )
            },
        },
        {
            title: ' Nature Of Business ',
            dataIndex: 'natureOfBusiness',
        },
        {
            title: ' Services ',
            dataIndex: 'serviceIds',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: SoleTraderListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.soleTraderId)
                                    setResetData('true')
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="clients Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    viewSoleTraderDetails(record?.soleTraderId)
                                    localStorage.setItem('solderTapId', '1')
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.soleTraderId,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.soleTraderId
                                        )
                                    }}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title="View Portfolio Details">
                            <UnorderedListOutlined
                                onClick={() => {
                                    openProtfolio(record?.soleTraderId)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<SoleTraderListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        soleTraderListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let soleTraderId = ''
        if (!deleteModalStatus) {
            soleTraderId = id
        }
        setsoleTraderId(soleTraderId)
    }

    const deleteSoleTrader = (): void => {
        DeleteSoleTrader(dispatch, soleTraderId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let soleTraderId = ''
        if (!activeInactiveModalStatus) {
            soleTraderId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setsoleTraderId(soleTraderId)
    }

    const updateActiveInactiveSoleTraders = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveSoleTrader(dispatch, soleTraderId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const createSoleTrader = (id: string): void => {
        const soleTraderData = soleTraderList.find(
            (item) => item.soleTraderId === id
        )
        navigate(`/clients/registered-clients/soletrader-new`, {
            state: { soleTraderData },
        })
    }
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const openProtfolio = (corporateId: string): void => {
        const refernceid = parseInt(corporateId)
        setrefernceId(refernceid)
        setportfolioModel(!portfolioModel)
    }

    return (
        <Card className={commonStyles.card}>
            <SearchInput
                placeHolder={'Search Sole Traders Details by keywords'}
                onSearch={onChangeFilter}
                onChange={onChangeText}
                width="478"
            />
            <div className={commonStyles.AddBtn}>
                <AddNewButton
                    name="Add New"
                    clickAction={() => {
                        createSoleTrader('')
                    }}
                />
            </div>

            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={soleTraderList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Client . Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteSoleTrader}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction('', '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this Client. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactiveSoleTraders}
            />
            <Modal
                title="Portfolio Details"
                open={portfolioModel}
                onCancel={() => {
                    setportfolioModel(!portfolioModel)
                }}
                footer={null}
                width={'50%'}
            >
                <PortfolioView
                    businessId={2}
                    referenceId={refernceId}
                    portfolioId={0}
                />
            </Modal>
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={Number(soleTraderId)}
                    messageTypeId={7}
                    businessId={2}
                    resetdata={resetData}
                    setResetData={setResetData}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (state: any): SoleTraderListStateInterface => {
    return {
        soleTraderList: state.client.soleTraderList,
        soleTraderListCount: state.client.soleTraderListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(SoleTradersList)
