import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTopOnNavigation = (): any => {
    const location = useLocation() // Get the current route
    const [pathname, setPathName] = useState('')

    useEffect(() => {
        // Scroll to the top whenever route changes
        setPathName(location.pathname)
    }, [location.pathname]) // Runs on route change

    useEffect(() => {
        window.scrollTo(0, 0)
        const handlePopState = (): void => {
            window.scrollTo(0, 0) // Ensure it scrolls to top on back/forward
        }

        window.addEventListener('popstate', handlePopState)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [pathname])

    return null
}

export default ScrollToTopOnNavigation
