import React from 'react'
import { Collapse, Form, Input, Button, Row, Col } from 'antd'
import type { FormInstance } from 'antd/es/form'

const { Panel } = Collapse

const HMRC: React.FC = () => {
    const [form] = Form.useForm<FormInstance>()

    const handleCancel = (): void => {
        form.resetFields()
    }

    const handleSave = (): void => {
        form.validateFields()
            .then((values) => {
                console.log('Saved values:', values)
            })
            .catch((info) => {
                console.log('Validation Failed:', info)
            })
    }

    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={16}>
                <Col span={8}>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="PAYE-Client Details" key="1">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{ name: '', email: '' }}
                            >
                                <Form.Item
                                    label="Tax Office Number"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your name" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please enter a valid email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your email" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="default"
                                        onClick={handleCancel}
                                        style={{ marginRight: '8px' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={8}>
                    <Collapse defaultActiveKey={['2']}>
                        <Panel header="CT-Client Details" key="2">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{ name: '', email: '' }}
                            >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your name" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please enter a valid email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your email" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="default"
                                        onClick={handleCancel}
                                        style={{ marginRight: '8px' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={8}>
                    <Collapse defaultActiveKey={['3']}>
                        <Panel header="SA-Client Details" key="3">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{ name: '', email: '' }}
                            >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your name" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'Please enter a valid email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your email" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="default"
                                        onClick={handleCancel}
                                        style={{ marginRight: '8px' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </div>
    )
}

export default HMRC
