// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inviteClient_t3etg {
  color: #175ca9;
  cursor: pointer;
}
.configurationRowWithMargin_mqSLm {
  width: 100%;
  margin-right: 10px;
}
.configurationRow_toUrp {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;AACJ;AAEA;EACI,WAAA;EACA,kBAAA;AAAJ;AAGA;EACI,WAAA;AADJ","sourcesContent":[".inviteClient {\n    color: #175ca9;\n    cursor: pointer;\n}\n\n.configurationRowWithMargin {\n    width: 100%;\n    margin-right: 10px;\n}\n\n.configurationRow {\n    width: 100%;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inviteClient": `inviteClient_t3etg`,
	"configurationRowWithMargin": `configurationRowWithMargin_mqSLm`,
	"configurationRow": `configurationRow_toUrp`
};
export default ___CSS_LOADER_EXPORT___;
