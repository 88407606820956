/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import ScrollMenu from 'react-horizontal-scroll-menu'
import Styles from './Less/DatePickerSlider.less'
import { pad2 } from '../Utils/Common'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

type Select = number | string | undefined | null

const HorizontalCalendar = (props: {
    setDateValue: (value: string) => void
    dateRange: string[]
}): React.ReactElement => {
    const { setDateValue, dateRange } = props
    const [Dates, setDates] = useState<dayjs.Dayjs[]>([])
    const [selected, setSelected] = useState<Select>()
    const currentDay = dayjs().format('D')

    useEffect(() => {
        if (dateRange?.length > 0) {
            const startDate = dayjs(dateRange[0])
            const endDate = dayjs(dateRange[1])
            const days = []
            for (let i = 0; i <= endDate.diff(startDate, 'days'); i++) {
                days.push(dayjs(startDate).add(i, 'days'))
            }
            setDates(days)
        }
    }, [dateRange])

    const MenuItem = ({
        day,
        number,
        selected,
        fullDate,
        key,
    }: any): React.ReactElement => {
        return (
            <div
                className={`${Styles.menuItem} ${Styles.dayItem} ${
                    selected === true ? Styles.active : ''
                }
                ${currentDay === number ? 'today' : null}`}
                key={key}
            >
                <span
                    className={`${Styles.number} ${
                        currentDay === number ? `${Styles.dot}` : ''
                    } `}
                >
                    {pad2(number)}
                </span>
                <span className={Styles.day}>{day}</span>
            </div>
        )
    }

    const Menu = (selected: any): any[] =>
        Dates?.map((day: any, index) => {
            return (
                <MenuItem
                    day={day.format('dd')}
                    number={day.format('D')}
                    fullDate={day.format('YYYY-MM-DD')}
                    key={index}
                    selected={selected}
                />
            )
        })

    const Arrow = ({ icon }: any): React.ReactElement => {
        return (
            <div className={Styles.arrowContainer}>
                <div className={Styles.button}>{icon}</div>
            </div>
        )
    }
    const ArrowLeft = Arrow({ icon: <LeftOutlined /> })
    const ArrowRight = Arrow({ icon: <RightOutlined /> })
    const menu = Menu(selected)

    const onSelect = (key: Select): void => {
        const findObj = menu?.find((item) => item?.key === key)?.props
        if (findObj !== undefined && findObj !== null) {
            const date = findObj?.fullDate
            setDateValue(date)
            if (key === selected) {
                setSelected(0)
            } else {
                setSelected(key)
            }
        }
    }

    return (
        <div className={Styles.formatContainer}>
            <ScrollMenu
                data={menu}
                arrowLeft={ArrowLeft}
                arrowRight={ArrowRight}
                selected={selected as string}
                onSelect={onSelect}
                scrollToSelected={true}
            />
        </div>
    )
}

export default HorizontalCalendar
