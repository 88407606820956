import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { setLoadingStatus } from '../Reducer/Common'
import { ErrorMessage } from '../Utils/Notification'
import {
    clientsericestausList,
    clientstausList,
    missingreportStatusList,
    monthwiseList,
    onboardingstausList,
    reportStatusList,
} from '../Reducer/Reports'
import { type ReportServiceStatus } from '../Types/Reports'
import handleError from '../Config/AxiosResponceStatus'

export const GetServiceStatusReport = async (
    dispatch: Dispatch,
    data: ReportServiceStatus
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(`/api/Report/GetServiceStatus`, data)

        if (response.status === 200) {
            dispatch(reportStatusList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetMissingReportData = async (
    dispatch: Dispatch,
    MissingFieldId: number,
    BusinessType: number,
    clientCategoryId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.get(
            `/api/Report/GetMissedInfo/${MissingFieldId}/${BusinessType}/0/${clientCategoryId}`
        )

        if (response.status === 200) {
            dispatch(missingreportStatusList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetclientstatusReportData = async (
    dispatch: Dispatch,

    clientCategoryId: number,

    BusinessType: number,
    flagId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.get(
            `/api/Report/GetClientStatus/${clientCategoryId}/${BusinessType}/${flagId}`
        )

        if (response.status === 200) {
            dispatch(clientstausList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
export const GetonboardingstatusReportData = async (
    dispatch: Dispatch,

    clientCategoryId: number,

    BusinessType: number,
    flagId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.get(
            `/api/Report/GetOnboardingClientStatus/${clientCategoryId}/${BusinessType}/${flagId}`
        )

        if (response.status === 200) {
            dispatch(onboardingstausList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
export const GetserviceclientReportData = async (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number | null
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.get(
            `/api/Report/GetServiceByClient/${businessTypeId}/${referenceId}`
        )

        if (response.status === 200) {
            dispatch(clientsericestausList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const Getmonthwise = async (
    dispatch: Dispatch,
    searchData: object
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    await axios
        .post(`api/Dashboard/GetDashboardMonthWise`, searchData)
        .then((response) => {
            if (response.status === 200) {
                dispatch(monthwiseList({ ...response.data }))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(handleError(response.data.status))
            }
        })
        .catch(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
