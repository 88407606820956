import React, { type ReactElement, useEffect, useState } from 'react'
import { Col, Row, Form, Collapse, Button, Card, Tooltip } from 'antd'
import type {
    CorporateHomeApi,
    HomeProps,
    HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { EditOutlined, EyeFilled, SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../../../Utils/Common.less'
import InitialDetailsForm from './InitialDetailsForm'
import RegisterAddressForm from './RegisterAddressForm'
import BussinessAddressForm from './BussinessAddressForm'
import SicCode from './SicCode'
import CorresponseAddressForm from './CorresponseAddressForm'
import {
    getClientCompanyDetails,
    setBasic,
} from '../../../../../../Services/Corporate'
import moment from 'moment'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const HomeList = (props: HomeProps): ReactElement => {
    const { corporateTaps, corporateId } = props
    const [form] = Form.useForm()
    const { Panel } = Collapse
    const [showHomeForm] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const [openPanel, setActivePanel] = useState<string | string[]>(['1'])
    const [isEditing, setIsEditing] = useState(false)
    const [isCorrEditing, setIsCorrEditing] = useState(false)
    const [isCorrBussEditing, setIsCorrBussEditing] = useState(false)
    const [selectedDateFromChild, setSelectedDateFromChild] =
        useState<string>('')
    const [selectCountry, setSelectedCountry] = useState<string>('')
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>()

    const handleDateChangeFromChild = (dateString: string): void => {
        setSelectedDateFromChild(dateString)
    }

    const handleCategorySelect = (id: number): void => {
        setSelectedCategoryId(id)
    }

    const handleDateChangeCountry = (country: string): void => {
        setSelectedCountry(country)
    }

    const togglePanel = (key: string | string[]): void => {
        if (typeof key === 'string') {
            setActivePanel([key])
        } else {
            setActivePanel(key)
        }
    }

    const toggleEditing = (): void => {
        setIsEditing(!isEditing)
    }

    const coresToggleEditing = (): void => {
        setIsCorrEditing(!isCorrEditing)
        setIsCorrBussEditing(false)
    }

    const coresBussToggleEditing = (): void => {
        setIsCorrBussEditing(!isCorrBussEditing)
        setIsCorrEditing(false)
    }

    const updatePage = (): void => {
        setEditMode(true)
        form.submit()
    }
    const viewPage = (): void => {
        setEditMode(false)
    }

    useEffect(() => {
        form.setFieldsValue({
            busaddress1: isEditing
                ? corporateTaps?.registerOfficeAddress?.regaddress1
                : corporateTaps?.businessAddress?.busaddress1,
            busaddress2: isEditing
                ? corporateTaps?.registerOfficeAddress?.regaddress2
                : corporateTaps?.businessAddress?.busaddress2,
            bustown: isEditing
                ? corporateTaps?.registerOfficeAddress?.regtown
                : corporateTaps?.businessAddress?.bustown,
            buscountry: isEditing
                ? corporateTaps?.registerOfficeAddress?.regcountry
                : corporateTaps?.businessAddress?.buscountry,
            buspostcode: isEditing
                ? corporateTaps?.registerOfficeAddress?.regpostcode
                : corporateTaps?.businessAddress?.buspostcode,
        })
    }, [isEditing, form, corporateTaps])

    useEffect(() => {
        form.setFieldsValue({
            corraddress1: isCorrEditing
                ? corporateTaps?.registerOfficeAddress?.regaddress1
                : corporateTaps?.correspondanceAddress?.corraddress1,
            corraddress2: isCorrEditing
                ? corporateTaps?.registerOfficeAddress?.regaddress2
                : corporateTaps?.correspondanceAddress?.corraddress2,
            corrtown: isCorrEditing
                ? corporateTaps?.registerOfficeAddress?.regtown
                : corporateTaps?.correspondanceAddress?.corrtown,
            corrcountry: isCorrEditing
                ? corporateTaps?.registerOfficeAddress?.regcountry
                : corporateTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: isCorrEditing
                ? corporateTaps?.registerOfficeAddress?.regpostcode
                : corporateTaps?.correspondanceAddress?.corrpostcode,
        })
    }, [isCorrEditing, form, corporateTaps])

    useEffect(() => {
        form.setFieldsValue({
            corraddress1: isCorrBussEditing
                ? corporateTaps?.businessAddress?.busaddress1
                : corporateTaps?.correspondanceAddress?.corraddress1,
            corraddress2: isCorrBussEditing
                ? corporateTaps?.businessAddress?.busaddress2
                : corporateTaps?.correspondanceAddress?.corraddress2,
            corrtown: isCorrBussEditing
                ? corporateTaps?.businessAddress?.bustown
                : corporateTaps?.correspondanceAddress?.corrtown,
            corrcountry: isCorrBussEditing
                ? corporateTaps?.businessAddress?.buscountry
                : corporateTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: isCorrBussEditing
                ? corporateTaps?.businessAddress?.buspostcode
                : corporateTaps?.correspondanceAddress?.corrpostcode,
        })
    }, [isCorrBussEditing, form, corporateTaps])

    useEffect(() => {
        form.setFieldsValue({
            corporateid: corporateTaps?.initialDetails?.corporateid ?? 0,
            clienttypeid: corporateTaps?.initialDetails?.clienttypeid ?? 0,
            authenticationcode:
                corporateTaps?.initialDetails?.authenticationcode,
            companytradingname:
                corporateTaps?.initialDetails?.companytradingname,
            tradecommencementdate:
                corporateTaps?.initialDetails?.tradecommencementdate != null
                    ? moment(
                          corporateTaps?.initialDetails?.tradecommencementdate
                      )
                    : null,
            turnover: corporateTaps?.initialDetails?.turnover,
            busaddress1: corporateTaps?.businessAddress?.busaddress1,
            busaddress2: corporateTaps?.businessAddress?.busaddress2,
            bustown: corporateTaps?.businessAddress?.bustown,
            buscountry: corporateTaps?.businessAddress?.buscountry,
            buspostcode: corporateTaps?.businessAddress?.buspostcode,
            corraddress1: corporateTaps?.correspondanceAddress?.corraddress1,
            corraddress2: corporateTaps?.correspondanceAddress?.corraddress2,
            corrtown: corporateTaps?.correspondanceAddress?.corrtown,
            corrcountry: corporateTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: corporateTaps?.correspondanceAddress?.corrpostcode,
            regaddress1: corporateTaps?.registerOfficeAddress?.regaddress1,
            regaddress2: corporateTaps?.registerOfficeAddress?.regaddress2,
            regtown: corporateTaps?.registerOfficeAddress?.regtown,
            regcountry: corporateTaps?.registerOfficeAddress?.regcountry,
            regpostcode: corporateTaps?.registerOfficeAddress?.regpostcode,
        })
    }, [corporateTaps])

    const handleFormSubmit = async (): Promise<void> => {
        const values = await form.getFieldsValue()
        const apiPayload = {
            ...values,
            corporateid: corporateTaps?.initialDetails?.corporateid ?? 0,
            clienttypeid: corporateTaps?.initialDetails?.clienttypeid ?? 0,
            authenticationcode:
                values.authenticationcode ??
                corporateTaps?.initialDetails?.authenticationcode,
            companytradingname:
                values.companytradingname ??
                corporateTaps?.initialDetails?.companytradingname,
            tradecommencementdate:
                selectedDateFromChild ??
                corporateTaps?.initialDetails?.tradecommencementdate,
            turnover:
                values.turnover ?? corporateTaps?.initialDetails?.turnover,
            busaddress1:
                values.busaddress1 ??
                corporateTaps?.businessAddress?.busaddress1,
            busaddress2:
                values.busaddress2 ??
                corporateTaps?.businessAddress?.busaddress2,
            bustown: values.bustown ?? corporateTaps?.businessAddress?.bustown,
            buscountry:
                selectCountry ?? corporateTaps?.businessAddress?.buscountry,
            buspostcode:
                values.buspostcode ??
                corporateTaps?.businessAddress?.buspostcode,
            corraddress1:
                values.corraddress1 ??
                corporateTaps?.correspondanceAddress?.corraddress1,
            corraddress2:
                values.corraddress2 ??
                corporateTaps?.correspondanceAddress?.corraddress2,
            corrtown:
                values.corrtown ??
                corporateTaps?.correspondanceAddress?.corrtown,
            corrcountry:
                values.corrcountry ??
                corporateTaps?.correspondanceAddress?.corrcountry,
            corrpostcode:
                values.corrpostcode ??
                corporateTaps?.correspondanceAddress?.corrpostcode,
            clientcategoryid: selectedCategoryId ?? 0,
        }
        void dataToSend(apiPayload)
    }

    const tradeCommencementDate =
        corporateTaps?.initialDetails?.tradecommencementdate != null
            ? moment(corporateTaps.initialDetails.tradecommencementdate).format(
                  'YYYY-MM-DD'
              )
            : null

    const dateofincorporation =
        corporateTaps?.initialDetails?.dateofincorporation != null
            ? moment(corporateTaps.initialDetails.dateofincorporation).format(
                  'YYYY-MM-DD'
              )
            : null
    const dataToSend = async (apiPayload: CorporateHomeApi): Promise<void> => {
        try {
            await setBasic(dispatch, apiPayload)
            await getClientCompanyDetails(dispatch, 1, corporateId, 1)
            setEditMode(false)
        } catch (error) {
            console.error('Error in dataToSend:', error)
        }
    }

    const termSignedDate = corporateTaps?.dateOfTermSigned
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const formatDate = (dateString: string | null): string => {
        if (dateString == null) {
            return 'No Date Available' // Handle null or undefined values
        }

        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
            return 'Invalid Date' // Handle cases where the date is not valid
        }

        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
        const year = date.getFullYear()

        return `${day}/${month}/${year}`
    }

    return (
        <Card className={commonStyles.card}>
            <Form form={form} requiredMark={customizeRequiredMark}>
                <div>
                    <div className={commonStyles.homeListBasic}>
                        <div>
                            <h3>Basic Information</h3>
                        </div>
                        <div>
                            {!showHomeForm &&
                            !editMode &&
                            ActivePremission('3000', PermissionType.UPDATE) ? (
                                <Tooltip title="Edit Basic Information">
                                    <Button type="primary" onClick={updatePage}>
                                        <EditOutlined />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {editMode && (
                                <Tooltip title="View Basic Information">
                                    <Button type="primary" onClick={viewPage}>
                                        <EyeFilled />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    <div>
                        {termSignedDate !== '01-01-1900' && (
                            <h4>
                                Term signed is activated on {termSignedDate}{' '}
                                this company
                            </h4>
                        )}
                    </div>
                </div>

                <Row gutter={16}>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion={true}
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'1'} header={'Initial Details'}>
                                {editMode ? (
                                    <InitialDetailsForm
                                        corporateTaps={corporateTaps}
                                        onDateChange={handleDateChangeFromChild}
                                        onCategorySelect={handleCategorySelect}
                                    />
                                ) : (
                                    <div
                                        className={commonStyles.corporateInform}
                                    >
                                        <div
                                            className={
                                                commonStyles.corporateBasicDetails
                                            }
                                        >
                                            <p>Client Category:</p>
                                            <p>Date of Incorporation:</p>
                                            <p>Company Trading Name:</p>
                                            <p>Trade Commencement Date:</p>
                                            <p>Expected Turn Over:</p>
                                            <p>Authentication Code:</p>
                                        </div>
                                        <div
                                            className={
                                                commonStyles.corporateBasicDetails
                                            }
                                        >
                                            <p>
                                                :{' '}
                                                {
                                                    corporateTaps
                                                        ?.initialDetails
                                                        ?.clientcategory
                                                }
                                            </p>
                                            <p>
                                                :{' '}
                                                {formatDate(
                                                    dateofincorporation
                                                )}
                                            </p>
                                            <p>
                                                :{' '}
                                                {
                                                    corporateTaps
                                                        ?.initialDetails
                                                        ?.companytradingname
                                                }
                                            </p>
                                            <p>
                                                :{' '}
                                                {formatDate(
                                                    tradeCommencementDate
                                                )}
                                            </p>
                                            <p>
                                                :{' '}
                                                {
                                                    corporateTaps
                                                        ?.initialDetails
                                                        ?.turnover
                                                }
                                            </p>
                                            <p>
                                                :{' '}
                                                {
                                                    corporateTaps
                                                        ?.initialDetails
                                                        ?.authenticationcode
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion={true}
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'2'} header={'Register Office Address'}>
                                {editMode ? (
                                    <>
                                        <RegisterAddressForm />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                commonStyles.corporateInform
                                            }
                                        >
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>Address 1</p>
                                                <p>Address 2</p>
                                                <p>Town</p>
                                                <p>Country</p>
                                                <p>Post Code</p>
                                            </div>
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>
                                                    :{' '}
                                                    {
                                                        corporateTaps
                                                            ?.registerOfficeAddress
                                                            ?.regaddress1
                                                    }
                                                </p>
                                                <p>
                                                    :{' '}
                                                    {
                                                        corporateTaps
                                                            ?.registerOfficeAddress
                                                            ?.regaddress2
                                                    }
                                                </p>
                                                <p>
                                                    :{' '}
                                                    {
                                                        corporateTaps
                                                            ?.registerOfficeAddress
                                                            ?.regtown
                                                    }
                                                </p>
                                                <p>
                                                    :{' '}
                                                    {
                                                        corporateTaps
                                                            ?.registerOfficeAddress
                                                            ?.regcountry
                                                    }
                                                </p>
                                                <p>
                                                    :{' '}
                                                    {
                                                        corporateTaps
                                                            ?.registerOfficeAddress
                                                            ?.regpostcode
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'3'} header={'SIC Codes'}>
                                {editMode ? (
                                    <>
                                        <SicCode />
                                    </>
                                ) : (
                                    <>
                                        <Row gutter={16}>
                                            <Col span={8}> SIC Code1</Col>
                                            <Col span={16}>
                                                :
                                                {corporateTaps?.sicCodes?.[0] ??
                                                    ''}
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={8}>SIC Code2</Col>
                                            <Col span={16}>
                                                :
                                                {corporateTaps?.sicCodes?.[1] ??
                                                    ''}
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={8}>SIC Code3</Col>
                                            <Col span={16}>
                                                :
                                                {corporateTaps?.sicCodes?.[2] ??
                                                    ''}
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={8}>SIC Code4</Col>
                                            <Col span={16}>
                                                :
                                                {corporateTaps?.sicCodes?.[3] ??
                                                    ''}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'4'} header={'Bussiness Address'}>
                                {editMode ? (
                                    <>
                                        <BussinessAddressForm
                                            isEditing={isEditing}
                                            toggleEditing={toggleEditing}
                                            onDateChange={
                                                handleDateChangeCountry
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                commonStyles.corporateInform
                                            }
                                        >
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>Address 1</p>
                                                <p>Address 2</p>
                                                <p>Town</p>
                                                <p>Country</p>
                                                <p>Post Code</p>
                                            </div>
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.businessAddress
                                                                ?.busaddress1
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.businessAddress
                                                                ?.busaddress2
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.businessAddress
                                                                ?.bustown
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.businessAddress
                                                                ?.buscountry
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.businessAddress
                                                                ?.buspostcode
                                                        }
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}></Col>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'5'} header={'Correspondance Address'}>
                                {editMode ? (
                                    <>
                                        <CorresponseAddressForm
                                            isEditing={isCorrEditing}
                                            toggleEditing={coresToggleEditing}
                                            toggleCorrBussEditing={
                                                coresBussToggleEditing
                                            }
                                            onDateChange={
                                                handleDateChangeCountry
                                            }
                                            edit={isCorrBussEditing}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                commonStyles.corporateInform
                                            }
                                        >
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>Address 1</p>
                                                <p>Address 2</p>
                                                <p>Town</p>
                                                <p>Country</p>
                                                <p>Post Code</p>
                                            </div>
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.correspondanceAddress
                                                                ?.corraddress1
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.correspondanceAddress
                                                                ?.corraddress2
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.correspondanceAddress
                                                                ?.corrtown
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.correspondanceAddress
                                                                ?.corrcountry
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            corporateTaps
                                                                ?.correspondanceAddress
                                                                ?.corrpostcode
                                                        }
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Collapse />
                {editMode && (
                    <div className={commonStyles.homeListBasic}>
                        <div></div>

                        <div>
                            {ActivePremission('3000', PermissionType.SAVE) ? (
                                <Tooltip title="Save Basic Information">
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            handleFormSubmit()
                                                .then(() => {
                                                    console.log(
                                                        'Form submitted successfully'
                                                    )
                                                })
                                                .catch((error: any) => {
                                                    console.error(
                                                        'Error submitting form:',
                                                        error
                                                    )
                                                })
                                        }}
                                    >
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )}
            </Form>
        </Card>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(HomeList)
