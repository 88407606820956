import dayjs from 'dayjs'

export const utilDate = (date: string | Date, format?: string): string => {
    if (dayjs(date.toString()).isValid()) {
        return dayjs(date).format(format ?? 'DD-MM-YYYY HH:mm:ss')
    } else {
        return ''
    }
}

export function extractDate(dateString: string): string {
    const dateWithoutTime = dateString?.replace(/\s\d{1,2}:\d{2}(AM|PM)$/, '')
    return dateWithoutTime
}
