import React from 'react'
import commontStyles from '../../../Utils/Common.less'
import { Row } from 'antd'
import { type TaskHours } from '../../../Types/HelpDesk/Cretetask'
import taskCss from './createaskcss.less'
interface expandOption {
    hourData: TaskHours
}
export const CreateHoursExpandOption = (
    props: expandOption
): React.ReactElement => {
    const { hourData } = props
    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Description -:{' '}
                <div className={taskCss.wrap_text}>{hourData.description}</div>
            </Row>
        </div>
    )
}
