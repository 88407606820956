import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    type DatePickerProps,
    TimePicker,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import dayjs, { type Dayjs } from 'dayjs'
import {
    type statustPropety,
    type changeProcessModelprops,
    type changeProcessModelProps,
    type PinedMessage,
} from '../../../Types/ToDoViewAllList'
import {
    GetServiceProcessInduvidual,
    SaveChangePrgress,
} from '../../../Services/ToDoViewAllList'
import { formatdate } from '../../../Utils/dateFormat'
import ReactQuill from 'react-quill'
interface ProcessRemarkProps extends changeProcessModelprops {
    auditdata?: PinedMessage | null
}
const ChangeProgressModel = (props: ProcessRemarkProps): React.ReactElement => {
    const {
        auditdata,
        serviceProcessId,
        serviceProcessInduvidualData,
        ServiceProcesName,
        serviceActionId,
        changeModelState,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [hourValue, setHourValue] = useState<Dayjs | null>(null)
    const [minuteValue, setMinuteValue] = useState<Dayjs | null>(null)
    const data =
        auditdata?.description !== undefined ? auditdata?.description : ''
    const [emailText, setEmailText] = useState('')
    const [internalaudit, setintrnalAudit] = useState('')
    const [buttonLoading, setButtonloading] = useState(false)
    const onChangeHour = (time: Dayjs, timeString: string | string[]): void => {
        setHourValue(time)
        void form.validateFields(['workdhours'])
    }
    form.setFieldValue(
        'Auditdescription',
        '<p><span style="color: rgb(33, 37, 41);letter-spacing: 0.1px;font-size: 14px;"><b><u>1.Assumptions</u></b></span></p><p><br></></p><p><span style="color: rgb(33, 37, 41);letter-spacing: 0.1px;font-size: 14px;"><b><u>2.Recommendation</u></b></span></p><p><br></></p><p><span style="color: rgb(33, 37, 41);letter-spacing: 0.1px;font-size: 14px;"><b><u>3.Actions to be taken</u></b></span></p><p><br></></p>'
    )
    useEffect(() => {
        if (ServiceProcesName === 'Internal Audit') {
            setintrnalAudit(ServiceProcesName)
        }
    }, [ServiceProcesName])
    useEffect(() => {
        form.setFieldValue('Auditdescription', data)
    }, [auditdata])

    const onChangeMinute = (
        time: Dayjs,
        timeString: string | string[]
    ): void => {
        setMinuteValue(time)
        void form.validateFields(['workdhours'])
    }
    useEffect(() => {
        GetServiceProcessInduvidual(dispatch, serviceProcessId)
    }, [serviceProcessId])
    useEffect(() => {
        form.setFieldValue('dueDate', selectedDate)
    }, [serviceProcessId, selectedDate])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            setButtonloading(true)
            await form.validateFields()

            const formData = form.getFieldsValue()
            const serviceProcessStatusProperty: statustPropety[] = []

            if (hourValue !== null && minuteValue !== null) {
                const hour = parseInt(hourValue.format('HH'))
                const minute = parseInt(minuteValue.format('mm'))
                const duration = hour * 60 + minute
                serviceProcessStatusProperty.push({
                    propertyType: 1,
                    referenceId: 0,
                    propertyValue: duration.toString(),
                    propertyDataType: 'duration',
                })
            } else if (hourValue === null && minuteValue !== null) {
                const minute = parseInt(minuteValue.format('mm'))
                const duration = minute
                serviceProcessStatusProperty.push({
                    propertyType: 1,
                    referenceId: 0,
                    propertyValue: duration.toString(),
                    propertyDataType: 'duration',
                })
            } else if (hourValue !== null && minuteValue === null) {
                const hour = parseInt(hourValue.format('HH'))

                const duration = hour * 60
                serviceProcessStatusProperty.push({
                    propertyType: 1,
                    referenceId: 0,
                    propertyValue: duration.toString(),
                    propertyDataType: 'duration',
                })
            }

            if (selectedDate !== null) {
                serviceProcessStatusProperty.push({
                    propertyType: 0,
                    referenceId: 0,
                    propertyValue: selectedDate,
                    propertyDataType: 'date',
                })
            }

            if (
                emailText !== undefined &&
                ServiceProcesName === 'Internal Audit'
            ) {
                serviceProcessStatusProperty.push({
                    propertyType: 7,
                    referenceId: 35,
                    propertyValue: emailText,
                    propertyDataType: 'summernote',
                })
            }
            const dataToSave = {
                type: 1,
                referenceId: serviceActionId ?? 0,
                statusId: serviceActionId === 25 ? 7 : 3,
                remarks: formData.description ?? '',
                completeFlag: 1,
                serviceProcessId,
                emailTemplateId: 0,
                serviceProcessStatusProperty:
                    serviceProcessStatusProperty ?? [],
            }

            await SaveChangePrgress(dispatch, dataToSave)
                .then((result) => {
                    if (result !== null && result !== undefined) {
                        setButtonloading(false)
                    }
                })
                .catch(() => {})
            setHourValue(null)
            setMinuteValue(null)
            form.resetFields()
            form.setFieldsValue({
                description: '',
                historyDate: '',
            })

            changeModelState()
            setSelectedDate('')
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const label = `${
        ServiceProcesName !== 'Manager' ? 'Complete Date' : 'Approved Date'
    }`

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video', 'formula'],
            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'code-block',
        'list',
        'bullet',
        'script',
        'indent',
        'size',
        'color',
        'background',
        'align',
        'link',
        'image',
        'video',
        'formula',
    ]

    const validateWorkedHours = async (
        _: any,
        value: any
    ): Promise<undefined> => {
        if (hourValue !== null && minuteValue !== null) {
            await Promise.resolve()
            return
        }
        await Promise.reject(new Error('Please input worked Hours!'))
    }
    return (
        <>
            <div>
                <h5>
                    {serviceProcessInduvidualData?.companyName}(
                    {serviceProcessInduvidualData?.clientNumber})
                    {serviceProcessInduvidualData?.serviceName}Process for the
                    period of{' '}
                    {formatdate(serviceProcessInduvidualData?.periodStartDate)}{' '}
                    to {formatdate(serviceProcessInduvidualData?.periodEndDate)}
                </h5>
            </div>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={label}
                                name="dueDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Date!',
                                    },
                                ]}
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChange(date, dateString)
                                        }}
                                        value={
                                            selectedDate.length > 0
                                                ? dayjs(selectedDate)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        className={commonStyles.dateWidth}
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        {ServiceProcesName !== 'Manager' && (
                            <Col span={12}>
                                <Form.Item
                                    label="Worked Hours"
                                    name="workdhours"
                                    rules={[
                                        {
                                            required: true,
                                            validator: validateWorkedHours,
                                        },
                                    ]}
                                >
                                    <Row>
                                        <Space wrap>
                                            <Col span={24}>
                                                <TimePicker
                                                    value={hourValue}
                                                    onChange={(
                                                        time,
                                                        timestring
                                                    ) => {
                                                        onChangeHour(
                                                            time,
                                                            timestring
                                                        )
                                                    }}
                                                    format="HH"
                                                    showHour
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <TimePicker
                                                    value={minuteValue}
                                                    onChange={(
                                                        time,
                                                        timestring
                                                    ) => {
                                                        onChangeMinute(
                                                            time,
                                                            timestring
                                                        )
                                                    }}
                                                    format="mm"
                                                    showMinute
                                                />
                                            </Col>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    {internalaudit === 'Internal Audit' && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Assumption, recomendation and action to be taken detail here"
                                    name="Auditdescription"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input Audit statement !',
                                        },
                                    ]}
                                >
                                    <ReactQuill
                                        theme="snow"
                                        value={emailText}
                                        onChange={(content) => {
                                            setEmailText(content)
                                        }}
                                        modules={modules}
                                        formats={formats}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`${ServiceProcesName}-Remarks`}
                                name="description"
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                                loading={buttonLoading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): changeProcessModelProps => {
    return {
        serviceProcessInduvidualData:
            state.dashboardInfo.serviceProcessInduvidualData,
    }
}

export default connect(mapStateToProps)(ChangeProgressModel)
