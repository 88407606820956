import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    GetClientOverviewDetailResponse,
    InitialClientOverviewDetailStateInterface,
} from '../Types/Overview'
import {
    type overviewClient,
    type ClientModelPayoad,
} from '../Types/OverViewClient'

const ClientOverviewReducer = createSlice({
    name: 'clientOverviewInfo',
    initialState: {
        overviewDetail: null,
        overviewClientList: [],
        overviewClientListCount: 0,
    },
    reducers: {
        overviewDetail(
            state: InitialClientOverviewDetailStateInterface,
            action: PayloadAction<GetClientOverviewDetailResponse>
        ) {
            state.overviewDetail = action?.payload
        },
        overviewClientList(
            state: overviewClient,
            action: PayloadAction<ClientModelPayoad>
        ) {
            state.overviewClientList = action?.payload?.data
            state.overviewClientListCount = action?.payload?.noOfRecords
        },
    },
})

export const { overviewDetail, overviewClientList } =
    ClientOverviewReducer.actions
export default ClientOverviewReducer.reducer
