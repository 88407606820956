import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type InvoiceHistoryPayloadInterface,
    type CorporateEntity,
    type InvoiceDetailsPayloadInterface,
    type InvoiceFromData,
    type CommunicationHistory,
    type AddressPayloadIntrfaceData,
} from '../Types/Invoice/Invoicing'
import { type ListFrontEndInterface } from '../Types/CommonType'
import { type PerformFormDataInterface } from '../Types/Invoicing/Invoice'
import {
    type StatusReportPayloadActionData,
    type StatusReporData,
} from '../Types/Invoicing/InvoiceReports'
import { type TogilleAddress } from '../Types/Client/RegisteredClients/Corporate'

interface InitailStateInterface {
    invoiceDetailsList: InvoiceFromData[]
    invoiceDetailsListCount: number
    clientDropDownList: CorporateEntity[]
    invoiceHistoryList: CommunicationHistory[]
    invoiceHistoryListCount: number
    emailTemplateTypeList: ListFrontEndInterface[]
    paymentOptionList: CorporateEntity[]
    paymentModeList: ListFrontEndInterface[]
    PerformInvoice: PerformFormDataInterface[]
    PerformInvoiceCount: number
    GetAddressInfo: TogilleAddress | null
    GetBankInfo: any
    ArchivedInvoice: any[]
    ArchivedInvoiceCount: number
    invoiceStatusReportList: StatusReporData | null
}

const InvoiceReducer = createSlice({
    name: 'invoice',
    initialState: {
        invoiceDetailsList: [],
        invoiceDetailsListCount: 0,
        clientDropDownList: [],
        invoiceHistoryList: [],
        invoiceHistoryListCount: 0,
        emailTemplateTypeList: [],
        paymentOptionList: [],
        paymentModeList: [],
        PerformInvoice: [],
        PerformInvoiceCount: 0,
        GetAddressInfo: null,
        GetBankInfo: [],
        ArchivedInvoice: [],
        ArchivedInvoiceCount: 0,
        invoiceStatusReportList: null,
    },
    reducers: {
        invoiceList(
            state: InitailStateInterface,
            action: PayloadAction<InvoiceDetailsPayloadInterface>
        ) {
            state.invoiceDetailsList = action?.payload?.data
            state.invoiceDetailsListCount = action?.payload?.noOfRecords
        },
        clientDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<CorporateEntity[]>
        ) {
            state.clientDropDownList = action?.payload
        },
        invoiceHistoryDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<InvoiceHistoryPayloadInterface>
        ) {
            state.invoiceHistoryList = action?.payload?.data
            state.invoiceHistoryListCount = action?.payload?.noOfRecords
        },
        invoiceEmailTemplateTypeList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.emailTemplateTypeList = action?.payload
        },
        paymentOptionList(
            state: InitailStateInterface,
            action: PayloadAction<CorporateEntity[]>
        ) {
            state.paymentOptionList = action?.payload
        },
        paymentModeList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.paymentModeList = action?.payload
        },
        PerformInvoice(
            state: InitailStateInterface,
            action: PayloadAction<any>
        ) {
            state.PerformInvoice = action?.payload?.data
            state.PerformInvoiceCount = action?.payload?.noOfRecords
        },
        GetAddressInfo(
            state: InitailStateInterface,
            action: PayloadAction<AddressPayloadIntrfaceData>
        ) {
            state.GetAddressInfo = action?.payload?.data
        },
        GetBankInfo(state: InitailStateInterface, action: PayloadAction<any>) {
            state.GetBankInfo = action?.payload?.data
        },
        ArchivedInvoice(
            state: InitailStateInterface,
            action: PayloadAction<any>
        ) {
            state.ArchivedInvoice = action?.payload?.data
            state.ArchivedInvoiceCount = action?.payload?.noOfRecords
        },
        invoiceStatusReportList(
            state: InitailStateInterface,
            action: PayloadAction<StatusReportPayloadActionData>
        ) {
            state.invoiceStatusReportList = action?.payload?.data
        },
    },
})

export const {
    invoiceList,
    clientDropDownList,
    invoiceHistoryDetailsList,
    invoiceEmailTemplateTypeList,
    paymentOptionList,
    paymentModeList,
    PerformInvoice,
    GetAddressInfo,
    GetBankInfo,
    ArchivedInvoice,
    invoiceStatusReportList,
} = InvoiceReducer.actions
export default InvoiceReducer.reducer
