import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, Row, Col } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import CoporateTabsless from './ContactInfo.less'
import {
    type PartneshipDataInterFace,
    type ContactEditFormInterface,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import { connect, useDispatch } from 'react-redux'
import { GetPartneshipContactInformation } from '../../../../../../Services/Common'
import { getPartnerContactPersonDetails } from '../../../../../../Services/Parnership'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'

const ContactInfoEditForm = (
    props: ContactEditFormInterface
): React.ReactElement => {
    const {
        editData,
        partnershipId,
        onSave,
        buttonloading,
        partneshipContactInfoList,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [contactPersonId, setContactPersonId] = useState<number | null>(null)
    useEffect(() => {
        GetPartneshipContactInformation(dispatch, partnershipId)
    }, [partnershipId])

    useEffect(() => {
        if (editData !== null) {
            const MemberData = partneshipContactInfoList.find(
                (data: ListFrontEndInterface) =>
                    data.label.includes(editData?.firstname ?? '')
            )
            const newData = {
                ...editData,
                memberPersonId: MemberData?.value ?? null,
            }
            form.setFieldsValue(newData)
            setContactPersonId(MemberData?.value ?? null)
        }
    }, [editData, partnershipId, partneshipContactInfoList])

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            if (
                contactPersonId !== 0 &&
                contactPersonId !== undefined &&
                contactPersonId !== null
            ) {
                const data = await getPartnerContactPersonDetails(
                    dispatch,
                    partnershipId,
                    contactPersonId
                )
                form.setFieldsValue({
                    mobilenumber: data?.mobileNumber ?? '',
                    title: data?.title ?? '',
                    homenumber: data?.homeNumber ?? '',
                    firstname: data?.firstName ?? '',
                    worknumber: data?.workNumber ?? '',
                    middlename: data?.middleName ?? '',
                    emailaddress: data?.emailAddress ?? '',
                    surname: data?.surName ?? '',
                    altemailaddress: data?.alternativeEmailAddress ?? '',
                })
            }
        }
        void fetchData()
    }, [contactPersonId])

    const handelContactPersonChange = (value: number): void => {
        setContactPersonId(value)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const onSaveData = (): void => {
        void onSaveDataContactdata()
    }

    const onSaveDataContactdata = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()

            const Language =
                formData.preferredlanguage === 1
                    ? 'English'
                    : formData.preferredlanguage === 2
                      ? 'Tamil'
                      : ''
            const dataToSave = {
                contactpersonid: editData?.contactpersonid ?? 0,
                registerid: partnershipId,
                registertype: 'PARTNERSHIP',
                title: formData.title ?? '',
                firstname: formData.firstname,
                middlename: formData.middlename ?? '',
                surname: formData.surname ?? '',
                preferredname: formData.preferredname ?? '',
                mobilenumber: formData.mobilenumber,
                homenumber: formData.homenumber ?? '',
                worknumber: formData.worknumber ?? '',
                emailaddress: formData.emailaddress,
                altemailaddress: formData.altemailaddress ?? '',
                preferredlanguage: formData.preferredlanguage ?? 0,
                languageName: Language,
            }
            onSave(dataToSave)
        } catch (error) {}
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="contactpersonid" hidden={true}>
                    <Input defaultValue={''} />
                </Form.Item>

                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item label="Partner " name="memberPersonId">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={handelContactPersonChange}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { value: null, label: 'Please select' },
                                    ...partneshipContactInfoList,
                                ]}
                                value={contactPersonId}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Mobile Number"
                            name="mobilenumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Mobile Number!',
                                },
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item label="Title " name="title">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                {' '}
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Home Number"
                            name="homenumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="firstname"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    pattern: /^[a-zA-Z]+$/,
                                    message:
                                        'First Name cannot be a number or contain numbers!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Work Number "
                            name="worknumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item label="Middle Name" name="middlename">
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email Address"
                            name="emailaddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'The input is not a valid Email!',
                                    pattern:
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item label="Sure Name " name="surname">
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Alternative Email"
                            name="altemailaddress"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item label="Prefered Name " name="preferredname">
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Prefered Language"
                            name="preferredlanguage"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value={0}>
                                    Please Select
                                </Select.Option>
                                <Select.Option value={1}>English</Select.Option>
                                <Select.Option value={2}>Tamil</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Button
                                type="primary"
                                onClick={onSaveData}
                                loading={buttonloading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): PartneshipDataInterFace => {
    return {
        partneshipContactInfoList: state.common.partneshipContactInfoList,
    }
}

export default connect(mapStateToProps)(ContactInfoEditForm)
