// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document_j5Mff {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  display: inline-block;
  overflow: visible;
}
.documentuploadtext_dWNh3 {
  margin-top: 20px;
}
.documentupload_t2wnv {
  position: static;
  height: 200px;
}
.CorporteDocumentGeenreal_O7TyR {
  margin-bottom: 60px;
}
.CorporteDocument_OKvea {
  margin-top: 10px;
}
.cardhight_Dw_jr {
  height: 350px;
}
.fileupload_q4xjF {
  position: absolute;
  top: 0;
  margin-top: 10px;
  width: 100%;
}
.savebuttonless_vemO8 {
  margin-top: 220px;
}
.dateWidth_wZwh4 {
  position: absolute;
  width: 100%;
  top: 0px !important;
}
.imagewidth_G5NeJ {
  width: 100%;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/SoleTrader/Buttons/Doucument/document.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAEA;EACI,gBAAA;EACA,aAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;AAFJ;AAKA;EACI,kBAAA;EACA,MAAA;EACA,gBAAA;EACA,WAAA;AAHJ;AAMA;EACI,iBAAA;AAJJ;AAMA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;AAJJ;AAOA;EACI,WAAA;EACA,gBAAA;AALJ","sourcesContent":[".document {\n    width: 100%;\n    height: 200px;\n    margin-bottom: 10px;\n    display: inline-block;\n    overflow: visible;\n}\n\n.documentuploadtext {\n    margin-top: 20px;\n}\n.documentupload {\n    position: static;\n    height: 200px;\n}\n\n.CorporteDocumentGeenreal {\n    margin-bottom: 60px;\n}\n.CorporteDocument {\n    margin-top: 10px;\n}\n\n.cardhight {\n    height: 350px;\n}\n\n.fileupload {\n    position: absolute;\n    top: 0;\n    margin-top: 10px;\n    width: 100%;\n}\n\n.savebuttonless {\n    margin-top: 220px;\n}\n.dateWidth {\n    position: absolute;\n    width: 100%;\n    top: 0px !important;\n}\n\n.imagewidth {\n    width: 100%;\n    margin-top: 20px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"document": `document_j5Mff`,
	"documentuploadtext": `documentuploadtext_dWNh3`,
	"documentupload": `documentupload_t2wnv`,
	"CorporteDocumentGeenreal": `CorporteDocumentGeenreal_O7TyR`,
	"CorporteDocument": `CorporteDocument_OKvea`,
	"cardhight": `cardhight_Dw_jr`,
	"fileupload": `fileupload_q4xjF`,
	"savebuttonless": `savebuttonless_vemO8`,
	"dateWidth": `dateWidth_wZwh4`,
	"imagewidth": `imagewidth_G5NeJ`
};
export default ___CSS_LOADER_EXPORT___;
