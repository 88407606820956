/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Card,
    Tooltip,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { CloudDownloadOutlined } from '@ant-design/icons'

import commonStyles from '../../../../Utils/Common.less'
import dayjs from 'dayjs'
import {
    type LeaveReportsData,
    type LeaveReportInterface,
} from '../../../../Types/Hr/Reports'
import {
    GetleaveDropDownList,
    GetLeaveMode,
    GetLeaveStatusReportData,
} from '../../../../Services/HR/LeavePermission'
import { GetUserList } from '../../../../Services/User'
import hederimage from '../../../../Assest/Svg/pattern-2.svg'
import { jsPDF } from 'jspdf'

const LeaveReport = (props: LeaveReportInterface): React.ReactElement => {
    const { userList, LeaveModeDropDownList, LeaveTypeDropDownlist } = props

    const dispatch = useDispatch()
    const [selectedSatusId, setSelectedServiceId] = useState<number>(0)
    const [form] = Form.useForm()
    const [selectedConsiderId, setSelectedLabelManager] = useState<number>(0)
    const [selectedInvoiceDate, setSelectedInvoiceDate] = useState<string>('')
    const [selectedDueDate, setSelectedDueDate] = useState<string>('')
    const [selectedLeaveType, setSelectedLeaveType] = useState<number>(0)
    const [selectedLeaveMode, setSelectedLeaveMode] = useState<number>(0)
    const [noRecordsFound, setNoRecordsFound] = useState(false)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetLeaveMode(dispatch)
        GetleaveDropDownList(dispatch)
        GetUserList(dispatch)
    }, [dispatch])

    const onChangeInvoiceDate1 = (date: DatePickerProps['value']): void => {
        if (date) {
            form.setFieldsValue({ fromDate: date })
            setSelectedInvoiceDate(date.format('DD-MM-YYYY'))
        }
    }

    const onChangeDue1 = (date: DatePickerProps['value']): void => {
        if (date) {
            form.setFieldsValue({ toDate: date })
            setSelectedDueDate(date.format('DD-MM-YYYY'))
        }
    }
    const disabledToDate = (current: dayjs.Dayjs): boolean => {
        const fromDate = form.getFieldValue('fromDate')
        return fromDate
            ? current && current.isBefore(dayjs(fromDate), 'day')
            : false
    }
    const handleSelectChangeLeaveType = (value: number): void => {
        setSelectedLeaveType(value)
    }

    const handleSelectChangeLeaveMode = (value: number): void => {
        setSelectedLeaveMode(value)
    }

    const handleManagerChange = (value: number): void => {
        setSelectedLabelManager(value)
    }

    const serviceDropDownList = [
        { value: 1, label: 'Pending' },
        { value: 2, label: 'Approved' },
    ]

    const handleSelectChangeService = (value: number): void => {
        setSelectedServiceId(value)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const getData = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formattedFromDate = selectedInvoiceDate
                ? dayjs(selectedInvoiceDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
                : ''
            const formattedToDate = selectedDueDate
                ? dayjs(selectedDueDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
                : ''

            const numericSatusId =
                typeof selectedSatusId === 'string'
                    ? selectedSatusId === 'all'
                        ? 0
                        : Number(selectedSatusId)
                    : selectedSatusId

            const numericLeaveType =
                typeof selectedLeaveType === 'string'
                    ? selectedLeaveType === 'all'
                        ? 0
                        : Number(selectedLeaveType)
                    : selectedLeaveType

            const numericLeaveMode =
                typeof selectedLeaveMode === 'string'
                    ? selectedLeaveMode === 'all'
                        ? 0
                        : Number(selectedLeaveMode)
                    : selectedLeaveMode

            const reportData: LeaveReportsData = await GetLeaveStatusReportData(
                dispatch,
                selectedConsiderId,
                numericSatusId,
                numericLeaveType,
                numericLeaveMode,
                formattedFromDate,
                formattedToDate
            )

            if (
                reportData?.leaveDetailsList !== null &&
                reportData?.leaveDetailsList !== undefined &&
                reportData?.leaveDetailsList.length > 0
            ) {
                generatePDF(reportData)
                setNoRecordsFound(false)
            } else {
                setNoRecordsFound(true)
            }
        } catch (error) {
            console.error('Error in getData:', error)
        }
    }

    const generatePDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop

            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('IYKONS LIMITED', textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('Leave Report', textXPosition, subHeaderYPosition)

            const lineStartX =
                textXPosition + doc.getTextWidth('Leave Report') + 5
            const lineEndX = lineStartX + 80
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)

            doc.setFontSize(addressFontSize)
            doc.setTextColor(0, 0, 0)
            const dateRangeYPosition = lineY + 10
            const dateRangeText = `From Date :- ${selectedInvoiceDate} To Date:- ${selectedDueDate}`
            doc.text(dateRangeText, textXPosition, dateRangeYPosition)

            const headers = [
                [
                    'Start Date',
                    'End Date',
                    'Employee Name',
                    'Leave Type',
                    'Leave Mode',
                    'Status',
                ],
            ]
            const tableData = data?.leaveDetailsList?.map((item: any) => [
                dayjs(item.leaveFrom, 'MMM D YYYY hh:mma').format(
                    'MMMM D, YYYY'
                ),
                dayjs(item.leaveTo, 'MMM D YYYY hh:mma').format('MMMM D, YYYY'),
                item.leaveAppliedByName,
                item.leaveType,
                item.leaveMode,
                item.leaveStatus,
            ])

            doc.autoTable({
                head: headers,
                body: tableData,
                startY: textYPosition + 30,
                theme: 'grid',
                styles: {
                    halign: 'left',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineColor: [160, 160, 160],
                    lineWidth: 0.05,
                },
            })

            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${tableData?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }

            doc.save('leave_report.pdf')
        }

        img.onerror = function () {
            console.error('Image loading failed')
        }
    }

    return (
        <>
            <Card title="Leave Reports" style={{ marginTop: '20px' }}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label="From Date"
                                    name="fromDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input From Date!',
                                        },
                                    ]}
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChangeInvoiceDate1}
                                            value={form.getFieldValue(
                                                'fromDate'
                                            )}
                                            className={commonStyles.dateWidth}
                                            disabledDate={(current) => {
                                                const currentDate = dayjs()
                                                return (
                                                    current &&
                                                    current.isAfter(
                                                        currentDate,
                                                        'day'
                                                    )
                                                ) // Disable past dates
                                            }}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="To Date"
                                    name="toDate"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your To Date!',
                                        },
                                    ]}
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChangeDue1}
                                            value={form.getFieldValue('toDate')}
                                            className={commonStyles.dateWidth}
                                            disabledDate={disabledToDate}
                                            disabled={
                                                !form.getFieldValue('fromDate')
                                            }
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Employee" name="Employee">
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Please select"
                                        onChange={handleManagerChange}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={[
                                            { value: 'all', label: 'All' },
                                            ...userList,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Status">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeService}
                                        value={selectedSatusId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...serviceDropDownList,
                                        ]}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                {' '}
                                <Form.Item label="Leave Type">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeLeaveType}
                                        value={selectedLeaveType}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...LeaveTypeDropDownlist,
                                        ]}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                {' '}
                                <Form.Item label="Leave Mode" name="leaveMode">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeLeaveMode}
                                        value={selectedLeaveMode}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...LeaveModeDropDownList,
                                        ]}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={20} span={4}>
                                <Tooltip title="Download Report">
                                    <Button
                                        type="primary"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={getData}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>{' '}
                {noRecordsFound && (
                    <div style={{ textAlign: 'left', marginTop: '20px' }}>
                        <span
                            style={{
                                color: '#808080',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            No Records found
                        </span>
                    </div>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): LeaveReportInterface => {
    return {
        userList: state.user.userList,
        LeaveTypeDropDownlist: state.hr.LeaveTypeDropDownlist,
        LeaveModeDropDownList: state.hr.LeaveModeDropDownList,
        leaveDetailsList: state.hr.leaveDetailsList,
    }
}

export default connect(mapStateToProps)(LeaveReport)
