/* eslint-disable */
// prettier-ignore
import React, { useState, ChangeEvent } from 'react';
import axios from 'axios'
import * as XLSX from 'xlsx'
import { Button, Select } from 'antd'

const { Option } = Select

// ---------------------
// TYPES FOR ORIGINAL COMPARISON (API1/API2)
// ---------------------
interface Service {
    id: number
    name: string
}

export interface ServiceData {
    serviceProcessId: number
    serviceId: number
    serviceName: string
    businessTypeId: number
    referenceId: number
    year: number
    periodStartDate: string
    periodEndDate: string
    currentStatus: string
    dueDays: number
    assignBy: string
    assignTo: string
    assignDate: string
    dueDate: string
    completeDate: string
    businessType: string
    clientName: string
    clientNumber: string
    serviceProgressStatus: number
    actionName: string
    additionalDataOne: string
    additionalDataTwo: string
    additionalDataThree: string
    additionalDataFour: string
    additionalDataFive: string
    lastCommunicationStatus: string
    processDueDate: string
    assignDueDays: number
    dueDateDescription: string
    dueDateProgressStatus: number
    isNewChatExist: number
    signableUrl: string | null
    companyStatus: string | null
}

interface Difference {
    property: string
    api1Value: any
    api2Value: any
}

interface ComparisonResult {
    clientName: string
    differences: Difference[]
}

interface Payload {
    searchString: string
    pageNo: number
    pageSize: number
    flagId: number
    serviceId: number
    statusId: number
    clientCategoryId: number
    clientTypeId: number
    FromDate: string
    ToDate: string
    NextStatmentFromDate: string
    NextStatmentToDate: string
    AssignedBy: number
    AssignedTo: number
    PayeFrequency: number
}

// ---------------------
// TYPES FOR ADDITIONAL DATA FLOW
// ---------------------
interface ClientInfo {
    clientName: string
    serviceList: string
    referenceId: number
}

interface PeriodInfo {
    fromDate: string
    toDate: string
    deadlineDate: string
}

type ServiceDetail = ServiceData // For APIs C, D, etc.

interface AdditionalData {
    record: any
    clientInfo: ClientInfo | null
    periodInfo: PeriodInfo | null
    overdueDetails: ServiceDetail[]
    inProgressDetails: ServiceDetail[]
    completeDetails: ServiceDetail[]
    // For Corporate only:
    govInfo?: {
        incorporationDate: string
        accountingReferenceDate: any // May be an object (e.g. { day, month }) or a string
        firstAccountsDate: string
        accountsDueDate: string
        firstStatementDate: string
        statementDueDate: string
    }
    initialDetail?: {
        dateOfIncorporation: string
        clientNumber: string
    }
    dashboardInfo?: {
        serviceList: string
        contactName: string
    }
    validation?: ValidationFlags
}

interface ValidationFlags {
    // For both Corporate and non‑Corporate validations:
    V1Failed?: boolean // Service list mismatch between main API and dashboard
    V2Failed?: boolean // Duplicate period year (for same service) in in‑progress records
    V3Failed?: boolean // One or more in‑progress record’s dueDays exceed allowed max OR for AA dueDate not January 31
    V4Failed?: boolean // Expected in‑progress record is missing when current date is between period end and deadline
    // Corporate only additional:
    V5Failed?: boolean // Corporate: Incorporation date does not match accounting reference date
}

interface VerifyResult {
    name: string
    result: string
}

// ---------------------
// CONSTANTS & DEFAULT PAYLOADS
// ---------------------
const serviceList: Service[] = [
    { id: 1, name: 'VAT' },
    { id: 3, name: 'PAYE' },
    { id: 4, name: 'PENSION' },
    { id: 100, name: 'OTHERS' },
    { id: 10, name: 'Confirmation' },
    { id: 9, name: 'AA' },
    { id: 2, name: 'CT' },
]

const API1_BASE =
    'http://iykmvcapi.iykons.com/api/Dashboard/GetServiceFollowUpThings/'
const API1_GUID = '4389CB00-B04B-49A3-8084-06E37A484DF5'
const API2_URL =
    'https://reactapi.iykons.com/api/Dashboard/GetServiceFollowUpThings'

const defaultPayload: Payload = {
    searchString: '',
    pageNo: 1,
    pageSize: 3000,
    flagId: 2,
    serviceId: 1,
    statusId: 0,
    clientCategoryId: 0,
    clientTypeId: 0,
    FromDate: '',
    ToDate: '',
    NextStatmentFromDate: '',
    NextStatmentToDate: '',
    AssignedBy: 0,
    AssignedTo: 0,
    PayeFrequency: 0,
}

const compareProperties: string[] = [
    'businessTypeId',
    'year',
    'periodStartDate',
    'periodEndDate',
    'currentStatus',
    'dueDays',
    'dueDate',
    'completeDate',
    'businessType',
    'clientName',
    'serviceProgressStatus',
    'actionName',
    'processDueDate',
    'assignDueDays',
    'dueDateProgressStatus',
    'companyStatus',
]

// ---------------------
// RETRY HELPER – Retry API calls until they succeed
// ---------------------
async function retryApiCall<T>(
    apiFunc: () => Promise<T>,
    delayMs = 1000
): Promise<T> {
    while (true) {
        try {
            return await apiFunc()
        } catch (e) {
            console.error('API call failed, retrying...', e)
            await new Promise((res) => setTimeout(res, delayMs))
        }
    }
}

// ---------------------
// MAIN COMPONENT
// ---------------------
const DashboardComparison: React.FC = () => {
    // STATES FOR ORIGINAL API1/API2 COMPARISON
    const [selectedService, setSelectedService] = useState<number | null>(null)
    const [selectedStatus, setSelectedStatus] = useState<number>(0)
    const [dataApi1, setDataApi1] = useState<ServiceData[]>([])
    const [dataApi2, setDataApi2] = useState<ServiceData[]>([])
    const [comparisonResults, setComparisonResults] = useState<
        ComparisonResult[]
    >([])
    const [loading, setLoading] = useState<boolean>(false)
    const [comparing, setComparing] = useState<boolean>(false)
    const [comparisonPerformed, setComparisonPerformed] =
        useState<boolean>(false)

    // STATES FOR ADDITIONAL DATA FLOW
    const [additionalLoading, setAdditionalLoading] = useState<boolean>(false)
    const [additionalData, setAdditionalData] = useState<AdditionalData[]>([])
    const [viewAdditional, setViewAdditional] = useState<boolean>(false)
    const [verifying, setVerifying] = useState<boolean>(false)
    const [verifyResults, setVerifyResults] = useState<VerifyResult[]>([])
    const [additionalFilter, setAdditionalFilter] = useState<string[]>([])
    const [selectedBusinessType, setSelectedBusinessType] =
        useState<string>('Retail')
    const [selectedClientCategory, setSelectedClientCategory] =
        useState<string>('Individual')

    // For client-type dropdown on original comparison (for AA/CT)
    const [selectedClientType, setSelectedClientType] = useState<number>(0)
    const [showClientTypeDropdown, setShowClientTypeDropdown] =
        useState<boolean>(false)

    // HANDLERS FOR DROPDOWNS
    const handleServiceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const serviceId = parseInt(e.target.value, 10)
        setSelectedService(serviceId)
        if (serviceId == 9 || serviceId == 2) {
            setShowClientTypeDropdown(true)
        } else {
            setShowClientTypeDropdown(false)
            setSelectedClientType(0)
        }
    }
    const handleStatusChange = (value: number) => setSelectedStatus(value)
    const handleClientTypeChange = (value: number) =>
        setSelectedClientType(value)
    const handleClientCategoryChange = (value: string) =>
        setSelectedClientCategory(value)
    const handleBusinessTypeChange = (value: string) =>
        setSelectedBusinessType(value)

    // ORIGINAL API1/API2 FETCH
    const fetchData = async () => {
        if (!selectedService) {
            alert('Please select a service')
            return
        }
        setDataApi1([])
        setDataApi2([])
        setComparisonResults([])
        setComparisonPerformed(false)
        setLoading(true)
        const payload: Payload = {
            ...defaultPayload,
            serviceId: selectedService!,
            statusId: selectedStatus,
            clientTypeId: showClientTypeDropdown ? selectedClientType : 0,
        }
        try {
            const response1 = await retryApiCall(() =>
                axios.post(`${API1_BASE}${API1_GUID}`, payload)
            )
            if (response1.data && response1.data.data)
                setDataApi1(response1.data.data)
            const token =
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiaW5mb0BpeWtvbnMuY29tIiwianRpIjoiYzFiZTQxMDAtMTJkMy00M2Y3LWIwNDUtMTk0MTcyODUxZTUzIiwiZXhwIjoxNzMyOTQ2NjE1LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjUwMDAiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjQyMDAifQ.GFcvFIxkmtPDZK_7tDnq33Ai7mTanvgvRLqHFg_x564'
            const response2 = await retryApiCall(() =>
                axios.post(API2_URL, payload, {
                    headers: { Authorization: `Bearer ${token}` },
                })
            )
            if (response2.data && response2.data.data)
                setDataApi2(response2.data.data)
        } catch (error) {
            console.error('Error fetching data:', error)
            alert('Error fetching data. Check console.')
        } finally {
            setLoading(false)
        }
    }

    // DOWNLOAD EXCEL UTILITY
    const downloadExcel = (data: any[], fileName: string) => {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
        XLSX.writeFile(workbook, fileName)
    }

    // ORIGINAL API1/API2 COMPARISON
    const compareData = () => {
        setComparing(true)
        setComparisonPerformed(true)
        const results: ComparisonResult[] = []

        // Group API1 records by composite key: clientName and serviceName
        const groupApi1: Record<string, ServiceData[]> = {}
        dataApi1.forEach((item) => {
            // Use a delimiter that is unlikely to appear in clientName or serviceName
            const key = `${item.clientName}|||${item.serviceName}`
            if (!groupApi1[key]) groupApi1[key] = []
            groupApi1[key].push(item)
        })

        // Group API2 records similarly
        const groupApi2: Record<string, ServiceData[]> = {}
        dataApi2.forEach((item) => {
            const key = `${item.clientName}|||${item.serviceName}`
            if (!groupApi2[key]) groupApi2[key] = []
            groupApi2[key].push(item)
        })

        // Loop over groups from API1 and compare with matching API2 groups
        Object.keys(groupApi1).forEach((key) => {
            const records1 = groupApi1[key]
            const records2 = groupApi2[key] || []
            const differences: Difference[] = []

            // Compare the number of records in each group
            if (records1.length !== records2.length) {
                differences.push({
                    property: 'recordCount',
                    api1Value: records1.length,
                    api2Value: records2.length,
                })
            }

            // To compare individual records, sort by periodStartDate
            const sorted1 = records1
                .slice()
                .sort(
                    (a, b) =>
                        new Date(a.periodStartDate).getTime() -
                        new Date(b.periodStartDate).getTime()
                )
            const sorted2 = records2
                .slice()
                .sort(
                    (a, b) =>
                        new Date(a.periodStartDate).getTime() -
                        new Date(b.periodStartDate).getTime()
                )

            // Compare corresponding records (only up to the minimum count)
            const countToCompare = Math.min(sorted1.length, sorted2.length)
            for (let i = 0; i < countToCompare; i++) {
                if (sorted1[i].periodStartDate !== sorted2[i].periodStartDate) {
                    differences.push({
                        property: 'periodStartDate',
                        api1Value: sorted1[i].periodStartDate,
                        api2Value: sorted2[i].periodStartDate,
                    })
                }
                if (sorted1[i].periodEndDate !== sorted2[i].periodEndDate) {
                    differences.push({
                        property: 'periodEndDate',
                        api1Value: sorted1[i].periodEndDate,
                        api2Value: sorted2[i].periodEndDate,
                    })
                }
                if (Number(sorted1[i].dueDays) !== Number(sorted2[i].dueDays)) {
                    differences.push({
                        property: 'dueDays',
                        api1Value: sorted1[i].dueDays,
                        api2Value: sorted2[i].dueDays,
                    })
                }
            }

            if (differences.length > 0) {
                const [clientName, serviceName] = key.split('|||')
                results.push({
                    clientName: `${clientName} - ${serviceName}`,
                    differences,
                })
            }
        })

        // Now check for any groups in API2 that have no matching group in API1
        Object.keys(groupApi2).forEach((key) => {
            if (!groupApi1[key]) {
                const records2 = groupApi2[key]
                const differences: Difference[] = [
                    {
                        property: 'recordCount',
                        api1Value: 0,
                        api2Value: records2.length,
                    },
                ]
                const [clientName, serviceName] = key.split('|||')
                results.push({
                    clientName: `${clientName} - ${serviceName}`,
                    differences,
                })
            }
        })

        setComparisonResults(results)
        setComparing(false)
    }
    // ---------------------
    // ADDITIONAL DATA FLOW FUNCTIONS
    // (Separate flows for Corporate, Individual, Sole Trader, Partnership)
    // ---------------------
    const fetchAdditionalDataForIndividual = async (
        record: any
    ): Promise<AdditionalData> => {
        const result: AdditionalData = {
            record,
            clientInfo: null,
            periodInfo: null,
            overdueDetails: [],
            inProgressDetails: [],
            completeDetails: [],
        }
        try {
            const urlA = `https://reactapi.iykons.com/api/Overview/GetClientDashboard/3/${record.individualId}/1`
            result.clientInfo = await retryApiCall(() => axios.get(urlA)).then(
                (res) => res.data.data
            )
            const referenceId = record.individualId

            const urlC = `https://reactapi.iykons.com/api/corporate/GetClientCompanyDetail/3/${referenceId}/4`
            await retryApiCall(() => axios.get(urlC)) // Not used further for individual
            const urlF = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/3/${referenceId}/0/0/=NA=/1/500/0`
            const responseF = await retryApiCall(() => axios.get(urlF))
            result.inProgressDetails = responseF.data.data
            const urlFc = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/3/${referenceId}/0/0/=NA=/1/500/2`
            const responseFc = await retryApiCall(() => axios.get(urlFc))
            result.completeDetails = responseFc.data.data
            const urlG = `https://reactapi.iykons.com/api/Overview/GetClientDashboard/3/${referenceId}/1`
            const responseG = await retryApiCall(() => axios.get(urlG))
            result.dashboardInfo = {
                serviceList: responseG.data.data.serviceList,
                contactName: responseG.data.data.contactName,
            }
        } catch (error) {
            console.error(
                `Error in Individual flow for record ${record.individualId}:`,
                error
            )
        }
        return result
    }

    const fetchAdditionalDataForCorporate = async (
        record: any
    ): Promise<AdditionalData> => {
        const result: AdditionalData = {
            record,
            clientInfo: {
                clientName: record.companyname,
                serviceList: record.serviceIds,
                referenceId: record.corporateid,
            },
            periodInfo: null,
            overdueDetails: [],

            inProgressDetails: [],
            completeDetails: [],
        }
        try {
            if (
                record.companyregisternumber &&
                record.companyregisternumber.trim() != ''
            ) {
                const regNumber = record.companyregisternumber.trim()
                const urlGov = `https://reactapi.iykons.com/api/CompaniesHouse/GetBasicInformation/${regNumber}`
                const govResponse = await retryApiCall(() => axios.get(urlGov))
                const dataGov = govResponse.data
                result.govInfo = {
                    incorporationDate: dataGov.date_of_creation,
                    accountingReferenceDate:
                        dataGov.accounts.accounting_reference_date,
                    firstAccountsDate: dataGov.accounts.next_made_up_to,
                    accountsDueDate: dataGov.accounts.next_due,
                    firstStatementDate:
                        dataGov.confirmation_statement.next_made_up_to,
                    statementDueDate: dataGov.confirmation_statement.next_due,
                }
                result.periodInfo = {
                    fromDate: result.govInfo.firstAccountsDate,
                    toDate: result.govInfo.accountsDueDate,
                    deadlineDate: result.govInfo.statementDueDate,
                }
            } else {
                console.error(
                    `Company registration number missing for corporateid ${record.corporateid}.`
                )
            }
            const urlC = `https://reactapi.iykons.com/api/corporate/GetClientCompanyDetail/1/${record.corporateid}/1`
            const responseC = await retryApiCall(() => axios.get(urlC))
            result.initialDetail = {
                dateOfIncorporation:
                    responseC.data.data.initialDetails.dateofincorporation,
                clientNumber: responseC.data.data.initialDetails.clientnumber,
            }
            const urlD = `https://reactapi.iykons.com/api/corporate/GetClientCompanyDetail/1/${record.corporateid}/4`
            const responseD = await retryApiCall(() => axios.get(urlD))
            const activeServices = responseD.data.data.filter(
                (item: any) => item.status == 'Active'
            )
            const urlF = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/1/${record.corporateid}/0/0/=NA=/1/500/0`
            const responseF = await retryApiCall(() => axios.get(urlF))
            result.inProgressDetails = responseF.data.data
            const urlFc = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/1/${record.corporateid}/0/0/=NA=/1/500/2`
            const responseFc = await retryApiCall(() => axios.get(urlFc))
            result.completeDetails = responseFc.data.data
            const urlG = `https://reactapi.iykons.com/api/Overview/GetClientDashboard/1/${record.corporateid}/1`
            const responseG = await retryApiCall(() => axios.get(urlG))
            result.dashboardInfo = {
                serviceList: responseG.data.data.serviceList,
                contactName: responseG.data.data.contactName,
            }
        } catch (error) {
            console.error(
                `Error in Corporate flow for corporateid ${record.corporateid}:`,
                error
            )
        }
        return result
    }

    const fetchAdditionalDataForSoleTrader = async (
        record: any
    ): Promise<AdditionalData> => {
        const result: AdditionalData = {
            record,
            clientInfo: {
                clientName: record.businessTradingName,
                serviceList: record.serviceIds,
                referenceId: record.soleTraderId,
            },
            periodInfo: null,
            overdueDetails: [],
            inProgressDetails: [],
            completeDetails: [],
        }
        try {
            const urlD = `https://reactapi.iykons.com/api/corporate/GetClientCompanyDetail/2/${record.soleTraderId}/4`
            const responseD = await retryApiCall(() => axios.get(urlD))
            const activeServices = responseD.data.data.filter(
                (item: any) => item.status == 'Active'
            )
            const urlF = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/2/${record.soleTraderId}/0/0/=NA=/1/500/0`
            const responseF = await retryApiCall(() => axios.get(urlF))
            result.inProgressDetails = responseF.data.data
            const urlFc = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/2/${record.soleTraderId}/0/0/=NA=/1/500/2`
            const responseFc = await retryApiCall(() => axios.get(urlFc))
            result.completeDetails = responseFc.data.data
            const urlG = `https://reactapi.iykons.com/api/Overview/GetClientDashboard/2/${record.soleTraderId}/1`
            const responseG = await retryApiCall(() => axios.get(urlG))
            result.dashboardInfo = {
                serviceList: responseG.data.data.serviceList,
                contactName: responseG.data.data.contactName,
            }
        } catch (error) {
            console.error(
                `Error in Sole Trader flow for soleTraderId ${record.soleTraderId}:`,
                error
            )
        }
        return result
    }

    const fetchAdditionalDataForPartnership = async (
        record: any
    ): Promise<AdditionalData> => {
        const result: AdditionalData = {
            record,
            clientInfo: {
                clientName: record.tradingName,
                serviceList: record.serviceIds,
                referenceId: record.partnershipId,
            },
            periodInfo: null,
            overdueDetails: [],
            inProgressDetails: [],
            completeDetails: [],
        }
        try {
            const urlD = `https://reactapi.iykons.com/api/corporate/GetClientCompanyDetail/4/${record.partnershipId}/4`
            const responseD = await retryApiCall(() => axios.get(urlD))
            const activeServices = responseD.data.data.filter(
                (item: any) => item.status == 'Active'
            )
            const urlF = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/4/${record.partnershipId}/0/0/=NA=/1/500/0`
            const responseF = await retryApiCall(() => axios.get(urlF))
            result.inProgressDetails = responseF.data.data
            const urlFc = `https://reactapi.iykons.com/api/Overview/GetClientServiceDetail/4/${record.partnershipId}/0/0/=NA=/1/500/2`
            const responseFc = await retryApiCall(() => axios.get(urlFc))
            result.completeDetails = responseFc.data.data
            const urlG = `https://reactapi.iykons.com/api/Overview/GetClientDashboard/4/${record.partnershipId}/1`
            const responseG = await retryApiCall(() => axios.get(urlG))
            result.dashboardInfo = {
                serviceList: responseG.data.data.serviceList,
                contactName: responseG.data.data.contactName,
            }
        } catch (error) {
            console.error(
                `Error in Partnership flow for partnershipId ${record.partnershipId}:`,
                error
            )
        }
        return result
    }

    // FETCH ADDITIONAL DATA FOR ALL BASED ON SELECTED CLIENT CATEGORY & BUSINESS TYPE
    const fetchAdditionalDataAll = async () => {
        if (!selectedClientCategory || !selectedBusinessType) {
            alert(
                'Please select both a Client Category and a Business Type first.'
            )
            return
        }
        setAdditionalLoading(true)
        setAdditionalData([])
        setViewAdditional(false)
        setVerifyResults([])
        try {
            const mainPayload = {
                searchCriteriaList: [
                    { field: 'BusinessType', value: selectedBusinessType },
                ],
                sortOrderList: [],
            }
            let endpoint = ''
            if (selectedClientCategory == 'Corporate') {
                endpoint =
                    'https://reactapi.iykons.com/api/Corporate/GetCorporateCompanyList/0/0/1/4000'
            } else if (selectedClientCategory == 'Individual') {
                endpoint =
                    'https://reactapi.iykons.com/api/Individual/GetIndividualList/0/0/1/4000'
            } else if (selectedClientCategory == 'SoleTrader') {
                endpoint =
                    'https://reactapi.iykons.com/api/SoleTrader/GetSoleTraderList/0/0/1/4000'
            } else if (selectedClientCategory == 'Partnership') {
                endpoint =
                    'https://reactapi.iykons.com/api/Partnership/GetPartnershipList/0/0/1/4000'
            }
            const mainResponse = await retryApiCall(() =>
                axios.post(endpoint, mainPayload)
            )
            const records = mainResponse.data.data
            if (!records || records.length == 0)
                throw new Error('No records returned from main API.')
            let promises: Promise<AdditionalData>[]
            if (selectedClientCategory == 'Corporate') {
                promises = records.map((r: any) =>
                    fetchAdditionalDataForCorporate(r)
                )
            } else if (selectedClientCategory == 'Individual') {
                promises = records.map((r: any) =>
                    fetchAdditionalDataForIndividual(r)
                )
            } else if (selectedClientCategory == 'SoleTrader') {
                promises = records.map((r: any) =>
                    fetchAdditionalDataForSoleTrader(r)
                )
            } else if (selectedClientCategory == 'Partnership') {
                promises = records.map((r: any) =>
                    fetchAdditionalDataForPartnership(r)
                )
            } else {
                promises = []
            }
            const results = (await Promise.all(promises)).filter(
                (r): r is AdditionalData => r != undefined
            )
            setAdditionalData(results)
        } catch (error) {
            console.error('Error fetching additional data:', error)
            alert('Error fetching additional data. Check console.')
        } finally {
            setAdditionalLoading(false)
        }
    }

    // ---------------------
    // VERIFICATION & VALIDATION
    // ---------------------
    const verifyAdditionalData = () => {
        // For maximum due days we use the following (applied for both corporate and non-corporate)
        const maxDue: Record<string, number> = {
            AA: 280,
            CS: 49, // 35+14 days
            VAT: 38,
            PAYE: 5,
            PENSION: 14,
        }

        setVerifying(true)
        const newVerifyResults: VerifyResult[] = []
        const updatedData = additionalData.map((item) => {
            // Initialize flags
            let V1Failed = false // Service list mismatch
            let V2Failed = false // Duplicate period year in in-progress records
            let V3Failed = false // Due days exceed allowed OR AA dueDate not January 31
            let V4Failed = false // Missing in-progress record when expected
            let V5Failed = false // (Corporate only) Incorporation date vs accounting ref date mismatch

            const errors: string[] = []
            const inProgress = item.inProgressDetails || []
            const completeDetails = item.completeDetails || []

            // V1: Compare dashboard service list with main API serviceIds
            if (item.dashboardInfo && item.record) {
                const svcListFromG = (item.dashboardInfo.serviceList || '')
                    .split(',')
                    .map((s: string) => s.trim().toUpperCase())
                    .sort()
                    .join(',')
                const svcListFromA = (item.record.serviceIds || '')
                    .split(',')
                    .map((s: string) => s.trim().toUpperCase())
                    .sort()
                    .join(',')
                if (svcListFromG !== svcListFromA && svcListFromG !== '') {
                    V1Failed = true
                    errors.push('Service list mismatch (V1)')
                }

                // Check that inProgress service names are included in the serviceIds from API A.
                const validServices = (item.record.serviceIds || '')
                    .split(',')
                    .map((s: string) => s.trim().toUpperCase())
                    .filter(Boolean)
                const invalidInProgressServices = inProgress
                    .map((ip) => ip.serviceName.trim().toUpperCase())
                    .filter((svc: string) => !validServices.includes(svc))
                if (invalidInProgressServices.length > 0) {
                    V1Failed = true
                    errors.push(
                        `In-progress data contains service(s) not in API A: ${invalidInProgressServices.join(', ')} (V1)`
                    )
                }
            }

            // V2: Check for duplicate period start/end year for same service in in-progress records
            const serviceRecords: Record<
                string,
                { startYears: number[]; endYears: number[] }
            > = {}
            inProgress.forEach((rec) => {
                const svc = rec.serviceName.toUpperCase()
                const startYear = new Date(rec.periodStartDate).getFullYear()
                const endYear = new Date(rec.periodEndDate).getFullYear()
                if (!serviceRecords[svc]) {
                    serviceRecords[svc] = { startYears: [], endYears: [] }
                }
                serviceRecords[svc].startYears.push(startYear)
                serviceRecords[svc].endYears.push(endYear)
            })
            Object.entries(serviceRecords).forEach(([svc, yrs]) => {
                if (
                    yrs.startYears.some(
                        (year) =>
                            yrs.startYears.filter((y) => y == year).length > 1
                    ) ||
                    yrs.endYears.some(
                        (year) =>
                            yrs.endYears.filter((y) => y == year).length > 1
                    )
                ) {
                    V2Failed = true
                    errors.push(`Duplicate period year for service ${svc} (V2)`)
                }
            })

            // V3: For each in-progress record, validate maximum dueDays and for AA that dueDate is January 31
            inProgress.forEach((ip) => {
                const svc = ip.serviceName.toUpperCase()
                if (
                    maxDue[svc] != undefined &&
                    Number(ip.dueDays) > maxDue[svc]
                ) {
                    V3Failed = true
                    errors.push(
                        `${svc} dueDays (${ip.dueDays}) exceed maximum (${maxDue[svc]}) (V3)`
                    )
                }

                if (svc == 'AA' && selectedClientCategory != 'Corporate') {
                    const due = new Date(ip.dueDate)
                    if (!(due.getMonth() == 0 && due.getDate() == 31)) {
                        V3Failed = true
                        errors.push('AA dueDate is not January 31 (V3)')
                    }
                }
            })
            const today = new Date()

            // V4: Check if today falls between period end and deadline and in-progress record is missing.
            inProgress.forEach((ip) => {
                let ipStart = new Date(ip.periodStartDate)
                let ipEnd = new Date(ip.periodEndDate)
                if (ipEnd < new Date(ip.dueDate)) {
                    ipStart = new Date(ip.periodEndDate)
                    ipEnd = new Date(ip.dueDate)
                }
                if (!(today >= ipStart && today <= ipEnd)) {
                    V4Failed = true
                    errors.push(
                        `${ip.serviceName} in‑progress record exists but current date is not within its period (${ip.periodStartDate} - ${ip.periodEndDate}) (V4)`
                    )
                }
            })
            // Corporate-only additional check:
            if (selectedClientCategory === 'Corporate' && item.govInfo) {
                const today = new Date()

                // --- Check for CS in the statement window ---
                const firstStatementDate = new Date(
                    item.govInfo.firstStatementDate
                )
                const statementDueDate = new Date(item.govInfo.statementDueDate)
                if (
                    !isNaN(firstStatementDate.getTime()) &&
                    !isNaN(statementDueDate.getTime())
                ) {
                    if (
                        today >= firstStatementDate &&
                        today <= statementDueDate
                    ) {
                        // First check in inProgress array
                        const csInProgress = inProgress.some(
                            (ip) => ip.serviceName.toUpperCase() === 'CS'
                        )
                        if (!csInProgress) {
                            // Additional check in completeDetails (same as inProgress) for same period start year
                            const statementYear =
                                firstStatementDate.getFullYear()
                            const csInCompleted = completeDetails.some(
                                (cd) =>
                                    cd.serviceName.toUpperCase() === 'CS' &&
                                    new Date(
                                        cd.periodStartDate
                                    ).getFullYear() === statementYear
                            )
                            if (!csInCompleted) {
                                V5Failed = true
                                errors.push(
                                    'No CS in-progress or completed record found in statement window (V5)'
                                )
                            }
                        }
                    }
                }

                // --- Check for AA in the accounts window ---
                const firstAccountsDate = new Date(
                    item.govInfo.firstAccountsDate
                )
                const accountsDueDate = new Date(item.govInfo.accountsDueDate)
                if (
                    !isNaN(firstAccountsDate.getTime()) &&
                    !isNaN(accountsDueDate.getTime())
                ) {
                    if (
                        today >= firstAccountsDate &&
                        today <= accountsDueDate
                    ) {
                        // First check in inProgress array
                        const aaInProgress = inProgress.some(
                            (ip) => ip.serviceName.toUpperCase() === 'AA'
                        )
                        if (!aaInProgress) {
                            // Additional check in completeDetails for same period start year
                            const accountsYear = firstAccountsDate.getFullYear()
                            const aaInCompleted = completeDetails.some(
                                (cd) =>
                                    cd.serviceName.toUpperCase() === 'AA' &&
                                    new Date(
                                        cd.periodStartDate
                                    ).getFullYear() === accountsYear
                            )
                            if (!aaInCompleted) {
                                V5Failed = true
                                errors.push(
                                    'No AA in-progress or completed record found in accounts window (V5)'
                                )
                            }
                        }
                    }
                }
            }

            newVerifyResults.push({
                name: item.clientInfo
                    ? item.clientInfo.clientName
                    : item.record?.clientnumber || 'Unknown',
                result: errors.join(' | ') || 'Validation Success',
            })

            if (selectedClientCategory == 'Corporate') {
                return {
                    ...item,
                    validation: {
                        V1Failed,
                        V2Failed,
                        V3Failed,
                        V4Failed,
                        V5Failed,
                    },
                }
            } else {
                return {
                    ...item,
                    validation: { V1Failed, V2Failed, V3Failed, V4Failed },
                }
            }
        })

        setAdditionalData(updatedData)
        setVerifyResults(newVerifyResults)
        setVerifying(false)
    }

    // ---------------------
    // FILTERING FOR ADDITIONAL DATA VIEW
    // For Corporate, only filters V1–V4 (and V5 if desired) are shown.
    // ---------------------
    const filterAdditionalData = (record: AdditionalData): boolean => {
        if (additionalFilter.length == 0) return true
        if (selectedClientCategory == 'Corporate') {
            for (const filter of additionalFilter) {
                if (filter == 'V1' && !record.validation?.V1Failed) return false
                if (filter == 'V2' && !record.validation?.V2Failed) return false
                if (filter == 'V3' && !record.validation?.V3Failed) return false
                if (filter == 'V4' && !record.validation?.V4Failed) return false
                if (filter == 'V5' && !record.validation?.V5Failed) return false
            }
        } else {
            // For non-corporate, we filter by V1, V2, V3 and V4 (if missing in-progress)
            for (const filter of additionalFilter) {
                if (filter == 'V1' && !record.validation?.V1Failed) return false
                if (filter == 'V2' && !record.validation?.V2Failed) return false
                if (filter == 'V3' && !record.validation?.V3Failed) return false
                if (filter == 'V4' && !record.validation?.V4Failed) return false
            }
        }
        return true
    }

    const downloadValidationFails = () => {
        const dataForExcel = verifyResults.map((vr) => ({
            Name: vr.name,
            Result: vr.result,
        }))
        downloadExcel(dataForExcel, 'ValidationFails.xlsx')
    }

    // ---------------------
    // RENDER ADDITIONAL DATA VIEW
    // ---------------------
    const renderAdditionalDataView = () => {
        const filteredData = additionalData.filter(filterAdditionalData)
        return (
            <div style={{ marginTop: '20px' }}>
                <h2>Additional Data View</h2>
                {selectedClientCategory == 'Corporate' ? (
                    <Select
                        mode="multiple"
                        style={{ width: '100%', marginBottom: '20px' }}
                        placeholder="Select Corporate validation filters (V1..V5, V4 for missing in-progress)"
                        onChange={(values: string[]) =>
                            setAdditionalFilter(values)
                        }
                        value={additionalFilter}
                    >
                        <Option value="V1">Service List Mismatch (V1)</Option>
                        <Option value="V2">Duplicate Period Year (V2)</Option>
                        <Option value="V3">
                            DueDays Exceed Max / AA DueDate (V3)
                        </Option>
                        <Option value="V4">Missing In‑Progress (V4)</Option>
                        <Option value="V5">
                            Incorp vs Acc Ref Mismatch (V5)
                        </Option>
                    </Select>
                ) : (
                    <Select
                        mode="multiple"
                        style={{ width: '100%', marginBottom: '20px' }}
                        placeholder="Select validation filters (V1, V2, V3, V4)"
                        onChange={(values: string[]) =>
                            setAdditionalFilter(values)
                        }
                        value={additionalFilter}
                    >
                        <Option value="V1">Service List Mismatch (V1)</Option>
                        <Option value="V2">Duplicate Period Year (V2)</Option>
                        <Option value="V3">
                            DueDays Exceed Max / AA DueDate (V3)
                        </Option>
                        <Option value="V4">Missing In‑Progress (V4)</Option>
                    </Select>
                )}
                {filteredData.length == 0 ? (
                    <p>No records match the selected filters.</p>
                ) : (
                    filteredData.map((item, idx) => {
                        const clientName = item.clientInfo
                            ? item.clientInfo.clientName
                            : item.record?.clientnumber || 'Unknown'
                        return (
                            <div
                                key={`client-${idx}`}
                                style={{
                                    border: '1px solid #ccc',
                                    marginBottom: '20px',
                                    padding: '10px',
                                }}
                            >
                                <h3>Client: {clientName}</h3>
                                {item.clientInfo && (
                                    <table
                                        border={1}
                                        cellPadding={5}
                                        style={{
                                            width: '100%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Client Name</th>
                                                <th>Service List</th>
                                                <th>Reference ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {item.clientInfo.clientName}
                                                </td>
                                                <td>
                                                    {
                                                        item.clientInfo
                                                            .serviceList
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.clientInfo
                                                            .referenceId
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {item.periodInfo && (
                                    <table
                                        border={1}
                                        cellPadding={5}
                                        style={{
                                            width: '100%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>From Date</th>
                                                <th>To Date</th>
                                                <th>Deadline Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {item.periodInfo.fromDate}
                                                </td>
                                                <td>
                                                    {item.periodInfo.toDate}
                                                </td>
                                                <td>
                                                    {
                                                        item.periodInfo
                                                            .deadlineDate
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {item.govInfo && (
                                    <table
                                        border={1}
                                        cellPadding={5}
                                        style={{
                                            width: '100%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Incorp. Date</th>
                                                <th>Accounting Ref</th>
                                                <th>First Accounts Date</th>
                                                <th>Accounts Due Date</th>
                                                <th>First Statement Date</th>
                                                <th>Statement Due Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {
                                                        item.govInfo
                                                            .incorporationDate
                                                    }
                                                </td>
                                                <td>
                                                    {typeof item.govInfo
                                                        .accountingReferenceDate ==
                                                    'object'
                                                        ? `${item.govInfo.accountingReferenceDate.day}/${item.govInfo.accountingReferenceDate.month}`
                                                        : item.govInfo
                                                              .accountingReferenceDate}
                                                </td>
                                                <td>
                                                    {
                                                        item.govInfo
                                                            .firstAccountsDate
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.govInfo
                                                            .accountsDueDate
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.govInfo
                                                            .firstStatementDate
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.govInfo
                                                            .statementDueDate
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {item.inProgressDetails &&
                                    item.inProgressDetails.length > 0 && (
                                        <>
                                            <h4>In-Progress Details</h4>
                                            <table
                                                border={1}
                                                cellPadding={5}
                                                style={{
                                                    width: '100%',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Year</th>
                                                        <th>Service Name</th>
                                                        <th>Period Start</th>
                                                        <th>Period End</th>
                                                        <th>Assign To</th>
                                                        <th>Assign Date</th>
                                                        <th>Due Days</th>
                                                        <th>Due Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.inProgressDetails.map(
                                                        (ip, i2) => (
                                                            <tr
                                                                key={`ip-${i2}`}
                                                            >
                                                                <td>
                                                                    {ip.year}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ip.serviceName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ip.periodStartDate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ip.periodEndDate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ip.assignTo
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ip.assignDate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {ip.dueDays}
                                                                </td>
                                                                <td>
                                                                    {ip.dueDate}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                {item.overdueDetails &&
                                    item.overdueDetails.length > 0 && (
                                        <>
                                            <h4>Overdue Details</h4>
                                            <table
                                                border={1}
                                                cellPadding={5}
                                                style={{
                                                    width: '100%',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Year</th>
                                                        <th>Service Name</th>
                                                        <th>Period Start</th>
                                                        <th>Period End</th>
                                                        <th>Assign To</th>
                                                        <th>Assign Date</th>
                                                        <th>Due Days</th>
                                                        <th>Due Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.overdueDetails.map(
                                                        (od, i2) => (
                                                            <tr
                                                                key={`od-${i2}`}
                                                            >
                                                                <td>
                                                                    {od.year}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        od.serviceName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        od.periodStartDate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        od.periodEndDate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        od.assignTo
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        od.assignDate
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {od.dueDays}
                                                                </td>
                                                                <td>
                                                                    {od.dueDate}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                {item.dashboardInfo && (
                                    <table
                                        border={1}
                                        cellPadding={5}
                                        style={{
                                            width: '100%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Service List</th>
                                                <th>Contact Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {
                                                        item.dashboardInfo
                                                            .serviceList
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.dashboardInfo
                                                            .contactName
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                                {item.validation &&
                                    selectedClientCategory == 'Corporate' && (
                                        <p>
                                            <strong>
                                                Validation Flags (Corporate):
                                            </strong>{' '}
                                            {`[V1: ${item.validation.V1Failed ? 'Failed' : 'Passed'}; V2: ${item.validation.V2Failed ? 'Failed' : 'Passed'}; V3: ${item.validation.V3Failed ? 'Failed' : 'Passed'}; V4: ${item.validation.V4Failed ? 'Failed' : 'Passed'}; V5: ${item.validation.V5Failed ? 'Failed' : 'Passed'}]`}
                                        </p>
                                    )}
                                {item.validation &&
                                    selectedClientCategory != 'Corporate' && (
                                        <p>
                                            <strong>
                                                Validation Flags
                                                (Non-Corporate):
                                            </strong>{' '}
                                            {`[V1: ${item.validation.V1Failed ? 'Failed' : 'Passed'}; V2: ${item.validation.V2Failed ? 'Failed' : 'Passed'}; V3: ${item.validation.V3Failed ? 'Failed' : 'Passed'}; V4: ${item.validation.V4Failed ? 'Failed' : 'Passed'}]`}
                                        </p>
                                    )}
                            </div>
                        )
                    })
                )}
                <Button
                    onClick={downloadValidationFails}
                    style={{ marginTop: '10px' }}
                >
                    Download Validation Fails
                </Button>
            </div>
        )
    }

    // ---------------------
    // RENDER COMPONENT
    // ---------------------
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Service Follow Up Data Comparison</h1>
            {/* Section 1: Original API1/API2 Comparison */}
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="service-select">Select Service: </label>
                <select
                    id="service-select"
                    onChange={handleServiceChange}
                    defaultValue=""
                >
                    <option value="" disabled>
                        Select a service
                    </option>
                    {serviceList.map((service) => (
                        <option key={service.id} value={service.id}>
                            {service.name}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="status-select">Select Status: </label>
                <Select
                    id="status-select"
                    style={{ width: 200 }}
                    defaultValue={0}
                    onChange={handleStatusChange}
                >
                    <Option value={0}>In Progress</Option>
                    <Option value={1}>Overdue</Option>
                    <Option value={2}>Completed</Option>
                </Select>
            </div>
            {showClientTypeDropdown && (
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="client-type-select">
                        Select Client Type:{' '}
                    </label>
                    <Select
                        id="client-type-select"
                        style={{ width: 200 }}
                        defaultValue={0}
                        onChange={handleClientTypeChange}
                    >
                        <Option value={0}>All</Option>
                        <Option value={1}>Corporate</Option>
                        <Option value={2}>Sole Trader</Option>
                        <Option value={3}>Individual</Option>
                        <Option value={4}>Partnership</Option>
                    </Select>
                </div>
            )}

            <div style={{ marginBottom: '20px' }}>
                <Button
                    onClick={fetchData}
                    loading={loading}
                    disabled={loading || comparing}
                >
                    Fetch Data
                </Button>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <Button
                    onClick={() => downloadExcel(dataApi1, 'API1_Data.xlsx')}
                    disabled={loading || comparing || !dataApi1.length}
                    style={{ marginRight: '10px' }}
                >
                    Download API1 Data Excel
                </Button>
                <Button
                    onClick={() => downloadExcel(dataApi2, 'API2_Data.xlsx')}
                    disabled={loading || comparing || !dataApi2.length}
                >
                    Download API2 Data Excel
                </Button>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <Button
                    onClick={compareData}
                    loading={comparing}
                    disabled={
                        loading ||
                        comparing ||
                        !dataApi1.length ||
                        !dataApi2.length
                    }
                >
                    Compare Data
                </Button>
            </div>
            {comparisonPerformed && comparisonResults.length == 0 && (
                <div style={{ marginBottom: '20px', color: 'green' }}>
                    <strong>All data equal</strong>
                </div>
            )}
            {comparisonResults.length > 0 && (
                <div>
                    <h2>Comparison Results</h2>
                    <table
                        border={1}
                        cellPadding={5}
                        style={{ borderCollapse: 'collapse', width: '100%' }}
                    >
                        <thead>
                            <tr>
                                <th>Client Name</th>
                                <th>Property</th>
                                <th>API1 Value</th>
                                <th>API2 Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {comparisonResults.map((result) =>
                                result.differences.map((diff, index) => (
                                    <tr
                                        key={`${result.clientName}-${diff.property}-${index}`}
                                    >
                                        {index == 0 && (
                                            <td
                                                rowSpan={
                                                    result.differences.length
                                                }
                                            >
                                                {result.clientName}
                                            </td>
                                        )}
                                        <td>{diff.property}</td>
                                        <td>{diff.api1Value?.toString()}</td>
                                        <td>{diff.api2Value?.toString()}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            onClick={() =>
                                downloadExcel(
                                    comparisonResults,
                                    'ComparisonResults.xlsx'
                                )
                            }
                            disabled={
                                loading ||
                                comparing ||
                                !comparisonResults.length
                            }
                        >
                            Download Comparison Excel
                        </Button>
                    </div>
                </div>
            )}

            <hr style={{ margin: '40px 0' }} />

            {/* Section 2: Additional Data Flow */}
            <h1>Additional Data Comparison</h1>
            <div style={{ marginBottom: '20px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="client-category-additional">
                        Select Client Category:{' '}
                    </label>
                    <Select
                        id="client-category-additional"
                        style={{ width: 200 }}
                        placeholder="Choose a category"
                        onChange={(value: string) =>
                            setSelectedClientCategory(value)
                        }
                    >
                        <Option value="Corporate">Corporate</Option>
                        <Option value="Individual">Individual</Option>
                        <Option value="SoleTrader">Sole Trader</Option>
                        <Option value="Partnership">Partnership</Option>
                    </Select>
                </div>
                <Button
                    onClick={fetchAdditionalDataAll}
                    loading={additionalLoading}
                    disabled={
                        additionalLoading ||
                        !selectedClientCategory ||
                        !selectedBusinessType
                    }
                >
                    Fetch Additional Data
                </Button>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <Button
                    onClick={() => setViewAdditional(true)}
                    disabled={additionalLoading || additionalData.length == 0}
                >
                    View Additional Data
                </Button>
                <Button
                    onClick={verifyAdditionalData}
                    loading={verifying}
                    disabled={
                        additionalLoading ||
                        verifying ||
                        additionalData.length == 0
                    }
                    style={{ marginLeft: '10px' }}
                >
                    Verify Additional Data
                </Button>
            </div>
            {verifyResults.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h3>Verification Results:</h3>
                    {verifyResults.map((vr, i) => (
                        <p
                            key={`vr-${i}`}
                            style={{
                                color: vr.result.includes('Success')
                                    ? 'green'
                                    : 'red',
                            }}
                        >
                            {vr.name}: {vr.result}
                        </p>
                    ))}
                </div>
            )}
            {viewAdditional && renderAdditionalDataView()}
        </div>
    )
}

export default DashboardComparison
