import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Space, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { SettingOutlined, CheckOutlined } from '@ant-design/icons'
import MessageListExpandView from './meesageExpandView'
import calculatePagination from '../../../Utils/Pagination'
import {
    GetNotificationList,
    SetReadMessage,
} from '../../../Services/Notificcations'
import {
    type NotificationState,
    type MessageTable,
    type NotificationTableInterface,
} from '../../../Types/Notifications'
import { useNavigate } from 'react-router-dom'

const MessageList = (props: MessageTable): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        notificationlist,
        notificationlistCount,
        flegId,
        serviceId,
        buttonId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [markasread, setMarkasread] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [notficcationId, setNotificationId] = useState(0)
    const [status, setStatus] = useState(0)
    const navigate = useNavigate()
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        if (buttonId !== undefined) {
            GetNotificationList(dispatch, buttonId, 0, serviceId, {
                pageNo,
                pageSize,
                searchCriteriaList,
                sortOrderList,
            })
        }
    }, [
        buttonId,
        flegId,
        serviceId,
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: notificationlistCount,
            },
        })
    }, [notificationlistCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        notificationlistCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<NotificationTableInterface> = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Date Info',
            dataIndex: 'dateInfo',
        },
        {
            title: 'Assign Information',
            dataIndex: 'fromUser',
        },
        {
            title: 'NotificationDate',
            dataIndex: 'notificationDate',
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record: NotificationTableInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Service Process">
                            <SettingOutlined
                                onClick={() => {
                                    const serviceprocessId =
                                        record.notificationReferenceId
                                    navigate(`/thingto-do/service-process`, {
                                        state: {
                                            serviceprocessId,
                                            serviceId,
                                        },
                                    })
                                }}
                            />
                        </Tooltip>
                        {record.markAsRead === 0 ? (
                            <Tooltip title="Mark as Read">
                                <CheckOutlined
                                    onClick={() => {
                                        markasreadModalFunction(
                                            record.notificationId,
                                            1
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<NotificationTableInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    const markasreadModalFunction = (
        notificationId: number,
        status: number
    ): void => {
        setNotificationId(notificationId)
        setStatus(status)
        setMarkasread(!markasread)
    }

    const Markasread = (): void => {
        if (buttonId !== undefined) {
            SetReadMessage(dispatch, buttonId, notficcationId, status)
            getTableData()
            markasreadModalFunction(0, 0)
        }
    }

    const notificationlistWithKeys: NotificationTableInterface[] =
        notificationlist?.map((item) => ({
            ...item,
            key: item.notificationId.toString(),
        }))

    const expandableData = (
        record: NotificationTableInterface
    ): React.ReactElement => {
        return <MessageListExpandView messageDetailsData={record} />
    }

    return (
        <>
            <SearchInput
                placeHolder={'Search Your Keyword & enter'}
                onSearch={onChangeFilter}
                onChange={onChangeText}
                width="478"
            />
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={notificationlistWithKeys}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={markasread}
                closeModal={() => {
                    markasreadModalFunction(0, 0)
                }}
                tittle="You are about to mark as read this record. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={Markasread}
            />
        </>
    )
}

const mapStateToProps = (state: any): NotificationState => {
    return {
        notificationlist: state.notification.notificationlist,
        notificationlistCount: state.notification.notificationListCount,
    }
}

export default connect(mapStateToProps)(MessageList)
