import { Button, Col, Form, Input, Row, Select, Tooltip, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    getBankInfo,
    SetInvoiceStatus,
    SetInvoiceDetails,
} from '../../../../Services/Invoicing/Invoice'
// import Money from '../../../../Assest/Svg/DashboardTodoMoney.svg'
import { type ToBeRaisedFormData } from '../../../../Types/Invoicing/Invoice'
import commonStyles from '../../../../Utils/Common.less'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
const ToBeRaised = (props: ToBeRaisedFormData): React.ReactElement => {
    const [form] = Form.useForm()
    const { GetBankInfo, record, onTableChange, resetdata, setResetData } =
        props
    const dispatch = useDispatch()
    const [currentDate, setCurrentDate] = useState<string>('')
    const [buttonloading, setButtonloading] = useState(false)
    const companyType = record.businessTypeId ?? 0
    const referenceId = record.referenceId ?? 0

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
        }

        setResetData('false')
    }, [resetdata])

    useEffect(() => {
        getBankInfo(dispatch)
        const formattedDate = new Date().toLocaleDateString('en-GB')
        setCurrentDate(formattedDate)
    }, [dispatch])
    const onFinish = (valuse: any): void => {
        void onFinishData(valuse)
    }
    const onFinishData = async (values: any): Promise<void> => {
        try {
            setButtonloading(true)
            await form.validateFields()
            const amount = parseFloat(values.Amount)
            const data = {
                invoicePaymentStatusId: 0,
                serviceProcessId: record.serviceProcessId ?? 0,
                currentStatus: 'INVOICED',
                Description: values.description ?? '',
                PaymentStatus: 'UnPaid',
                PaymentOptionId: parseInt(values.clientCategoryId ?? '0', 10),
                Amount: amount,
            }

            const invoiceData = {
                invoiceId: 0,
                invoiceNo: '0',
                invoiceCategory: 0,
                businessType: companyType,
                invoiceFor: referenceId ?? 0,
                invoiceForDescription: '',
                invoiceDate: currentDate,
                paymentDueDate: currentDate,
                total: amount,
                subTotal: amount,
                discountTotal: 0,
                taxTotal: 0,
                netTotal: amount,
                preparedBy: 1,
                paymentNotifyTo: 0,
                paidTo: 0,
                paidToDescription: 'no',
                approvedBy: 1,
                invoiceStatus: 1,
                paymentModeId: 0,
                paymentOptionId: parseInt(values.clientCategoryId ?? '0', 10),
                remarks: '',
                clientId: '',
                clientName: '',
                paymentOptionName: '',
                acc_InvoiceDetailList: [
                    {
                        discount: 0,
                        discountValue: 0,
                        discountType: 2,
                        invoiceId: 0,
                        lineDescription: values.description ?? '',
                        grossTotal: amount,
                        netAmount: amount,
                        quantity: 1,
                        serviceId: record.serviceId,
                        taxApplied: 0,
                        tax: 0,
                        totalAfterDiscount: 0,
                        unitPrice: 0,
                    },
                ],
                acc_InvoiceDetailViewList: [
                    {
                        discount: 0,
                        discountType: 0,
                        discountValue: 0,
                        grossTotal: amount,
                        invoiceDetailId: 0,
                        invoiceId: 0,
                        lineDescription: values.description ?? '',
                        netAmount: amount,
                        quantity: 0,
                        serviceId: record.serviceId,
                        tax: 0,
                        taxApplied: 0,
                        totalAfterDiscount: 0,
                        unitPrice: 0,
                        serviceName: '',
                        description: '',
                    },
                ],
                isSent: 0,
                sentDate: '',
                serviceProcessId: record.serviceProcessId ?? 0,
                isPaid: 0,
                paidDate: '',
                paidRemarks: '',
                accountName: '',
                accountNo: '',
                branch: '',
                swiftCode: '',
                iban: '',
            }

            await SetInvoiceStatus(dispatch, data)

            await SetInvoiceDetails(dispatch, invoiceData)
                .then((result) => {
                    if (result !== null && result !== undefined) {
                        setButtonloading(false)
                        onTableChange()
                    }
                })
                .catch(() => {})
        } catch (error) {
            void message.error('Failed to submit form: ')
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={onFinish}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="departmentId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Amount"
                            name="Amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Amount!',
                                },
                                {
                                    validator: async (_, value) => {
                                        value < 0
                                            ? await Promise.reject(
                                                  new Error(
                                                      'invoice amount cannot be negative.'
                                                  )
                                              )
                                            : await Promise.resolve()
                                    },
                                },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Invoice From" name="clientCategoryId">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                dropdownRender={(menu) => <div>{menu}</div>}
                                options={GetBankInfo?.map(
                                    (bank: {
                                        id: number
                                        displayName: string
                                        additionalDataOne: string
                                        displayImageURL: string
                                    }) => ({
                                        value: bank.id,
                                        label: (
                                            <div>
                                                {/* <img
                                                    src={Money}
                                                    style={{
                                                        width: 20,
                                                        marginLeft: 10,
                                                    }}
                                                /> */}
                                                <span>{`${bank.displayName} - ${bank.additionalDataOne}`}</span>
                                            </div>
                                        ),
                                    })
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                {ActivePremission('6000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title={'Create Invoice'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={buttonloading}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        GetBankInfo: state.invoice.GetBankInfo ?? [],
        GetAddressInfo: state.invoice.GetAddressInfo ?? [],
    }
}

export default connect(mapStateToProps)(ToBeRaised)
