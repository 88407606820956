import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    Card,
    Row,
    Menu,
    Modal,
    Col,
    Avatar,
    Collapse,
    Table,
    Pagination,
} from 'antd'
import commonStyles from '../../Utils/Common.less'
import {
    DownOutlined,
    UserOutlined,
    EllipsisOutlined,
    EditOutlined,
} from '@ant-design/icons'
import SearchInput from '../../Components/SearchInput'
import CommonButton from '../../Components/Commonbutton'
import Dropdown from 'antd/es/dropdown/dropdown'
import SelectiveButton from '../../Components/SelectiveButton'
import styles from './backLogList.less'
import CreateSprint from './createSprint'
import ToDo from './toDo'
import Inprogress from './inprogress'
import {
    GetBacklog,
    GetSprint,
    GetSprintTask,
    TaskMoveToSprint,
    setSprint,
} from '../../Services/Helpdesk/Backlog'
import { type TableParamsInterface } from '../../Types/CommonType'
import { commonTableParams } from '../../Utils/CommontTable'
import { type ColumnsType } from 'antd/es/table'
import {
    type GetSprintListInterfaceType,
    type BacklogListStateInterface,
    type createSprintForm,
} from '../../Types/Backlog/Backlog'
import { GetUserList } from '../../Services/User'

const BackLogList = (props: BacklogListStateInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        sprintList,
        sprintTaskList,
        sprintListCount,
        sprintBackLogList,
        sprintBackLogListCount,
    } = props
    const [activeButton, setActiveButton] = useState(0)
    const [modalTitle, setModalTitle] = useState('Modal Title')
    const [modalVisible, setModalVisible] = useState(false)
    const [startSprintVisible, setStartSprintVisible] = useState(false)
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const [showTable, setShowTable] = useState(false)
    const { Panel } = Collapse
    const [sprintId, setSprintId] = useState(0)
    const [searchCriteriaList] = useState([
        {
            fieldName: 'multiplesearch',
            searchOperator: 10,
            fieldValue1: '',
            fieldValue2: '',
        },
    ])
    const [visibleTaskId, setVisibleTaskId] = useState(null)
    const [lastCreatedSprint, setLastCreatedSprint] = useState<any>('')
    const [lastCreatedSprint2, setLastCreatedSprint2] = useState<any>('')
    const [sprinstatus, setSprintStatus] = useState(0)
    const [backlogCurrentPage, setBacklogCurrentPage] = useState(1)
    const [currentStatus, setCurrentStatus] = useState('')
    const [searchString, setSearchString] = useState('=NA=')
    const [searchStringSprintTask, setSearchStringSprintTask] = useState('=NA=')

    const hasFetchedOnce = useRef(false)
    const backlogItemsPerPage = 10
    const hasFetchedOnce1 = useRef(false)
    useEffect(() => {
        if (sprintList?.length > 0) {
            const sortedSprintList = sprintList
                .slice()
                .sort(
                    (a: { sprintId: number }, b: { sprintId: number }) =>
                        b.sprintId - a.sprintId
                )
            const maxIdSprint = sortedSprintList[0]
            const maxIdSprint2 = sortedSprintList[1]
            setLastCreatedSprint(maxIdSprint)
            setLastCreatedSprint2(maxIdSprint2)
        }
    }, [sprintList])

    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetUserList(dispatch)
    }, [])

    const projectStatusView = (name: string): void => {
        if (name.length > 0) {
            setCurrentStatus(name)
        }
    }

    const handleModalClose = (): void => {
        setModalVisible(false)
    }

    const handlePageChange = (page: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(page)
    }

    const setSprintData = async (
        data: createSprintForm,
        callBack: CallableFunction
    ): Promise<void> => {
        await setSprint(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    callBack()
                    setSprintStatus((prevStatus) => prevStatus + 1)
                }
            })
            .catch(() => {})
    }

    const refreshSprintTaskList = useCallback(() => {
        GetSprintTask(dispatch, {
            sprintId,
            flag: 2,
            searchString: searchStringSprintTask,
            pageNo: 1,
            pageSize: 10,
            assignedBy: 0,
            assignedTo: 0,
        })
    }, [dispatch, sprintId, searchStringSprintTask])

    const sprint1 = async (taskId: number, flag: number): Promise<void> => {
        const data = {
            typeId: 1,
            taskId,
            sprintId: lastCreatedSprint.sprintId,
            flagId: flag,
        }

        try {
            TaskMoveToSprint(dispatch, data)
            refreshSprintTaskList()
            getTableData()
        } catch (error) {
            console.error('Failed to move task to sprint:', error)
        }
    }

    const getTableData = useCallback(() => {
        GetBacklog(dispatch, {
            flag: 2,
            searchString,
            pageNo: backlogCurrentPage,
            pageSize: backlogItemsPerPage,
            assignedBy: 0,
            assignedTo: 0,
        })
    }, [dispatch, backlogCurrentPage, backlogItemsPerPage, searchString])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData, searchString])

    useEffect(() => {
        refreshSprintTaskList()
    }, [refreshSprintTaskList])

    useEffect(() => {
        GetSprint(dispatch, {
            pageNo: currentPage,
            pageSize: 10,
            searchCriteriaList,
            sortOrderList: [{}],
        })
    }, [dispatch, sprintId, currentPage, sprinstatus])

    const onChangeFilter = (data: string): void => {
        if (data.length !== 0) {
            setSearchString(data)
        } else {
            setSearchString('=NA=')
        }
    }

    const onChangeFilter2 = (data: string): void => {
        setSearchStringSprintTask(data)
    }

    // useEffect(() => {
    //     getTableData()
    // }, [getTableData])
    const handleBacklogPageChange = (page: number): void => {
        setBacklogCurrentPage(page)
    }

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: sprintListCount,
            },
        })
    }, [sprintListCount])

    const onSaveSprint = (data: any, callBack: CallableFunction): void => {
        const startDateMoment = data.sprintStartDate
        const endDateMoment = data.sprintEndDate
        const formattedStartDate = startDateMoment.format('D/M/YYYY')
        const formattedEndDate = endDateMoment.format('D/M/YYYY')

        const newData = {
            ...data,
            sprintId: 0,
            departmentId: 0,
            clientCategoryId: 0,
            sprintStartDate: formattedStartDate,
            sprintEndDate: formattedEndDate,
        }
        void setSprintData(newData, callBack)
    }

    // const handleOtherFunction = (): React.ReactElement => {
    //     if (activeButton === 1) {
    //         // return <DetailReport />
    //     } else if (activeButton === 2) {
    //         // return <TotalReport />
    //     }
    //     return <></>
    // }

    const columns: ColumnsType<any> = [
        {
            render: (record) => (
                <div>
                    <input type="checkbox" checked />
                </div>
            ),
        },
        {
            dataIndex: 'taskName',
        },
        {
            dataIndex: 'priorityName',
        },
        {
            render: (text, record) => (
                <div className={styles.sprintListContainer}>
                    <Avatar icon={<UserOutlined />} />
                    <EllipsisOutlined
                        className={styles.sprintListElipseStyle}
                        onClick={() => {
                            handleEllipse(record)
                        }}
                    />
                    {visibleTaskId === record.taskId && (
                        <Dropdown
                            placement="bottomLeft"
                            overlay={
                                <Menu>
                                    <Menu.SubMenu
                                        key="moveToSubMenu"
                                        title="Move To"
                                    >
                                        <Menu.Item
                                            key="sprint1"
                                            onClick={() => {
                                                void sprint1(record.taskId, 1)
                                            }}
                                        >
                                            {lastCreatedSprint.sprintName ?? ''}
                                        </Menu.Item>
                                        <Menu.Item key="sprint2">
                                            {lastCreatedSprint2.sprintName ??
                                                ''}
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                    {/* <Menu.Item key="option2">Delete</Menu.Item>
                                    <Menu.Item key="option3">Edit</Menu.Item> */}
                                </Menu>
                            }
                        >
                            <a
                                className="ant-dropdown-link"
                                onClick={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                <DownOutlined />
                            </a>
                        </Dropdown>
                    )}
                </div>
            ),
        },
    ]

    const columns2: ColumnsType<any> = [
        {
            render: (record) => (
                <div>
                    <input type="checkbox" checked />
                </div>
            ),
        },
        {
            dataIndex: 'taskName',
        },
        {
            dataIndex: 'priorityName',
        },
        {
            render: (text, record) => (
                <button
                    onClick={() => {
                        void sprint1(record.taskId, 2)
                    }}
                >
                    Remove
                </button>
            ),
        },
    ]

    const handleEllipse = (record: any): void => {
        setVisibleTaskId(record.taskId)
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        // if (buttonName === 1) {
        // }
        // if (buttonName === 2) {
        // }
        // if (buttonName === 3) {
        // }
    }

    const handleModalOpen = (): void => {
        setModalVisible(true)
        setModalTitle('Create Sprint')
    }

    const handleModalStartSprint = (sprintId: number): void => {
        setStartSprintVisible(true)
        setSprintId(sprintId)
    }

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handleBackLogClick = (): void => {
        setShowTable(!showTable)
    }

    const handleCollapseChange = (key: string | string[]): void => {
        if (Array.isArray(key) && key?.length > 0) {
            const sprintId = parseInt(key[0], 10)
            setSprintId(sprintId)
        } else if (typeof key === 'string') {
            const sprintId = parseInt(key, 10)
            setSprintId(sprintId)
        }
    }

    return (
        <div>
            <Card className={commonStyles.card}>
                <Row align="middle" className={commonStyles.mainRow}>
                    <div className={styles.btnStartQaComlete}>
                        {startSprintVisible && (
                            <CommonButton
                                name="TASK"
                                isActive={activeButton === 1}
                                clickAction={() => {
                                    projectStatusView('1')
                                }}
                                additionalOnClick={() => {
                                    handleButtonClick(1)
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.btnStartQaComlete}>
                        {startSprintVisible && (
                            <CommonButton
                                name="QA"
                                isActive={activeButton === 2}
                                clickAction={() => {
                                    projectStatusView('2')
                                }}
                                additionalOnClick={() => {
                                    handleButtonClick(2)
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.btnStartQaComlete}>
                        {startSprintVisible && (
                            <CommonButton
                                name="Completed"
                                isActive={activeButton === 3}
                                clickAction={() => {
                                    projectStatusView('')
                                }}
                                additionalOnClick={() => {
                                    handleButtonClick(3)
                                }}
                            />
                        )}
                    </div>
                </Row>{' '}
                {!startSprintVisible && (
                    <Row>
                        <div
                            onClick={handleBackLogClick}
                            className={styles.backlogHeaderContainerdiv}
                        >
                            <Collapse collapsible="icon">
                                <Collapse.Panel
                                    header={
                                        <Row className={styles.BacklogHeader}>
                                            <Col
                                                span={8}
                                                className={
                                                    styles.backlogColumnDiv
                                                }
                                            >
                                                <div>Backlog</div>
                                                <div
                                                    className={
                                                        styles.formOutlineBackogCollapse
                                                    }
                                                >
                                                    <EditOutlined />
                                                </div>
                                            </Col>

                                            <Col span={3}>
                                                <SelectiveButton
                                                    name="Create Sprint"
                                                    clickAction={
                                                        handleModalOpen
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    key="1"
                                >
                                    <div style={{ marginBottom: '30px' }}>
                                        <SearchInput
                                            placeHolder={
                                                'Search CompanyName by keywords'
                                            }
                                            onSearch={onChangeFilter}
                                            // onChange={onChangeText}
                                            width="378"
                                        />
                                    </div>
                                    <div>
                                        <Table
                                            dataSource={sprintBackLogList}
                                            columns={columns}
                                            size="middle"
                                            className={
                                                styles.sprintBackListTable
                                            }
                                            pagination={false}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                marginTop: '20px',
                                            }}
                                        >
                                            <Pagination
                                                current={backlogCurrentPage}
                                                total={sprintBackLogListCount}
                                                pageSize={backlogItemsPerPage}
                                                onChange={
                                                    handleBacklogPageChange
                                                }
                                                showSizeChanger={false}
                                                showQuickJumper={false}
                                            />
                                        </div>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    </Row>
                )}
                {!startSprintVisible && (
                    <Row>
                        <Row style={{ width: '100%' }}>
                            {sprintList.map(
                                (
                                    sprint: GetSprintListInterfaceType,
                                    index: number
                                ) => (
                                    <div
                                        key={sprint.sprintId}
                                        style={{
                                            marginBottom: '5px',
                                            width: '100%',
                                        }}
                                    >
                                        <Collapse
                                            accordion
                                            className={
                                                styles.sprintListoutStyles
                                            }
                                            collapsible="icon"
                                            size="small"
                                            onChange={handleCollapseChange}
                                            bordered={false}
                                        >
                                            <Panel
                                                key={sprint.sprintId}
                                                header={
                                                    <Row
                                                        style={{
                                                            display: 'flex',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Col
                                                            span={8}
                                                            style={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <div>
                                                                {
                                                                    sprint.sprintName
                                                                }
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.editOutLinedSprintList
                                                                }
                                                            >
                                                                <EditOutlined />
                                                            </div>
                                                        </Col>
                                                        <div
                                                            className={
                                                                styles.startSprintButtonSprintList
                                                            }
                                                        >
                                                            <Col span={6}>
                                                                <SelectiveButton
                                                                    name="Start Sprint"
                                                                    clickAction={() => {
                                                                        handleModalStartSprint(
                                                                            sprint.sprintId
                                                                        )
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col
                                                                span={10}
                                                                style={{
                                                                    marginLeft:
                                                                        '70px',
                                                                }}
                                                            >
                                                                <EllipsisOutlined
                                                                    className={
                                                                        styles.EllipsisOutlinedSprintList
                                                                    }
                                                                />
                                                            </Col>
                                                        </div>
                                                    </Row>
                                                }
                                                className={
                                                    styles.sprintListCollapsePanel
                                                }
                                            >
                                                <div
                                                    style={{
                                                        marginBottom: '30px',
                                                    }}
                                                >
                                                    <SearchInput
                                                        placeHolder={
                                                            'Search Sprint Task'
                                                        }
                                                        onSearch={
                                                            onChangeFilter2
                                                        }
                                                        width="378"
                                                    />
                                                </div>
                                                <div>
                                                    <Table
                                                        dataSource={
                                                            sprintTaskList
                                                        }
                                                        columns={columns2}
                                                        bordered={false}
                                                        size="middle"
                                                        style={{
                                                            marginTop: '-10px',
                                                        }}
                                                    />
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                )
                            )}
                        </Row>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '20px',
                                width: '100%', // Ensure this div takes full width
                            }}
                        >
                            <Pagination
                                current={currentPage}
                                total={sprintListCount}
                                pageSize={itemsPerPage}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                showQuickJumper={false}
                            />
                        </div>
                    </Row>
                )}
                <Row>
                    <Col span={12}>
                        {startSprintVisible && (
                            <ToDo
                                sprintTaskList={sprintTaskList}
                                currentStatus={currentStatus}
                            />
                        )}
                    </Col>
                    <Col span={12}>
                        {startSprintVisible && (
                            <Inprogress
                                sprintTaskList={sprintTaskList}
                                currentStatus={currentStatus}
                            />
                        )}
                    </Col>
                </Row>
            </Card>
            <Modal
                title={modalTitle}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
            >
                <CreateSprint
                    onSave={onSaveSprint}
                    handleModalClose={handleModalClose}
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): BacklogListStateInterface => {
    return {
        sprintList: state.backlog.sprintList,
        sprintListCount: state.backlog.sprintListCount,
        sprintTaskList: state.backlog.sprintTaskList,
        sprintBackLogList: state.backlog.sprintBackLogList,
        sprintBackLogListCount: state.backlog.sprintBackLogListCount,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(BackLogList)
