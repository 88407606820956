import { Card, Col, Collapse, Flex, Modal, Row, Space, Table } from 'antd'
import React, { useState } from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
import {
    type CS_ShareCapitalDetail,
    type CompanyDetailsprops,
} from '../../../Types/CompanyHouse/CompanyOverView'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import AddNewCurrencyList from '../CompanyHouseRelatedThings/addnewcurrencyClass'
import { type ColumnsType } from 'antd/es/table'
import AddNewShareClass from '../CompanyHouseRelatedThings/addNewShareClass'
import FullWidthModal from '../../../Components/FullWidthModal'
import { useDispatch } from 'react-redux'
import {
    DeleteShareDetails,
    GetCompanyOverViewData,
} from '../../../Services/CompanyHouse/CompanyOverView'
const defaultShareCapitalDetail = {
    shareCapitalDetailId: 0,
    shareCapitalId: 0,
    shareClass: '',
    noofShares: 0,
    aggregateNominalValue: 0,
    particulars: '',
    currencyName: '',
}
const ShareCapital = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId, companyHouseOverViewList, editId, businessId } =
        props
    const [currencyModel, setcurrencyModel] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Banking Details'
    )
    const [editData, setEditData] = useState<CS_ShareCapitalDetail>(
        defaultShareCapitalDetail
    )
    const [shareDetailsId, setShareDetailsId] = useState(0)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [currencyName, setCurrencyName] = useState('')
    const [shareCapitalId, setshareCapitalId] = useState(0)
    const [shareCapitalDetailId, setShareCapitalDetailId] = useState(0)
    const dispatch = useDispatch()
    const columns: ColumnsType<CS_ShareCapitalDetail> = [
        {
            title: 'Share Class',
            dataIndex: 'shareClass',
            key: 'shareClass',
        },
        {
            title: 'No of Shares',
            dataIndex: 'noofShares',
            key: 'noofShares',
        },
        {
            title: 'Aggregate Nominal Value',
            dataIndex: 'aggregateNominalValue',
            key: 'aggregateNominalValue',
        },
        {
            title: 'Prescribed Particulars',
            dataIndex: 'particulars',
            key: 'particulars',
        },
        ...(editId === 1
            ? [
                  {
                      title: '',
                      key: 'action',
                      render: (record: CS_ShareCapitalDetail) => (
                          <Space size="middle">
                              <CusttomBackgroundButton
                                  color={'#175ca9'}
                                  name={''}
                                  icon={<EditOutlined />}
                                  clickAction={() => {
                                      openEditModal(
                                          record.shareCapitalDetailId,
                                          currencyName,
                                          record.shareCapitalId,
                                          record.shareCapitalDetailId
                                      )
                                  }}
                              />
                              <CusttomBackgroundButton
                                  color={'#175ca9'}
                                  name={''}
                                  icon={<DeleteOutlined />}
                                  clickAction={() => {
                                      deleteModalFunction(
                                          record.shareCapitalDetailId
                                      )
                                  }}
                              />
                          </Space>
                      ),
                  },
              ]
            : []),
    ]
    const OpenCurrencyModel = (): void => {
        setcurrencyModel(!currencyModel)
    }
    const changeModalStatus = (): void => {
        setcurrencyModel(!currencyModel)
    }
    const openEditModal = (
        id: number,
        currencyName: string,
        shareCapitalId: number,
        shareCapitalDetailId: number
    ): void => {
        setCurrencyName(currencyName)
        setshareCapitalId(shareCapitalId)
        setShareCapitalDetailId(shareCapitalDetailId)
        const shareCapitalDetail = companyHouseOverViewList?.cS_ShareCapitalList
            ?.flatMap((shareCapital) => shareCapital.cS_ShareCapitalDetailList)
            .find((detail) => detail.shareCapitalDetailId === id)

        let tittle = 'Add Share Capital'
        if (id !== 0) {
            tittle = 'Edit Share Capital'
        }

        setEditModalTittle(tittle)
        setEditData(shareCapitalDetail ?? defaultShareCapitalDetail)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const deleteModalFunction = (id: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let shareDetailsId = 0
        if (!deleteModalStatus) {
            shareDetailsId = id
        }
        setShareDetailsId(shareDetailsId)
    }

    const deleteShareDetails = (): void => {
        DeleteShareDetails(dispatch, shareDetailsId)
        setTimeout(() => {
            GetCompanyOverViewData(
                dispatch,
                businessId ?? 0,
                companyHouseOverViewList.corporateId,
                0,
                4
            )
            setDeleteModalStatus(!deleteModalStatus)
        }, 1000)
    }

    return (
        <>
            <div>
                <h3>Share Capital</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <div className={style.misstingInfo}>
                        If you want to do changes required in registered office
                        address should be filed using the{' '}
                        <b>&quot;Add New Currency and Share Class&quot;</b>,{' '}
                        <b>&quot;Add New Share Class&quot;</b> before you file
                        the confirmation statement.
                    </div>
                )}

                {editId === 1 && (
                    <>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#175ca9'}
                                name={'Add New Currency and Share Class'}
                                icon={<PlusOutlined />}
                                clickAction={OpenCurrencyModel}
                            />
                        </Flex>
                        <br />
                    </>
                )}

                {companyHouseOverViewList?.cS_ShareCapitalList?.length !== 0 ? (
                    companyHouseOverViewList?.cS_ShareCapitalList?.map(
                        (shares, index) => (
                            <Collapse defaultActiveKey={['']} key={index}>
                                <Collapse.Panel
                                    header={
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <span>
                                                Currency : {shares.currencyName}
                                            </span>
                                        </div>
                                    }
                                    key={1}
                                >
                                    {editId === 1 && (
                                        <>
                                            <div style={{ marginTop: '20px' }}>
                                                <Flex
                                                    justify="flex-end"
                                                    gap={5}
                                                >
                                                    <CusttomBackgroundButton
                                                        color={'#175ca9'}
                                                        name={'Add Share Class'}
                                                        icon={<PlusOutlined />}
                                                        clickAction={() => {
                                                            openEditModal(
                                                                0,
                                                                shares.currencyName,
                                                                shares.shareCapitalId,
                                                                0
                                                            )
                                                        }}
                                                    />
                                                </Flex>
                                            </div>
                                            <br />
                                        </>
                                    )}
                                    <Row gutter={[24, 8]}>
                                        <Col span={24}>
                                            <Table
                                                dataSource={
                                                    shares.cS_ShareCapitalDetailList
                                                }
                                                columns={columns}
                                                pagination={false}
                                            />
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row gutter={[24, 8]}>
                                        <Col span={8}>
                                            Total number of shares issued
                                        </Col>
                                        <Col span={8}>
                                            : {shares.totalSharesIssued}
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 8]}>
                                        <Col span={8}>
                                            Total aggregate value of shares
                                            issued
                                        </Col>
                                        <Col span={8}>
                                            : {shares.totalAggregateValue}
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 8]}>
                                        <Col span={8}>
                                            Total aggregate amount unpaid
                                        </Col>
                                        <Col span={8}>
                                            : {shares.totalAmountUnpaid}
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        )
                    )
                ) : (
                    <div>
                        <h3> No Record Found</h3>
                    </div>
                )}
            </Card>
            <Modal
                footer={null}
                onCancel={changeModalStatus}
                width={'50%'}
                title={'Add New Currency and Class'}
                open={currencyModel}
            >
                <AddNewCurrencyList
                    onClosemodel={changeModalStatus}
                    companyName={companyHouseOverViewList?.clientName ?? ''}
                    companyNumber={
                        companyHouseOverViewList?.companyRegNumber ?? ''
                    }
                    companyId={0}
                    corporateId={companyHouseOverViewList.corporateId ?? 0}
                    serviceProcessId={0}
                    businessId={businessId ?? 0}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={editModalTittle}
                open={createEditModalStatus}
            >
                <AddNewShareClass
                    onClosemodel={changeCreateEditModalStatus}
                    corporateId={companyHouseOverViewList.corporateId ?? 0}
                    serviceProcessId={0}
                    businessId={businessId ?? 0}
                    editData={editData}
                    currencyName={currencyName}
                    shareCapitalDetailId={shareCapitalDetailId}
                    shareCapitalId={shareCapitalId}
                />
            </Modal>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={() => {
                    deleteModalFunction(0)
                }}
                tittle="You are about to delete this share capital. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteShareDetails}
            />
        </>
    )
}

export default ShareCapital
