export const convertImageToBase64 = async (
    imageUrl: string
): Promise<string> => {
    try {
        const response = await fetch(imageUrl)
        const blob = await response.blob()

        return await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result)
                } else {
                    reject(new Error('Failed to convert image to Base64.'))
                }
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    } catch (error) {
        console.error('Error converting image to Base64:', error)
        throw error
    }
}

export const convertImageToBase642 = async (url: string): Promise<string> => {
    return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
            const reader = new FileReader()
            reader.onloadend = function () {
                resolve(reader.result as string)
            }
            reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = function () {
            reject(new Error('Error loading image'))
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    })
}
