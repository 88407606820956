import React, { type ReactElement, useState } from 'react'
import { Form, Select, Input, Col, Row } from 'antd'
import { connect } from 'react-redux'
import {
    type initialDetailsInterface,
    type SoleTraderHomeFormDataInterface,
    type initialState,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'

const InitialDetailsForm = (props: initialDetailsInterface): ReactElement => {
    const { categoryDropDownList, onCategorySelect } = props
    const { Option } = Select
    const [title, setTitle] = useState('')

    const handleTitleChange = (value: string): void => {
        setTitle(value)
    }

    return (
        <>
            <Form.Item<SoleTraderHomeFormDataInterface>
                name="soleTraderId"
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item<SoleTraderHomeFormDataInterface>
                name="clientNumber"
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="Client Category"
                        name="clientCategoryId"
                    >
                        <Select
                            allowClear
                            showSearch
                            onChange={(selectedId) => {
                                onCategorySelect(selectedId)
                            }}
                            filterOption={(input, option) =>
                                option?.label
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase()) ?? false
                            }
                            options={[
                                { value: 0, label: 'Please Select' },
                                ...categoryDropDownList,
                            ]}
                            defaultValue={1}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        name="title"
                        label="Title"
                    >
                        <Select
                            allowClear
                            showSearch
                            onChange={handleTitleChange}
                            value={title}
                        >
                            <Option value="UNKNOWN">UNKNOWN</Option>
                            <Option value="MR">MR</Option>
                            <Option value="MRS">MRS</Option>
                            <Option value="MISS">MISS</Option>
                            <Option value="Dr">DR</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="First Name"
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter First Name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="Middle Name"
                        name="middleName"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}></Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="Surname"
                        name="surName"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter surname!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="NatureOfBussiness"
                        name="natureOfBusiness"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="BussinessTradingName"
                        name="businessTradingName"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the Businesstrdingname!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item<SoleTraderHomeFormDataInterface>
                        label="Individual UTR"
                        name="individualUTR"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): initialState => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
    }
}

export default connect(mapStateToProps)(InitialDetailsForm)
