import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetDashboardNewsList,
    SaveDashboardNews,
    DeleteDashboardNews,
    ActiveInactiveDashboardNews,
} from '../../../Services/DashboardNews'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import DashboardNewsForm from './dashboardNewsForm'
import type {
    DashboardNewsListInterface,
    DashboardNewsListDataTypeInterface,
    DashboardNewsFormDataInterface,
} from '../../../Types/DashboardNews'
import DashboardNewsExpandView from './dashboardNewsExpandView'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
import { formatdate } from '../../../Utils/dateFormat'

export const DashboardNewsItemDefaultData = {
    newsId: '0',
    title: '',
    description: '',
    newsURL: '',
    newsDate: '',
    activeStatus: '',
}

const DashboardNewsList = (
    props: DashboardNewsListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { dashboardNewsList, dashboardNewsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [dashboardNewsId, setdashboardNewsId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState('Create News')
    const [editData, setEditData] = useState<DashboardNewsFormDataInterface>(
        DashboardNewsItemDefaultData
    )
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetDashboardNewsList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }

        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: dashboardNewsListCount,
            },
        })
    }, [dashboardNewsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        dashboardNewsListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<DashboardNewsListDataTypeInterface> = [
        {
            width: 350,
            title: 'Title',
            dataIndex: 'title',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            width: 150,
            title: 'News Date',
            key: 'newsDate',
            render: (_, record: DashboardNewsListDataTypeInterface) => {
                return <div>{formatdate(record.newsDate)}</div>
            },
        },
        {
            width: 350,
            title: ' News URL',
            dataIndex: 'newsURL',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: DashboardNewsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1010', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Dashboard News">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.newsId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.newsId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1010', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(record?.newsId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<DashboardNewsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let dashboardNewsId = ''
        if (!deleteModalStatus) {
            dashboardNewsId = id
        }
        setdashboardNewsId(dashboardNewsId)
    }

    const deleteDashboardNews = (): void => {
        DeleteDashboardNews(dispatch, dashboardNewsId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = dashboardNewsList.find(
            (data: DashboardNewsFormDataInterface) => {
                return data.newsId === id
            }
        )

        let tittle = 'Create News'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit News'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? DashboardNewsItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let dashboardNewsId = ''
        if (!activeInactiveModalStatus) {
            dashboardNewsId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setdashboardNewsId(dashboardNewsId)
    }

    const updateActiveInactiveDashboardNews = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveDashboardNews(
            dispatch,
            dashboardNewsId,
            status,
            getTableData
        )
        activeInactiveModalFunction('', '')
    }

    const onSaveDashboardNews = (
        data: DashboardNewsFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void SaveDashboardNewsData(data, callBack)
    }

    const SaveDashboardNewsData = async (
        data: DashboardNewsFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        setIsLoadingModal(true)
        await SaveDashboardNews(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                    setIsLoadingModal(false)
                }
            })
            .catch(() => {})
    }

    const expandableData = (
        record: DashboardNewsListDataTypeInterface
    ): React.ReactElement => {
        return <DashboardNewsExpandView dashboardNewsData={record} />
    }

    return (
        <>
            <Card
                title="Dashboard News"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search News Details by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={dashboardNewsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this News . Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteDashboardNews}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this News. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveDashboardNews}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <DashboardNewsForm
                        onSave={onSaveDashboardNews}
                        editData={editData}
                        isLoadingModal={isLoadingModal}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): DashboardNewsListInterface => {
    return {
        dashboardNewsList: state.initial.dashboardNewsList,
        dashboardNewsListCount: state.initial.dashboardNewsListCount,
    }
}

export default connect(mapStateToProps)(DashboardNewsList)
