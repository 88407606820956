import React, { useEffect, useState } from 'react'
import { Col, Row, Collapse, Switch, Button } from 'antd'
import type {
    CorporateServicesView,
    ServiceStateInterface,
    AnnualAccountData,
} from '../../../../../../Types/Client/RegisteredClients/Individual'

import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import AnualAccountEdit from './serviceForm'
import {
    GetCTService,
    getClientCompanyDetails,
} from '../../../../../../Services/Individual'
import { connect, useDispatch } from 'react-redux'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
export const AnnualAccountDataDefalut = {
    annualAccountId: 0,
    companyType: 0,
    referenceId: 0,
    fromDate: '01/01/1900',
    toDate: '01/01/1900',
    deadlineDate: '01/01/1900',
    status: '',
}
const ServiceView = (props: CorporateServicesView): React.ReactElement => {
    const { individualTaps, individualId } = props
    const dispatch = useDispatch()

    const [AAServiceData, setAAServiceData] = useState<AnnualAccountData>()
    const [isEditMode, setIsEditMode] = useState(false)
    const [editData, setEditData] = useState<AnnualAccountData>(
        AnnualAccountDataDefalut
    )
    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(AAServiceData ?? AnnualAccountDataDefalut)
    }

    useEffect(() => {
        if (Array.isArray(individualTaps) && individualTaps.length > 0) {
            const AAService = individualTaps?.find(
                (service) => service?.serviceName === 'AA'
            )
            const AAServiceReferenceId = AAService?.serviceReferenceId ?? 0
            const serviceID = AAService?.serviceId ?? 0
            if (AAServiceReferenceId !== null && AAServiceReferenceId !== 0) {
                void GetCTService(
                    dispatch,
                    serviceID,
                    AAServiceReferenceId
                ).then((data) => {
                    if (data !== null) {
                        setAAServiceData(data?.data)
                    }
                })
            }
        }
    }, [individualTaps])
    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    const datareFetch = (): void => {
        void getClientCompanyDetails(dispatch, 3, individualId, 4)
    }

    const handleChildFormSave = (): void => {
        datareFetch()
        setIsEditMode(false)
    }

    return (
        <>
            <Collapse expandIconPosition="end">
                <Collapse.Panel
                    header="Annual Accounts"
                    key="1"
                    extra={
                        <Switch checked={AAServiceData?.status === 'Active'} />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" onClick={handleEditClick}>
                                {isEditMode ? (
                                    <EyeOutlined />
                                ) : ActivePremission(
                                      '3000',
                                      PermissionType.UPDATE
                                  ) ? (
                                    <EditOutlined />
                                ) : (
                                    <></>
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isEditMode ? (
                        <div>
                            <AnualAccountEdit
                                editData={editData}
                                individualId={individualId}
                                handleChildFormSave={handleChildFormSave}
                            />
                        </div>
                    ) : (
                        <Row gutter={[24, 8]}>
                            <Col span={4}>
                                <p>Period</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    : {formatdate(AAServiceData?.fromDate)} -{' '}
                                    {formatdate(AAServiceData?.toDate)}
                                </p>
                            </Col>
                            <Col span={4}>
                                <p>Deadline Date</p>
                            </Col>
                            <Col span={6}>
                                <p>
                                    : {formatdate(AAServiceData?.deadlineDate)}{' '}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Collapse.Panel>
            </Collapse>
        </>
    )
}
const mapStateToProps = (state: any): ServiceStateInterface => {
    return {
        individualTaps: state.client.individualTaps,
    }
}

export default connect(mapStateToProps)(ServiceView)
