import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Collapse, Switch, Input, Button, Tooltip } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { SaveClientCompanySupplier } from '../../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../../Utils/Common.less'
import type {
    SupplierDetailsFormData,
    SupplierInfoInterFaceSoleTrader,
} from '../../../../../Types/Supplier'
import { SuccessMessage } from '../../../../../Utils/Notification'
import ActivePremission from '../../../../../Utils/premissins'
import { PermissionType } from '../../../../../Types/Enum/PermissionType'

const SupplierInfo = (
    props: SupplierInfoInterFaceSoleTrader
): React.ReactElement => {
    const { supplierInfoList, soleTraderId } = props
    const { Panel } = Collapse
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [enabledSwitchIndices, setEnabledSwitchIndices] = useState<number[]>(
        []
    )

    const initialValues: Record<string, any> = {}

    const handleSwitchChange = (index: number): void => {
        if (enabledSwitchIndices.includes(index)) {
            setEnabledSwitchIndices(
                enabledSwitchIndices?.filter((i) => i !== index)
            )
        } else {
            setEnabledSwitchIndices([...enabledSwitchIndices, index])
        }
    }

    useEffect(() => {
        if (supplierInfoList !== null) {
            const initialSwitchIndices = Object.keys(supplierInfoList)
                ?.filter(
                    (key) =>
                        supplierInfoList[key]?.companyType !== 0 &&
                        supplierInfoList[key]?.referenceId !== 0
                )
                ?.map((key) => parseInt(key))

            setEnabledSwitchIndices(initialSwitchIndices)
        }
    }, [supplierInfoList])

    const handleSave = (values: any): void => {
        void saveSupplierData(values)
    }

    const saveSupplierData = async (values: {
        details: SupplierDetailsFormData[]
    }): Promise<void> => {
        const formValues = form.getFieldsValue()
        const updatedDetails = formValues?.details?.map(
            (detail: SupplierDetailsFormData, index: number) => ({
                ...detail,
                companyType: 2,
                referenceId: soleTraderId,
            })
        )

        const selectedDetails = updatedDetails?.filter(
            (detail: any, index: number) =>
                enabledSwitchIndices?.includes(index)
        )

        try {
            await Promise.all(
                selectedDetails?.map(
                    async (updatedDetail: SupplierDetailsFormData) => {
                        return await SaveClientCompanySupplier(
                            dispatch,
                            updatedDetail
                        )
                    }
                )
            )
            SuccessMessage('Supplier Info Save Successfully')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form
            name="complex-form"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            layout={'vertical'}
            autoComplete="off"
            initialValues={initialValues}
            form={form}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Supplier Info" key="1">
                            {supplierInfoList?.length > 0 &&
                                supplierInfoList?.map(
                                    (supplier: any, index: number) => (
                                        <Row key={index}>
                                            <Col span={12}>
                                                <Row>
                                                    <div
                                                        className={
                                                            commonStyles.SupplierInfoList
                                                        }
                                                    >
                                                        <Switch
                                                            checked={enabledSwitchIndices.includes(
                                                                index
                                                            )}
                                                            onChange={() => {
                                                                handleSwitchChange(
                                                                    index
                                                                )
                                                            }}
                                                            className={
                                                                commonStyles.SupplierInfoSwitch
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            commonStyles.SupplierInfoList
                                                        }
                                                    >
                                                        {supplier.supplierName}
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    {enabledSwitchIndices.includes(
                                                        index
                                                    ) && (
                                                        <>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'supplierId',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.supplierId
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'supplierName',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.supplierName
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'clientCompanySupplierId',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.clientCompanySupplierId
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'companyType',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.companyType
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'status',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.status
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'referenceId',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.referenceId
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'userName',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.userName
                                                                    }
                                                                    label="UserName"
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="User Name"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'password',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.password
                                                                    }
                                                                    label="Password"
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Password"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'consumerNo',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.consumerNo
                                                                    }
                                                                    label="Customer #"
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Customer"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'url',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.url
                                                                    }
                                                                    label="URL"
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="URL"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                )}
                            {ActivePremission('3000', PermissionType.SAVE) && (
                                <Row gutter={16}>
                                    <Col offset={20} span={2}>
                                        <Tooltip title={'Save changes'}>
                                            <Button
                                                type="primary"
                                                onClick={handleSave}
                                            >
                                                <SaveOutlined />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            )}
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </Form>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        supplierInfoList: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(SupplierInfo)
