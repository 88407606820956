// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabsWidth_hU_KE {
  width: 100%;
}
.flexContainer_BY92L {
  display: 'flex';
  justify-content: 'space-between';
  width: '100%';
}
.rowform_vGk49 {
  margin-bottom: 20px;
}
.titleofpage_ns0CQ {
  font-size: 18px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Partnership/Buttons/ContactInfo/ContactInfo.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEA;EACI,eAAA;EACA,gCAAA;EACA,aAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAIA;EACI,eAAA;EACA,iBAAA;AAFJ","sourcesContent":[".tabsWidth {\n    width: 100%;\n}\n\n.flexContainer {\n    display: 'flex';\n    justify-content: 'space-between';\n    width: '100%';\n}\n\n.rowform {\n    margin-bottom: 20px;\n}\n\n.titleofpage {\n    font-size: 18px;\n    font-weight: bold;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsWidth": `tabsWidth_hU_KE`,
	"flexContainer": `flexContainer_BY92L`,
	"rowform": `rowform_vGk49`,
	"titleofpage": `titleofpage_ns0CQ`
};
export default ___CSS_LOADER_EXPORT___;
