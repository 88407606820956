import { connect, useDispatch } from 'react-redux'
import {
    type InvoiceDetailsListDataTypeInterface,
    type PaidInvoiceProps,
    type PaidInvoiceSateProps,
} from '../../../Types/Invoice/Invoicing'
import commonStyles from '../../../Utils/Common.less'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Space, Table, Tooltip, type TableProps, Modal } from 'antd'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { GetInvoiceList } from '../../../Services/Invoice'
import calculatePagination from '../../../Utils/Pagination'
import { type ColumnsType } from 'antd/es/table'
import {
    HistoryOutlined,
    EyeOutlined,
    FilePdfOutlined,
    DownloadOutlined,
} from '@ant-design/icons'
import invoice from './Invoice.less'
import InvoiceHistory from '../../../Components/history'
import { useNavigate } from 'react-router-dom'

const PaidInvoiceList = (props: PaidInvoiceProps): React.ReactElement => {
    const {
        invoiceDetailsList,
        invoiceDetailsListCount,
        activeButton,
        searchData,
        resetData,
    } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [Historymodel, setHistorymodel] = useState(false)
    const [invoiceId, setInvoiceId] = useState(0)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (searchData !== null) {
            getTableData()
        }
    }, [searchData])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetInvoiceList(
            dispatch,
            {
                pageNo,
                pageSize,
                searchCriteriaList,
                sortOrderList,
            },
            searchData ?? undefined,
            activeButton
        )
    }, [
        tableParams?.sorter,
        tableParams?.pagination,
        tableParams?.filter,
        searchData,
        resetData,
    ])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: invoiceDetailsListCount,
            },
        })
    }, [invoiceDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        invoiceDetailsListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const onInvoicePreviewClick = (InvoiceId: number): void => {
        navigate(`/invoicing/invoic-status/previewInvoice-invoice`, {
            state: { InvoiceId },
        })
    }

    const onInvoiceviewClick = (
        InvoiceId: number,
        invoicename: string
    ): void => {
        const invoiceSaveViewStatus = false
        navigate(`/invoicing/invoic-status/view-invoice`, {
            state: { InvoiceId, invoicename, invoiceSaveViewStatus },
        })
    }

    const columns: ColumnsType<InvoiceDetailsListDataTypeInterface> = [
        { width: 70, title: '#', dataIndex: 'no' },
        {
            title: 'Invoice No',
            dataIndex: 'invoiceNo',
        },
        { width: 110, title: 'Date', dataIndex: 'invoiceDate' },
        {
            width: 100,
            title: 'Number',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
        },
        { width: 100, title: 'Amount', dataIndex: 'netTotal' },
        {
            title: 'Current Status',
            key: 'CurrentStatus',
            render: (_, record: InvoiceDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle" direction="vertical">
                        <span className={invoice.communicatioStatus}>
                            PAID ON {record.invoiceDate}
                        </span>

                        <span>
                            <Space size="middle">
                                <Tooltip title="View Invoice">
                                    <EyeOutlined
                                        onClick={() => {
                                            onInvoiceviewClick(
                                                record.invoiceId,
                                                record.invoiceNo
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>

                                <Tooltip title="Preview Invoice">
                                    <FilePdfOutlined
                                        onClick={() => {
                                            onInvoicePreviewClick(
                                                record.invoiceId
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>

                                <Tooltip title="Download Invoice">
                                    <DownloadOutlined
                                        onClick={() => {
                                            handleDownloadPreviewInvoice(
                                                record.invoiceId
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                            </Space>
                        </span>
                    </Space>
                )
            },
        },
        {
            title: 'Communication',
            key: 'action',
            render: (_, record: InvoiceDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle" direction="vertical">
                        <span className={invoice.communicatioStatus}>
                            {record.lastCommunicationStatus}
                        </span>

                        <span>
                            <Space size="middle">
                                <Tooltip title="Communication History">
                                    <HistoryOutlined
                                        onClick={() => {
                                            openHistoryModel(record.invoiceId)
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                            </Space>
                        </span>
                    </Space>
                )
            },
        },
    ]
    const onChangeTableParams: TableProps<InvoiceDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const openHistoryModel = (id: number): void => {
        setInvoiceId(id)
        setHistorymodel(!Historymodel)
    }

    const changeHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setHistorymodel(!Historymodel)
    }

    const handleDownloadPreviewInvoice = (InvoiceId: number): void => {
        const downloatstatus = true

        navigate(`/invoicing/invoic-status/previewInvoice-invoice`, {
            state: { InvoiceId, downloatstatus },
        })
    }
    const [showMessage, setShowMessage] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(true)
        }, 3000)

        // Clear the timer if the component unmounts
        return () => {
            clearTimeout(timer)
        }
    }, [])
    return (
        <>
            <div className={commonStyles.table}>
                {invoiceDetailsList?.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={invoiceDetailsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                ) : (
                    <div>
                        {showMessage ? (
                            <h4>No matching data available</h4>
                        ) : (
                            <h4>Loading data...</h4>
                        )}
                    </div>
                )}
            </div>
            <Modal
                footer={null}
                onCancel={changeHistoryModalStatus}
                width={'50%'}
                title={'Communication History'}
                open={Historymodel}
            >
                <InvoiceHistory
                    invoiceIds={invoiceId}
                    seletedButton={selectedButtonId}
                    setButtonID={setSelectedButtonId}
                />
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any): PaidInvoiceSateProps => {
    return {
        invoiceDetailsList: state.invoice.invoiceDetailsList,
        invoiceDetailsListCount: state.invoice.invoiceDetailsListCount,
    }
}

export default connect(mapStateToProps)(PaidInvoiceList)
