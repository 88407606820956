import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import type {
    ListFrontEndInterface,
    ListBackendInterface,
} from '../Types/CommonType'
import { userList } from '../Reducer/User'

export const GetUserList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListing/19`)
        .then((response) => {
            if (response.status === 200) {
                const uList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(userList(uList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
