import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard'
import { ShowProducts } from '../Pages/showProducts'
import SupplierDetailsList from '../Pages/ServiceSetup/ManageSupplier/supplierDetailsList'
import DashboardNewsList from '../Pages/InitialSetup/DashBoardNews/dashboardNewsList'
import LeaveList from '../Pages/InitialSetup/LeaveType/leaveList'
import TemplateTypeList from '../Pages/InitialSetup/TemplateType/templateTypeList'
import GroupAndPartnerList from '../Pages/InitialSetup/GroupAndPartners/groupAndPartnerList'
import BankingDetailsList from '../Pages/InitialSetup/BankingDetails/bankingDetailsList'
import RegisterClient from '../Pages/Clients/RegisteredClients/registerclient'
import DepartmentList from '../Pages/InitialSetup/Departments/departmentList'
import HolidaysDetailsList from '../Pages/InitialSetup/Holiday/holidaysDetailsList'
import StaffDesignationList from '../Pages/InitialSetup/StaffDesignation/staffDesignationList'
import WorkflowList from '../Pages/InitialSetup/Workflow/WorkflowList'
import WorkflowDetailsList from '../Pages/InitialSetup/WorkflowDetails/WorkflowDetailsList'
import OtherPersonList from '../Pages/ClientCommunication/OtherPerson/otherPersonList'
import CorporateView from '../Pages/Clients/RegisteredClients/Corporate/corporateView'
import CorporateNew from '../Pages/Clients/RegisteredClients/Corporate/corporateNew'
import IndividualView from '../Pages/Clients/RegisteredClients/Individual/individualView'
import IndividualNew from '../Pages/Clients/RegisteredClients/Individual/individualNew'
import UserRights from '../Pages/UserManagement/UserRights/user-right'
import QuotatioPreview from '../Pages/Clients/RegisteredClients/Corporate/Buttons/Compliance/QuotationPreview/QuotationPreview'
import AntiMoneyLaundering from '../Pages/Clients/RegisteredClients/Corporate/Buttons/Compliance/AntimoneyLaundering/antimoneyLaundering'
import LetterOfEngagement from '../Pages/Clients/RegisteredClients/Corporate/Buttons/Compliance/LetterofEngagement/letterofEngagement'
import SoleTraderView from '../Pages/Clients/RegisteredClients/SoleTrader/soleTraderView'
import SoleTraderNew from '../Pages/Clients/RegisteredClients/SoleTrader/soleTraderNew'
import EmailTemplateList from '../Pages/InitialSetup/EmailTemplate/emailTemplateList'
import AddEditEmailTemplateForm from '../Pages/InitialSetup/EmailTemplate/emailTemplateForm'
import UserInfomationList from '../Pages/UserManagement/ManageUser/userInfoList'
import InvoiceList from '../Pages/Invoice/Invoicing/invoiceList'
import CreateInvoice from '../Pages/Invoice/Invoicing/createinvoice'
import ViewInvoice from '../Pages/Invoice/Invoicing/CommunicationStatus/viewInvoice'
import PreviewInvoice from '../Pages/Invoice/Invoicing/CommunicationStatus/previewInvoice'
import SendEmail from '../Pages/Invoice/Invoicing/CommunicationStatus/sendInvoiceMail'
import ClientView from '../Pages/HelpDesk/ClientForm/Clientview'
import PartnershipNew from '../Pages/Clients/RegisteredClients/Partnership/ PartnershipNew'
import partnershipView from '../Pages/Clients/RegisteredClients/Partnership/PartnershipView'
import ParnershipLetterOfEngagement from '../Pages/Clients/RegisteredClients/Partnership/Buttons/Compliance/LetterofEngagement/letterofEngagement'
import PartneshipQuotatioPreview from '../Pages/Clients/RegisteredClients/Partnership/Buttons/Compliance/QuotationPreview/QuotationPreview'
import PartneshipAntiMoneyLaundering from '../Pages/Clients/RegisteredClients/Partnership/Buttons/Compliance/AntimoneyLaundering/antimoneyLaundering'
import InvoicePreparation from '../Pages/Invoice/Invoice Preparation/InvoicePreparation'
import BulkMail from '../Pages/ClientCommunication/BulkMail/BulkMail'
import PorfolioList from '../Pages/Clients/ProfolioManager/porfoliomanagerList'
import OnBoardingclient from '../Pages/Clients/OnboardingClients/OnBoardingclient'
import ProspectClientDetailsList from '../Pages/Clients/ProspectClients/prospactclientList'
import ProspectSendEmail from '../Pages/Clients/ProspectClients/history/Send_Email'
import SoleTraderQuotatioPreview from '../Pages/Clients/RegisteredClients/SoleTrader/Buttons/Compliance/QuotationPreview/QuotationPreview'
import SoleTraderAntiMoneyLaundering from '../Pages/Clients/RegisteredClients/SoleTrader/Buttons/Compliance/AntimoneyLaundering/antimoneyLaundering'
import SoleTraderLetterOfEngagement from '../Pages/Clients/RegisteredClients/SoleTrader/Buttons/Compliance/LetterofEngagement/letterofEngagement'
import IndividualQuotatioPreview from '../Pages/Clients/RegisteredClients/Individual/Buttons/Compliance/QuotationPreview/QuotationPreview'
import IndividualAntiMoneyLaundering from '../Pages/Clients/RegisteredClients/Individual/Buttons/Compliance/AntimoneyLaundering/antimoneyLaundering'
import IndividualLetterOfEngagement from '../Pages/Clients/RegisteredClients/Individual/Buttons/Compliance/LetterofEngagement/letterofEngagement'
import BulkSms from '../Pages/ClientCommunication/BulkSms/BulksmsView'
import PropertyView from '../Pages/MisssingInformation/PropertyView/propertyView'
import ChangePin from '../Pages/Profile/changePin'
import ChangePassword from '../Pages/Profile/changePassword'
import ListofHoliday from '../Pages/Profile/listOfHistor'
import ManageProfile from '../Pages/Profile/manageProfile'
import NotificationList from '../Pages/Notification/ViewAllNotification/notificationList'
import OverViewClient from '../Pages/DashboardClientOverview/overviewPage'
import ServiceUserList from '../Pages/ServiceSetup/ServiceUser/serviceUserList'
import ClientServiceUserList from '../Pages/ServiceSetup/ClientServiceUser/clientServiceUserList'
import StaffwiseReport from '../Pages/DashboardStaffwiseReport/StaffwiseReport'
import ToDoViewAllList from '../Pages/ThingToDoViewAll/thingToDoViewAllList'
import ServiceProcessList from '../Pages/ThingToDoViewAll/ProcessModel/serviceProcessPage'
import SendServiceProcessEmail from '../Pages/ThingToDoViewAll/CommunicationModel/sendServiceProcessEmail'
import CSFillingList from '../Pages/ThingToDoViewAll/CS Filling/CSFillingList'
import LeavePermissionList from '../Pages/HR/LeaveAndPermission/LeavePermissionList'
import TimeSheetView from '../Pages/HR/TimeSheet/TimeSheetView'
import ManageDiary from '../Pages/HelpDesk/ManageDiary/ManageDiaryView'
import ProjectManagement from '../Pages/InitialSetup/ProjectManagement/ProjectManagementList'
import ProjectManagmentView from '../Pages/InitialSetup/ProjectManagement/ProjectManagmentExpamnd'
import LandingView from '../Pages/HelpDesk/createTask/LandingPage'
import TimesheetApprovalList from '../Pages/ToApproveViewAll/Timesheet/timesheetList'
import LeaveApprovalList from '../Pages/ToApproveViewAll/Leave/leavelist'
import PermissionApprovalList from '../Pages/ToApproveViewAll/Permission/permissionList'
import GlobelSearchClient from '../Pages/GlobelSearchClientDashboard/clientOverViewDashbord'
import ClientSendEmail from '../Pages/GlobelSearchClientDashboard/Buttons/ClientComModels/SendEmail'
import MobileRequestList from '../Pages/HelpDesk/MoblieRequest/mobileRequestList'
import SendEmailInvoice from '../Pages/Invoice/Invoicing/UnpaidInvoiceHistory/sendInvoiceMailByUnpaid'
import CompanyEligiblity from '../Pages/CompanyHouse/CompanyEligiblity/companyEligiblity'
import CompanyFormation from '../Pages/CompanyHouse/CompanyFormation/companyformation'
import CompanyOverView from '../Pages/CompanyHouse/CompanyOverview/companyOverView'
import CompanyOverViewButtons from '../Pages/CompanyHouse/CompanyOverview/companyOverViewButtons'
import WikiArticalList from '../Pages/WIKI/WIKIArtical/wikiArticalList'
import WikiArticalGroupList from '../Pages/WIKI/WIKIArticalGroup/wikiArticalGroupList'
import ServiceStatusReport from '../Pages/Reports/Service-Status/serviceStatus'
import ClientStatusReport from '../Pages/Reports/ClientStatus/ClientStatus'
import OnboardingReport from '../Pages/Reports/Onboarding/Onboarding'
import ServiceclientReport from '../Pages/Reports/ServiceClient/ServiceClient'
import MonthwiseReport from '../Pages/Reports/Monthwise/monthwiseReport'
import MissingInformation from '../Pages/Reports/MissingInformation/missingInformation'
import TimesheetReport from '../Pages/HR/Reports/TimeSheet/TimesheetReport'
import PermisionReport from '../Pages/HR/Reports/Permission/PermisionReport'
import LeaveReport from '../Pages/HR/Reports/Leave/LeaveReport'
import PaidReport from '../Pages/Invoice/CCReports/paymentSummaryReports'
import InvoiceTypeReport from '../Pages/Invoice/CCReports/invoiceTypeReports'
import Backlog from '../Pages/BackLog/backLogList'
import Hmrc from '../Pages/Hmrc/hmrc'
import CreateClientCatergoryList from '../Pages/InitialSetup/CreateCategory/createClientCategorylist'
import newpage from '../Pages/Clients/new/newpage'
import ConformationStatementFilling from '../Pages/CompanyHouse/ConformationStatement/conformatitinStatemetFilling'
import CSFEditButton from '../Pages/CompanyHouse/ConformationStatementFillingEdit/conformationStatemtFillingEditButton'
import CHFillingHistory from '../Pages/CompanyHouse/ConformationFillingHistory/CHFillingHistory'
import CSFillingStatusList from '../Pages/CompanyHouse/CSFillingStatus/csFillingStatus'
import ClientViewMissingInfo from '../Pages/MisssingInformation/ClientView/clinetView'
// import Sample from '../Pages/sample'

export const breadCrumbNameRouteMapping: any = {
    '': 'Dashboard',
    'system-configuration': 'System Configuration',
    'company-group': 'Group & Partners',
    'payment-option': 'Bank Details',
    department: 'Departments',
    designation: 'Staff Designations',
    'email_template-type': 'Email Template Type',
    'leave-type': 'Leave Type',
    holiday: 'Holiday',
    'work-flow': 'Work Flow',
    'work-flow-details': 'Work Flow Details',
    'email-template': 'Email Template',
    'view-template': 'View Template',
    news: 'Dashboard News',
    'other-person': 'Other Person',
    'service-setup': 'Service Setup',
    'service-user': 'Service User',
    'client-service-user': 'Client Service User',
    supplier: ' Manage Supplier',
    clients: 'Clients',
    'new-page': 'newpage',
    'portfolio-Manager': 'Portfolio Manager',
    'registered-clients': 'Client Company',
    'quotation-preview': 'Quotation Preview',
    'letterof-engagement': 'Letter of Engagement',
    'anti-money-laundering': 'Anti-Money Laundering',
    'corporate-view': 'Corporate View',
    'corporate-new': 'Corporate New',
    'individual-view': 'Individual View',
    'soletrader-new': 'SoleTrader New',
    'partnership-view': 'Partnership View',
    'partnership-new': 'Partnership New',
    'soletrader-view': 'SoleTrader View',
    'onboarding-clients': 'Onboarding Clients',
    'prospect-clients': 'Prospect Clients',
    'send-email': 'Send Email',
    'missing-information': 'Missing information',
    'awaiting-approval': 'Awaiting Approval',
    'property-view': 'Property View',
    'client-view': 'Client View',
    invoicing: 'Invoicing',
    'create-invoice': 'Create Invoice',
    'view-invoice': 'View & Edit Invoice',
    'previewInvoice-invoice': 'Preview Invoice',
    'send-invoiceEmail': 'Send InvoiceEmail',
    'cc-accountStatement': 'CC Account Statement',
    'invoice-preparation': 'Invoice Preparation',
    'accounts-Statement': 'CC Accounts Statement',
    'credit-control': 'CC Report',
    'status-report': 'Status Report',
    'client-report': 'Client Report',
    'payementsummary-report': 'Paymentsummary Report',
    'invoice-type-report': 'Invoice Type Report',
    'unsend-report': 'Unsend Report',
    'unpaid-report': 'Unpaid Report',
    'paid-report': 'Payment Summary',
    'ad-hoc-services': 'Ad Hoc Services',
    'help-desk': 'Help Desk',
    'create-task': 'Create Task',
    'my-task': 'My Task',
    'mobile-request': 'Mobile Request',
    'forms-to-clients': 'Forms To Clients',
    'manage-diary': 'Manage Diary',
    hr: 'HR',
    'leave-permission': 'Leave Permissions',
    backLog: 'Back Log',
    'time-sheet': 'Time Sheet',
    reports: 'Reports',
    'user-management': 'User Management',
    'manage-user': 'Manage User',
    'user-rights': 'User Rights',
    report: 'Reports',
    'service-status': 'Service Status',
    'service-client': 'Service Client',
    'month-wise': 'Month Wise',
    'missing-info': 'Missing Info',
    'client-status': 'Client Status',
    onboarding: 'Onboarding Reports',
    'client-comunication': 'Client Comunication',
    'bulk-mail': 'Bulk Mail',
    'bulk-sms': 'Bulk SMS',
    'other-business-associatiates': 'Other Business Associates',
    wiki: 'WIKI',
    'article-group': 'Article Group',
    article: 'Article',
    'company-house': 'Company House',
    'company-eligibility': 'Company Eligibility',
    'company-formation': 'Company Formation',
    'company-overview': 'Company Overview',
    'confimation-statement-filing': 'Confimation Statement Filing',
    'ch-filing-history': 'CH Filing History',
    hrmc: 'HRMC',
    'hrmc-64-8': 'HRMC 64-8',
    'vat-validator': 'VAT Validator',
    overview: 'Overview',
    'client-dashboard': 'Client Dashboard',
    'change-Password': 'Change Password',
    'change-Pin': 'Change Pin',
    'change-holidayList': 'Change HolidayList',
    'manage-Profile': 'Manage Profile',
    notification: 'Notification',
    'view-all': 'View All Notifications',
    'client-overview': 'Client Overview',
    'project-management': 'Project Management',
    'project-view': 'Project View',
    'thing-to-do': 'Thing To Do',
    'service-process': ' Service Process',
    'confirmation-Statement': 'Conformation Statement',
    summary: 'Summary',
    'leave-list': 'Leave',
    'permission-list': 'Permission',
    approve: 'Approval',
    'send-invoiceDetailsEmail': 'Send Invoice Mail',
    'Things-to-Follow': 'Things to Follow',
    'details-Individual': 'Details Individual',
    'invoic-status': 'Status',
    'time-sheet-approve': 'Time Sheet Approve',
    'individual-new': 'Individual Registration',
    sprint: 'Sprint',
    createCategory: 'Create Category',
    accounting: 'Accounting',
    Registered_Clients: 'Registered Clients',
    OnBoarding_Clients: 'OnBoarding Clients',
    'client-wise': 'Clientwise',
    details: 'Details',
    history: 'History',
    category: 'category',
    permission: 'Permission',
    leave: 'Leave',
}

const routes = [
    {
        path: '/',
        component: Dashboard,
        key: '/',
    },
    {
        path: '/system-configuration/company-group',
        component: GroupAndPartnerList,
        key: '/system-configuration/company-group',
    },
    {
        path: '/system-configuration/payment-option',
        component: BankingDetailsList,
        key: '/system-configuration/payment-option',
    },
    {
        path: '/system-configuration/department',
        component: DepartmentList,
        key: '/system-configuration/department',
    },
    {
        path: '/system-configuration/designation',
        component: StaffDesignationList,
        key: '/system-configuration/designation',
    },
    {
        path: '/system-configuration/email_template-type',
        component: TemplateTypeList,
        key: '/system-configuration/email_template-type',
    },
    {
        path: '/system-configuration/leave-type',
        component: LeaveList,
        key: '/system-configuration/leave-type',
    },
    {
        path: '/system-configuration/holiday',
        component: HolidaysDetailsList,
        key: '/system-configuration/holiday',
    },
    {
        path: '/system-configuration/work-flow',
        component: WorkflowList,
        key: '/system-configuration/work-flow',
    },
    {
        path: '/system-configuration/work-flow-details',
        component: WorkflowDetailsList,
        key: '/system-configuration/work-flow-details',
    },
    {
        path: '/system-configuration/email-template',
        component: EmailTemplateList,
        key: '/system-configuration/email-template',
    },
    {
        path: '/system-configuration/view-template',
        component: AddEditEmailTemplateForm,
        key: '/system-configuration/view-template',
    },
    {
        path: '/system-configuration/news',
        component: DashboardNewsList,
        key: '/system-configuration/news',
    },
    {
        path: '/system-configuration/supplier',
        component: SupplierDetailsList,
        key: '/system-configuration/supplier',
    },
    {
        path: '/system-configuration/other-person',
        component: OtherPersonList,
        key: '/system-configuration/other-person',
    },

    {
        path: '/system-configuration/project-management',
        component: ProjectManagement,
        key: '/system-configuration/project-management',
    },
    {
        path: '/system-configuration/project-management/project-view',
        component: ProjectManagmentView,
        key: '/system-configuration/project-management/project-view',
    },
    {
        path: '/initial-setup/department',
        component: DepartmentList,
        key: '/initial-setup/department',
    },
    {
        path: '/service-setup/client-service-user',
        component: ClientServiceUserList,
        key: '/service-setup/client-service-user',
    },
    {
        path: '/service-setup/service-user',
        component: ServiceUserList,
        key: '/service-setup/service-user',
    },
    {
        path: '/service-setup/supplier',
        component: SupplierDetailsList,
        key: '/service-setup/supplier',
    },
    {
        path: '/clients/portfolio-Manager',
        component: PorfolioList,
        key: '/clients/portfolio-Manager',
    },
    {
        path: '/clients/registered-clients',
        component: RegisterClient,
        key: '/clients/registered-clients',
    },
    {
        path: '/clients/registered-clients/corporate-view',
        component: CorporateView,
        key: '/clients/registered-clients/corporate-view',
    },
    {
        path: '/clients/onboarding-clients/corporate-view',
        component: CorporateView,
        key: '/clients/onboarding-clients/corporate-view',
    },
    {
        path: '/clients/registered-clients/corporate-new',
        component: CorporateNew,
        key: '/clients/registered-clients/corporate-new',
    },
    {
        path: '/clients/onboarding-clients/corporate-new',
        component: CorporateNew,
        key: '/clients/onboarding-clients/corporate-new',
    },
    {
        path: '/clients/registered-clients/individual-new',
        component: IndividualNew,
        key: '/clients/registered-clients/individual-new',
    },
    {
        path: '/clients/onboarding-clients/individual-new',
        component: IndividualNew,
        key: '/clients/onboarding-clients/individual-new',
    },
    {
        path: '/clients/registered-clients/individual-view/quotation-preview',
        component: IndividualQuotatioPreview,
        key: '/clients/registered-clients/individual-view/quotation-preview',
    },
    {
        path: '/clients/registered-clients/individual-view/letterof-engagement',
        component: IndividualLetterOfEngagement,
        key: '/clients/registered-clients/individual-view/letterof-engagement',
    },
    {
        path: '/clients/registered-clients/individual-view/anti-money-laundering',
        component: IndividualAntiMoneyLaundering,
        key: '/clients/registered-clients/individual-view/anti-money-laundering',
    },
    {
        path: '/clients/onboarding-clients/individual-view/quotation-preview',
        component: IndividualQuotatioPreview,
        key: '/clients/onboarding-clients/individual-view/quotation-preview',
    },
    {
        path: '/clients/onboarding-clients/individual-view/letterof-engagement',
        component: IndividualLetterOfEngagement,
        key: '/clients/onboarding-clients/individual-view/letterof-engagement',
    },
    {
        path: '/clients/onboarding-clients/individual-view/anti-money-laundering',
        component: IndividualAntiMoneyLaundering,
        key: '/clients/onboarding-clients/individual-view/anti-money-laundering',
    },
    {
        path: '/clients/registered-clients/individual-view',
        component: IndividualView,
        key: '/clients/registered-clients/individual-view',
    },
    {
        path: '/clients/onboarding-clients/individual-view',
        component: IndividualView,
        key: '/clients/onboarding-clients/individual-view',
    },

    {
        path: '/clients/registered-clients/soletrader-view',
        component: SoleTraderView,
        key: '/clients/registered-clients/soletrader-view',
    },
    {
        path: '/clients/onboarding-clients/soletrader-view',
        component: SoleTraderView,
        key: '/clients/onboarding-clients/soletrader-view',
    },
    {
        path: '/clients/registered-clients/soletrader-new',
        component: SoleTraderNew,
        key: '/clients/registered-clients/soletrader-new',
    },
    {
        path: '/clients/onboarding-clients/soletrader-new',
        component: SoleTraderNew,
        key: '/clients/onboarding-clients/soletrader-new',
    },
    {
        path: '/clients/registered-clients/corporate-view/quotation-preview',
        component: QuotatioPreview,
        key: '/clients/registered-clients/corporate-view/quotation-preview',
    },
    {
        path: '/clients/registered-clients/corporate-view/letterof-engagement',
        component: LetterOfEngagement,
        key: '/clients/registered-clients/corporate-view/letterof-engagement',
    },
    {
        path: '/clients/registered-clients/corporate-view/anti-money-laundering',
        component: AntiMoneyLaundering,
        key: '/clients/registered-clients/corporate-view/anti-money-laundering',
    },
    {
        path: '/clients/onboarding-clients/corporate-view/quotation-preview',
        component: QuotatioPreview,
        key: '/clients/onboarding-clients/corporate-view/quotation-preview',
    },
    {
        path: '/clients/onboarding-clients/corporate-view/letterof-engagement',
        component: LetterOfEngagement,
        key: '/clients/onboarding-clients/corporate-view/letterof-engagement',
    },
    {
        path: '/clients/onboarding-clients/corporate-view/anti-money-laundering',
        component: AntiMoneyLaundering,
        key: '/clients/onboarding-clients/corporate-view/anti-money-laundering',
    },
    {
        path: '/clients/onboarding-clients/partnership-view',
        component: partnershipView,
        key: '/clients/onboarding-clients/partnership-view',
    },
    {
        path: '/clients/registered-clients/partnership-view',
        component: partnershipView,
        key: '/clients/registered-clients/partnership-view',
    },
    {
        path: '/clients/registered-clients/partnership-new',
        component: PartnershipNew,
        key: '/clients/registered-clients/partnership-new',
    },
    {
        path: '/clients/onboarding-clients/partnership-new',
        component: PartnershipNew,
        key: '/clients/onboarding-clients/partnership-new',
    },
    {
        path: '/clients/registered-clients/partnership-view/quotation-preview',
        component: PartneshipQuotatioPreview,
        key: '/clients/registered-clients/partnership-view/quotation-preview',
    },
    {
        path: '/clients/registered-clients/partnership-view/letterof-engagement',
        component: ParnershipLetterOfEngagement,
        key: '/clients/registered-clients/partnership-view/letterof-engagement',
    },
    {
        path: '/clients/registered-clients/partnership-view/anti-money-laundering',
        component: PartneshipAntiMoneyLaundering,
        key: '/clients/registered-clients/partnership-view/anti-money-laundering',
    },
    {
        path: '/clients/onboarding-clients/partnership-view/quotation-preview',
        component: PartneshipQuotatioPreview,
        key: '/clients/onboarding-clients/partnership-view/quotation-preview',
    },
    {
        path: '/clients/onboarding-clients/partnership-view/letterof-engagement',
        component: ParnershipLetterOfEngagement,
        key: '/clients/onboarding-clients/partnership-view/letterof-engagement',
    },
    {
        path: '/clients/onboarding-clients/partnership-view/anti-money-laundering',
        component: PartneshipAntiMoneyLaundering,
        key: '/clients/onboarding-clients/partnership-view/anti-money-laundering',
    },
    {
        path: '/clients/registered-clients/soletrader-view/quotation-preview',
        component: SoleTraderQuotatioPreview,
        key: '/clients/registered-clients/soletrader-view/quotation-preview',
    },
    {
        path: '/clients/registered-clients/soletrader-view/letterof-engagement',
        component: SoleTraderLetterOfEngagement,
        key: '/clients/registered-clients/soletrader-view/letterof-engagement',
    },
    {
        path: '/clients/registered-clients/soletrader-view/anti-money-laundering',
        component: SoleTraderAntiMoneyLaundering,
        key: '/clients/registered-clients/soletrader-view/anti-money-laundering',
    },
    {
        path: '/clients/onboarding-clients/soletrader-view/quotation-preview',
        component: SoleTraderQuotatioPreview,
        key: '/clients/onboarding-clients/soletrader-view/quotation-preview',
    },
    {
        path: '/clients/onboarding-clients/soletrader-view/letterof-engagement',
        component: SoleTraderLetterOfEngagement,
        key: '/clients/onboarding-clients/soletrader-view/letterof-engagement',
    },
    {
        path: '/clients/onboarding-clients/soletrader-view/anti-money-laundering',
        component: SoleTraderAntiMoneyLaundering,
        key: '/clients/onboarding-clients/soletrader-view/anti-money-laundering',
    },
    {
        path: '/clients/onboarding-clients',
        component: OnBoardingclient,
        key: '/clients/onboarding-clients',
    },
    {
        path: '/clients/prospect-clients',
        component: ProspectClientDetailsList,
        key: '/clients/prospect-clients',
    },
    {
        path: '/clients/onboarding-clients/individual-new',
        component: ShowProducts,
        key: '/clients/onboarding-clients/individual-new',
    },
    {
        path: '/clients/prospect-clients/send-email',
        component: ProspectSendEmail,
        key: '/clients/prospect-clients/send-email',
    },
    {
        path: '/missing-information/awaiting-approval',
        component: ShowProducts,
        key: '/missing-information/awaiting-approval',
    },
    {
        path: '/missing-information/property-view',
        component: PropertyView,
        key: '/missing-information/property-view',
    },
    {
        path: '/missing-information/client-view',
        component: ClientViewMissingInfo,
        key: '/missing-information/client-view',
    },
    {
        path: '/invoicing/invoice-preparation',
        component: InvoicePreparation,
        key: '/invoicing/invoice-preparation',
    },
    {
        path: '/invoicing/accounts-Statement',
        component: ShowProducts,
        key: '/invoicing/accounts-Statement',
    },
    {
        path: '/invoicing/invoic-status',
        component: InvoiceList,
        key: '/invoicing/invoic-status',
    },
    {
        path: '/invoicing/invoic-status/create-invoice',
        component: CreateInvoice,
        key: '/invoicing/invoic-status/create-invoice',
    },
    {
        path: '/invoicing/invoic-status/view-invoice',
        component: ViewInvoice,
        key: '/invoicing/invoic-status/view-invoice',
    },
    {
        path: '/invoicing/invoic-status/previewInvoice-invoice',
        component: PreviewInvoice,
        key: '/invoicing/invoic-status/previewInvoice-invoice',
    },
    {
        path: '/invoicing/invoic-status/send-invoiceEmail',
        component: SendEmail,
        key: '/invoicing/invoic-status/send-invoiceEmail',
    },
    {
        path: '/invoicing/cc-accountStatement',
        component: ShowProducts,
        key: '/invoicing/cc-accountStatement',
    },
    {
        path: '/invoicing/credit-control/status-report',
        component: ShowProducts,
        key: '/invoicing/credit-control/status-report',
    },
    {
        path: '/invoicing/credit-control/client-report',
        component: ShowProducts,
        key: '/invoicing/credit-control/client-report',
    },
    {
        path: '/invoicing/credit-control/invoice-type-report',
        component: InvoiceTypeReport,
        key: '/invoicing/credit-control/invoice-type-report',
    },
    {
        path: '/invoicing/credit-control/unsend-report',
        component: ShowProducts,
        key: '/invoicing/credit-control/unsend-report',
    },
    {
        path: '/invoicing/credit-control/unpaid-report',
        component: ShowProducts,
        key: '/invoicing/credit-control/unpaid-report',
    },
    {
        path: '/invoicing/credit-control/paid-report',
        component: PaidReport,
        key: '/invoicing/credit-control/paid-report',
    },

    {
        path: '/ad-hoc-services',
        component: ShowProducts,
        key: '/ad-hoc-services',
    },
    {
        path: '/help-desk/create-task',
        component: LandingView,
        key: '/help-desk/create-task',
    },

    {
        path: '/help-desk/my-task',
        component: ShowProducts,
        key: '/help-desk/my-task',
    },
    {
        path: '/help-desk/mobile-request',
        component: MobileRequestList,
        key: '/help-desk/mobile-request',
    },
    {
        path: '/help-desk/forms-to-clients',
        component: ClientView,
        key: '/help-desk/forms-to-clients',
    },
    {
        path: '/help-desk/manage-diary',
        component: ManageDiary,
        key: '/help-desk/manage-diary',
    },
    {
        path: '/hr/leave-permission',
        component: LeavePermissionList,
        key: '/hr/leave-permission',
    },
    {
        path: '/hr/time-sheet',
        component: TimeSheetView,
        key: '/hr/time-sheet',
    },
    {
        path: '/hr/reports/time-sheet',
        component: TimesheetReport,
        key: '/hr/reports/time-sheet',
    },
    {
        path: '/hr/reports/leave',
        component: LeaveReport,
        key: '/hr/reports/leave',
    },
    {
        path: '/hr/reports/permission',
        component: PermisionReport,
        key: '/hr/reports/permission',
    },
    {
        path: '/user-management/manage-user',
        component: UserInfomationList,
        key: '/user-management/manage-user',
    },
    {
        path: '/user-management/user-rights',
        component: UserRights,
        key: '/user-management/user-rights',
    },
    {
        path: '/report/service-status',
        component: ServiceStatusReport,
        key: '/report/service-status',
    },
    {
        path: '/report/service-client',
        component: ServiceclientReport,
        key: '/report/service-client',
    },
    {
        path: '/report/month-wise',
        component: MonthwiseReport,
        key: '/report/month-wise',
    },
    {
        path: '/report/missing-info',
        component: MissingInformation,
        key: '/report/missing-info',
    },
    {
        path: '/report/client-status',
        component: ClientStatusReport,
        key: '/report/client-status',
    },
    {
        path: '/report/onboarding',
        component: OnboardingReport,
        key: '/report/onboarding',
    },
    {
        path: '/client-comunication/bulk-sms',
        component: BulkSms,
        key: '/client-comunication/bulk-sms',
    },
    {
        path: '/client-comunication/bulk-mail',
        component: BulkMail,
        key: '/client-comunication/bulk-mail',
    },
    {
        path: '/client-comunication/other-business-associatiates',
        component: OtherPersonList,
        key: '/client-comunication/other-business-associatiates',
    },
    {
        path: '/wiki/article-group',
        component: WikiArticalGroupList,
        key: '/wiki/article-group',
    },
    {
        path: '/wiki/article',
        component: WikiArticalList,
        key: '/wiki/article',
    },
    {
        path: '/company-house/company-eligibility',
        component: CompanyEligiblity,
        key: '/company-house/company-eligibility',
    },
    {
        path: '/company-house/company-formation',
        component: CompanyFormation,
        key: '/company-house/company-formation',
    },
    {
        path: '/company-house/company-overview',
        component: CompanyOverView,
        key: '/company-house/company-overview',
    },
    {
        path: '/company-house/confimation-statement-filing',
        component: ConformationStatementFilling,
        key: '/company-house/confimation-statement-filing',
    },
    {
        path: '/company-house/ch-filing-history',
        component: CHFillingHistory,
        key: '/company-house/ch-filing-history',
    },
    {
        path: '/hrmc/hrmc-64-8',
        component: Hmrc,
        key: '/hrmc/hrmc-64-8',
    },
    {
        path: '/hrmc/vat-validator',
        component: ShowProducts,
        key: '/hrmc/vat-validator',
    },
    {
        path: '/overview/client-dashboard',
        component: GlobelSearchClient,
        key: '/overview/client-dashboard',
    },
    {
        path: '/change-Password',
        component: ChangePassword,
        key: '/change-Password',
    },
    {
        path: '/change-Pin',
        component: ChangePin,
        key: '/change-Pin',
    },
    {
        path: '/change-holidayList',
        component: ListofHoliday,
        key: '/change-holidayList',
    },
    {
        path: '/manage-Profile',
        component: ManageProfile,
        key: '/manage-Profile',
    },
    {
        path: '/notification/view-all',
        component: NotificationList,
        key: '/notification/view-all',
    },
    {
        path: '/client-overview',
        component: OverViewClient,
        key: '/client-overview',
    },
    {
        path: '/dashboard-staff-overview',
        component: StaffwiseReport,
        key: '/dashboard-staff-overview',
    },
    // /thing-to-do?mode=0&serviceFlag=2
    // /thing-to-do/:mode?/:serviceFlag?/statusId
    {
        path: '/thing-to-do',
        component: ToDoViewAllList,
        key: '/thing-to-do',
    },
    // /thing-to-do/:serviceProcesssId?/:serviceId?
    {
        path: '/thing-to-do/service-process',
        component: ServiceProcessList,
        key: '/thing-to-do/service-process',
    },
    {
        path: '/thing-to-do/service-process/send-email',
        component: SendServiceProcessEmail,
        key: '/thing-to-do/service-process/send-email',
    },
    {
        path: '/confirmation-Statement/summary',
        component: CSFillingList,
        key: '/confirmation-Statement/summary',
    },
    {
        path: '/time-sheet-approve',
        component: TimesheetApprovalList,
        key: '/time-sheet-approve',
    },
    {
        path: '/leave/approve',
        component: LeaveApprovalList,
        key: '/leave/approve',
    },
    {
        path: '/permission/approve',
        component: PermissionApprovalList,
        key: '/permission/approve',
    },
    {
        path: '/overview/send-email',
        component: ClientSendEmail,
        key: '/overview/send-email',
    },
    {
        path: '/invoicing/invoic-status/send-invoiceDetailsEmail',
        component: SendEmailInvoice,
        key: '/invoicing/invoic-status/send-invoiceDetailsEmail',
    },
    {
        path: '/Things-to-Follow',
        component: ToDoViewAllList,
        key: '/Things-to-Follow',
    },
    {
        path: '/confirmation-Statement/details-Individual',
        component: CompanyOverViewButtons,
        key: '/confirmation-Statement/details-Individual',
    },
    {
        path: '/help-desk/sprint',
        component: Backlog,
        key: '/help-desk/create-task',
    },
    {
        path: '/system-configuration/createCategory',
        component: CreateClientCatergoryList,
        key: '/system-configuration/createCategory',
    },
    {
        path: '/clients/category',
        component: newpage,
        key: '/clients/category',
    },
    {
        path: '/clients/prospect',
        component: newpage,
        key: '/clients/prospect',
    },
    {
        path: '/confirmation-Statement/details',
        component: CSFEditButton,
        key: '/confirmation-Statement/details',
    },
    {
        path: '/confirmation-Statement/history',
        component: CSFillingStatusList,
        key: '/confirmation-Statement/history',
    },
    {
        path: '/thing-to-do/send-email',
        component: SendServiceProcessEmail,
        key: '/thing-to-do/send-email',
    },
    {
        path: '/Things-to-Follow/send-email',
        component: SendServiceProcessEmail,
        key: '/Things-to-Follow/send-email',
    },
    {
        path: '/Things-to-Follow/service-process',
        component: ServiceProcessList,
        key: '/Things-to-Follow/service-process',
    },
    {
        path: '/Things-to-Follow/service-process/send-email',
        component: SendServiceProcessEmail,
        key: '/Things-to-Follow/service-process/send-email',
    },
]
// interface RouteDataIntrface {
//     dynamicPath: string
//     perntName: string
// }
// interface RoutingListProps {
//     routeData: RouteDataIntrface
// }
function RoutingList(): React.ReactElement {
    // const clientRoute = {
    //     path: routeData.dynamicPath,
    //     component: newpage,
    //     key: routeData.dynamicPath,
    // }

    // // Create an updated routes list by adding the dynamic route
    // const updatedRoutes = [...routes, clientRoute]
    return (
        <div>
            <Routes key={'1'}>
                {routes?.map((item) => {
                    return (
                        <Route
                            path={item.path}
                            Component={item.component}
                            key={item.key}
                        />
                    )
                })}
            </Routes>
        </div>
    )
}

export default RoutingList
// const mapStateToProps = (state: any): RoutingListProps => {
//     return {
//         routeData: state.dashboardFlag.routeData,
//     }
// }
// export default connect(mapStateToProps)(RoutingList)
