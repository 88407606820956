// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceName_YKXnt {
  text-align: left;
  margin-left: 10px;
}
.serviccNumInput_N0Pvn {
  width: 70%;
}
.textAreaWide_wYuBx {
  width: 50%;
  margin-left: 15px;
}
.datePicker_aNFmB {
  position: relative;
  width: 100%;
}
.TermsSigine_ShmG9 {
  background-color: #f7c5c5;
}
.TermsSigineText_PlKc0 {
  padding: 10px;
}
.enableService_eM6ub {
  align-self: right;
  text-align: right;
  color: #175ca9;
  right: 0;
  cursor: pointer;
  position: relative;
}
.right_alignButtonIcon_n0Cfb {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.controlItemWidth_VXZ1N {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Corporate/Buttons/Compliance/complince.less"],"names":[],"mappings":"AAOA;EACI,gBAAA;EACA,iBAAA;AANJ;AASA;EACI,UAAA;AAPJ;AAUA;EACI,UAAA;EACA,iBAAA;AARJ;AAeA;EACI,kBAAA;EACA,WAAA;AAbJ;AAgBA;EACI,yBAAA;AAdJ;AAgBA;EACI,aAAA;AAdJ;AAgBA;EACI,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,QAAA;EACA,eAAA;EACA,kBAAA;AAdJ;AA2BA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;AAzBJ;AA2BA;EACI,WAAA;AAzBJ","sourcesContent":["// .serviceItem {\n//     display: flex;\n//     justify-content: space-between;\n//     align-items: left;\n//     margin-bottom: 10px;\n// }\n\n.serviceName {\n    text-align: left;\n    margin-left: 10px;\n}\n\n.serviccNumInput {\n    width: 70%;\n}\n\n.textAreaWide {\n    width: 50%;\n    margin-left: 15px;\n}\n\n// .datePickerLeft {\n//     margin-top: 10px;\n// }\n\n.datePicker {\n    position: relative;\n    width: 100%;\n}\n\n.TermsSigine {\n    background-color: #f7c5c5;\n}\n.TermsSigineText {\n    padding: 10px;\n}\n.enableService {\n    align-self: right;\n    text-align: right;\n    color: #175ca9;\n    right: 0;\n    cursor: pointer;\n    position: relative;\n}\n\n// // .totalbox {\n// //     margin-top: 20px;\n// //     margin-bottom: 20px;\n// // }\n\n// // .serviceSwitch {\n// //     margin-top: 20px !important;\n// //     margin-bottom: 20px !important;\n// // }\n\n.right_alignButtonIcon {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n.controlItemWidth {\n    width: 100%;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceName": `serviceName_YKXnt`,
	"serviccNumInput": `serviccNumInput_N0Pvn`,
	"textAreaWide": `textAreaWide_wYuBx`,
	"datePicker": `datePicker_aNFmB`,
	"TermsSigine": `TermsSigine_ShmG9`,
	"TermsSigineText": `TermsSigineText_PlKc0`,
	"enableService": `enableService_eM6ub`,
	"right_alignButtonIcon": `right_alignButtonIcon_n0Cfb`,
	"controlItemWidth": `controlItemWidth_VXZ1N`
};
export default ___CSS_LOADER_EXPORT___;
