import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip, Switch, Drawer } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import calculatePagination from '../../../Utils/Pagination'
import type {
    DataCHAT,
    ProspectClientDetailsListDataTypeInterface,
    ProspectClientFormDataInterface,
    ProspectClientListInterface,
} from '../../../Types/Client/ProspectClients/prospectClient'
import ProspectClientDetailsExpandView from './prospectclientExpendview'
import {
    ActiveInactiveProspect,
    DeleteProspectClient,
    GetProspectClientDetailsList,
    SaveProspectClient,
} from '../../../Services/ProspectClient'
import ProspectClientForm from './prospectClientForm'
import {
    PhoneOutlined,
    MailOutlined,
    MessageOutlined,
    HistoryOutlined,
    CommentOutlined,
} from '@ant-design/icons'
import ProspectSms from './history/sms'
import ProspectPhoneCall from './history/Phonecall'
import ProspectHistory from './history/historys'
import { useNavigate } from 'react-router-dom'
import StaffCommunication from '../../../Components/StaffChat'
import { GetChat } from '../../../Services/Common'
import { ErrorMessage } from '../../../Utils/Notification'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const ProspectClientItemDefaultData = {
    prospectClientId: 0,
    title: '',
    firstName: '',
    middleName: '',
    surName: '',
    companyName: '',
    referredBy: '',
    mobileNumber: '',
    emailAddress: '',
    address1: '',
    address2: '',
    town: '',
    postCode: '',
    country: '',
    description: '',
    isActive: '',
    lastCommunicationStatus: '',
    lastCommunicationStatusUrl: '',
    isNewChatExist: 0,
    clientDynCategory: 0,
}

const ProspectClientDetailsList = (
    props: ProspectClientListInterface
): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        prospectClientDetailsList,
        prospectClientDetailsListCount,
        siderClientID,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [SMSModalStatus, setSMSModalStatus] = useState(false)
    const [Phonecallmodel, setPhonecallmodel] = useState(false)
    const [Historymodel, setHistorymodel] = useState(false)
    const [prosClientId, setProsClientId] = useState('')
    const [prospectSMSClientId, setprospectSMSClientId] = useState(0)
    const [prospectChatClientId, setprospectChat] = useState(0)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Prospect Client'
    )
    const [editData, setEditData] = useState<ProspectClientFormDataInterface>(
        ProspectClientItemDefaultData
    )
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('Active')
    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [buttonloading, setButtonloading] = useState(false)
    const [phonecallModelClick, setPhoneCallModelClick] = useState(false)
    const [resetData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (prospectChatClientId !== 0) {
            void GetChat(
                dispatch,
                5,
                prospectChatClientId,
                0,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [prospectChatClientId])

    const showDrawer = (id: number): void => {
        setprospectChat(id)
        setOpen(true)
    }

    const onClose = (): void => {
        setOpen(false)
    }
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetProspectClientDetailsList(dispatch, siderClientID, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        siderClientID,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: prospectClientDetailsListCount,
            },
        })
    }, [prospectClientDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        prospectClientDetailsListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<ProspectClientDetailsListDataTypeInterface> = [
        {
            title: 'Name',
            render: (_, record: ProspectClientDetailsListDataTypeInterface) => (
                <span>
                    {record.title === 'UNKNOWN' ? '' : record.title}{' '}
                    {record.firstName} {record.surName}
                </span>
            ),
            sorter: (a, b) => a.prospectClientId - b.prospectClientId,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            width: 250,
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Contact No',
            dataIndex: 'mobileNumber',
        },
        {
            title: '',
            key: 'action',

            render: (_, record: ProspectClientDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle" direction="vertical">
                        <span style={{ display: 'block' }}>
                            {record.lastCommunicationStatus}
                        </span>

                        <span>
                            <Space size="middle">
                                <Tooltip title="Communication History">
                                    <HistoryOutlined
                                        onClick={() => {
                                            openHistoryModel(
                                                record?.prospectClientId
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                                <Tooltip title="Send Mail">
                                    <MailOutlined
                                        onClick={() => {
                                            viewSendEmail(
                                                record?.prospectClientId
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                                <Tooltip title="Send SMS">
                                    <MessageOutlined
                                        onClick={() => {
                                            openSMSModel(
                                                record?.prospectClientId
                                            )
                                            setPhoneCallModelClick(true)
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                                <Tooltip title="Phone Calls">
                                    <PhoneOutlined
                                        onClick={() => {
                                            openPhonecallModel(
                                                record?.prospectClientId
                                            )
                                            setPhoneCallModelClick(true)
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                            </Space>
                        </span>
                    </Space>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: ProspectClientDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('3000', PermissionType.UPDATE) ? (
                            <Tooltip title="Edit Prospect Client">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.prospectClientId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        <Tooltip title="Staff Communication">
                            <CommentOutlined
                                onClick={() => {
                                    showDrawer(record?.prospectClientId)
                                    setResetData('true')
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.prospectClientId?.toString(),
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) ? (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            (record?.prospectClientId).toString()
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ProspectClientDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let prosClientId = ''
        if (!deleteModalStatus) {
            prosClientId = id
        }
        setProsClientId(prosClientId)
    }

    const deleteProspectDetails = (): void => {
        DeleteProspectClient(dispatch, prosClientId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let prosClientId = ''
        if (!activeInactiveModalStatus) {
            prosClientId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setProsClientId(prosClientId)
    }

    const updateActiveInactiveGroupAndPartners = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveProspect(dispatch, prosClientId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const openEditModal = (id: number): void => {
        const data = prospectClientDetailsList?.find(
            (data: ProspectClientFormDataInterface) => {
                return data.prospectClientId === id
            }
        )

        let tittle = 'Create Prospect'
        if (id > 0) {
            tittle = 'Edit Prospect'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? ProspectClientItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const changeSMSModalStatus = (): void => {
        setSMSModalStatus(!SMSModalStatus)
    }

    const openSMSModel = (id: number): void => {
        setprospectSMSClientId(id)
        setSMSModalStatus(!SMSModalStatus)
    }

    const changePhonecallModalStatus = (): void => {
        setPhonecallmodel(!Phonecallmodel)
    }

    const changeHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setHistorymodel(!Historymodel)
    }

    const openHistoryModel = (id: number): void => {
        setprospectSMSClientId(id)
        setHistorymodel(!Historymodel)
    }

    const openPhonecallModel = (id: number): void => {
        setprospectSMSClientId(id)
        setPhonecallmodel(!Phonecallmodel)
    }
    const viewSendEmail = (id: number): void => {
        navigate(`/clients/prospect-clients/send-email`, {
            state: { id },
        })
    }
    const onSaveProspectClient = (
        data: ProspectClientFormDataInterface,
        callBack: CallableFunction
    ): void => {
        if (data.emailAddress.includes('.com.')) {
            ErrorMessage('Plese Input Correct Email')
        } else {
            const datasave = {
                ...data,
                clientDynCategory: siderClientID,
            }
            void saveBanDetailsData(datasave, callBack)
        }
    }

    const saveBanDetailsData = async (
        data: ProspectClientFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        setButtonloading(true)
        await SaveProspectClient(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                    setButtonloading(false)
                }
            })
            .catch(() => {})
    }

    const expandableData = (
        record: ProspectClientDetailsListDataTypeInterface
    ): React.ReactElement => {
        return (
            <ProspectClientDetailsExpandView
                prospectClientDetailsData={record}
            />
        )
    }

    return (
        <>
            <div>
                <h2>Prospect Clients</h2>
            </div>
            <Card
                title="Prospect Clients"
                extra={
                    <AddNewButton
                        name="Create Prospect"
                        clickAction={() => {
                            openEditModal(0)
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search your keywords company name'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={prospectClientDetailsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Prospect. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteProspectDetails}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Prospect. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveGroupAndPartners}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <ProspectClientForm
                        onSave={onSaveProspectClient}
                        editData={editData}
                        buttonloading={buttonloading}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeSMSModalStatus}
                    width={'25%'}
                    title={'SMS'}
                    open={SMSModalStatus}
                >
                    <ProspectSms
                        prospectClientId={prospectSMSClientId}
                        changeSMSModalStatus={changeSMSModalStatus}
                        phonecallModelClick={phonecallModelClick}
                        setPhoneCallModelClick={setPhoneCallModelClick}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changePhonecallModalStatus}
                    width={'25%'}
                    title={'Phone Calls'}
                    open={Phonecallmodel}
                >
                    <ProspectPhoneCall
                        prospectClientId={prospectSMSClientId}
                        changePhoneCallModalStatus={changePhonecallModalStatus}
                        phonecallModelClick={phonecallModelClick}
                        setPhoneCallModelClick={setPhoneCallModelClick}
                    />
                </Modal>

                <Modal
                    footer={null}
                    onCancel={changeHistoryModalStatus}
                    width={'50%'}
                    title={'Communication History'}
                    open={Historymodel}
                >
                    <ProspectHistory
                        prospectClientId={prospectSMSClientId}
                        changePhoneCallModalStatus={changeHistoryModalStatus}
                        setButtonID={setSelectedButtonId}
                        seletedButton={selectedButtonId}
                    />
                </Modal>
                <Drawer
                    title={ChatDatas?.clientName}
                    width={550}
                    onClose={onClose}
                    open={open}
                >
                    <StaffCommunication
                        refernceId={prospectChatClientId}
                        messageTypeId={5}
                        businessId={0}
                        resetdata={resetData}
                        setResetData={setResetData}
                    />
                </Drawer>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ProspectClientListInterface => {
    return {
        prospectClientDetailsList: state.client.prospectClientDetailsList,
        prospectClientDetailsListCount:
            state.client.prospectClientDetailsListCount,
        siderClientID: state.dashboardFlag.siderClientID,
    }
}

export default connect(mapStateToProps)(ProspectClientDetailsList)
