import { type Dispatch } from '@reduxjs/toolkit'

import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import { type Email } from '../Types/Client/ProspectClients/prospectClient'
import axios from '../Config/AxiosConfig'
import axioss from 'axios'
import { SetClientEmailStatus } from './Corporate'
export const SaveFormToClient = async (
    dispatch: Dispatch,
    data: Email,
    attachment: string[]
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/Email/SetClientEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                // const emailSend = {
                //     email: data.toAddress,
                //     subject: data.subject,
                //     htmlMessage: 'Email-Template',
                //     description: data.description,
                //     attachments: attachment,
                //     ccemailAddress: data.ccAddress,
                //     bccEmailAddress: data.bccAddress,
                //     contactName: '',
                //     employeeName: '',
                //     actionUrl: '',
                // }
                // void SendEmailUseNodeJsInCLientTofornm(
                //     dispatch,
                //     emailSend,
                //     response.data.identifierId
                // )
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                dispatch(setLoadingStatus({ status: false }))
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SendEmailUseNodeJsInCLientTofornm = async (
    dispatch: Dispatch,
    data: any,
    indentId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    // https://mailserver.iykons.com/send-email
    // http://localhost:5001/
    return await axioss
        .post(`https://mailserver.iykons.com/send-email`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage('Email Send SucessFully')
                SetClientEmailStatus(dispatch, indentId)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
