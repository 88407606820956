// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treeNodeTitle_Jx5lx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.treeNodeControls_GKZ6W {
  display: flex;
  align-items: center;
}
.treeNodeControls_GKZ6W button {
  margin-left: 18px;
}
.treeNodeTitle_Jx5lx span {
  font-size: 14px;
}
.addNewSubtask_CFNel {
  color: #499ce0;
  font-weight: bold;
  cursor: pointer;
}
.cardMargin_LMSKm {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/InitialSetup/ProjectManagement/Tabs/Tabs.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;AAAJ;AAGA;EACI,iBAAA;AADJ;AAIA;EACI,eAAA;AAFJ;AAKA;EACI,cAAA;EACA,iBAAA;EACA,eAAA;AAHJ;AAMA;EACI,mBAAA;AAJJ","sourcesContent":[".treeNodeTitle {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.treeNodeControls {\n    display: flex;\n    align-items: center;\n}\n\n.treeNodeControls button {\n    margin-left: 18px;\n}\n\n.treeNodeTitle span {\n    font-size: 14px;\n}\n\n.addNewSubtask {\n    color: rgb(73, 156, 224);\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.cardMargin {\n    margin-bottom: 20px;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeNodeTitle": `treeNodeTitle_Jx5lx`,
	"treeNodeControls": `treeNodeControls_GKZ6W`,
	"addNewSubtask": `addNewSubtask_CFNel`,
	"cardMargin": `cardMargin_LMSKm`
};
export default ___CSS_LOADER_EXPORT___;
