import React from 'react'
import { Button, ConfigProvider, Tooltip } from 'antd'

interface DefaultButtonProps {
    name: string
    clickAction?: () => void
    color: string
    icon?: React.ReactNode
    htmlType?: 'button' | 'submit' | 'reset'
    size?: 'large' | 'small' | 'middle'
}

const CusttomBackgroundButton = (
    props: DefaultButtonProps
): React.ReactElement => {
    const {
        name = 'Add New',
        clickAction,
        color,
        icon,
        htmlType,
        size = 'middle',
    } = props

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: color,
                },
            }}
        >
            <Tooltip title={name}>
                <Button
                    type="primary"
                    size={size}
                    icon={icon}
                    onClick={clickAction}
                    htmlType={htmlType}
                >
                    {name}
                </Button>
            </Tooltip>
        </ConfigProvider>
    )
}

export default CusttomBackgroundButton
