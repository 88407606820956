import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    InputNumber,
    Tooltip,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { type OptionList } from '../../../Types/Invoice/Invoicing'
import {
    type ServiceProcessInviceProps,
    type ModelProps,
} from '../../../Types/ToDoViewAllList'
import invoive from '../../Invoice/Invoicing/Invoice.less'
import { GetPaymentOption } from '../../../Services/Invoice'
import { SaveInvoicePymentDetails } from '../../../Services/ToDoViewAllList'
import dayjs from 'dayjs'
import { SetInvoiceDetails } from '../../../Services/Invoicing/Invoice'

const InvoiceModel = (props: ModelProps): React.ReactElement => {
    const {
        serviceProcessId,
        paymentOptionList,
        changeModalStatus,
        serviceId,
        businessType,
        referenceId,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [paymentoptions, setPymentOptions] = useState<OptionList[]>(
        [] as OptionList[]
    )
    const [selectedpaymentOption, setSelectedpaymentOption] = useState('')
    const [buttonLoading, setButtonloading] = useState(false)
    useEffect(() => {
        GetPaymentOption(dispatch)
    }, [])
    useEffect(() => {
        if (paymentOptionList !== undefined) {
            setPymentOptions(
                paymentOptionList.map((client) => {
                    return {
                        label: client.displayName,
                        value: client.id,
                        id: client.additionalDataFour,
                        desc: (
                            <div>
                                <div className={invoive.clintDropdownListDiv}>
                                    {/* <img
                                        className={invoive.clintDropdownListImg}
                                        src={client.displayImageURL}
                                        alt="User"
                                    /> */}
                                    <span>{client.displayName}</span>
                                </div>
                                <div>
                                    <span>
                                        {client.additionalDataTwo}-
                                        {client.additionalDataOne}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            invoive.clientDropdownListSpan
                                        }
                                    >
                                        Name-{client.additionalDataThree}
                                    </span>
                                    <span
                                        className={
                                            invoive.clientDropdownListSpan2
                                        }
                                    ></span>
                                    <span
                                        className={
                                            invoive.clientDropdownListSpan
                                        }
                                    >
                                        ContactNo-{client.additionalDataFour}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Email-{client.additionalDataFive}
                                    </span>
                                </div>
                            </div>
                        ),
                    }
                })
            )
        }
    }, [paymentOptionList])
    const handlePaymentOptionChange = (value: string): void => {
        setSelectedpaymentOption(value)
    }

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            setButtonloading(true)
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                invoicePaymentStatusId: 0,
                serviceProcessId,
                currentStatus: 'INVOICED',
                paymentStatus: 'UNPAID',
                paymentOptionId: parseInt(selectedpaymentOption),
                description: formData.description ?? '',
            }
            const currentDate = dayjs()
            await SaveInvoicePymentDetails(dispatch, dataToSave)
            const invoiceData = {
                invoiceId: 0,
                invoiceNo: '0',
                invoiceCategory: 0,
                businessType: businessType ?? 0,
                invoiceFor: referenceId ?? 0,
                invoiceForDescription:
                    '14 ELM ROAD N/A,\n CHESSINGTON,\n KT9 1AW,\n UNITED KINGDOM.',
                invoiceDate: currentDate.toString(),
                paymentDueDate: currentDate.toString(),
                total: formData.amount,
                subTotal: formData.amount,
                discountTotal: 0,
                taxTotal: 0,
                netTotal: formData.amount,
                preparedBy: 1,
                paymentNotifyTo: 0,
                paidTo: 0,
                paidToDescription:
                    '14 ELM ROAD N/A,\n CHESSINGTON,\n KT9 1AW,\n UNITED KINGDOM.',
                approvedBy: 1,
                invoiceStatus: 1,
                paymentModeId: 0,
                paymentOptionId: parseInt(selectedpaymentOption),
                remarks: '',
                clientId: '0',
                clientName: '',
                paymentOptionName: '',
                acc_InvoiceDetailList: [
                    {
                        discount: 0,
                        discountValue: 0,
                        discountType: 2,
                        invoiceId: 0,
                        lineDescription: formData.description ?? '',
                        grossTotal: formData.amount,
                        netAmount: formData.amount,
                        quantity: 1,
                        serviceId: serviceId ?? 0,
                        taxApplied: 0,
                        tax: 0,
                        totalAfterDiscount: 0,
                        unitPrice: formData.amount,
                    },
                ],
                acc_InvoiceDetailViewList: [
                    {
                        discount: 0,
                        discountType: 0,
                        discountValue: 0,
                        grossTotal: 0,
                        invoiceDetailId: 0,
                        invoiceId: 0,
                        lineDescription: '',
                        netAmount: 0,
                        quantity: 0,
                        serviceId: 0,
                        tax: 0,
                        taxApplied: 0,
                        totalAfterDiscount: 0,
                        unitPrice: 0,
                        serviceName: '',
                        description: '',
                    },
                ],
                isSent: 0,
                sentDate: '',
                serviceProcessId,
                isPaid: 0,
                paidDate: '',
                paidRemarks: '',
                accountName: '',
                accountNo: '',
                branch: '',
                swiftCode: '',
                iban: '',
            }

            await SetInvoiceDetails(dispatch, invoiceData)
                .then((result) => {
                    if (result !== null && result !== undefined) {
                        setButtonloading(false)
                    }
                })
                .catch(() => {})
            form.resetFields()

            changeModalStatus()
        } catch (error) {}
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
            >
                <Form.Item
                    name="invoiceId"
                    hidden={true}
                    initialValue={serviceProcessId}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Amount" name="amount">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Payment Option"
                            name="paymentOptionId"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={handlePaymentOptionChange}
                                optionFilterProp="children"
                                value={selectedpaymentOption}
                                optionLabelProp="label"
                                options={paymentoptions}
                                optionRender={(option) => (
                                    <>{option.data?.desc}</>
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description " name="description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title=" Save Invoice">
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                                loading={buttonLoading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): ServiceProcessInviceProps => {
    return {
        paymentOptionList: state.invoice.paymentOptionList,
    }
}
export default connect(mapStateToProps)(InvoiceModel)
