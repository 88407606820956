// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synchronize_Hum4A {
  margin-top: 30px;
}
.companyHouseLink_ZFQhl {
  margin-left: 8px;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/Less/Clients/RegisteredClients/Corporate/CorporateNew.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,gBAAA;EACA,0BAAA;AAAJ","sourcesContent":[".synchronize {\n    margin-top: 30px;\n}\n\n.companyHouseLink {\n    margin-left: 8px;\n    text-decoration: underline;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"synchronize": `synchronize_Hum4A`,
	"companyHouseLink": `companyHouseLink_ZFQhl`
};
export default ___CSS_LOADER_EXPORT___;
