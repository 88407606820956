/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react'
import {
    Button,
    Collapse,
    Col,
    Row,
    Dropdown,
    Modal,
    Card,
    Tooltip,
} from 'antd'
import {
    EditOutlined,
    EyeOutlined,
    DownOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import FullWidthModal from '../../../../../../Components/FullWidthModal'
import { connect, useDispatch } from 'react-redux'
import {
    AttachIndividual,
    GenerateAccount,
} from '../../../../../../Services/Corporate'
import OfficerPersonModelForm from '../../../Corporate/Buttons/OfficerButton/OfficerPersonModelForm'
import Partners from './partners'
import PartnerViewPage from './patnerviewPage'
import PartnerMemberEditForm from './partnerEditPage'
import {
    type PartnerTapData,
    type PartnersDataInterface,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import {
    DeletePartner,
    getClientCompanyDetails,
} from '../../../../../../Services/Parnership'

type EditStates = Record<number, boolean>
const defaultPartnerData = {
    partnerId: 0,
    partnershipId: 0,
    individualId: 0,
    tagIndividualId: 0,
    individualClientNumber: '',
    title: '',
    firstName: '',
    middleName: '',
    surName: '',
    dateOfBirth: '',
    partnerAddressOne: '',
    partnerAddressTwo: '',
    partnerTown: '',
    partnerCountry: '',
    partnerPostCode: '',
    mobileNumber: '',
    homeNumber: '',
    workNumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    individualUTR: '',
    niNumber: '',
    isActive: '',
    isDelete: '',
}
const PartnerMainPage = (props: {
    partnershipId: number
    partnershipTaps: PartnerTapData[]
}): React.ReactElement => {
    const { partnershipId, partnershipTaps } = props
    const dispatch = useDispatch()
    const [isEditMode, setIsEditMode] = useState<EditStates>({})
    const [generateAccountModalVisible, setGenerateAccountModalVisible] =
        useState(false)
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
        useState(false)
    const [tagModalVisible, setTagModalVisible] = useState(false)
    const [memeberreferenceId, setMemberRefernce] = useState<number>(0)
    const [referenceId, setRefernceid] = useState<number>(0)
    const [editData, setEditData] = useState<PartnerTapData>(defaultPartnerData)
    const [tagName, setTagName] = useState('')
    const [removeAccounModel, setRemoveAccountModel] = useState(false)
    const [SelectName, setName] = useState<number>(0)
    const [activeKey, setActiveKey] = useState<string | string[]>([])
    const handleEditClick = (referenceId: number): void => {
        setIsEditMode((prevEditStates) => ({
            ...prevEditStates,
            [referenceId]: !prevEditStates[referenceId],
        }))
        const datas = partnershipTaps?.find((data: PartnerTapData) => {
            return data?.partnerId === referenceId
        })
        setEditData(datas ?? defaultPartnerData)
    }

    const openTagModal = (): void => {
        setTagModalVisible(true)
        setGenerateAccountModalVisible(false)
    }

    const handleCancel = (): void => {
        setTagModalVisible(false)
    }

    useEffect(() => {
        const memberDetailss =
            SelectName !== null && SelectName !== 0
                ? partnershipTaps?.find(
                      (member: { partnerId: number }) =>
                          member.partnerId === SelectName
                  )
                : null

        setTagName(memberDetailss?.individualId.toString() ?? '')
    }, [SelectName])
    const opendleteModel = (referenceId: number): void => {
        setRefernceid(referenceId)
        setDeleteAccountModalVisible(true)
    }
    const items =
        tagName === ''
            ? [
                  {
                      key: '1',
                      label: 'Attach Tag',
                      onClick: openTagModal,
                  },
                  {
                      key: '2',
                      label: 'Generate Account',
                      onClick: () => {
                          setGenerateAccountModalVisible(true)
                      },
                  },
              ]
            : [
                  {
                      key: '3',
                      label: 'Remove Tag',
                      onClick: () => {
                          setRemoveAccountModel(true)
                      },
                  },
              ]

    const hadelClickGenerateAccounts = (referenceId: number): void => {
        setMemberRefernce(referenceId)
    }

    const GenerateAccounts = (getreferenceid: number): void => {
        if (getreferenceid !== null) {
            void GenerateAccount(dispatch, getreferenceid)
        }
        setGenerateAccountModalVisible(false)
    }

    const handeldeleteShareholder = (partnerId: number): void => {
        void DeletePartner(dispatch, partnershipId, partnerId)
        setTimeout(() => {
            ReFetchData()
        }, 2000)

        setDeleteAccountModalVisible(false)
    }
    const tabIndex = 8
    const businessTypeId = 4
    const ReFetchData = (): void => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            partnershipId,
            tabIndex
        )
    }

    const handleSaveCallBack = (data: PartnerTapData): void => {
        setIsEditMode((prevEditStates) => ({
            ...prevEditStates,
            [data?.partnerId ?? 0]: false,
        }))
    }

    const RemoveTagAccount = (getreferenceid: number): void => {
        void AttachIndividual(dispatch, getreferenceid, 4, Number(tagName), 1)
        setRemoveAccountModel(false)
        setTimeout(() => {
            ReFetchData()
        }, 2000)
    }
    const onsetName = (name: number): void => {
        setName(name)
    }
    return (
        <Card title="Partner Information">
            {Array.isArray(partnershipTaps) &&
                partnershipTaps?.map((partner, index) => (
                    <Collapse
                        defaultActiveKey={['']}
                        activeKey={activeKey}
                        className={commonStyles.corporateOfficercollaps}
                        key={index + 1}
                        onChange={(key) => {
                            setActiveKey(key)
                            const names = partner.partnerId
                            onsetName(names)
                        }}
                    >
                        <Collapse.Panel
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>
                                        {`${partner?.title} ${partner?.firstName} ${partner?.middleName} ${partner?.surName}`}
                                    </span>
                                </div>
                            }
                            key={index + 1}
                        >
                            <Row gutter={16} justify="end">
                                <Col>
                                    <Dropdown
                                        menu={{ items }}
                                        placement="bottom"
                                        arrow={{ pointAtCenter: true }}
                                    >
                                        <Button
                                            type="primary"
                                            onClick={(): void => {
                                                hadelClickGenerateAccounts(
                                                    partner?.partnerId
                                                )
                                            }}
                                        >
                                            {tagName !== '' ? tagName : 'Tag'}
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </Col>

                                <Col>
                                    <Tooltip title=" Delete Partner">
                                        <Button
                                            type="primary"
                                            onClick={(): void => {
                                                opendleteModel(
                                                    partner?.partnerId
                                                )
                                            }}
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>

                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={(): void => {
                                            handleEditClick(partner?.partnerId)
                                        }}
                                    >
                                        {isEditMode[partner?.partnerId] ? (
                                            <Tooltip title=" View Details">
                                                <EyeOutlined />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Edit">
                                                <EditOutlined />
                                            </Tooltip>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                            {isEditMode[partner?.partnerId] ? (
                                <PartnerMemberEditForm
                                    editData={editData}
                                    corporateId={partner?.partnerId}
                                    saveCallBack={(data: PartnerTapData) => {
                                        handleSaveCallBack(data)
                                    }}
                                    ReFetchData={ReFetchData}
                                />
                            ) : (
                                <PartnerViewPage partnershipTaps={partner} />
                            )}
                        </Collapse.Panel>
                    </Collapse>
                ))}
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header="Add Partner" key="1">
                    <Partners partnershipId={partnershipId} />
                </Collapse.Panel>
            </Collapse>
            <FullWidthModal
                modalStatus={generateAccountModalVisible}
                closeModal={() => {
                    setGenerateAccountModalVisible(false)
                }}
                tittle="You are about to generate the individual account. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={() => {
                    GenerateAccounts(memeberreferenceId)
                }}
            />
            <FullWidthModal
                modalStatus={deleteAccountModalVisible}
                closeModal={() => {
                    setDeleteAccountModalVisible(false)
                }}
                tittle="You are about to delete this shareholder. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={() => {
                    handeldeleteShareholder(referenceId)
                }}
            />
            <Modal
                title="Please select the individual Account"
                onCancel={() => {
                    setTagModalVisible(false)
                }}
                open={tagModalVisible}
                footer={null}
                width={'50%'}
            >
                <OfficerPersonModelForm
                    onCancel={handleCancel}
                    referencId={partnershipId}
                />
            </Modal>
            <FullWidthModal
                modalStatus={removeAccounModel}
                closeModal={() => {
                    setRemoveAccountModel(false)
                }}
                tittle="You are about to remove this business tag. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={() => {
                    RemoveTagAccount(memeberreferenceId)
                }}
            />
        </Card>
    )
}
const mapStateToProps = (state: any): PartnersDataInterface => {
    return {
        countryList: state.common.countryList,
        partnershipTaps: state.client.partnershipTaps,
        InduvidualMemberList: state.common.InduvidualMemberList,
    }
}
export default connect(mapStateToProps)(PartnerMainPage)
