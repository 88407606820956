// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateWidth_OIrz8 {
  position: absolute;
  width: 100%;
  top: 0px !important;
}
.services_XCCtS {
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Partnership/Buttons/Partneship services/Partnershipserviceless.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;AACJ;AACA;EACI,mBAAA;AACJ","sourcesContent":[".dateWidth {\n    position: absolute;\n    width: 100%;\n    top: 0px !important;\n}\n.services {\n    margin-bottom: 15px;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateWidth": `dateWidth_OIrz8`,
	"services": `services_XCCtS`
};
export default ___CSS_LOADER_EXPORT___;
