import { Card, Flex, Modal } from 'antd'
import React, { useState } from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import AddSAILAddress from '../CompanyHouseRelatedThings/AddNewSAILAddress'
import { type CompanyDetailsprops } from '../../../Types/CompanyHouse/CompanyOverView'
const dummySailAddress = {
    sailAddressId: 0,
    companyId: 0,
    corporateId: 0,
    houseNumber: '',
    poBoxNumber: '',
    address1: '',
    address2: '',
    town: '',
    region: '',
    country: '',
    postCode: '',
    isSubmitToCh: 0,
    submittedDate: '',
}
const SAILAddersss = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId, companyHouseOverViewList, businessId } = props
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const addressdata = companyHouseOverViewList?.cS_SailAddress?.address1
    const sailAddress = companyHouseOverViewList?.cS_SailAddress
    const openEditModal = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    return (
        <>
            <div>
                <h3>SAIL Address</h3>
            </div>
            {companyHousId !== 1 && (
                <Card title="Single Alternative Inspection Location (SAIL)">
                    <div>
                        Currently there is no SAIL address registered for this
                        company.
                    </div>
                </Card>
            )}

            {companyHousId === 1 && (
                <Card>
                    <div className={style.misstingInfo}>
                        If you want to do changes required in SAIL address
                        should be filed using the{' '}
                        <b>&quot;Create New Address&quot;</b> page before you
                        file the confirmation statement.
                    </div>
                    <h2>Single Alternative Inspection Location (SAIL)</h2>
                    <Flex justify="flex-start" gap={30}>
                        {companyHouseOverViewList?.cS_SailAddress !== null &&
                        companyHouseOverViewList?.cS_SailAddress !==
                            undefined ? (
                            <div>
                                {
                                    companyHouseOverViewList?.cS_SailAddress
                                        ?.houseNumber
                                }
                                ,
                                {
                                    companyHouseOverViewList?.cS_SailAddress
                                        ?.address1
                                }{' '}
                                {
                                    companyHouseOverViewList?.cS_SailAddress
                                        ?.address2
                                }
                                ,
                                {companyHouseOverViewList?.cS_SailAddress?.town}
                                ,
                                {
                                    companyHouseOverViewList?.cS_SailAddress
                                        ?.postCode
                                }
                                {
                                    companyHouseOverViewList?.cS_SailAddress
                                        ?.country
                                }
                                ,
                                {
                                    companyHouseOverViewList?.cS_SailAddress
                                        ?.poBoxNumber
                                }
                            </div>
                        ) : (
                            <div>
                                Currently there is no SAIL address registered
                                for this company.
                            </div>
                        )}
                        <CusttomBackgroundButton
                            color={'#175ca9'}
                            name={`${
                                addressdata !== undefined &&
                                addressdata?.length > 0
                                    ? 'Change'
                                    : 'Create New SAIL Address'
                            }`}
                            icon={
                                addressdata !== undefined &&
                                addressdata?.length > 0 ? (
                                    <EditOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            }
                            clickAction={() => {
                                openEditModal()
                            }}
                        />
                    </Flex>
                </Card>
            )}
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={'Create New SAIL Address'}
                open={createEditModalStatus}
            >
                <AddSAILAddress
                    companyName={companyHouseOverViewList?.clientName ?? ''}
                    companyNumber={
                        companyHouseOverViewList?.companyRegNumber ?? ''
                    }
                    corporateId={companyHouseOverViewList?.corporateId ?? 0}
                    oncloseModel={changeCreateEditModalStatus}
                    sailAddress={sailAddress ?? dummySailAddress}
                    businessId={businessId ?? 0}
                />
            </Modal>
        </>
    )
}

export default SAILAddersss
