/* eslint-disable */
// prettier-ignore
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd'
import DashboardComparison from './DashboardComparison'

const DashboardComparisonPopup: React.FC = () => {
    const [visible, setVisible] = useState(false)

    // Listen for Ctrl+Shift+T keydown to toggle the modal
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Ctrl, Shift, and T are pressed together
            if (
                event.ctrlKey &&
                event.shiftKey &&
                event.key.toLowerCase() === 'e'
            ) {
                setVisible(true)
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [])

    return (
        <Modal
            title="Dashboard Comparison"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            width="80%"
        >
            <DashboardComparison />
        </Modal>
    )
}

export default DashboardComparisonPopup
