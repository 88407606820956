import { Col, Row } from 'antd'
import React from 'react'
import { type PartnerTapData } from '../../../../../../Types/Client/RegisteredClients/Partnership'

interface partnerviewdata {
    partnershipTaps: PartnerTapData
}
const PartnerViewPage = (props: partnerviewdata): React.ReactElement => {
    const { partnershipTaps } = props

    return (
        <>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Date of Birth</p>
                </Col>
                <Col span={6}>
                    <p>: {partnershipTaps?.dateOfBirth}</p>
                </Col>
                <Col span={5}>
                    <p>Address </p>
                </Col>
                <Col span={7}>
                    <p>
                        :{partnershipTaps?.partnerAddressOne}
                        {partnershipTaps?.partnerAddressTwo}
                        {partnershipTaps?.partnerTown}
                    </p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Home No</p>
                </Col>
                <Col span={6}>
                    <p>: {partnershipTaps?.homeNumber}</p>
                </Col>
                <Col span={5}>
                    <p>Post Code </p>
                </Col>
                <Col span={7}>
                    <p>: {partnershipTaps?.partnerPostCode}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Mobile No</p>
                </Col>
                <Col span={6}>
                    <p>: {partnershipTaps?.mobileNumber}</p>
                </Col>
                <Col span={5}>
                    <p>Country </p>
                </Col>
                <Col span={7}>
                    <p>: {partnershipTaps?.partnerCountry}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Work </p>
                </Col>
                <Col span={6}>
                    <p>: {partnershipTaps?.workNumber}</p>
                </Col>
                <Col span={5}>
                    <p>NI No </p>
                </Col>
                <Col span={7}>
                    <p>: {partnershipTaps?.niNumber}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Individual UTR</p>
                </Col>
                <Col span={6}>
                    <p>: {partnershipTaps?.individualUTR} </p>
                </Col>
                <Col span={5}>
                    <p>Email</p>
                </Col>
                <Col span={7}>
                    <p>: {partnershipTaps?.emailAddress} </p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Alternative Email </p>
                </Col>
                <Col span={6}>
                    <p>:{partnershipTaps?.alternativeEmailAddress}</p>
                </Col>
            </Row>
        </>
    )
}

export default PartnerViewPage
