import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { TableListInterface } from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'
import {
    prospectClientDetailsList,
    prospectHistoryDetailsList,
} from '../Reducer/Client'
import {
    type Email,
    type CommunicationHistory,
    type ProspectClientFormDataInterface,
} from '../Types/Client/ProspectClients/prospectClient'
import { SaveEmailAttachments } from './Corporate'
import { SendEmailUseNodeJs } from './Common'

export const GetProspectClientDetailsList = (
    dispatch: Dispatch,
    clientDynCategoryId: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Prospect/GetClientProspectList/${clientDynCategoryId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ProspectClientFormDataInterface) => {
                        return {
                            ...value,
                            key: value.prospectClientId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(prospectClientDetailsList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveProspectClient = async (
    dispatch: Dispatch,
    data: ProspectClientFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Prospect/SetClientProspect`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeleteProspectClient = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/29/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ActiveInactiveProspect = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/18/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetInvidualProsPectDetails = async (
    dispatch: Dispatch,
    prospectId: number,
    getDetails: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Prospect/GetClientProspectDetail/${prospectId}`)
        .then((response) => {
            if (response.status === 200) {
                const details = response.data.data
                getDetails(details)
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveCommuniCationProspectClient = async (
    dispatch: Dispatch,
    data: CommunicationHistory
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Communication/SetOtherCommunication`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveEmail = async (
    dispatch: Dispatch,
    data: Email,
    attachment: string[]
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    const saveCommincationHistory = {
        otherCommunicationHistoryId: 0,
        communicationModeId: 1,
        communicationTypeId: 1,
        businessTypeId: 0,
        referenceId: data.referenceId,
        contactNumber: '',
        description: data.subject,
        historyDate: '',
    }
    return await axios
        .post(`/api/Email/SetClientEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)

                attachment.forEach((path): void => {
                    const dataToUpload = {
                        clientAttachmentId: response.data.identifierId,
                        clientEmailId: 0,
                        attachmentPath: path,
                        uniqueCode: '',
                    }
                    void SaveEmailAttachments(dispatch, dataToUpload)
                    // Perform any desired operation with each path
                })

                void SaveCommuniCationProspectClient(
                    dispatch,
                    saveCommincationHistory
                )

                const emailSend = {
                    email: data.toAddress,
                    subject: data.subject,
                    htmlMessage: 'Email-Template',
                    description: data.description,
                    attachments: attachment,
                    ccemailAddress: data.ccAddress,
                    bccEmailAddress: data.bccAddress,
                    contactName: data.contactName,
                    employeeName: '',
                    actionUrl: '',
                }
                void SendEmailUseNodeJs(dispatch, emailSend)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetProspectClientHistoryDetailsList = (
    dispatch: Dispatch,
    selectedButtonId: number,
    referenceId: number,
    selectedButtonIdd: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Communication/GetOtherCommunicationHistoryList/${selectedButtonId}/${referenceId}/${selectedButtonIdd}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CommunicationHistory) => {
                        return {
                            ...value,
                            key: value.otherCommunicationHistoryId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(prospectHistoryDetailsList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
