import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    type PartnerDocumentList,
    type CompanyAttachment,
    type PartnershipDocumentView,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import {
    EyeOutlined,
    CloudDownloadOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import commontStyles from './document.less'
import commoncss from '../../../../../../Utils/Common.less'
import OfficerDocumentExpanView from './officerDocumentExpanView'
import OfficerDcumentForm from './officerDocumentForm'
import DocumentViewModelForm from './documentViewModel'
import { GetDocumenttypeList } from '../../../../../../Services/Common'
import ServiceDocumentUpload from './serviceDocumentUpload'
import { getClientCompanyDetails } from '../../../../../../Services/Parnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import { SaveOfficerDocument } from '../../../../../../Services/Corporate'

const DocumentMain = (
    props: PartnershipDocumentView & {
        partnershipId: number
        companyservicedetails: any
    }
): React.ReactElement => {
    const {
        partnershipTaps,
        documenttypeList,
        partnershipId,
        companyservicedetails,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [viewModalVisible, setViewModalVisible] = useState(false)
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(22)
    const [companyAttachmentId, setCompanyAttachmentId] = useState<number>(0)
    const [selectedDocument, setSelectedDocument] = useState<string>('')
    const [downloadShowId, setDownloadShowId] = useState<number>(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetDocumenttypeList(dispatch, 3, 1, 4)
        hasFetchedOnce.current = false
    }, [])

    const OpenModel = (attachmentPath: string, dowId: number): void => {
        setSelectedDocument(attachmentPath)
        setViewModalVisible(true)
        setDownloadShowId(dowId)
    }

    const columns = [
        {
            title: 'Document Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => (
                <Space size={'middle'}>
                    {record?.attachmentPath !== '' &&
                    record?.attachmentPath !== undefined &&
                    record.attachmentPath !== null ? (
                        <>
                            <Tooltip title={`View`}>
                                <EyeOutlined
                                    onClick={() => {
                                        OpenModel(record.attachmentPath, 0)
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={`Download`}>
                                <CloudDownloadOutlined
                                    onClick={() => {
                                        OpenModel(record.attachmentPath, 1)
                                    }}
                                />
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip title={`Not Available`}>
                                <EyeOutlined />
                            </Tooltip>
                            <Tooltip title={`Not Available`}>
                                <CloudDownloadOutlined />
                            </Tooltip>
                        </>
                    )}
                </Space>
            ),
        },
    ]

    const officerColums = [
        {
            title: 'Name',
            dataIndex: 'documentOwner',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    officerDocumentList: Array<{
                        documentBaseIcon: string | undefined
                        attachmentPath: string
                    }>
                }
            ) => (
                <Space size="middle">
                    {record.officerDocumentList?.map((doc, index) => (
                        <React.Fragment key={index}>
                            {doc?.attachmentPath !== '' &&
                            doc?.attachmentPath !== undefined &&
                            doc.attachmentPath !== null ? (
                                <>
                                    <Tooltip title={`View`}>
                                        <EyeOutlined
                                            onClick={() => {
                                                OpenModel(doc.attachmentPath, 0)
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={` Download`}>
                                        <CloudDownloadOutlined
                                            onClick={() => {
                                                OpenModel(doc.attachmentPath, 1)
                                            }}
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Tooltip title={`Not Available`}>
                                        <EyeOutlined />
                                    </Tooltip>
                                    <Tooltip title={`Not Available`}>
                                        <CloudDownloadOutlined />
                                    </Tooltip>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </Space>
            ),
        },
    ]

    const Servicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    attachmentPath: string
                }
            ) => (
                <Space size={'middle'}>
                    {record?.attachmentPath !== '' &&
                    record?.attachmentPath !== undefined &&
                    record.attachmentPath !== null ? (
                        <>
                            <Tooltip title={`View`}>
                                <EyeOutlined
                                    onClick={() => {
                                        OpenModel(record.attachmentPath, 0)
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={`Download`}>
                                <CloudDownloadOutlined
                                    onClick={() => {
                                        OpenModel(record.attachmentPath, 1)
                                    }}
                                />
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip title={`Not Available`}>
                                <EyeOutlined />
                            </Tooltip>
                            <Tooltip title={`Not Available`}>
                                <CloudDownloadOutlined />
                            </Tooltip>
                        </>
                    )}
                </Space>
            ),
        },
    ]

    const filterServicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => <Space size="middle"></Space>,
        },
    ]

    const dataSource = partnershipTaps?.officerDocumentHeaderList?.map(
        (item) => ({
            documentOwner: item.documentOwner,
            officerDocumentList: item.officerDocumentList,
        })
    )

    const expandableData = (record: {
        officerDocumentList: PartnerDocumentList[]
    }): JSX.Element => {
        return (
            <OfficerDocumentExpanView expandData={record.officerDocumentList} />
        )
    }

    const uniqueServiceDocuments = Array.from(
        new Set(
            partnershipTaps?.serviceDocumentList?.map(
                (document) => document.companyAttachmentId
            )
        )
    )?.map((companyAttachmentId) =>
        partnershipTaps?.serviceDocumentList?.find(
            (document) => document.companyAttachmentId === companyAttachmentId
        )
    )

    const handleDocumentTitleChange = (value: number): void => {
        setSelectedDocumentType(value)
        form.resetFields(['attachmentPath'])
    }

    useEffect(() => {
        const matchingDocument = partnershipTaps?.generalDocumentList?.find(
            (document) => document.documentBaseId === selectedDocumentType
        )
        if (matchingDocument != null) {
            const companyAttachmentIds = matchingDocument.companyAttachmentId
            setCompanyAttachmentId(companyAttachmentIds)
        } else {
            setCompanyAttachmentId(0)
        }
    }, [selectedDocumentType])

    const activeServices = companyservicedetails?.data?.filter(
        (service: { status: string }) => service.status === 'Active'
    )

    const servicedocumentName = uniqueServiceDocuments?.map((service) =>
        service?.serviceName.toUpperCase()
    )

    // Filter out items from 'a' that have a service name present in 'activeServices'
    const filteredServiceData = activeServices?.filter(
        (item: { serviceName: string }) =>
            !servicedocumentName.includes(item.serviceName)
    )

    const pensionDocuments = uniqueServiceDocuments
        ?.filter((document) => document?.serviceName === 'Pension')
        .map((document) => ({
            ...document,
            key: document?.companyAttachmentId,
        }))

    const pensionServiceNames = pensionDocuments.map((doc) => doc.serviceName)

    // Filter out services from filteredServiceData
    const updatedServiceData = filteredServiceData?.filter(
        (service: { serviceName: string }) =>
            !pensionServiceNames.includes(service.serviceName)
    )

    const businessTypeId = 4
    const tabIndex = 3
    const fetchData = (): void => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            partnershipId,
            tabIndex
        )
    }
    const handeleCompanDocumentSave = (): void => {
        void handeleDocumentSave()
    }

    const handeleDocumentSave = async (): Promise<void> => {
        let documentBaseNames = ''

        switch (selectedDocumentType) {
            case 1:
                documentBaseNames = 'Letter of Engagement'
                break
            case 2:
                documentBaseNames = 'Company Incorporation'
                break
            case 3:
                documentBaseNames = 'AML Document'
                break
            default:
                documentBaseNames = ''
                break
        }

        const savedata = {
            companyAttachmentId: companyAttachmentId ?? 0,
            companyTypeId: 4,
            referenceId: partnershipId,
            referenceId2: 0,
            documentBaseId: selectedDocumentType ?? 0,
            documentBaseName: documentBaseNames,
            attachmentPath: convertedString ?? '',
            corporateId: 0,
            type: 'GENERAL',
            ppExpiryDate: '',
            dlExpiryDate: '',
            prExpiryDate: '',
        }
        await SaveOfficerDocument(dispatch, savedata).then((result) => {
            if (result !== null && result !== undefined) {
                fetchData()

                form.resetFields(['attachmentPath'])
            }
        })
    }
    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const convertedString = uploadFileList
        .map((item) => item.slice())
        .join(', ')

    return (
        <>
            <Row>
                <Col span={24}>
                    <Collapse
                        expandIconPosition="end"
                        accordion
                        defaultActiveKey={['1']}
                    >
                        <Collapse.Panel header="Partnership Document" key="1">
                            <Row gutter={[24, 16]}>
                                <Col span={12}>
                                    <Table
                                        dataSource={
                                            partnershipTaps?.generalDocumentList
                                        }
                                        columns={columns}
                                        pagination={false}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className={commoncss.formWrapper}>
                                        <Form
                                            name="complex-form"
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            layout={'vertical'}
                                            autoComplete="off"
                                            form={form}
                                        >
                                            <Row
                                                gutter={[8, 8]}
                                                style={{ marginBottom: '80px' }}
                                            >
                                                <Col span={24}>
                                                    <Form.Item<CompanyAttachment>
                                                        label="Document Type"
                                                        name="title"
                                                        className={
                                                            commontStyles.CorporteDocumentGeenreal
                                                        }
                                                    >
                                                        <Select
                                                            allowClear
                                                            placeholder="Select a Document Title"
                                                            onChange={(
                                                                value: number
                                                            ) => {
                                                                handleDocumentTitleChange(
                                                                    value
                                                                )
                                                            }}
                                                            options={
                                                                documenttypeList
                                                            }
                                                            // defaultValue={22}
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option?.label
                                                                    ?.toLowerCase()
                                                                    .includes(
                                                                        input.toLowerCase()
                                                                    ) ?? false
                                                            }
                                                            showSearch
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <div
                                                        className={
                                                            commontStyles.document
                                                        }
                                                    >
                                                        <S3UploadMultiple
                                                            name={
                                                                'attachmentPath'
                                                            }
                                                            value={
                                                                uploadFileList
                                                            }
                                                            setFormData={
                                                                handleSetFormData
                                                            }
                                                            corporate={
                                                                'Partnership'
                                                            }
                                                            corporateid={
                                                                partnershipId
                                                            }
                                                            documenttype={
                                                                'General'
                                                            }
                                                            documentbaseId={
                                                                selectedDocumentType
                                                            }
                                                            memberpersonId={0}
                                                            selectedDocumentType={
                                                                selectedDocumentType
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {ActivePremission(
                                                '3000',
                                                PermissionType.SAVE
                                            ) ? (
                                                <Row gutter={16}>
                                                    <Col offset={20} span={2}>
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                handeleCompanDocumentSave
                                                            }
                                                        >
                                                            <SaveOutlined />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>

                        <Collapse.Panel header="Partner's Document" key="2">
                            <Row gutter={[24, 16]}>
                                <Col span={12}>
                                    <Table
                                        columns={officerColums}
                                        dataSource={dataSource}
                                        rowKey={(record) =>
                                            record.documentOwner
                                        }
                                        pagination={false}
                                        expandable={{
                                            expandedRowRender: expandableData,
                                            rowExpandable: () => true,
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <OfficerDcumentForm
                                        OfficerData={partnershipTaps}
                                        partnershipId={partnershipId}
                                        fetchData={fetchData}
                                    />
                                </Col>
                            </Row>
                        </Collapse.Panel>
                        {pensionDocuments.length > 0 && (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>Pension Document</span>
                                    </div>
                                }
                                key={'4'}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                pensionDocuments !== undefined
                                                    ? pensionDocuments.map(
                                                          (doc) => ({
                                                              ...doc,
                                                              attachmentPath:
                                                                  doc.attachmentPath ??
                                                                  '', // Add a default value or handle accordingly
                                                          })
                                                      )
                                                    : []
                                            }
                                            columns={Servicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            partnershipId={partnershipId}
                                            companyAttachmentId={0}
                                            serviceName={'Pension'}
                                            documentBaseId={0}
                                            documentType={''}
                                            pensionData={pensionDocuments}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        )}
                        {uniqueServiceDocuments?.map((document) => (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>{document?.title}</span>
                                    </div>
                                }
                                key={document?.companyAttachmentId ?? '1'}
                            >
                                <Card className={commontStyles.cardhight}>
                                    <Row gutter={[24, 16]}>
                                        <Col span={12}>
                                            <Table
                                                dataSource={
                                                    document != null
                                                        ? [document]
                                                        : []
                                                }
                                                columns={Servicecolumns}
                                                pagination={false}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ServiceDocumentUpload
                                                partnershipId={partnershipId}
                                                companyAttachmentId={
                                                    document?.companyAttachmentId
                                                }
                                                serviceName={
                                                    document?.serviceName
                                                }
                                                documentBaseId={
                                                    document?.documentBaseId
                                                }
                                                documentType={document?.documentType.toString()}
                                                fetchData={fetchData}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Collapse.Panel>
                        ))}
                        {updatedServiceData?.map((document: any) => (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>
                                            {document?.serviceName} File
                                            Document
                                        </span>
                                    </div>
                                }
                                key={document?.serviceReferenceId}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                document != null
                                                    ? [document]
                                                    : []
                                            }
                                            columns={filterServicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            partnershipId={partnershipId}
                                            companyAttachmentId={0}
                                            serviceName={document?.serviceName}
                                            documentBaseId={
                                                document?.serviceName === 'VAT'
                                                    ? 8
                                                    : document?.serviceName ===
                                                        'PAYE'
                                                      ? 10
                                                      : document?.serviceName ===
                                                          'CT600'
                                                        ? 9
                                                        : document?.serviceName ===
                                                            'AA'
                                                          ? 16
                                                          : 0
                                            }
                                            documentType={'0'}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Col>
            </Row>
            <Modal
                title="Documents"
                onCancel={() => {
                    setViewModalVisible(false)
                }}
                open={viewModalVisible}
                footer={null}
                width={'50%'}
            >
                <DocumentViewModelForm
                    data={selectedDocument}
                    itshow={downloadShowId}
                />
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any): PartnershipDocumentView => {
    return {
        partnershipTaps: state.client.partnershipTaps,
        documenttypeList: state.common.documenttypeList,
    }
}

export default connect(mapStateToProps)(DocumentMain)
