import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { Form, Collapse, Button, Card, Tooltip } from 'antd'
import type {
    IndividualCreateFormDataInterface,
    HomeProps,
    HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Individual'
import { EditOutlined, EyeFilled, SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { GetCategoryDropDownList } from '../../../../../../Services/GroupAndPartners'
import { GetCountryList } from '../../../../../../Services/Common'
import commonStyles from '../../../../../../Utils/Common.less'
import InitialDetailsForm from './InitialDetailsForm'
import {
    getClientCompanyDetails,
    setBasic,
} from '../../../../../../Services/Individual'
import HomeAddressForm from './HomeAddressForm'
import PersonalDetailsForm from './PersonalDetailsForm'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import SoleTraderConfigurationLess from '../../../../../../Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less'
import { ErrorMessage } from '../../../../../../Utils/Notification'
import { formatdate } from '../../../../../../Utils/dateFormat'
const HomeList = (props: HomeProps): ReactElement => {
    const { individualTaps, individualId } = props
    const [form] = Form.useForm()
    const [showHomeForm] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const [openPanel, setActivePanel] = useState<string | string[]>(['1'])

    const [selectedCategoryId, setSelectedCategoryId] = useState<number>()
    const [selectedDateOfBirthFromChild, setSelectedDateOfBirthFromChild] =
        useState<string>('')
    const [selectedDueDateFromChild, setSelectedDueDateFromChild] =
        useState<string>('')
    const hasFetchedOnce = useRef(false)
    const handleDateOfBirthChangeFromChild = (dateString: string): void => {
        setSelectedDateOfBirthFromChild(dateString)
    }

    const handleDueDateChangeFromChild = (dateString: string): void => {
        setSelectedDueDateFromChild(dateString)
    }

    const togglePanel = (key: string | string[]): void => {
        if (typeof key === 'string') {
            setActivePanel([key])
        } else {
            setActivePanel(key)
        }
    }

    const handleCategorySelect = (id: number): void => {
        setSelectedCategoryId(id)
    }

    const handelCountychange = (value: string): void => {}
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch])

    const updatePage = (): void => {
        setEditMode(true)
        form.submit()
    }
    const viewPage = (): void => {
        setEditMode(false)
    }

    useEffect(() => {
        form.setFieldsValue({
            individualId:
                individualTaps?.individualInitialDetails?.individualId,
            clientNumber:
                individualTaps?.individualInitialDetails?.clientNumber ?? 0,
            clienttypeid:
                individualTaps?.individualInitialDetails?.clienttypeid ?? 3,
            title: individualTaps?.individualInitialDetails?.title,
            firstName: individualTaps?.individualInitialDetails?.firstName,
            middleName: individualTaps?.individualInitialDetails?.middleName,
            surName: individualTaps?.individualInitialDetails?.surName,
            typeOfSelfEmployment:
                individualTaps?.individualInitialDetails?.typeOfSelfEmployment,
            tradingName: individualTaps?.individualInitialDetails?.tradingName,
            individualUTR:
                individualTaps?.individualInitialDetails?.individualUTR,
            dateOfBirth: individualTaps?.individualPersonalDetails?.dateOfBirth,
            niNumber: individualTaps?.individualPersonalDetails?.niNumber,
            duedate: individualTaps?.individualPersonalDetails?.duedate,
            mobileNumber:
                individualTaps?.individualPersonalDetails?.mobileNumber,
            workNumber: individualTaps?.individualPersonalDetails?.workNumber,
            emailAddress:
                individualTaps?.individualPersonalDetails?.emailAddress,
            alternativeEmailAddress:
                individualTaps?.individualPersonalDetails
                    ?.alternativeEmailAddress,
            addressOne: individualTaps?.individualAddress?.addressOne,
            addressTwo: individualTaps?.individualAddress?.addressTwo,
            town: individualTaps?.individualAddress?.town,
            country: individualTaps?.individualAddress?.country,
            postCode: individualTaps?.individualAddress?.postCode,
            clientCategoryId:
                individualTaps?.individualInitialDetails?.clientCategoryId,

            homeNumber: individualTaps?.individualPersonalDetails?.homeNumber,
        })
    }, [individualTaps])

    const handleFormSubmit = async (): Promise<void> => {
        try {
            await form.validateFields()
            const values = form.getFieldsValue()
            console.log('values.addressTwo', values)
            const apiPayload = {
                ...values,
                individualId:
                    individualTaps?.individualInitialDetails?.individualId,
                clientNumber:
                    values.clientNumber ??
                    individualTaps?.individualAddress?.clientNumber,
                addressOne:
                    values.addressOne ??
                    individualTaps?.individualAddress?.addressOne,
                addressTwo:
                    values.addressTwo ??
                    individualTaps?.individualAddress?.addressTwo,
                town: values.town ?? individualTaps?.individualAddress?.town,
                country:
                    values.country ??
                    individualTaps?.individualAddress?.country,

                postCode:
                    values.postCode ??
                    individualTaps?.individualAddress?.postCode,
                clientCategoryId: selectedCategoryId ?? 1,
                alternativeEmailAddress:
                    values.alternativeEmailAddress ??
                    individualTaps?.individualPersonalDetails
                        ?.alternativeEmailAddress,

                emailAddress:
                    values.emailAddress ??
                    individualTaps?.individualPersonalDetails?.emailAddress,
                firstName:
                    values.firstName ??
                    individualTaps?.individualInitialDetails?.FirstName,

                individualUTR:
                    values.individualUTR ??
                    individualTaps?.individualInitialDetails?.IndividualUTR,
                middleName:
                    values.middleName ??
                    individualTaps?.individualInitialDetails?.MiddleName,
                mobileNumber:
                    values.mobileNumber ??
                    individualTaps?.individualPersonalDetails?.mobileNumber,
                niNumber:
                    values.niNumber ??
                    individualTaps?.individualPersonalDetails?.niNumber,
                dateOfBirth: selectedDateOfBirthFromChild,
                duedate: selectedDueDateFromChild,

                typeOfSelfEmployment:
                    values.typeOfSelfEmployment ??
                    individualTaps?.individualInitialDetails
                        ?.typeOfSelfEmployment,
                surName:
                    values.surName ??
                    individualTaps?.individualInitialDetails?.SurName,
                title:
                    values.title ??
                    individualTaps?.individualInitialDetails?.title,
                workNumber:
                    values.workNumber ??
                    individualTaps?.individualPersonalDetails?.workNumber,
                tradingName:
                    values.tradingName ??
                    individualTaps?.individualInitialDetails?.tradingName,
                homeNumber:
                    values.homeNumber ??
                    individualTaps?.individualPersonalDetails?.homeNumber,
                isActive: 'Active',
                isDelete: 'InActive',
            }

            void dataToSend(apiPayload)
        } catch (error) {
            ErrorMessage('Fill Mandatory Fields')
        }
    }

    const dataToSend = async (
        apiPayload: IndividualCreateFormDataInterface
    ): Promise<void> => {
        try {
            await setBasic(dispatch, apiPayload)
            await getClientCompanyDetails(dispatch, 3, individualId, 1)
            setEditMode(false)
        } catch (error) {
            console.error('Error in dataToSend:', error)
        }
    }

    const termSignedDate = individualTaps?.initialDetails?.tradecommencementdate

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const induvidualData = individualTaps?.individualInitialDetails
    const InitialDetails = (): React.ReactElement => {
        return editMode ? (
            <InitialDetailsForm
                individualTaps={individualTaps}
                onCategorySelect={handleCategorySelect}
            />
        ) : (
            <div className={commonStyles.corporateInform}>
                <div className={commonStyles.corporateBasicDetails}>
                    <p>Client Category:</p>
                    <p>Title:</p>
                    <p>First Name:</p>
                    <p>Middle Name:</p>
                    <p>Surname:</p>
                    <p>Type Of SelfEmployement:</p>
                    <p>TradingName:</p>
                    <p>Individual UTR:</p>
                </div>
                <div className={commonStyles.corporateBasicDetails}>
                    <p>: {induvidualData?.clientCategoryName}</p>
                    <p>: {induvidualData?.title}</p>
                    <p>: {induvidualData?.firstName}</p>
                    <p>: {induvidualData?.middleName}</p>
                    <p>: {induvidualData?.surName}</p>
                    <p>: {induvidualData?.typeOfSelfEmployment}</p>
                    <p>: {induvidualData?.tradingName}</p>
                    <p>: {induvidualData?.individualUTR}</p>
                </div>
            </div>
        )
    }
    const individualAddress = individualTaps?.individualAddress
    const HomeAddress = (): React.ReactElement => {
        return editMode ? (
            <>
                <HomeAddressForm onDateChange={handelCountychange} />
            </>
        ) : (
            <>
                <div className={commonStyles.corporateInform}>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>Address 1</p>
                        <p>Address 2</p>
                        <p>Town</p>
                        <p>Country</p>
                        <p>Post Code</p>
                    </div>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>: {individualAddress?.addressOne}</p>
                        <p>: {individualAddress?.addressTwo}</p>
                        <p>: {individualAddress?.town}</p>
                        <p>: {individualAddress?.country}</p>
                        <p>: {individualAddress?.postCode}</p>
                    </div>
                </div>
            </>
        )
    }
    const personalData = individualTaps?.individualPersonalDetails
    const PersonalDetails = (): React.ReactElement => {
        return editMode ? (
            <>
                <PersonalDetailsForm
                    onDateChange={handleDateOfBirthChangeFromChild}
                    onDueDateChange={handleDueDateChangeFromChild}
                />
            </>
        ) : (
            <>
                <div className={commonStyles.corporateInform}>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>Date Of Birth</p>
                        <p>NI Number</p>
                        <p>Due Date</p>
                        <p>Mobile Number</p>
                        <p>Home Number</p>
                        <p>Work Number</p>
                        <p>Email Address</p>
                        <p>Alternative Email</p>
                    </div>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>: {formatdate(personalData?.dateOfBirth)}</p>
                        <p>: {personalData?.niNumber}</p>
                        <p>: {formatdate(personalData?.dueDate)}</p>
                        <p>: {personalData?.mobileNumber}</p>
                        <p>: {personalData?.homeNumber}</p>
                        <p>: {personalData?.workNumber}</p>
                        <p>: {personalData?.emailAddress}</p>
                        <p>: {personalData?.alternativeEmailAddress}</p>
                    </div>
                </div>
            </>
        )
    }
    return (
        <Card className={commonStyles.card}>
            <Form form={form} requiredMark={customizeRequiredMark}>
                <div className={commonStyles.homeListBasic}>
                    <div>
                        <h3>Basic Information</h3>
                    </div>
                    <div>
                        {!showHomeForm &&
                        !editMode &&
                        ActivePremission('3000', PermissionType.UPDATE) ? (
                            <Tooltip title="Edit Basic Information">
                                <Button type="primary" onClick={updatePage}>
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        {editMode && (
                            <Tooltip title="View Basic Information">
                                <Button type="primary" onClick={viewPage}>
                                    <EyeFilled />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <div>
                    <h4>
                        Term signed is activated on {formatdate(termSignedDate)}{' '}
                        this company
                    </h4>
                </div>
                <div
                    className={commonStyles.flexDirectionRow}
                    style={{ width: '100%' }}
                >
                    <div
                        className={
                            SoleTraderConfigurationLess.configurationRowWithMargin
                        }
                    >
                        <Collapse
                            defaultActiveKey={1}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Initial Details',
                                    children: InitialDetails(),
                                },
                            ]}
                            activeKey={openPanel}
                            onChange={togglePanel}
                            accordion
                        />
                        <Collapse
                            bordered={true}
                            items={[
                                {
                                    key: '2',
                                    label: 'Home Address',
                                    children: HomeAddress(),
                                },
                            ]}
                            activeKey={openPanel}
                            onChange={togglePanel}
                            accordion
                        />
                    </div>
                    <div
                        className={SoleTraderConfigurationLess.configurationRow}
                    >
                        <Collapse
                            bordered={true}
                            items={[
                                {
                                    key: '3',
                                    label: 'Personal Details',
                                    children: PersonalDetails(),
                                },
                            ]}
                            activeKey={openPanel}
                            onChange={togglePanel}
                            accordion
                        />
                    </div>
                </div>
                {editMode && (
                    <div className={commonStyles.homeListBasic}>
                        <div></div>
                        {ActivePremission('3000', PermissionType.SAVE) ? (
                            <Tooltip title="Save Basic Information">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleFormSubmit()
                                            .then(() => {
                                                console.log(
                                                    'Form submitted successfully'
                                                )
                                            })
                                            .catch((error: any) => {
                                                console.error(
                                                    'Error submitting form:',
                                                    error
                                                )
                                            })
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </Form>
        </Card>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        individualTaps: state.client.individualTaps,
    }
}

export default connect(mapStateToProps)(HomeList)
