import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Col,
    DatePicker,
    type DatePickerProps,
    Form,
    Input,
    Row,
    Select,
    Space,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    GetsubTaskList,
    SavesubTask,
} from '../../../Services/Helpdesk/CreteTask'
import {
    type subtaskinterfaces,
    type subtaskinterface,
    type Task,
} from '../../../Types/HelpDesk/Cretetask'
import dayjs from 'dayjs'
import commontStyles from '../../../Utils/Common.less'
import { GetUserList } from '../../../Services/User'
import { GetPriorityList } from '../../../Services/Corporate'
import { type ListFrontEndInterface } from '../../../Types/CommonType'

const SubtaskModal = (
    props: subtaskinterfaces & { taskId: string; getTask: Task }
): React.ReactElement => {
    const dispatch = useDispatch()
    const { getTask, priorityDropDownList, userList } = props
    const [SubTaskId] = useState('0')
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetPriorityList(dispatch)
    }, [dispatch])

    useEffect(() => {
        form.setFieldValue('toDate', selectedDate)
    }, [selectedDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }

    useEffect(() => {
        void GetsubTaskList(dispatch, SubTaskId)
    }, [SubTaskId, dispatch])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    const saveSubTask = async (): Promise<void> => {
        const formData = form.getFieldsValue()
        const formatstartDate = dayjs(formData.toDate).format('DD/MM/YYYY')
        const taskDetails = getTask

        if (taskDetails !== undefined) {
            const datatosave = {
                subTaskId: '0',
                subTaskName: formData.subTaskName,
                toDate: formatstartDate,
                priorityId: formData.priorityId ?? 0,
                assignedTo: formData.assignedToName,
                description: formData.description ?? '',
                subTaskNo: formData.subTaskNo ?? '',
                taskId: taskDetails.taskId,
                taskNo: taskDetails.taskNo,
                sortOrder: taskDetails.sortOrder,
                assignedByName: taskDetails.assignedByName,
                fromDate: dayjs(taskDetails.fromDate).format('DD/MM/YYYY'),
                statusId: taskDetails.projectStatusId,
                notifyToStatusChange: taskDetails.notifyToStatusChange,
                projectTierInfo: taskDetails.projectTierInfo ?? '0',
                daysCount: taskDetails.daysCount,
            }

            try {
                await SavesubTask(dispatch, datatosave)
                form.resetFields()
                setSelectedDate('')
            } catch (error) {
                console.error('Failed to save subtask:', error)
            }
        }
    }

    const handleFinish = (): void => {
        void saveSubTask()
    }

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="subTaskId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Sub-Task Name"
                            name="subTaskName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Task Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Due Date"
                            name="toDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Due Date!',
                                },
                            ]}
                        >
                            <Space>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    className={commontStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Priority"
                            name="priorityId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Priority',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...priorityDropDownList?.map(
                                        (service: ListFrontEndInterface) => ({
                                            value: service.value,
                                            label: service.label,
                                        })
                                    ),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assignedToName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Assigned',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Tooltip title="Save SubTask">
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): subtaskinterface => {
    return {
        userList: state.user.userList,
        userimageList: state.helpdesk.userimageList,
        subtaskDetailsList: state.helpdesk.subtaskDetailsList,
        subtaskDetailsListCount: state.helpdesk.subtaskDetailsListCount,
        SubViewDetail: state.helpdesk.SubViewDetail,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(SubtaskModal)
