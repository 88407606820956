/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    Row,
    Col,
    List,
    Button,
    Avatar,
    Tag,
    Carousel,
    Form,
    Modal,
    Empty,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './index.less'
import type {
    ApprovalTimesheet,
    ApproveProps,
    CustomLeaveApproval,
    PermissionApproval,
} from '../../Types/Dashboard'
import { extractDate } from '../../Utils/formatDate'
import TextArea from 'antd/es/input/TextArea'
import { GetDashboard, SetApprovalStatus } from '../../Services/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import commonStyles from '../../Utils/Common.less'

const ToApprove = ({
    leaveApprovalList,
    permissionApprovalList,
    approvalTimesheetList,
}: ApproveProps): React.ReactElement => {
    const [leaveApprovalData, setLeaveApprovalData] = useState<
        CustomLeaveApproval[]
    >([])
    const [permissionData, setPermissionData] = useState<PermissionApproval[]>(
        []
    )
    const [timesheetData, setTimesheetData] = useState<ApprovalTimesheet[]>([])
    const [isOpenModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState('')
    const [approvalRequestId, setApprovalRequestId] = useState<number>(0)
    const [action, setAction] = useState<number>(0)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const leaveTypeColor = (leaveType: string): string => {
        switch (leaveType) {
            case 'Annual':
                return '#026AA2'
            case 'Casual':
                return '#027A48'
            case 'Medical':
                return '#C11574'
            default:
                return '#026AA2'
        }
    }

    const leaveTypeBackgroundColor = (leaveType: string): string => {
        switch (leaveType) {
            case 'Annual':
                return '#F0F9FF'
            case 'Casual':
                return '#ECFDF3'
            case 'Medical':
                return '#FDF2FA'
            default:
                return '#F0F9FF'
        }
    }

    useEffect(() => {
        const listData =
            leaveApprovalList?.length > 0
                ? leaveApprovalList?.map((value: any) => {
                      return {
                          ...value,
                          leaveTypeColor: leaveTypeColor(value.leaveType),
                          leaveTypeBackgroundColor: leaveTypeBackgroundColor(
                              value.leaveType
                          ),
                      }
                  })
                : []

        setLeaveApprovalData(listData)

        setPermissionData(permissionApprovalList)
        setTimesheetData(approvalTimesheetList)
    }, [leaveApprovalList, permissionApprovalList, approvalTimesheetList])

    const carouselRef = useRef<any>(null)

    const [selectHeader, setSelectHeader] = useState<number>(0)

    const goTo = (slide: number, event?: React.MouseEvent): void => {
        // Prevent default scroll behavior if event exists
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (event) {
            event.preventDefault()
        }
        setSelectHeader(slide)
        carouselRef?.current?.goTo(slide, false)
    }

    const openModal = (
        isApprove: boolean,
        id: number,
        action: number
    ): void => {
        form.resetFields()
        setOpenModal(true)
        setApprovalRequestId(id)
        setAction(action)
        setTitle(isApprove ? 'Approved' : 'Rejected')
    }

    const handleCancel = (): void => {
        setOpenModal(false)
        setTitle('')
    }
    const token = localStorage.getItem('jwtToken')
    const user = localStorage.getItem('user')
    const clientCategoryId =
        user !== null ? JSON.parse(user)?.clientCategoryId : ''
    const getFlag = useSelector((state: any) => state.dashboardFlag.flag)

    const onFinish = (): void => {
        if (action === 1) {
            if (approvalRequestId !== undefined && approvalRequestId > 0) {
                const isApprove: number = title === 'Approved' ? 1 : 2
                void SetApprovalStatus(
                    dispatch,
                    action,
                    approvalRequestId,
                    isApprove,
                    form.getFieldValue('approvalComment')
                )
            }
        }

        if (action === 2) {
            if (approvalRequestId !== undefined && approvalRequestId > 0) {
                const isApprove: number = title === 'Approved' ? 1 : 2
                void SetApprovalStatus(
                    dispatch,
                    action,
                    approvalRequestId,
                    isApprove,
                    form.getFieldValue('approvalComment')
                )
            }
        }

        if (action === 3) {
            if (approvalRequestId !== undefined && approvalRequestId > 0) {
                const isApprove: number = title === 'Approved' ? 1 : 2
                void SetApprovalStatus(
                    dispatch,
                    action,
                    approvalRequestId,
                    isApprove,
                    form.getFieldValue('approvalComment')
                )
            }
        }
        if (
            token !== null &&
            token !== undefined &&
            clientCategoryId !== undefined
        ) {
            setTimeout(() => {
                void GetDashboard(
                    dispatch,
                    clientCategoryId,
                    getFlag !== undefined && getFlag !== null
                        ? Number(getFlag)
                        : 1
                )
            }, 3000)
        }
        handleCancel()
    }

    const handleNavigation = (selectHeader: number): void => {
        switch (selectHeader) {
            case 0:
                navigate('/leave/approve')
                break
            case 1:
                navigate('/permission/approve')
                break
            case 2:
                navigate('/time-sheet-approve')
                break
            default:
                break
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const convertDuration = (minutes: any): string => {
        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60
        return `${hours} hours ${remainingMinutes} minutes`
    }

    return (
        <div className={`${styles.marginRight10} ${styles.marginLeft10}`}>
            <Card className={`${styles.card}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <div className={styles.toApprove__tittle}>
                                    To Approve
                                </div>
                            </div>
                        </Col>
                        <Col span={12} className={styles.toApprove__viewall}>
                            <div
                                onClick={() => {
                                    handleNavigation(selectHeader)
                                }}
                            >
                                View All
                            </div>
                        </Col>
                    </Row>

                    <Row className={styles.marginTop10}>
                        <Col
                            span={6}
                            onClick={() => {
                                goTo(0)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className={
                                    selectHeader === 0
                                        ? styles.toFollow__tab__active
                                        : styles.toFollow__tab__inactive
                                }
                            >
                                Leave
                            </div>
                        </Col>
                        <Col
                            span={6}
                            onClick={() => {
                                goTo(1)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className={
                                    selectHeader === 1
                                        ? styles.toFollow__tab__active
                                        : styles.toFollow__tab__inactive
                                }
                            >
                                Permission
                            </div>
                        </Col>
                        <Col
                            span={6}
                            onClick={() => {
                                goTo(2)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className={
                                    selectHeader === 2
                                        ? styles.toFollow__tab__active
                                        : styles.toFollow__tab__inactive
                                }
                            >
                                Timesheet
                            </div>
                        </Col>
                    </Row>

                    <Carousel dots={false} ref={carouselRef}>
                        {leaveApprovalData.length > 0 ? (
                            <Row className={styles.marginTop10}>
                                <Col span={24}>
                                    <List>
                                        <VirtualList
                                            data={leaveApprovalData}
                                            height={480}
                                            itemHeight={47}
                                            itemKey="email"
                                        >
                                            {(item: any, index: number) => (
                                                <List.Item
                                                    key={index}
                                                    className={
                                                        styles.toApprove__list__item
                                                    }
                                                >
                                                    <Row>
                                                        <Col span={3}>
                                                            <Avatar
                                                                src={
                                                                    item.coveringPerson
                                                                }
                                                                size={40}
                                                            />
                                                        </Col>
                                                        <Col
                                                            span={20}
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                        >
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__tittle
                                                                }
                                                            >
                                                                {item.appliedBy}
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                {
                                                                    item.description
                                                                }
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                Applied :{' '}
                                                                {item.appliedOn}
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                {extractDate(
                                                                    item.leaveFrom
                                                                ) ===
                                                                extractDate(
                                                                    item.leaveTo
                                                                ) ? (
                                                                    <span>
                                                                        Leave :{' '}
                                                                        {extractDate(
                                                                            item.leaveFrom
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        Leave :{' '}
                                                                        {extractDate(
                                                                            item.leaveFrom
                                                                        )}
                                                                        {' - '}{' '}
                                                                        {extractDate(
                                                                            item.leaveTo
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </Row>
                                                            <Row>
                                                                <Col
                                                                    span={5}
                                                                    className={
                                                                        styles.marginTop5
                                                                    }
                                                                >
                                                                    <Tag
                                                                        className={
                                                                            styles.toApprove__list__item__leavetype
                                                                        }
                                                                        style={{
                                                                            backgroundColor: `${item.leaveTypeBackgroundColor}`,
                                                                            color: `${item.leaveTypeColor}`,
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.leaveType
                                                                        }
                                                                    </Tag>
                                                                </Col>
                                                                <Col
                                                                    span={7}
                                                                    className={
                                                                        styles.marginTop5
                                                                    }
                                                                >
                                                                    <Tag
                                                                        className={
                                                                            styles.toApprove__list__item__leaveday
                                                                        }
                                                                    >
                                                                        {
                                                                            item.leaveMode
                                                                        }
                                                                    </Tag>
                                                                </Col>
                                                                <Col
                                                                    span={12}
                                                                    className={
                                                                        styles.toApprove__list__item__button__main
                                                                    }
                                                                >
                                                                    <Button
                                                                        className={
                                                                            styles.toApprove__list__item__button__approve
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            openModal(
                                                                                true,
                                                                                item.approvalRequestId,
                                                                                1
                                                                            )
                                                                        }}
                                                                    >
                                                                        Approve
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        className={
                                                                            styles.toApprove__list__item__button__cancel
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            openModal(
                                                                                false,
                                                                                item.approvalRequestId,
                                                                                1
                                                                            )
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        </VirtualList>
                                    </List>
                                </Col>
                            </Row>
                        ) : (
                            <Empty
                                style={{ padding: '40px 0px' }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>No Leave Approval Found</span>
                                }
                            />
                        )}

                        {permissionData.length > 0 ? (
                            <Row className={styles.marginTop10}>
                                <Col span={24}>
                                    <List>
                                        <VirtualList
                                            data={permissionData}
                                            height={480}
                                            itemHeight={47}
                                            itemKey="email"
                                        >
                                            {(item: any, index: number) => (
                                                <List.Item
                                                    key={index}
                                                    className={
                                                        styles.toApprove__list__item
                                                    }
                                                >
                                                    <Row>
                                                        <Col span={21}>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__tittle
                                                                }
                                                            >
                                                                {item.appliedBy}
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                {
                                                                    item.description
                                                                }
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                Applied :{' '}
                                                                {item.appliedOn}
                                                            </Row>

                                                            <Row>
                                                                <Col
                                                                    span={12}
                                                                    className={
                                                                        styles.toApprove__list__item__button__main
                                                                    }
                                                                >
                                                                    <Button
                                                                        className={
                                                                            styles.toApprove__list__item__button__approve
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            openModal(
                                                                                true,
                                                                                item.approvalRequestId,
                                                                                2
                                                                            )
                                                                        }}
                                                                    >
                                                                        Approve
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        className={
                                                                            styles.toApprove__list__item__button__cancel
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            openModal(
                                                                                false,
                                                                                item.approvalRequestId,
                                                                                2
                                                                            )
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        </VirtualList>
                                    </List>
                                </Col>
                            </Row>
                        ) : (
                            <Empty
                                style={{ padding: '40px 0px' }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span>No Permission Approval Found</span>
                                }
                            />
                        )}

                        {timesheetData.length > 0 ? (
                            <Row className={styles.marginTop10}>
                                <Col span={24}>
                                    <List>
                                        <VirtualList
                                            data={timesheetData}
                                            height={480}
                                            itemHeight={47}
                                            itemKey="email"
                                        >
                                            {(item: any, index: number) => (
                                                <List.Item
                                                    key={index}
                                                    className={
                                                        styles.toApprove__list__item
                                                    }
                                                >
                                                    <Row>
                                                        <Col span={24}>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__tittle
                                                                }
                                                            >
                                                                {item.userName}
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                {
                                                                    item.description
                                                                }
                                                            </Row>
                                                            <Row
                                                                className={
                                                                    styles.toApprove__list__item__reason
                                                                }
                                                            >
                                                                Date :{' '}
                                                                {item.dateTime}
                                                            </Row>

                                                            <Row>
                                                                <Col
                                                                    span={18}
                                                                    className={
                                                                        styles.toApprove__list__item__reason
                                                                    }
                                                                    // style={{
                                                                    //     marginRight:
                                                                    //         '5px',
                                                                    // }}
                                                                >
                                                                    Duration :{' '}
                                                                    {convertDuration(
                                                                        item.duration
                                                                    )}{' '}
                                                                </Col>

                                                                <Col
                                                                    span={5}
                                                                    className={
                                                                        styles.toApprove__list__item__button__main
                                                                    }
                                                                >
                                                                    <Button
                                                                        className={
                                                                            styles.toApprove__list__item__button__approve
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            openModal(
                                                                                true,
                                                                                item.approvalRequestId,
                                                                                3
                                                                            )
                                                                        }}
                                                                    >
                                                                        Approve
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        className={
                                                                            styles.toApprove__list__item__button__cancel
                                                                        }
                                                                        size="small"
                                                                        onClick={() => {
                                                                            openModal(
                                                                                false,
                                                                                item.approvalRequestId,
                                                                                3
                                                                            )
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        </VirtualList>
                                    </List>
                                </Col>
                            </Row>
                        ) : (
                            <Empty
                                style={{ padding: '40px 0px' }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<span>No tasks for approval</span>}
                            />
                        )}
                    </Carousel>
                </div>
            </Card>

            <Modal
                width={600}
                title={title}
                open={isOpenModal}
                okText="Save"
                onCancel={handleCancel}
                onOk={form.submit}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Comments"
                                name="approvalComment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter the comment',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default ToApprove
