import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Select, Row, Col, Button } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { type TableParamsInterface } from '../../../Types/CommonType'
import { commonTableParams } from '../../../Utils/CommontTable'
import {
    type UserRightsFormDataInterface,
    type UserRightsListInterface,
    type UserRightsPayloadInterface,
} from '../../../Types/UserRights'
import { GetUserList } from '../../../Services/User'
import { GetUserRights, SaveUserRights } from '../../../Services/UserRights'
import { SaveOutlined } from '@ant-design/icons'

const { Option } = Select

const UserRights = (props: UserRightsListInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { userRightsList, userRightsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [userID, setSelectedUserId] = useState<number | undefined>(undefined)
    const [userRightsListCountState, setUserRightsListCount] =
        useState<number>(0)
    const [rightPermissionValues, setRightPermissionValues] = useState<
        Map<number, string>
    >(new Map())

    useEffect(() => {
        GetUserList(dispatch)
    }, [])

    useEffect(() => {
        console.log('userID', userID)
        if (userID !== undefined && userID !== null) {
            GetUserRights(dispatch, userID)
                .then((data: UserRightsPayloadInterface) => {
                    setUserRightsListCount(data.noOfRecords)
                    const rightPermissionValues = new Map()
                    data.data.forEach((permission) => {
                        rightPermissionValues.set(
                            permission.rightId,
                            permission.permissionValue
                        )
                    })
                    setRightPermissionValues(rightPermissionValues)
                })
                .catch(() => {})
        }
    }, [userID])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: userRightsListCount,
            },
        })
    }, [userRightsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const loadUserRights = (userId: number): void => {
        if (userId !== undefined && userId !== null) {
            GetUserRights(dispatch, userId)
                .then((data: UserRightsPayloadInterface) => {
                    setUserRightsListCount(data.noOfRecords)
                })
                .catch(() => {})
        }
    }

    const handleUserSelect = (userId: number): void => {
        setSelectedUserId(userId)
        loadUserRights(userId)
    }

    const handleRightChange = (
        rightId: number,
        selectedOptions: string[]
    ): void => {
        const permissionValue = selectedOptions.join(',')
        setRightPermissionValues(
            (prevRightPermissionValues) =>
                new Map(prevRightPermissionValues.set(rightId, permissionValue))
        )
    }

    const handleSaveRights = (): void => {
        if (userID !== null && userID !== undefined) {
            const updatedUserRightsList = userRightsList?.map((item) => {
                const permissionValue =
                    rightPermissionValues.get(item.rightId) ?? ''

                return {
                    rightCode: item.rightCode,
                    rightId: item.rightId,
                    rightDescription: item.rightDescription,
                    permissionValue,
                    rightPermissionList: item.rightPermissionList,
                }
            })

            const Data = {
                rightsViewList: [],
                statusCode: 200,
                noOfRecords: userRightsListCountState,
                data: updatedUserRightsList,
                error: null,
            }

            SaveUserRights(dispatch, Data, userID)
                .then(() => {})
                .catch(() => {})
        }
    }

    const flterOptionsUserRigist = (
        item: UserRightsFormDataInterface
    ): string[] | undefined => {
        const arr = item.rightPermissionList
            .filter((y) => y.rightPermissionDescription !== '')
            .map((z) => z.rightPermissionId)

        const orArr = rightPermissionValues.get(item.rightId)?.split(',')
        const result = orArr?.filter((i) => arr.includes(Number(i)))

        return result
    }

    return (
        <Card title="User Rights" className={commonStyles.card}>
            <div className={commonStyles.dataLabel}>User Name</div>
            <Select
                showSearch
                placeholder="Please select"
                options={[
                    { label: 'Please select', value: null },
                    ...props.userList,
                ]}
                allowClear
                onChange={handleUserSelect}
                filterOption={(input, option) =>
                    (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
                style={{ width: '478px' }}
            />
            {userID !== null && userID !== undefined && (
                <>
                    <hr className={commonStyles.hr} />
                    <div className={commonStyles.dataLabel}>User Rights</div>
                    <div className={commonStyles.dataContainer}>
                        {userRightsList?.map((item) => (
                            <div
                                key={item.rightId}
                                className={commonStyles.dataItem}
                            >
                                <div className={commonStyles.dataValue}>
                                    <hr className={commonStyles.hr} />
                                    {item.rightDescription}
                                    <Select
                                        mode="multiple"
                                        className={commonStyles.usredropdown}
                                        value={flterOptionsUserRigist(item)}
                                        onChange={(selectedOptions) => {
                                            handleRightChange(
                                                item.rightId,
                                                selectedOptions
                                            )
                                        }}
                                    >
                                        {item.rightPermissionList
                                            .filter(
                                                (permission: any) =>
                                                    permission.rightPermissionDescription.trim() !==
                                                    ''
                                            )
                                            ?.map((permission: any) => (
                                                <Option
                                                    key={String(
                                                        permission.rightPermissionId
                                                    )}
                                                    value={String(
                                                        permission.rightPermissionId
                                                    )}
                                                >
                                                    <span
                                                        className={
                                                            commonStyles.rightPermissionDescription
                                                        }
                                                    >
                                                        {
                                                            permission.rightPermissionDescription
                                                        }
                                                    </span>
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={(): void => {
                                    handleSaveRights()
                                }}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Card>
    )
}

const mapStateToProps = (state: any): UserRightsListInterface => {
    return {
        userRightsList: state.userrights.userRightsList,
        userRightsListCount: state.userrights.userRightsListCount,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(UserRights)
