import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import PensionEdit from './PensionEdit'
import {
    type PensionData,
    type PensionViewPagePropData,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

export const PensionDefultData = {
    pensionId: 0,
    companyType: 4,
    referenceId: 0,
    letterCode: '',
    stagingDate: '01/01/1900',
    pensionProvider: '',
    hmrcDate: '01/01/1900',
    nestEmployerId: '',
    nestUserName: '',
    nestPassword: '',
    employerPW: '',
    delegateId: '',
    primaryDelegateUserId: '',
    primaryDelegatePassword: '',
    primaryDelegatePIN: '',
    status: '',
}

const PensionviewPage = (
    props: PensionViewPagePropData
): React.ReactElement => {
    const { PensionServiceData, partnershipId, datareFetch } = props
    const [isEditMode, setIsEditMode] = useState(false)
    const [editData, setEditData] = useState<PensionData>(PensionDefultData)

    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(PensionServiceData ?? PensionDefultData)
    }

    const handleChildFormSave = (): void => {
        datareFetch()
        setIsEditMode(false)
    }

    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${day}/${month}/${year}`
            return formattedDate
        }
    }
    return (
        <>
            <>
                <Row gutter={16} justify="end">
                    <Col>
                        <Button type="primary" onClick={handleEditClick}>
                            {isEditMode ? (
                                <EyeOutlined />
                            ) : (
                                ActivePremission(
                                    '3000',
                                    PermissionType.UPDATE
                                ) && <EditOutlined />
                            )}
                        </Button>
                    </Col>
                </Row>
                {isEditMode ? (
                    <div>
                        <PensionEdit
                            pensioneditData={editData}
                            partnershipId={partnershipId}
                            handleChildFormSave={handleChildFormSave}
                        />
                    </div>
                ) : (
                    <Row gutter={[24, 8]}>
                        <Col span={6}>
                            <p>Letter Code</p>
                            <p>Staging Date</p>
                            <p>Pension Providers</p>
                            <p>HMRC Date </p>
                            <p>NEST Employer ID</p>
                            <p>Employer NEST Login</p>
                        </Col>
                        <Col span={6}>
                            <p>: {PensionServiceData?.letterCode}</p>
                            <p>
                                : {formatdate(PensionServiceData?.stagingDate)}
                            </p>
                            <p>: {PensionServiceData?.pensionProvider}</p>
                            <p>: {formatdate(PensionServiceData?.hmrcDate)}</p>
                            <p>: {PensionServiceData?.nestEmployerId}</p>
                            <p>: {PensionServiceData?.nestUserName}</p>
                        </Col>
                        <Col span={6}>
                            <p>Employer NEST Password</p>
                            <p>NEST Employer PW</p>
                            <p>NEST Delegate ID</p>
                            <p>Primary Delegate ID</p>
                            <p>Primary Delegate Password</p>
                            <p>Primary Delegate Pin</p>
                        </Col>
                        <Col span={6}>
                            <p>: {PensionServiceData?.employerPW}</p>
                            <p>: {PensionServiceData?.nestPassword}</p>
                            <p>: {PensionServiceData?.delegateId}</p>
                            <p>: {PensionServiceData?.primaryDelegateUserId}</p>
                            <p>
                                : {PensionServiceData?.primaryDelegatePassword}
                            </p>
                            <p>: {PensionServiceData?.primaryDelegatePIN}</p>
                        </Col>
                    </Row>
                )}
            </>
        </>
    )
}

export default PensionviewPage
