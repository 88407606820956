import React, { useState } from 'react'
import { Tabs } from 'antd'
import TaskList from './ListSubtask'
import TaskForm from './CreteSubtask'
import { connect } from 'react-redux'
import {
    type Task,
    type subtaskinterface,
} from '../../../Types/HelpDesk/Cretetask'

const { TabPane } = Tabs

const MainPage = (
    props: subtaskinterface & { taskId: string; getTask: Task }
): React.ReactElement => {
    const { taskId, getTask } = props

    const [activeKey, setActiveKey] = useState('1')

    const handleTabChange = (key: string): void => {
        setActiveKey(key)
    }

    return (
        <Tabs
            activeKey={activeKey}
            onChange={handleTabChange}
            defaultActiveKey="1"
        >
            <TabPane tab="SubTask List" key="1">
                <TaskList
                    taskId={taskId}
                    getTask={getTask}
                    activeKey={activeKey}
                />
            </TabPane>
            <TabPane tab="SubTask Form" key="2">
                <TaskForm taskId={taskId} getTask={getTask} />
            </TabPane>
        </Tabs>
    )
}

const mapStateToProps = (state: any): subtaskinterface => {
    return {
        userList: state.user.userList,
        SubViewDetail: state.helpdesk.SubViewDetail,
        subtaskDetailsList: state.helpdesk.subtaskDetailsList,
        subtaskDetailsListCount: state.helpdesk.subtaskDetailsListCount,
        userimageList: state.helpdesk.userimageList,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(MainPage)
