import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
} from 'antd'
import commontStyles from './document.less'
import { SaveOutlined } from '@ant-design/icons'
import { type PartnershipDocumentSave } from '../../../../../../Types/Client/RegisteredClients/Partnership'
import dayjs from 'dayjs'
import { connect, useDispatch } from 'react-redux'

import moment from 'moment'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import { SaveOfficerDocument } from '../../../../../../Services/Corporate'

const OfficerDcumentForm = (props: {
    OfficerData: any
    partnershipId: number
    fetchData: () => void
}): React.ReactElement => {
    const { partnershipId, OfficerData, fetchData } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDatePE, setSelectedDatePE] = useState<string>('')
    const [selectedDateDL, setSelectedDateDL] = useState<string>('')
    const [selectedDatePR, setSelectedDatePR] = useState<string>('')
    const [selectedMemberType, setSelectedMemberType] = useState<string>('')
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(24)

    const [showDatePicker, setShowDatePicker] = useState(true)
    const [selectedReferenceId, setSelectedReferenceId] = useState<number>(0)
    const officerDocumentHeaderList = OfficerData?.officerDocumentHeaderList

    const onChangePE = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDatePE(isoDate)
        }
    }

    const onChangeDL = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDateDL(isoDate)
        }
    }

    const onChangePR = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDatePR(isoDate)
        }
    }

    const handleDoumentTypeChange = (value: number): void => {
        setSelectedDocumentType(value)
        setShowDatePicker(value === 24)
        form.resetFields(['attachmentPath'])
    }

    const handleMemberTypeChange = (value: string): void => {
        setSelectedMemberType(value)
        setSelectedReferenceId(0)
    }
    useEffect(() => {
        form.setFieldValue('ppExpiryDate', selectedDatePE)
        form.setFieldValue('dlExpiryDate', selectedDateDL)
        form.setFieldValue('prExpiryDate', selectedDatePR)
    }, [selectedDatePE, selectedDateDL, selectedDatePR])

    const findCompanyAttachmentId = (
        officerDocumentHeaderList: any,
        selectedReferenceId: number,
        selectedDocumentType: number
    ): number => {
        for (const header of officerDocumentHeaderList) {
            if (header.officerPersonOrCorporateId === selectedReferenceId) {
                const matchingDocument = header.officerDocumentList.find(
                    (document: any) =>
                        document.documentBaseId === selectedDocumentType
                )

                return matchingDocument != null
                    ? matchingDocument.companyAttachmentId
                    : null
            }
        }

        return 0
    }

    const handelSave = (): void => {
        void handelDataSave()
    }
    const handelDataSave = async (): Promise<void> => {
        const formdata = form.getFieldsValue()
        // const membertype: string = selectedMemberType === 'PERSON'

        const documentname =
            selectedDocumentType === 4 ? 'ID Proof' : 'Address Proof'

        const companyAttachmentId = findCompanyAttachmentId(
            officerDocumentHeaderList,
            selectedReferenceId,
            selectedDocumentType
        )
        const ppExpiryDate =
            formdata.ppExpiryDate != null
                ? moment(formdata.ppExpiryDate).format('DD/MM/YYYY')
                : ''

        const dlExpiryDate =
            formdata.dlExpiryDate != null
                ? moment(formdata.dlExpiryDate).format('DD/MM/YYYY')
                : ''

        const prExpiryDate =
            formdata.prExpiryDate != null
                ? moment(formdata.prExpiryDate).format('DD/MM/YYYY')
                : ''
        const convertedString = uploadFileList
            .map((item) => item.slice())
            .join(', ')
        const savedata = {
            companyAttachmentId: companyAttachmentId ?? 0,
            companyTypeId: 4,
            referenceId: selectedReferenceId,
            referenceId2: 0,
            documentBaseId: selectedDocumentType ?? 0,
            documentBaseName: documentname,
            attachmentPath: convertedString ?? '',
            corporateId: 0,
            type: selectedMemberType,
            ppExpiryDate: ppExpiryDate !== 'Invalid date' ? ppExpiryDate : '',
            dlExpiryDate: dlExpiryDate !== 'Invalid date' ? dlExpiryDate : '',
            prExpiryDate: prExpiryDate !== 'Invalid date' ? prExpiryDate : '',
        }
        await SaveOfficerDocument(dispatch, savedata).then((result) => {
            if (result !== null && result !== undefined) {
                fetchData()

                form.resetFields(['attachmentPath'])
            }
        })
    }

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }
    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<PartnershipDocumentSave> label="Member Partner">
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleMemberTypeChange}
                                value={selectedMemberType}
                            ></Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={commontStyles.CorporteDocument}>
                    <Col span={24}>
                        <Form.Item<PartnershipDocumentSave>
                            label="Document Type"
                            name="documentBaseId"
                            className={`${
                                selectedDocumentType === 25
                                    ? commontStyles.CorporteDocumentGeenreal
                                    : ''
                            }`}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleDoumentTypeChange}
                                value={selectedDocumentType}
                                defaultValue={24}
                            >
                                <Select.Option value={25}>
                                    Address Proof
                                </Select.Option>
                                <Select.Option value={24}>
                                    ID Proof
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {showDatePicker && (
                    <>
                        <Row
                            gutter={16}
                            className={commontStyles.CorporteDocument}
                        >
                            <Col span={24}>
                                <Form.Item<PartnershipDocumentSave>
                                    label="Passport Expiry Date"
                                    name="ppExpiryDate"
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            format={'DD/MM/YYYY'}
                                            onChange={(date, dateString) => {
                                                onChangePE(date, dateString)
                                            }}
                                            value={
                                                selectedDatePE.length > 0
                                                    ? dayjs(selectedDatePE)
                                                    : undefined
                                            }
                                            className={commontStyles.dateWidth}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            gutter={16}
                            className={commontStyles.CorporteDocument}
                        >
                            <Col span={24}>
                                <Form.Item<PartnershipDocumentSave>
                                    label="Driving Licence Expiry Date"
                                    name="dlExpiryDate"
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            format={'DD/MM/YYYY'}
                                            onChange={(date, dateString) => {
                                                onChangeDL(date, dateString)
                                            }}
                                            value={
                                                selectedDateDL.length > 0
                                                    ? dayjs(selectedDateDL)
                                                    : undefined
                                            }
                                            className={commontStyles.dateWidth}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            gutter={16}
                            className={commontStyles.CorporteDocument}
                        >
                            <Col span={24}>
                                <Form.Item<PartnershipDocumentSave>
                                    label="PR Expiry Date"
                                    name="prExpiryDate"
                                    className={
                                        commontStyles.CorporteDocumentGeenreal
                                    }
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={(date, dateString) => {
                                                onChangePR(date, dateString)
                                            }}
                                            className={commontStyles.dateWidth}
                                            value={
                                                selectedDatePR.length > 0
                                                    ? dayjs(selectedDatePR)
                                                    : null
                                            }
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                <Row gutter={16} className={commontStyles.document}>
                    <Col span={24}>
                        <S3UploadMultiple
                            name={'attachmentPath'}
                            value={uploadFileList}
                            setFormData={handleSetFormData}
                            corporate={'Partnership'}
                            corporateid={partnershipId}
                            documenttype={
                                selectedMemberType === 'PERSON'
                                    ? 'Person'
                                    : 'Corporate'
                            }
                            documentbaseId={selectedDocumentType}
                            memberpersonId={selectedReferenceId}
                        />
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) && (
                    <Row
                        gutter={16}
                        className={commontStyles.CorporteDocument}
                        style={{ marginTop: 50 }}
                    >
                        <Col offset={20} span={2}>
                            <Button type="primary" onClick={handelSave}>
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        addressproofList: state.common.addressproofList,
    }
}

export default connect(mapStateToProps)(OfficerDcumentForm)
