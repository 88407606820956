import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Flex, Modal, Row, Timeline, Tooltip } from 'antd'
import buttonstyle from './taps.less'
import {
    PhoneOutlined,
    MessageOutlined,
    MailOutlined,
    FilePdfOutlined,
} from '@ant-design/icons'
import ClientSms from './ClientComModels/smsModel'
import ClientPhoneCall from './ClientComModels/phonecallModel'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { GetCilentCommLogList } from '../../../Services/GlobelSearch'
import {
    type ClientDashboardClientCommLogStateIntrface,
    type CDClientCommLogProps,
    type ClientCommunicationData,
} from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import PreviewEmailModel from './previewEmailModel'

const ClientComList = (props: CDClientCommLogProps): React.ReactElement => {
    const { CDClientCommLogList, businessTypeId, referenceId } = props
    const navigate = useNavigate()
    const [mergedData, setMergedData] = useState<ClientCommunicationData[]>([])
    const [SMSModalStatus, setSMSModalStatus] = useState(false)
    const [Phonecallmodel, setPhonecallmodel] = useState(false)
    const [activeButton, setActiveButton] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [PreviewMailmodel, setPreviewMailmodel] = useState(false)
    const [clientEmailID, setClientEmail] = useState(0)
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        setCurrentPage(1)
        setItemsPerPage(10)
        setMergedData([])
    }, [activeButton])

    const getTableData = useCallback(() => {
        GetCilentCommLogList(
            dispatch,
            activeButton,
            businessTypeId,
            referenceId,
            '=NA=',
            currentPage,
            itemsPerPage
        )
    }, [activeButton, businessTypeId, referenceId, currentPage, itemsPerPage])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    useEffect(() => {
        const newData = [...CDClientCommLogList, ...mergedData]
        setMergedData(newData)
    }, [CDClientCommLogList])

    const handlePageChange = (): void => {
        window.scrollTo(0, 0)
        setCurrentPage(currentPage + 1)
        setItemsPerPage(itemsPerPage + 10)
    }

    const openSMSModel = (): void => {
        setSMSModalStatus(!SMSModalStatus)
    }
    const closeSMSModel = (): void => {
        setSMSModalStatus(!SMSModalStatus)
    }

    const openPhoneModel = (): void => {
        setPhonecallmodel(!Phonecallmodel)
    }

    const changePhonecallModalStatus = (): void => {
        setPhonecallmodel(!Phonecallmodel)
    }

    const changePreviewMailModalStatus = (): void => {
        setPreviewMailmodel(!PreviewMailmodel)
    }
    const handelPreviewModelOpen = (value: number): void => {
        setClientEmail(value)
        setPreviewMailmodel(!PreviewMailmodel)
    }
    const onEmailClieck = (): void => {
        navigate(`/overview/send-email`, {
            state: { businessTypeId, referenceId },
        })
    }

    const handleButtonClick = (btnNum: number): void => {
        setActiveButton(btnNum)
    }

    const OvalImage = ({
        src,
        width,
        height,
    }: {
        src: string
        width: number
        height: number
    }): JSX.Element => {
        return (
            <div
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: '50%',
                    overflow: 'hidden',
                }}
            >
                <img src={src} alt="Oval" className={buttonstyle.comLogimage} />
            </div>
        )
    }

    return (
        <>
            <div className={buttonstyle.comLogimage}>
                <Row gutter={15} justify="start">
                    <Flex justify="space-between" gap={5}>
                        <Col>
                            <Button
                                type="primary"
                                size="large"
                                className={buttonstyle.historyButton}
                            >
                                <span
                                    className={buttonstyle.buttontext}
                                    onClick={() => {
                                        handleButtonClick(1)
                                    }}
                                >
                                    Email
                                </span>
                                <span onClick={onEmailClieck}>
                                    <MailOutlined /> Send Email
                                </span>
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="large"
                                className={buttonstyle.historyButton}
                            >
                                <span
                                    className={buttonstyle.buttontext}
                                    onClick={() => {
                                        handleButtonClick(2)
                                    }}
                                >
                                    SMS
                                </span>
                                <span onClick={openSMSModel}>
                                    <MessageOutlined /> Send SMS
                                </span>
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="large"
                                className={buttonstyle.historyButton}
                            >
                                <span
                                    className={buttonstyle.buttontext}
                                    onClick={() => {
                                        handleButtonClick(3)
                                    }}
                                >
                                    Phone{' '}
                                </span>
                                <span onClick={openPhoneModel}>
                                    <PhoneOutlined /> Save Call
                                </span>
                            </Button>
                        </Col>
                    </Flex>
                </Row>
                {activeButton === 1 && (
                    <div style={{ marginRight: '50px', left: 0 }}>
                        <Timeline mode={'left'}>
                            {mergedData.map((email, index) => (
                                <Timeline.Item
                                    label={
                                        <div
                                            className={buttonstyle.setDateStyle}
                                        >
                                            {email.sentDateSort}
                                        </div>
                                    }
                                    key={index}
                                    dot={
                                        <Tooltip title={email.sentBy}>
                                            <OvalImage
                                                src={`${email.sentByUrl}`}
                                                width={35}
                                                height={35}
                                            />
                                        </Tooltip>
                                    }
                                    style={{ marginBottom: '10px' }}
                                >
                                    <div className={buttonstyle.dotdivStyle}>
                                        <strong> {email.subject}</strong>
                                        <FilePdfOutlined
                                            onClick={() => {
                                                handelPreviewModelOpen(
                                                    email.clientEmailId
                                                )
                                            }}
                                        />
                                        <br />
                                        <strong>To :</strong> {email.toAddress}
                                    </div>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        <div className={buttonstyle.buttonDiv}>
                            <Button
                                size="large"
                                onClick={() => {
                                    handlePageChange()
                                }}
                            >
                                Learn More
                            </Button>
                        </div>
                    </div>
                )}
                {activeButton === 2 && (
                    <div>
                        <Timeline mode={'left'}>
                            {mergedData.map((sms, index) => (
                                <Timeline.Item
                                    label={
                                        <div
                                            className={buttonstyle.setDateStyle}
                                        >
                                            {sms.sentDateSort}
                                        </div>
                                    }
                                    key={index}
                                    dot={
                                        <div
                                            className={buttonstyle.dotdivStyle}
                                        >
                                            <Tooltip title={sms.sentBy}>
                                                <OvalImage
                                                    src={`${sms.sentByUrl}`}
                                                    width={35}
                                                    height={35}
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                    style={{ marginBottom: '10px' }}
                                >
                                    <div className={buttonstyle.dotdivStyle}>
                                        <strong> {sms.smsContent}</strong>
                                    </div>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        <div className={buttonstyle.buttonDiv}>
                            <Button
                                size="large"
                                onClick={() => {
                                    handlePageChange()
                                }}
                            >
                                Learn More
                            </Button>
                        </div>
                    </div>
                )}
                {activeButton === 3 && (
                    <div>
                        <Timeline mode={'left'}>
                            {mergedData.map((phone, index) => (
                                <Timeline.Item
                                    label={
                                        <div
                                            className={buttonstyle.setDateStyle}
                                        >
                                            {phone.sentDateSort}
                                        </div>
                                    }
                                    key={index}
                                    dot={
                                        <div>
                                            <Tooltip title={phone.sentBy}>
                                                <OvalImage
                                                    src={`${phone.sentByUrl}`}
                                                    width={35}
                                                    height={35}
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                    style={{ marginBottom: '10px' }}
                                >
                                    <div className={buttonstyle.dotdivStyle}>
                                        <strong> {phone.smsContent}</strong>
                                    </div>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        <div className={buttonstyle.buttonDiv}>
                            <Button
                                size="large"
                                onClick={() => {
                                    handlePageChange()
                                }}
                            >
                                Learn More
                            </Button>
                        </div>
                    </div>
                )}
                <Modal
                    footer={null}
                    onCancel={closeSMSModel}
                    width={'25%'}
                    title={'SMS'}
                    open={SMSModalStatus}
                >
                    <ClientSms
                        changeModalStatus={closeSMSModel}
                        businessTypeId={businessTypeId}
                        referenceId={referenceId}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changePhonecallModalStatus}
                    width={'25%'}
                    title={'Phone Calls'}
                    open={Phonecallmodel}
                >
                    <ClientPhoneCall
                        changeModalStatus={changePhonecallModalStatus}
                        businessTypeId={businessTypeId}
                        referenceId={referenceId}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changePreviewMailModalStatus}
                    width={'50%'}
                    title={'Preview Mail'}
                    open={PreviewMailmodel}
                >
                    <PreviewEmailModel ClientEmailId={clientEmailID} />
                </Modal>
            </div>
        </>
    )
}
const mapStateToProps = (
    state: any
): ClientDashboardClientCommLogStateIntrface => {
    return {
        CDClientCommLogList: state.golobelSearch.CDClientCommLogList,
        CDClientCommLogListCount: state.golobelSearch.CDClientCommLogListCount,
    }
}

export default connect(mapStateToProps)(ClientComList)
