import {
    Col,
    Row,
    Input,
    Form,
    Card,
    Collapse,
    Select,
    Button,
    Switch,
    DatePicker,
    Space,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import soleTraderNewStyles from '../../../../Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import type {
    SoleTraderNewStateInterface,
    SoleTraderCreateFormDataInterface,
} from '../../../../Types/Client/RegisteredClients/SoleTrader'
import { GetCountryList } from '../../../../Services/Common'
import { saveSoleTrader } from '../../../../Services/SoleTrader'
import { SaveOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const SoleTraderNew = (
    props: SoleTraderNewStateInterface
): React.ReactElement => {
    const { categoryDropDownList, countryList } = props
    const location = useLocation()
    const onbordingId = location.state?.onbordingId
    const [form] = Form.useForm()
    const [collapseOne, SetCollapseOne] = useState<string[] | string>(['1'])
    const [collapseTwo, SetCollapseTwo] = useState<string[] | string>([])
    const navigate = useNavigate()
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch, Form])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const saveCallBack = (data: SoleTraderCreateFormDataInterface): void => {
        localStorage.setItem('solderTapId', '1')
        navigate(
            onbordingId !== undefined && onbordingId === 0
                ? '/clients/registered-clients/soletrader-view'
                : '/clients/onboarding-clients/soletrader-view',
            {
                state: { SoleTraderData: data },
            }
        )
    }

    const onSaveSoleTrader = (
        data: SoleTraderCreateFormDataInterface
    ): void => {
        if (collapseOne.length === 1) {
            SetCollapseOne(['1', '2'])
            SetCollapseTwo(['1', '2', '3'])
        } else {
            void saveSoleTrader(dispatch, data, saveCallBack)
        }
    }

    const collapseOneOnChange = (data: string | string[]): void => {
        SetCollapseOne(data)
    }

    const collapseTwoOnChange = (data: string | string[]): void => {
        SetCollapseTwo(data)
    }

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDateAp)
    }, [selectedDateAp])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const initialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Client Category"
                    name="clientCategoryId"
                    initialValue={1}
                >
                    <Select
                        placeholder="Please select"
                        options={[...categoryDropDownList]}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                        allowClear
                        showSearch
                        defaultValue={1}
                    />
                </Form.Item>
                <Form.Item label="Title" name="tittle">
                    <Select
                        allowClear
                        placeholder="Please select title"
                        onChange={() => {}}
                        defaultValue={'UNKNOWN'}
                        filterOption={(input, option) =>
                            typeof option?.children === 'string' && // Ensure option.children is a string
                            (option.children as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        showSearch
                    >
                        <Select.Option value="UNKNOWN">Unknown</Select.Option>
                        <Select.Option value="MR.">MR.</Select.Option>
                        <Select.Option value="MISS.">MISS.</Select.Option>
                        <Select.Option value="MRS.">MRS.</Select.Option>
                        <Select.Option value="MS.">MS.</Select.Option>
                        <Select.Option value="DR.">DR.</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter First Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Middle Name" name="middleName">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surename"
                    name="surName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Surename',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Nature Of Business" name="natureOfBusiness">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Business Trading Name"
                    name="businessTradingName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Business Trading Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Individual UTR" name="individualUTR">
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const personalDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Date Of Birth" name="dateOfBirth">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateAppoint(date, dateString)
                            }}
                            value={
                                selectedDateAp.length > 0
                                    ? dayjs(selectedDateAp)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            disabledDate={(current) => {
                                // Disable dates before today
                                return (
                                    current !== null &&
                                    current > dayjs().startOf('day')
                                )
                            }}
                        />
                    </Space>
                </Form.Item>
                <Form.Item label="NI Number" name="niNumber">
                    <Input />
                </Form.Item>
                <Form.Item label="Nationality" name="nationality">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Mobile Number',
                        },
                        {
                            pattern: /^\d{10,15}$/,
                            message: 'Mobile Number must be 10-15 digits!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Home Number" name="homeNumber">
                    <Input />
                </Form.Item>
                <Form.Item label="Work Number" name="workNumber">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="emailAddress"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Email Address',
                        },
                        {
                            pattern:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Please enter a valid Email Address!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Alternative Email Address"
                    name="alternativeEmailAddress"
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const homeAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Address 1"
                    name="homeAddressOne"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="homeAddressTwo">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="homeTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="homeCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="homePostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const onChangeBAHome = (checked: boolean): void => {
        let busAddress1 = ''
        let busAddress2 = ''
        let busTown = ''
        let busCountry = ''
        let busPostCode = ''
        if (checked) {
            busAddress1 = form.getFieldValue('homeAddressOne')
            busAddress2 = form.getFieldValue('homeAddressTwo')
            busTown = form.getFieldValue('homeTown')
            busCountry = form.getFieldValue('homeCountry')
            busPostCode = form.getFieldValue('homePostCode')
        }
        form.setFieldValue('busAddress1', busAddress1)
        form.setFieldValue('busAddress2', busAddress2)
        form.setFieldValue('busTown', busTown)
        form.setFieldValue('busCountry', busCountry)
        form.setFieldValue('busPostCode', busPostCode)
    }

    const businessAddress = (): React.ReactElement => {
        return (
            <div>
                <div className={commonStyles.marginBottom10}>
                    <Switch size="small" onChange={onChangeBAHome} />
                    &nbsp;
                    <label className={commonStyles.colorGray}>
                        Same as Home
                    </label>
                </div>
                <Form.Item
                    label="Address 1"
                    name="busAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="busAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="busTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="busCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="busPostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const onChangeCAHome = (checked: boolean): void => {
        let corrAddress1 = ''
        let corrAddress2 = ''
        let corrTown = ''
        let corrCountry = ''
        let corrPostCode = ''
        if (checked) {
            corrAddress1 = form.getFieldValue('homeAddressOne')
            corrAddress2 = form.getFieldValue('homeAddressTwo')
            corrTown = form.getFieldValue('homeTown')
            corrCountry = form.getFieldValue('homeCountry')
            corrPostCode = form.getFieldValue('homePostCode')
        }
        form.setFieldValue('corrAddress1', corrAddress1)
        form.setFieldValue('corrAddress2', corrAddress2)
        form.setFieldValue('corrTown', corrTown)
        form.setFieldValue('corrCountry', corrCountry)
        form.setFieldValue('corrPostCode', corrPostCode)
    }

    const onChangeCABusiness = (checked: boolean): void => {
        let corrAddress1 = ''
        let corrAddress2 = ''
        let corrTown = ''
        let corrCountry = ''
        let corrPostCode = ''
        if (checked) {
            corrAddress1 = form.getFieldValue('busAddress1')
            corrAddress2 = form.getFieldValue('busAddress2')
            corrTown = form.getFieldValue('busTown')
            corrCountry = form.getFieldValue('busCountry')
            corrPostCode = form.getFieldValue('busPostCode')
        }
        form.setFieldValue('corrAddress1', corrAddress1)
        form.setFieldValue('corrAddress2', corrAddress2)
        form.setFieldValue('corrTown', corrTown)
        form.setFieldValue('corrCountry', corrCountry)
        form.setFieldValue('corrPostCode', corrPostCode)
    }

    const correspondAddress = (): React.ReactElement => {
        return (
            <div>
                <div className={soleTraderNewStyles.cpAddressMain}>
                    <div className={commonStyles.margintRight100}>
                        <Switch size="small" onChange={onChangeCAHome} />
                        &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Home
                        </label>
                    </div>
                    <div>
                        <Switch size="small" onChange={onChangeCABusiness} />
                        &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Business
                        </label>
                    </div>
                </div>
                <Form.Item
                    label="Address 1"
                    name="corrAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="corrAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="corrTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="corrCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="corrPostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    return (
        <>
            <div>
                <h2>Sole Trader Registration</h2>
            </div>
            <Card className={commonStyles.card}>
                <Form
                    name="complex-form"
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                    onFinish={onSaveSoleTrader}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Type">
                                <Input disabled value={'Sole Trader'} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Client No" name="clientNumber">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Name">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Business Trading Name"
                                name="businessTradingName"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <h3>Basic Information</h3>
                    </Row>
                    <div className={commonStyles.flexDirectionRow}>
                        <div className={soleTraderNewStyles.collapseFirst}>
                            <Collapse
                                activeKey={collapseOne}
                                onChange={collapseOneOnChange}
                                bordered={true}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Initial Details',
                                        children: initialDetails(),
                                    },
                                    {
                                        key: '2',
                                        label: 'Personal Details',
                                        children: personalDetails(),
                                    },
                                ]}
                            />
                        </div>
                        <div className={soleTraderNewStyles.collapseSecond}>
                            <Collapse
                                activeKey={collapseTwo}
                                onChange={collapseTwoOnChange}
                                bordered={true}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Home Address',
                                        children: homeAddress(),
                                    },
                                    {
                                        key: '2',
                                        label: 'Business Address',
                                        children: businessAddress(),
                                    },
                                    {
                                        key: '3',
                                        label: 'Correspondance Address',
                                        children: correspondAddress(),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    &nbsp;
                    <Row gutter={16}>
                        <Col offset={21} span={1}>
                            <Tooltip title=" Save Basic Information">
                                <Button type="primary" htmlType="submit">
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): SoleTraderNewStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(SoleTraderNew)
