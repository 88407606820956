import React, { type ReactElement } from 'react'
import { Form, Input } from 'antd'
import { connect } from 'react-redux'
import {
    type RegisterInterface,
    type HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'

const SicCode = (props: RegisterInterface): ReactElement => {
    return (
        <>
            <Form.Item label="SIC Code1">
                <Input disabled />
            </Form.Item>
            <Form.Item label="SIC Code2">
                <Input disabled />
            </Form.Item>
            <Form.Item label="SIC Code3">
                <Input disabled />
            </Form.Item>
            <Form.Item label="SIC Code14">
                <Input disabled />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(SicCode)
