import React, { useState } from 'react'
import { Col, Row, Form, Button, Tooltip } from 'antd'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { SaveOutlined } from '@ant-design/icons'
import {
    type ModelProps,
    type PinedMessage,
} from '../../../Types/ToDoViewAllList'
import { useDispatch } from 'react-redux'
import { SavePinnedChat } from '../../../Services/ToDoViewAllList'
interface ProcessRemarkProps extends ModelProps {
    auditdata?: PinedMessage
}
const ProcessRemarks = (props: ProcessRemarkProps): React.ReactElement => {
    const {
        auditdata,
        serviceProcessId,
        serviceId,
        referenceId,
        businessType,
        changeModalStatus,
    } = props
    const [form] = Form.useForm()
    const [emailText, setEmailText] = useState(auditdata?.description)
    const dispatch = useDispatch()

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const dataToSave = {
                msgReferenceId: serviceProcessId,
                businessTypeId: businessType ?? 0,
                referenceId: referenceId ?? 0,
                chatId: 0,
                serviceId: serviceId ?? 0,
                description: emailText ?? '',
            }

            await SavePinnedChat(dispatch, dataToSave)

            form.setFieldsValue({
                description: '',
            })

            changeModalStatus()
        } catch (error) {}
    }
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video', 'formula'],
            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'code-block',
        'list',
        'bullet',
        'script',
        'indent',
        'size',
        'color',
        'background',
        'align',
        'link',
        'image',
        'video',
        'formula',
    ]

    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
        >
            <div style={{ height: '350px' }}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Describe workflow process details here. It may useful tips for working user.">
                            <ReactQuill
                                theme="snow"
                                value={emailText}
                                onChange={(content) => {
                                    setEmailText(content)
                                }}
                                modules={modules}
                                formats={formats}
                                style={{ height: '230px' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Row>
                <Col offset={22} span={2}>
                    <Form.Item>
                        <Tooltip title="Save Details">
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default ProcessRemarks
