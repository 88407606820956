import React, { useEffect, useRef, useState } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
    GetPersonalCalender,
    GetTeamCalender,
} from '../../../Services/HR/LeavePermission'
import { connect, useDispatch } from 'react-redux'
import {
    type CalendarComponentStateType,
    type CalendarComponentPropsType,
} from '../../../Types/Hr/LeavePermission'
import CustomToolbar from '../../HelpDesk/ManageDiary/dairyHederRender'

const localizer = momentLocalizer(moment)
moment.locale('en-gb')
interface Event {
    title: string
    start: Date
    end: Date
    description: string
    eventId: number
}
const CalendarComponent = (
    props: CalendarComponentPropsType
): React.ReactElement => {
    const { TeamCalenderList, PersonalCalenderList, setActive } = props
    const dispatch = useDispatch()
    const [events, setEvents] = useState<Event[]>([])
    const hasFetchedOnce = useRef(false)

    console.log('TeamCalenderList', TeamCalenderList)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            GetTeamCalender(dispatch)
            GetPersonalCalender(dispatch)
        }
    }, [dispatch])

    useEffect(() => {
        const mergedEvents = setActive ? TeamCalenderList : PersonalCalenderList

        const formattedEvents: Event[] = mergedEvents.map((event, index) => ({
            title: event.title,
            start: moment(event.start, 'DD MMM YYYY h:mmA').toDate(), // Updated format
            end: moment(event.end, 'DD MMM YYYY h:mmA').toDate(), // Updated format
            description: event.description ?? '', // Add fallback for description
            eventId: index,
        }))

        setEvents(formattedEvents)
    }, [TeamCalenderList, PersonalCalenderList, setActive])

    const MyEvent = ({ event }: { event: any }): JSX.Element => {
        return (
            <div>
                <b>{event.title}</b>

                {/* <i>{event.description}</i> */}
            </div>
        )
    }

    return (
        <div style={{ height: '80vh' }}>
            <BigCalendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                defaultView="month"
                selectable
                onSelectEvent={(event) => {
                    console.log('Selected Event:', event)
                }}
                components={{
                    event: MyEvent, // Use the custom event component
                    toolbar: CustomToolbar, // Apply custom toolbar
                }}
            />
        </div>
    )
}

const mapStateToProps = (state: any): CalendarComponentStateType => {
    return {
        TeamCalenderList: state.hr.TeamCalenderList,
        PersonalCalenderList: state.hr.PersonalCalenderList,
    }
}

export default connect(mapStateToProps)(CalendarComponent)
