import React, { useEffect, useRef, useState } from 'react'
import {
    type setTasks,
    type TaskFormDataStateInterface,
} from '../../../Types/HelpDesk/Cretetask'
import { connect, useDispatch } from 'react-redux'
import {
    GetTaskData,
    GetsatusList,
    getProjectlist,
    getusersdroplist,
} from '../../../Services/Helpdesk/CreteTask'
import { Card, Col, Flex, Modal, Row, Select, Spin, Tabs } from 'antd'
import SearchInput from '../../../Components/SearchInput'
import AddNewButton from '../../../Components/AddNewButton'
import { GetUserList } from '../../../Services/User'
import CreateTaskForm from './CreateTaskForm'
import ClientCreationPage from './CreateClient'
import TabPane from 'antd/es/tabs/TabPane'
import ToDoTasks, { TaskdefaultData } from './toDoTasks'
import InprogressTasks from './inprogressTasks'
import CompletedTask from './completedTask'
import { GetDYNAMICClientDetails } from '../../../Services/Common'

const LandingView = (props: TaskFormDataStateInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { userList, projectDropDownList, dynamicClientCategoryList } = props
    const user = localStorage.getItem('user')
    const userId = user !== null ? JSON.parse(user)?.userId : ''
    const [activeTab, setActiveTab] = useState('1')
    const [searchString, setSearchString] = useState('=NA=')
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Edit Task Details')
    const [editData, setEditData] = useState<setTasks>(TaskdefaultData)
    const [businesstypeid, setSelectedServiceId] = useState<number | undefined>(
        0
    )
    const [miisedfieldid, setmiisefieldid] = useState<number>(userId)
    const [taskId] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedclientDynCategoryId, setSelectedDepartment] =
        useState<number>(0)
    const [selectedProjectID, setSelectedProjectID] = useState<number>(0)
    const [isLoading] = useState<boolean>(false)
    const [selectedTaskId, setSelectedTaskIds] = useState<string>('')
    const [selectedTaskId2, setSelectedTaskIds2] = useState<string>('')
    const [activeItDepartment, setActiveItDepartment] = useState(false)
    const [activeItDepartmentView, setActiveItDepartmentView] = useState(false)
    const [createTaskUpdater, setCreateTaskUpdater] = useState(false)
    const [taskUpdate, setTaskUpdate] = useState(false)
    const hasFetchedOnce = useRef(false)
    const [restData, setResetData] = useState('false')
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (userId !== 1 && userId !== 3) {
            setSelectedDepartment(userId)
            if (
                selectedclientDynCategoryId === 17 ||
                selectedclientDynCategoryId === 253
            ) {
                setActiveItDepartmentView(true)
            } else {
                setActiveItDepartmentView(false)
            }
        }
    }, [userId])

    useEffect(() => {
        if (
            selectedclientDynCategoryId !== 0 &&
            selectedclientDynCategoryId !== undefined
        ) {
            getProjectlist(dispatch, selectedclientDynCategoryId)
        }
    }, [selectedclientDynCategoryId, dispatch])

    useEffect(() => {
        GetDYNAMICClientDetails(dispatch)
    }, [createTaskUpdater, dispatch])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetsatusList(dispatch)
        void getusersdroplist(dispatch)
    }, [])

    const handleTabChange = (key: string): void => {
        setActiveTab(key)
        if (key === '3') {
            setActiveItDepartment(true)
        }
        if (key === '1') {
            setActiveItDepartment(false)
        }
        setActiveItDepartment(false)
    }

    const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = event.target.value
        onChangeFilter(searchText)
    }
    const onChangeFilter = (data: string): void => {
        if (data === '') {
            setSearchString('=NA=')
        } else {
            setSearchString(data)
        }
    }

    const handleUserSelect = (value: number): void => {
        setSelectedServiceId(value)
    }

    const handleUserSelect1 = (value: number): void => {
        setmiisefieldid(value)
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(false)
        setTimeout(() => {
            setTaskUpdate(true)
        }, 3000)
    }

    const openEditModal = (id: string): void => {
        void openEditData(id)
    }
    const openEditData = async (id: string): Promise<void> => {
        try {
            setTaskUpdate(false)
            // const data = await GetTaskData(dispatch, id)
            let title = 'Create Task Details'
            if (id !== null && id !== undefined && id !== '') {
                title = 'Edit Task Details'
            }

            setEditModalTittle(title)
            setEditData(TaskdefaultData)
            setCreateEditModalStatus(true)
            if (id !== '') {
                const data = await GetTaskData(dispatch, id)
                let title = 'Create Task Details'
                if (id !== null && id !== undefined && id !== '') {
                    title = 'Edit Task Details'
                }

                setEditModalTittle(title)
                setEditData(data ?? TaskdefaultData)
                setCreateEditModalStatus(true)
            }
            // } else {
            //     setEditModalTittle('Edit Task Details')
            //     setEditData(TaskdefaultData)
            //     changeCreateEditModalStatus()
            // }
        } catch (err) {}
    }

    const handleCloseModal = (): void => {
        setModalVisible(false)
    }

    const onSaveTask = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        callBack()
        setCreateTaskUpdater(true)
        setTimeout(() => {
            setCreateTaskUpdater(false)
        }, 2000)
    }

    const handleDepartmentChange = (value: number): void => {
        if (value !== null) {
            setSelectedDepartment(value)
            setSelectedProjectID(0)
            if (value === 17 || value === 253) {
                setActiveItDepartmentView(true)
            } else {
                setActiveItDepartmentView(false)
            }
        }
    }

    const handleProjectChange = (value: number): void => {
        if (value !== null) {
            setSelectedProjectID(value)
        }
    }

    const handleSetSelectedTaskIdsTod = (id: string): void => {
        setSelectedTaskIds(id)
    }

    const handleSetSelectedTaskIdsInpro = (id: string): void => {
        setSelectedTaskIds2(id)
    }

    return (
        <>
            <Card>
                <Row gutter={[16, 16]} align="middle">
                    <Col>
                        <SearchInput
                            placeHolder={'Search by keywords'}
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                            width="228"
                        />
                    </Col>
                    <Col>
                        <Select
                            allowClear
                            style={{ width: '120px' }}
                            showSearch
                            placeholder="Please select"
                            onChange={handleUserSelect}
                            value={businesstypeid ?? 0}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { value: 0, label: 'Assign By' },
                                ...userList,
                            ]}
                        />
                    </Col>
                    <Col>
                        <Select
                            allowClear
                            style={{ width: '120px' }}
                            showSearch
                            placeholder="Please select"
                            onChange={handleUserSelect1}
                            value={miisedfieldid}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { value: 0, label: 'Assign To' },
                                ...userList,
                            ]}
                        />
                    </Col>
                    <Col>
                        <Select
                            showSearch
                            allowClear
                            placeholder={
                                <span style={{ fontWeight: 'bold' }}>
                                    Client Category
                                </span>
                            }
                            onChange={handleDepartmentChange}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { label: 'Please Select', value: 0 },
                                { label: 'Accounting', value: 1 },
                                ...dynamicClientCategoryList.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                })),
                            ]}
                            style={{ width: '200px' }}
                        />
                    </Col>
                    <Col>
                        <Select
                            showSearch
                            allowClear
                            placeholder={
                                <span style={{ fontWeight: 'bold' }}>
                                    Project
                                </span>
                            }
                            onChange={handleProjectChange}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { label: 'Please select', value: 0 },
                                ...projectDropDownList.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                })),
                            ]}
                            value={selectedProjectID}
                            style={{ width: '200px' }}
                        />
                    </Col>
                    <Col flex="auto">
                        <Row justify="end" gutter={[8, 0]}>
                            <Col>
                                <AddNewButton
                                    name="Create Task"
                                    clickAction={() => {
                                        openEditModal('')
                                        setResetData('true')
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    defaultActiveKey="1"
                >
                    <TabPane tab="My Task" key="1">
                        <Row>
                            <Col span={12}>
                                <ToDoTasks
                                    setSelectedTaskIds={
                                        handleSetSelectedTaskIdsTod
                                    }
                                    selectedProjectID={selectedProjectID}
                                    AssignBY={businesstypeid ?? 0}
                                    AssignTo={miisedfieldid ?? 0}
                                    activeItDepartment={activeItDepartment}
                                    activeStates={selectedTaskId2}
                                    activeTab={activeTab}
                                    searchString={searchString}
                                    createTaskModalStatus={taskUpdate}
                                    departmentId={
                                        selectedclientDynCategoryId ?? 0
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <InprogressTasks
                                    setSelectedTaskIds={
                                        handleSetSelectedTaskIdsInpro
                                    }
                                    selectedProjectID={selectedProjectID}
                                    AssignBY={businesstypeid ?? 0}
                                    AssignTo={miisedfieldid ?? 0}
                                    activeItDepartment={activeItDepartment}
                                    activeStates={selectedTaskId}
                                    activeTab={activeTab}
                                    searchString={searchString}
                                    createTaskModalStatus={taskUpdate}
                                    departmentId={
                                        selectedclientDynCategoryId ?? 0
                                    }
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    {activeItDepartmentView && (
                        <TabPane tab="QA" key="3">
                            <Row>
                                <Col span={12}>
                                    <ToDoTasks
                                        setSelectedTaskIds={
                                            handleSetSelectedTaskIdsTod
                                        }
                                        selectedProjectID={selectedProjectID}
                                        AssignBY={businesstypeid ?? 0}
                                        AssignTo={miisedfieldid ?? 0}
                                        activeItDepartment={activeItDepartment}
                                        activeStates={selectedTaskId2}
                                        activeTab={activeTab}
                                        searchString={searchString}
                                        createTaskModalStatus={taskUpdate}
                                        departmentId={
                                            selectedclientDynCategoryId ?? 0
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <InprogressTasks
                                        setSelectedTaskIds={
                                            handleSetSelectedTaskIdsInpro
                                        }
                                        selectedProjectID={selectedProjectID}
                                        AssignBY={businesstypeid ?? 0}
                                        AssignTo={miisedfieldid ?? 0}
                                        activeItDepartment={activeItDepartment}
                                        activeStates={selectedTaskId}
                                        activeTab={activeTab}
                                        searchString={searchString}
                                        createTaskModalStatus={taskUpdate}
                                        departmentId={
                                            selectedclientDynCategoryId ?? 0
                                        }
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    )}

                    <TabPane tab="Completed" key="2">
                        {isLoading && activeTab === '2' ? (
                            <Flex
                                align="center"
                                justify="center"
                                style={{ padding: '20px' }}
                            >
                                <Spin />
                            </Flex>
                        ) : (
                            <CompletedTask
                                setSelectedTaskIds={handleSetSelectedTaskIdsTod}
                                selectedProjectID={selectedProjectID}
                                AssignBY={businesstypeid ?? 0}
                                AssignTo={miisedfieldid ?? 0}
                                activeItDepartment={activeItDepartment}
                                activeStates={selectedTaskId2}
                                activeTab={activeTab}
                                searchString={searchString}
                                createTaskModalStatus={taskUpdate}
                                departmentId={selectedclientDynCategoryId ?? 0}
                            />
                        )}
                    </TabPane>
                </Tabs>
            </Card>

            <Modal
                title="Create Client"
                open={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={'40%'}
            >
                <ClientCreationPage />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={editModalTittle}
                open={createEditModalStatus}
                destroyOnClose={true}
            >
                <CreateTaskForm
                    onSave={onSaveTask}
                    editData={editData}
                    taskId={taskId}
                    setResetData={setResetData}
                    resetdata={restData}
                    projectStatusLable={'TO-DO'}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): TaskFormDataStateInterface => {
    return {
        userList: state.user.userList,
        taskDetailsList: state.helpdesk.taskDetailsList,
        projectDropDownList: state.helpdesk.projectDropDownList,
        allDepartmentList: state.helpdesk.allDepartmentList,
        taskDetailsListCount: state.helpdesk.taskDetailsListCount,
        userimageList: state.helpdesk.userimageList,
        userimageListCount: state.helpdesk.userimageListCount,
        satusList: state.helpdesk.satusList,
        ProjectManagmentStausList: state.initial.ProjectManagmentStausList,
        dynamicClientCategoryList: state.common.dynamicClientCategoryList,
    }
}

export default connect(mapStateToProps)(LandingView)
