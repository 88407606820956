import {
    Form,
    Input,
    Button,
    Switch,
    Col,
    Row,
    Space,
    DatePicker,
    Select,
    InputNumber,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCountryList } from '../../../../../../Services/Common'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import {
    type CtStateInterface,
    type CorporateOfficerView,
    type OfficerDetailsFormInterfacePerson,
    type TogilleAddress,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import moment from 'moment'
import {
    GetAddress,
    SaveOfficerPerson,
} from '../../../../../../Services/Corporate'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const OfficerEditForm = (
    props: OfficerDetailsFormInterfacePerson & {
        corporateId: number
        saveCallBack: CallableFunction
        iseditNumber: number
        companyNumber: string
        ReFetchData: () => void
    }
): React.ReactElement => {
    const {
        editData,
        countryList,
        corporateId,
        saveCallBack,
        iseditNumber,
        ReFetchData,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })
    const [addressDatas, setAddressData] = useState<TogilleAddress | null>(null)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldValue('referenceId', '0')
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCountryList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (countryList !== null && editData !== null) {
            const countryData = countryList?.find(
                (data: ListFrontEndInterface) => data.label === editData.country
            )
            const newData = {
                ...editData,
                country: countryData?.label ?? 'Please Select',
            }
            form.setFieldsValue(newData)
        }
    }, [editData, countryList])

    useEffect(() => {
        if (editData !== null) {
            const dateOfBirth = moment(editData.dateOfBirth, 'M/D/YYYY')
            const formattedDate = dateOfBirth.format('MM/DD/YYYY')
            setSelectedDate(formattedDate)

            const dateofAppointment = moment(
                editData?.dateofAppointment,
                'M/D/YYYY'
            )
            const formattedDateAp = dateofAppointment?.format('MM/DD/YYYY')
            setSelectedDateAp(formattedDateAp)
        }
    }, [editData])

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDate)
        form.setFieldValue('dateofAppointment', selectedDateAp)
    }, [selectedDate, selectedDateAp])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                corporateId,
                switchId,
                (addressData: TogilleAddress) => {
                    setAddressData(addressData)
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        if (addressDatas !== null && typeof addressDatas === 'object') {
            const newFieldValues = {
                addressLineOne: addressDatas?.addressLineOne,
                addressLineTwo: addressDatas?.addressLineTwo,
                town: addressDatas?.town,
                country: addressDatas?.country,
                postCode: addressDatas?.postCode,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])

    const onPresonEditData = (): void => {
        const formdata = form.getFieldsValue()
        void onSaveData(formdata)
    }

    const onSaveData = async (
        formdata: CorporateOfficerView
    ): Promise<void> => {
        try {
            await form.validateFields()
            const shareHolderTypeName = formdata.shareHolderTypeName
            let shareHolderTypeId

            if (shareHolderTypeName === 'ORDINARY') {
                shareHolderTypeId = 1
            } else {
                shareHolderTypeId = 2
            }
            const dataToSave = {
                memberpersonid: editData.referenceId,
                title: editData.titleOrCompanyName,
                firstname: editData.firstNameOrBRNo,
                middlename: editData.middleName,
                surname: editData.surName,
                dateofbirth: moment(formdata.dateOfBirth).format('DD/MM/YYYY'),
                address1: formdata.addressLineOne,
                address2: formdata.addressLineTwo,
                town: formdata.town,
                country: formdata.country,
                postcode: formdata.postCode,
                mobilenumber: formdata.mobileNo,
                homenumber: formdata.homeNo,
                worknumber: formdata.workNo,
                emailaddress: formdata.email,
                altemailaddress: formdata.alternativeEmail,
                ninumber: formdata.niNumber,
                nationality: formdata.nationality,
                occupation: formdata.occupation,
                shareholdingpercentage: formdata.shareHoldingPercentage ?? 0,
                noofshares: formdata.noOfShares ?? 0,
                shareholdertypeid: shareHolderTypeId ?? 0,
                dateofappointment:
                    moment(formdata.dateofAppointment).format('DD/MM/YYYY') ??
                    '',
                corporateid: corporateId,
                isNewShareHolder: iseditNumber,
                countryOfResidence: formdata.countryOfResidence ?? '',
            }
            // saveCallBack(dataToSave)
            await SaveOfficerPerson(dispatch, dataToSave)
                .then((result) => {
                    ReFetchData()
                    saveCallBack(editData)
                })
                .catch(() => {})
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={() => {}}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Office"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="1"
                                checked={switchStates[1]}
                                onChange={() => {
                                    handleSwitchChange(1)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Business"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={() => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Correspondence"
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={() => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Date of Birth"
                            name="dateOfBirth"
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate?.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please Input Valid Email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Address Line 1"
                            name="addressLineOne"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Alternative Email Address "
                            name="alternativeEmail"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please Input Alternative Email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Address line 2"
                            name="addressLineTwo"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="NI Number"
                            name="niNumber"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Town"
                            name="town"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Nationality"
                            name="nationality"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Country "
                            name="country"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                showSearch
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country?.label,
                                    label: country?.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Occupation"
                            name="occupation"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="PostCode"
                            name="postCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Input postCode!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Country of Residence"
                            name="countryOfResidence"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country?.label,
                                    label: country?.label,
                                }))}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                showSearch
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Mobile Number "
                            name="mobileNo"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {editData?.officerRole?.includes('SHAREHOLDER') && (
                        <Col span={12}>
                            <Form.Item<CorporateOfficerView>
                                label="Date Of Appointment "
                                name="dateofAppointment"
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedDateAp?.length > 0
                                                ? dayjs(selectedDateAp)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        className={commonStyles.dateWidth}
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Home Number "
                            name="homeNo"
                            rules={[
                                {
                                    pattern:
                                        /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                                    message: 'please enter valid mobile number',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {editData?.officerRole?.includes('SHAREHOLDER') && (
                        <Col span={12}>
                            <Form.Item<CorporateOfficerView>
                                label="Shareholding Percentage "
                                name="shareHoldingPercentage"
                            >
                                <InputNumber
                                    className={commonStyles.InputNumber}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Work Number"
                            name="workNo"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {editData?.officerRole?.includes('SHAREHOLDER') && (
                        <Col span={12}>
                            <Form.Item<CorporateOfficerView>
                                label="Number of Shares "
                                name="noOfShares"
                            >
                                <InputNumber
                                    className={commonStyles.InputNumber}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                {editData?.officerRole?.includes('SHAREHOLDER') && (
                    <Row
                        gutter={[16, 24]}
                        className={commonStyles.officerButtonMargin}
                    >
                        <Col span={12}>
                            <Form.Item<CorporateOfficerView>
                                label="Shareholder Type"
                                name="shareHolderTypeName"
                            >
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={() => {}}
                                >
                                    <Select.Option value="ORDINARY">
                                        Ordinary
                                    </Select.Option>
                                    <Select.Option value="PREFERENCE">
                                        Preference
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save Details">
                                <Button
                                    type="primary"
                                    onClick={onPresonEditData}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): CtStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(OfficerEditForm)
