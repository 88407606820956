import React, { useState, useEffect } from 'react'
import commontStyles from '../../../../../../Utils/Common.less'
import CorporateConfigurationLess from '../../../../../../Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less'
import { Collapse, Switch, Row, Col, Modal } from 'antd'
import FullWidthModal from '../../../../../../Components/FullWidthModal'
import GoCardlessForm from './GoCardless/GoCardlessForm'
import GoCardlessInviteClient from './GoCardless/GoCardlessInviteClient'
import {
    getClientPortalInvitation,
    GetDirectDebitCustomer,
} from '../../../../../../Services/Parnership'
import { useDispatch, connect } from 'react-redux'
import { type PartnershipConfigurationInterface } from '../../../../../../Types/Client/RegisteredClients/Partnership'

const PartnershipConfiguration = (
    props: PartnershipConfigurationInterface
): React.ReactElement => {
    const { registeredClientPartnershipConfiguration, partnershipId } = props
    const referenceId =
        registeredClientPartnershipConfiguration?.data?.referenceId
    const enableGoCardlessFormStatus =
        referenceId !== null && referenceId !== undefined
    const [enableCustomerPortal, SetEnableCustomerPortal] = useState(false)
    const [pendingCustomerPortalState, setPendingCustomerPortalState] =
        useState(false) // New state to track the pending state change
    const [enableGoCardlessForm, SetEnableGoCardlessForm] = useState(
        enableGoCardlessFormStatus
    )
    const [customerPortalModal, SetCustomerPortalModal] = useState(false)
    const [goCardlessModal, setGoCardLessModal] = useState(false)
    const dispatch = useDispatch()
    const businessTypeId = 4
    const [, setActiveInactiveStatusLabel] = useState('')

    useEffect(() => {
        GetDirectDebitCustomer(dispatch, 4, partnershipId)
    }, [])

    useEffect(() => {
        setActiveInactiveStatusLabel(
            enableCustomerPortal ? 'Active' : 'Inactive'
        )
        if (enableCustomerPortal) {
            void getClientPortalInvitation(
                dispatch,
                businessTypeId,
                partnershipId
            )
        }
    }, [enableCustomerPortal])

    const onChangeEnableCustomerPortal = (checked: boolean): void => {
        setPendingCustomerPortalState(checked)
        SetCustomerPortalModal(true)
    }

    const closeCustomerPortalModal = (): void => {
        SetCustomerPortalModal(false)
    }

    const acceptCustomerPortalModalEnable = (): void => {
        SetEnableCustomerPortal(pendingCustomerPortalState)

        SetCustomerPortalModal(false)
    }

    const onChangeGoCardlessForm = (): void => {
        SetEnableGoCardlessForm(!enableGoCardlessForm)
    }

    const cancelGoCardlessModal = (): void => {
        setGoCardLessModal(false)
    }

    const customerPortal = (): React.ReactElement => {
        return (
            <div>
                <Switch
                    onChange={onChangeEnableCustomerPortal} // Trigger modal on switch change
                    checked={enableCustomerPortal}
                />
                &nbsp; Enable Customer Portal
            </div>
        )
    }

    const goCardless = (): React.ReactElement => {
        return (
            <div className={commontStyles.flexDirectionColumn}>
                <Row>
                    <Col span={12}>
                        <Switch
                            onChange={onChangeGoCardlessForm}
                            checked={enableGoCardlessForm}
                        />
                        &nbsp; Enable GoCardless
                    </Col>
                    {(referenceId === null || referenceId === undefined) && (
                        <Col
                            span={12}
                            className={CorporateConfigurationLess.inviteClient}
                            onClick={() => {
                                setGoCardLessModal(true)
                            }}
                        >
                            Invite Client
                        </Col>
                    )}
                </Row>
                {enableGoCardlessForm && (
                    <GoCardlessForm
                        registeredClientPartnershipConfiguration={''}
                        partnershipId={partnershipId}
                    />
                )}
            </div>
        )
    }

    return (
        <div className={commontStyles.flexDirectionRow}>
            <div
                className={
                    CorporateConfigurationLess.configurationRowWithMargin
                }
            >
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'Company House',
                            children: '',
                        },
                    ]}
                />
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'Customer Portal',
                            children: customerPortal(),
                        },
                    ]}
                />
            </div>
            <div className={CorporateConfigurationLess.configurationRow}>
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'HMRC',
                            children: '',
                        },
                    ]}
                />
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'GoCardless',
                            children: goCardless(),
                        },
                    ]}
                />
            </div>

            <FullWidthModal
                modalStatus={customerPortalModal}
                closeModal={closeCustomerPortalModal}
                tittle={`You are about to ${pendingCustomerPortalState ? 'activate' : 'deactivate'} this client account. Click 'Yes' to continue.`} // Dynamic title based on action
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={acceptCustomerPortalModalEnable}
            />

            <Modal
                footer={null}
                onCancel={cancelGoCardlessModal}
                width={'30%'}
                title={'Gocardless Invite Client'}
                open={goCardlessModal}
            >
                <GoCardlessInviteClient
                    paymentOptionDropDownList={[]}
                    cancelGoCardlessModal={cancelGoCardlessModal}
                    partnershipId={partnershipId}
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        registeredClientPartnershipConfiguration:
            state.client.registeredClientPartnershipConfiguration,
    }
}

export default connect(mapStateToProps)(PartnershipConfiguration)
