import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type CorporatePayloadInteface } from '../Types/Client/RegisteredClients/Corporate'
import { type GetSprintListInterfaceType } from '../Types/Backlog/Backlog'

interface ClientStateInterface {
    sprintList: GetSprintListInterfaceType[]
    sprintListCount: number
    sprintTaskList: any[]
    sprintTaskCount: number
    sprintBackLogList: any[]
    sprintBackLogListCount: number
    TaskAttachmentList: any[]
    TaskAttachmentListCount: number
    userimageList: any[]
    hoursList: any[]
    follwersList: any[]
    follwersListCount: number
}

const ClientSlice = createSlice({
    name: 'backlog',
    initialState: {
        sprintList: [],
        sprintListCount: 0,
        sprintTaskList: [],
        sprintTaskCount: 0,
        sprintBackLogList: [],
        sprintBackLogListCount: 0,
        TaskAttachmentList: [],
        TaskAttachmentListCount: 0,
        userimageList: [],
        hoursList: [],
        follwersList: [],
        follwersListCount: 0,
    },
    reducers: {
        sprintList(state: ClientStateInterface, action: PayloadAction<any>) {
            state.sprintList = action?.payload?.data
            state.sprintListCount = action?.payload?.noOfRecords
        },
        sprintTaskList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.sprintTaskList = action?.payload?.data
            state.sprintTaskCount = action?.payload?.noOfRecords
        },
        sprintBackLogList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.sprintBackLogList = action?.payload?.data
            state.sprintBackLogListCount = action?.payload?.noOfRecords
        },
        TaskAttachmentList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.TaskAttachmentList = action?.payload?.data
            state.TaskAttachmentListCount = action?.payload?.noOfRecords
        },
        userimageList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.userimageList = action?.payload?.data
        },
        hoursList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.hoursList = action?.payload?.data
        },
        follwersList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.follwersList = action?.payload?.data
        },
    },
})

export const {
    sprintList,
    sprintTaskList,
    sprintBackLogList,
    TaskAttachmentList,
    userimageList,
    hoursList,
    follwersList,
} = ClientSlice.actions

export default ClientSlice.reducer
