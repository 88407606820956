import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Switch,
    Row,
    Col,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import Partnerless from '../Partnershipserviceless.less'
import { type PensionEditFormprops } from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import moment from 'moment'
import dayjs from 'dayjs'
import { PensionSaveData } from '../../../../../../Services/SoleTrader'
import { useDispatch } from 'react-redux'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const PensionEditForm = (props: PensionEditFormprops): React.ReactElement => {
    const { pensioneditData, soletrderId, handleChildFormSave } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { Option } = Select
    const [servicesEnabled, setServicesEnabled] = useState(false)
    const [StagingDate, setStagingDate] = useState<string | null>(null)
    const [HmrcDate, setHmrcDate] = useState<string | null>(null)

    useEffect(() => {
        if (pensioneditData !== null) {
            form.setFieldsValue(pensioneditData)
            setServicesEnabled(pensioneditData.status === 'Active')
        }
    }, [pensioneditData])

    const handleServiceSwitchChange = (checked: boolean): void => {
        setServicesEnabled(checked)
    }

    useEffect(() => {
        if (pensioneditData !== null) {
            setStagingDate(pensioneditData.stagingDate)

            // const formathmrcdate = moment(pensioneditData.hmrcDate).format(
            //     'MM/DD/YYYY'
            // )
            setHmrcDate(pensioneditData.hmrcDate)
        }
    }, [pensioneditData])

    useEffect(() => {
        form.setFieldValue('hmrcDate', HmrcDate)
        form.setFieldValue('stagingDate', StagingDate)
    }, [HmrcDate, StagingDate])

    const onChangeStartDate = (
        startingDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (startingDate != null) {
            const isoDate = startingDate.toISOString()
            setStagingDate(isoDate)
        }
    }

    const onChangeEF = (
        Hrmrcdate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (Hrmrcdate != null) {
            const isoDate = Hrmrcdate.toISOString()
            setHmrcDate(isoDate)
        }
    }

    const handelonSave = (): void => {
        const formData = form.getFieldsValue()
        const dataToSave = {
            pensionId: pensioneditData.pensionId,
            companyType: 2,
            referenceId: soletrderId,
            letterCode: formData.letterCode,
            stagingDate: moment(formData.stagingDate).format('DD/MM/YYYY'),
            pensionProvider: formData.pensionProvider,
            hmrcDate: moment(formData.hmrcDate).format('DD/MM/YYYY'),
            nestEmployerId: formData.nestEmployerId,
            nestUserName: formData.nestUserName,
            nestPassword: formData.nestPassword,
            employerPW: formData.employerPW,
            delegateId: formData.delegateId,
            primaryDelegateUserId: formData.primaryDelegateUserId,
            primaryDelegatePassword: formData.primaryDelegatePassword,
            primaryDelegatePIN: formData.primaryDelegatePIN,
            status: servicesEnabled ? 'Active' : 'Inactive',
        }
        void PensionSaveData(dispatch, dataToSave)
        handleChildFormSave()
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                form={form}
                name="ct-form"
                layout={'vertical'}
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item label="Letter Code" name="letterCode">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Employer NEST Password"
                            name="nestPassword"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Staging Date"
                            name="stagingDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Start Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    format={'DD/MM/YYYY'}
                                    className={commonStyles.dateWidth}
                                    onChange={(date, dateString) => {
                                        onChangeStartDate(date, dateString)
                                    }}
                                    value={
                                        StagingDate != null
                                            ? dayjs(StagingDate)
                                            : null
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="NEST Employer PW" name="employerPW">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Pension Providers"
                            name="pensionProvider"
                        >
                            <Select
                                defaultValue={'UNKNOWN'}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="UNKNOWN">UNKNOWN</Option>
                                <Option value="GENERAL">GENERAL</Option>
                                <Option value="AEGON">AEGON</Option>
                                <Option value="AME">AME</Option>
                                <Option value="AVIVA">AVIVA</Option>
                                <Option value="BEAUFORT CONSULTING">
                                    BEAUFORT CONSULTING
                                </Option>
                                <Option value="BLUESKY">BLUESKY</Option>
                                <Option value="CAREY PENSION">
                                    CAREY PENSION
                                </Option>
                                <Option value="CORPAD">CORPAD</Option>
                                <Option value="CREATIVE PENSION TRUST">
                                    CREATIVE PENSION TRUST
                                </Option>
                                <Option value="ETHIKA">ETHIKA</Option>
                                <Option value="FRIENDLY PENSION">
                                    FRIENDLY PENSION
                                </Option>
                                <Option value="FRIENDS LIFE">
                                    FRIENDS LIFE
                                </Option>
                                <Option value="GENLIFE">GENLIFE</Option>
                                <Option value="LEGAL AND GENERAL">
                                    LEGAL AND GENERAL
                                </Option>
                                <Option value="NEST">NEST</Option>
                                <Option value="NOW">NOW</Option>
                                <Option value="PAPDIS">PAPDIS</Option>
                                <Option value="ROYAL LONDON">
                                    ROYAL LONDON
                                </Option>
                                <Option value="SALVUS">SALVUS</Option>
                                <Option value="SCOTTISH WIDOWS ASSIST ME">
                                    SCOTTISH WIDOWS ASSIST ME
                                </Option>
                                <Option value="SCOTTISH WIDOWS STANDARD">
                                    SCOTTISH WIDOWS STANDARD
                                </Option>
                                <Option value="SIMPLY">SIMPLY</Option>
                                <Option value="SMART (OLD STYLE)">
                                    SMART (OLD STYLE)
                                </Option>
                                <Option value="SMART (PAPDIS)">
                                    SMART (PAPDIS)
                                </Option>
                                <Option value="STANDARD LIFE">
                                    STANDARD LIFE
                                </Option>
                                <Option value="THE PENSION TRUST">
                                    THE PENSION TRUST
                                </Option>
                                <Option value="The People’s Pension">
                                    THE PEOPLESS PENSION
                                </Option>
                                <Option value="TRUE POTENTIAL">
                                    TRUE POTENTIAL
                                </Option>
                                <Option value="TRUST PENSIONS">
                                    TRUST PENSIONS
                                </Option>
                                <Option value="WESSEX">WESSEX</Option>
                                <Option value="WORKERS PENSION TRUST">
                                    WORKERS PENSION TRUST
                                </Option>
                                <Option value="WORKPLACE PENSION TRUST">
                                    WORKPLACE PENSION TRUST
                                </Option>
                                <Option value="WORKPLACE PENSION ADVISORY SERVICE">
                                    WORKPLACE PENSION ADVISORY SERVICE
                                </Option>
                                <Option value="ZURICH">ZURICH</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="NEST Delegate ID" name="delegateId">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item label="HMRC Date" name="hmrcDate">
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    format={'DD/MM/YYYY'}
                                    className={commonStyles.dateWidth}
                                    onChange={(date, dateString) => {
                                        onChangeEF(date, dateString)
                                    }}
                                    value={
                                        HmrcDate != null
                                            ? dayjs(HmrcDate)
                                            : null
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Primary Delegate ID"
                            name="primaryDelegateUserId"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="NEST Employer ID"
                            name="nestEmployerId"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Primary Delegate Password"
                            name="primaryDelegatePassword"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Employer NEST Login"
                            name="nestUserName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Primary Delegate Pin"
                            name="primaryDelegatePIN"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Enable/Disable Services"
                            name="status"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                onChange={handleServiceSwitchChange}
                                checked={servicesEnabled}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Tooltip title="Save Pension">
                                <Button type="primary" onClick={handelonSave}>
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

export default PensionEditForm
