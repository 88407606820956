import React, { useCallback, useState } from 'react'
import { Row, Col, Button, Form, Card } from 'antd'
import CoporateTabsless from '../../../../../../Less/Clients/RegisteredClients/Corporate/CorporateTabs.less'
import { connect } from 'react-redux'
import type {
    ContactInfoDataTabs,
    ContactInfoInterface,
    ContactInfoOfficerDatas,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import ContactInfoEditForm from './ContactInfoEditForm'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
const ContactInfoDetails = (props: ContactInfoDataTabs): React.ReactElement => {
    const { corporateTaps, corporateid } = props
    const [editContactData, setEditContactData] =
        useState<ContactInfoInterface | null>(null)

    const [isEditMode, setIsEditMode] = useState(false)
    const handleEditClick = useCallback((): void => {
        setIsEditMode((prevIsEditMode) => !prevIsEditMode)
        if (corporateTaps !== null) {
            setEditContactData(corporateTaps)
        }
    }, [corporateTaps])
    const handleSaveComplete = (): void => {
        setIsEditMode(false)
    }

    return (
        <>
            <Card title="Contact Details">
                <Form onFinish={() => {}}>
                    <div className={CoporateTabsless?.contactinfo}>
                        <Row gutter={16} justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={handleEditClick}
                                >
                                    {isEditMode ? (
                                        <EyeOutlined />
                                    ) : ActivePremission(
                                          '3000',
                                          PermissionType.UPDATE
                                      ) ? (
                                        <EditOutlined />
                                    ) : (
                                        <></>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                        {isEditMode ? (
                            <div>
                                <ContactInfoEditForm
                                    editdata={editContactData}
                                    isEditMode={isEditMode}
                                    corporateid={corporateid}
                                    onSave={handleSaveComplete}
                                    setIsEditMode={setIsEditMode}
                                />
                            </div>
                        ) : (
                            <Row gutter={[16, 32]}>
                                <Col span={3}>
                                    <p>Title</p>
                                    <p>First Name</p>
                                    <p>Middle Name</p>
                                    <p>Surname</p>
                                    <p>Preferred Name</p>
                                    <p>Prefered Language</p>
                                </Col>
                                <Col span={4}>
                                    <p>: {corporateTaps?.title} </p>
                                    <p>: {corporateTaps?.firstname}</p>
                                    <p>: {corporateTaps?.middlename}</p>
                                    <p>: {corporateTaps?.surname}</p>
                                    <p>: {corporateTaps?.preferredname} </p>
                                    <p>: {corporateTaps?.languageName} </p>
                                </Col>
                                <Col span={3} offset={4}>
                                    <p>Mobile Number</p>
                                    <p>Home Number</p>
                                    <p>Work Number</p>
                                    <p>Email Address</p>
                                    <p>Alternative Email</p>
                                </Col>

                                <Col span={6}>
                                    <p>: {corporateTaps?.mobilenumber} </p>
                                    <p>: {corporateTaps?.homenumber}</p>
                                    <p>: {corporateTaps?.worknumber}</p>
                                    <p>: {corporateTaps?.emailaddress}</p>
                                    <p>: {corporateTaps?.altemailaddress}</p>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Form>
            </Card>
        </>
    )
}
const mapStateToProps = (state: any): ContactInfoOfficerDatas => {
    return {
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(ContactInfoDetails)
