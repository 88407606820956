import { Col, Row } from 'antd'
import React from 'react'
import { type CorporateOfficerView } from '../../../../../../Types/Client/RegisteredClients/Corporate'

const corporateMemberView = (props: {
    officer: CorporateOfficerView
}): React.ReactElement => {
    const { officer } = props

    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={8}>
                    {' '}
                    <p>Company Number</p>
                </Col>
                <Col span={8}>:</Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={8}>
                    {' '}
                    <p>Address</p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>
                        : {officer?.addressLineOne}
                        {officer?.addressLineTwo}
                        {officer?.town}
                    </p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={8}>
                    {' '}
                    <p>Country</p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>: {officer?.country}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={8}>
                    <p>Post Code </p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>: {officer?.postCode}</p>
                </Col>
            </Row>

            {Array.isArray(officer.officerRoleInformationList) &&
                officer.officerRoleInformationList?.map(
                    (roleInfo: any, roleIndex: any) => (
                        <Row gutter={[24, 8]} key={roleIndex}>
                            <Col span={8}>
                                <p>
                                    Appoinment Date
                                    <br /> ({roleInfo?.memberType})
                                </p>
                            </Col>
                            <Col span={8}>
                                <p>:{roleInfo?.dateOfAppointment}</p>
                            </Col>
                        </Row>
                    )
                )}
        </>
    )
}

export default corporateMemberView
