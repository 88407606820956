import { type CHFillingHistoryFormDataInterface } from '../../Types/CompanyHouse/CHFillinfHistory'
import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage } from '../../Utils/Notification'
import type {
    ListBackendInterface,
    ListFrontEndInterface,
    TableListInterface,
} from '../../Types/CommonType'
import { setLoadingStatus } from '../../Reducer/Common'
import {
    CHFillingHistoryList,
    CSFillingList,
    fillingTypeList,
} from '../../Reducer/CompanyHouseReducer'
import { type AdvanceSearchInterface } from '../../Types/Invoice/Invoicing'
import { type CSFillingFormDataInterface } from '../../Types/CompanyHouse/CSFillingStatus'

export const GetCHFillingHistoryList = (
    dispatch: Dispatch,
    data: TableListInterface,
    fillingTypeId: number,
    searchData: AdvanceSearchInterface | undefined
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = searchData ?? { searchCriteriaList, sortOrderList }

    axios
        .post(
            `/api/ConfirmationStatement/GetFilingHistoryList/${fillingTypeId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CHFillingHistoryFormDataInterface) => {
                        return {
                            ...value,
                            key: value.submissionId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(CHFillingHistoryList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetFillingTypeList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/63') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(fillingTypeList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCSFillingStatusList = (
    dispatch: Dispatch,
    data: TableListInterface,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/ConfirmationStatement/GetSubmissionStatus/${referenceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CSFillingFormDataInterface) => {
                        return {
                            ...value,
                            key: value.submissionId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(CSFillingList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
