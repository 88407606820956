import React, { type ReactElement } from 'react'
import { Col, Form, Input, Row, Select, Switch } from 'antd'
import { connect } from 'react-redux'
import {
    type HomeStateProps,
    type RegisterAddress,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import commonStyles from '../../../../../../Utils/Common.less'

const CorresponseAddressForm = (props: RegisterAddress): ReactElement => {
    const {
        countryList,
        isEditing,
        toggleEditing,
        edit,
        toggleCorrBussEditing,
    } = props

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <div className={commonStyles.bussinessToggle}>
                        <Switch
                            size="small"
                            checked={isEditing}
                            onChange={toggleEditing}
                        />
                        <span className={commonStyles.bussinesToggleA}>
                            Same as Home
                        </span>
                    </div>
                </Col>

                <Col span={12}>
                    <div className={commonStyles.bussinessToggle}>
                        <Switch
                            size="small"
                            checked={edit}
                            onChange={toggleCorrBussEditing}
                        />
                        <span className={commonStyles.bussinesToggleA}>
                            Same as Business
                        </span>
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Address 1"
                        name="corrAddress1"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your address one!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item label="Address 2" name="corrAddress2">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item
                        label="Town"
                        name="corrTown"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your town!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Country" name="corrCountry">
                        <Select
                            allowClear
                            showSearch
                            placeholder="Please select"
                            onChange={() => {}}
                            options={countryList?.map(
                                (country: { label: any }) => ({
                                    value: country.label,
                                    label: country.label,
                                })
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Post Code"
                        name="corrPostCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your PostCode!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(CorresponseAddressForm)
