// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* your-style.module.css or your-style.css */
.rbc_toolbar_pXbez {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  left: 0;
  right: 0;
}
.buttonGroup_aLoBa {
  /* Style for the combined button group */
  border: #cccccc solid 1px;
  /* Add a border to the group */
  border-radius: 4px;
  /* Rounded corners for the group */
  overflow: hidden;
  /* Hide any overflowing borders from individual buttons */
}
.buttonGroup_aLoBa button {
  padding: 6px 12px;
  background-color: #ffffff;
  color: #383a3c;
  border: none;
  /* Remove individual button borders */
  border-right: #cccccc solid 1px;
  /* Add right border except for the last button */
  cursor: pointer;
  transition: background 0.3s;
}
.rbc_toolbar_pXbez button:last-child {
  border-right: none;
  /* Remove right border from the last button */
}
.rbc_toolbar_pXbez button:hover {
  background-color: #e6e6e6;
}
.rbc_toolbar_pXbez span {
  font-size: 16px;
  font-weight: bold;
}
.buttonGroup_aLoBa button .active_ZFyJM {
  /* Use .active instead of .rbc_toolbar .active */
  background-color: #0056b3;
  color: white;
  /* Example: Change text color for better visibility */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/HelpDesk/ManageDiary/dairyHeder.less"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;AACJ;AAEA;EAAE,wCAAwC;EACtC,yBAAA;EACF,8BAA8B;EAA5B,kBAAA;EAEF,kCAAkC;EADhC,gBAAA;EAGF,yDAAyD;AAC3D;AADA;EACI,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EAGF,qCAAqC;EAFnC,+BAAA;EAIF,gDAAgD;EAH9C,eAAA;EACA,2BAAA;AAKJ;AAFA;EACI,kBAAA;EAIF,6CAA6C;AAC/C;AADA;EACI,yBAAA;AAGJ;AAAA;EACI,eAAA;EACA,iBAAA;AAEJ;AACA;EACE,gDAAgD;EAC9C,yBAAA;EACA,YAAA;EACF,qDAAqD;AACvD","sourcesContent":["/* your-style.module.css or your-style.css */\n.rbc_toolbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n    left: 0;\n    right: 0;\n}\n\n.buttonGroup { /* Style for the combined button group */\n    border: #cccccc solid 1px; /* Add a border to the group */\n    border-radius: 4px; /* Rounded corners for the group */\n    overflow: hidden; /* Hide any overflowing borders from individual buttons */\n}\n\n.buttonGroup button {\n    padding: 6px 12px;\n    background-color: #ffffff;\n    color: #383a3c;\n    border: none; /* Remove individual button borders */\n    border-right: #cccccc solid 1px; /* Add right border except for the last button */\n    cursor: pointer;\n    transition: background 0.3s;\n}\n\n.rbc_toolbar button:last-child {\n    border-right: none; /* Remove right border from the last button */\n}\n\n\n.rbc_toolbar button:hover {\n    background-color: #e6e6e6;\n}\n\n.rbc_toolbar span {\n    font-size: 16px;\n    font-weight: bold;\n}\n\n.buttonGroup button \n    .active { /* Use .active instead of .rbc_toolbar .active */\n    background-color: #0056b3;\n    color: white; /* Example: Change text color for better visibility */\n}\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rbc_toolbar": `rbc_toolbar_pXbez`,
	"buttonGroup": `buttonGroup_aLoBa`,
	"active": `active_ZFyJM`
};
export default ___CSS_LOADER_EXPORT___;
