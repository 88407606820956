import { Form, Input, Button, Switch, Col, Row, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCountryList } from '../../../../../../Services/Common'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import {
    type CorporateOfficerView,
    type OfficerDetailsFormInterface,
    type TogilleAddress,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import {
    GetAddress,
    SaveOfficerCorporate,
} from '../../../../../../Services/Corporate'
import moment from 'moment'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const OfficerCorporateEditForm = (
    props: OfficerDetailsFormInterface & {
        corporateId: number
        saveCallBack: CallableFunction
        iseditNumber: number
        companyName: string
        ReFetchData: () => void
    }
): React.ReactElement => {
    const {
        editData,
        countryList,
        corporateId,
        saveCallBack,
        iseditNumber,
        ReFetchData,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [, setSelectedCountry] = useState<string>('')
    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })
    const [addressDatas, setAddressData] = useState<TogilleAddress | null>()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldValue('referenceId', '0')
    }, [])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCountryList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (countryList !== null && editData !== null) {
            // Check if both countryList and editData are defined
            const countryData = countryList?.find(
                (data: ListFrontEndInterface) => data.label === editData.country
            )

            const newData = {
                ...editData,
                country: countryData?.label ?? 'Please Select',
            }
            form.setFieldsValue(newData)
        }
    }, [editData, countryList])

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                corporateId,
                switchId,
                (addressData: TogilleAddress) => {
                    setAddressData(addressData)
                    setSelectedCountry(addressData?.country ?? '')
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        if (addressDatas !== null && typeof addressDatas === 'object') {
            const newFieldValues = {
                addressLineOne: addressDatas?.addressLineOne,
                addressLineTwo: addressDatas?.addressLineTwo,
                town: addressDatas?.town,
                country: addressDatas?.country,
                postCode: addressDatas?.postCode,
            }

            form.setFieldsValue(newFieldValues)
            form.setFieldsValue({
                country: addressDatas?.country,
            })
        }
    }, [addressDatas])

    const onCorporateEditData = (): void => {
        const formdata = form.getFieldsValue()
        void handelCorporateSave(formdata)
    }

    const handelCorporateSave = async (
        formdata: CorporateOfficerView
    ): Promise<void> => {
        const savedata = {
            membercorporateid: editData.referenceId,
            companyname: editData.titleOrCompanyName,
            companynumber: '',
            corpaddress1: formdata.addressLineOne,
            corpaddress2: formdata.addressLineTwo,
            corptown: formdata.town,
            corpcountry: formdata.country,
            corppostcode: formdata.postCode,
            dateofappointment: moment(formdata.dateofAppointment).format(
                'DD/MM/YYYY HH:mm:ss A'
            ),
            shareholdingpercentage: editData.shareHoldingPercentage,
            shareholdertypeid: 0,
            noofshares: editData.noOfShares,
            corporateid: corporateId,
            isNewShareHolder: iseditNumber,
        }
        saveCallBack(savedata)
        await SaveOfficerCorporate(dispatch, savedata)
            .then((result) => {
                ReFetchData()
            })
            .catch(() => {})
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Office"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="1"
                                checked={switchStates[1]}
                                onChange={(): void => {
                                    handleSwitchChange(1)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Business"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={(): void => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Correspondence"
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={(): void => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView> label="Company Number">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Address 1"
                            name="addressLineOne"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Address 2"
                            name="addressLineTwo"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Town"
                            name="town"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Country "
                            name="country"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country?.label,
                                    label: country?.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="PostCode"
                            name="postCode"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                onClick={onCorporateEditData}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): any => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(OfficerCorporateEditForm)
