import React from 'react'
import { Input } from 'antd'
import styles from './Less/searchInput.less'
const { Search } = Input

interface SearchInputInterface {
    placeHolder?: string
    width?: string
    onSearch?: (data: any) => void
    onChange?: (data: any) => void
    value?: string
}

const SearchInput = (props: SearchInputInterface): React.ReactElement => {
    const {
        placeHolder = 'To filter the clients by number /name',
        width = 378,
        onSearch,
        onChange,
        value,
    } = props
    return (
        <div className={styles.searchDiv}>
            <Search
                placeholder={placeHolder}
                onChange={onChange}
                onSearch={onSearch}
                style={{ width: width + 'px' }}
                className={styles.searchStyles}
                value={value}
            />
        </div>
    )
}

export default SearchInput
