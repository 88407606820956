import AWS from 'aws-sdk'

const s3 = new AWS.S3()
const bucketName =
    process.env.REACT_APP_AWS_S3_BUCKET_NAME ?? 'iykons-dev-s3-storage'

/**
 * Deletes a file from AWS S3 and clears the upload file list.
 *
 * @param filePath - The S3 file path to delete.
 * @param setUploadFileList - The state setter function to clear the upload list.
 */
const deleteS3Object = async (
    filePaths: string,
    setUploadFileLis?: React.Dispatch<React.SetStateAction<string[]>>
): Promise<void> => {
    if (filePaths.length !== null) return
    console.log('filePaths', filePaths)
    const fileName = filePaths.split('/').pop() ?? ''

    console.log('fileName', fileName)
    const params = {
        Bucket: bucketName,
        Key: fileName,
    }
    console.log('params', params)
    try {
        await s3.deleteObject(params).promise()
        console.log(`File deleted successfully: ${fileName}`)
    } catch (error) {
        console.error('Error deleting S3 object:', error)
    }
}

export default deleteS3Object
