import React from 'react'
import { Table } from 'antd'
import type { OnBoardingCorporateExpandViewInterface } from '../../../../Types/Client/OnboardingClients/OnBoardingCoporate'
import commontStyles from '../../../../Utils/Common.less'

const CoporateExpandView = (
    props: OnBoardingCorporateExpandViewInterface
): React.ReactElement => {
    const { corporateData } = props

    const columns = [
        {
            title: 'Name',
            dataIndex: 'contactName',
        },
        {
            title: 'Contact No',
            dataIndex: 'mobilenumber',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Alter Email',
            dataIndex: 'alternativeEmailAddress',
        },
    ]

    const dataSource = [
        {
            key: '1',
            contactName: corporateData.contactName,
            mobilenumber: corporateData.mobilenumber,
            emailAddress: corporateData.emailAddress,
            alternativeEmailAddress: corporateData.alternativeEmailAddress,
        },
    ]

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default CoporateExpandView
