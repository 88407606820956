// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reactQuill_NY4jO {
  height: 150px;
  border: none;
  margin-bottom: 30px;
}
.historyButton_g6Duj {
  width: 200px;
  height: 75px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
}
.buttontext_VssIZ {
  font-size: 30px;
  font-weight: 700;
  margin-right: 'auto';
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/ProspectClients/history/send_email.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACA;EACI,YAAA;EACA,uBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,eAAA;EACA,gBAAA;EACA,oBAAA;AAAJ","sourcesContent":[".reactQuill {\n    height: 150px;\n    border: none;\n    margin-bottom: 30px;\n}\n.historyButton {\n    width: 200px;\n    height: 75px !important;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 20px;\n    margin-bottom: 30px;\n}\n\n.buttontext {\n    font-size: 30px;\n    font-weight: 700;\n    margin-right: 'auto';\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reactQuill": `reactQuill_NY4jO`,
	"historyButton": `historyButton_g6Duj`,
	"buttontext": `buttontext_VssIZ`
};
export default ___CSS_LOADER_EXPORT___;
