import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Input, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import 'react-quill/dist/quill.snow.css'
import { SaveOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { GetInvoiceDetails, SaveEmail } from '../../../Services/Invoice'
import {
    GetSendEmailSDetailsByServiceProcessId,
    SaveChangePrgress,
} from '../../../Services/ToDoViewAllList'
import ReactQuillComponet from '../../../Components/ReactQillComponet'
import S3UploadMultiple from '../../../Components/S3UploadMultiple'
import { type ContactInfo } from '../../../Types/Invoice/Invoicing'
import { type statustPropety } from '../../../Types/ToDoViewAllList'
import { servicedataId } from '../../../Reducer/DashboardFlagReducer'
interface EmailDatas {
    serviceId: number
    contactName: string
    fromAddress: string
    toAddress: string
    ccAddress: string
    bccAddress: string
    subject: string
    emailAttachmentList: any[] // Replace 'any[]' with a more specific type if possible
    description: string // Assuming 'description' is an HTML string
}

const extractTextFromHtml = (htmlString: any): string => {
    const tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.innerHTML
}

const convertHtmlToText = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent ?? ''
}

const SendServiceProcessEmail = (): React.ReactElement => {
    const location = useLocation()
    const { tempId, invoiceIds, emailPageShow, serviceId, serviceActionId } =
        location.state
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [emailText, setEmailText] = useState('')
    const [emaildata, setEmaildata] = useState<EmailDatas>({
        serviceId: 0,
        contactName: '',
        fromAddress: '',
        toAddress: '',
        ccAddress: '',
        bccAddress: '',
        subject: '',
        emailAttachmentList: [],
        description: '',
    })
    const navigate = useNavigate()
    const [EmailDetails, setEmailDetails] = useState<ContactInfo | null>(null)

    useEffect(() => {
        const fetchData = (): void => {
            void GetInvoiceDetails(
                dispatch,
                invoiceIds,
                (details: ContactInfo) => {
                    setEmailDetails(details)
                }
            )
        }
        fetchData()
    }, [invoiceIds])

    useEffect(() => {
        if (EmailDetails !== null && typeof EmailDetails === 'object') {
            const newFieldValues = {
                toAddress: EmailDetails?.emailAddress,
                ccAddress: EmailDetails?.alternativeEmailAddress,
                bccAddress: EmailDetails?.bccEmailAddress,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [EmailDetails, form])

    useEffect(() => {
        if (tempId !== null && tempId !== undefined) {
            const GetEmailDeatils = async (): Promise<void> => {
                const emaildata = await GetSendEmailSDetailsByServiceProcessId(
                    dispatch,
                    invoiceIds,
                    tempId
                )
                setEmaildata(emaildata)
                form.setFieldsValue(emaildata)
                const description = extractTextFromHtml(emaildata?.description)
                const task = convertHtmlToText(description)
                setEmailText(task)
            }
            void GetEmailDeatils()
        }
    }, [tempId])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const [uploadFileList, setUploadFileList] = useState<string[]>([])
    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()
            const serviceProcessStatusProperty: statustPropety[] = []
            const formData = form.getFieldsValue()
            // const dataTosave = {
            //     clientEmailId: 0,
            //     templateId: tempId,
            //     serviceProcessId: invoiceIds,
            //     companyTypeId: 6,
            //     referenceId: 0,
            //     serviceActionId: 0,
            //     serviceActionStepId: 0,
            //     fromAddress: 'info@iykons.com',
            //     toAddress: formData.emailAddress,
            //     ccAddress: formData.emailCC,
            //     bccAddress: formData.emailBcc,
            //     subject: formData.subject,
            //     description: emailText,
            //     emailStatus: 'Sucsss',
            //     serviceId: 0,
            //     contactName: emaildata?.contactName,
            //     invoiceId: 0,
            // }
            const dataTosave = {
                clientEmailId: 0,
                templateId: tempId,
                invoiceId: 0,
                companyTypeId: 6,
                referenceId: 0,
                serviceActionId: 0,
                serviceActionStepId: 0,
                fromAddress: 'info@iykons.com',
                toAddress: formData.toAddress,
                ccAddress: formData.ccAddress,
                bccAddress: formData.bccAddress,
                subject: formData.subject,
                description: emailText,
                emailStatus: 'Pending',
                serviceId: 0,
                fromName: emaildata?.contactName,
                clientEmailTypeId: 16,
                uniqueCode: '',
                attachmentStringId: `${Date.now()}`,
            }
            const htmlMessage = 'Email-Template'
            await SaveEmail(dispatch, dataTosave, uploadFileList, htmlMessage)
            if (serviceActionId !== undefined) {
                if (emailText !== undefined) {
                    serviceProcessStatusProperty.push({
                        propertyType: -1, //
                        referenceId: 0, //
                        propertyValue: '',
                        propertyDataType: '',
                    })
                }
                const data = {
                    serviceProcessId: invoiceIds,
                    type: 1,
                    referenceId: serviceActionId, // service action id
                    statusId: 3,
                    remarks: 'Process Completed Email',
                    completeFlag: 1,
                    emailTemplateId: 0,
                    serviceProcessStatusProperty:
                        serviceProcessStatusProperty ?? [],
                }
                await SaveChangePrgress(dispatch, data)
            }

            form.resetFields()
            if (emailPageShow === 1) {
                navigate(`/thingto-do`)
            } else if (emailPageShow === 3) {
                navigate(`/`)
            } else {
                dispatch(
                    servicedataId({
                        serviceProcesssId: invoiceIds,
                        serviceId: serviceId ?? 0,
                    })
                )
                navigate(`/thingto-do/service-process`)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }
    return (
        <>
            <div>
                <h3>Send Email</h3>
                <span>Browse your send details here</span>
            </div>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Card title="Send Email" className={commonStyles.card}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Email To"
                                name="toAddress"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Email CC"
                                name="ccAddress"
                                // rules={[
                                //     {
                                //         type: 'email',
                                //         message: 'Please input Valid email!',
                                //     },
                                // ]}
                            >
                                <Input />
                            </Form.Item>
                            <span>
                                <i>
                                    Note:-This format only accepted
                                    (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                </i>
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Email BCC " name="bccAddress">
                                <Input />
                            </Form.Item>
                            <span>
                                <i>
                                    Note:-This format only accepted
                                    (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                </i>
                            </span>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Subject',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Description">
                                <ReactQuillComponet
                                    value={emailText}
                                    onChange={(value: string) => {
                                        onDescriptinChange(value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="Attachment" name="imageUpload">
                                <S3UploadMultiple
                                    name={'imageUpload'}
                                    value={uploadFileList}
                                    setFormData={handleSetFormData}
                                    corporate={''}
                                    corporateid={0}
                                    documenttype={''}
                                    documentbaseId={0}
                                    memberpersonId={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col offset={22} span={2}>
                            <Form.Item>
                                <Tooltip title="Send Email">
                                    <Button
                                        type="primary"
                                        size="middle"
                                        onClick={onSave}
                                    >
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    )
}

export default SendServiceProcessEmail
