import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'

export const GetEmailTemplate = async (
    dispatch: Dispatch,
    businessId: string,
    referenceId: string,
    tempId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Email/GetEmailTemplatePropertyByReferenceId/${businessId}/${referenceId}/${tempId}`
        )

        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        // ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetFortoClienDetails = async (
    dispatch: Dispatch,
    businessId: string,
    refernceId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/GetClientContactInfo/${businessId}/${refernceId}`
        )

        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetAmentMetDetails = async (
    dispatch: Dispatch,
    clientToformId: string
): Promise<any> => {
    // Replace 'any' with the type you expect to be returned
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Management/GetFormAmendmentList/${clientToformId}`
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        console.error('Error getting PAYE service details:', error)
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}
