import React, { useState } from 'react'
import { Card, Col, Row, Tabs } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import Staus from './Tabs/Status'
import Tier from './Tabs/Tier'
import Notification from './Tabs/Notification'
import Attachment from './Tabs/Attachment'
import { useLocation } from 'react-router-dom'
const ProjectManagmentView = (): React.ReactElement => {
    const location = useLocation()
    const ProjectData = location.state?.ProjectData
    const [activeTab, setActiveTab] = useState('1')

    const onChange = (key: string): void => {
        setActiveTab(key)
    }
    const ProjectIds = ProjectData.projectId

    const items = [
        {
            key: '1',
            label: 'Tier',
            children: <Tier projectId={ProjectIds} />,
        },
        {
            key: '2',
            label: 'Status',
            children: <Staus projectId={ProjectIds} />,
        },
        {
            key: '3',
            label: 'Notification',
            children: <Notification projectId={ProjectIds} />,
        },
        {
            key: '4',
            label: 'Attachment',
            children: <Attachment projectId={ProjectIds} />,
        },
    ]

    return (
        <>
            <Card className={commonStyles.card} title={ProjectData.projectName}>
                <Row gutter={[16, 32]}>
                    <Col span={5} style={{ fontSize: '15px' }}>
                        Project Prefix
                    </Col>
                    <Col span={1}>:</Col>
                    <Col span={6} style={{ fontSize: '15px' }}>
                        {ProjectData.projectPrefix}
                    </Col>

                    <Col span={5} style={{ fontSize: '15px' }}>
                        Project Lead Name
                    </Col>
                    <Col span={1}>:</Col>
                    <Col span={6} style={{ fontSize: '15px' }}>
                        {ProjectData.projectLeadName}
                    </Col>
                </Row>
                <Row gutter={[16, 32]}>
                    <Col
                        span={5}
                        style={{ fontSize: '15px', marginTop: '10px' }}
                    >
                        Assign User Name
                    </Col>
                    <Col
                        span={1}
                        style={{ fontSize: '15px', marginTop: '10px' }}
                    >
                        :
                    </Col>
                    <Col
                        span={6}
                        style={{ fontSize: '15px', marginTop: '10px' }}
                    >
                        {ProjectData.assignUserName}
                    </Col>

                    <Col
                        span={5}
                        style={{ fontSize: '15px', marginTop: '10px' }}
                    >
                        Duration
                    </Col>
                    <Col
                        span={1}
                        style={{ fontSize: '15px', marginTop: '10px' }}
                    >
                        :
                    </Col>
                    <Col
                        span={6}
                        style={{ fontSize: '15px', marginTop: '10px' }}
                    >
                        {ProjectData.projectStartDate} /
                        {ProjectData.projectEndDate}
                    </Col>
                </Row>
            </Card>

            <Tabs
                activeKey={activeTab}
                onChange={onChange}
                items={items}
                className={commonStyles.card}
            />
        </>
    )
}

export default ProjectManagmentView
