import React from 'react'
import { Table } from 'antd'
import commontStyles from './document.less'
import {
    type officerDocumentList,
    type CorporateDoucmentOfficerExpandView,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { formatdate } from '../../../../../../Utils/dateFormat'

const OfficerDocumentExpanView = (
    props: CorporateDoucmentOfficerExpandView
): React.ReactElement => {
    const { expandData } = props
    const columns = [
        {
            title: 'Expiry Dates',
            dataIndex: 'expiryDates',
            render: (text: any, record: any) => (
                <div>
                    {record.expiryDates.map((item: any, index: number) => {
                        return item.date !== '1/1/1900' ? (
                            <p key={index}>
                                <strong>{item.label}:</strong> {item.date}
                            </p>
                        ) : null
                    })}
                </div>
            ),
        },
    ]

    const dataSource = expandData?.map(
        (record: officerDocumentList, index: number) => {
            return {
                key: index.toString(),
                expiryDates: [
                    {
                        label: 'Passport',
                        date: formatdate(record.ppExpiryDate),
                    },
                    {
                        label: 'Driving License',
                        date: formatdate(record.dlExpiryDate),
                    },
                    {
                        label: 'PR',
                        date: formatdate(record.prExpiryDate),
                    },
                ],
            }
        }
    )

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default OfficerDocumentExpanView
