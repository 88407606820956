// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editMain_LTdWZ {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 95%;
  justify-content: space-between;
}
.editSecond_tOQIi {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 95%;
  justify-content: space-between;
}
.editMainWidth_LpKQv {
  width: 48%;
}
.header_JNR_R {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Less/Clients/RegisteredClients/SoleTrader/ContactInforEditForm.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,UAAA;EACA,8BAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,UAAA;EACA,8BAAA;AAAJ;AAGA;EACI,UAAA;AADJ;AAIA;EACI,eAAA;AAFJ","sourcesContent":[".editMain {\n    display: flex;\n    flex-direction: row;\n    margin-top: 10px;\n    width: 95%;\n    justify-content: space-between;\n}\n\n.editSecond {\n    display: flex;\n    flex-direction: row;\n    margin-top: 30px;\n    width: 95%;\n    justify-content: space-between;\n}\n\n.editMainWidth {\n    width: 48%;\n}\n\n.header {\n    font-size: 20px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMain": `editMain_LTdWZ`,
	"editSecond": `editSecond_tOQIi`,
	"editMainWidth": `editMainWidth_LpKQv`,
	"header": `header_JNR_R`
};
export default ___CSS_LOADER_EXPORT___;
