import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetWorkflowDetailsList,
    SaveWorkflowDetails,
    DeleteWorkflowDetail,
} from '../../../Services/WorkflowDetails'
import { GetWorkflowDetailById } from '../../../Services/Workflow'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import type {
    WorkflowDetailsListInterface,
    WorkflowDetailsFormDataInterface,
    WorkflowDetailsListDataTypeInterface,
} from '../../../Types/WorkflowDetails'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import WorkflowDetailsForm from './WorkflowDetailsForm'
import { GetAccessLevelList } from '../../../Services/Common'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const WorkflowDetailsItemDefaultData = {
    alternativeBy: '0',
    alternativeName: '',
    canSkip: 0,
    levelReference: '',
    levelReferenceName: '',
    levelType: '',
    levelTypeName: '',
    sequenceNo: '',
    workflowLevel: '',
    workflowLevelId: '0',
    clientCategoryName: '',
    clientCategoryId: '0',
    departmentName: '',
    departmentId: '0',
    activeStatus: '',
    workflowName: '',
    workflowId: '',
}

const WorkflowDetailsList = (
    props: WorkflowDetailsListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { workflowDetailsList, workflowDetailsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [workflowLevelId, setWorkflowLevelId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState<string>(
        'Create Workflow Details'
    )
    const [editData, setEditData] = useState<WorkflowDetailsFormDataInterface>(
        WorkflowDetailsItemDefaultData
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetAccessLevelList(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        const queryParams = new URLSearchParams(location.search)
        const workflowId = queryParams.get('id') ?? ''
        GetWorkflowDetailsList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            workflowId
        )
        GetWorkflowDetailById(dispatch, workflowId)
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: workflowDetailsListCount,
            },
        })
    }, [workflowDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        workflowDetailsListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<WorkflowDetailsListDataTypeInterface> = [
        {
            title: 'S.No',
            dataIndex: 'sequenceNo',
        },
        {
            title: 'Name',
            dataIndex: 'workflowName',
        },
        {
            title: 'Level',
            dataIndex: 'workflowLevel',
        },
        {
            title: 'Level Type Name',
            dataIndex: 'levelTypeName',
        },
        {
            title: 'Level Reference Name',
            dataIndex: 'levelReferenceName',
        },
        {
            title: 'Alternative Name',
            dataIndex: 'alternativeName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (
                _,
                record: WorkflowDetailsListDataTypeInterface
            ): React.ReactElement => {
                return (
                    <Space size="middle">
                        {ActivePremission('1007', PermissionType.UPDATE) && (
                            <Tooltip title="Edit WorkFlow Detail">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.workflowLevelId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        {ActivePremission('1007', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.workflowLevelId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<WorkflowDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'workflowName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let workflowLevelId = ''
        if (!deleteModalStatus) {
            workflowLevelId = id
        }
        setWorkflowLevelId(workflowLevelId)
    }

    const deleteWorkflowDetail = (): void => {
        DeleteWorkflowDetail(dispatch, workflowLevelId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = workflowDetailsList.find(
            (data: WorkflowDetailsFormDataInterface) => {
                return data.workflowLevelId === id
            }
        )

        let tittle: string = 'Create Workflow Details'
        if (id !== null) {
            tittle = 'Edit Workflow Details'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? WorkflowDetailsItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const saveWorkflowDetails = async (
        data: WorkflowDetailsFormDataInterface
    ): Promise<void> => {
        await SaveWorkflowDetails(dispatch, data)
    }

    const onSaveWorkflowDetails = (
        data: WorkflowDetailsFormDataInterface,
        callBack: CallableFunction
    ): void => {
        saveWorkflowDetails(data)
            .then((result) => {
                if (result !== null) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Workflow Detail"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search workflow detail by keywords'}
                    onSearch={onChangeFilter}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={workflowDetailsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this workflow. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteWorkflowDetail}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <WorkflowDetailsForm
                        onSave={onSaveWorkflowDetails}
                        editData={editData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): WorkflowDetailsListInterface => {
    return {
        workflowDetailsList: state.initial.workflowDetailsList,
        workflowDetailsListCount: state.initial.workflowDetailsListCount,
    }
}

export default connect(mapStateToProps)(WorkflowDetailsList)
