import React, { type ReactElement, useState } from 'react'
import { Col, Form, Input, Row, Select, Switch } from 'antd'
import { connect } from 'react-redux'
import {
    type BussinessStateProps,
    type BussinessAddress,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import dayjs from 'dayjs'
import commonStyles from '../../../../../../Utils/Common.less'

const BussinessAddressForm = (props: BussinessAddress): ReactElement => {
    const { countryList, isEditing, toggleEditing } = props
    dayjs.locale('en')
    const [selectedCountry, setSelectedCountry] = useState<string>('')

    const handleCountryChange = (value: string): void => {
        setSelectedCountry(value)
        props.onDateChange(value)
    }
    return (
        <>
            <div className={commonStyles.bussinessToggle}>
                <Switch
                    size="small"
                    checked={isEditing}
                    onChange={toggleEditing}
                />
                <span className={commonStyles.bussinesToggleA}>
                    Same as Home
                </span>
            </div>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item
                        label="Address 1"
                        name="busAddress1"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your address one!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Address 2" name="busAddress2">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Town"
                        name="busTown"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your town!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Country" name="busCountry">
                        <Select
                            key={countryList.length}
                            allowClear
                            showSearch
                            onChange={handleCountryChange}
                            options={countryList?.map(
                                (country: { label: any }) => ({
                                    value: country.label,
                                    label: country.label,
                                })
                            )}
                            value={selectedCountry}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item
                        label="Post Code"
                        name="busPostCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your PostCode!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): BussinessStateProps => {
    return {
        countryList: state.common.countryList,
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(BussinessAddressForm)
