import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { ListFrontEndInterface } from '../Types/CommonType'
import {
    type CompanyListPayloadInterface,
    type CompanyFormationTableDataInterface,
} from '../Types/CompanyHouse/CompanyFormation'
import {
    type CompanyHouseCorparePayloadIntreface,
    type CorporateCHInfo,
} from '../Types/CompanyHouse/CompanyOverView'
import {
    type CHFillingHistoryPayloadInterface,
    type CHFillingHistoryFormDataInterface,
} from '../Types/CompanyHouse/CHFillinfHistory'
import {
    type CSFillingFormDataInterface,
    type CSFillingPayloadInterface,
} from '../Types/CompanyHouse/CSFillingStatus'

interface InitailStateInterface {
    chCompanyType: ListFrontEndInterface[]
    endingNameList: ListFrontEndInterface[]
    ComapnyDetailsList: CompanyFormationTableDataInterface[]
    CompanyDetailsListCount: number
    companyHouseOverViewList: CorporateCHInfo | null
    CHFillingHistoryList: CHFillingHistoryFormDataInterface[]
    CHFillingHistoryListCount: number
    fillingTypeList: ListFrontEndInterface[]
    CSFillingList: CSFillingFormDataInterface[]
    CSFillingListCount: number
}

const CompanyHouseReducer = createSlice({
    name: 'companyHouse',
    initialState: {
        chCompanyType: [],
        endingNameList: [],
        ComapnyDetailsList: [],
        CompanyDetailsListCount: 0,
        companyHouseOverViewList: null,
        CHFillingHistoryList: [],
        CHFillingHistoryListCount: 0,
        fillingTypeList: [],
        CSFillingList: [],
        CSFillingListCount: 0,
    },
    reducers: {
        chCompanyType(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.chCompanyType = action?.payload
        },
        endingNameList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.endingNameList = action?.payload
        },
        ComapnyDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<CompanyListPayloadInterface>
        ) {
            state.ComapnyDetailsList = action?.payload?.data
            state.CompanyDetailsListCount = action?.payload?.noOfRecords
        },
        companyHouseOverViewList(
            state: InitailStateInterface,
            action: PayloadAction<CompanyHouseCorparePayloadIntreface>
        ) {
            state.companyHouseOverViewList = action?.payload?.data
        },
        CHFillingHistoryList(
            state: InitailStateInterface,
            action: PayloadAction<CHFillingHistoryPayloadInterface>
        ) {
            state.CHFillingHistoryList = action?.payload?.data
            state.CHFillingHistoryListCount = action?.payload?.noOfRecords
        },
        fillingTypeList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.fillingTypeList = action?.payload
        },
        CSFillingList(
            state: InitailStateInterface,
            action: PayloadAction<CSFillingPayloadInterface>
        ) {
            state.CSFillingList = action?.payload?.data
            state.CSFillingListCount = action?.payload?.noOfRecords
        },
    },
})

export const {
    chCompanyType,
    endingNameList,
    ComapnyDetailsList,
    companyHouseOverViewList,
    CHFillingHistoryList,
    fillingTypeList,
    CSFillingList,
} = CompanyHouseReducer.actions
export default CompanyHouseReducer.reducer
