import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Card, Table, Button } from 'antd'
import commonStyles from '../../../../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'

import type {
    IndividualDetailsListformInterface,
    InduvidualDetailsStateListInterface,
    IndividualListDataTypeInterface,
} from '../../../../../../Types/Client/RegisteredClients/Individual'

import { PlusOutlined, EyeOutlined } from '@ant-design/icons'
import BusinessTagForm from './BusinessTagForm'
import SearchInput from '../../../../../../Components/SearchInput'
import { type TableParamsInterface } from '../../../../../../Types/CommonType'
import { commonTableParams } from '../../../../../../Utils/CommontTable'
import calculatePagination from '../../../../../../Utils/Pagination'

const IndividualList = (
    props: IndividualDetailsListformInterface
): React.ReactElement => {
    const { individualTaps, individualId, indiviualbusinessTagListCount } =
        props
    const [isEditMode, setIsEditMode] = useState(false)
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const handleButtonClick = (): void => {
        // setIsEditMode(!isEditMode) Add this line to set the modal status
        setIsEditMode(!isEditMode)
    }
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const onChangeTableParams: TableProps<IndividualListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }
    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        indiviualbusinessTagListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<IndividualListDataTypeInterface> = [
        {
            title: 'Company Name',
            dataIndex: 'companyName',
        },
        {
            title: 'Type',
            dataIndex: 'businessType',
        },
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Contact Person',
            render: (text, record) =>
                `${record.title} ${record.firstName} ${record.surName}`,
        },
        {
            title: 'Contact Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Contact No',
            dataIndex: 'mobileNumber',
        },
    ]

    return (
        <>
            <div>
                <h2>Business Tag</h2>
            </div>
            <Card
                title={
                    isEditMode
                        ? `Recommended corporate, partnership business to tag the individual account`
                        : `Tagged corporate, partnership business to the above individual account`
                }
                extra={
                    <Button type="primary" onClick={handleButtonClick}>
                        {isEditMode ? <EyeOutlined /> : <PlusOutlined />}
                    </Button>
                }
                className={commonStyles.card}
            >
                {!isEditMode ? (
                    <SearchInput
                        placeHolder={'Search Name by keywords'}
                        onChange={onChangeText}
                        onSearch={onChangeFilter}
                        width="478"
                    />
                ) : null}

                {isEditMode ? (
                    <BusinessTagForm individualId={individualId} />
                ) : (
                    <div className={commonStyles.table}>
                        <Table
                            columns={columns}
                            dataSource={individualTaps}
                            onChange={onChangeTableParams}
                            pagination={pagination}
                        />
                    </div>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): InduvidualDetailsStateListInterface => {
    return {
        indiviualbusinessTagList: state.client.indiviualbusinessTagList,
        indiviualbusinessTagListCount:
            state.client.indiviualbusinessTagListCount,
        individualTaps: Array.isArray(state.client.individualTaps)
            ? state.client.individualTaps
            : [],
    }
}

export default connect(mapStateToProps)(IndividualList)
