import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type UserData,
    type ProfileHolidayData,
    type ProfileHolidayPayloadInteface,
    type UserDataPayloadActionData,
} from '../Types/Profile'

interface ProfileStateInterface {
    userHolidayList: ProfileHolidayData[]
    userHolidayListCount: number
    profiledataList: UserData | null
}

const profileUser = createSlice({
    name: 'profile',
    initialState: {
        userHolidayList: [],
        userHolidayListCount: 0,
        profiledataList: null,
    },
    reducers: {
        userHolidayList(
            state: ProfileStateInterface,
            action: PayloadAction<ProfileHolidayPayloadInteface>
        ) {
            state.userHolidayList = action?.payload?.data
            state.userHolidayListCount = action?.payload?.noOfRecords
        },
        profiledataList(
            state: ProfileStateInterface,
            action: PayloadAction<UserDataPayloadActionData>
        ) {
            state.profiledataList = action?.payload?.data
        },
    },
})

export const { userHolidayList, profiledataList } = profileUser.actions

export default profileUser.reducer
