import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
    Card,
    Table,
    Space,
    Tooltip,
    Modal,
    Col,
    Row,
    Form,
    Input,
    Button,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import {
    CheckOutlined,
    MinusCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import calculatePagination from '../../../Utils/Pagination'
import {
    type TimsheetApproveStateInterfcae,
    type TimesheetApprovelTableInterface,
} from '../../../Types/ToApproveViewAll/Timesheet'
import TimesheetExpandView from './timesheetListExpandview'
import { SetApprovalStatus } from '../../../Services/Dashboard'
import { connect, useDispatch } from 'react-redux'
import { formatdate } from '../../../Utils/dateFormat'
import { GetTimeSheetApproveList } from '../../../Services/ToApproveList'
const { TextArea } = Input

const TimesheetApprovalList = (
    props: TimsheetApproveStateInterfcae
): React.ReactElement => {
    const { timeSheetApproveList, timesheetApproveListCount } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [buttonLoading, setButtonloading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [title, setTitle] = useState('')
    const [isOpenModal, setOpenModal] = useState(false)
    const [approvalRequestId, setApprovalRequestId] = useState<number>(0)
    const [action, setAction] = useState<number>(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetTimeSheetApproveList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: timesheetApproveListCount,
            },
        })
    }, [timesheetApproveListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        timesheetApproveListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<TimesheetApprovelTableInterface> = [
        {
            title: 'User',
            dataIndex: 'userName',
        },
        {
            title: 'Date',
            key: 'dateTime',
            render: (record: TimesheetApprovelTableInterface) => {
                return <div>{formatdate(record.dateTime)}</div>
            },
        },
        {
            width: 500,
            title: 'Task',
            dataIndex: 'description',
        },
        {
            title: 'Duration',
            render: (_, record) => {
                const durationInMinutes = parseInt(record.duration, 10)
                const hours = Math.floor(durationInMinutes / 60) // Get the whole number of hours
                const minutes = durationInMinutes % 60
                return `${hours}hr  : ${minutes} min `
            },
        },
        {
            title: 'Status',
            key: 'submissionStatus',
            render: (_, record) => {
                return record.submissionStatus === 1 ? 'Pending' : 'Saved'
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                if (record.currentApprovalStatus !== 0) {
                    return (
                        <Space size="middle">
                            <Tooltip title="Approve Timesheet">
                                <CheckOutlined
                                    onClick={() => {
                                        openModal(
                                            'Approved',
                                            record.approvalRequestId,
                                            3
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                            <Tooltip title="Reject Timesheet">
                                <MinusCircleOutlined
                                    onClick={() => {
                                        openModal(
                                            'Rejected',
                                            record.approvalRequestId,
                                            3
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        </Space>
                    )
                }
                return null
            },
        },
    ]

    const onChangeTableParams: TableProps<TimesheetApprovelTableInterface>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const handleCancel = (): void => {
        setOpenModal(false)
        setTitle('')
    }

    const openModal = (isApprove: string, id: number, action: number): void => {
        form.resetFields()
        setOpenModal(true)
        setApprovalRequestId(id)
        setAction(action)
        setTitle(isApprove)
    }

    const onFinish = (): void => {
        void onFinishAction()
    }
    const onFinishAction = async (): Promise<void> => {
        try {
            setButtonloading(true)
            await form.validateFields()
            if (approvalRequestId !== undefined && approvalRequestId > 0) {
                const isApprove: number = title === 'Approved' ? 1 : 2
                await SetApprovalStatus(
                    dispatch,
                    action,
                    approvalRequestId,
                    isApprove,
                    form.getFieldValue('approvalComment')
                )
                    .then((result) => {
                        if (result !== null && result !== undefined) {
                            getTableData()
                            handleCancel()
                            setButtonloading(false)
                        }
                    })
                    .catch(() => {})
            }
        } catch {}
    }
    const expandableData = (
        record: TimesheetApprovelTableInterface
    ): React.ReactElement => {
        return <TimesheetExpandView expandData={record} />
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const datasource = timeSheetApproveList?.map((item) => ({
        ...item,
        key: item.approvalRequestId,
    }))
    return (
        <>
            <Card title="Timesheet Approval" className={commonStyles.card}>
                <SearchInput
                    placeHolder={'Search by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
            </Card>
            <Modal
                width={600}
                title={title}
                open={isOpenModal}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Comments"
                                name="approvalComment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter the comment',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={2} offset={21}>
                            <Button
                                type="primary"
                                onClick={onFinish}
                                loading={buttonLoading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): TimsheetApproveStateInterfcae => {
    return {
        timeSheetApproveList: state.dashboardInfo.timeSheetApproveList,
        timesheetApproveListCount:
            state.dashboardInfo.timesheetApproveListCount,
    }
}
export default connect(mapStateToProps)(TimesheetApprovalList)
