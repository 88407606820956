import React, { Component, type ErrorInfo } from 'react' // Use "import type" for ErrorInfo
import { ErrorMessage } from '../Utils/Notification'
import { Result } from 'antd'
import { Link } from 'react-router-dom'

interface ErrorBoundaryProps {
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Error caught by ErrorBoundary: ', error, errorInfo)
        ErrorMessage('An unexpected error occurred. Please try again later.')
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <>
                    <Result
                        status="403"
                        title="403"
                        subTitle="Sorry, unexpected error occurred this page."
                        extra={<Link to="/">Back Home</Link>}
                    />
                </>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
