import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    DatePicker,
    Select,
    Switch,
    TimePicker,
    // type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    GetWorkFlow,
    GetLeaveMode,
    SavePermission,
} from '../../../Services/HR/LeavePermission'
import { GetUserList } from '../../../Services/User'
import {
    type PermissionListType,
    type ApplyFormType,
    type ApplyFormPermissionStateType,
} from '../../../Types/Hr/LeavePermission'
import ApplyPermissionList from './ApplyPermissionList'
import dayjs from 'dayjs'
import moment from 'moment'

const { TextArea } = Input

const ApplyPermission = (props: ApplyFormType): React.ReactElement => {
    const { workflowDropDownList, resetdata, setResetData } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    // const [formHeight, setFormHeight] = useState('auto')
    const [showAdditionalFields, setShowAdditionalFields] = useState(false)
    const [editedData, setEditedData] = useState<PermissionListType>()
    const [savaStatus, setSaveStatus] = useState(false)
    const hasFetchedOnce = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetWorkFlow(dispatch)
        GetLeaveMode(dispatch)
        GetUserList(dispatch)
    }, [dispatch])
    useEffect(() => {
        if (editedData != null) {
            form.setFieldsValue({
                permissionId: editedData.permissionId,
                endTo: dayjs(editedData.endTo, 'YYYY-MM-DDTHH:mm:ss'),
                startTime: moment(editedData.startTime, 'hh:mm A'),
                endTime: moment(editedData.endTime, 'hh:mm A'),
                description: editedData.description,
                workflowId: editedData.workflowId,
                coveringFrom: dayjs(
                    editedData.coveringFrom,
                    'YYYY-MM-DDTHH:mm:ss'
                ),
                coveringStartTime:
                    editedData.coveringStartTime !== '' &&
                    dayjs(editedData.coveringStartTime, 'hh:mm A'),
                coveringEndTime:
                    editedData.coveringEndTime !== '' &&
                    dayjs(editedData.coveringEndTime, 'hh:mm A'),
            })
            setStartTime(dayjs(editedData.endTime, 'hh:mm A'))
        }
    }, [editedData])

    const onFinish = async (): Promise<void> => {
        try {
            await form.validateFields()
            setIsLoadingModal(true)
            const formdata = form.getFieldsValue()

            const formattedStartTime = dayjs(formdata.startTime).format(
                'HH:mm A'
            )
            const formattedEndTime = dayjs(formdata.endTime).format('HH:mm A')
            const formattedcoveringStartTime =
                formdata.coveringStartTime !== null &&
                formdata.coveringStartTime !== undefined
                    ? dayjs(formdata.coveringStartTime).format('HH:mm A')
                    : ''
            const formattedcoveringEndTime =
                formdata.coveringEndTime !== null &&
                formdata.coveringEndTime !== undefined
                    ? dayjs(formdata.coveringEndTime).format('HH:mm A')
                    : ''
            const formatDateEndTo = dayjs(
                formdata.endTo,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('DD/MM/YYYY')
            const coveringFromDate =
                dayjs(formdata.coveringFrom, 'YYYY-MM-DDTHH:mm:ss').format(
                    'DD/MM/YYYY'
                ) ?? ''

            const permissionApprovalStatus = 'Saved'
            const coveringRequired = 1
            const permissionApprovalDescription = ''
            const apiVAlue = {
                ...formdata,
                permissionId: editedData?.permissionId ?? 0,
                permissionApprovalStatus,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                coveringStartTime: formattedcoveringStartTime ?? '',
                coveringEndTime: formattedcoveringEndTime ?? '',
                endTo: formatDateEndTo,
                coveringFrom:
                    coveringFromDate === 'Invalid Date' ? '' : coveringFromDate,
                coveringRequired,
                permissionApprovalDescription,
                startFrom: formatDateEndTo ?? '',
                coveringTo:
                    coveringFromDate === 'Invalid Date' ? '' : coveringFromDate,
                workflowId: formdata.workflowId,
                description: formdata.description,
            }

            await SavePermission(dispatch, apiVAlue)
                .then((result) => {
                    if (result !== null && result !== undefined) {
                        setIsLoadingModal(false)
                    }
                })
                .catch(() => {})

            form.resetFields()
            setSaveStatus(true)
        } catch (err) {
            console.error('Validation errors:', err)
        }
    }

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
            form.resetFields(['coveringFrom'])
        }

        setResetData('false')
    }, [resetdata])

    const statusHandlingFunction = (status: boolean): void => {
        setSaveStatus(status)
    }

    const handleEditedData = (editedData: PermissionListType): void => {
        setEditedData(editedData)
    }
    const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null)

    const handleStartTimeChange = (time: dayjs.Dayjs | null): void => {
        setStartTime(time)
    }

    // const disableEndTime = (
    //     current: Dayjs | null
    // ):
    //     | {
    //           disabledHours?: undefined
    //           disabledMinutes?: undefined
    //       }
    //     | {
    //           disabledHours: () => number[]
    //           disabledMinutes: (selectedHour: number) => number[]
    //       } => {
    //     if (startTime == null) {
    //         return {}
    //     }
    //     const startHour = startTime.hour()
    //     const startMinute = startTime.minute()

    //     return {
    //         disabledHours: () =>
    //             Array.from({ length: 12 }, (_, hour) => hour).filter(
    //                 (hour) => hour < startHour
    //             ),
    //         disabledMinutes: (selectedHour: number) =>
    //             selectedHour === startHour
    //                 ? Array.from({ length: 60 }, (_, minute) =>
    //                       minute <= startMinute ? minute : null
    //                   ).filter((minute) => minute !== null)
    //                 : [],
    //     }
    // }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex"
                initialValues={{ remember: true }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Date"
                            name="endTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                                disabledDate={(current) =>
                                    current !== null &&
                                    current < moment().startOf('day')
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Start Time"
                            name="startTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select the Start Time!',
                                },
                            ]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                defaultOpenValue={dayjs()
                                    .set('hour', 0)
                                    .set('minute', 0)}
                                use12Hours
                                format="h:mm A"
                                onChange={handleStartTimeChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="End Time"
                            name="endTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select the End Time!',
                                },
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        const startTime =
                                            getFieldValue('startTime')
                                        if (
                                            value !== null &&
                                            startTime !== null &&
                                            Boolean(value.isAfter(startTime))
                                        ) {
                                            await Promise.resolve()
                                            return
                                        }
                                        return await Promise.reject(
                                            new Error(
                                                'End Time must be grathan than Start Time!'
                                            )
                                        )
                                    },
                                }),
                            ]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                use12Hours
                                format="h:mm A"
                                // disabledTime={disableEndTime}
                                disabled={startTime === null}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Purpose"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter the purpose',
                                },
                            ]}
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Work Flow"
                            name="workflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the WorkFlow!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...workflowDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Toggle Additional Fields"
                            valuePropName="checked"
                        >
                            <Switch
                                onChange={(checked) => {
                                    setShowAdditionalFields(checked)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showAdditionalFields && (
                    <>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item<PermissionListType>
                                    label="Covering Date"
                                    name="coveringFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the Covering Date!',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        disabledDate={(current) =>
                                            current !== null &&
                                            current < moment().startOf('day')
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item<PermissionListType>
                                    label="Covering Start Time"
                                    name="coveringStartTime"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the Covering Start Time!',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        style={{ width: '100%' }}
                                        use12Hours
                                        format="h:mm A"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item<PermissionListType>
                                    label="Covering End Time"
                                    name="coveringEndTime"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the Covering End Time!',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        style={{ width: '100%' }}
                                        use12Hours
                                        format="h:mm A"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                onFinish()
                                    .then(() => {
                                        console.log(
                                            'Form submitted successfully'
                                        )
                                    })
                                    .catch((error: PermissionListType) => {
                                        console.error(
                                            'Error submitting form:',
                                            error
                                        )
                                    })
                            }}
                            loading={isLoadingModal}
                        >
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
            <ApplyPermissionList
                onFinish={onFinish}
                editedData={handleEditedData}
                savaStatus={savaStatus}
                statusHandlingFunction={statusHandlingFunction}
            />
        </div>
    )
}

const mapStateToProps = (state: any): ApplyFormPermissionStateType => {
    return {
        workflowDropDownList: state.hr.workflowDropDownList,
    }
}

export default connect(mapStateToProps)(ApplyPermission)
