import { Button, Col, Collapse, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import VATViewPage from './VatViewPage'
import PAYEviewPage from './PayeViewPage'
import PensionviewPage from './PensionViewPage'
import AnualAccountEdit from './AnualAccountEdit'
import {
    type ParnersgipStateInteface,
    type PartnershipServiceMainInterface,
    type VatData,
    type AnnualAccountData,
    type PensionData,
    type PayeServiceDataSS,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import { connect, useDispatch } from 'react-redux'
import {
    GetService,
    getClientCompanyDetails,
} from '../../../../../../Services/Parnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

export const AnnualAccountDataDefalut = {
    annualAccountId: 0,
    companyType: 0,
    referenceId: 0,
    fromDate: '01/01/1900',
    toDate: '01/01/1900',
    deadlineDate: '01/01/1900',
    status: '',
}

const PartnershipService = (
    props: PartnershipServiceMainInterface
): React.ReactElement => {
    const { partnershipTaps, partnershipId } = props
    const dispatch = useDispatch()
    const [isEditMode, setIsEditMode] = useState(false)
    const [PayeServiceData, setPayeServiceData] = useState<PayeServiceDataSS>()
    const [VatServiceData, setVATServiceData] = useState<VatData>()
    const [AAServiceData, setAAServiceData] = useState<AnnualAccountData>()
    const [PensionServiceData, setPensionServiceData] = useState<PensionData>()

    const [editData, setEditData] = useState<AnnualAccountData>(
        AnnualAccountDataDefalut
    )
    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(AAServiceData ?? AnnualAccountDataDefalut)
    }

    useEffect(() => {
        if (Array.isArray(partnershipTaps) && partnershipTaps.length > 0) {
            const VatServiceData = partnershipTaps?.find(
                (service) => service?.serviceName === 'VAT'
            )
            const VATServiceReferenceId =
                VatServiceData?.serviceReferenceId ?? 0
            const serviceID = VatServiceData?.serviceId ?? 0
            if (VATServiceReferenceId > 0) {
                void GetService(
                    dispatch,
                    serviceID,
                    VATServiceReferenceId
                ).then((data) => {
                    if (data !== null) {
                        setVATServiceData(data)
                    }
                })
            }
        }
    }, [partnershipTaps])

    useEffect(() => {
        if (Array.isArray(partnershipTaps) && partnershipTaps.length > 0) {
            const PAYEServiceData = partnershipTaps?.find(
                (service) => service?.serviceName === 'PAYE'
            )
            const serviceReferenceId = PAYEServiceData?.serviceReferenceId ?? 0
            const serviceID = PAYEServiceData?.serviceId ?? 0

            if (serviceReferenceId != null) {
                void GetService(dispatch, serviceID, serviceReferenceId).then(
                    (data) => {
                        if (data !== null) {
                            setPayeServiceData(data)
                        }
                    }
                )
            }
        }
    }, [partnershipTaps])

    useEffect(() => {
        if (Array.isArray(partnershipTaps) && partnershipTaps.length > 0) {
            const AAServiceData = partnershipTaps?.find(
                (service) => service?.serviceName === 'AA'
            )
            const AAServiceReferenceId = AAServiceData?.serviceReferenceId ?? 0
            const serviceID = AAServiceData?.serviceId ?? 0
            if (AAServiceReferenceId > 0) {
                void GetService(dispatch, serviceID, AAServiceReferenceId).then(
                    (data) => {
                        if (data !== null) {
                            setAAServiceData(data)
                        }
                    }
                )
            }
        }
    }, [partnershipTaps])

    useEffect(() => {
        if (Array.isArray(partnershipTaps) && partnershipTaps.length > 0) {
            const PensionServiceData = partnershipTaps?.find(
                (service) => service?.serviceName === 'Pension'
            )
            const PensionServiceReferenceId =
                PensionServiceData?.serviceReferenceId ?? 0

            const serviceID = PensionServiceData?.serviceId ?? 0
            if (PensionServiceReferenceId > 0) {
                void GetService(
                    dispatch,
                    serviceID,
                    PensionServiceReferenceId
                ).then((data) => {
                    if (data !== null) {
                        setPensionServiceData(data)
                    }
                })
            }
        }
    }, [partnershipTaps])

    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }

    const datareFetch = (): void => {
        void getClientCompanyDetails(dispatch, 4, partnershipId, 4)
    }
    const handleChildFormSave = (): void => {
        datareFetch()
        setIsEditMode(false)
    }
    return (
        <>
            <Collapse expandIconPosition="end">
                <Collapse.Panel
                    header="Annual Accounts"
                    key="1"
                    extra={
                        <Switch checked={AAServiceData?.status === 'Active'} />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" onClick={handleEditClick}>
                                {isEditMode ? (
                                    <EyeOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.UPDATE
                                    ) && <EditOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isEditMode ? (
                        <div>
                            <AnualAccountEdit
                                editData={editData}
                                partnershipId={partnershipId}
                                handleChildFormSave={handleChildFormSave}
                            />
                        </div>
                    ) : (
                        <Row gutter={[24, 8]}>
                            <Col span={5}>
                                <p>Period</p>
                            </Col>
                            <Col span={7}>
                                <p>
                                    : {formatdate(AAServiceData?.fromDate)}-
                                    {formatdate(AAServiceData?.toDate)}
                                </p>
                            </Col>
                            <Col span={5}>
                                <p>Deadline Date</p>
                            </Col>
                            <Col span={7}>
                                <p>
                                    : {formatdate(AAServiceData?.deadlineDate)}{' '}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Collapse.Panel>
            </Collapse>
            <Collapse expandIconPosition="end">
                <Collapse.Panel
                    header="VAT"
                    key="2"
                    extra={
                        <Switch checked={VatServiceData?.status === 'Active'} />
                    }
                >
                    <VATViewPage
                        VatServiceData={VatServiceData}
                        partnershipId={partnershipId}
                        datareFetch={datareFetch}
                    />
                </Collapse.Panel>
            </Collapse>
            <Collapse expandIconPosition="end">
                <Collapse.Panel
                    header="PAYE"
                    key="3"
                    extra={
                        <Switch
                            checked={PayeServiceData?.status === 'Active'}
                        />
                    }
                >
                    <PAYEviewPage
                        PayeServiceData={PayeServiceData}
                        datareFetch={datareFetch}
                        partnershipId={partnershipId}
                    />
                </Collapse.Panel>
            </Collapse>
            <Collapse expandIconPosition="end">
                <Collapse.Panel
                    header="Pension"
                    key="4"
                    extra={
                        <Switch
                            checked={PensionServiceData?.status === 'Active'}
                        />
                    }
                >
                    <PensionviewPage
                        PensionServiceData={PensionServiceData}
                        partnershipId={partnershipId}
                        datareFetch={datareFetch}
                    />
                </Collapse.Panel>
            </Collapse>
        </>
    )
}

const mapStateToProps = (state: any): ParnersgipStateInteface => {
    return {
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(PartnershipService)
