import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Spin } from 'antd'
import { LoginApi } from '../../Services/Auth'
import styles from './login.less'
// import InputField from '../../Components/InputField'
import InputFieldEmail from '../../Components/InputFieldEmail'
import InputFieldCheckBox from '../../Components/InputFieldCheckBox'
import { useDispatch } from 'react-redux'
import type { loginDataInterface } from '../../Types/Auth'
import commonStyles from '../../Utils/Common.less'
import { useLocation } from 'react-router-dom'
import ChangePin from './changePin'
import ChangePassword from './changePassword'
import ForgotPassword from './forgotPassword'
import InputFieldPassword from '../../Components/InputFieldPassword'

export default function Login(): React.ReactElement {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const [verificationCode, setVerificationCode] = useState<string | null>(
        null
    )
    const [isResetPin, setIsResetPin] = useState<boolean>(false)
    const [isResetPassword, setIsResetPassword] = useState<boolean>(false)
    const [forgotPasswd, setForgotpasswd] = useState(false)
    const [rememberMe, setRememberMe] = useState<boolean>(false)
    const [form] = Form.useForm()

    useEffect(() => {
        const username = sessionStorage.getItem('username')
        const password = sessionStorage.getItem('password')
        const remember: boolean = sessionStorage.getItem('rememberMe') !== null
        setRememberMe(remember)
        form.setFieldsValue({
            username,
            password,
        })
    }, [])
    const onSubmit = (data: loginDataInterface): void => {
        const newData = {
            ipAddress: '',
            username: data.username,
            password: data.password,
        }

        if (rememberMe) {
            sessionStorage.setItem('username', data.username)
            sessionStorage.setItem('password', data.password)
            sessionStorage.setItem('rememberMe', rememberMe.toString())
        } else {
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('password')
            sessionStorage.removeItem('rememberMe')
        }

        LoginApi(dispatch, newData, setLoading)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const getQueryParam = (param: string): string | null => {
        const urlParams = new URLSearchParams(location.search)
        return urlParams.get(param)
    }

    useEffect(() => {
        try {
            const pathname = location.pathname
            const verificationCode = getQueryParam('VerficationCode')

            if (verificationCode != null) {
                setVerificationCode(verificationCode)
            }

            if (pathname.includes('ResetPassword')) {
                setIsResetPassword(true)
            } else {
                setIsResetPassword(false)
            }

            if (pathname.includes('ResetPin')) {
                setIsResetPin(true)
            } else {
                setIsResetPin(false)
            }
        } catch {}
    }, [location])

    if (verificationCode != null) {
        if (isResetPin) {
            return <ChangePin code={verificationCode} />
        }
        if (isResetPassword) {
            return <ChangePassword code={verificationCode} />
        }
    }

    const handleForgotPinClick = (): void => {
        setForgotpasswd(true)
    }

    if (forgotPasswd) {
        return <ForgotPassword />
    }

    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>
                                Hello Again!!
                            </div>
                            <div className={styles.loginTittleDesc}>
                                Enter your credentials to access your account
                            </div>
                        </div>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                            form={form}
                        >
                            {/* <InputField
                                name="username"
                                placeHolder="ex. email@domain.com"
                                label="Username"
                                reuireMessage="Please input your username!"
                            /> */}
                            <InputFieldEmail
                                name="username"
                                placeHolder="ex. email@domain.com"
                                label="Username"
                                reuireMessage="Please input your username!"
                            />
                            <InputFieldPassword
                                name="password"
                                label="Password"
                                reuireMessage="Please input your password!"
                            />

                            <InputFieldCheckBox
                                name="remember"
                                label="Remember Me"
                                checked={rememberMe}
                                onChange={(e) => {
                                    setRememberMe(e.target.checked)
                                }}
                            >
                                <a
                                    className={styles.loginFormForgot}
                                    onClick={handleForgotPinClick}
                                >
                                    Forgot password
                                </a>
                            </InputFieldCheckBox>
                            {loading && (
                                <div className={styles.overlay}>
                                    <Spin size="large" />{' '}
                                </div>
                            )}
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.loginFormButton}
                                >
                                    Sign In
                                </Button>
                            </Form.Item>
                        </Form>

                        {/* <div className={styles.doNotHaveAccountText}>
                            Don’t have an account?
                            <a href="">Sign Up</a>
                        </div> */}
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}
