import { Col, Row, Input, Form, Card, Modal } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { useLocation } from 'react-router-dom'
import AddNewButton from '../../../../Components/AddNewButton'
import IndividualTabs from './individualTabs'
import React, { useState, type ReactElement } from 'react'
import CreateTask from '../../../../Components/CreateTask'

const IndividualView = (): ReactElement => {
    const location = useLocation()
    const IndividualData = location.state?.individualData
    const [isEditModalVisible, setEditModalVisible] = useState(false)
    const openTaskModal = (): void => {
        setEditModalVisible(true)
    }

    const handleCloseModal = (): void => {
        setEditModalVisible(false)
    }
    return (
        <>
            <div>
                <h2>Individual Registration</h2>
            </div>
            <Form>
                <Card
                    title={`${
                        IndividualData?.name !== null &&
                        IndividualData?.name !== undefined
                            ? IndividualData?.name
                            : ''
                    } - ${
                        IndividualData?.clientNumber !== null &&
                        IndividualData?.clientNumber !== undefined
                            ? IndividualData?.clientNumber
                            : ''
                    }`}
                    extra={
                        <AddNewButton
                            name="Create Task"
                            clickAction={() => {
                                openTaskModal()
                            }}
                        />
                    }
                    className={commonStyles.card}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Type" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Client No" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={IndividualData?.clientype}
                                    disabled
                                    defaultValue={'Individual'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={IndividualData?.clientNumber}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Name" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Trading Name" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item>
                                <Input value={IndividualData?.name} disabled />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item>
                                <Input
                                    value={IndividualData?.tradingName}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className={commonStyles.width100}>
                        <IndividualTabs />
                    </Row>
                    <Modal
                        footer={null}
                        onCancel={handleCloseModal}
                        width={'50%'}
                        title="Create Task"
                        open={isEditModalVisible}
                    >
                        <CreateTask onClose={handleCloseModal} />
                    </Modal>
                </Card>
            </Form>
        </>
    )
}

export default IndividualView
