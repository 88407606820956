import React from 'react'
import { Col, Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { type ProspectClientExpandViewInterface } from '../../../Types/Client/ProspectClients/prospectClient'

const ProspectClientDetailsExpandView = (
    props: ProspectClientExpandViewInterface
): React.ReactElement => {
    const { prospectClientDetailsData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                <Col span={3}>Referred By</Col>
                <Col span={8}>
                    <div className={commontStyles.expandRowValue}>
                        {prospectClientDetailsData.referredBy}
                    </div>
                </Col>
            </Row>
            <Row className={commontStyles.expandRow}>
                <Col span={3}> Address</Col>
                <Col span={8}>
                    {' '}
                    <div className={commontStyles.expandRowValue}>
                        {prospectClientDetailsData.address1}
                    </div>
                </Col>
            </Row>
            <Row className={commontStyles.expandRow}>
                <Col span={3}> Country</Col>
                <Col span={8}>
                    <div className={commontStyles.expandRowValue}>
                        {prospectClientDetailsData.country}
                    </div>
                </Col>
            </Row>
            <Row className={commontStyles.expandRow}>
                <Col span={3}>Description</Col>
                <Col span={8}>
                    <div className={commontStyles.expandRowValue}>
                        {prospectClientDetailsData.description}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProspectClientDetailsExpandView
