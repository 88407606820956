import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { Form, Collapse, Button, message, Tooltip } from 'antd'
import type {
    SoleTraderCreateFormDataInterface,
    HomeProps,
    HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import { EditOutlined, EyeFilled, SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { GetCategoryDropDownList } from '../../../../../../Services/GroupAndPartners'
import { GetCountryList } from '../../../../../../Services/Common'
import commonStyles from '../../../../../../Utils/Common.less'
import InitialDetailsForm from './InitialDetailsForm'
import BussinessAddressForm from './BussinessAddressForm'
import CorresponseAddressForm from './CorresponseAddressForm'
import {
    getClientCompanyDetails,
    setBasic,
} from '../../../../../../Services/SoleTrader'
import HomeAddressForm from './HomeAddressForm'
import PersonalDetailsForm from './PersonalDetailsForm'
import SoleTraderConfigurationLess from '../../../../../../Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import moment from 'moment'
const HomeList = (props: HomeProps): ReactElement => {
    const { soleTraderTaps, soleTraderId } = props
    const [form] = Form.useForm()
    const [showHomeForm] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const [isEditing, setIsEditing] = useState(false)
    const [isCorrEditing, setIsCorrEditing] = useState(false)
    const [isCorrBussEditing, setIsCorrBussEditing] = useState(false)
    const [selectCountryHome, setSelectedCountryHome] = useState<string>('')
    const [selectCountryBus, setSelectedCountryBus] = useState<string>('')
    const [selectCountryCorr, setSelectedCountryCorr] = useState<string>('')
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>()
    const [selectedDateFromChild, setSelectedDateFromChild] =
        useState<string>('')
    const hasFetchedOnce1 = useRef(false)
    const [openPanel, setActivePanel] = useState<string | string[]>(['1'])
    const handleDateChangeFromChild = (dateString: string): void => {
        setSelectedDateFromChild(dateString)
    }

    const handleDateChangeCountryHome = (country: string): void => {
        setSelectedCountryHome(country)
    }

    const handleDateChangeCountryBus = (country: string): void => {
        setSelectedCountryBus(country)
    }

    const handleDateChangeCountryCorr = (country: string): void => {
        setSelectedCountryCorr(country)
    }

    const handleCategorySelect = (id: number): void => {
        setSelectedCategoryId(id)
        console.log('id', id)
    }

    const toggleEditing = (): void => {
        setIsEditing(!isEditing)
    }

    const coresToggleEditing = (): void => {
        setIsCorrEditing(!isCorrEditing)
        setIsCorrBussEditing(false)
    }

    const coresBussToggleEditing = (): void => {
        setIsCorrBussEditing(!isCorrBussEditing)
        setIsCorrEditing(false)
    }
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch])

    const updatePage = (): void => {
        setEditMode(true)
        form.submit()
    }
    const viewPage = (): void => {
        setEditMode(false)
    }

    useEffect(() => {
        form.setFieldsValue({
            busAddress1: isEditing
                ? soleTraderTaps?.registerOfficeAddress?.regaddress1
                : soleTraderTaps?.businessAddress?.busaddress1,
            busAddress2: isEditing
                ? soleTraderTaps?.registerOfficeAddress?.regaddress2
                : soleTraderTaps?.businessAddress?.busaddress2,
            busTown: isEditing
                ? soleTraderTaps?.registerOfficeAddress?.regtown
                : soleTraderTaps?.businessAddress?.bustown,
            busCountry: isEditing
                ? soleTraderTaps?.registerOfficeAddress?.regcountry
                : soleTraderTaps?.businessAddress?.buscountry,
            busPostCode: isEditing
                ? soleTraderTaps?.registerOfficeAddress?.regpostcode
                : soleTraderTaps?.businessAddress?.buspostcode,
        })
    }, [isEditing, form, soleTraderTaps])

    useEffect(() => {
        form.setFieldsValue({
            corrAddress1: isCorrEditing
                ? soleTraderTaps?.registerOfficeAddress?.regaddress1
                : soleTraderTaps?.correspondanceAddress?.corraddress1,
            corrAddress2: isCorrEditing
                ? soleTraderTaps?.registerOfficeAddress?.regaddress2
                : soleTraderTaps?.correspondanceAddress?.corraddress2,
            corrTown: isCorrEditing
                ? soleTraderTaps?.registerOfficeAddress?.regtown
                : soleTraderTaps?.correspondanceAddress?.corrtown,
            corrCountry: isCorrEditing
                ? soleTraderTaps?.registerOfficeAddress?.regcountry
                : soleTraderTaps?.correspondanceAddress?.corrcountry,
            corrPostCode: isCorrEditing
                ? soleTraderTaps?.registerOfficeAddress?.regpostcode
                : soleTraderTaps?.correspondanceAddress?.corrpostcode,
        })
    }, [isCorrEditing, form, soleTraderTaps])

    useEffect(() => {
        form.setFieldsValue({
            corrAddress1: isCorrBussEditing
                ? soleTraderTaps?.businessAddress?.busaddress1
                : soleTraderTaps?.correspondanceAddress?.corraddress1,
            corrAddress2: isCorrBussEditing
                ? soleTraderTaps?.businessAddress?.busaddress2
                : soleTraderTaps?.correspondanceAddress?.corraddress2,
            corrTown: isCorrBussEditing
                ? soleTraderTaps?.businessAddress?.bustown
                : soleTraderTaps?.correspondanceAddress?.corrtown,
            corrCountry: isCorrBussEditing
                ? soleTraderTaps?.businessAddress?.buscountry
                : soleTraderTaps?.correspondanceAddress?.corrcountry,
            corrPostCode: isCorrBussEditing
                ? soleTraderTaps?.businessAddress?.buspostcode
                : soleTraderTaps?.correspondanceAddress?.corrpostcode,
        })
    }, [isCorrBussEditing, form, soleTraderTaps])
    useEffect(() => {
        form.setFieldsValue({
            soleTraderId:
                soleTraderTaps?.soleTraderInitialDetails?.soleTraderId ?? 0,
            clientNumber:
                soleTraderTaps?.soleTraderInitialDetails?.clientNumber ?? 0,
            title: soleTraderTaps?.soleTraderInitialDetails?.title,
            firstName: soleTraderTaps?.soleTraderInitialDetails?.firstName,
            middleName: soleTraderTaps?.soleTraderInitialDetails?.middleName,
            surName: soleTraderTaps?.soleTraderInitialDetails?.surName,
            natureOfBusiness:
                soleTraderTaps?.soleTraderInitialDetails?.natureOfBusiness,
            businessTradingName:
                soleTraderTaps?.soleTraderInitialDetails?.businessTradingName,
            individualUTR:
                soleTraderTaps?.soleTraderInitialDetails?.individualUTR,
            dateOfBirth: soleTraderTaps?.soleTraderPersonalDetails?.dateOfBirth,
            niNumber: soleTraderTaps?.soleTraderPersonalDetails?.niNumber,
            nationality: soleTraderTaps?.soleTraderPersonalDetails?.nationality,
            mobileNumber:
                soleTraderTaps?.soleTraderPersonalDetails?.mobileNumber,
            workNumber: soleTraderTaps?.soleTraderPersonalDetails?.workNumber,
            emailAddress:
                soleTraderTaps?.soleTraderPersonalDetails?.emailAddress,
            alternativeEmailAddress:
                soleTraderTaps?.soleTraderPersonalDetails
                    ?.alternativeEmailAddress,
            busAddress1: soleTraderTaps?.businessAddress?.busaddress1,
            busAddress2: soleTraderTaps?.businessAddress?.busaddress2,
            busTown: soleTraderTaps?.businessAddress?.bustown,
            busCountry: soleTraderTaps?.businessAddress?.buscountry,
            busPostCode: soleTraderTaps?.businessAddress?.buspostcode,
            corrAddress1: soleTraderTaps?.correspondanceAddress?.corraddress1,
            corrAddress2: soleTraderTaps?.correspondanceAddress?.corraddress2,
            corrTown: soleTraderTaps?.correspondanceAddress?.corrtown,
            corrCountry: soleTraderTaps?.correspondanceAddress?.corrcountry,
            corrPostCode: soleTraderTaps?.correspondanceAddress?.corrpostcode,
            clientCategoryId:
                soleTraderTaps?.soleTraderInitialDetails?.clientCategoryId,
            homeAddressOne: soleTraderTaps?.registerOfficeAddress?.regaddress1,
            homeAddressTwo: soleTraderTaps?.registerOfficeAddress?.regaddress2,
            homeTown: soleTraderTaps?.registerOfficeAddress?.regtown,
            homeCountry: soleTraderTaps?.registerOfficeAddress?.regcountry,
            homePostCode: soleTraderTaps?.registerOfficeAddress?.regpostcode,
            homeNumber: soleTraderTaps?.soleTraderPersonalDetails?.homeNumber,
        })
    }, [soleTraderTaps])

    const handleFormSubmit = async (): Promise<void> => {
        try {
            await form.validateFields()
            const values = await form.getFieldsValue()
            const apiPayload = {
                ...values,
                soleTraderId:
                    soleTraderTaps?.soleTraderInitialDetails?.soleTraderId ?? 0,
                clientNumber:
                    values.clientNumber ??
                    soleTraderTaps?.soleTraderInitialDetails?.clientNumber,
                busAddress1:
                    values.busAddress1 ??
                    soleTraderTaps?.businessAddress?.busaddress1,
                busAddress2:
                    values.busAddress2 ??
                    soleTraderTaps?.businessAddress?.busaddress2,
                busTown:
                    values.busTown ?? soleTraderTaps?.businessAddress?.bustown,
                busCountry:
                    selectCountryBus ??
                    soleTraderTaps?.businessAddress?.buscountry,
                busPostCode:
                    values.busPostCode ??
                    soleTraderTaps?.businessAddress?.buspostcode,
                corrAddress1:
                    values.corrAddress1 ??
                    soleTraderTaps?.correspondanceAddress?.corraddress1,
                corrAddress2:
                    values.corrAddress2 ??
                    soleTraderTaps?.correspondanceAddress?.corraddress2,
                corrTown:
                    values.corrTown ??
                    soleTraderTaps?.correspondanceAddress?.corrtown,
                corrCountry:
                    selectCountryCorr ??
                    soleTraderTaps?.correspondanceAddress?.corrcountry,
                corrPostCode:
                    values.corrPostCode ??
                    soleTraderTaps?.correspondanceAddress?.corrpostcode,
                clientCategoryId: values.clientCategoryId ?? selectedCategoryId,
                alternativeEmailAddress:
                    values.alternativeEmailAddress ??
                    soleTraderTaps?.soleTraderPersonalDetails
                        ?.alternativeEmailAddress,
                dateOfBirth: selectedDateFromChild,
                emailAddress:
                    values.emailAddress ??
                    soleTraderTaps?.soleTraderPersonalDetails?.emailAddress,
                firstName:
                    values.firstName ??
                    soleTraderTaps?.soleTraderInitialDetails?.firstName,
                homeAddressOne:
                    values.homeAddressOne ??
                    soleTraderTaps?.registerOfficeAddress?.regaddress1,
                homeAddressTwo:
                    values.homeAddressTwo ??
                    soleTraderTaps?.registerOfficeAddress?.regaddress2,
                homeCountry:
                    selectCountryHome ??
                    soleTraderTaps?.registerOfficeAddress?.regcountry,
                homePostCode:
                    values.homePostCode ??
                    soleTraderTaps?.registerOfficeAddress?.regpostcode,
                homeTown:
                    values.homeTown ??
                    soleTraderTaps?.registerOfficeAddress?.regtown,
                individualUTR:
                    values.individualUTR ??
                    soleTraderTaps?.soleTraderInitialDetails?.individualUTR,
                middleName:
                    values.middleName ??
                    soleTraderTaps?.soleTraderInitialDetails?.middleName,
                mobileNumber:
                    values.mobileNumber ??
                    soleTraderTaps?.soleTraderPersonalDetails?.mobileNumber,
                niNumber:
                    values.niNumber ??
                    soleTraderTaps?.soleTraderPersonalDetails?.niNumber,
                nationality:
                    values.nationality ??
                    soleTraderTaps?.soleTraderPersonalDetails?.nationality,
                natureOfBusiness:
                    values.natureOfBusiness ??
                    soleTraderTaps?.soleTraderInitialDetails?.natureOfBusiness,
                surName:
                    values.surName ??
                    soleTraderTaps?.soleTraderInitialDetails?.surName,
                title:
                    values.title ??
                    soleTraderTaps?.soleTraderInitialDetails?.title,
                workNumber:
                    values.workNumber ??
                    soleTraderTaps?.soleTraderPersonalDetails?.workNumber,
                businessTradingName:
                    values.businessTradingName ??
                    soleTraderTaps?.soleTraderInitialDetails
                        ?.businessTradingName,
                homeNumber:
                    values.homeNumber ??
                    soleTraderTaps?.soleTraderPersonalDetails?.homeNumber,
                isActive: 'Active',
                isDelete: 'InActive',
            }

            void dataToSend(apiPayload)
        } catch (error) {
            void message.error('Error submitting form')
        }
    }

    const dataToSend = async (
        apiPayload: SoleTraderCreateFormDataInterface
    ): Promise<void> => {
        try {
            await setBasic(dispatch, apiPayload)
            await getClientCompanyDetails(dispatch, 2, soleTraderId, 1)
            setEditMode(false)
            window.scrollTo(0, 0)
        } catch (error) {
            console.error('Error in dataToSend:', error)
        }
    }
    const HomeAddressData = soleTraderTaps?.registerOfficeAddress
    const HomeAddresData = (): React.ReactElement => {
        return (
            <div>
                {editMode ? (
                    <>
                        <HomeAddressForm
                            onDateChange={handleDateChangeCountryHome}
                        />
                    </>
                ) : (
                    <>
                        <div className={commonStyles.corporateInform}>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>Address 1</p>
                                <p>Address 2</p>
                                <p>Town</p>
                                <p>Country</p>
                                <p>Post Code</p>
                            </div>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>: {HomeAddressData?.regaddress1}</p>
                                <p>: {HomeAddressData?.regaddress2}</p>
                                <p>: {HomeAddressData?.regtown}</p>
                                <p>: {HomeAddressData?.regcountry}</p>
                                <p>: {HomeAddressData?.regpostcode}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    const intialDetails = (): React.ReactElement => {
        return (
            <div>
                {editMode ? (
                    <InitialDetailsForm
                        soleTraderTaps={soleTraderTaps}
                        onCategorySelect={handleCategorySelect}
                    />
                ) : (
                    <div className={commonStyles.corporateInform}>
                        <div className={commonStyles.corporateBasicDetails}>
                            <p>Client Category:</p>
                            <p>Title:</p>
                            <p>First Name:</p>
                            <p>Middle Name:</p>
                            <p>Surname:</p>
                            <p>Nature Of Bussiness:</p>
                            <p>Business Trading Name:</p>
                            <p>Individual UTR:</p>
                        </div>
                        <div className={commonStyles.corporateBasicDetails}>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.clientCategoryName
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.title
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.firstName
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.middleName
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.surName
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.natureOfBusiness
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.businessTradingName
                                }
                            </p>
                            <p>
                                :{' '}
                                {
                                    soleTraderTaps?.soleTraderInitialDetails
                                        ?.individualUTR
                                }
                            </p>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    const dateOfBirth =
        soleTraderTaps?.soleTraderPersonalDetails?.dateOfBirth != null
            ? moment(
                  soleTraderTaps?.soleTraderPersonalDetails?.dateOfBirth
              ).format('YYYY-MM-DD')
            : null
    const personalDetailsData = soleTraderTaps?.soleTraderPersonalDetails
    const PersonalDetails = (): React.ReactElement => {
        return (
            <div>
                {soleTraderTaps !== null && editMode ? (
                    <>
                        <PersonalDetailsForm
                            onDateChange={handleDateChangeFromChild}
                        />
                    </>
                ) : (
                    <>
                        <div className={commonStyles.corporateInform}>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>Date Of Birth</p>
                                <p>NI Number</p>
                                <p>Nationality</p>
                                <p>Mobile Number</p>
                                <p>Home Number</p>
                                <p>Work Number</p>
                                <p>Email Address</p>
                                <p>Alternative Email</p>
                            </div>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>: {dateOfBirth}</p>
                                <p>: {personalDetailsData?.niNumber}</p>
                                <p>: {personalDetailsData?.nationality}</p>
                                <p>: {personalDetailsData?.mobileNumber}</p>
                                <p>: {personalDetailsData?.homeNumber}</p>
                                <p>: {personalDetailsData?.workNumber}</p>
                                <p>: {personalDetailsData?.emailAddress}</p>
                                <p>
                                    :{' '}
                                    {
                                        personalDetailsData?.alternativeEmailAddress
                                    }
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
    const bissinessAddress = soleTraderTaps?.businessAddress
    const BusinessDetails = (): React.ReactElement => {
        return (
            <div>
                {editMode ? (
                    <>
                        <BussinessAddressForm
                            isEditing={isEditing}
                            toggleEditing={toggleEditing}
                            onDateChange={handleDateChangeCountryBus}
                        />
                    </>
                ) : (
                    <>
                        <div className={commonStyles.corporateInform}>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>Address 1</p>
                                <p>Address 2</p>
                                <p>Town</p>
                                <p>Country</p>
                                <p>Post Code</p>
                            </div>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>
                                    <p>: {bissinessAddress?.busaddress1}</p>
                                    <p>: {bissinessAddress?.busaddress2}</p>
                                    <p>: {bissinessAddress?.bustown}</p>
                                    <p>: {bissinessAddress?.buscountry}</p>
                                    <p>: {bissinessAddress?.buspostcode}</p>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    const corresPondanceAddress = soleTraderTaps?.correspondanceAddress
    const CorrespondenceAddress = (): React.ReactElement => {
        return (
            <div>
                {editMode ? (
                    <>
                        <CorresponseAddressForm
                            isEditing={isCorrEditing}
                            toggleEditing={coresToggleEditing}
                            onDateChange={handleDateChangeCountryCorr}
                            edit={isCorrBussEditing}
                            toggleCorrBussEditing={coresBussToggleEditing}
                        />
                    </>
                ) : (
                    <>
                        <div className={commonStyles.corporateInform}>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>Address 1</p>
                                <p>Address 2</p>
                                <p>Town</p>
                                <p>Country</p>
                                <p>Post Code</p>
                            </div>
                            <div className={commonStyles.corporateBasicDetails}>
                                <p>
                                    <p>
                                        : {corresPondanceAddress?.corraddress1}
                                    </p>
                                    <p>
                                        : {corresPondanceAddress?.corraddress2}
                                    </p>
                                    <p>: {corresPondanceAddress?.corrtown}</p>
                                    <p>
                                        : {corresPondanceAddress?.corrcountry}
                                    </p>
                                    <p>
                                        : {corresPondanceAddress?.corrpostcode}
                                    </p>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
    const termSignedDate =
        soleTraderTaps?.soleTraderInitialDetails?.dateOfTermSigned

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const togglePanel = (key: string | string[]): void => {
        if (typeof key === 'string') {
            setActivePanel([key])
        } else {
            setActivePanel(key)
        }
    }

    return (
        <Form
            form={form}
            requiredMark={customizeRequiredMark}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="complex-form"
            style={{ width: '100%' }}
        >
            <div className={commonStyles.homeListBasic}>
                <div>
                    <h3>Basic Information</h3>
                </div>
                <div>
                    {!showHomeForm &&
                        !editMode &&
                        ActivePremission('3000', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Basic Information">
                                <Button type="primary" onClick={updatePage}>
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                        )}
                    {editMode && (
                        <Tooltip title="View Basic Information">
                            <Button type="primary" onClick={viewPage}>
                                <EyeFilled />
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </div>

            <div>
                {termSignedDate !== '1/1/1900 12:00:00 AM' && (
                    <h4>
                        Term signed is activated on {termSignedDate} this
                        company
                    </h4>
                )}
            </div>

            <div
                className={commonStyles.flexDirectionRow}
                style={{ width: '100%' }}
            >
                <div
                    className={
                        SoleTraderConfigurationLess.configurationRowWithMargin
                    }
                >
                    <Collapse
                        defaultActiveKey={1}
                        bordered={true}
                        items={[
                            {
                                key: '1',
                                label: 'Initial Details',
                                children: intialDetails(),
                            },
                        ]}
                        activeKey={openPanel}
                        onChange={togglePanel}
                        accordion
                    />
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '2',
                                label: 'Personal Details',
                                children: PersonalDetails(),
                            },
                        ]}
                        activeKey={openPanel}
                        onChange={togglePanel}
                        accordion
                    />
                </div>
                <div className={SoleTraderConfigurationLess.configurationRow}>
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '3',
                                label: 'Home Address',
                                children: HomeAddresData(),
                            },
                        ]}
                        activeKey={openPanel}
                        onChange={togglePanel}
                        accordion
                    />
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '4',
                                label: 'Bussiness Address',
                                children: BusinessDetails(),
                            },
                        ]}
                        activeKey={openPanel}
                        onChange={togglePanel}
                        accordion
                    />
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '5',
                                label: 'Correspondence Address',
                                children: CorrespondenceAddress(),
                            },
                        ]}
                        activeKey={openPanel}
                        onChange={togglePanel}
                        accordion
                    />
                </div>
            </div>

            {editMode && (
                <div className={commonStyles.homeListBasic}>
                    <div></div>

                    {ActivePremission('3000', PermissionType.SAVE) && (
                        <div>
                            <Tooltip title="Edit Basic Information">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleFormSubmit()
                                            .then(() => {
                                                console.log(
                                                    'Form submitted successfully'
                                                )
                                            })
                                            .catch((error: any) => {
                                                console.error(
                                                    'Error submitting form:',
                                                    error
                                                )
                                            })
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            )}
        </Form>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(HomeList)
