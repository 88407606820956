import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Row, Input, Form, Button, Switch, Select, List, Card } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type EmailTemplateFormDataInterface,
    type EmailContentTypeListFrontEndInterface,
    type EmailTemplateViewDetailsPayloadInterface,
} from '../../../Types/EmailTemplate'
import { EmailTemplateItemDefaultData } from './emailTemplateList'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import { connect, useDispatch } from 'react-redux'
import {
    GetContentProperties,
    GetTemplateTypeList,
    GetEmailTemplateDetailsById,
    AddEmailTemplateDetails,
} from '../../../Services/EmailTemplateService'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { type bool } from 'aws-sdk/clients/signer'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
import { CustomToolbar } from '../../../Components/ReactQillComponet'

const AddEditEmailTemplateForm = (
    props: EmailTemplateViewDetailsPayloadInterface
): React.ReactElement => {
    const location = useLocation()
    const id = location.state?.emailTemplateId
    const [form] = Form.useForm()
    const [emailText, setEmailText] = useState('')
    const [selectedTemplateType, setSelectedTemplateType] = useState(0)
    const dispatch = useDispatch()
    const reactQuillRef = useRef<ReactQuill | null>(null)
    const { emailTemplateDetails, templateTypeList, contentPropertiesList } =
        props
    const [isCommunicationEmail, setIsCommunicationEmail] = useState(
        emailTemplateDetails.isCommunicationEmail !== 0
    )
    const [isFormAmendmentEmail, setIsFormAmendmentEmail] = useState(
        emailTemplateDetails.isFormAmendmentEmail !== 0
    )
    const navigate = useNavigate()
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    useEffect(() => {
        if (id != null && id !== '') {
            GetEmailTemplateDetailsById(dispatch, id.toString())
        } else {
            form.setFieldValue('templateId', 0)
            form.setFieldValue('isCommunicationEmail', 0)
            form.setFieldValue('isFormAmendmentEmail', 0)
        }
    }, [id])

    const getContentData = useCallback((id: number) => {
        if (id !== undefined && id !== null) {
            GetContentProperties(dispatch, id)
        }
    }, [])

    useEffect(() => {
        setSelectedTemplateType(emailTemplateDetails.templateType)
        setEmailText(emailTemplateDetails?.emailContent)
    }, [emailTemplateDetails])

    const resetForm = (): void => {
        navigate('/system-configuration/email-template')
        form.setFieldsValue(EmailTemplateItemDefaultData)
    }

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetTemplateTypeList(dispatch)
    }, [])

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (id !== '') {
            getContentData(selectedTemplateType)
        }
    }, [selectedTemplateType])

    useEffect(() => {
        const templateData = templateTypeList.find(
            (data: ListFrontEndInterface) =>
                data.label === emailTemplateDetails.templateTypeName
        )
        const newData = {
            ...emailTemplateDetails,
            templateType: templateData?.value != null || '0',
        }
        form.setFieldsValue(newData)
    }, [emailTemplateDetails, templateTypeList])

    const setEnableCommunicateChatSwitch = (isEnabled: bool): void => {
        form.setFieldValue('isCommunicationEmail', isEnabled ? 1 : 0)
        setIsCommunicationEmail(isEnabled)
    }

    const setAmendmentSwitch = (isEnabled: bool): void => {
        form.setFieldValue('isFormAmendmentEmail', isEnabled ? 1 : 0)
        setIsFormAmendmentEmail(isEnabled)
    }

    const handleChange = (value: number): void => {
        const data: ListFrontEndInterface | undefined = templateTypeList?.find(
            (data: ListFrontEndInterface) => {
                return data.value === value
            }
        )
        form.setFieldValue('templateTypeName', data?.label)
        form.setFieldValue('templateType', data?.value)

        setSelectedTemplateType(value)
        getContentData(value)
    }

    const appendText = (textToAppend: string): void => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (reactQuillRef.current) {
            const currentContent = reactQuillRef.current
                .getEditor()
                .getContents()
            const newText = { insert: textToAppend }
            currentContent.ops?.push(newText)
            reactQuillRef.current.getEditor().setContents(currentContent)
        }
    }

    const onSave = (data: EmailTemplateFormDataInterface): void => {
        setIsLoadingModal(true)
        const DataToSave = {
            templateId: data.templateId,
            templateName: data.templateName,
            templateType: selectedTemplateType,
            templateTypeName: data.templateTypeName,
            subject: data.subject,
            emailContent: emailText,
            activeStatus: data.activeStatus,
            isCommunicationEmail: data.isCommunicationEmail,
            isFormAmendmentEmail: data.isFormAmendmentEmail,
        }
        AddEmailTemplateDetails(dispatch, DataToSave)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    resetForm()
                    setIsLoadingModal(false)
                }
            })
            .catch(() => {})
    }

    const modules = {
        toolbar: '#toolbar',

        clipboard: {
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'code-block',
        'list',
        'bullet',
        'script',
        'indent',
        'size',
        'color',
        'background',
        'align',
        'link',
        'image',
        'video',
        'formula',
    ]

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div>
                <h2>Email Template</h2>
            </div>
            <Card title="Email Template Details">
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        onFinish={(data) => {
                            onSave(data)
                        }}
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Form.Item<EmailTemplateFormDataInterface>
                            name="templateId"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item<EmailTemplateFormDataInterface>
                            name="activeStatus"
                            hidden={true}
                            initialValue={'Active'}
                        >
                            <Input />
                        </Form.Item>

                        <Row>
                            <Col span={24}>
                                <Form.Item<EmailTemplateFormDataInterface>
                                    label="Name"
                                    name="templateName"
                                    rules={[
                                        { required: true, message: 'Name!' },
                                    ]}
                                >
                                    <Input
                                        className={commonStyles.inputWidth}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={7}>
                                <Form.Item<EmailTemplateFormDataInterface>
                                    label="Template Type"
                                    name="templateTypeName"
                                >
                                    <Select
                                        value={selectedTemplateType}
                                        allowClear
                                        placeholder="Please select"
                                        onChange={handleChange}
                                        options={templateTypeList}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item<EmailTemplateFormDataInterface>
                                    label=" "
                                    name="isCommunicationEmail"
                                >
                                    <Switch
                                        checked={isCommunicationEmail}
                                        onChange={
                                            setEnableCommunicateChatSwitch
                                        }
                                    />
                                    <span style={{ marginLeft: 10 }}>
                                        Enable this to use communicate with
                                        client
                                    </span>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item<EmailTemplateFormDataInterface>
                                    label=" "
                                    name="isFormAmendmentEmail"
                                >
                                    <Switch
                                        checked={isFormAmendmentEmail}
                                        onChange={setAmendmentSwitch}
                                    />
                                    <span style={{ marginLeft: 10 }}>
                                        Enable this to use form amendment with
                                        client
                                    </span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Form.Item<EmailTemplateFormDataInterface>
                                    label="Subject"
                                    name="subject"
                                    rules={[
                                        { required: true, message: 'Subject!' },
                                    ]}
                                >
                                    <Input
                                        className={commonStyles.inputWidth}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={7}>
                                <Form.Item<EmailTemplateFormDataInterface> label="Content properties">
                                    <List
                                        size="small"
                                        bordered
                                        dataSource={contentPropertiesList}
                                        renderItem={(
                                            item: EmailContentTypeListFrontEndInterface,
                                            index: number
                                        ) => (
                                            <List.Item
                                                onClick={() => {
                                                    appendText(
                                                        '( ' + item.label + ' )'
                                                    )
                                                }}
                                                key={index}
                                            >
                                                {'( ' + item.label + ' )'}{' '}
                                            </List.Item>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={17}>
                                <Form.Item<EmailTemplateFormDataInterface>
                                    label="Content"
                                    name="emailContent"
                                >
                                    <CustomToolbar />
                                    <ReactQuill
                                        theme="snow"
                                        ref={reactQuillRef}
                                        value={emailText}
                                        onChange={(content) => {
                                            setEmailText(content)
                                        }}
                                        modules={modules}
                                        formats={formats}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {ActivePremission('1008', PermissionType.SAVE) && (
                            <Row gutter={16}>
                                <Col offset={22} span={2}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={Boolean(isLoadingModal)}
                                    >
                                        <SaveOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (
    state: any
): EmailTemplateViewDetailsPayloadInterface => {
    return {
        templateTypeList: state.common.emailTemplateTypeList,
        contentPropertiesList: state.initial.emailContentTypeList,
        emailTemplateDetails: state.initial.emailTemplateDetails,
    }
}

export default connect(mapStateToProps)(AddEditEmailTemplateForm)
