import { type Dispatch } from '@reduxjs/toolkit'
import { setLoadingStatus } from '../../Reducer/Common'
import axios1 from 'axios'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { type CompanyDetails } from '../../Types/CompanyHouse/CompanyEligiblity'
import {
    type ListBackendInterface,
    type ListFrontEndInterface,
} from '../../Types/CommonType'
import {
    chCompanyType,
    endingNameList,
} from '../../Reducer/CompanyHouseReducer'
import axios from '../../Config/AxiosConfig'
export const CheckCompanyEligiblity = async (
    dispatch: Dispatch,
    serchString: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios1.get(
            `https://utilities.iykons.com/CompanyHouseUK/CompanyNameAvailability/Abc123456789xyz/${serchString}`
        )

        if (response.status === 200) {
            return response?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveCompanyEligiblityStatus = async (
    dispatch: Dispatch,
    data: CompanyDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/CompaniesHouse/SetCHCompany`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetCHCompanyType = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/65') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(chCompanyType(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCHEndingName = (
    dispatch: Dispatch,
    comapnyTypeId: number,
    comunityStatus: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/66/${comapnyTypeId}/${comunityStatus}`
        ) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(endingNameList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
