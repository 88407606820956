import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { GetITDashboardResponse } from '../Types/Dashboard'
import {
    type ITTask,
    type ITProject,
    type ITSTaffprogressDetailsData,
    type ProjectTaskCounts,
} from '../Types/ITDashbord'

export interface InitialStateInterface {
    itdashboard: GetITDashboardResponse | null
    ITprojectList: ITProject[]
    ITTaskList: ITTask[]
    ItStaffProgressList: ITSTaffprogressDetailsData[]
    overalltaskListcoutdata: ProjectTaskCounts[]
    BPOANDotherStaffProgressList: ITSTaffprogressDetailsData[]
    bpoTaskList: ITTask[]
}
const ITDashboardReducer = createSlice({
    name: 'itdashboardInfo',
    initialState: {
        itdashboard: null,
        ITprojectList: [],
        ITTaskList: [],
        ItStaffProgressList: [],
        overalltaskListcoutdata: [],
        BPOANDotherStaffProgressList: [],
        bpoTaskList: [],
    },
    reducers: {
        itdashboard(
            state: InitialStateInterface,
            action: PayloadAction<GetITDashboardResponse>
        ) {
            state.itdashboard = action?.payload
        },
        itprojectList(
            state: InitialStateInterface,
            action: PayloadAction<ITProject[] | []>
        ) {
            state.ITprojectList = action?.payload
        },
        itTaskList(
            state: InitialStateInterface,
            action: PayloadAction<ITTask[] | []>
        ) {
            state.ITTaskList = action?.payload
        },
        ItStaffProgressList(
            state: InitialStateInterface,
            action: PayloadAction<ITSTaffprogressDetailsData[] | []>
        ) {
            state.ItStaffProgressList = action?.payload
        },
        overalltaskListcoutdata(
            state: InitialStateInterface,
            action: PayloadAction<ProjectTaskCounts[] | []>
        ) {
            state.overalltaskListcoutdata = action?.payload
        },
        BPOANDotherStaffProgressList(
            state: InitialStateInterface,
            action: PayloadAction<ITSTaffprogressDetailsData[] | []>
        ) {
            state.BPOANDotherStaffProgressList = action?.payload
        },
        bpoTaskList(
            state: InitialStateInterface,
            action: PayloadAction<ITTask[] | []>
        ) {
            state.bpoTaskList = action?.payload
        },
    },
})

export const {
    itdashboard,
    itprojectList,
    itTaskList,
    ItStaffProgressList,
    overalltaskListcoutdata,
    BPOANDotherStaffProgressList,
    bpoTaskList,
} = ITDashboardReducer.actions
export default ITDashboardReducer.reducer
