/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    Card,
    Tooltip,
    Radio,
    type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CloudDownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import hederimage from '../../../Assest/Svg/pattern-2.svg'
import { type InvoiceReportsInterface } from '../../../Types/Invoicing/InvoiceReports'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import {
    GetPaidInvoiceReporDetailsList,
    GetUnpaidReporDetailsList,
} from '../../../Services/Invoicing/InvoiceReport'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import { GetRegisteClientDetails } from '../../../Services/Common'
import autoTable from 'jspdf-autotable'
import { GetPaymentMode, GetPaymentOption } from '../../../Services/Invoice'

const PaymentsalaryReport = (
    props: InvoiceReportsInterface
): React.ReactElement => {
    const {
        categoryDropDownList,
        paymentOptionList,
        serviceDropDownList,
        regiterClientDetails,
    } = props

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selectedToDate, setSelectedToDate] = useState<string>('')
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const [noRecordsFound, setNoRecordsFound] = useState(false)
    const [selectedCompanyGroupId, setSelectedCompanyGroupsId] = useState(0)
    const [selectedBusinessId, setSelectedBusinessId] = useState(0)
    const [reportType, setReportType] = useState<'paid' | 'unpaid'>('paid')

    const [selectedpaymentOption, setSelectedpaymentOption] = useState('0')
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetPaymentMode(dispatch)
        GetRegisteClientDetails(dispatch)
        GetServiceDropDownList(dispatch)
        GetPaymentOption(dispatch)
    }, [dispatch])

    const handlePaymentOptionChange = (value: string): void => {
        setSelectedpaymentOption(value)
    }

    const getPaymentOptionName = (value: string | number): string => {
        const option = paymentOptionList?.find((option) => option.id === value)
        return option
            ? `${option.additionalDataOne} - ${option.displayName}`
            : 'Unknown'
    }
    const selectedPaymentOptionName = getPaymentOptionName(
        selectedpaymentOption
    )
    const getTableData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formattedSelectedDate = selectedDate
                ? dayjs(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
                : ''
            const formattedSelectedToDate = selectedToDate
                ? dayjs(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
                : ''

            const datatoPost = {
                fromDate: formattedSelectedDate,
                toDate: formattedSelectedToDate,
            }
            let reportData
            if (reportType === 'paid') {
                reportData = await GetPaidInvoiceReporDetailsList(
                    dispatch,
                    selectedCompanyGroupId,
                    selectedBusinessId,
                    selectedServiceId,
                    datatoPost
                )
            } else {
                reportData = await GetUnpaidReporDetailsList(
                    dispatch,
                    selectedCompanyGroupId,
                    selectedBusinessId,
                    Number(selectedpaymentOption),
                    datatoPost
                )
            }

            if (
                Boolean(reportData?.invoiceStatusList) &&
                reportData.invoiceStatusList.length > 0
            ) {
                handleDownloadPDF(reportData)
                setNoRecordsFound(false)
            } else {
                setNoRecordsFound(true)
            }
        } catch (error) {
            console.error('Error in getTableData:', error)
        }
    }

    const handleDownloadPDF = (data: any): void => {
        if (reportType === 'paid') {
            generatePaidReportPDF(data)
        } else {
            generateUnpaidReportPDF(data)
        }
    }
    const getCompanyGroupLabel = (id: number): string => {
        if (id === 0) return 'All'
        const group = categoryDropDownList.find((item) => item.value === id)
        return group != null ? group.label : id.toString()
    }
    const generatePaidReportPDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        const missingCompanyCategoryLabel = getCompanyGroupLabel(
            selectedCompanyGroupId
        )

        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const categoryFontSize = 10

        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop

            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text(missingCompanyCategoryLabel, textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('Invoice Report', textXPosition, subHeaderYPosition)

            const lineStartX =
                textXPosition + doc.getTextWidth('Invoice Report') + 5
            const lineEndX = lineStartX + 80
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)
            doc.setFontSize(categoryFontSize)
            doc.setTextColor(0, 0, 0)
            const dateRangeYPosition = lineY + 10
            const dateRangeText = `From Date :- ${selectedDate} To Date:- ${selectedToDate}`
            doc.text(dateRangeText, textXPosition, dateRangeYPosition)

            const headers = [
                [
                    {
                        content: 'Number',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Client',
                        styles: {
                            halign: 'left',
                            cellWidth: 'wrap',
                        },
                    },
                    {
                        content: 'Service',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Invoice Date',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Net Total',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Payment Option',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Payment Status',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                ],
            ]

            const tableData1 = data?.invoiceStatusList?.map((item: any) => {
                const possibleFormats = [
                    'MMM DD YYYY h:mmA',
                    'MMM D YYYY h:mmA',
                ]
                let parsedDate = dayjs(item.invoiceDate, possibleFormats[0])

                if (!parsedDate.isValid()) {
                    parsedDate = dayjs(item.invoiceDate, possibleFormats[1])
                }

                const formattedDate = parsedDate.isValid()
                    ? parsedDate.format('MMM DD YYYY') // e.g., "Aug 11 2022"
                    : item.invoiceDate // fallback in case of parsing error
                const result = (parseFloat(item.invoiceAmount) + 0.0).toFixed(1)
                let dateInfo = ''
                if (item.serviceName === 'AA') {
                    dateInfo = `Next accounts made up to ${item.periodStartDate} due by   ${item.periodEndDate}`
                } else {
                    dateInfo = `Period of ${item.periodStartDate}to  ${item.periodEndDate}`
                }
                return [
                    item.clientNumber,
                    `${item.clientName}\n${dateInfo}`,
                    item.serviceName,
                    formattedDate,
                    result,
                    item.paymentOptionName,
                    item.paymentStatus,
                ]
            })

            autoTable(doc, {
                startY: subHeaderYPosition + 15,
                head: headers,
                body: tableData1,
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                },
                bodyStyles: {
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                    fontSize: 8,
                },
                columnStyles: {
                    1: { cellWidth: 78 }, // Assuming the "Client" column is the second column (index 1)
                },
            })
            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${tableData1?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }

            doc.save('GeneratedReports_Report-InvoicePaid.pdf')
        }
    }
    const disabledToDate = (current: dayjs.Dayjs): boolean => {
        const fromDate = form.getFieldValue('fromDate')
        return fromDate
            ? current && current.isBefore(dayjs(fromDate), 'day')
            : false
    }
    // eslint-disable-next-line new-cap
    const generateUnpaidReportPDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        const missingCompanyCategoryLabel = getCompanyGroupLabel(
            selectedCompanyGroupId
        )

        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const categoryFontSize = 10

        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop

            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text(missingCompanyCategoryLabel, textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('Invoice Unpaid Report', textXPosition, subHeaderYPosition)

            const lineStartX =
                textXPosition + doc.getTextWidth('Invoice Unpaid Report') + 5
            const lineEndX = lineStartX + 80
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)
            doc.setFontSize(categoryFontSize)
            doc.setTextColor(0, 0, 0)
            const dateRangeYPosition = lineY + 10
            const dateRangeText = `From Date :- ${selectedDate} To Date:- ${selectedToDate} Status:-${selectedPaymentOptionName}`
            doc.text(dateRangeText, textXPosition, dateRangeYPosition)
            const headers = [
                [
                    {
                        content: 'Number',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Client',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Service',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Invoice Date',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Net Total',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                ],
            ]

            const tableData = data?.invoiceStatusList?.map((item: any) => {
                const possibleFormats = [
                    'MMM DD YYYY h:mmA',
                    'MMM D YYYY h:mmA',
                ]
                let parsedDate = dayjs(item.invoiceDate, possibleFormats[0])

                if (!parsedDate.isValid()) {
                    parsedDate = dayjs(item.invoiceDate, possibleFormats[1])
                }

                const formattedDate = parsedDate.isValid()
                    ? parsedDate.format('MMM DD YYYY') // e.g., "Aug 11 2022"
                    : item.invoiceDate // fallback in case of parsing error
                const result = (parseFloat(item.invoiceAmount) + 0.0).toFixed(1)
                let dateInfo = ''
                if (item.serviceName === 'AA') {
                    dateInfo = `Next accounts made up to ${item.periodStartDate} due by   ${item.periodEndDate}`
                } else {
                    dateInfo = `Period of ${item.periodStartDate}to  ${item.periodEndDate}`
                }
                return [
                    item.clientNumber,
                    `${item.clientName}\n${dateInfo}`,
                    item.serviceName,
                    formattedDate,
                    result,
                    item.paymentOptionName,
                    item.paymentStatus,
                ]
            })

            autoTable(doc, {
                startY: subHeaderYPosition + 15,
                head: headers,
                body: tableData,
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                    fontSize: 8,
                },
                bodyStyles: {
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                    fontSize: 8,
                },
                columnStyles: {
                    1: { cellWidth: 78 }, // Assuming the "Client" column is the second column (index 1)
                },
            })
            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${tableData?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }

            doc.save('GeneratedReports_Report-InvoiceUnpaid')
        }
    }

    const handleSelectChangeService = (value: number): void => {
        setSelectedServiceId(value)
    }

    const handleSelectChangeCompanyGroup = (value: number): void => {
        setSelectedCompanyGroupsId(value)
    }

    const handleSelectChangeBusinesType = (value: number): void => {
        setSelectedBusinessId(value)
    }

    const onChange = (date: DatePickerProps['value']): void => {
        if (date) {
            form.setFieldsValue({ fromDate: date })
            setSelectedDate(date.format('DD-MM-YYYY'))
        }
    }

    const onChangeTodate = (date: DatePickerProps['value']): void => {
        if (date) {
            form.setFieldsValue({ toDate: date })
            setSelectedToDate(date.format('DD-MM-YYYY'))
        }
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Card title="Payment Summary Reports" style={{ marginTop: '20px' }}>
                <Radio.Group
                    value={reportType}
                    onChange={(e) => {
                        setReportType(e.target.value)
                    }}
                    style={{ marginBottom: 16 }}
                >
                    <Radio.Button value="paid">Paid</Radio.Button>
                    <Radio.Button value="unpaid">Unpaid</Radio.Button>
                </Radio.Group>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={reportType === 'unpaid' ? 8 : 6}>
                                <Form.Item
                                    label="From Date"
                                    name="fromDate"
                                    rules={[{ required: true }]}
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChange}
                                            value={form.getFieldValue(
                                                'fromDate'
                                            )}
                                            className={commonStyles.dateWidth}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={reportType === 'unpaid' ? 8 : 6}>
                                <Form.Item
                                    label="To Date"
                                    name="toDate"
                                    rules={[{ required: true }]}
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChangeTodate}
                                            value={form.getFieldValue('toDate')}
                                            className={commonStyles.dateWidth}
                                            disabledDate={disabledToDate}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            {reportType === 'paid' && (
                                <Col span={6}>
                                    <Form.Item label="Service">
                                        <Select
                                            placeholder="Please select"
                                            onChange={handleSelectChangeService}
                                            value={selectedServiceId}
                                            options={[
                                                { value: 0, label: 'All' },
                                                ...serviceDropDownList,
                                            ]}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option?.label
                                                    ?.toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    ) ?? false
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={reportType === 'unpaid' ? 8 : 6}>
                                <Form.Item label="Company Group">
                                    <Select
                                        placeholder="Please select"
                                        onChange={
                                            handleSelectChangeCompanyGroup
                                        }
                                        value={selectedCompanyGroupId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={reportType === 'unpaid' ? 8 : 6}>
                                <Form.Item label="Business Type">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeBusinesType}
                                        value={selectedBusinessId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...regiterClientDetails,
                                        ]}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            {reportType === 'unpaid' && (
                                <Col span={8}>
                                    <Form.Item
                                        label="Invoice From"
                                        name="paymentOptionId"
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Please select"
                                            value={selectedpaymentOption}
                                            onChange={handlePaymentOptionChange}
                                            optionFilterProp="children"
                                            style={{ width: '100%' }}
                                            filterOption={(input, option) =>
                                                typeof option?.children ===
                                                    'string' &&
                                                (option.children as string)
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                        >
                                            <Select.Option
                                                key={'0'}
                                                value={'0'}
                                            >
                                                ALL
                                            </Select.Option>
                                            {paymentOptionList?.map(
                                                (option) => (
                                                    <>
                                                        <Select.Option
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            {
                                                                option.additionalDataOne
                                                            }
                                                            -
                                                            {option.displayName}
                                                        </Select.Option>
                                                    </>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={16}>
                            <Col offset={22} span={2}>
                                <Tooltip title="Download Report">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={getTableData}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {noRecordsFound && (
                    <div style={{ textAlign: 'left', marginTop: '20px' }}>
                        <span
                            style={{
                                color: '#808080',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            No Records found
                        </span>
                    </div>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): InvoiceReportsInterface => {
    return {
        parentCategoryList: state.initial.parentCategoryList,
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
        invoiceStatusReportList: state.invoice.invoiceStatusReportList,
        paymentOptionList: state.invoice.paymentOptionList,
    }
}

export default connect(mapStateToProps)(PaymentsalaryReport)
