import React, { useEffect, useRef, useState } from 'react'
import { Calendar, Card, Modal } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type EventlistFormDataInterface,
    type EventStateType,
} from '../../../Types/HelpDesk/ManageDiary'
import ManageDiaryForm from './ManagrDiaryForm'
import { GetEventList } from '../../../Services/Helpdesk/ManageDiary'
import ManageDiaryList from './ManageDiaryList'
import dayjs, { type Dayjs } from 'dayjs'

const ManageDiaryView = (props: EventStateType): React.ReactElement => {
    const { EventList } = props

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs())
    const [modalVisible, setModalVisible] = useState(false)
    const [morevisible, setmorevisible] = useState(false)
    const [modalEVentVisible, setModaleventVisible] = useState(false)
    const [selectedEvents, setSelectedEvents] = useState<
        EventlistFormDataInterface[]
    >([])
    const selectedEvent = useRef<EventlistFormDataInterface | null>(null)
    const format = 'DD/MM/YYYY'
    const [eventmodel, seteventmodel] = useState(0)
    const dispatch = useDispatch()
    const [modalTitle, setModalTitle] = useState('')
    const isEvent = useRef<number>(0)
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const hasFetchedOnce = useRef(false)
    const [calendarKey, setCalendarKey] = useState(0)
    const handleDateSelect = (date: Dayjs): void => {
        const selectedDateString = date.isValid()
            ? date.format(format)
            : dayjs().format(format)

        const selectedMonth = date.isValid()
            ? date.format('MM')
            : dayjs().format('MM')
        const selectedYear = date.isValid()
            ? date.format('YYYY')
            : dayjs().format('YYYY')

        setSelectedDate(date)
        const eventsOnSelectedDate = EventList.filter(
            (event) =>
                dayjs(event.startFrom).format(format) === selectedDateString
        )
        setSelectedEvents(eventsOnSelectedDate)

        if (isEvent.current === 0) {
            if (selectedMonth === month && selectedYear === year) {
                setModalVisible(true)
                seteventmodel(1)
            }
            setMonth(selectedMonth)
            setYear(selectedYear)
        } else if (isEvent.current === 1) {
            setModaleventVisible(true)
        } else {
            setmorevisible(true)
        }
    }

    const moreevent = (): void => {
        isEvent.current = 2
    }

    const moreventclose = (): void => {
        isEvent.current = 0
        setmorevisible(false)
    }
    const closeModal = (): void => {
        setModalVisible(false)
        setTimeout(() => {
            isEvent.current = 0
            GetEventList(dispatch)
        }, 1000)
    }

    const handleEvent = (event: EventlistFormDataInterface): void => {
        isEvent.current = 1

        setModalTitle(event.eventTitle)
        selectedEvent.current = event
        setModaleventVisible(true)
    }

    const oncloseEventModal = (): void => {
        setModaleventVisible(false)
        isEvent.current = 0
    }

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }

        GetEventList(dispatch)
        setCalendarKey((prevKey) => prevKey + 1) // Force re-render after event list is updated
    }, [])

    const handleDeleteEvent = (eventId: number): void => {
        const updatedEvents = selectedEvents.filter(
            (event) => event.eventId !== eventId
        )
        setSelectedEvents(updatedEvents)
        if (updatedEvents.length === 0) {
            setModaleventVisible(false)
        }
    }

    const dateCellRender = (date: Dayjs): JSX.Element[] | null => {
        const currentDate = date.format(format)
        const eventsOnThisDay = EventList.filter((event) => {
            const eventDateStr = dayjs(event.startFrom).format(format)
            return eventDateStr === currentDate
        })

        if (eventsOnThisDay.length === 1) {
            return eventsOnThisDay.map((event) => (
                <div
                    style={{
                        backgroundColor: 'green',
                        cursor: 'pointer',
                        width: '100%',
                        padding: '5px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: 'white',
                    }}
                    key={event.eventId}
                    onClick={() => {
                        handleEvent(event)
                    }}
                >
                    {event.eventTitle}
                </div>
            ))
        } else if (eventsOnThisDay.length > 1) {
            return [
                <div
                    style={{ cursor: 'pointer' }}
                    key={`${currentDate}-add-more`}
                    onClick={() => {
                        moreevent()
                    }}
                >
                    Show More
                </div>,
            ]
        } else {
            return null
        }
    }

    return (
        <>
            <Card title="Manage Diary">
                <Calendar
                    onSelect={handleDateSelect}
                    dateCellRender={dateCellRender}
                    key={calendarKey}
                />
            </Card>

            <Modal
                title="Save Event"
                open={modalVisible}
                onCancel={closeModal}
                footer={null}
                width={'50%'}
            >
                {selectedDate !== null && eventmodel === 1 && (
                    <ManageDiaryForm
                        date={selectedDate}
                        initialFormData={selectedEvents}
                        oncloseEventModal={closeModal}
                    />
                )}
            </Modal>
            <Modal
                title={modalTitle}
                open={modalEVentVisible}
                onCancel={oncloseEventModal}
                footer={null}
            >
                <ManageDiaryList
                    selectedDate={selectedDate}
                    selectedEvent={selectedEvent.current}
                    onDeleteEvent={handleDeleteEvent}
                    selectedEvents={selectedEvents}
                    setModaleventVisible={setModaleventVisible}
                />
            </Modal>
            <Modal
                title={''}
                open={morevisible}
                onCancel={moreventclose}
                footer={null}
            >
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                padding: '10px',
                                fontWeight: 'bold',
                                fontSize: '16px',
                            }}
                        >
                            {selectedDate.format('DD MMMM YYYY')}
                        </div>

                        <div style={{ flex: 2, padding: '10px' }}>
                            {selectedEvents?.length > 0 ? (
                                selectedEvents.map((event) => (
                                    <div
                                        key={event.eventId}
                                        style={{
                                            backgroundColor: 'green',
                                            cursor: 'pointer',
                                            width: '80%',
                                            padding: '5px',
                                            textAlign: 'center',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            color: 'white',
                                            marginBottom: '4px',
                                            borderRadius: '4px',
                                            boxShadow:
                                                '0 2px 5px rgba(0,0,0,0.2)',
                                        }}
                                        onClick={() => {
                                            handleEvent(event)
                                        }}
                                    >
                                        {event.eventTitle}
                                    </div>
                                ))
                            ) : (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: '#666',
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): EventStateType => {
    return {
        EventList: state.helpdesk.EventList,
        EventListCount: state.helpdesk.EventListCount,
    }
}

export default connect(mapStateToProps)(ManageDiaryView)
