import React, { useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
interface PDFPreviewProps {
    pdfUrl: string
}
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
const PDFPreview = (props: PDFPreviewProps): React.ReactElement => {
    const { pdfUrl } = props
    const [numPages, setNumPages] = useState<number>(0)
    const [pageNumber, setPageNumber] = useState<number>(1)

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages)
    }

    const goToNextPage = (): void => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const goToPrevPage = (): void => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    return (
        <div>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
            <p>
                Page {pageNumber} of {numPages}
            </p>
            <button onClick={goToPrevPage} disabled={pageNumber === 1}>
                Previous
            </button>
            <button onClick={goToNextPage} disabled={pageNumber === numPages}>
                Next
            </button>
        </div>
    )
}
export default PDFPreview
