import type { Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { DepartmentFormDataInterface } from '../Types/Department'
import type {
    TableListInterface,
    ListFrontEndInterface,
    ListBackendInterface,
} from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'
import {
    departmentDropDownLists,
    departmentLists,
} from '../Reducer/InitialReducer'

export const GetDepartmentList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/General/GetDepartmentList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: DepartmentFormDataInterface) => {
                        return {
                            ...value,
                            key: value.departmentId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(departmentLists({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const CheckDepartmentNameExist = (
    dispatch: Dispatch,
    id: string,
    clientCategoryId: string,
    name: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = [
        {
            fieldName: ' departmentName',
            compareOperator: 1,
            fieldValue1: name,
            fieldValue2: '',
        },
        {
            fieldName: 'departmentId',
            compareOperator: 1,
            fieldValue1: String(id),
            fieldValue2: '',
        },
        {
            fieldName: ' clientCategoryId',
            compareOperator: 1,
            fieldValue1: String(clientCategoryId),
            fieldValue2: '',
        },
    ]
    axios
        .post(`/api/ExistObjectData/3`, bodyData)
        .then((response) => {
            if (response.status === 200 && Boolean(response?.data?.isExist)) {
                ErrorMessage(response.data.error.message)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching post:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveDepartment = async (
    dispatch: Dispatch,
    data: DepartmentFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetDepartment`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const ActiveInactiveDepartment = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/8/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteDepartment = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/13/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDepartmentDropDownList = (
    dispatch: Dispatch,
    clientCategoryId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/26/${clientCategoryId}`)
        .then((response) => {
            if (response.status === 200) {
                const newDepartmentList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(departmentDropDownLists(newDepartmentList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
