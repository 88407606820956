import React, { useEffect } from 'react'
import { Form, Input, Row, Col, Button } from 'antd'
import { SaveClientCategory } from '../../../Services/CreateCatogry'
import { connect, useDispatch } from 'react-redux'
import {
    type saveClientCategory,
    type createCategoryParentStateInterface,
    type createcategoryProps,
} from '../../../Types/CreateCategory'
import { CheckclientcatergoryNameExist } from '../../../Services/Helpdesk/CreteTask'
import { SuccessMessage } from '../../../Utils/Notification'
import commontStyles from '../../../Utils/Common.less'
const CreateCategory = (props: createcategoryProps): React.ReactElement => {
    const {
        changeCreateEditModalStatus,
        clinetDynamicategory,
        clientParentCategoryDropDown,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    useEffect(() => {
        form.setFieldValue('clientDynCategoryId', '0')
    }, [])
    useEffect(() => {
        if (clinetDynamicategory !== '0') {
            const clientParent = clientParentCategoryDropDown?.find(
                (item) =>
                    item.clientDynCategoryId === Number(clinetDynamicategory)
            )
            form.setFieldValue('clientDynCategoryId', clinetDynamicategory)
            form.setFieldValue(
                'clientDynCategoryName',
                clientParent?.clientDynCategoryName
            )
            form.setFieldValue(
                'clientNoStartFrom',
                clientParent?.clientNoStartFrom
            )
        } else {
            form.setFieldValue('clientDynCategoryId', 0)
            form.setFieldValue('clientDynCategoryName', '')
            form.setFieldValue('clientNoStartFrom', 0)
        }
    }, [clinetDynamicategory])

    const handleOk = (): void => {
        const formValues = form.getFieldsValue()
        void SaveClientCategoryData(formValues)
    }

    const SaveClientCategoryData = async (
        data: saveClientCategory
    ): Promise<void> => {
        try {
            const categoryExists = await onNameChange()

            if (categoryExists === 'false') {
                if (clinetDynamicategory === '0') {
                    const result = await SaveClientCategory(dispatch, data)

                    if (result !== null && result !== undefined) {
                        // Save related child data
                        await SaveChildData(
                            result.data.clientDynCategoryId,
                            'Registered Clients',
                            0
                        )
                        await SaveChildData(
                            result.data.clientDynCategoryId,
                            'OnBoarding Clients',
                            0
                        )
                        await SaveChildData(
                            result.data.clientDynCategoryId,
                            'Prospect Clients',
                            0
                        )

                        SuccessMessage('Client Category Save Successfully')
                    }
                } else {
                    const datass = {
                        clientDynCategoryId: data.clientDynCategoryId,
                        clientDynCategoryName: data.clientDynCategoryName,
                        clientDynCategoryParentId: 0,
                        clientNoStartFrom: data.clientNoStartFrom,
                    }
                    await SaveClientCategory(dispatch, datass)

                    SuccessMessage('Client Category Update Successfully')
                    changeCreateEditModalStatus()
                }
                form.resetFields()
            }
        } catch (error) {
            console.error('Error saving client category data:', error)
        }
    }
    const SaveChildData = async (
        clientDynCategoryIdVar: number,
        childDataName: string,
        clientDynCategoryIds: number
    ): Promise<void> => {
        const dataToSend = {
            clientDynCategoryId: clientDynCategoryIds,
            clientDynCategoryParentId: clientDynCategoryIdVar,
            clientDynCategoryName: childDataName,
            clientNoStartFrom: '0',
        }

        try {
            const result = await SaveClientCategory(dispatch, dataToSend)

            if (result !== null && result !== undefined) {
                changeCreateEditModalStatus()
            }
        } catch (error) {
            console.error('Error saving child data:', error)
        }
    }

    const onNameChange = async (): Promise<string> => {
        const categoryName = form.getFieldValue('clientDynCategoryName') ?? ''

        try {
            const exists = await CheckclientcatergoryNameExist(
                dispatch,
                '0',
                categoryName
            )
            return exists
        } catch {
            return 'Error'
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Form
                layout="vertical"
                onFinish={handleOk}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="clientDynCategoryId" hidden={true}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="clientNoStartFrom" hidden={true}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Category Name"
                            name="clientDynCategoryName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the category name!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter category name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={20} span={4}>
                        <Button htmlType="submit" type="primary">
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const mapStateToProps = (state: any): createCategoryParentStateInterface => {
    return {
        clientParentCategoryDropDown:
            state.initial.clientParentCategoryDropDown,
        clientParentCategoryDropDownCount:
            state.initial.clientParentCategoryDropDownCount,
    }
}

export default connect(mapStateToProps)(CreateCategory)
