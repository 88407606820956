import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type clientReportPayloadActionData,
    type ClientReportsData,
    type MissReportsData,
    type MissingReportPayloadActionData,
    type ReportPayloadActionData,
    type ReportsData,
    type OnboardingReportsData,
    type onboardingPayloadActionData,
    type clientstausstusReportsData,
    type clienterviceReportPayloadActionData,
} from '../Types/Reports'
import {
    type ServiceDetailsReport,
    type monthwisereportPayloadActionData,
} from '../Types/Hr/Reports'

interface InitailStateInterface {
    reportStatusList: ReportsData | null
    missingreportStatusList: MissReportsData | null
    clientstausList: ClientReportsData | null
    onboardingstausList: OnboardingReportsData | null
    clientsericestausList: clientstausstusReportsData | null
    monthwiseList: ServiceDetailsReport[]
    monthwiseListCount: number
}

const Reports = createSlice({
    name: 'reports',
    initialState: {
        reportStatusList: null,
        missingreportStatusList: null,
        clientstausList: null,
        onboardingstausList: null,
        clientsericestausList: null,
        monthwiseList: [],
        monthwiseListCount: 0,
    },

    reducers: {
        onboardingstausList(
            state: InitailStateInterface,
            action: PayloadAction<onboardingPayloadActionData>
        ) {
            state.onboardingstausList = action?.payload?.data
        },
        clientsericestausList(
            state: InitailStateInterface,
            action: PayloadAction<clienterviceReportPayloadActionData>
        ) {
            state.clientsericestausList = action?.payload?.data
        },
        clientstausList(
            state: InitailStateInterface,
            action: PayloadAction<clientReportPayloadActionData>
        ) {
            state.clientstausList = action?.payload?.data
        },
        reportStatusList(
            state: InitailStateInterface,
            action: PayloadAction<ReportPayloadActionData>
        ) {
            state.reportStatusList = action?.payload?.data
        },
        missingreportStatusList(
            state: InitailStateInterface,
            action: PayloadAction<MissingReportPayloadActionData>
        ) {
            state.missingreportStatusList = action?.payload?.data
        },
        monthwiseList(
            state: InitailStateInterface,
            action: PayloadAction<monthwisereportPayloadActionData>
        ) {
            state.monthwiseList = action?.payload?.data
            state.monthwiseListCount = action?.payload?.noOfRecords
        },
    },
})

export const {
    reportStatusList,
    missingreportStatusList,
    clientstausList,
    onboardingstausList,
    clientsericestausList,
    monthwiseList,
} = Reports.actions
export default Reports.reducer
