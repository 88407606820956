import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'antd'
import styles from './login.less'
import type { LoginPinInterface } from '../../Types/Auth'
import Login from './login'
import LoginPin from './loginPin'

export interface successPintype {
    name: string
}

const SuccessPin = (props: successPintype): React.ReactElement => {
    const { name } = props

    const [openLoginPin, setLoginPinPage] = useState(false)
    const [loginPage, setLoginPage] = useState<boolean>(false)
    const backButtonClick = (): void => {
        if (name !== ' Reset Pin') {
            setLoginPage(true)
        } else {
            setLoginPinPage(true)
        }
    }

    if (loginPage) {
        return <Login />
    }
    if (openLoginPin) {
        return <LoginPin />
    }
    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>Thank you</div>
                            <div className={styles.loginTittleDesc}>
                                Check your Mail To {name}
                            </div>
                        </div>
                        <Button
                            className={styles.backButton}
                            onClick={backButtonClick}
                        >
                            Back to Sign In
                        </Button>
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): LoginPinInterface => {
    return {
        username: state.auth.username,
    }
}

export default connect(mapStateToProps)(SuccessPin)
