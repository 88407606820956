import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    type ProfileManageFormDataStateInterface,
    type ManageProfileFormData,
} from '../../Types/Profile'
import { GetCountryList } from '../../Services/Common'
import { type ListFrontEndInterface } from '../../Types/CommonType'
import dayjs from 'dayjs'
import S3Upload from '../../Components/S3Upload'

const ManageProfileForm = (
    props: ManageProfileFormData
): React.ReactElement => {
    const { onSave, editData, countryList } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')

    const setFormData = (name: string, location: string): void => {
        form.setFieldValue(name, location)
    }

    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    useEffect(() => {
        if (editData.dateOfBirth !== '') {
            setSelectedDate(
                dayjs(editData.dateOfBirth).add(1, 'day').format('YYYY-MM-DD')
            )
        }
    }, [editData.dateOfBirth])

    useEffect(() => {
        const countryData = countryList.find(
            (data: ListFrontEndInterface) => data.label === editData.country
        )
        const newData = {
            ...editData,
            country: countryData?.label ?? '',
            dateOfBirth:
                editData.dateOfBirth !== ''
                    ? dayjs(editData.dateOfBirth)
                    : null,
        }
        form.setFieldsValue(newData)
    }, [editData, countryList])

    useEffect(() => {
        if (selectedDate !== '') {
            form.setFieldValue('dateOfBirth', dayjs(selectedDate))
        }
    }, [selectedDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = dayjs(date).format('YYYY-MM-DD')
            setSelectedDate(isoDate)
        }
    }

    const resetForm = (): void => {
        form.resetFields()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    onFinish={(data) => {
                        onSave(data, resetForm)
                    }}
                    requiredMark={customizeRequiredMark}
                >
                    <Form.Item name="userId" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="User Name"
                                name="userName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your userName!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Country"
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your country!',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={countryList?.map((country) => ({
                                        value: country.label,
                                        label: country.label,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Address one"
                                name="addressOne"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input your addressOne!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Address Two" name="addressTwo">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Date" name="dateOfBirth">
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChange(date, dateString)
                                        }}
                                        value={
                                            selectedDate !== null
                                                ? dayjs(selectedDate)
                                                : null
                                        }
                                        className={commonStyles.dateWidth}
                                        format="YYYY-MM-DD"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Town" name="town">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Post Code"
                                name="postCode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your postCode!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Contact Number"
                                name="contactNo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your contactNo!',
                                    },
                                    {
                                        pattern: /^\d{10,15}$/,
                                        message:
                                            'Mobile Number must be 10-15 digits!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Profile Image" name="imageurl">
                                <S3Upload
                                    name={'imageurl'}
                                    value={form.getFieldValue('imageurl')}
                                    setFormData={setFormData}
                                    corporate={''}
                                    corporateid={0}
                                    documenttype={''}
                                    documentbaseId={0}
                                    memberpersonId={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): ProfileManageFormDataStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(ManageProfileForm)
