export interface CH_CompanyDetailsView {
    companyId: number
    companyType: string
    dateofResolution: string
    isCompanyNameHasResolved: number
    companyName: string
    dateOfIncorporation: string
    marketPeriod: string
    regAddress1: string
    regAddress2: string
    regTown: string
    regCountry: string
    regPostCode: string
    sicCode1: string
    sicCode2: string
    sicCode3: string
    sicCode4: string
}
export interface CompanyOfficerInformation {
    altEmailaddress: any
    referenceId: number
    type: string
    titleOrCompanyName: string
    firstNameOrBRNo: string
    middleName: string
    surName: string
    dateOfBirth: string
    addressLineOne: string
    addressLineTwo: string
    town: string
    country: string
    postCode: string
    corrAddressLineOne: string
    corrAddressLineTwo: string
    corrTown: string
    corrCountry: string
    corrPostCode: string
    mobileNo: string
    homeNo: string
    workNo: string
    email: string
    alternativeEmail: string
    niNumber: string
    nationality: string
    occupation: string
    countryOfResidence: string
    memberRoleId: number
    dateofAppointment: string
    memberTypeId: number
    officerRole: string
}

export interface CS_ShareCapitalDetail {
    shareCapitalDetailId: number
    shareCapitalId: number
    shareClass: string
    noofShares: number
    aggregateNominalValue: number
    particulars: string
    currencyName: string
}

export interface CS_ShareCapital {
    shareCapitalId: number
    companyId: number
    corporateId: number
    currencyId: number
    currencyName: string
    totalSharesIssued: number
    totalAggregateValue: number
    totalAmountUnpaid: number
    isSubmitToCH: number
    submittedDate: string // Ideally use `Date` type if possible
    cS_ShareCapitalDetailList: CS_ShareCapitalDetail[]
}
export interface SubmissionInfo {
    submissionTypeId: number
    submissionType: string
    lastUpdateDate: string
    lastUpdateBy: string
    referenceId: number
    corporateId: number
}
export interface CS_Submission {
    submissionId: number
    submissionNumber: string
    submissionDate: string
    submittedBy: string
    recentModificationsList: SubmissionInfo[]
}

export interface CS_ShareHolder {
    companyReferenceId: number
    companyId: number
    corporateId: number
    divisionType: number
    memberType: number
    name: string
    shareClass: string
    noofShares: number
    transferDate: string // Ideally use `Date` type if possible
}
export interface CS_PSC {
    pscId: number
    pscType: number
    companyId: number
    corporateId: number
    title: string
    firstName: string
    middleName: string
    surName: string
    dateofBirth: string
    nationality: string
    countryofResidence: string
    isSameasOffice: number
    corrAddress1: string
    corrAddress2: string
    corrTown: string
    corrCountry: string
    corrPostcode: string
    isSameasCorrespondance: number
    resAddress1: string
    resAddress2: string
    resTown: string
    resCountry: string
    resPostcode: string
    disclosureExemptions: number
    personHoldsShares: number
    firmHoldShares: number
    trustHoldShares: number
    personHoldsVotingRights: number
    firmHoldVotingRights: number
    trustHoldVotingRights: number
    isRighttoAppointorRemovebyPerson: number
    isRighttoAppointorRemovebyFirm: number
    isRighttoAppointorRemovebyTrust: number
    hasSignificantInfluencebyPerson: number
    hasSignificantInfluencebyFirm: number
    hasSignificantInfluencebyTrust: number
    notificationDate: string
    registerEntryDate: string
    isSubmitToCH: number
    llpName: string
    buildingNumber: string
    legalFormoftheEntity: string
    lawwhichgovernsthisEntity: string
}
export interface CsSailAddress {
    sailAddressId: number
    companyId: number
    corporateId: number
    houseNumber: string
    poBoxNumber: string
    address1: string
    address2: string
    town: string
    region: string
    country: string
    postCode: string
    isSubmitToCh: number
    submittedDate: string // Ideally use `Date` type if possible
}

export interface CorporateCHInfo {
    corporateId: number
    clientName: string
    clientNumber: string
    companyRegNumber: string
    statementDate: string
    isFileWithChanges: number
    clientTypeId: number
    cH_CompanyDetailsView: CH_CompanyDetailsView | null
    companyOfficerInformationList: CompanyOfficerInformation[] | null
    cS_SailAddress: CsSailAddress | null
    cS_ShareCapitalList: CS_ShareCapital[] | null
    cS_ShareHolderList: CS_ShareHolder[] | null
    cS_PSCList: CS_PSC[] | null
    cS_Submisssion: CS_Submission
    reviewDate: string
}

export interface CompanyHouseCorparePayloadIntreface {
    data: CorporateCHInfo
}

export interface CompanyHouseOverviewSteteIntrface {
    companyHouseOverViewList: CorporateCHInfo
}

export interface CompanyAddress {
    companyId: number
    corporateId: number
    houseNumber: string
    postCode: string
    address1: string
    address2: string
    town: string
    region: string
    country: string
    poBoxNumber: string
    isSubmitToCH: number
}

export interface CompanyResolution {
    companyId: number
    corporateId: number
    companyName: string
    isCompanyNamesubmittoCH: number
    dateofResolution: string // Use Date type if you plan to handle it as a Date object
    isCompanyNameHasResolved: number
}

export interface OfficerDetails {
    companyReferenceId: number
    companyId: number
    corporateId: number
    typeId: number
    officerName: string
    officerRole: string
    dateofAppointment: string
    resignationDate: string
    isResignationSubmitToCH: number
    additionalDataOne: number
    additionalDataTwo: number
    additionalDataThree: number
}

export interface PSCDetails {
    pscId: number
    corporateId: number
    companyId: number
    companyName: string
    companyRegNumber: string
    pscTypeId: number
    pscTypeName: string
    additionalDataOne: string
    additionalDataTwo: string
    additionalDataThree: string
    additionalDataFour: string
    additionalDataFive: string
    dateofBirth: string
    ceasedPSCDate: string
    registerEntryDate: string
    isCeasedSubmitToCH: number
}
export interface CompanyPersonDetailsSaveData {
    companyPersonId: number
    companyId: number
    corporateId: number
    divisiontype: number
    membertype: number
    title: string
    firstname: string
    middlename: string
    surname: string
    dateofbirth: string
    address1: string
    address2: string
    town: string
    country: string
    postcode: string
    corrAddress1: string
    corrAddress2: string
    corrTown: string
    corrCountry: string
    corrPostcode: string
    mobilenumber: string
    homenumber: string
    worknumber: string
    emailaddress: string
    altEmailaddress: string
    ninumber: string
    nationality: string
    occupation: string
    countryofresidence: string
    dateofappointment: string
    isConsenttoAct: number
    isSubmitToCH: number
}

export interface CompanyPersonDetails {
    companypersonid: string
    corporateid: string
    companyid: string
    divisiontype: string
    membertype: string
    title: string
    firstname: string
    middlename: string // Optional as per the provided data
    surname: string
    dateofbirth: string // Consider using a Date type if working with dates
    nationality: string
    occupation: string
    countryofresidence: string
    dateofappointment: string // Consider using a Date type if working with dates
    address1: string
    address2: string
    town: string
    country: string
    postcode: string
    corraddress1: string
    corraddress2: string
    corrtown: string
    corrcountry: string
    corrpostcode: string
    isconsenttoact: number // Could also use boolean if true/false
    issubmittocH: number // Could also use boolean if true/false
}
export interface CompanyCorporateDetails {
    companyCorporateId: number
    companyId: number
    corporateId: number
    divisionType: string
    memberType: number
    companyName: string
    companyNumber: string
    corpAddress1: string
    corpAddress2: string
    corpTown: string
    corpCountry: string
    corpPostCode: string
    dateofappointment: string
    companyRegisteredIn: string
    isUKLimitedCompany: number
    legalForm: number
    lawGoverns: number
    isConsenttoAct: number
    isSubmitToCH: number
}

export const defaultPSCInfo = {
    pscId: 0,
    pscType: 0,
    companyId: 0,
    corporateId: 0,
    title: '',
    firstName: '',
    middleName: '',
    surName: '',
    dateofBirth: '',
    nationality: '',
    countryofResidence: '',
    isSameasOffice: 0,
    corrAddress1: '',
    corrAddress2: '',
    corrTown: '',
    corrCountry: '',
    corrPostcode: '',
    isSameasCorrespondance: 0,
    resAddress1: '',
    resAddress2: '',
    resTown: '',
    resCountry: '',
    resPostcode: '',
    disclosureExemptions: 0,
    personHoldsShares: 0,
    firmHoldShares: 0,
    trustHoldShares: 0,
    personHoldsVotingRights: 0,
    firmHoldVotingRights: 0,
    trustHoldVotingRights: 0,
    isRighttoAppointorRemovebyPerson: 0,
    isRighttoAppointorRemovebyFirm: 0,
    isRighttoAppointorRemovebyTrust: 0,
    hasSignificantInfluencebyPerson: 0,
    hasSignificantInfluencebyFirm: 0,
    hasSignificantInfluencebyTrust: 0,
    notificationDate: '',
    registerEntryDate: '',
    isSubmitToCH: 0,
    llpName: '',
    buildingNumber: '',
    legalFormoftheEntity: '',
    lawwhichgovernsthisEntity: '',
}

export interface CompanyDetailsprops {
    companyHousId: number
    pageIdentyFyId?: number
    companyHouseOverViewList: CorporateCHInfo
    businessId?: number
    editId: number
}
export interface CompanyShareholder {
    companyReferenceId: number
    divisionType: number
    companyId: number
    corporateId: number
    shareClass: number
    noofShares: number
    additionalDataOne: string
    additionalDataTwo: string
    additionalDataThree: string
    additionalDataFour: string
    additionalDataFive: string
}

export interface csSubmitData {
    corporateId: number
    isFileWithChanges: number
    submissionTypeIds: string
    reviewDate: string
}
export interface CompanyOverview {
    companyId: number
    corporateId: number
    sicCode1: number
    sicCode2: number
    sicCode3: number
    sicCode4: number
    isSubmitToCH: number
}

export interface ShareCapital {
    shareCapitalId: number
    companyId: number
    corporateId: number
    currencyId: number
    shareClass: string
    noofShares: number
    aggregateNominalValue: number
    particulars: string
}

export interface setCompanyhouse {
    companyNumber: string
    tabIndex: number
    clientTypeId: number
}

export interface ShareHolderDetails {
    companyReferenceId: number
    divisionType: number
    companyName: string
    companyNumber: string
    shareHolderName: string
    shareClass: string
    sharesAvailable: string
    noofShares: number
    transferDate: string // Consider using `Date` if you want to handle date objects instead of strings
}

export interface SetCompanyDetailsData {
    companyId: number
    corporateId: number
    companyName: string
    isCompanyNamesubmittoCH: number
    dateofResolution: string
    isCompanyNameHasResolved: number
}

export interface CompanyCorporateShareHolderDetails {
    referenceId: number
    title: string
    firstName: string
    middleName: string
    surName: string
    companyName: string
    companyNumber: string
    address1: string
    address2: string
    town: string
    country: string
    postCode: string
}
