import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import CommonButton from '../../../Components/Commonbutton'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import PerformInvoice from './PerformInvoice'
import ArchivedInvoice from './ArchivedInvoice'
import { type InvoiceStateInterface } from '../../../Types/Invoicing/Invoice'

const InvoicePreparation = (
    props: InvoiceStateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { categoryDropDownList, serviceDropDownList } = props
    const [activeButton, setActiveButton] = useState(1)
    const [showCoporateList, setShowCoporateList] = useState<boolean>(true)
    const [showSoleTraderList, setShowSoleTrader] = useState<boolean>(true)
    const [selectedId, setSelectedId] = useState(0)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        setShowSoleTrader(true)
    }, [])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 0 : Number(value))
    }

    const handleSelectChange1 = (value: string | number): void => {
        setSelectedServiceId(value === 'all' ? 0 : Number(value))
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setShowCoporateList(true)
            setShowSoleTrader(false)
            setSelectedId(0)
            setSelectedServiceId(0)
        }
        if (buttonName === 2) {
            setShowSoleTrader(true)
            setShowCoporateList(false)
            setSelectedId(0)
            setSelectedServiceId(0)
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return (
                <PerformInvoice
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        } else if (activeButton === 2) {
            return (
                <ArchivedInvoice
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        return <></>
    }

    return (
        <>
            <Card title="Invoice Preparation" className={commonStyles.card}>
                <Select
                    className={commonStyles.topdropdown2}
                    placeholder="Please select"
                    onChange={handleSelectChange}
                    value={selectedId === 0 ? 'all' : selectedId}
                    options={[
                        { value: 'all', label: 'All' },
                        ...categoryDropDownList,
                    ]}
                />
                <Select
                    className={commonStyles.topdropdown}
                    placeholder="Please select"
                    onChange={handleSelectChange1}
                    value={selectedServiceId === 0 ? 'all' : selectedServiceId}
                    options={[
                        { value: 'all', label: 'All' },
                        ...serviceDropDownList,
                    ]}
                />
                <CommonButton
                    name="Perform Invoice"
                    isActive={activeButton === 1}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(1)
                    }}
                />
                <CommonButton
                    name="Archived Invoice"
                    isActive={activeButton === 2}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(2)
                    }}
                />
                {showCoporateList && activeButton === 1 && (
                    <PerformInvoice
                        selectedCategoryId={selectedId}
                        selectedServiceId={selectedServiceId}
                    />
                )}
                {showSoleTraderList && activeButton === 2 && (
                    <ArchivedInvoice
                        selectedCategoryId={selectedId}
                        selectedServiceId={selectedServiceId}
                    />
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): InvoiceStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
    }
}

export default connect(mapStateToProps)(InvoicePreparation)
