import { type ReportHandler } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
    if (onPerfEntry !== null && onPerfEntry instanceof Function) {
        import('web-vitals')
            .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
                getCLS(onPerfEntry)
                getFID(onPerfEntry)
                getFCP(onPerfEntry)
                getLCP(onPerfEntry)
                getTTFB(onPerfEntry)
            })
            .catch((error) => {
                // Handle any potential errors here
                console.error('Error loading web-vitals:', error)
            })
    }
}

export default reportWebVitals
