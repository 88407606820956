import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import VatEditPage from './VatEditPage'
import {
    type VatData,
    type VatViewPagePropData,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

export const VatDefultData = {
    vatId: 0,
    companyType: 0,
    referenceId: 0,
    vatNumber: '',
    vatScheme: '',
    vatRegistrationDate: '01/01/1900',
    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    effectiveDate: '01/01/1900',
    endingDate: '01/01/1900',
    dataInput: '',
    status: '',
    hmrcGatewayId: '',
    hmrcPassword: '',
    vatFrequency: 0,
    vatFrequencyName: '',
}
const VATViewPage = (props: VatViewPagePropData): React.ReactElement => {
    const { VatServiceData, partnershipId, datareFetch } = props
    const [isEditMode, setIsEditMode] = useState(false)
    const [editData, setEditData] = useState<VatData>(VatDefultData)
    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(VatServiceData ?? VatDefultData)
    }

    const handleChildFormSave = (): void => {
        datareFetch()
        setIsEditMode(false)
    }

    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${day}/${month}/${year}`
            return formattedDate
        }
    }
    return (
        <>
            <>
                <Row gutter={16} justify="end">
                    <Col>
                        <Button type="primary" onClick={handleEditClick}>
                            {isEditMode ? (
                                <EyeOutlined />
                            ) : (
                                ActivePremission(
                                    '3000',
                                    PermissionType.UPDATE
                                ) && <EditOutlined />
                            )}
                        </Button>
                    </Col>
                </Row>
                {isEditMode ? (
                    <div>
                        <VatEditPage
                            partnershipId={partnershipId}
                            vateditdata={editData}
                            handleChildFormSave={handleChildFormSave}
                        />
                    </div>
                ) : (
                    <Row gutter={[24, 8]}>
                        <Col span={6}>
                            <p>VAT Number</p>
                            <p>VAT Scheme</p>
                            <p>VAT Registration Date</p>
                            <p>VAT Effective Date</p>
                            <p>Period Ending Date</p>
                            <p>HMRC Gateway ID</p>
                            <p>VAT Frequency</p>
                        </Col>
                        <Col span={6}>
                            <p>: {VatServiceData?.vatNumber}</p>

                            <p>: {VatServiceData?.vatScheme}</p>
                            <p>
                                :{' '}
                                {formatdate(
                                    VatServiceData?.vatRegistrationDate
                                )}
                            </p>
                            <p>: {formatdate(VatServiceData?.effectiveDate)}</p>
                            <p>: {formatdate(VatServiceData?.endingDate)}</p>
                            <p>: {VatServiceData?.hmrcGatewayId}</p>
                            <p>: {VatServiceData?.vatFrequency}</p>
                        </Col>
                        <Col span={5}>
                            <p>Address 1</p>
                            <p>Address 2</p>
                            <p>Town</p>
                            <p>Country</p>
                            <p>Post Code</p>
                            <p>Password</p>
                            <p>Data Input</p>
                        </Col>
                        <Col span={7}>
                            <p>: {VatServiceData?.addressLineOne}</p>
                            <p>: {VatServiceData?.addressLineTwo}</p>
                            <p>: {VatServiceData?.town}</p>
                            <p>: {VatServiceData?.country}</p>
                            <p>: {VatServiceData?.postCode}</p>
                            <p>: {VatServiceData?.hmrcPassword}</p>
                            <p>: {VatServiceData?.dataInput}</p>
                        </Col>
                    </Row>
                )}
            </>
        </>
    )
}

export default VATViewPage
