import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { staffDesignationList } from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { StaffDesignationFormDataInterface } from '../Types/StaffDesignations'
import type { TableListInterface } from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'

export const GetStaffDesignationList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/General/GetDesignationList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: StaffDesignationFormDataInterface) => {
                        return {
                            ...value,
                            key: value.designationId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(staffDesignationList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const CheckStaffDesignationNameExist = (
    dispatch: Dispatch,
    id: string,
    departmentId: string,
    clientCategoryId: string,
    name: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = [
        {
            fieldName: 'designationName',
            compareOperator: 1,
            fieldValue1: name,
            fieldValue2: '',
        },
        {
            fieldName: 'designationId',
            compareOperator: 1,
            fieldValue1: String(id),
            fieldValue2: '',
        },
        {
            fieldName: 'departmentId',
            compareOperator: 1,
            fieldValue1: String(departmentId),
            fieldValue2: '',
        },
        {
            fieldName: 'clientCategoryId',
            compareOperator: 1,
            fieldValue1: String(clientCategoryId),
            fieldValue2: '',
        },
    ]
    axios
        .post(`/api/ExistObjectData/4`, bodyData)
        .then((response) => {
            if (response.status === 200 && Boolean(response?.data?.isExist)) {
                ErrorMessage(response.data.error.message)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching post:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveStaffDesignation = async (
    dispatch: Dispatch,
    data: StaffDesignationFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetDesignation`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const ActiveInactiveStaffDesignation = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/9/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteStaffDesignation = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/14/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
