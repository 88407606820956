import { Col, Row, Input, Form, Card, Select, Button } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import corporateNewStyle from '../../../../Less/Clients/RegisteredClients/Corporate/CorporateNew.less'
import { SyncCompanyHouse } from '../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { GetClientTypeDropDownList } from '../../../../Services/GroupAndPartners'
import type {
    ClientData,
    CorporateNewStateInterface,
} from '../../../../Types/Client/RegisteredClients/Corporate'
import { useNavigate } from 'react-router-dom'
import { SyncOutlined } from '@ant-design/icons'
const CorporateNew = (props: any): React.ReactElement => {
    const { clientTypeDropDownList } = props
    const dispatch = useDispatch()
    const [companyNumber, setCompanyHouseNumber] = useState('1')
    const [tabIndex] = useState(1)
    const [clientTypeId, setClientTypeId] = useState(1)
    const [companyHouseUrl, setCompanyHouseUrl] = useState(
        'https://find-and-update.company-information.service.gov.uk/company/undefined'
    )
    const [form] = Form.useForm()
    const navigate = useNavigate()
    useEffect(() => {
        GetClientTypeDropDownList(dispatch)
    }, [])

    const Synchronize = async (): Promise<void> => {
        try {
            await form.validateFields()
            const data = await SyncCompanyHouse(dispatch, {
                companyNumber,
                tabIndex,
                clientTypeId,
            })
            viewCorporateDetails(data)
        } catch (error) {}
    }

    const viewCorporateDetails = (data: ClientData): void => {
        localStorage.removeItem('corporateData')
        localStorage.removeItem('onbordingId')
        const corporateData = {
            corporateid: data?.initialDetails?.corporateid,
            companyname: data?.initialDetails?.companyname,
            companyregisternumber: data?.initialDetails?.companyregnumber,
            clientnumber: data?.initialDetails?.clientnumber,
            clienttype: data?.initialDetails?.clienttype,
            activestatus: '',
            emailAddress: '',
            contactName: '',
            mobilenumber: '',
            alternativeEmailAddress: '',
        }
        const onbordingId = 0
        localStorage.setItem('corporateData', JSON.stringify(corporateData))
        localStorage.setItem('onbordingId', onbordingId.toString())
        navigate(`/clients/registered-clients/corporate-view`)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onChangeRegistrationNumber = (e: any): void => {
        const value = e.target.value
        setCompanyHouseNumber(value)
        const url = `https://find-and-update.company-information.service.gov.uk/company/${value}`
        setCompanyHouseUrl(url)
    }

    const onClientTypeChange = (value: number): void => {
        setClientTypeId(value)
    }
    return (
        <>
            <div>
                <h1>Corporate Registration</h1>
            </div>
            <Card title="Corporate Registration" className={commonStyles.card}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Type" name="clientType">
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        onClientTypeChange(value)
                                    }}
                                    options={clientTypeDropDownList}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' &&
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Client No" name="clientNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label="Registration Number"
                                name="registrationNumber"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter registration number',
                                    },
                                ]}
                            >
                                <Input onChange={onChangeRegistrationNumber} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className={corporateNewStyle.synchronize}
                            >
                                <Button
                                    icon={<SyncOutlined />}
                                    onClick={() => {
                                        void Synchronize()
                                    }}
                                    type="primary"
                                >
                                    {' '}
                                    Synchronize
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Company Name" name="companyName">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <a
                        href={companyHouseUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={corporateNewStyle.companyHouseLink}
                    >
                        Link To Company House
                    </a>
                </Form>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): CorporateNewStateInterface => {
    return {
        clientTypeDropDownList: state.client.clientTypeDropDownList,
    }
}

export default connect(mapStateToProps)(CorporateNew)
