import React from 'react'
import moment from 'moment'
import style from './dairyHeder.less' // Import your CSS module

const CustomToolbar: React.FC<any> = (props) => {
    const { label, onNavigate, view, views, onView } = props

    const formattedLabel =
        view === 'agenda'
            ? label
                  .split(' – ')
                  .map((date: moment.MomentInput) =>
                      moment(date, 'MM/DD/YYYY').format('DD/MM/YYYY')
                  )
                  .join(' - ')
            : label

    return (
        <div className={style.rbc_toolbar}>
            <div className={style.buttonGroup}>
                {' '}
                {/* Container for Today/Back/Next */}
                <button onClick={() => onNavigate('TODAY')}>Today</button>
                <button onClick={() => onNavigate('PREV')}>Back</button>
                <button onClick={() => onNavigate('NEXT')}>Next</button>
            </div>
            <span>{formattedLabel}</span>

            <span>
                <div className={style.buttonGroup}>
                    {views.map((v: any) => (
                        <button
                            key={v}
                            onClick={() => onView(v)}
                            className={view === v ? style.active : ''} // Use style.active
                        >
                            {v.charAt(0).toUpperCase() + v.slice(1)}
                        </button>
                    ))}
                </div>
            </span>
        </div>
    )
}

export default CustomToolbar
