import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    List,
    Flex,
    Pagination,
    Spin,
    Empty,
    Avatar,
    Tooltip,
    Button,
    Tag,
    Modal,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './Itindex.less'
import { PlusOutlined } from '@ant-design/icons'
import { ReactComponent as ItemLinkIcon } from '../../../Assest/Svg/MissingInfoTittleIcon.svg'
import { GetITTaskDetails } from '../../../Services/ITDashboard'
import { connect, useDispatch } from 'react-redux'
import {
    type ITTask,
    type ITTaskStateInteface,
} from '../../../Types/ITDashbord'
import { useNavigate } from 'react-router-dom'
import CreateTask from '../../../Components/CreateTask'

interface Priority {
    priorityId: number
    priorityName: string
    colorCode: string
}

// List of priorities with color codes
const priorityList: Priority[] = [
    { priorityId: 1, priorityName: 'Low', colorCode: '#28a745' }, // Green
    { priorityId: 2, priorityName: 'Medium', colorCode: '#ffc107' }, // Yellow
    { priorityId: 3, priorityName: 'High', colorCode: '#dc3545' }, // Red
    { priorityId: 4, priorityName: 'Critical', colorCode: '#6610f2' }, // Purple
]
interface assiginstatus {
    statusId: number
    statusName: string
    colorCode: string
}

// List of priorities with color codes
const assiginstatusList: assiginstatus[] = [
    { statusId: 0, statusName: 'To Do', colorCode: '#3f6bef' }, // Green
    { statusId: 1, statusName: 'In Progress', colorCode: '#7d69e9' }, // Yellow
    { statusId: 2, statusName: 'Re open', colorCode: '#a1262e' }, // Red
    { statusId: 3, statusName: 'Completed', colorCode: '#79ddc0' }, // Purple
]
const ProjectStatusList = (props: ITTaskStateInteface): React.ReactElement => {
    const { ITtakslist } = props
    const [data, setData] = useState<ITTask[]>([])
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState(0)
    const [noOfRecords, setNoOfRecords] = useState(0)
    const navigate = useNavigate()
    const [activeTabType, setActiveTabType] = useState(0)
    const [taskmodelOpen, setTsakModelOpen] = useState(false)
    const handleTabClickTaskType = (tab: number): void => {
        setActiveTabType(tab)
    }
    const [activeTab, setActiveTab] = useState(0)
    const [resetdata, setResetData] = useState('false')
    const handleTabClick = (tab: number): void => {
        setActiveTab(tab)
        setPageNumber(1)
    }
    const pageSize = 10

    useEffect(() => {
        if (activeTabType !== 0) {
            GetITTaskDetails(dispatch, activeTabType)
        }
        setPageNumber(1)
    }, [activeTabType])
    useEffect(() => {
        setLoading(true)
        const updatelist = ITtakslist?.filter(
            (item) => item.statusId === activeTab
        )

        const startIndex = (pageNumber - 1) * pageSize
        const paginatedData = updatelist.slice(
            startIndex,
            startIndex + pageSize
        )

        setData(paginatedData)
        setNoOfRecords(updatelist.length)
        setLoading(false)
    }, [activeTab, pageNumber, ITtakslist])

    const getassignToDetails = (
        statusId: number
    ): { statusName: string; colorCode: string } | null => {
        const assingTolist = assiginstatusList.find(
            (p) => p.statusId === statusId
        )

        return assingTolist !== null && assingTolist !== undefined
            ? {
                  statusName: assingTolist?.statusName,
                  colorCode: assingTolist?.colorCode,
              }
            : null
    }

    const getPriorityDetails = (
        priorityId: number
    ): { priorityName: string; colorCode: string } | null => {
        const priority = priorityList.find((p) => p.priorityId === priorityId)
        return priority !== null && priority !== undefined
            ? {
                  priorityName: priority?.priorityName,
                  colorCode: priority?.colorCode,
              }
            : null
    }

    const generateMembersarray = (
        memberUrlsString: string
    ): Array<{
        profileUrl: string
        memberName: string
    }> => {
        return memberUrlsString?.split(',')?.map((url, index) => ({
            profileUrl: url,
            memberName: `Member ${index + 1}`, // Customize memberName if you have the data
        }))
    }
    const openTaskModel = (): void => {
        setTsakModelOpen(!taskmodelOpen)
        setResetData('true')
    }
    const CloseTaskModel = (): void => {
        setTsakModelOpen(!taskmodelOpen)
    }
    return (
        <div className={styles.marginRight10}>
            <Card
                className={`${styles.card}`}
                title={
                    <div className={styles.myTask__title}>
                        <span
                            className={`${styles.tab} ${activeTabType === 0 ? styles.active : ''}`}
                            onClick={() => {
                                handleTabClickTaskType(0)
                            }}
                        >
                            My Tasks
                        </span>
                        {' / '}
                        <span
                            className={`${styles.tab} ${activeTabType === 1 ? styles.active : ''}`}
                            onClick={() => {
                                handleTabClickTaskType(1)
                            }}
                        >
                            Follow
                        </span>
                    </div>
                }
            >
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={20}>
                            <Flex gap={25}>
                                <div
                                    className={`${styles.projectstaus__list__title} ${activeTab === 0 ? styles.active : ''}`}
                                    onClick={() => {
                                        handleTabClick(0)
                                    }}
                                >
                                    To DO
                                </div>
                                <div
                                    className={`${styles.projectstaus__list__title} ${activeTab === 1 ? styles.active : ''}`}
                                    onClick={() => {
                                        handleTabClick(1)
                                    }}
                                >
                                    In Progress
                                </div>
                                <div
                                    className={`${styles.projectstaus__list__title} ${activeTab === 2 ? styles.active : ''}`}
                                    onClick={() => {
                                        handleTabClick(2)
                                    }}
                                >
                                    Re-Open
                                </div>
                                <div
                                    className={`${styles.projectstaus__list__title} ${activeTab === 3 ? styles.active : ''}`}
                                    onClick={() => {
                                        handleTabClick(3)
                                    }}
                                >
                                    Complete
                                </div>
                            </Flex>
                        </Col>
                        <Col span={4} className={styles.marginTop5}>
                            <Flex justify={'space-evenly'} align={'center'}>
                                <div
                                    className={styles.project__viewallcomplate}
                                >
                                    <Button
                                        icon={<PlusOutlined />}
                                        type="primary"
                                        size="small"
                                        onClick={openTaskModel}
                                    />
                                </div>
                                <div className={styles.project__viewall}>
                                    {' '}
                                    <div
                                        onClick={() => {
                                            navigate('/help-desk/create-task')
                                        }}
                                    >
                                        View All
                                    </div>
                                </div>
                            </Flex>
                        </Col>
                    </Row>

                    {/* <Divider style={{ margin: '10px 0px' }} /> */}

                    {isLoading ? (
                        <Flex
                            align="center"
                            justify="center"
                            style={{ padding: '20px 0px' }}
                        >
                            <Spin />
                        </Flex>
                    ) : (
                        <>
                            {data.length > 0 ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <List>
                                                <VirtualList
                                                    data={data}
                                                    height={600}
                                                    itemKey="serviceProcessId"
                                                    className={
                                                        styles.todo__list__main
                                                    }
                                                >
                                                    {(
                                                        item: ITTask,
                                                        index: number
                                                    ) => (
                                                        <List.Item key={index}>
                                                            <Row
                                                                className={
                                                                    styles.todo__list__main__item__main
                                                                }
                                                            >
                                                                <Col span={24}>
                                                                    <Row>
                                                                        <Col
                                                                            span={
                                                                                20
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.todo__list__main__item__main__tittle
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.taskName
                                                                                }
                                                                                &nbsp;&nbsp;
                                                                                <ItemLinkIcon />
                                                                            </div>
                                                                        </Col>
                                                                        <Col
                                                                            span={
                                                                                4
                                                                            }
                                                                        >
                                                                            <Flex
                                                                                justify={
                                                                                    'flex-end'
                                                                                }
                                                                            >
                                                                                <Tag
                                                                                    className={
                                                                                        styles.projectstaus__list__item__tag__one
                                                                                    }
                                                                                    color={
                                                                                        getPriorityDetails(
                                                                                            item.priorityId
                                                                                        )
                                                                                            ?.colorCode
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        getPriorityDetails(
                                                                                            item.priorityId
                                                                                        )
                                                                                            ?.priorityName
                                                                                    }
                                                                                </Tag>

                                                                                <Tag
                                                                                    className={
                                                                                        styles.projectstaus__list__item__tag__one
                                                                                    }
                                                                                    color={
                                                                                        getassignToDetails(
                                                                                            item.statusId
                                                                                        )
                                                                                            ?.colorCode
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        getassignToDetails(
                                                                                            item.statusId
                                                                                        )
                                                                                            ?.statusName
                                                                                    }
                                                                                </Tag>
                                                                            </Flex>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col
                                                                            span={
                                                                                24
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.todo__list__second__item__tittle
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.description
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col
                                                                            span={
                                                                                6
                                                                            }
                                                                            className={
                                                                                styles.todo__list__third__item__first__tittle
                                                                            }
                                                                        >
                                                                            <Row>
                                                                                <Col
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                    }}
                                                                                >
                                                                                    Assign
                                                                                    by:
                                                                                </Col>
                                                                                <Col>
                                                                                    {' '}
                                                                                    <Avatar.Group
                                                                                        max={{
                                                                                            count: 2,
                                                                                            style: {
                                                                                                color: '#f56a00',
                                                                                                backgroundColor:
                                                                                                    '#fde3cf',
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {generateMembersarray(
                                                                                            item.assignByImageUrl
                                                                                        )?.map(
                                                                                            (
                                                                                                datass,
                                                                                                index
                                                                                            ) => (
                                                                                                <Tooltip
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                    title={
                                                                                                        datass.memberName
                                                                                                    }
                                                                                                >
                                                                                                    <Avatar
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        src={
                                                                                                            datass.profileUrl
                                                                                                        }
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            )
                                                                                        )}
                                                                                    </Avatar.Group>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col
                                                                    span={5}
                                                                    className={
                                                                        styles.projectstaus__last__item__main
                                                                    }
                                                                ></Col>
                                                            </Row>
                                                        </List.Item>
                                                    )}
                                                </VirtualList>
                                            </List>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            width: '100%',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Col
                                            span={24}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'stretch',
                                            }}
                                        >
                                            <Pagination
                                                defaultCurrent={pageNumber}
                                                total={noOfRecords}
                                                onChange={(e) => {
                                                    setPageNumber(e)
                                                }}
                                                pageSize={10}
                                                showSizeChanger={false}
                                                showQuickJumper={false}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Empty
                                    style={{ padding: '40px 0px' }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>No tasks to complete.</span>
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            </Card>
            <Modal
                footer={null}
                onCancel={CloseTaskModel}
                width={'60%'}
                title={'Create Task'}
                open={taskmodelOpen}
            >
                <CreateTask
                    onClose={CloseTaskModel}
                    resetdata={resetdata}
                    setResetData={setResetData}
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): ITTaskStateInteface => {
    return {
        ITtakslist: state.itdashboardInfo.ITTaskList,
    }
}

export default connect(mapStateToProps)(ProjectStatusList)
