import React, { useEffect, useRef, useState } from 'react'
import { Row, Col } from 'antd'
import Welcome from './welcome'
import HeadLines from './headlines'
import MissingInfo from './missingInfo'
import styles from './index.less'
import InvoiceCreditControl from './invoiceCreditControl'
import ToFollow from './toFollow'
import StaffProgress from './staffProgress'
import Calender from './calender'
import MyTask from './myTask'
import MobileRequest from './mobileRequest'
import ToApprove from './toApprove'
import { useDispatch, connect, useSelector } from 'react-redux'
import { GetDashboard } from '../../Services/Dashboard'
import type { InitialStateInterface } from '../../Types/Dashboard'
import { GetClientOverview } from '../../Services/Overview'
import { GetEventListByUserId } from '../../Services/Event'
import ToDOClients from './clients2'
import ITDashBoard from './ITDepartmetDashbord/ItDashbord'
import BPOotherDashboard from './BPO and OtherClinet Dashbord/BPOandOthersDashbord'
import Todo from './todo'
const DashBoard = (props: InitialStateInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const departmentId = useSelector(
        (state: any) => state.dashboardFlag.departmentId
    )
    const getFlag = useSelector((state: any) => state.dashboardFlag.flag)
    const [, setIsLoadingModal] = useState(false)
    const token = localStorage.getItem('jwtToken')
    const user = localStorage.getItem('user')
    const clientCategoryId =
        user !== null ? JSON.parse(user)?.clientCategoryId : ''
    const userDetials = user !== null ? JSON.parse(user) : ''
    const [departmentIds, setDepartmetId] = useState(0)
    const clientDynCategoryId = Number(localStorage.getItem('departmetId'))
    useEffect(() => {
        // console.log(departmentId)
        setDepartmetId(clientDynCategoryId)
    }, [clientDynCategoryId, departmentId])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const hasFetchedOnce = useRef(false)

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        const datafetch = async (): Promise<void> => {
            if (
                token !== null &&
                token !== undefined &&
                clientCategoryId !== undefined
            ) {
                setIsLoadingModal(true)
                await GetDashboard(
                    dispatch,
                    clientCategoryId,
                    getFlag !== undefined && getFlag !== null
                        ? Number(getFlag)
                        : 1
                )
                    .then((result) => {
                        if (result !== null && result !== undefined) {
                            setIsLoadingModal(false)
                        }
                    })
                    .catch(() => {})

                await GetClientOverview(dispatch)
                await GetEventListByUserId(dispatch)
            }
        }
        void datafetch()
    }, [token, clientCategoryId, getFlag])
    const [getDashboardInfo, setDashboardInfo] = useState(props?.dashboard)
    const [getEventListByUserInfo, setEventListByUserInfo] = useState(
        props?.eventListByUser
    )
    const [getOverviewInfo, setOverviewInfo] = useState(props?.overview)

    useEffect(() => {
        if (props?.dashboard !== undefined) {
            setDashboardInfo(props?.dashboard)
        }

        if (props?.eventListByUser !== undefined) {
            setEventListByUserInfo(props?.eventListByUser)
        }

        if (props?.overview !== undefined) {
            setOverviewInfo(props?.overview)
        }
    }, [props])

    return getDashboardInfo !== undefined && getDashboardInfo !== null ? (
        <>
            {departmentIds === 1 ? (
                <Row>
                    <Col span={16}>
                        <Welcome
                            lastLoginDate={
                                getDashboardInfo?.greetingInfo?.lastLoginDate !=
                                null
                                    ? getDashboardInfo?.greetingInfo
                                          ?.lastLoginDate
                                    : ''
                            }
                        />
                        <HeadLines
                            backgroundImageUrl={
                                getDashboardInfo?.greetingInfo
                                    ?.backgroundImageUrl != null
                                    ? getDashboardInfo?.greetingInfo
                                          ?.backgroundImageUrl
                                    : ''
                            }
                            greetingText={
                                getDashboardInfo?.greetingInfo?.greetingText !=
                                null
                                    ? getDashboardInfo?.greetingInfo
                                          ?.greetingText
                                    : ''
                            }
                            dashboardNewsInfo={
                                getDashboardInfo?.dashboardNewsInfo != null
                                    ? getDashboardInfo?.dashboardNewsInfo
                                    : []
                            }
                        />
                        {userDetials?.userGroupId !== 4 && (
                            <Row>
                                <Col span={8} className={styles.col}>
                                    <ToDOClients
                                        totalActiveCount={
                                            getOverviewInfo?.totalActiveCount !=
                                            null
                                                ? getOverviewInfo?.totalActiveCount
                                                : 0
                                        }
                                        totalInActiveCount={
                                            getOverviewInfo?.totalInActiveCount !=
                                            null
                                                ? getOverviewInfo?.totalInActiveCount
                                                : 0
                                        }
                                        businessTypeInfoList={
                                            getOverviewInfo?.businessTypeInfoList !=
                                            null
                                                ? getOverviewInfo?.businessTypeInfoList
                                                : []
                                        }
                                        clientInfoList={
                                            getOverviewInfo?.clientInfoList !=
                                            null
                                                ? getOverviewInfo?.clientInfoList
                                                : []
                                        }
                                        overviewServiceStatusList={
                                            getOverviewInfo?.overviewServiceStatusList !=
                                            null
                                                ? getOverviewInfo?.overviewServiceStatusList
                                                : []
                                        }
                                        prospectiveClientInfoList={
                                            getOverviewInfo?.prospectiveClientInfoList !=
                                            null
                                                ? getOverviewInfo?.prospectiveClientInfoList
                                                : []
                                        }
                                        serviceActiveInfoList={
                                            getOverviewInfo?.serviceActiveInfoList !=
                                            null
                                                ? getOverviewInfo?.serviceActiveInfoList
                                                : []
                                        }
                                        serviceSeparationInfoList={
                                            getOverviewInfo?.serviceSeparationInfoList !=
                                            null
                                                ? getOverviewInfo?.serviceSeparationInfoList
                                                : []
                                        }
                                    />
                                </Col>
                                <Col span={8} className={styles.col}>
                                    <MissingInfo
                                        missedInformationList={
                                            getDashboardInfo?.missedInformationInfo !=
                                            null
                                                ? getDashboardInfo?.missedInformationInfo
                                                : []
                                        }
                                    />
                                </Col>
                                <Col span={8} className={styles.col}>
                                    <InvoiceCreditControl
                                        paidInvoiceSummaries={
                                            getDashboardInfo?.paidInvoiceSummary !=
                                            null
                                                ? getDashboardInfo?.paidInvoiceSummary
                                                : []
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        {getDashboardInfo?.dashboardServieGridList.length !==
                            0 &&
                            getDashboardInfo?.dashboardServieGridList[0]
                                .serviceName !== 'My Task' && (
                                <Row>
                                    <Col span={24} className={styles.col}>
                                        <Todo
                                            clientthingstodoInProgressList={
                                                getDashboardInfo?.clientthingstodoInProgressList !=
                                                null
                                                    ? getDashboardInfo.clientthingstodoInProgressList
                                                    : []
                                            }
                                            clientthingstodoOverDueList={
                                                getDashboardInfo?.clientthingstodoOverDueList !=
                                                null
                                                    ? getDashboardInfo.clientthingstodoOverDueList
                                                    : []
                                            }
                                            taskthingstodoInProgressList={
                                                getDashboardInfo?.taskthingstodoInProgressList !=
                                                null
                                                    ? getDashboardInfo.taskthingstodoInProgressList
                                                    : []
                                            }
                                            taskthingstodoOverDueList={
                                                getDashboardInfo?.taskthingstodoOverDueList !=
                                                null
                                                    ? getDashboardInfo.taskthingstodoOverDueList
                                                    : []
                                            }
                                            dashboardServieGridList={
                                                getDashboardInfo?.dashboardServieGridList !=
                                                null
                                                    ? getDashboardInfo.dashboardServieGridList
                                                    : []
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        {getDashboardInfo.clientthingstofollowOverDueList
                            .length > 0 && (
                            <Row>
                                <Col span={24} className={styles.col}>
                                    <ToFollow
                                        clientthingstofollowInProgressList={
                                            getDashboardInfo?.clientthingstofollowInProgressList !=
                                            null
                                                ? getDashboardInfo.clientthingstofollowInProgressList
                                                : []
                                        }
                                        taskthingstofollowInProgressList={
                                            getDashboardInfo?.taskthingstofollowInProgressList !=
                                            null
                                                ? getDashboardInfo.taskthingstofollowInProgressList
                                                : []
                                        }
                                        clientthingstofollowOverDueList={
                                            getDashboardInfo?.clientthingstofollowOverDueList !=
                                            null
                                                ? getDashboardInfo.clientthingstofollowOverDueList
                                                : []
                                        }
                                        taskthingstofollowOverDueList={
                                            getDashboardInfo?.taskthingstofollowOverDueList !=
                                            null
                                                ? getDashboardInfo.taskthingstofollowOverDueList
                                                : []
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        {userDetials?.userGroupId !== 4 &&
                            userDetials?.userGroupId !== 3 && (
                                <Row>
                                    <Col span={24}>
                                        <StaffProgress
                                            dashboardThingsToDoUserWiseList={
                                                getDashboardInfo?.dashboardThingsToDoUserWiseList !=
                                                null
                                                    ? getDashboardInfo.dashboardThingsToDoUserWiseList
                                                    : []
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                    </Col>

                    <Col span={8}>
                        <Calender eventList={getEventListByUserInfo ?? []} />
                        <MyTask />
                        {userDetials.userGroupId !== 4 && (
                            <>
                                <MobileRequest
                                    clientRequestList={
                                        getDashboardInfo?.clientRequestInfo !=
                                        null
                                            ? getDashboardInfo?.clientRequestInfo
                                            : []
                                    }
                                />
                                <ToApprove
                                    leaveApprovalList={
                                        getDashboardInfo?.leaveApprovalList !=
                                        null
                                            ? getDashboardInfo.leaveApprovalList
                                            : []
                                    }
                                    approvalTimesheetList={
                                        getDashboardInfo?.approvalTimesheetList !=
                                        null
                                            ? getDashboardInfo.approvalTimesheetList
                                            : []
                                    }
                                    permissionApprovalList={
                                        getDashboardInfo?.permissionApprovalList !=
                                        null
                                            ? getDashboardInfo.permissionApprovalList
                                            : []
                                    }
                                    myApprovalTimesheetList={
                                        getDashboardInfo?.myApprovalTimesheetList !=
                                        null
                                            ? getDashboardInfo.myApprovalTimesheetList
                                            : []
                                    }
                                    myLeaveApprovalList={
                                        getDashboardInfo?.myLeaveApprovalList !=
                                        null
                                            ? getDashboardInfo.myLeaveApprovalList
                                            : []
                                    }
                                    myPermissionApprovalList={
                                        getDashboardInfo?.myPermissionApprovalList !=
                                        null
                                            ? getDashboardInfo.myPermissionApprovalList
                                            : []
                                    }
                                />
                            </>
                        )}
                    </Col>
                </Row>
            ) : departmentIds === 17 || departmentIds === 253 ? (
                <ITDashBoard />
            ) : (
                <BPOotherDashboard />
            )}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state: any): InitialStateInterface => {
    return {
        dashboard: state.dashboardInfo.dashboard,
        overview: state.dashboardInfo.overview,
        eventListByUser: state.dashboardInfo.eventListByUser,
    }
}

export default connect(mapStateToProps)(DashBoard)
