// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clintDropdownListDiv_ENIBu {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  color: dodgerblue;
  cursor: pointer;
}
.clientDropdownListSpan_zLwSh {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
}
.clientDropdownListSpan2_pbj8t {
  margin-top: 13px;
  width: 100%;
  font-size: 12px;
  color: #b0b1b1;
}
.clientDropdownListSpan1_lcbiC {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MisssingInformation/ClientView/ClientView.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACA;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AACJ;AACA;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;AACJ;AACA;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".clintDropdownListDiv {\n    display: flex;\n    align-items: center;\n    font-weight: 600;\n    font-size: 15px;\n    color: dodgerblue;\n    cursor: pointer;\n}\n.clientDropdownListSpan {\n    width: 100%;\n    font-size: 12px;\n    font-weight: 500;\n}\n.clientDropdownListSpan2 {\n    margin-top: 13px;\n    width: 100%;\n    font-size: 12px;\n    color: #b0b1b1;\n}\n.clientDropdownListSpan1 {\n    width: 100%;\n    font-size: 14px;\n    font-weight: 600;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clintDropdownListDiv": `clintDropdownListDiv_ENIBu`,
	"clientDropdownListSpan": `clientDropdownListSpan_zLwSh`,
	"clientDropdownListSpan2": `clientDropdownListSpan2_pbj8t`,
	"clientDropdownListSpan1": `clientDropdownListSpan1_lcbiC`
};
export default ___CSS_LOADER_EXPORT___;
