import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import TaskList from './ListSubtask'
import TaskForm from './CreteSubtask'
import { connect } from 'react-redux'
import {
    type SubTaskView,
    type Task,
    type subtaskinterface,
} from '../../../Types/HelpDesk/Cretetask'

const { TabPane } = Tabs
const defaultSubTask = {
    subTaskId: 0,
    subTaskNo: '',
    subTaskName: '',
    taskId: '',
    taskName: '',
    priorityName: '',
    taskTypeName: '',
    sortOrder: 0,
    description: '', // Use null if necessary
    assignedByName: '',
    assignedToName: '',
    fromDate: '', // Could be defaulted to a specific date if needed
    toDate: '',
    daysCount: 0,
    daysColourCode: '',
    status: '',
    notifyToStatusChange: 0,
    projectTierInfo: '', // Defaulting to an empty string, consider setting to a placeholder if needed
}

const MainPage = (
    props: subtaskinterface & {
        taskId: string
        getTask: Task
        setResetData: React.Dispatch<React.SetStateAction<string>>
        restData: string
    }
): React.ReactElement => {
    const { taskId, getTask, restData, setResetData } = props

    const [activeKey, setActiveKey] = useState('1')
    const [subtaskData, setSubtaskData] = useState<SubTaskView>(defaultSubTask)
    useEffect(() => {
        setActiveKey('1')
    }, [taskId])
    useEffect(() => {
        setActiveKey('1')
    }, [])
    const handleTabChange = (key: string): void => {
        setActiveKey(key)
        setResetData('true')
    }

    return (
        <Tabs
            activeKey={activeKey}
            onChange={handleTabChange}
            defaultActiveKey="1"
        >
            <TabPane tab="SubTask List" key="1">
                <TaskList
                    taskId={taskId}
                    getTask={getTask}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    setSubtaskData={setSubtaskData}
                />
            </TabPane>
            <TabPane tab="SubTask Form" key="2">
                <TaskForm
                    taskId={taskId}
                    getTask={getTask}
                    resetdata={restData}
                    setResetData={setResetData}
                    subtaskData={subtaskData}
                    setActiveKey={setActiveKey}
                />
            </TabPane>
        </Tabs>
    )
}

const mapStateToProps = (state: any): subtaskinterface => {
    return {
        userList: state.user.userList,
        SubViewDetail: state.helpdesk.SubViewDetail,
        subtaskDetailsList: state.helpdesk.subtaskDetailsList,
        subtaskDetailsListCount: state.helpdesk.subtaskDetailsListCount,
        userimageList: state.helpdesk.userimageList,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(MainPage)
