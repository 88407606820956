import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type ClientRequestApproval,
    type MobileReqestPayloadInterface,
} from '../Types/MobileRequest'

interface MobileStateInterface {
    mobileReqestList: ClientRequestApproval[]
    mobileReqestListCount: number
}

const MobileRequest = createSlice({
    name: 'mobileRequest',
    initialState: {
        mobileReqestList: [],
        mobileReqestListCount: 0,
    },
    reducers: {
        mobileReqestList(
            state: MobileStateInterface,
            action: PayloadAction<MobileReqestPayloadInterface>
        ) {
            state.mobileReqestList = action?.payload?.data
            state.mobileReqestListCount = action?.payload?.noOfRecords
        },
    },
})

export const { mobileReqestList } = MobileRequest.actions

export default MobileRequest.reducer
