import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Divider,
    List,
    Flex,
    Pagination,
    Spin,
    Empty,
    Progress,
    Avatar,
    Tooltip,
    Skeleton,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './Itindex.less'
import { ReactComponent as CheckIcon } from '../../../Assest/Svg/Check circle.svg'
import { connect, useDispatch } from 'react-redux'
import {
    type ProjectListProps,
    type ITProject,
    type ProjectGetInterface,
} from '../../../Types/ITDashbord'
import { GetITProjectDetails } from '../../../Services/ITDashboard'
import { useNavigate } from 'react-router-dom'

const ProjectList = (props: ProjectListProps): React.ReactElement => {
    const { itprojectList, setProjectId } = props
    const [data, setData] = useState<ITProject[]>([])
    const dispatch = useDispatch()
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [noOfRecords] = useState(0)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        GetITProjectDetails(dispatch)
    }, [])
    useEffect(() => {
        setProjectId(itprojectList[0]?.projectId)
        setisLoading(true)
        setData(itprojectList)

        setisLoading(false)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [itprojectList])

    const calculateTaskCompletionPercentage = (
        doneTaskCount: number,
        totalTaskCount: number
    ): number => {
        return totalTaskCount > 0
            ? parseFloat(((doneTaskCount / totalTaskCount) * 100).toFixed(2))
            : 0
    }

    const generateMembersarray = (
        memberUrlsString: string
    ): Array<{
        profileUrl: string
        memberName: string
    }> => {
        return memberUrlsString?.split(',')?.map((url, index) => ({
            profileUrl: url,
            memberName: `Member ${index + 1}`, // Customize memberName if you have the data
        }))
    }
    return (
        <div className={styles.marginRight10}>
            <Card className={`${styles.card}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={8}>
                            <div className={styles.mobileRequest__tittle}>
                                Projects
                            </div>
                        </Col>
                        <Col span={16}>
                            <Flex justify={'space-evenly'} align={'center'}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}
                                >
                                    <div>
                                        <CheckIcon />
                                    </div>
                                    <div
                                        className={
                                            styles.project__viewallcomplate
                                        }
                                    >
                                        2 complete this month
                                    </div>
                                </div>
                                <div className={styles.project__viewall}>
                                    <div
                                        onClick={() => {
                                            navigate(
                                                '/system-configuration/project-management'
                                            )
                                        }}
                                    >
                                        View All
                                    </div>
                                </div>
                            </Flex>
                        </Col>
                    </Row>
                    <Row className={styles.todo__tab__one}>
                        <Col span={6}>CLIENT</Col>
                        <Col span={6}>PROJECT</Col>
                        <Col span={6}>MEMBERS</Col>
                        <Col span={6}>COMPLETE</Col>
                    </Row>
                    <Divider style={{ margin: '10px 0px' }} />

                    {isLoading ? (
                        <Flex
                            align="center"
                            justify="center"
                            style={{ padding: '20px 0px' }}
                        >
                            <Spin />
                        </Flex>
                    ) : (
                        <>
                            {data?.length > 0 ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <List>
                                                <VirtualList
                                                    data={data}
                                                    height={200}
                                                    itemKey="taskId"
                                                >
                                                    {(
                                                        item: ITProject,
                                                        index: number
                                                    ) => (
                                                        <List.Item
                                                            key={index}
                                                            className={
                                                                styles.project__list__item
                                                            }
                                                        >
                                                            <Skeleton
                                                                loading={
                                                                    loading
                                                                }
                                                                active
                                                                avatar
                                                            >
                                                                <Row
                                                                    onClick={() => {
                                                                        setProjectId(
                                                                            item.projectId ??
                                                                                itprojectList[0]
                                                                                    .projectId
                                                                        )
                                                                    }}
                                                                >
                                                                    <Col
                                                                        span={6}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.project__list__item__tittle
                                                                            }
                                                                        >
                                                                            {
                                                                                item.clientCategoryName
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col
                                                                        span={6}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.project__list__item__tittle
                                                                            }
                                                                        >
                                                                            {
                                                                                item.projectName
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col
                                                                        span={6}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.project__list__item__tittle
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            <Avatar.Group
                                                                                max={{
                                                                                    count: 2,
                                                                                    style: {
                                                                                        color: '#f56a00',
                                                                                        backgroundColor:
                                                                                            '#fde3cf',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {generateMembersarray(
                                                                                    item.imageUrls
                                                                                )?.map(
                                                                                    (
                                                                                        datass,
                                                                                        index
                                                                                    ) =>
                                                                                        datass.profileUrl !==
                                                                                            null &&
                                                                                        datass.profileUrl !==
                                                                                            undefined &&
                                                                                        datass.profileUrl !==
                                                                                            '' && (
                                                                                            <Tooltip
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                title={
                                                                                                    datass.memberName
                                                                                                }
                                                                                            >
                                                                                                <Avatar
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                    src={
                                                                                                        datass.profileUrl
                                                                                                    }
                                                                                                />
                                                                                            </Tooltip>
                                                                                        )
                                                                                )}
                                                                            </Avatar.Group>
                                                                        </div>
                                                                    </Col>
                                                                    <Col
                                                                        span={6}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.project__list__item__tittle
                                                                            }
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '14px',
                                                                                    color: '#6b5edd',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                }}
                                                                            >
                                                                                {calculateTaskCompletionPercentage(
                                                                                    10,
                                                                                    item.totalTaskCount
                                                                                )}

                                                                                %
                                                                            </span>
                                                                            <Progress
                                                                                percent={calculateTaskCompletionPercentage(
                                                                                    10,
                                                                                    item.totalTaskCount
                                                                                )}
                                                                                strokeColor="#6b5edd"
                                                                                showInfo={
                                                                                    false
                                                                                } // Hide the default percentage label inside the progress bar
                                                                                style={{
                                                                                    flex: 1,
                                                                                }}
                                                                                size="small"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Skeleton>
                                                        </List.Item>
                                                    )}
                                                </VirtualList>
                                            </List>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            width: '100%',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Col
                                            span={24}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'stretch',
                                            }}
                                        >
                                            <Pagination
                                                defaultCurrent={pageNumber}
                                                total={noOfRecords}
                                                onChange={(e) => {
                                                    setPageNumber(e)
                                                }}
                                                showSizeChanger={false}
                                                showQuickJumper={false}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Empty
                                    style={{ padding: '40px 0px' }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>No tasks to complete.</span>
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = (state: any): ProjectGetInterface => {
    return {
        itprojectList: state.itdashboardInfo.ITprojectList,
    }
}

export default connect(mapStateToProps)(ProjectList)
