import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Button, Card, Space, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { type updateProfile, type UserData } from '../../Types/Profile'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import ManageProfileForm from './manageProfileForm'
import { GetProfileDetails, SaveProfile } from '../../Services/Profile'
import dayjs from 'dayjs'
export const ManageProfileDefaultdata = {
    userId: 0,
    firstName: '',
    middleName: '',
    surName: '',
    dateOfBirth: '',
    addressOne: '',
    addressTwo: '',
    town: '',
    country: '',
    postCode: '',
    userName: '',
    imageurl: '',
    emailAddress: '',
    contactNo: '',
    managerName: '',
    workingStatus: '',
    userGroupName: '',
    clientCategoryName: '',
    departmentName: '',
    designationName: '',
    no: '',
    createdDate: '',
    createdUser: '',
    lastUpdateDate: '',
    lastUpdateUser: '',
    deletedDate: '',
    deletedUser: '',
}

const ManageProfile = (): React.ReactElement => {
    const [userData, setUserData] = useState<UserData | null>()
    const [editData, setEditData] = useState<UserData>(ManageProfileDefaultdata)
    const [isEditMode, setIsEditMode] = useState(false)
    const dispatch = useDispatch()
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        fetchUserData()
    }, [tokenDetails.userId])

    const fetchUserData = (): void => {
        void GetProfileDetails(
            dispatch,
            tokenDetails?.userId,
            (addressData: UserData) => {
                setUserData(addressData)
            }
        )
    }
    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(userData ?? ManageProfileDefaultdata)
    }

    const onSaveProfile = (
        data: updateProfile,
        callBack: CallableFunction
    ): void => {
        void saveProfiledataData(data, callBack)
    }
    const saveProfiledataData = async (
        data: updateProfile,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveProfile(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    setIsEditMode(!isEditMode)
                    fetchUserData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card title={'Manage Profile'}>
                <>
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" onClick={handleEditClick}>
                                {isEditMode ? (
                                    <Tooltip title="View Profile">
                                        <EyeOutlined />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Edit Profile">
                                        <EditOutlined />
                                    </Tooltip>
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isEditMode ? (
                        <div>
                            <ManageProfileForm
                                onSave={onSaveProfile}
                                editData={editData}
                            />
                        </div>
                    ) : (
                        <Row gutter={[24, 8]}>
                            <Col span={6}>
                                <Space direction="vertical">
                                    <p>User Name</p>
                                    <p>Date of Brith</p>
                                    <p>Address One</p>
                                    <p>Address Two</p>
                                    <p>Post Code </p>
                                    <p>Town</p>
                                    <p>Country</p>
                                    <p>Contact Number</p>
                                    <p>Profile Image</p>
                                </Space>
                            </Col>
                            <Col span={6}>
                                <Space direction="vertical">
                                    <p>: {userData?.userName ?? ''}</p>
                                    <p>
                                        :{' '}
                                        {userData?.dateOfBirth != null
                                            ? dayjs(userData.dateOfBirth)
                                                  .add(1, 'day')
                                                  .format('YYYY-MM-DD')
                                            : ''}
                                    </p>
                                    <p>: {userData?.addressOne ?? ''}</p>
                                    <p>: {userData?.addressTwo ?? ''}</p>
                                    <p>: {userData?.postCode ?? ''}</p>
                                    <p>: {userData?.town ?? ''}</p>
                                    <p>: {userData?.country ?? ''}</p>
                                    <p>: {userData?.contactNo ?? ''}</p>
                                    <p>
                                        <img
                                            src={userData?.imageurl}
                                            width={100}
                                            height={100}
                                        />
                                    </p>
                                </Space>
                            </Col>
                        </Row>
                    )}
                </>
            </Card>
        </>
    )
}

export default ManageProfile
