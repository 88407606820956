import React, { useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    DatePicker,
    Button,
    TimePicker,
    Tooltip,
} from 'antd'
import commonStyles from '../../Utils/Common.less'
import TextArea from 'antd/es/input/TextArea'
import { SaveOutlined } from '@ant-design/icons'
import {
    type createSprintForm,
    type createSprintFormTypeInterface,
} from '../../Types/Backlog/Backlog'
import type dayjs from 'dayjs'
import moment from 'moment'

const CreateSprint = (
    props: createSprintFormTypeInterface
): React.ReactElement => {
    const { onSave, handleModalClose } = props
    // const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [duration, setDuration] = useState<dayjs.Dayjs | null>(null)
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const resetForm = (): void => {
        form.setFieldsValue({})
    }
    const onChange1 = (time: dayjs.Dayjs | null): void => {
        setDuration(time)
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                onFinish={(data) => {
                    onSave(data, resetForm)
                    handleModalClose()
                }}
            >
                <Form.Item<createSprintForm> name="sprintId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<createSprintForm>
                            label="Sprint Name"
                            name="sprintName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the Sprint Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<createSprintForm>
                            label="Duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Duration!',
                                },
                            ]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                value={duration}
                                onChange={onChange1}
                                format="HH:mm"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<createSprintForm>
                            label="Start date"
                            name="sprintStartDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Your Start Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="D/M/YYYY"
                                className={commonStyles.dateWidth}
                                inputReadOnly
                                disabledDate={(current) =>
                                    current !== null &&
                                    current < moment().startOf('day')
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<createSprintForm>
                            label="End Date"
                            name="sprintEndDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Your End Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="D/M/YYYY"
                                className={commonStyles.dateWidth}
                                inputReadOnly
                                disabledDate={(current) =>
                                    current !== null &&
                                    current < moment().startOf('day')
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<createSprintForm>
                            label="Sprint goal"
                            name="sprintDescription"
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save sprint">
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CreateSprint
