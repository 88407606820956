import type { Dispatch } from '@reduxjs/toolkit'
import { type TableListInterface } from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'
import axios from '../Config/AxiosConfig'
import {
    type saveClientCategory,
    type createCategoryParentInterface,
} from '../Types/CreateCategory'
import {
    clientParentCategoryDropDown,
    clientParentCategoryReducer,
} from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'

export const GetParentCategory = (
    dispatch: Dispatch,
    clientCategory: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/ClientCategory/GetClientCategory/${clientCategory}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: createCategoryParentInterface) => {
                        return {
                            ...value,
                            key: value.clientDynCategoryId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(clientParentCategoryDropDown({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveClientCategory = async (
    dispatch: Dispatch,
    data: saveClientCategory
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ClientCategory/SetClientCategory`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data.data,
                }
            } else {
                ErrorMessage(response.data.operationMsg)
                return {
                    success: false,
                    data: null,
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeleteClientDynCategory = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/39/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
                location.reload()
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ActiveInactiveClientDynCategory = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/21/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetParentCategoryss = (
    dispatch: Dispatch,
    clientCategory: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/ClientCategory/GetClientCategory/${clientCategory}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: createCategoryParentInterface) => {
                        return {
                            ...value,
                            key: value.clientDynCategoryId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(clientParentCategoryReducer({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
