// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleText_QUfI1 {
  font-size: 35px;
  font-weight: 700;
}
.subText_ZPlHh {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/CompanyHouse/CompanyEligiblity/companyEligiblity.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;AACJ;AACA;EACI,eAAA;AACJ","sourcesContent":[".titleText {\n    font-size: 35px;\n    font-weight: 700;\n}\n.subText {\n    font-size: 20px;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": `titleText_QUfI1`,
	"subText": `subText_ZPlHh`
};
export default ___CSS_LOADER_EXPORT___;
