import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type ArticalPayloadInteface,
    type ArticleData,
} from '../Types/wikiArtical'
import { type ListFrontEndInterface } from '../Types/CommonType'
import {
    type ArticalGroupPayloadInteface,
    type ArticleGroupData,
} from '../Types/wikiArticalGroup'

interface WikiPageStateInterface {
    articalList: ArticleData[]
    articalListCount: number
    articalListGroupData: ArticleGroupData[]
    articalListGroupDataCount: number
    articalGroupList: ListFrontEndInterface[]
}

const WikiPage = createSlice({
    name: 'wiki',
    initialState: {
        articalList: [],
        articalListCount: 0,
        articalGroupList: [],
        articalListGroupData: [],
        articalListGroupDataCount: 0,
    },
    reducers: {
        articalList(
            state: WikiPageStateInterface,
            action: PayloadAction<ArticalPayloadInteface>
        ) {
            state.articalList = action?.payload?.data
            state.articalListCount = action?.payload?.noOfRecords
        },
        articalGroupList(
            state: WikiPageStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.articalGroupList = action?.payload
        },
        articalListGroupData(
            state: WikiPageStateInterface,
            action: PayloadAction<ArticalGroupPayloadInteface>
        ) {
            state.articalListGroupData = action?.payload?.data
            state.articalListGroupDataCount = action?.payload?.noOfRecords
        },
    },
})

export const { articalList, articalGroupList, articalListGroupData } =
    WikiPage.actions

export default WikiPage.reducer
