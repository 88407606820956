// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_AwERr {
  margin: 0px 5px 15px 5px;
  border: none !important;
}
.clicked_sMQ_H {
  background-color: #346cfc;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/Commonbutton.less"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,uBAAA;AACJ;AACA;EACI,yBAAA;AACJ","sourcesContent":[".button {\n    margin: 0px 5px 15px 5px;\n    border: none !important;\n}\n.clicked {\n    background-color: #346cfc;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_AwERr`,
	"clicked": `clicked_sMQ_H`
};
export default ___CSS_LOADER_EXPORT___;
