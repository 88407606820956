import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Table,
    type TableProps,
    Tabs,
    TimePicker,
    Tooltip,
} from 'antd'
import {
    SaveHours,
    gethourssdroplist,
} from '../../../Services/Helpdesk/CreteTask'
import { connect, useDispatch } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import TabPane from 'antd/es/tabs/TabPane'
import {
    type TaskHourssRequestBodysform,
    type TaskHours,
} from '../../../Types/HelpDesk/Cretetask'
import dayjs from 'dayjs'
import { type ColumnsType } from 'antd/es/table'
import { formatdate } from '../../../Utils/dateFormat'
import calculatePagination from '../../../Utils/Pagination'

const HoursForm = (
    props: TaskHourssRequestBodysform & { taskid: string }
): React.ReactElement => {
    const dispatch = useDispatch()
    const { taskid, hoursList } = props
    const { TextArea } = Input
    const [form] = Form.useForm()
    const [activeTabKey, setActiveTabKey] = useState('1')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    useEffect(() => {
        void fetchHoursList()
    }, [taskid])

    const fetchHoursList = async (): Promise<void> => {
        await gethourssdroplist(dispatch, taskid, currentPage, itemsPerPage)
    }

    const onSave = (): void => {
        void onSaveChat()
    }

    const onSaveChat = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()

            const formattedStartTime = dayjs(formData.startTime).format(
                'HH:mm A'
            )
            const formattedEndTime = dayjs(formData.endTime).format('HH:mm A')
            const formatstartDate = dayjs(formData.startDate).format(
                'DD/MM/YYYY'
            )

            const formDataFormat = {
                taskHoursId: 0,
                taskId: Number(taskid),
                startDate: formatstartDate,
                startTime: formattedStartTime ?? '',
                endTime: formattedEndTime ?? '',
                duration: '',
                description: formData.description ?? '',
            }
            await SaveHours(dispatch, formDataFormat)
            form.resetFields()
            await fetchHoursList()
            setActiveTabKey('1')
        } catch (error) {
            console.error('Error in saving this one', error)
        }
    }

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        hoursList.length,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<TaskHours> = [
        {
            title: 'Date',
            key: 'startDate',
            render: (records) => {
                return <div>{formatdate(records.startDate)}</div>
            },
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
    ]
    const onChangeTableParams: TableProps<TaskHours>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const TaskForm = (): React.ReactElement => (
        <Form
            name="complex-form"
            initialValues={{ remember: true }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="Due Date"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Due Date!',
                            },
                        ]}
                    >
                        <DatePicker
                            name="startDate"
                            format="DD/MM/YYYY"
                            className={commonStyles.dateWidth}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Start Time"
                        name="startTime"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter startTime!',
                            },
                        ]}
                    >
                        <TimePicker
                            name="startTime"
                            style={{ width: '100%' }}
                            format="HH:mm"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="End Time"
                        name="endTime"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter endTime!',
                            },
                        ]}
                    >
                        <TimePicker
                            name="endTime"
                            style={{ width: '100%' }}
                            format="HH:mm"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Description" name="description">
                        <TextArea rows={2} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col offset={22} span={2}>
                    <Tooltip title="Save Hours">
                        <Button type="primary" onClick={onSave}>
                            <SaveOutlined />
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        </Form>
    )

    const TaskList = (): React.ReactElement => (
        <>
            <>
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={hoursList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
            </>
        </>
    )

    return (
        <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
            <TabPane tab="Hours List" key="1">
                <TaskList />
            </TabPane>
            <TabPane tab="Hours Form" key="2">
                <TaskForm />
            </TabPane>
        </Tabs>
    )
}

const mapStateToProps = (state: any): TaskHourssRequestBodysform => {
    return {
        hoursList: state.helpdesk.hoursList,
        hoursListCount: state.helpdesk.hoursListCount,
    }
}
export default connect(mapStateToProps)(HoursForm)
