export const randomColor = (): string => {
    const gen = Math.floor(Math.random() * 16777215).toString(16)
    return gen === 'ffffff' ? '#5c1975' : '#' + gen
}

export const randomLightColor = (): string => {
    // const red = Math.floor(Math.random() * 255)
    // const green = Math.floor(Math.random() * 255)
    // const blue = Math.floor(Math.random() * 255)
    // const opacity = 0.5
    // return `rgba(${red}, ${green}, ${blue}, ${opacity})`
    return '#aae7f7'
}
