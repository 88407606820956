import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Select, Tooltip } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type ProspectFormStateInterface,
    type ProspectClientFormDataInterface,
    type ProspectClientFormInterface,
} from '../../../Types/Client/ProspectClients/prospectClient'
import { ProspectClientItemDefaultData } from './prospactclientList'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import { GetCountryList } from '../../../Services/Common'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const { TextArea } = Input

const ProspectClientForm = (
    props: ProspectClientFormInterface
): React.ReactElement => {
    const { onSave, editData, countryList, buttonloading } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    useEffect(() => {
        const countryData = countryList?.find(
            (data: ListFrontEndInterface) => data.label === editData.country
        )
        const newData = {
            ...editData,
            country: countryData?.value ?? '',
        }
        form.setFieldsValue(newData)
    }, [editData, countryList])
    useEffect(() => {
        form.setFieldsValue(editData)
    }, [editData])

    const resetForm = (): void => {
        form.setFieldsValue(ProspectClientItemDefaultData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    const countryData = countryList?.find(
                        (data1: ListFrontEndInterface) =>
                            data1.value === data.country
                    )
                    const datasave = {
                        ...data,
                        country: countryData?.label ?? '',
                    }
                    onSave(datasave, resetForm)
                }}
                requiredMark={customizeRequiredMark}
                form={form}
            >
                <Form.Item<ProspectClientFormDataInterface>
                    name="prospectClientId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item<ProspectClientFormDataInterface>
                    name="clientDynCategory"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your tittle!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Middle Name"
                            name="middleName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Surname"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Surname!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Email Address"
                            name="emailAddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    pattern:
                                        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}@[\w.-]+\.[a-zA-Z]{2,}$/,
                                    message:
                                        'Please Input Correct Email Address',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Mobile Number "
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message: 'please enter valid mobile number',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Company Name"
                            name="companyName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Referred By"
                            name="referredBy"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Address One"
                            name="address1"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Address Two"
                            name="address2"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Town"
                            name="town"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Country"
                            name="country"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={countryList?.map((country) => ({
                                    value: country.value,
                                    label: country.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Post Code"
                            name="postCode"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your description!',
                                },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={buttonloading}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ProspectFormStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(ProspectClientForm)
