import React from 'react'
import { Button } from 'antd'
import { ReactComponent as PlusIcon } from '../Assest/Svg/Plus.svg'

interface AddNewButtonInterface {
    name?: string
    clickAction: () => void
}

const AddNewButton = (props: AddNewButtonInterface): React.ReactElement => {
    const { name = 'Add New', clickAction } = props

    return (
        <Button type="primary" icon={<PlusIcon />} onClick={clickAction}>
            {name}
        </Button>
    )
}

export default AddNewButton
