// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nameContainer_dFnwu {
  height: 10px;
  display: flex;
  justify-content: center;
}
.firstName_x6NMA {
  display: inline-block;
  height: 25px;
  margin-top: 10px;
  text-align: flex-start;
  font-size: 16px;
}
.lastName_AtXIi {
  height: 25px;
  text-align: flex-start;
  font-size: 12px;
  font-weight: 400;
  color: #718096;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/userAvatar.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAEA;EACI,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;AAAJ;AAGA;EACI,YAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ","sourcesContent":[".nameContainer {\n    height: 10px;\n    display: flex;\n    justify-content: center;\n}\n\n.firstName {\n    display: inline-block;\n    height: 25px;\n    margin-top: 10px;\n    text-align: flex-start;\n    font-size: 16px;\n}\n\n.lastName {\n    height: 25px;\n    text-align: flex-start;\n    font-size: 12px;\n    font-weight: 400;\n    color: #718096;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameContainer": `nameContainer_dFnwu`,
	"firstName": `firstName_x6NMA`,
	"lastName": `lastName_AtXIi`
};
export default ___CSS_LOADER_EXPORT___;
