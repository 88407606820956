// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roleBadge_PIkyx {
  display: flex;
  gap: 4px;
}
.roleSpan_L3uuI {
  border-radius: 5px;
  padding: 3px;
}
.director_XHzAv {
  background-color: #2196f3;
}
.psc_h3pA0 {
  background-color: #fbe6f0;
}
.shareholder_E61bg {
  background-color: #ecf7ed;
}
.defaultColor_LRh_C {
  background-color: #cbeef6;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Corporate/Buttons/OfficerButton/ofiicer.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;AACJ;AAEA;EACI,kBAAA;EACA,YAAA;AAAJ;AAGA;EACI,yBAAA;AADJ;AAIA;EACI,yBAAA;AAFJ;AAKA;EACI,yBAAA;AAHJ;AAKA;EACI,yBAAA;AAHJ","sourcesContent":[".roleBadge {\n    display: flex;\n    gap: 4px;\n}\n\n.roleSpan {\n    border-radius: 5px;\n    padding: 3px;\n}\n\n.director {\n    background-color: #2196f3;\n}\n\n.psc {\n    background-color: #fbe6f0;\n}\n\n.shareholder {\n    background-color: #ecf7ed;\n}\n.defaultColor {\n    background-color: #cbeef6;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roleBadge": `roleBadge_PIkyx`,
	"roleSpan": `roleSpan_L3uuI`,
	"director": `director_XHzAv`,
	"psc": `psc_h3pA0`,
	"shareholder": `shareholder_E61bg`,
	"defaultColor": `defaultColor_LRh_C`
};
export default ___CSS_LOADER_EXPORT___;
