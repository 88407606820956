import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Switch,
    Flex,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import FullWidthModal from '../../../Components/FullWidthModal'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import dayjs from 'dayjs'
import { type PSCDetails } from '../../../Types/CompanyHouse/CompanyOverView'
import {
    GetCompanyOverViewData,
    GetTerminatePSCOffocerDetails,
    SetTeminatePSCOfficer,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { useDispatch } from 'react-redux'
import moment from 'moment'
interface terminatePSCData {
    onClosemodel: () => void
    corporateId: number
    businessId: number
    pscID: number
}
const RemovePscOfficer = (props: terminatePSCData): React.ReactElement => {
    const { onClosemodel, pscID, corporateId, businessId } = props
    const [form] = Form.useForm()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [ceasedPSCDate, setCeasedPSCDate] = useState<string>('')
    const [registerEntryDate, setRegisterEntryDate] = useState<string>('')
    const [isSubmitToCH, setIsSubmitToCH] = useState<number>(0)
    const [isSubmitToYes, setIsSubmitToCHYes] = useState<boolean>(false)
    const [PSCofficerData, setPSCofficersData] = useState<PSCDetails | null>(
        null
    )
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchAuditData = async (): Promise<void> => {
            try {
                const data = await GetTerminatePSCOffocerDetails(
                    dispatch,
                    pscID
                )
                setPSCofficersData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        void fetchAuditData()
    }, [pscID])
    const handleSwitchChange = (checked: boolean): void => {
        setSwithModelStatus(!swithModalStatus)
        setIsSubmitToCHYes(checked)
    }

    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        setIsSubmitToCH(isSubmitToYes ? 1 : 0)
        ChangeModalFunction()
    }
    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setCeasedPSCDate(isoDate)
        }
    }

    const onRegisterEntryDateChange = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setRegisterEntryDate(isoDate)
        }
    }
    const handelSaveData = (): void => {
        void handelSetData()
    }
    const handelSetData = async (): Promise<void> => {
        try {
            // const fotmData = form.getFieldsValue()
            await form.validateFields()
            const dataToSave = {
                pscId: pscID,
                corporateId,
                companyId: 0,
                companyName: PSCofficerData?.companyName ?? '',
                companyRegNumber: PSCofficerData?.companyRegNumber ?? '',
                pscTypeId: PSCofficerData?.pscTypeId ?? 0,
                pscTypeName: PSCofficerData?.pscTypeName ?? '',
                additionalDataOne: PSCofficerData?.additionalDataOne ?? '',
                additionalDataTwo: PSCofficerData?.additionalDataTwo ?? '',
                additionalDataThree: PSCofficerData?.additionalDataThree ?? '',
                additionalDataFour: PSCofficerData?.additionalDataFour ?? '',
                additionalDataFive: PSCofficerData?.additionalDataFive ?? '',
                dateofBirth: PSCofficerData?.dateofBirth ?? '',
                ceasedPSCDate: moment(ceasedPSCDate).format('DD/MM/YYYY'),
                registerEntryDate:
                    moment(registerEntryDate).format('DD/MM/YYYY'),
                isCeasedSubmitToCH: isSubmitToCH,
            }
            void SetTeminatePSCOfficer(dispatch, dataToSave)
            GetCompanyOverViewData(dispatch, businessId, corporateId, 0, 6)
            onClosemodel()
            setRegisterEntryDate('')
            setCeasedPSCDate('')
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={10}>Company Name</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>{PSCofficerData?.companyName}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Reg. Number</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>{PSCofficerData?.companyRegNumber}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Type Of PSC</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>{PSCofficerData?.pscTypeName}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>PSC&apos;s Name</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>
                            {PSCofficerData?.additionalDataOne}
                            {PSCofficerData?.additionalDataTwo}
                            {PSCofficerData?.additionalDataThree}
                            {PSCofficerData?.additionalDataFour}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Date of Birth</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>{PSCofficerData?.dateofBirth}</Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Date the person ceased to be a PSC"
                                name="dateOfBirth"
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            ceasedPSCDate.length > 0
                                                ? dayjs(ceasedPSCDate)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Register entry date"
                                name="dateOfBirth"
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onRegisterEntryDateChange(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            registerEntryDate.length > 0
                                                ? dayjs(registerEntryDate)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Switch
                                checked={isSubmitToCH === 1}
                                onChange={handleSwitchChange}
                            />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={onClosemodel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                                clickAction={handelSaveData}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

export default RemovePscOfficer
