// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tittle_hKnx7 {
  font-size: 24px;
  text-align: center;
}
.paragraph_qYBOX {
  text-align: center;
}
.yesButton_v0pyt {
  margin: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/fullWidthModal.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AAEA;EACI,kBAAA;AAAJ;AAGA;EACI,cAAA;AADJ","sourcesContent":[".tittle {\n    font-size: 24px;\n    text-align: center;\n}\n\n.paragraph {\n    text-align: center;\n}\n\n.yesButton {\n    margin: 0 10px;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tittle": `tittle_hKnx7`,
	"paragraph": `paragraph_qYBOX`,
	"yesButton": `yesButton_v0pyt`
};
export default ___CSS_LOADER_EXPORT___;
