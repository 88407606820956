import { Col, DatePicker, Flex, Form, Row, Switch } from 'antd'
import complianceStyles from './compilance.less'
import React from 'react'
import dayjs from 'dayjs'
import { SyncOutlined } from '@ant-design/icons'
import { type ClientControlData } from '../../../../../../Types/Client/RegisteredClients/Corporate'
const ControlItems = (props: {
    clientControlViewList: ClientControlData[]
    showDatePickerMap: Record<number, boolean>
    dateValues: Record<number, dayjs.Dayjs | null>
    handleSwitchChangeControll: (
        clientControlId: number,
        checked: boolean
    ) => void
    handleDateChange: (date: dayjs.Dayjs | null, controllersId: number) => void
    openDeleteModal: () => void
    onbordingId: number
}): React.ReactElement => {
    const {
        clientControlViewList,
        showDatePickerMap,
        dateValues,
        handleSwitchChangeControll,
        handleDateChange,
        openDeleteModal,
        onbordingId,
    } = props
    return (
        <>
            <Row className={complianceStyles.controlItemWidth}>
                <Col span={24}>
                    <Row className={complianceStyles.datePickerLeft}>
                        <div key={clientControlViewList[0]?.clientControlId}>
                            <Form.Item
                                name={`Controllstatus-${clientControlViewList[0]?.clientControlId}`}
                            >
                                {clientControlViewList[0]?.keyDataType ===
                                    'Bool' && (
                                    <Switch
                                        checked={
                                            showDatePickerMap[
                                                clientControlViewList[0]
                                                    ?.clientControlId
                                            ]
                                        }
                                        onChange={(checked) => {
                                            handleSwitchChangeControll(
                                                clientControlViewList[0]
                                                    ?.clientControlId,
                                                checked
                                            )
                                        }}
                                    />
                                )}
                                <span style={{ marginLeft: '10px' }}>
                                    {clientControlViewList[0]?.description}
                                </span>
                            </Form.Item>
                        </div>
                    </Row>
                    {Boolean(
                        showDatePickerMap[
                            clientControlViewList[0]?.clientControlId
                        ]
                    ) && (
                        <Row className={complianceStyles.datePickerLeft}>
                            <Col span={24}>
                                <div
                                    key={
                                        clientControlViewList[1]
                                            ?.clientControlId
                                    }
                                >
                                    <Form.Item
                                        label={
                                            clientControlViewList[1]
                                                ?.description
                                        }
                                    >
                                        {clientControlViewList[1]
                                            ?.keyDataType === 'DateTime' && (
                                            <DatePicker
                                                value={
                                                    dateValues[
                                                        clientControlViewList[1]
                                                            ?.clientControlId
                                                    ] != null
                                                        ? dayjs(
                                                              dateValues[
                                                                  clientControlViewList[1]
                                                                      ?.clientControlId
                                                              ],
                                                              'DD/MM/YYYY'
                                                          )
                                                        : null
                                                }
                                                format="DD/MM/YYYY"
                                                allowClear
                                                className={
                                                    complianceStyles.datePicker
                                                }
                                                onChange={(
                                                    date,
                                                    dateString
                                                ): void => {
                                                    handleDateChange(
                                                        date,
                                                        clientControlViewList[1]
                                                            ?.clientControlId
                                                    )
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row className={complianceStyles.datePickerLeft}>
                        <div key={clientControlViewList[2]?.clientControlId}>
                            <Form.Item
                                name={`Controllstatus-${clientControlViewList[2]?.clientControlId}`}
                            >
                                {clientControlViewList[2]?.keyDataType ===
                                    'Bool' && (
                                    <Switch
                                        checked={
                                            showDatePickerMap[
                                                clientControlViewList[2]
                                                    ?.clientControlId
                                            ]
                                        }
                                        onChange={(checked) => {
                                            handleSwitchChangeControll(
                                                clientControlViewList[2]
                                                    ?.clientControlId,
                                                checked
                                            )
                                        }}
                                    />
                                )}
                                {'  '}
                                <span style={{ marginLeft: '10px' }}>
                                    {clientControlViewList[2]?.description}
                                </span>
                            </Form.Item>
                        </div>
                    </Row>
                    {Boolean(
                        showDatePickerMap[
                            clientControlViewList[2]?.clientControlId
                        ]
                    ) && (
                        <Row className={complianceStyles.datePickerLeft}>
                            <Col span={24}>
                                <div
                                    key={
                                        clientControlViewList[3]
                                            ?.clientControlId
                                    }
                                >
                                    <Form.Item
                                        label={
                                            clientControlViewList[3].description
                                        }
                                    >
                                        {clientControlViewList[3]
                                            .keyDataType === 'DateTime' && (
                                            <DatePicker
                                                value={
                                                    dateValues[
                                                        clientControlViewList[3]
                                                            ?.clientControlId
                                                    ] != null
                                                        ? dayjs(
                                                              dateValues[
                                                                  clientControlViewList[3]
                                                                      ?.clientControlId
                                                              ],
                                                              'DD/MM/YYYY'
                                                          )
                                                        : null
                                                }
                                                format="DD/MM/YYYY"
                                                allowClear
                                                className={
                                                    complianceStyles.datePicker
                                                }
                                                onChange={(
                                                    date,
                                                    dateString
                                                ): void => {
                                                    handleDateChange(
                                                        date,
                                                        clientControlViewList[3]
                                                            ?.clientControlId
                                                    )
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row className={complianceStyles.datePickerLeft}>
                        <Col span={24}>
                            <div
                                key={clientControlViewList[4]?.clientControlId}
                            >
                                <Form.Item
                                    label={
                                        clientControlViewList[4]?.description
                                    }
                                >
                                    {clientControlViewList[4]?.keyDataType ===
                                        'DateTime' && (
                                        <DatePicker
                                            value={
                                                dateValues[
                                                    clientControlViewList[4]
                                                        ?.clientControlId
                                                ] != null
                                                    ? dayjs(
                                                          dateValues[
                                                              clientControlViewList[4]
                                                                  ?.clientControlId
                                                          ],
                                                          'DD/MM/YYYY'
                                                      )
                                                    : null
                                            }
                                            format="DD/MM/YYYY"
                                            allowClear
                                            className={
                                                complianceStyles.datePicker
                                            }
                                            onChange={(
                                                date,
                                                dateString
                                            ): void => {
                                                handleDateChange(
                                                    date,
                                                    clientControlViewList[4]
                                                        ?.clientControlId
                                                )
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>

                    {clientControlViewList[5]?.keyName === 'TermsSigned' && (
                        <>
                            <div
                                className={
                                    clientControlViewList[5]?.keyName ===
                                    'TermsSigned'
                                        ? complianceStyles.TermsSigine
                                        : ''
                                }
                            >
                                <Form.Item
                                    name={`Controllstatus-${clientControlViewList[5]?.clientControlId}`}
                                >
                                    <div
                                        className={
                                            complianceStyles.TermsSigineText
                                        }
                                    >
                                        {clientControlViewList[5]
                                            ?.keyDataType === 'Bool' && (
                                            <Switch
                                                disabled={onbordingId !== 1}
                                                checked={
                                                    showDatePickerMap[
                                                        clientControlViewList[5]
                                                            ?.clientControlId
                                                    ]
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChangeControll(
                                                        clientControlViewList[5]
                                                            ?.clientControlId,
                                                        checked
                                                    )
                                                }}
                                            />
                                        )}
                                        <span>
                                            {
                                                clientControlViewList[5]
                                                    ?.description
                                            }
                                        </span>
                                    </div>
                                </Form.Item>
                            </div>
                            <Flex justify="flex-end">
                                <div>
                                    {clientControlViewList[5]?.keyName ===
                                        'TermsSigned' && (
                                        <p
                                            onClick={openDeleteModal}
                                            className={
                                                complianceStyles.enableService
                                            }
                                        >
                                            <SyncOutlined />
                                            Enable Service
                                        </p>
                                    )}
                                </div>
                            </Flex>
                        </>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default ControlItems
