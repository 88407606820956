import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Switch, Col, Row, Tooltip, Drawer } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import { MessageOutlined, EyeOutlined } from '@ant-design/icons'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import type {
    onboardingPartnershipListStateInterface,
    onboardingPartnershiplListDataTypeInterface,
    onboardingPartnershipListInterface,
} from '../../../../Types/Client/OnboardingClients/OnBoardingPartnership'
import {
    ActiveInactiveParnership,
    DeleteParnership,
    GetParnershipList,
} from '../../../../Services/OnBoardingPartnrship'
import AddNewButton from '../../../../Components/AddNewButton'
import PartnershipExpandView from './OnBoardingPartnershipExpand'
import { useNavigate } from 'react-router-dom'
import calculatePagination from '../../../../Utils/Pagination'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import StaffCommunication from '../../../../Components/StaffChat'
import { GetChat } from '../../../../Services/Common'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
export const PartnershipItemDefaultData = {
    partnershipId: '0',
    name: '',
    tradingName: '',
    typeOfSelfEmployment: '',
    clientNumber: '',
    activestatus: '',
    serviceIds: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const PartnershiplList = (
    props: onboardingPartnershipListInterface
): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        onboardingpartnerList,
        onboardingpartnerListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [partnershipId, setPartnershipId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [resetData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (Number(partnershipId) !== 0) {
            void GetChat(
                dispatch,
                6,
                Number(partnershipId),
                4,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [partnershipId])

    const openChatModal = (partnershipId: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setPartnershipId(partnershipId)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetParnershipList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
        hasFetchedOnce.current = false
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: onboardingpartnerListCount,
            },
        })
    }, [onboardingpartnerListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const ViewParnershipDetails = (id: string): void => {
        const partnershipData = onboardingpartnerList?.find(
            (item) => item.partnershipId === id
        )
        const onbordingId = 1
        navigate(`/clients/onboarding-clients/partnership-view`, {
            state: { partnershipData, onbordingId },
        })
    }

    const createPartnership = (id: string): void => {
        const partnershipData = onboardingpartnerList?.find(
            (item) => item.partnershipId === id
        )
        const onbordingId = 1
        navigate('/clients/onboarding-clients/partnership-new', {
            state: { partnershipData, onbordingId },
        })
    }

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        onboardingpartnerListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<onboardingPartnershiplListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Trading Name',
            dataIndex: 'tradingName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: ' Partnership UTR',
            dataIndex: 'partnershipUTR',
        },
        {
            title: 'Service',
            dataIndex: 'serviceIds',
        },
        {
            title: 'Action',
            key: 'action1',
            render: (
                _,
                record: onboardingPartnershiplListDataTypeInterface
            ) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.partnershipId)
                                    setResetData('true')
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="View Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    ViewParnershipDetails(record?.partnershipId)
                                    localStorage.setItem('partnerTapId', '1')
                                }}
                            />
                        </Tooltip>

                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.partnershipId,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) ? (
                            <Tooltip title="Delete Parteneship">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.partnershipId
                                        )
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<onboardingPartnershiplListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let partnershipId = ''
        if (!deleteModalStatus) {
            partnershipId = id
        }
        setPartnershipId(partnershipId)
    }

    const deletePartnership = (): void => {
        DeleteParnership(dispatch, partnershipId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let partnershipId = ''
        if (!activeInactiveModalStatus) {
            partnershipId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setPartnershipId(partnershipId)
    }

    const updateActiveInactivePartnership = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveParnership(dispatch, partnershipId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const expandableData = (
        record: onboardingPartnershiplListDataTypeInterface
    ): JSX.Element => {
        return <PartnershipExpandView PartnershipData={record} />
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.cardContainer}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={'Search Name by keywords'}
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                            width="478"
                        />
                    </Col>
                    <Col span={4}>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                createPartnership('')
                            }}
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={onboardingpartnerList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Parnership. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deletePartnership}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction('', '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this Parnership. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactivePartnership}
            />

            {/* <Modal
                title="Staff Comm"
                open={chatModalOpen}
                onCancel={() => {
                    setChatModalOpen(false)
                }}
                footer={null}
                width={'50%'}
            >
                {selectedPartnershipId != null && (
                    <PartnershipChat
                        partnershipId={parseInt(selectedPartnershipId, 10)}
                    />
                )}
            </Modal> */}
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={Number(partnershipId)}
                    messageTypeId={6}
                    businessId={4}
                    resetdata={resetData}
                    setResetData={setResetData}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (
    state: any
): onboardingPartnershipListStateInterface => {
    return {
        onboardingpartnerList: state.client.onboardingpartnerList,
        onboardingpartnerListCount: state.client.onboardingpartnerListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(PartnershiplList)
