import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../../Config/AxiosConfig'
import { setLoadingStatus } from '../../Reducer/Common'
import {
    type ReportDateInterface,
    type ReportServiceStatus,
} from '../../Types/Invoicing/InvoiceReports'
import { ErrorMessage } from '../../Utils/Notification'
import { invoiceStatusReportList } from '../../Reducer/Invoice'

export const GetStatusReporDetailsList = (
    dispatch: Dispatch,
    data: ReportServiceStatus
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .post(`/api/Report/GetServiceStatus`, data)
        .then((response) => {
            if (response.status === 200) {
                dispatch(invoiceStatusReportList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetInvoiceTypeReporDetailsList = async (
    dispatch: Dispatch,
    clientCategoryId: number,
    companyTypeId: number,
    serviceId: number,
    flagId: number,
    data: ReportDateInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.post(
            `/api/Report/GetServiceInvoiceListByActionStatus/${clientCategoryId}/${companyTypeId}/${serviceId}/${flagId}`,
            data
        )

        if (response.status === 200) {
            dispatch(invoiceStatusReportList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetPaidInvoiceReporDetailsList = async (
    dispatch: Dispatch,
    clientCategoryId: number,
    companyTypeId: number,
    serviceId: number,
    data: ReportDateInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `/api/Report/GetServicePaidInvoiceList/${clientCategoryId}/${companyTypeId}/${serviceId}`,
            data
        )

        if (response.status === 200) {
            dispatch(invoiceStatusReportList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetUnSendReporDetailsList = (
    dispatch: Dispatch,
    clientCategoryId: number,
    companyTypeId: number,
    serviceId: number,
    data: ReportDateInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .post(
            `/api/Report/GetServiceInvoiceListByPaymentOption/${clientCategoryId}/${companyTypeId}/${serviceId}/0`,
            data
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(invoiceStatusReportList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetUnpaidReporDetailsList = async (
    dispatch: Dispatch,
    clientCategoryId: number,
    companyTypeId: number,
    paymentOptionId: number,
    data: ReportDateInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `/api/Report/GetServiceInvoiceListByPaymentOption/${clientCategoryId}/${companyTypeId}/${paymentOptionId}/2`,
            data
        )

        if (response.status === 200) {
            dispatch(invoiceStatusReportList({ ...response.data }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
