import { Form, Button, Col, Row, Select } from 'antd'
import commonStyles from '../../../../../../Utils/Common.less'
import { type CorporateOfficerView } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import React, { useEffect, useState } from 'react'
import {
    AttachIndividual,
    PopulateindualAccountList,
} from '../../../../../../Services/Corporate'
import { useDispatch } from 'react-redux'
import { type ListBackendInterface } from '../../../../../../Types/CommonType'
const OfficerPersonModelForm = (props: {
    referencId: number
    onCancel: () => void
}): React.ReactElement => {
    const { referencId, onCancel } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [accountName, setaccountName] = useState<ListBackendInterface[]>([])
    const [individualId, setSelectedInduvidual] = useState(0)

    useEffect(() => {
        hadelShowList(referencId)
    }, [referencId])

    const hadelShowList = (referencid: number): void => {
        void PopulateindualAccountList(
            dispatch,
            referencid,
            (getAccount: ListBackendInterface[]) => {
                setaccountName(getAccount)
            }
        )
    }

    const companyType = 1
    const handelAttachIndividual = (status: number): void => {
        void handelAttachIndividualData(status)
    }
    const handelAttachIndividualData = async (
        status: number
    ): Promise<void> => {
        await AttachIndividual(
            dispatch,
            individualId,
            companyType,
            referencId,
            status
        )

        onCancel()
    }
    const handeleDataChange = (value: number): void => {
        setSelectedInduvidual(value)
    }
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
            >
                <Row gutter={16} style={{ marginBottom: '20px' }}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>>
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handeleDataChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                {accountName?.map((item: any) => (
                                    <Select.Option
                                        key={item?.stdId}
                                        value={item?.stdId}
                                    >
                                        {item.stdName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} justify="end">
                    <Col>
                        <Button
                            type="primary"
                            onClick={(): void => {
                                handelAttachIndividual(1)
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={2}>
                        <Button
                            type="primary"
                            onClick={(): void => {
                                handelAttachIndividual(0)
                            }}
                        >
                            OK
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default OfficerPersonModelForm
