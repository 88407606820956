import React, { useState } from 'react'
import CommonButton from '../../Components/Commonbutton'
import ServiceButton from './Buttons/serviceButton'
import TaskList from './Buttons/taskbutton'
import InvoicesList from './Buttons/invoices'
import ClientComList from './Buttons/clientComLog'
import StaffComunicationList from './Buttons/staffComLog'
interface GlobelClientTapsPrps {
    businessId: number
    refernceId: number
}
const GlobelClientTaps = (props: GlobelClientTapsPrps): React.ReactElement => {
    const { businessId, refernceId } = props
    const [activeButton, setActiveButton] = useState(1)
    const [showServiceList, setShowServiceList] = useState(true)
    const [showTaskList, setShowTaskList] = useState(true)
    const [showInvoicesList, setShowIndividualList] = useState(true)
    const [showClientComList, setShowClientComList] = useState(true)
    const [showStaffComList, setShowStaffComList] = useState(true)

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setShowServiceList(true)
            setShowTaskList(false)
        } else if (buttonName === 2) {
            setShowTaskList(true)
            setShowServiceList(false)
        } else if (buttonName === 3) {
            setShowIndividualList(true)
            setShowServiceList(false)
            setShowTaskList(false)
        } else if (buttonName === 4) {
            setShowClientComList(true)
            setShowIndividualList(false)
            setShowServiceList(false)
            setShowTaskList(false)
        } else if (buttonName === 5) {
            setShowStaffComList(true)
            setShowClientComList(false)
            setShowIndividualList(false)
            setShowServiceList(false)
            setShowTaskList(false)
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return (
                <ServiceButton
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )
        } else if (activeButton === 2) {
            return (
                <TaskList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )
        }
        if (activeButton === 3) {
            return (
                <InvoicesList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )
        }
        if (activeButton === 4) {
            return (
                <ClientComList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )
        }
        if (activeButton === 5) {
            return (
                <StaffComunicationList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )
        }
        return <></>
    }

    return (
        <>
            <CommonButton
                name="Service"
                isActive={activeButton === 1}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(1)
                }}
            />
            <CommonButton
                name="Task"
                isActive={activeButton === 2}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(2)
                }}
            />
            <CommonButton
                name="Invoices"
                isActive={activeButton === 3}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(3)
                }}
            />
            <CommonButton
                name="Client Com log"
                isActive={activeButton === 4}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(4)
                }}
            />
            <CommonButton
                name="Staff Com log"
                isActive={activeButton === 5}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(5)
                }}
            />
            {showServiceList && activeButton === 1 && (
                <ServiceButton
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )}
            {showInvoicesList && activeButton === 3 && (
                <InvoicesList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )}
            {showTaskList && activeButton === 2 && (
                <TaskList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )}
            {showClientComList && activeButton === 4 && (
                <ClientComList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )}
            {showStaffComList && activeButton === 5 && (
                <StaffComunicationList
                    businessTypeId={businessId}
                    referenceId={refernceId}
                />
            )}
        </>
    )
}

export default GlobelClientTaps
