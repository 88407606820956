import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import Welcome from './welcome'
import HeadLines from './headlines'
import styles from './Itindex.less'
import Todo from './todo'
import ToFollow from './toFollow'

import Calender from './calender'
import MyTask from './myTask'
import MobileRequest from './mobileRequest'
import { useDispatch, connect, useSelector } from 'react-redux'
import { GetITDashboard } from '../../../Services/Dashboard'
import type { InitialStateInterface } from '../../../Types/Dashboard'
import ToDOClients from './clients2'
import ToApprove from '../toApprove'

const ITDashBoard = (props: InitialStateInterface): React.ReactElement => {
    const dispatch = useDispatch()

    const getFlag = useSelector((state: any) => state.dashboardFlag.flag)

    const token = localStorage.getItem('jwtToken')
    const user = localStorage.getItem('user')
    const clientCategoryId =
        user !== null ? JSON.parse(user)?.clientCategoryId : ''
    const userDetials = user !== null ? JSON.parse(user) : ''
    useEffect(() => {
        if (
            token !== null &&
            token !== undefined &&
            clientCategoryId !== undefined
        ) {
            void GetITDashboard(dispatch)
        }
    }, [token, clientCategoryId, getFlag])
    const [getDashboardInfo, setDashboardInfo] = useState(props?.dashboard)
    const [getEventListByUserInfo, setEventListByUserInfo] = useState(
        props?.eventListByUser
    )
    const [getOverviewInfo, setOverviewInfo] = useState(props?.itdashboard)

    useEffect(() => {
        if (props?.dashboard !== undefined) {
            setDashboardInfo(props?.dashboard)
        }

        if (props?.eventListByUser !== undefined) {
            setEventListByUserInfo(props?.eventListByUser)
        }

        if (props?.itdashboard !== undefined) {
            setOverviewInfo(props?.itdashboard)
        }
    }, [props])

    return getDashboardInfo !== undefined && getDashboardInfo !== null ? (
        <>
            <Row>
                <Col span={16}>
                    <Welcome
                        lastLoginDate={
                            getOverviewInfo?.greetingInfo?.lastLoginDate != null
                                ? getOverviewInfo?.greetingInfo?.lastLoginDate
                                : ''
                        }
                    />
                    <HeadLines
                        backgroundImageUrl={
                            getOverviewInfo?.greetingInfo?.backgroundImageUrl !=
                            null
                                ? getOverviewInfo?.greetingInfo
                                      ?.backgroundImageUrl
                                : ''
                        }
                        greetingText={
                            getOverviewInfo?.greetingInfo?.greetingText != null
                                ? getOverviewInfo?.greetingInfo?.greetingText
                                : ''
                        }
                        dashboardNewsInfo={
                            getOverviewInfo?.dashboardNewsInfo != null
                                ? getOverviewInfo?.dashboardNewsInfo
                                : []
                        }
                    />
                    {userDetials?.userGroupId !== 4 && (
                        <Row>
                            <Col span={8} className={styles.col}>
                                <ToDOClients
                                    clientInfo={
                                        getOverviewInfo?.clientInfo != null
                                            ? getOverviewInfo?.clientInfo
                                            : []
                                    }
                                />
                            </Col>
                            <Col span={16} className={styles.col}>
                                {userDetials.userGroupId !== 4 && (
                                    <MobileRequest
                                        clientRequestList={
                                            getDashboardInfo?.clientRequestInfo !=
                                            null
                                                ? getDashboardInfo?.clientRequestInfo
                                                : []
                                        }
                                    />
                                )}
                            </Col>
                            {/* <Col span={8} className={styles.col}></Col> */}
                        </Row>
                    )}
                    {getDashboardInfo?.dashboardServieGridList.length !== 0 &&
                        getDashboardInfo?.dashboardServieGridList[0]
                            .serviceName !== 'My Task' && (
                            <Row>
                                <Col span={24} className={styles.col}>
                                    <Todo
                                        projectInfo={
                                            getOverviewInfo?.projectInfo != null
                                                ? getOverviewInfo.projectInfo
                                                : []
                                        }
                                    />
                                </Col>
                            </Row>
                        )}

                    <Row>
                        <Col span={24} className={styles.col}>
                            <ToFollow
                                projectInfo={
                                    getOverviewInfo?.projectInfo != null
                                        ? getOverviewInfo.projectInfo
                                        : []
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Calender eventList={getEventListByUserInfo ?? []} />
                    <MyTask />
                    {userDetials?.userGroupId !== 4 && (
                        <ToApprove
                            leaveApprovalList={
                                getDashboardInfo?.leaveApprovalList != null
                                    ? getDashboardInfo.leaveApprovalList
                                    : []
                            }
                            approvalTimesheetList={
                                getDashboardInfo?.approvalTimesheetList != null
                                    ? getDashboardInfo.approvalTimesheetList
                                    : []
                            }
                            permissionApprovalList={
                                getDashboardInfo?.permissionApprovalList != null
                                    ? getDashboardInfo.permissionApprovalList
                                    : []
                            }
                        />
                    )}
                </Col>
            </Row>
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state: any): InitialStateInterface => {
    return {
        dashboard: state.dashboardInfo.dashboard,
        overview: state.dashboardInfo.overview,
        eventListByUser: state.dashboardInfo.eventListByUser,
        itdashboard: state.dashboardInfo.itdashboard,
    }
}

export default connect(mapStateToProps)(ITDashBoard)
