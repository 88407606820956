import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Col, Row, Checkbox } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetPerformInvoice,
    GetSetMark,
} from '../../../Services/Invoicing/Invoice'
import {
    type ArchiveInvoiceState,
    type ArcheivedInvoiceListDataTypeInterface,
    type ArchivedInvoiceProps,
} from '../../../Types/Invoicing/Invoice'
import SearchInput from '../../../Components/SearchInput'
import calculatePagination from '../../../Utils/Pagination'
import InvoicePreparationExpandView from './invoicepreparetionExpandView'

const ArchivedInvoice = (props: ArchivedInvoiceProps): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        ArchivedInvoice,
        selectedCategoryId,
        selectedServiceId,
        ArchivedInvoiceCount,
    } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [searchString, setSearchString] = useState('=NA=')
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        GetPerformInvoice(
            dispatch,
            searchString,
            2,
            selectedCategoryId,
            selectedServiceId,
            currentPage,
            itemsPerPage
        )
    }, [
        searchString,
        currentPage,
        itemsPerPage,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleCheckboxChange = (record: number): void => {
        void GetSetMark(dispatch, record)
        getTableData()
    }

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString)
        return date.toLocaleDateString()
    }

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        ArchivedInvoiceCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<ArcheivedInvoiceListDataTypeInterface> = [
        {
            width: 100,
            title: 'Number',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Client',
            dataIndex: 'referenceName',
        },
        {
            title: 'Period',
            dataIndex: 'periodStartDates',
            render: (_, record) => (
                <span>
                    Period Of {formatDate(record.periodStartDate)} to{' '}
                    {formatDate(record.periodEndDate)}
                </span>
            ),
        },
        {
            width: 120,
            title: 'Services',
            dataIndex: 'serviceName',
        },
        {
            title: 'Current Status',
            dataIndex: 'currentStatus',
        },
        {
            title: '',
            key: 'checkbox',
            render: (_, record: ArcheivedInvoiceListDataTypeInterface) => {
                const str = record.currentStatus.substring(0, 7)
                return (
                    str.localeCompare('INVOICE') === 0 &&
                    (record.isSent === 0 || record.isPaid === 0) && (
                        <div>
                            <Checkbox
                                checked
                                onChange={() => {
                                    handleCheckboxChange(
                                        record.serviceProcessId
                                    )
                                }}
                            />
                        </div>
                    )
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ArcheivedInvoiceListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        if (data.length !== 0) {
            setSearchString(data)
        } else {
            setSearchString('=NA=')
        }
    }
    const expandableData = (
        record: ArcheivedInvoiceListDataTypeInterface
    ): React.ReactElement => {
        return <InvoicePreparationExpandView InvoiceData={record} />
    }
    const mappdata = ArchivedInvoice?.map((item) => ({
        ...item,
        key: item.serviceProcessId,
    }))
    const [showMessage, setShowMessage] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(true)
        }, 6000)

        // Clear the timer if the component unmounts
        return () => {
            clearTimeout(timer)
        }
    }, [])
    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.corpList}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={'Search CompanyName by keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                {mappdata.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={mappdata}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                ) : (
                    <div>
                        {showMessage ? (
                            <h4>No matching data available</h4>
                        ) : (
                            <h4>Loading data...</h4>
                        )}
                    </div>
                )}
            </div>
        </Card>
    )
}

const mapStateToProps = (state: any): ArchiveInvoiceState => {
    return {
        ArchivedInvoice: state.invoice.ArchivedInvoice,
        ArchivedInvoiceCount: state.invoice.ArchivedInvoiceCount,
    }
}

export default connect(mapStateToProps)(ArchivedInvoice)
