import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Button, Select, Row, Col } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import CoporateTabsless from '../../../../../../Less/Clients/RegisteredClients/Corporate/CorporateTabs.less'
import {
    type ContactInfoStateInterface,
    type ContactEditData,
    type ContactInfoInterface,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import {
    GetContactInfoPersonDropDown,
    SaveContactInfo,
    getClientCompanyDetails,
    getContactPersonDetails,
} from '../../../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import { ErrorMessage } from '../../../../../../Utils/Notification'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const ContactInfoEditForm = (
    props: ContactEditData & { onSave: () => void }
): React.ReactElement => {
    const { editdata, corporateid, onSave, contactInfoPersonDropDownList } =
        props
    const [memberPersonId, setMemberPersonId] = useState(0)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldsValue(editdata)
    }, [editdata, form])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetContactInfoPersonDropDown(dispatch, corporateid, 1)
    }, [corporateid])

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            if (memberPersonId !== 0) {
                const data = await getContactPersonDetails(
                    dispatch,
                    1,
                    memberPersonId
                )
                form.setFieldsValue({
                    mobilenumber:
                        data?.memberPersonHeaderInfo?.mobilenumber ?? '',
                    title: data?.memberPersonHeaderInfo?.title ?? '',
                    homenumber: data?.memberPersonHeaderInfo?.homenumber ?? '',
                    firstname: data?.memberPersonHeaderInfo?.firstname ?? '',
                    worknumber: data?.memberPersonHeaderInfo?.worknumber ?? '',
                    middlename: data?.memberPersonHeaderInfo?.middlename ?? '',
                    emailaddress:
                        data?.memberPersonHeaderInfo?.emailaddress ?? '',
                    surname: data?.memberPersonHeaderInfo?.surname ?? '',
                    altemailaddress:
                        data?.memberPersonHeaderInfo?.altemailaddress ?? '',
                })
            }
        }
        void fetchData()
    }, [memberPersonId])

    const handelSave = (): void => {
        void handleClick()
    }
    const handleClick = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            if (
                typeof formData.emailaddress === 'string' &&
                Boolean(formData.emailaddress.includes('com.'))
            ) {
                ErrorMessage('Plese Input Correct Email')
            } else {
                const Language =
                    formData.preferredlanguage === 1
                        ? 'English'
                        : formData.preferredlanguage === 2
                          ? 'Tamil'
                          : ''
                const dataToSave = {
                    contactpersonid: editdata?.contactpersonid ?? 0,
                    registerid: corporateid,
                    registertype: editdata?.registertype ?? 'CORPORATE',
                    title: formData.title,
                    firstname: formData.firstname,
                    middlename: formData.middlename,
                    surname: formData.surname,
                    preferredname: formData.preferredname ?? '',
                    mobilenumber: formData.mobilenumber,
                    homenumber: formData.homenumber,
                    worknumber: formData.worknumber,
                    emailaddress: formData.emailaddress,
                    altemailaddress: formData.altemailaddress,
                    preferredlanguage: formData.preferredlanguage ?? 0,
                    languageName: Language,
                }
                await SaveContactInfo(dispatch, dataToSave)
                await getClientCompanyDetails(dispatch, 1, corporateid, 5)
                onSave()
            }
        } catch (error) {
            console.error('Error validating fields:', error)
        }
    }

    const memberPersonOnchange = (value: number): void => {
        setMemberPersonId(value)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<ContactInfoInterface>
                    name="registerid"
                    hidden={true}
                >
                    <Input defaultValue={''} />
                </Form.Item>

                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item label="Member Person ">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={(value) => {
                                    memberPersonOnchange(value)
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={contactInfoPersonDropDownList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Mobile Number"
                            name="mobilenumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Mobile Number!',
                                },
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Title "
                            name="title"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                {' '}
                                <Select.Option value=" Please Select">
                                    Please Select
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Home Number"
                            name="homenumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="First Name"
                            name="firstname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the FirstName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Work Number "
                            name="worknumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Middle Name"
                            name="middlename"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Email Address"
                            name="emailaddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Email Address!',
                                },
                                {
                                    type: 'email',
                                    message:
                                        'Please enter a valid Email Address!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Sure Name "
                            name="surname"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Alternative Email"
                            name="altemailaddress"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Prefered Name "
                            name="preferredname"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item<ContactInfoInterface>
                            label="Prefered Language"
                            name="preferredlanguage"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value=" Please Select">
                                    Please Select
                                </Select.Option>
                                <Select.Option value={1}>English</Select.Option>
                                <Select.Option value={2}>Tamil</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    handelSave()
                                }}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ContactInfoStateInterface => {
    return {
        contactInfoPersonDropDownList:
            state.client.contactInfoPersonDropDownList,
    }
}
export default connect(mapStateToProps)(ContactInfoEditForm)
