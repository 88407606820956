// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document_ILQ_8 {
  width: 100%;
  height: 600px;
  margin-bottom: 20px;
}
.documentuploadtext_Lfa1n {
  margin-top: 20px;
}
.documentupload_3EIbz {
  position: static;
  height: 200px;
}
.CorporteDocumentGeenreal_kdTqn {
  margin-bottom: auto !important;
}
.CorporteDocument_hKAL0 {
  margin-top: 10px;
}
.cardhight_xR0C8 {
  height: 350px;
}
.fileupload_dypmX {
  position: absolute;
  top: 0;
  margin-top: 10px;
  margin-bottom: 40px !important;
  width: 100%;
}
.savebuttonless_QM3EN {
  margin-top: 360px;
}
.dateWidth_EKo6N {
  position: absolute;
  width: 100%;
  top: 0px !important;
}
.imagewidth_MZnjg {
  width: 100%;
  margin-top: 20px;
}
.attchment_W8qeR {
  margin-top: auto !important;
  top: 200px !important;
  margin-bottom: 110px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Individual/Buttons/Documents/document.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAEA;EACI,gBAAA;EACA,aAAA;AAAJ;AAGA;EACI,8BAAA;AADJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;AAFJ;AAKA;EACI,kBAAA;EACA,MAAA;EACA,gBAAA;EACA,8BAAA;EACA,WAAA;AAHJ;AAMA;EACI,iBAAA;AAJJ;AAMA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;AAJJ;AAOA;EACI,WAAA;EACA,gBAAA;AALJ;AAOA;EACI,2BAAA;EACA,qBAAA;EACA,oBAAA;AALJ","sourcesContent":[".document {\n    width: 100%;\n    height: 600px;\n    margin-bottom: 20px;\n}\n\n.documentuploadtext {\n    margin-top: 20px;\n}\n.documentupload {\n    position: static;\n    height: 200px;\n}\n\n.CorporteDocumentGeenreal {\n    margin-bottom: auto !important;\n}\n.CorporteDocument {\n    margin-top: 10px;\n}\n\n.cardhight {\n    height: 350px;\n}\n\n.fileupload {\n    position: absolute;\n    top: 0;\n    margin-top: 10px;\n    margin-bottom: 40px !important;\n    width: 100%;\n}\n\n.savebuttonless {\n    margin-top: 360px;\n}\n.dateWidth {\n    position: absolute;\n    width: 100%;\n    top: 0px !important;\n}\n\n.imagewidth {\n    width: 100%;\n    margin-top: 20px;\n}\n.attchment {\n    margin-top: auto !important;\n    top: 200px !important;\n    margin-bottom: 110px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"document": `document_ILQ_8`,
	"documentuploadtext": `documentuploadtext_Lfa1n`,
	"documentupload": `documentupload_3EIbz`,
	"CorporteDocumentGeenreal": `CorporteDocumentGeenreal_kdTqn`,
	"CorporteDocument": `CorporteDocument_hKAL0`,
	"cardhight": `cardhight_xR0C8`,
	"fileupload": `fileupload_dypmX`,
	"savebuttonless": `savebuttonless_QM3EN`,
	"dateWidth": `dateWidth_EKo6N`,
	"imagewidth": `imagewidth_MZnjg`,
	"attchment": `attchment_W8qeR`
};
export default ___CSS_LOADER_EXPORT___;
