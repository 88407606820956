import React from 'react'
import { Col, Row } from 'antd'
import { type PayeViewPagePropData } from '../../../../../../Types/Client/RegisteredClients/SoleTrader'

export const Payedefaultdata = {
    payeId: 0,
    companyType: 0,
    referenceId: 0,
    payeReference: '',
    accountOfficeReference: '',
    payrollFrequency: '',
    schemeStartDate: '01/01/1900',
    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    gateWayId: '',
    password: '',
    status: '',
    csiFiling: 0,
    payeProcess: 0,
    filingMethod: 0,
    payeProcessName: '',
    filingMethodName: '',
    hmrcPaymentFrequency: 0,
    hmrcPaymentFrequencyName: '',
}
const PAYEviewPage = (props: PayeViewPagePropData): React.ReactElement => {
    const { PayeServiceData } = props

    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>PAYE Reference</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PayeServiceData?.payeReference}</p>
                </Col>
                <Col span={5}>
                    <p>Address 2</p>
                </Col>
                <Col span={7}>
                    <p>: {PayeServiceData?.addressLineTwo}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Account Office Reference</p>
                </Col>
                <Col span={6}>
                    <p>: {PayeServiceData?.accountOfficeReference}</p>
                </Col>
                <Col span={5}>
                    <p>Town</p>
                </Col>
                <Col span={7}>
                    <p>: {PayeServiceData?.town}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Payroll Frequency</p>
                </Col>
                <Col span={6}>
                    <p>: {PayeServiceData?.payrollFrequency}</p>
                </Col>
                <Col span={5}>
                    {' '}
                    <p>Country</p>
                </Col>
                <Col span={7}>
                    {' '}
                    <p>: {PayeServiceData?.country}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Scheme Start Date</p>
                </Col>
                <Col span={6}>
                    <p>: {formatdate(PayeServiceData?.schemeStartDate)}</p>
                </Col>
                <Col span={5}>
                    {' '}
                    <p>Post Code</p>
                </Col>
                <Col span={7}>
                    {' '}
                    <p>: {PayeServiceData?.postCode}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Address 1 </p>
                </Col>
                <Col span={6}>
                    <p>: {PayeServiceData?.addressLineOne}</p>
                </Col>
                <Col span={5}>
                    <p>HMRC Gateway ID</p>
                </Col>
                <Col span={7}>
                    <p>: {PayeServiceData?.gateWayId}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>CIS Filling</p>
                </Col>
                <Col span={6}>
                    <p>
                        :{' '}
                        {PayeServiceData?.csiFiling !== undefined
                            ? PayeServiceData.csiFiling === 1
                                ? 'Applicable'
                                : 'Not Applicable'
                            : ''}
                    </p>
                </Col>
                <Col span={5}>
                    <p>Password</p>
                </Col>
                <Col span={7}>
                    <p>: {PayeServiceData?.password}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>PAYE Process Date</p>
                </Col>
                <Col span={6}>
                    <p>: {PayeServiceData?.payeProcessName}</p>
                </Col>
                <Col span={5}>
                    {' '}
                    <p>Filling Method</p>
                </Col>
                <Col span={7}>
                    <p>
                        :{' '}
                        {PayeServiceData?.filingMethod !== undefined &&
                        PayeServiceData?.filingMethod !== 0
                            ? PayeServiceData.filingMethod === 1
                                ? 'Nil Return'
                                : PayeServiceData.filingMethod === 2
                                  ? 'Standard Filing'
                                  : 'Hours to be obtained WEEKLY Filing'
                            : ''}
                    </p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>HMRC Payment Frequency</p>
                </Col>
                <Col span={6}>
                    <p>: {PayeServiceData?.hmrcPaymentFrequencyName}</p>
                </Col>
            </Row>
        </>
    )
}

export default PAYEviewPage
