import React from 'react'
import { Col, Row } from 'antd'
import { connect } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import type { GroupAndPartnerExpandViewStateInterface } from '../../../Types/GroupAndPartners'
import { type ProjectManagmentExpandViewInterface } from '../../../Types/ProjectMangement'

const ProjectManagmentExpandViewExpandView = (
    props: ProjectManagmentExpandViewInterface
): React.ReactElement => {
    const { ProjectData } = props

    return (
        <div className={commonStyles.expandCard}>
            <Row className={commonStyles.expandRow}>
                <Col span={3}>Project Name :- </Col>
                <Col span={10}>{ProjectData.projectName}</Col>
            </Row>

            <Row className={commonStyles.expandRow}>
                <Col span={3}>Project Prefix :-</Col>
                <Col span={10}>{ProjectData.projectPrefix}</Col>
            </Row>
            <Row className={commonStyles.expandRow}>
                <Col span={3}> Description :-</Col>
                <Col span={10}>{ProjectData.projectDescription}</Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (
    state: any
): GroupAndPartnerExpandViewStateInterface => {
    return {
        parentCategoryList: state.initial.parentCategoryList,
    }
}

export default connect(mapStateToProps)(ProjectManagmentExpandViewExpandView)
