const handleError = (errorStatusCode: number): string => {
    let errorMessage: string = ''

    switch (errorStatusCode) {
        case 400:
            // errorMessage = 'Please fill in the fields correctly before saving.'
            errorMessage = 'Bad Request'
            break
        case 401:
            errorMessage = 'Access is denied due to invalid credentials.'
            break
        case 403:
            errorMessage = 'You do not have permission to access this resource.'
            break
        case 404:
            errorMessage =
                'The requested resource could not be found on the server.'
            break
        case 405:
            errorMessage =
                'The request method is not supported for the requested resource.'
            break
        case 408:
            errorMessage = 'The server timed out waiting for the request.'
            break
        case 409:
            errorMessage =
                'The request could not be completed due to a conflict with the current state of the resource.'
            break
        case 410:
            errorMessage =
                'The requested resource is no longer available and has been permanently removed.'
            break
        case 413:
            errorMessage =
                'The request is larger than the server is willing or able to process.'
            break
        case 415:
            errorMessage =
                'The request entity has a media type which the server or resource does not support.'
            break
        case 429:
            errorMessage =
                'You have sent too many requests in a given amount of time.'
            break
        case 500:
            errorMessage =
                'The server encountered an unexpected condition that prevented it from fulfilling the request.'
            break
        case 502:
            errorMessage =
                'The server received an invalid response from the upstream server.'
            break
        case 503:
            errorMessage =
                'The server is not ready to handle the request, usually due to temporary overload or maintenance.'
            break
        case 504:
            errorMessage =
                'The server did not receive a timely response from an upstream server.'
            break
        default:
            errorMessage = `Unexpected Error: Received status code ${errorStatusCode}.`
    }

    return errorMessage
}

export default handleError
