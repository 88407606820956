// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document_CGGif {
  width: 100%;
  margin-bottom: 20px;
}
.documentuploadtext_GLgkD {
  margin-top: 20px;
}
.documentupload_oB0LR {
  position: static;
  height: 200px;
}
.CorporteDocumentGeenreal_B9vPN {
  margin-bottom: 60px;
}
.CorporteDocument_hzfA3 {
  margin-top: 10px;
}
.cardhight_vVvpy {
  height: 350px;
}
.fileupload_B21jU {
  position: absolute;
  top: 0;
  margin-top: 10px;
  width: 100%;
}
.savebuttonless_aOFSL {
  margin-top: 220px;
}
.dateWidth_U9FRm {
  position: absolute;
  width: 100%;
  top: 0px !important;
}
.imagewidth_Mqd0V {
  width: 100%;
  margin-top: 20px;
}
.savebuttonless_aOFSL {
  margin-top: 320px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Partnership/Buttons/Documents/document.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAEA;EACI,gBAAA;EACA,aAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;AAFJ;AAKA;EACI,kBAAA;EACA,MAAA;EACA,gBAAA;EACA,WAAA;AAHJ;AAMA;EACI,iBAAA;AAJJ;AAMA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;AAJJ;AAOA;EACI,WAAA;EACA,gBAAA;AALJ;AAOA;EACI,iBAAA;AALJ","sourcesContent":[".document {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.documentuploadtext {\n    margin-top: 20px;\n}\n.documentupload {\n    position: static;\n    height: 200px;\n}\n\n.CorporteDocumentGeenreal {\n    margin-bottom: 60px;\n}\n.CorporteDocument {\n    margin-top: 10px;\n}\n\n.cardhight {\n    height: 350px;\n}\n\n.fileupload {\n    position: absolute;\n    top: 0;\n    margin-top: 10px;\n    width: 100%;\n}\n\n.savebuttonless {\n    margin-top: 220px;\n}\n.dateWidth {\n    position: absolute;\n    width: 100%;\n    top: 0px !important;\n}\n\n.imagewidth {\n    width: 100%;\n    margin-top: 20px;\n}\n.savebuttonless {\n    margin-top: 320px;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"document": `document_CGGif`,
	"documentuploadtext": `documentuploadtext_GLgkD`,
	"documentupload": `documentupload_oB0LR`,
	"CorporteDocumentGeenreal": `CorporteDocumentGeenreal_B9vPN`,
	"CorporteDocument": `CorporteDocument_hzfA3`,
	"cardhight": `cardhight_vVvpy`,
	"fileupload": `fileupload_B21jU`,
	"savebuttonless": `savebuttonless_aOFSL`,
	"dateWidth": `dateWidth_U9FRm`,
	"imagewidth": `imagewidth_Mqd0V`
};
export default ___CSS_LOADER_EXPORT___;
