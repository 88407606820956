import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Space, DatePicker } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    DashboardNewsFormDataInterface,
    DashboardNewsFormInterface,
} from '../../../Types/DashboardNews'
import { DashboardNewsItemDefaultData } from './dashboardNewsList'
import type { DatePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const { TextArea } = Input

const DashboardNewsForm = (
    props: DashboardNewsFormInterface
): React.ReactElement => {
    const { onSave, editData, isLoadingModal } = props
    const [form] = Form.useForm()
    const [selectednewsDate, setSelectednewsDate] = useState<string>('')

    useEffect(() => {
        form.setFieldsValue(editData)
    }, [editData])

    const resetForm = (): void => {
        form.setFieldsValue(DashboardNewsItemDefaultData)
    }

    useEffect(() => {
        setSelectednewsDate(editData.newsDate)
    }, [editData.newsDate])

    useEffect(() => {
        form.setFieldValue('newsDate', selectednewsDate)
    }, [selectednewsDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectednewsDate(isoDate)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<DashboardNewsFormDataInterface>
                    name="newsId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DashboardNewsFormDataInterface>
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your tittle!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DashboardNewsFormDataInterface>
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please description ',
                                },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DashboardNewsFormDataInterface>
                            label="Date"
                            name="newsDate"
                            rules={[{ required: true }]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectednewsDate.length > 0
                                            ? dayjs(selectednewsDate)
                                            : null
                                    }
                                    className={commontStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DashboardNewsFormDataInterface>
                            label="URL"
                            name="newsURL"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your URL!',
                                },
                                {
                                    pattern: /^(ftp|http|https):\/\/[^ "]+$/,
                                    message: 'Please enter a valid URL!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1010', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={Boolean(isLoadingModal)}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

export default DashboardNewsForm
