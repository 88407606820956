import React, { useState, type ReactElement } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { connect } from 'react-redux'
import {
    type HomeAddress,
    type HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'

const HomeAddressForm = (props: HomeAddress): ReactElement => {
    const { countryList } = props
    const [selectedCountry, setSelectedCountry] = useState<string>('')

    const handleCountryChange = (value: string): void => {
        setSelectedCountry(value)
        props.onDateChange(value)
    }

    return (
        <>
            {' '}
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Address 1"
                        name="homeAddressOne"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your address one!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Address 2" name="homeAddressTwo">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Town"
                        name="homeTown"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your town!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Country" name="homeCountry">
                        <Select
                            key={countryList.length}
                            allowClear
                            showSearch
                            onChange={handleCountryChange}
                            options={countryList?.map(
                                (country: { label: any }) => ({
                                    value: country.label,
                                    label: country.label,
                                })
                            )}
                            value={selectedCountry}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item
                        label="Post Code"
                        name="homePostCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your PostCode!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(HomeAddressForm)
