import React from 'react'
interface HTMLcodeToReactProps {
    html: string
}

const HTMLcodeToReact = (props: HTMLcodeToReactProps): React.ReactElement => {
    const { html } = props
    // Define a regular expression to match span tags and extract both text and style
    const regex = /<span.*?style="(.*?)".*?>(.*?)<\/span>/g

    // Extract text and style using regular expression
    const matches = html.matchAll(regex)

    // Extracted text and style list
    const textAndStyleList = Array.from(matches, (match) => ({
        style: match[1],
        text: match[2].replace(/<[^>]*>/g, ''), // Remove any HTML tags
    }))

    return (
        <div>
            {/* Render the extracted text with styles */}
            {textAndStyleList.map(({ style, text }, index) => (
                <p
                    key={index}
                    style={style !== '' ? { ...parseStyle(style) } : undefined}
                >
                    {text}
                </p>
            ))}
        </div>
    )
}

// Function to parse style string into style object
const parseStyle = (styleString: string): Record<string, string> => {
    const styles = styleString.split(';')
    const styleObject: Record<string, string> = {}
    styles.forEach((style) => {
        const [key, value] = style.split(':')
        if (key.trim().length > 0 && value.trim() !== '') {
            styleObject[key.trim()] = value.trim()
        }
    })
    return styleObject
}

export default HTMLcodeToReact
