import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    type PartnershipDocumentView,
    type CompanyAttachment,
} from '../../../../../../Types/Client/RegisteredClients/Individual'
import {
    EyeOutlined,
    CloudDownloadOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import commontStyles from './document.less'
import commoncss from '../../../../../../Utils/Common.less'
import DocumentViewModelForm from './documentViewModel'
import {
    SaveOfficerDocument,
    getClientCompanyDetails,
} from '../../../../../../Services/Individual'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import ServiceDocumentUpload from './serviceDocumentUpload'
import { GetDocumenttypeList } from '../../../../../../Services/Common'

const DocumentMain = (
    props: PartnershipDocumentView & {
        individualId: number
        companyservicedetails: any
    }
): React.ReactElement => {
    const {
        individualTaps,
        documenttypeList,
        individualId,
        companyservicedetails,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [viewModalVisible, setViewModalVisible] = useState(false)
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(0)
    const [companyAttachmentId, setCompanyAttachmentId] = useState<number>(0)
    const [selectedDocument, setSelectedDocument] = useState<string>('')
    const [downloadShowId, setDownloadShowId] = useState<number>(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetDocumenttypeList(dispatch, 3, 1, 3)
    }, [])

    const OpenModel = (attachmentPath: string, dowId: number): void => {
        setSelectedDocument(attachmentPath)
        setViewModalVisible(true)
        setDownloadShowId(dowId)
    }

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const columns = [
        {
            title: 'Document Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => (
                <Space size={'middle'}>
                    {record?.attachmentPath !== '' &&
                    record?.attachmentPath !== undefined &&
                    record.attachmentPath !== null ? (
                        <>
                            <Tooltip title={`View`}>
                                <EyeOutlined
                                    onClick={() => {
                                        OpenModel(record.attachmentPath, 0)
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={`Download`}>
                                <CloudDownloadOutlined
                                    onClick={() => {
                                        OpenModel(record.attachmentPath, 1)
                                    }}
                                />
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip title={`Not Available`}>
                                <EyeOutlined />
                            </Tooltip>
                            <Tooltip title={`Not Available`}>
                                <CloudDownloadOutlined />
                            </Tooltip>
                        </>
                    )}
                </Space>
            ),
        },
    ]

    const Servicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    attachmentPath: string
                }
            ) => (
                <Space size="middle">
                    <Tooltip title="View Document">
                        <EyeOutlined
                            onClick={() => {
                                OpenModel(record.attachmentPath, 0)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Download Document">
                        <CloudDownloadOutlined
                            onClick={() => {
                                OpenModel(record.attachmentPath, 1)
                            }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const filterServicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => <Space size="middle"></Space>,
        },
    ]

    const uniqueServiceDocuments = Array.from(
        new Set(
            individualTaps?.serviceDocumentList?.map(
                (document) => document.companyAttachmentId
            )
        )
    )?.map((companyAttachmentId) =>
        individualTaps?.serviceDocumentList.find(
            (document) => document.companyAttachmentId === companyAttachmentId
        )
    )
    const handleDocumentTitleChange = (value: number): void => {
        setSelectedDocumentType(value)
        setUploadFileList([])
    }

    useEffect(() => {
        const matchingDocument = individualTaps?.generalDocumentList?.find(
            (document) => document.documentBaseId === selectedDocumentType
        )
        if (matchingDocument != null) {
            const companyAttachmentIds = matchingDocument.companyAttachmentId
            setCompanyAttachmentId(companyAttachmentIds)
        } else {
            setCompanyAttachmentId(0)
        }
    }, [selectedDocumentType])

    const activeServices = companyservicedetails?.data?.filter(
        (service: { status: string }) => service.status === 'Active'
    )

    const servicedocumentName = uniqueServiceDocuments?.map((service) =>
        service?.serviceName.toUpperCase()
    )

    // Filter out items from 'a' that have a service name present in 'activeServices'
    const filteredServiceData = activeServices?.filter(
        (item: { serviceName: string }) => {
            const serviceName = item.serviceName.toUpperCase()
            const adjustedServiceName =
                serviceName === 'AA' ? 'ANNUAL ACCOUNTS' : serviceName
            return !servicedocumentName.includes(adjustedServiceName)
        }
    )

    const pensionDocuments = uniqueServiceDocuments
        ?.filter((document) => document?.serviceName === 'Pension')
        .map((document) => ({
            ...document,
            key: document?.companyAttachmentId,
        }))

    const pensionServiceNames = pensionDocuments.map((doc) => doc.serviceName)

    // Filter out services from filteredServiceData
    const updatedServiceData = filteredServiceData?.filter(
        (service: { serviceName: string }) =>
            !pensionServiceNames.includes(service.serviceName)
    )
    const businessTypeId = 3
    const tabIndex = 3
    const fetchData = (): void => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            individualId,
            tabIndex
        )
    }

    const handeleCompanDocumentSave = (): void => {
        let documentBaseNames = ''

        switch (selectedDocumentType) {
            case 18:
                documentBaseNames = 'Letter of Engagement'
                break
            case 20:
                documentBaseNames = 'ID Proof'
                break
            case 21:
                documentBaseNames = 'Address Proof'
                break
            case 26:
                documentBaseNames = 'Individual UTR Certificate'
                break
            case 19:
                documentBaseNames = 'AML Document'
                break
            default:
                documentBaseNames = ''
                break
        }
        let attachmentPaths = ''
        if (uploadFileList?.length > 0) {
            attachmentPaths = uploadFileList[0]
        }
        const savedata = {
            companyAttachmentId: companyAttachmentId ?? 0,
            companyTypeId: 3,
            referenceId: individualId,
            referenceId2: 0,
            documentBaseId: selectedDocumentType ?? 0,
            documentBaseName: documentBaseNames,
            attachmentPath: attachmentPaths ?? '',
            individualId: 0,
            type: 'GENERAL',
            ppExpiryDate: '',
            dlExpiryDate: '',
            prExpiryDate: '',
        }
        void SaveOfficerDocument(dispatch, savedata)
        fetchData()
        setUploadFileList([''])
        form.resetFields(['attachmentPath'])
    }
    const updatedOptions = [
        { value: 1, label: 'Please Select' },
        ...documenttypeList,
    ]

    return (
        <>
            <Row>
                <Col span={24}>
                    <Collapse
                        expandIconPosition="end"
                        accordion
                        defaultActiveKey={['1']}
                    >
                        <Collapse.Panel header="Individual Document" key="1">
                            <Row gutter={[24, 16]}>
                                <Col span={12}>
                                    <Table
                                        dataSource={
                                            individualTaps?.generalDocumentList
                                        }
                                        columns={columns}
                                        pagination={false}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className={commoncss.formWrapper}>
                                        <Form
                                            name="complex-form"
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            layout={'vertical'}
                                            autoComplete="off"
                                            form={form}
                                        >
                                            <Row
                                                gutter={[24, 16]}
                                                style={{
                                                    marginBottom: '100px',
                                                }}
                                            >
                                                <Col span={24}>
                                                    <Form.Item<CompanyAttachment>
                                                        label="Document Type"
                                                        name="title"
                                                        className={
                                                            commontStyles.CorporteDocumentGeenreal
                                                        }
                                                        initialValue={1}
                                                    >
                                                        <Select
                                                            allowClear
                                                            placeholder="Select a Document Type"
                                                            onChange={(
                                                                value: number
                                                            ) => {
                                                                handleDocumentTitleChange(
                                                                    value
                                                                )
                                                            }}
                                                            options={
                                                                updatedOptions
                                                            }
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option?.label
                                                                    ?.toLowerCase()
                                                                    .includes(
                                                                        input.toLowerCase()
                                                                    ) ?? false
                                                            }
                                                            showSearch
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={[24, 16]}>
                                                <Col
                                                    span={24}
                                                    className={
                                                        commontStyles.attchment
                                                    }
                                                >
                                                    <Form.Item
                                                        name={'attachmentPath'}
                                                    >
                                                        <S3UploadMultiple
                                                            name={
                                                                'attachmentPath'
                                                            }
                                                            value={
                                                                uploadFileList
                                                            }
                                                            setFormData={
                                                                handleSetFormData
                                                            }
                                                            corporate={
                                                                'Induvidual'
                                                            }
                                                            corporateid={
                                                                individualId
                                                            }
                                                            documenttype={
                                                                'General'
                                                            }
                                                            documentbaseId={
                                                                selectedDocumentType
                                                            }
                                                            memberpersonId={0}
                                                            selectedDocumentType={
                                                                selectedDocumentType
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            {ActivePremission(
                                                '3000',
                                                PermissionType.SAVE
                                            ) ? (
                                                <Row
                                                    gutter={16}
                                                    className={
                                                        commontStyles.savebuttonless
                                                    }
                                                >
                                                    <Col offset={20} span={2}>
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                handeleCompanDocumentSave
                                                            }
                                                        >
                                                            <SaveOutlined />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                        {uniqueServiceDocuments?.map((document, index) => (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>{document?.title}</span>
                                    </div>
                                }
                                key={index + 2}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                document != null
                                                    ? [document]
                                                    : []
                                            }
                                            columns={Servicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            indvidualId={individualId}
                                            companyAttachmentId={
                                                document?.companyAttachmentId
                                            }
                                            serviceName={document?.serviceName}
                                            documentBaseId={
                                                document?.documentBaseId
                                            }
                                            documentType={document?.documentType.toString()}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        ))}
                        {pensionDocuments?.length > 0 && (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>Pension Document</span>
                                    </div>
                                }
                                key={'4'}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                pensionDocuments !== undefined
                                                    ? pensionDocuments?.map(
                                                          (doc) => ({
                                                              ...doc,
                                                              attachmentPath:
                                                                  doc.attachmentPath ??
                                                                  '', // Add a default value or handle accordingly
                                                          })
                                                      )
                                                    : []
                                            }
                                            columns={Servicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            indvidualId={individualId}
                                            companyAttachmentId={0}
                                            serviceName={'Pension'}
                                            documentBaseId={0}
                                            documentType={''}
                                            pensionData={pensionDocuments}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        )}
                        {updatedServiceData?.map(
                            (document: any, index: number) => (
                                <Collapse.Panel
                                    header={
                                        <div>
                                            <span>
                                                {document?.serviceName} File
                                                Document
                                            </span>
                                        </div>
                                    }
                                    key={index}
                                >
                                    <Row gutter={[24, 16]}>
                                        <Col span={12}>
                                            <Table
                                                dataSource={
                                                    document != null
                                                        ? [document]
                                                        : []
                                                }
                                                columns={filterServicecolumns}
                                                pagination={false}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ServiceDocumentUpload
                                                indvidualId={individualId}
                                                companyAttachmentId={0}
                                                serviceName={
                                                    document?.serviceName
                                                }
                                                documentBaseId={
                                                    document?.serviceName ===
                                                    'VAT'
                                                        ? 8
                                                        : document?.serviceName ===
                                                            'PAYE'
                                                          ? 10
                                                          : document?.serviceName ===
                                                              'CT600'
                                                            ? 9
                                                            : document?.serviceName ===
                                                                'AA'
                                                              ? 16
                                                              : 0
                                                }
                                                documentType={'0'}
                                                fetchData={fetchData}
                                            />
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            )
                        )}
                    </Collapse>
                </Col>
            </Row>
            <Modal
                title="Documents"
                onCancel={() => {
                    setViewModalVisible(false)
                }}
                open={viewModalVisible}
                footer={null}
                width={'50%'}
            >
                <DocumentViewModelForm
                    data={selectedDocument}
                    itshow={downloadShowId}
                />
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any): PartnershipDocumentView => {
    return {
        individualTaps: state.client.individualTaps,
        documenttypeList: state.common.documenttypeList,
    }
}

export default connect(mapStateToProps)(DocumentMain)
