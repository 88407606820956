import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Tree,
    type TreeDataNode,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import tabStyles from '../Tabs/Tabs.less'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import {
    type ProjectTier,
    type ProjectManagmentTierFormDataInterface,
    type ProjectManagmentTierListInterface,
} from '../../../../Types/ProjectMangement'
import {
    AddTierDetails,
    DeleteTier,
    GetTierList,
} from '../../../../Services/ProjectManagement'
import { CarryOutOutlined, DeleteOutlined } from '@ant-design/icons'
import AddNewButton from '../../../../Components/AddNewButton'
import FullWidthModal from '../../../../Components/FullWidthModal'

interface MyTreeNode extends TreeDataNode {
    children: MyTreeNode[]
    hasChildren?: boolean
    isPlaceholder?: boolean
    path: string
}

export const ProjectItemDefaultData = {
    projectId: '0',
    projectName: '',
    departmentId: '',
    department: '',
    projectDescription: '',
    projectStartDate: '01/01/1900',
    projectEndDate: '01/01/1900',
    completedTaskCount: 0,
    completedHoursCount: 0,
    pendingTaskCount: 0,
    assignedTaskCount: 0,
    inProgressTaskCount: 0,
    clientCategoryName: '',
    clientCategoryId: 0,

    projectLeadId: 0,
    projectLeadName: '',
    assignToUserId: 0,
    assignUserName: '',
    projectPrefix: '',
    designationId: '0',
}
type VisibleTiers = Record<number, boolean>

const ProjectList = (
    props: ProjectManagmentTierListInterface & { projectId: string }
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        ProjectManagmentTierList,
        ProjectManagmentTierListCount,
        projectId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)

    useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [treeData, setTreeData] = useState<TreeDataNode[]>([])
    const [newTaskModalVisible, setNewTaskModalVisible] = useState(false)
    const [currentParentId, setCurrentParentId] = useState<string | null>(null)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [form] = Form.useForm()
    const [projectTierId, setProjectId] = useState('')
    const [selectedPath, setSelectedPath] = useState('')
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        setCommonTableParamsData(tableParams)
        GetTierList(dispatch, projectId)
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: ProjectManagmentTierListCount,
            },
        })
    }, [ProjectManagmentTierListCount])
    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const [visibleTiers, setVisibleTiers] = useState<VisibleTiers>({})

    const toggleProjectTierVisibility = (tierId: number): void => {
        setVisibleTiers((prev) => ({
            ...prev,
            [tierId]: !prev[tierId],
        }))
    }

    const generateTreeData = (
        projects: ProjectManagmentTierFormDataInterface[],
        parentPath = ''
    ): MyTreeNode[] => {
        const createTreeNode = (
            project: ProjectManagmentTierFormDataInterface,
            isRoot = false,
            parentPath = ''
        ): MyTreeNode => {
            const title = isRoot
                ? project.projectTierName
                : project.projectTierName
            const currentPath = isRoot ? title : `${parentPath} / ${title}`

            const node: MyTreeNode = {
                title: (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '15px',
                            // border: '1px solid #ccc',
                            // borderRadius: '5px',
                            padding: '10px',
                        }}
                    >
                        <div>
                            <CarryOutOutlined
                                style={{ marginRight: 8, fontSize: '15px' }}
                                onClick={() => {
                                    toggleProjectTierVisibility(
                                        Number(project.projectTierId)
                                    )
                                }}
                            />
                            {title}
                        </div>
                        <DeleteOutlined
                            style={{ marginLeft: '20px' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                deleteModalFunction(
                                    project.projectTierId.toString()
                                )
                            }}
                        />
                    </div>
                ),
                key: project.projectTierId,
                children: [],
                path: currentPath,
            }

            if (visibleTiers[Number(project.projectTierId)]) {
                node.children = project.children.map((child) =>
                    createTreeNode(child, false, currentPath)
                )

                node.children.push({
                    title: (
                        <span
                            className={tabStyles.addNewSubtask}
                            onClick={() => {
                                openNewTaskModal(
                                    project.projectTierId.toString()
                                )
                            }}
                        >
                            Add Tier
                        </span>
                    ),
                    key: `new-${project.projectTierId}`,
                    isPlaceholder: true,
                    children: [],
                    path: '',
                })
            }

            return node
        }

        return projects.map((project) => createTreeNode(project, true))
    }

    const openNewTaskModal = (parentId: string = '0'): void => {
        setCurrentParentId(parentId)
        setNewTaskModalVisible(true)
    }
    const handleAddNewSubtask = async (
        taskData: ProjectTier
    ): Promise<void> => {
        if (currentParentId === null) {
            console.error('Parent tier ID is null.')
            return
        }

        try {
            const success = await AddTierDetails(dispatch, {
                ...taskData,
                parentTierId: currentParentId,
            })

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (success) {
                getTableData()
            }
        } catch (error) {
            console.error('Failed to add tier:', error)
        }
    }

    const onSelect = (selectedKeys: React.Key[], info: any): void => {
        if (info.node.isPlaceholder !== undefined) {
            openNewTaskModal(info.node.key.replace('new-', ''))
        } else {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            setSelectedPath(info.node.path || '')
        }
    }

    useEffect(() => {
        const newTreeData = generateTreeData(ProjectManagmentTierList)
        setTreeData(newTreeData)
    }, [ProjectManagmentTierList, visibleTiers])

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        setProjectId(id)
    }

    const deleteProject = (): void => {
        DeleteTier(dispatch, projectTierId, getTableData)
        deleteModalFunction('')
    }

    const onFormSubmit = (values: any): void => {
        const taskData: ProjectTier = {
            projectTierId: '0',
            projectId,
            projectTierName: values.taskName,
            parentTierId: currentParentId ?? '0',
        }
        void handleAddNewSubtask(taskData)
        form.resetFields()
        setNewTaskModalVisible(false)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Card
                title="Project Management"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openNewTaskModal('0')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <Row>
                    <Col span={24}>
                        <div
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            <strong>Current Path:</strong> {selectedPath}
                        </div>
                    </Col>
                </Row>
                <Col span={24}>
                    <Tree
                        treeData={treeData}
                        onSelect={onSelect}
                        showIcon
                        autoExpandParent
                    />
                </Col>

                <Modal
                    title="Add New Subtier"
                    open={newTaskModalVisible}
                    onCancel={() => {
                        setNewTaskModalVisible(false)
                    }}
                    footer={null}
                >
                    <div className={commonStyles.formWrapper}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFormSubmit}
                            requiredMark={customizeRequiredMark}
                        >
                            <Form.Item
                                name="taskName"
                                label="Task Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the task name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Add Task
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    open={createEditModalStatus}
                ></Modal>

                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Tier Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteProject}
                />
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ProjectManagmentTierListInterface => {
    return {
        ProjectManagmentTierList: state.initial.ProjectManagmentTierList,
        ProjectManagmentTierListCount:
            state.initial.ProjectManagmentTierListCount,
    }
}

export default connect(mapStateToProps)(ProjectList)
