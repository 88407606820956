import axios from '../Config/AxiosConfig'
import type { Dispatch } from '@reduxjs/toolkit'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import { messageList, notificationlist } from '../Reducer/NotificationReducer'
import { type TableListInterface } from '../Types/CommonType'
import { type NotificationTableList } from '../Types/Notifications'

export const GetNotificationDetials = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(`/api/Notification/GetNotificationPanelInfo/1/10`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(messageList({ ...response?.data }))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetReadMessage = (
    dispatch: Dispatch,
    typeId: number,
    notificationId: number,
    status: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetMarkasRead/2/${typeId}/${notificationId}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                GetNotificationDetials(dispatch)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetNotificationList = (
    dispatch: Dispatch,
    buttonId: number,
    flagID: number,
    serviceId: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Notification/GetUserNotification/${pageNo}/${pageSize}/${buttonId}/${flagID}/${serviceId}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: NotificationTableList) => {
                        return {
                            ...value,
                            key: value.notificationId,
                        }
                    }
                )

                response.data.data = newData
                dispatch(notificationlist({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            dispatch(
                notificationlist({
                    data: [],
                    noOfRecords: 0,
                })
            )
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
