import React, { useEffect, useState } from 'react'
import { Card, Modal, Space, Table, Tooltip } from 'antd'
import {
    EyeOutlined,
    DeleteOutlined,
    CloudDownloadOutlined,
} from '@ant-design/icons'
import commontStyles from './document.less'
import AWS from 'aws-sdk'
import {
    ErrorMessage,
    SuccessMessage,
} from '../../../../../../Utils/Notification'
import PDFPreview from '../../../../../../Components/PDFPreview'

const DocumentViewModelForm = (props: {
    data: string
    itshow: number
}): React.ReactElement => {
    const { data, itshow } = props
    const [imagePath, setImagePath] = useState('')
    const [visible, setVisible] = useState(false)
    const [fileList, setFileList] = useState<string[]>([])
    const [previewPdf, setPdfPreview] = useState(false)
    const [pdfURL, setUrl] = useState('')
    const url = new URL(data)
    const directoryPath =
        url.pathname
            .substring(0, url.pathname.lastIndexOf('/'))
            .replace(/^\/|\/$/g, '') + '/'

    const s3 = new AWS.S3()
    const bucketName =
        process.env.REACT_APP_AWS_S3_BUCKET_NAME ?? 'iykons-dev-s3-storage'

    const fetchFileList = async (): Promise<string[]> => {
        const params = {
            Bucket: bucketName,
            Prefix: directoryPath,
        }

        try {
            const response = await s3.listObjectsV2(params).promise()

            const files: string[] = (response.Contents ?? [])
                ?.map((file) => file.Key)
                ?.filter((file): file is string => Boolean(file))

            setFileList(files)
            return files
        } catch (error) {
            return []
        }
    }

    useEffect(() => {
        void fetchFileList()
    }, [data])

    const Servicecolumns = [
        {
            title: 'File',
            dataIndex: 'attachmentPath',
            key: 'key',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => {
                return (
                    <Space size="middle">
                        <Space size="middle">
                            {itshow === 0 ? (
                                <>
                                    <Tooltip title="View">
                                        <EyeOutlined
                                            onClick={() => {
                                                handleViewImage(record.key)
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <DeleteOutlined
                                            onClick={() => {
                                                void handleDeleteClick(
                                                    record.attachmentPath
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                <Tooltip title="Download Document">
                                    <a
                                        href={`https://${bucketName}.s3.eu-west-1.amazonaws.com/${record.key}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={record.attachmentPath}
                                    >
                                        <CloudDownloadOutlined />
                                    </a>
                                </Tooltip>
                            )}
                        </Space>
                    </Space>
                )
            },
        },
    ]
    const getFileExtension = (url: string | undefined): string | undefined => {
        if (url != null) {
            return url.split('.').pop()?.toLowerCase()
        }
        return undefined
    }
    const handleViewImage = (imagePath: string): void => {
        const newpath = `https://${bucketName}.s3.eu-west-1.amazonaws.com/${imagePath}`

        const fileExtension = getFileExtension(newpath)
        if (fileExtension === 'pdf') {
            setUrl(newpath)
            setPdfPreview(true)
            setVisible(false)
        } else {
            setImagePath(newpath)
            setVisible(true)
        }
    }

    const handleDeleteClick = async (filePath: string): Promise<void> => {
        const params = {
            Bucket: bucketName,
            Key: directoryPath + filePath,
        }

        try {
            await s3.deleteObject(params).promise()
            SuccessMessage('File deleted successfully:')
            const updatedFileList = await fetchFileList()
            setFileList(updatedFileList)
        } catch (error) {
            ErrorMessage('Error deleting S3 object:')
        }
    }

    const changeCreateEditModalStatus = (): void => {
        setPdfPreview(false)
    }
    return (
        <div>
            <Card>
                {fileList?.length !== 0 ? (
                    <Table
                        columns={Servicecolumns}
                        dataSource={fileList?.map((file) => ({
                            key: file,
                            attachmentPath: file.substring(
                                file.lastIndexOf('/') + 1
                            ),
                        }))}
                        pagination={false}
                    />
                ) : (
                    <h4>No file uploaded.</h4>
                )}
            </Card>
            <Modal
                width={'60%'}
                open={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <img
                    src={imagePath}
                    alt="View"
                    className={commontStyles.imagewidth}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={'sss'}
                open={previewPdf}
            >
                <PDFPreview pdfUrl={pdfURL} />
            </Modal>
        </div>
    )
}

export default DocumentViewModelForm
