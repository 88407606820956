import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import commonStyles from '../../Utils/Common.less'
import CommonButton from '../../Components/Commonbutton'
import DetailReport from './DetailReport'
import TotalReport from './TotalReport'

const StaffwiseReport = (): React.ReactElement => {
    const [activeButton, setActiveButton] = useState(1)
    const [showDetailReport, setshowDetailReport] = useState<boolean>(true)
    const [showTotalReport, setshowToatalRerport] = useState<boolean>(true)

    useEffect(() => {
        window.scroll(0, 0)
        setshowToatalRerport(true)
    }, [])

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setshowDetailReport(true)
            setshowToatalRerport(false)
        }
        if (buttonName === 2) {
            setshowToatalRerport(true)
            setshowDetailReport(false)
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return <DetailReport />
        } else if (activeButton === 2) {
            return <TotalReport />
        }
        return <></>
    }

    return (
        <Card title="Staffwise Report" className={commonStyles.card}>
            <CommonButton
                name="Detail Report"
                isActive={activeButton === 1}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(1)
                }}
            />
            <CommonButton
                name="Total Report"
                isActive={activeButton === 2}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(2)
                }}
            />

            {showDetailReport && activeButton === 1 && <DetailReport />}
            {showTotalReport && activeButton === 2 && <TotalReport />}
        </Card>
    )
}

export default StaffwiseReport
