import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'

import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'

import {
    type ListBackendInterface,
    type ListFrontEndInterface,
    type TableListInterface,
} from '../Types/CommonType'

import type {
    onboardingPartnershipFormDataInterface,
    onboardingPartneshipHomeApi,
} from '../Types/Client/OnboardingClients/OnBoardingPartnership'
import { onboardingpartnerList, priorityDropDownList } from '../Reducer/Client'
import { serviceDropDownList } from '../Reducer/InitialReducer'
import { type CreateTaskInterface } from '../Types/Client/RegisteredClients/Corporate'

export const GetParnershipList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Onboarding/GetPartnershipList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: onboardingPartnershipFormDataInterface) => {
                        return {
                            ...value,
                            key: value.partnershipId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(onboardingpartnerList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetServiceDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/18')
        .then((response) => {
            if (response.status === 200) {
                const serviceListData: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(serviceDropDownList(serviceListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const ActiveInactiveParnership = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetRecordActiveStatus/4/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeleteParnership = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/4/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPriorityList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/24')
        .then((response) => {
            if (response.status === 200) {
                const newPriority: ListFrontEndInterface[] =
                    response.data.data.map((data: ListBackendInterface) => ({
                        value: data.stdId,
                        label: data.stdName,
                    }))
                dispatch(priorityDropDownList(newPriority))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveTask = async (
    dispatch: Dispatch,
    data: CreateTaskInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTask`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SavePartnershipData = async (
    dispatch: Dispatch,
    data: onboardingPartneshipHomeApi,
    saveCallBack: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = {
        partnershipId: 0,
        clientCategoryId: data.clientCategoryId ?? 0,
        clientNumber: data.clientNumber ?? '0',
        tradingName: data.tradingName ?? '',
        partnershipUTR: data.partnershipUTR ?? '',
        busAddressOne: data.busAddressOne ?? '',
        busAddressTwo: data.busAddressTwo ?? '',
        busTown: data.busTown ?? '',
        busCountry: data.busCountry ?? '',
        busPostCode: data.busPostCode ?? '',
        corrAddressOne: data.corrAddressOne ?? '',
        corrAddressTwo: data.corrAddressTwo ?? '',
        corrTown: data.corrTown ?? '',
        corrCountry: data.corrCountry ?? '',
        corrPostCode: data.corrPostCode ?? '',
        isActive: 'Active',
        isDelete: 'Inactive',
    }
    try {
        const response = await axios.post(
            `api/Partnership/SetPartnership`,
            bodyData
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
            saveCallBack(response.data.data)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}
