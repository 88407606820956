import React from 'react'
import styles from './login.less'
import {
    type changePinInterface,
    type changePinDataInterface,
} from '../../Types/Auth'
import { Col, Row, Form, Button } from 'antd'
import commonStyles from '../../Utils/Common.less'
import { ChangePinApi } from '../../Services/Auth'
import { useDispatch } from 'react-redux'
import InputField from '../../Components/InputField'
import InputFieldPassword from '../../Components/InputFieldPassword'
import { useNavigate } from 'react-router-dom'

const ChangePin = (props: changePinInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { code } = props

    const onSubmit = (data: changePinDataInterface): void => {
        const newData = {
            resetCode: code,
            emailAddress: data.emailAddress,
            pin: data.pin,
            confirmPIN: data.confirmPIN,
        }
        ChangePinApi(dispatch, newData, () => {
            navigate(`/`)
            window.location.reload()
        })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>Change Pin</div>
                            <div className={styles.loginTittleDesc}>
                                Reset your Pin to access your account
                            </div>
                        </div>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                        >
                            <InputField
                                name="emailAddress"
                                placeHolder="ex. email@domain.com"
                                label="EmailAddress"
                                reuireMessage="Please input your Email!"
                            />
                            <InputFieldPassword
                                name="pin"
                                label="Pin"
                                reuireMessage="Please input your pin!"
                            />
                            <InputFieldPassword
                                name="confirmPIN"
                                label="ConfirmPin"
                                reuireMessage="Please confirm your password!"
                            />
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.loginFormButton}
                                >
                                    SignIn
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className={styles.doNotHaveAccountText}>
                            Don’t have an account?
                            <a href="">SignIn</a>
                        </div>
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ChangePin
