import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Flex,
    Form,
    Row,
    Select,
    Space,
    Tooltip,
} from 'antd'
import commonStyles from '../../Utils/Common.less'
import {
    UnorderedListOutlined,
    AppstoreOutlined,
    SearchOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    type ToDoAdvanceSeach,
    type ToDoViewAllListStateInterface,
} from '../../Types/ToDoViewAllList'
import { GetCategoryDropDownList } from '../../Services/GroupAndPartners'
import ThingsToDoTaps from './thingsToDoViewAllButtons'
import dayjs from 'dayjs'
import { GetUserList } from '../../Services/User'
import style from './tingtodo.less'
const { RangePicker } = DatePicker
interface ListDataInterface {
    value: number
    label: string
}
const AdvanceSearchDefaultdata: ToDoAdvanceSeach = {
    targetDate: ['', ''],
    nextStatmentToDate: ['', ''],
    assignedbyId: 0,
    assignedToId: 0,
}
const ToDoViewAllList = (
    props: ToDoViewAllListStateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { categoryDropDownList, userList } = props
    const id = Number(localStorage.getItem('mode'))
    const serviceFlag = Number(localStorage.getItem('serviceFlag'))
    const statusId = Number(localStorage.getItem('statusId')) ?? 0
    const [selectedIcon, setSelectedIcon] = useState(1)
    const [selectedId, setSelectedId] = useState(0)
    const [selectedStatusId, setSelectedStatus] = useState(statusId)
    const [targetDate, setTargetDate] = useState<string[]>([])
    const [nextStateMentDate, setNextStetmentDate] = useState<string[]>([])
    const [collapsActive, setCollapsActive] = useState(false)
    const [form] = Form.useForm()
    const [searchData, setSearchData] = useState<ToDoAdvanceSeach>(
        AdvanceSearchDefaultdata
    )
    const [clientTypeId, setClientTypeId] = useState(0)
    const [serviceId, setServiceId] = useState(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetUserList(dispatch)
        window.scrollTo(0, 0)
    }, [dispatch])

    const toggleIcon = (id: number): void => {
        setSelectedIcon(id === 1 ? 1 : 2)
    }

    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 0 : Number(value))
    }

    const handleSelectChange2 = (value: number): void => {
        localStorage.setItem('statusId', value.toString())
        setSelectedStatus(value)
    }
    const statusdata: ListDataInterface[] = [
        {
            value: 0,
            label: 'In Progress',
        },
        {
            value: 1,
            label: 'Overdue',
        },
        {
            value: 2,
            label: 'Completed',
        },
    ]

    const ClientData: ListDataInterface[] = [
        {
            value: 0,
            label: 'All',
        },
        {
            value: 1,
            label: 'Corporate',
        },
        {
            value: 2,
            label: 'Sole Trader',
        },
        {
            value: 3,
            label: 'Individual',
        },
        {
            value: 4,
            label: 'Partnership',
        },
    ]

    const onTargeDateChage = (
        dates: any,
        dateStrings: [string, string]
    ): void => {
        setTargetDate(dateStrings)
    }

    const onNextStetmentDate = (
        dates: any,
        dateStrings: [string, string]
    ): void => {
        setNextStetmentDate(dateStrings)
    }

    useEffect(() => {
        form.setFieldValue('targetDate', targetDate)
        form.setFieldValue('nextStatmentToDate', nextStateMentDate)
    }, [targetDate, nextStateMentDate])

    const toggleCollapse = (): void => {
        setCollapsActive(!collapsActive)
        form.resetFields()
        setTargetDate([])
        setNextStetmentDate([])
    }

    const handleResetFields = (): void => {
        form.resetFields()
        setTargetDate([])
        setNextStetmentDate([])
    }

    const onClick = (): void => {
        const formData = form.getFieldsValue()

        setSearchData(formData)
    }
    const AdvanceSearch = (): React.ReactElement => {
        return (
            <Collapse
                expandIconPosition="end"
                activeKey={collapsActive ? '1' : undefined}
                onChange={toggleCollapse}
            >
                <Collapse.Panel header="Advance Search" key={1}>
                    <div className={commonStyles.formWrapper}>
                        <Form
                            name="complex-form"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            layout={'vertical'}
                            autoComplete="off"
                            form={form}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Target Date"
                                        name="targetDate"
                                    >
                                        <Space direction="vertical">
                                            <RangePicker
                                                format="MM/DD/YYYY"
                                                className={style.dateWidth}
                                                onChange={(
                                                    date,
                                                    dateString
                                                ) => {
                                                    onTargeDateChage(
                                                        date,
                                                        dateString
                                                    )
                                                }}
                                                value={
                                                    targetDate != null
                                                        ? [
                                                              dayjs(
                                                                  targetDate[0]
                                                              ),
                                                              dayjs(
                                                                  targetDate[1]
                                                              ),
                                                          ]
                                                        : null
                                                }
                                            />
                                        </Space>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Next Statment Date"
                                        name="nextStatmentToDate"
                                    >
                                        <Space direction="vertical">
                                            <RangePicker
                                                className={style.dateWidth}
                                                format="MM/DD/YYYY"
                                                onChange={(
                                                    date,
                                                    dateString
                                                ) => {
                                                    onNextStetmentDate(
                                                        date,
                                                        dateString
                                                    )
                                                }}
                                                value={
                                                    nextStateMentDate != null
                                                        ? [
                                                              dayjs(
                                                                  nextStateMentDate[0]
                                                              ),
                                                              dayjs(
                                                                  nextStateMentDate[1]
                                                              ),
                                                          ]
                                                        : null
                                                }
                                            />
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Assigned by"
                                        name="assignedbyId"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Please select"
                                            onChange={() => {}}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={userList}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Assigned To"
                                        name="assignedToId"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Please select"
                                            onChange={() => {}}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={userList}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16} justify="end">
                                <Col>
                                    <Button
                                        danger
                                        type="primary"
                                        onClick={handleResetFields}
                                        style={{ marginRight: 8 }}
                                    >
                                        <CloseOutlined />
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={onClick}>
                                        <SearchOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Collapse.Panel>
            </Collapse>
        )
    }
    const handleSelectbusiness = (value: number): void => {
        setClientTypeId(value)
    }
    return (
        <>
            <Card
                title={`${id === 1 ? 'Things To Do' : 'Things To Follow'}- ${
                    selectedIcon === 1 ? 'Client View' : 'Task View'
                }`}
                extra={
                    <>
                        <Flex gap={8}>
                            <Select
                                className={style.selectWidth}
                                placeholder="Please select"
                                onChange={(value) => {
                                    handleSelectChange2(value)
                                }}
                                value={selectedStatusId}
                                options={statusdata}
                            />

                            <Select
                                className={style.selectWidth}
                                placeholder="Please select"
                                onChange={handleSelectChange}
                                value={selectedId === 0 ? 'all' : selectedId}
                                options={[
                                    { value: 'all', label: 'All' },
                                    ...categoryDropDownList,
                                ]}
                            />
                            {serviceId === 9 && (
                                <Select
                                    className={style.selectWidth}
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        handleSelectbusiness(value)
                                    }}
                                    value={clientTypeId}
                                    options={ClientData}
                                />
                            )}
                            <div className={style.iconStyle}>
                                {selectedIcon === 1 ? (
                                    <Tooltip title="Task View">
                                        <UnorderedListOutlined
                                            onClick={() => {
                                                toggleIcon(2)
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Client View">
                                        <AppstoreOutlined
                                            onClick={() => {
                                                toggleIcon(1)
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </Flex>
                    </>
                }
                className={commonStyles.card}
            >
                <div className={style.advancesearchStyle}>
                    <AdvanceSearch />
                </div>
                <div>
                    <ThingsToDoTaps
                        searchData={searchData}
                        flagId={id}
                        statusId={selectedStatusId}
                        clientCategoryId={selectedId}
                        businessTypeId={clientTypeId}
                        setServiceId={setServiceId}
                        selectedIcon={selectedIcon}
                        id={id}
                        serviceid={serviceFlag}
                    />
                </div>

                <div className={commonStyles.table}></div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ToDoViewAllListStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(ToDoViewAllList)
