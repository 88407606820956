/* eslint-disable react/react-in-jsx-scope */
import { Card } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type Task,
    type subtaskinterface,
} from '../../../Types/HelpDesk/Cretetask'

import commonStyles from '../../../Utils/Common.less'
import { useEffect, type Key } from 'react'
import { SubViewDetails } from '../../../Services/Helpdesk/CreteTask'

const subtaskModal = (
    props: subtaskinterface & {
        taskId: string
        getTask: Task
        activeKey: string
    }
): React.ReactElement => {
    const { userimageList, getTask, SubViewDetail, activeKey } = props
    const dispatch = useDispatch()

    const formatDate = (dateStr: string | number | Date): string => {
        const date = new Date(dateStr)
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        })
    }

    useEffect(() => {
        const projectStatuId = getTask.projectStatusId.toString()
        SubViewDetails(dispatch, projectStatuId, '=NA=', 1, 10, 0, 0)
    }, [dispatch, activeKey, getTask.projectStatusId, SubViewDetails])

    const filterListTask = SubViewDetail.filter(
        (task) => task.taskId === getTask.taskId
    )

    return (
        <>
            {filterListTask?.map((task, index) => (
                <div key={index}>
                    {Array.isArray(task.subTaskViews) &&
                        task.subTaskViews.map(
                            (
                                subtask: {
                                    subTaskName: string
                                    daysColourCode: any
                                    toDate: string | number | Date
                                    assignedToName: string
                                },
                                subIndex: Key | null | undefined
                            ) => {
                                return (
                                    <Card
                                        key={subIndex}
                                        className={commonStyles.card}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '20px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                }}
                                            >
                                                <div>
                                                    <span
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            width: '200px',
                                                        }}
                                                    >
                                                        {subtask.subTaskName}
                                                    </span>
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                subtask.daysColourCode,
                                                            color: 'white',
                                                            padding: '5px 8px',
                                                            display:
                                                                'inline-block',
                                                        }}
                                                    >
                                                        {formatDate(
                                                            subtask.toDate
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                }}
                                            >
                                                <div>
                                                    {userimageList?.map(
                                                        (user: {
                                                            userName: string
                                                            imageUrl: string
                                                        }) => {
                                                            if (
                                                                user.userName ===
                                                                subtask.assignedToName
                                                            ) {
                                                                return (
                                                                    <img
                                                                        key={`${user.userName}-assignedTo`}
                                                                        src={
                                                                            user?.imageUrl
                                                                        }
                                                                        alt={`${user.userName}-assignedTo`}
                                                                        style={{
                                                                            width: '32px',
                                                                            height: '32px',
                                                                            borderRadius:
                                                                                '50%',
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            return null
                                                        }
                                                    )}
                                                </div>
                                                {!userimageList.some(
                                                    (user) =>
                                                        user.userName ===
                                                        subtask.assignedToName
                                                ) && (
                                                    <span>
                                                        {subtask.assignedToName}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                )
                            }
                        )}
                </div>
            ))}
        </>
    )
}

const mapStateToProps = (state: any): subtaskinterface => {
    return {
        userList: state.user.userList,
        userimageList: state.helpdesk.userimageList,
        subtaskDetailsList: state.helpdesk.subtaskDetailsList,
        subtaskDetailsListCount: state.helpdesk.subtaskDetailsListCount,
        SubViewDetail: state.helpdesk.SubViewDetail,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(subtaskModal)
