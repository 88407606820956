import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { holidaysDetailsList } from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { HolidayDetailsFormDataInterface } from '../Types/HolidayDetails'
import type { TableListInterface } from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'
import handleError from '../Config/AxiosResponceStatus'

export const GetHolidayList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/General/GetHolidayList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: HolidayDetailsFormDataInterface) => {
                        return {
                            ...value,
                            key: value.companyHolidayId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(holidaysDetailsList({ ...response.data }))
            } else {
                ErrorMessage(handleError(response.data.statusCode))
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            // ErrorMessage(handleError(error.response.data.statusCode))

            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveHoliday = async (
    dispatch: Dispatch,
    data: HolidayDetailsFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetHoliday`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const ActiveInactiveHoliday = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/10/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteHoliday = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/15/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const CheckHolidayDateExist = (
    dispatch: Dispatch,
    companyHolidayId: string,
    date: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = [
        {
            fieldName: 'date',
            compareOperator: 1,
            fieldValue1: date,
            fieldValue2: 'string',
        },
        {
            fieldName: 'companyholidayid',
            compareOperator: 1,
            fieldValue1: companyHolidayId,
            fieldValue2: 'string',
        },
    ]
    axios
        .post(`/api/ExistObjectData/5`, bodyData)
        .then((response) => {
            if (response.status === 200 && Boolean(response?.data?.isExist)) {
                ErrorMessage(response.data.error.message)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching post:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
