import React from 'react'
import commonStyle from './Common.less'
import type { TablePaginationConfig } from 'antd/es/table'

function calculatePagination(
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageSizeChange: (pageSize: number) => void
): TablePaginationConfig {
    const totalPages: number = Math.ceil(totalItems / itemsPerPage)
    const showTotal = (): React.ReactElement => {
        return (
            <div className={commonStyle.custom_pagination}>
                <div className={commonStyle.left_pagination}>
                    Page {currentPage} of {totalPages}{' '}
                </div>
            </div>
        )
    }

    const paginationConfig: TablePaginationConfig = {
        current: currentPage,
        total: totalItems,
        pageSize: itemsPerPage,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', totalItems.toString()],
        showPrevNextJumpers: true,
        showTotal,
        onShowSizeChange: (current, pageSize) => {
            if (onPageSizeChange !== undefined) {
                if (pageSize === totalItems) {
                    onPageSizeChange(totalItems)
                } else {
                    onPageSizeChange(pageSize)
                }
            }
        },
        itemRender: (page: number, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <a>Previous</a>
            }
            if (type === 'next') {
                return <a>Next</a>
            }

            return originalElement
        },
    }

    return paginationConfig
}

export default calculatePagination
