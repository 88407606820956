import React, {
    useState,
    type MouseEventHandler,
    useEffect,
    useRef,
} from 'react'
import { PhoneOutlined, UserOutlined, MailOutlined } from '@ant-design/icons'
import {
    Layout,
    Menu,
    Badge,
    type MenuProps,
    Switch,
    Tooltip,
    Modal,
    Button,
    Select,
    type SelectProps,
    Drawer,
    ConfigProvider,
    Flex,
} from 'antd'
import styles from './Less/style.less'
import UserAvatar from '../Components/UserAvatar'
import { ReactComponent as NotificationIcon } from '../Assest/Svg/Notification.svg'
import { ReactComponent as SettingIcon } from '../Assest/Svg/Setting.svg'
import { ReactComponent as LoadingIcon } from '../Assest/Svg/Loading.svg'
// import { ReactComponent as HelpIcon } from '../Assest/Svg/Help.svg'
import { ReactComponent as UserIcon } from '../Assest/Svg/Users.svg'
// import { ReactComponent as SearchIcon } from '../Assest/Svg/Search.svg'
// import { ReactComponent as BodyIcon } from '../Assest/Svg/Body.svg'
import { ReactComponent as Logo } from '../Assest/Svg/logo-01.svg'
import { useDispatch, connect } from 'react-redux'
import { flag } from '../Reducer/DashboardFlagReducer'
import DashboarNotificationPopup from '../Pages/Notification/dashboadPopupNotification'
import { useNavigate } from 'react-router-dom'
import { type Data } from '../Types/Notifications'
import { GetNotificationDetials } from '../Services/Notificcations'
import axios from '../Config/AxiosConfig'
import { ErrorMessage } from '../Utils/Notification'
import DashboarMeetingPopup from '../Pages/Meeting/Meeting'
import SettingPage from '../Pages/SettingPage/setting'
import { ReactComponent as Corporate } from '../Assest/ClientTypeSvg/copo.svg'
import { ReactComponent as Induvidual } from '../Assest/ClientTypeSvg/indi-icon.svg'
import { ReactComponent as Soltrader } from '../Assest/ClientTypeSvg/sole-icon.svg'
import { ReactComponent as Partnership } from '../Assest/ClientTypeSvg/part-icon.svg'
import { ReactComponent as Others } from '../Assest/ClientTypeSvg/othe-icon.svg'
import { type UserData } from '../Types/Profile'
import { GetProfileDetails } from '../Services/Profile'
const { Header } = Layout

type MenuItem = Required<MenuProps>['items'][number]

interface InitialStateInterface {
    flag: number
    messageList: Data
    isHeaderAlts: number
    fixedMenu: number
    profiledataList: UserData
}

const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
    }
}

function LayoutBanner(
    props: {
        collapsed: boolean
        handleOnCollapse: MouseEventHandler
        messageList: Data
        isHeaderAlts: number
        fixedMenu: number
        profiledataList: UserData
    },
    dashboardFlagProp: InitialStateInterface
): React.ReactElement {
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null
    const { messageList, isHeaderAlts, profiledataList } = props
    const [open, setOpen] = useState(false)
    const [isHeaderAlt, setIsHeaderAlt] = useState<number>(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        return storedMenu != null ? JSON.parse(storedMenu) : 0
    })

    useEffect(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        if (storedMenu != null) {
            setIsHeaderAlt(JSON.parse(storedMenu))
        }
    }, [isHeaderAlts])

    // const getCollapseIcon = (): React.ReactElement => {
    //     if (collapsed) {
    //         return (
    //             <MenuUnfoldOutlined
    //                 onClick={handleOnCollapse}
    //                 className={styles.trigger}
    //             />
    //         )
    //     }
    //     return (
    //         <MenuFoldOutlined
    //             onClick={handleOnCollapse}
    //             className={styles.trigger}
    //         />
    //     )
    // }

    const dispatch = useDispatch()
    const [isFlag, setFlag] = useState<boolean>(dashboardFlagProp.flag === 1)
    const [modalVisible, setModalVisible] = useState(false)
    const [meetingmodalVisible, setMeetingmodalVisible] = useState(false)
    const navigate = useNavigate()
    const onChange = (checked: boolean): void => {
        setFlag(checked)
        dispatch(flag(checked ? 1 : 2))
    }
    const notficationCount = messageList?.totalUnreadNotificationCount
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetNotificationDetials(dispatch)
        hasFetchedOnce.current = false
    }, [dispatch])

    const settingItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Tooltip
                    placement="top"
                    title={isFlag ? 'Year wise' : 'Month wise'}
                >
                    <Switch defaultChecked size="small" onChange={onChange} />
                </Tooltip>
            ),
        },
    ]
    console.log(settingItems)
    const onClose = (): void => {
        setOpen(false)
    }
    const showDrawer = (): void => {
        setOpen(true)
    }
    const handleNameClick = (): void => {
        setModalVisible(true)
    }
    const handleMeetingClick = (): void => {
        setMeetingmodalVisible(true)
    }

    const onviewAllClick = (): void => {
        navigate(`/notification/view-all`)
        oncancelModel()
    }

    const oncancelModel = (): void => {
        setModalVisible(false)
    }

    const onClickOverview = (): void => {
        navigate(`/client-overview`)
    }

    const [searchValue, setSearchValue] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<SelectProps['options']>([])

    const image = (path: string): JSX.Element => {
        switch (path) {
            case '../assets/img/corp-icon.png':
                return <Corporate />
            case '../assets/img/indi-icon.png':
                return <Induvidual />
            case '../assets/img/part-icon.png':
                return <Partnership />
            case '../assets/img/sole-icon.png':
                return <Soltrader />
            default:
                return <Others />
        }
    }
    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <Flex gap={20}>
                                    <div>{image(client?.displayImageURL)}</div>
                                    <div>
                                        <div
                                            className={
                                                styles.clintDropdownListDiv
                                            }
                                        >
                                            <span>{client.displayName}</span>
                                        </div>
                                        <div>
                                            <span
                                                className={
                                                    styles.clientDropdownListSpan
                                                }
                                            >
                                                {client.additionalDataTwo}-
                                                {client.additionalDataOne}
                                            </span>
                                        </div>
                                        <div>
                                            <span
                                                className={
                                                    styles.clientDropdownListSpan
                                                }
                                            >
                                                <UserOutlined /> :{' '}
                                                {client.additionalDataThree}
                                            </span>
                                        </div>
                                        <div>
                                            <span
                                                className={
                                                    styles.clientDropdownListSpan
                                                }
                                            >
                                                <MailOutlined /> :{' '}
                                                {client.additionalDataFive}
                                            </span>

                                            <span
                                                style={{ marginLeft: 20 }}
                                                className={
                                                    styles.clientDropdownListSpan
                                                }
                                            >
                                                <PhoneOutlined /> :{' '}
                                                {client.additionalDataFour}
                                            </span>
                                        </div>
                                        {/* <div>
                                            <span
                                                className={
                                                    styles.clientDropdownListSpan2
                                                }
                                            >
                                                <MailOutlined /> -
                                                {client.additionalDataFive}
                                            </span>
                                        </div> */}
                                    </div>
                                </Flex>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage('Record Not Found')
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue?.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        void fetchUserData()
    }, [tokenDetails?.userId])

    const fetchUserData = async (): Promise<void> => {
        await GetProfileDetails(dispatch, tokenDetails?.userId, () => {})
    }
    const [values, setvalue] = useState<string | null>(null)
    const onChangeSearch = (value: string): void => {
        setvalue(value)
        if (value !== undefined) {
            navigate(`/overview/client-dashboard`, {
                state: { value },
            })
            setvalue(null)
            setSearchValue('') // Clears the search input
            setData([])
        }
    }

    return (
        <>
            <Header
                className={
                    isHeaderAlt === 1
                        ? styles.headerLayout
                        : styles.headerLayoutAlt
                }
            >
                <div className={styles.headerRight}>
                    <Logo style={{ width: '350px', height: 'auto' }} />

                    <ConfigProvider
                        theme={{
                            token: {
                                // Seed Token
                                colorPrimary: '#0219E6',
                            },
                            components: {
                                Select: {
                                    optionSelectedBg: '#175ca9', // Background color when an option is selected
                                    optionSelectedColor: '#fff',
                                    // optionActiveBg: '#175ca9',
                                },
                            },
                        }}
                    >
                        <Select
                            style={{
                                width: '458px',
                                marginLeft: 40,
                                marginTop: 8,
                            }}
                            loading={isLoading}
                            showSearch
                            placeholder="Search clients by name or number"
                            value={values}
                            onChange={(value) => {
                                onChangeSearch(value)
                            }}
                            onSearch={(value) => {
                                setSearchValue(value)
                            }}
                            filterOption={false}
                            allowClear
                            onClear={() => {
                                setSearchValue('')
                                setData([])
                            }}
                            optionLabelProp="label"
                            options={data}
                            optionRender={(option) => <>{option.data.desc}</>}
                            notFoundContent={null}
                        />
                    </ConfigProvider>
                </div>
                <Menu
                    // onClick={this.handleLanguageMenuClick}
                    mode="horizontal"
                    className={styles.menu}
                    items={[
                        // getItem(
                        //     <SearchIcon />,
                        //     'search',
                        //     <BodyIcon className={styles.searchIconText} />
                        // ),
                        getItem(
                            '',
                            'user',
                            <Tooltip title="Meetings">
                                <UserIcon onClick={handleMeetingClick} />
                            </Tooltip>
                        ),
                        // getItem(
                        //     '',
                        //     'help',
                        //     <Tooltip title="IYKONS-HELP">
                        //         <HelpIcon />
                        //     </Tooltip>
                        // ),
                        getItem(
                            '',
                            'loading',
                            <Tooltip title="Client Overview">
                                <LoadingIcon onClick={onClickOverview} />
                            </Tooltip>
                        ),
                        getItem(
                            '',
                            'setting',
                            <SettingIcon onClick={showDrawer} />
                        ),
                        getItem(
                            '',
                            'notification',
                            <Badge count={notficationCount ?? 0}>
                                <NotificationIcon onClick={handleNameClick} />
                            </Badge>
                        ),

                        getItem(
                            <div className={styles.avatarWidth}>
                                {UserAvatar(
                                    profiledataList?.userName ?? 'Cemal',
                                    54,
                                    profiledataList?.imageurl ??
                                        'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                                )}
                            </div>,
                            'profile'
                        ),
                    ]}
                />
            </Header>
            <Modal
                open={modalVisible}
                width={'25%'}
                footer={[
                    <Button
                        key="back"
                        onClick={onviewAllClick}
                        style={{ border: 'none' }}
                    >
                        View All Notifications
                    </Button>,
                ]}
                mask={false}
                closable={false}
                style={{ left: '30%', top: '10%' }}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >
                <DashboarNotificationPopup />
            </Modal>

            <Modal
                open={meetingmodalVisible}
                width={'26%'}
                footer={null}
                mask={false}
                closable={false}
                style={{ left: '10%', top: '10%' }}
                onCancel={() => {
                    setMeetingmodalVisible(false)
                }}
            >
                <DashboarMeetingPopup />
            </Modal>
            <Drawer
                title={`IYKONECT - Layout Settings`}
                width={450}
                onClose={onClose}
                open={open}
            >
                <SettingPage />
            </Drawer>
        </>
    )
}

const mapStateToProps = (state: any): InitialStateInterface => {
    return {
        flag: state.dashboardFlag.flag,
        isHeaderAlts: state.dashboardFlag.isHeaderAlt,
        fixedMenu: state.dashboardFlag.fixedMenu,
        messageList: state.notification.messageList,
        profiledataList: state.profile.profiledataList,
    }
}

export default connect(mapStateToProps)(LayoutBanner)
