import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../../Utils/Common.less'
import { type SoltraderExpandViewData } from '../../../../Types/ServiceSetup/ClientServiceUser'

const SoltraderExpandView = (
    props: SoltraderExpandViewData
): React.ReactElement => {
    const { soltraderData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Nature Of Business :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {soltraderData.natureOfBusiness}
                </div>
            </Row>
        </div>
    )
}

export default SoltraderExpandView
