import React, { useRef, useState } from 'react'
import { Row, Col, Card, Carousel, Empty, Tag } from 'antd'
import styles from './Itindex.less'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip } from 'chart.js'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ClientGroup } from '../../../Assest/Svg/ClientsGroup.svg'
import {
    type ItclientInfo,
    type GetITDashboardResponse,
} from '../../../Types/Dashboard'
Chart.register(ArcElement, Tooltip)
// const ONBOARDINGTAB = 'Onboarding'
const REGISTEREDTAB = 'IT Clients'

const ToDOClients = ({
    clientInfo,
}: GetITDashboardResponse): React.ReactElement => {
    const navigate = useNavigate()
    interface GraphDataType {
        label: string
        value: number
        percentage: number
        color: string
    }

    const findColor = (label: string): string => {
        switch (label) {
            case 'Registered Clients':
                return '#695EEF'
            case 'OnBoarding Clients':
                return '#50effb'
            case 'Prospect Clients':
                return '#3fe4a8'
            case 'Partnership':
                return '#FFAC30'
            case 'Ltd Company':
                return '#f27e2d'
            case 'Charity':
                return '#e4afd9'
            case 'Ad hoc':
                return '#50cd44'
            case 'Trust':
                return '#A020F0'
            case 'LLP':
                return '#A020AE'
            default:
                return '#695EEF'
        }
    }

    const getTotal = (list: number[]): number => {
        return list.length > 0
            ? list?.reduce(function (acc, obj) {
                  return acc + Number(obj)
              }, 0)
            : 0
    }

    const getGraphData = (
        list: ItclientInfo[],
        total: number
    ): GraphDataType[] | null => {
        return list.length > 0 && total > 0
            ? list.map((data: ItclientInfo) => {
                  return {
                      label: data.clientType,
                      value: data.noOfClient,
                      percentage: (data.noOfClient / total) * 100,
                      color: findColor(data.clientType),
                  }
              })
            : null
    }

    const totalClientRegister: number = getTotal(
        clientInfo?.map((item) => item.noOfClient)
    )

    const graphClientRegister: GraphDataType[] | null = getGraphData(
        clientInfo ?? [],
        totalClientRegister
    )

    const dataClientOverDueValues = {
        graphData: graphClientRegister,
        total: totalClientRegister,
    }
    const dataRegisterData =
        graphClientRegister !== null
            ? {
                  labels: dataClientOverDueValues?.graphData?.map(
                      (data) => data.label
                  ),
                  datasets: [
                      {
                          data: dataClientOverDueValues?.graphData?.map(
                              (data) => data.value
                          ),
                          backgroundColor:
                              dataClientOverDueValues?.graphData?.map(
                                  (data) => data.color
                              ),
                          display: true,
                          borderColor: '#FFFFFF',
                      },
                  ],
              }
            : null

    const carouselRef = useRef<any>(null)

    const Chart = ({
        data,
        total,
    }: {
        data: any
        total: number
    }): React.ReactElement => {
        return data != null ? (
            <>
                <div className={styles.toFollow__chart__main}>
                    <Doughnut
                        data={data}
                        width={'100%'}
                        height={'auto'}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                tooltip: {
                                    enabled: true,
                                    usePointStyle: true,
                                    yAlign: 'bottom',
                                    xAlign: 'center',
                                    // position: 'average',
                                },
                            },
                            rotation: 0,
                            circumference: 360,
                            cutout: '60%',
                            maintainAspectRatio: false,
                            responsive: true,
                        }}
                    />
                    <div className={styles.toFollow__chart__text}>
                        Total {total}
                    </div>
                </div>
            </>
        ) : (
            <div className={styles.toFollow__chart__main}>
                <Empty
                    style={{ padding: '40px 0px' }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        )
    }
    const [activeTab, setActiveTab] = useState(0)
    const onChangeTap = (currentSlide: number): void => {
        setActiveTab(currentSlide)
    }
    const goTo = (slide: number): void => {
        setActiveTab(slide)
        carouselRef?.current?.goTo(slide, false)
    }

    return (
        <div className={styles.marginRight10}>
            <Card className={`${styles.card} ${styles.client__card__height}`}>
                <div className={styles.cardContent}>
                    <Row
                        style={{
                            background: 'white',
                        }}
                    >
                        <Col span={12}>
                            <div
                                style={{
                                    display: 'flex', // Use flexbox to align items in a row
                                    alignItems: 'center', // Vertically center the items
                                    cursor: 'pointer',
                                    gap: '10px', // Add 10px gap between items
                                }}
                                onClick={() => {
                                    navigate(`/clients/registered-clients`)
                                }}
                            >
                                <ClientGroup /> {/* Your image component */}
                                <span className={styles.clients__first__left}>
                                    Clients
                                </span>
                            </div>
                        </Col>
                        <Col span={8} className={styles.client__first__middle}>
                            {/* <div className={styles.client__first__middle__text}>
                                Total
                            </div> */}
                            <div
                                className={styles.client__first__middle__count}
                            >
                                {totalClientRegister}
                            </div>
                        </Col>
                        {/* <Col span={2} className={styles.client__first__right}>
                            <VerticalThreeDots />
                        </Col> */}
                    </Row>

                    <Carousel
                        dots={false}
                        afterChange={onChangeTap}
                        ref={carouselRef}
                    >
                        {/* <div>
                            <Chart
                                data={dataClientOnbording ?? null}
                                total={totalOnbordingClients}
                            />
                        </div> */}
                        <div>
                            <Chart
                                data={dataRegisterData ?? null}
                                total={totalClientRegister}
                            />
                        </div>
                        {/* <div>
                            <Chart
                                data={dataTaskInProgress ?? null}
                                total={totalProspect}
                            />
                        </div> */}
                    </Carousel>
                    <Row className={styles.client__second}>
                        <Col span={24} className={styles.client__second__col}>
                            <div className={styles.client__second__number}>
                                <Tag
                                    bordered={false}
                                    color="#ffffff"
                                    className={
                                        styles.client__second__number__tag
                                    }
                                >
                                    {totalClientRegister}
                                </Tag>
                            </div>
                            <a
                                onClick={() => {
                                    goTo(1)
                                }}
                                style={{
                                    fontSize: '12px',
                                    color:
                                        activeTab === 1 ? '#306BFF' : '#5F6D7E',
                                }}
                            >
                                {REGISTEREDTAB}
                            </a>
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    )
}

export default ToDOClients
