import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Form,
    Switch,
    Input,
    Select,
    Button,
    Tooltip,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { useDispatch, connect } from 'react-redux'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { type BulkmailFormStateInterface } from '../../../Types/BulkEmail'
import { SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { GetContactDetail } from '../../../Services/BulkEmail'
import SearchForm from './serchForm'
import S3Uploadimage from '../../../Components/uploadimage'

const BulkMail = (props: BulkmailFormStateInterface): React.ReactElement => {
    const [isImageSwitchedOn, setIsImageSwitchedOn] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { categoryDropDownList } = props

    const [catergoryId, setcatergoryId] = useState<number | null>(0)
    const [modulld, setmoduleId] = useState<number | null>(null)
    const [selectedStatus, setSelectedStatus] = useState<number>(1)
    const handleSwitchChange = (checked: boolean): void => {
        setIsImageSwitchedOn(checked)
    }
    const [imageLink, setImageLink] = useState('')
    const [isRedirecting, setIsRedirecting] = useState(false)
    const hasFetchedOnce1 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [])

    useEffect(() => {
        if (
            catergoryId !== null &&
            modulld !== null &&
            modulld?.toString()?.length !== 0
        ) {
            GetContactDetail(dispatch, catergoryId, modulld, selectedStatus)
        }
    }, [catergoryId, modulld, selectedStatus])

    const setFormData = (name: string, location: string): void => {
        form.setFieldValue(name, location)
        setImageLink(location)
    }

    const handleSearch = (): void => {
        if (
            catergoryId !== null &&
            modulld !== null &&
            modulld?.toString()?.length !== 0
        ) {
            GetContactDetail(dispatch, catergoryId, modulld, selectedStatus)
            setIsRedirecting(true)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const handleSelectChange = (value: number): void => {
        setcatergoryId(value)
    }

    const handleSelectChange1 = (value: number | null): void => {
        setmoduleId(value)
    }
    const handleImageShow = (): void => {
        void handleImageShowData()
    }
    const handleImageShowData = async (): Promise<void> => {
        try {
            await form.validateFields(['imageUpload'])
            form.setFieldValue('s3imageUpload1', imageLink)
        } catch {}
    }
    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ imageLink }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
            onFinish={handleSearch}
        >
            <Card title="Bulk Email">
                <div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item>
                                <Switch id="2" onChange={handleSwitchChange} />
                                <span style={{ marginLeft: '5px' }}>
                                    Click to add image and Copy Image URL
                                </span>
                            </Form.Item>
                        </Col>
                    </Row>

                    {isImageSwitchedOn && (
                        <Row gutter={24}>
                            <Col span={7}>
                                <Form.Item
                                    label="Image Upload"
                                    name="imageUpload"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload an image!',
                                        },
                                    ]}
                                >
                                    <S3Uploadimage
                                        name={'imageUpload'}
                                        value={form.getFieldValue(
                                            'imageUpload'
                                        )}
                                        setFormData={setFormData}
                                        corporate={'BulkMailImage'}
                                        corporateid={0}
                                        documenttype={''}
                                        documentbaseId={0}
                                        memberpersonId={0}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={14}>
                                <Form.Item
                                    label="Image Link"
                                    name="s3imageUpload1"
                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    <Tooltip title="Upload Image">
                                        <Button
                                            type="primary"
                                            onClick={handleImageShow}
                                            style={{ marginTop: '30px' }}
                                        >
                                            <SaveOutlined />
                                        </Button>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Client Category"
                                name="clientCategory"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select the Client Category!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Please select"
                                    showSearch
                                    allowClear
                                    onChange={handleSelectChange}
                                    defaultValue={0}
                                    filterOption={(input, option) =>
                                        !(option == null) &&
                                        option.label
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={[
                                        { value: 0, label: 'All' },
                                        ...categoryDropDownList,
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the Status!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Status"
                                    showSearch
                                    allowClear
                                    value={selectedStatus}
                                    onChange={(value) => {
                                        setSelectedStatus(value)
                                    }}
                                    defaultValue={1}
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' &&
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    <Select.Option value={1}>
                                        PersonWise
                                    </Select.Option>
                                    <Select.Option value={2}>
                                        ClientWise
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Module"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the Module!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Module"
                                    onChange={handleSelectChange1}
                                    value={modulld}
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' &&
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    mode="multiple"
                                >
                                    <Select.Option value={1}>
                                        Corporate
                                    </Select.Option>
                                    <Select.Option value={2}>
                                        Sole Trader
                                    </Select.Option>
                                    <Select.Option value={3}>
                                        Individual
                                    </Select.Option>
                                    <Select.Option value={4}>
                                        Partnership
                                    </Select.Option>
                                    <Select.Option value={5}>
                                        UK-Staffs
                                    </Select.Option>
                                    <Select.Option value={6}>
                                        India Staffs
                                    </Select.Option>
                                    <Select.Option value={7}>VAT</Select.Option>
                                    <Select.Option value={8}>
                                        PAYE
                                    </Select.Option>
                                    <Select.Option value={9}>
                                        Ad Hoc
                                    </Select.Option>
                                    <Select.Option value={10}>
                                        Other
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col offset={22} span={2}>
                            <Form.Item>
                                <Button type="primary" onClick={handleSearch}>
                                    <SearchOutlined />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                    {isRedirecting &&
                        modulld !== null &&
                        catergoryId !== null && (
                            <SearchForm
                                moduleIds={[modulld]}
                                clientCategoryId={catergoryId}
                                statusId={selectedStatus}
                            />
                        )}
                </div>
            </Card>
        </Form>
    )
}

const mapStateToProps = (state: any): BulkmailFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        contactdepartmentDropDownList:
            state.initial.contactdepartmentDropDownList,
        contactdepartmentDropDownListCount:
            state.initial.contactdepartmentDropDownListCount,
    }
}

export default connect(mapStateToProps)(BulkMail)
