// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload_container_iAUuo {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.upload_container_iAUuo.dragging_tSy8A {
  border: 2px dashed #1890ff;
  background-color: rgba(24, 144, 255, 0.1);
}
.pdf_container_bLJWi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.pdf_page_wrapper_nJZaA {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  /* Adjust this to your needs */
  width: 100%;
  flex: content;
  margin: 10px;
}
.pdf_page_wrapper_nJZaA > *:nth-child(2) {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/upload.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,cAAA;AACJ;AAEA;EACI,0BAAA;EACA,yCAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AADJ;AAIA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EAFF,8BAA8B;EAG5B,WAAA;EACA,aAAA;EACA,YAAA;AADJ;AAGA;EACI,aAAA;AADJ","sourcesContent":[".upload_container {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n}\n\n.upload_container.dragging {\n    border: 2px dashed #1890ff;\n    background-color: rgba(24, 144, 255, 0.1);\n}\n\n.pdf_container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n}\n\n.pdf_page_wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    max-height: 500px; /* Adjust this to your needs */\n    width: 100%;\n    flex: content;\n    margin: 10px;\n}\n.pdf_page_wrapper > *:nth-child(2) {\n    display: none;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload_container": `upload_container_iAUuo`,
	"dragging": `dragging_tSy8A`,
	"pdf_container": `pdf_container_bLJWi`,
	"pdf_page_wrapper": `pdf_page_wrapper_nJZaA`
};
export default ___CSS_LOADER_EXPORT___;
