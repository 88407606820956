/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Form,
    Card,
    type SelectProps,
    Select,
    Col,
    Tooltip,
    Button,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { GetUserList } from '../../../Services/User'
import { ErrorMessage } from '../../../Utils/Notification'
import {
    type clientstausstusReportsData,
    type clientserviceReportInterface,
} from '../../../Types/Reports'
import axios from '../../../Config/AxiosConfig'
import { CloudDownloadOutlined } from '@ant-design/icons'
import jsPDF from 'jspdf'
import { GetserviceclientReportData } from '../../../Services/Reports'
import hederimage from '../../../Assest/Svg/pattern-2.svg'
import moment from 'moment'
const ServiceClientReport = (
    props: clientserviceReportInterface
): React.ReactElement => {
    const { clientsericestausList } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [data, setData] = useState<SelectProps['options']>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const [noRecordsFound, setNoRecordsFound] = useState(false)
    const [clientIdentifier, setClientIdentifier] = useState<number | null>(
        null
    )
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [])
    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={client.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                client.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                client.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                client.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const getTabledata = async (): Promise<void> => {
        try {
            // Ensure that validation passes before proceeding
            await form.validateFields() // Wait for validation to complete
            const reportData: clientstausstusReportsData =
                await GetserviceclientReportData(dispatch, 1, clientIdentifier)

            if (
                reportData?.clientService !== null &&
                reportData?.clientService !== undefined &&
                reportData?.clientService.length > 0
            ) {
                generatePDF(reportData)
                setNoRecordsFound(false)
            } else {
                setNoRecordsFound(true)
            }
        } catch (error) {
            // If validation fails, or if there's an error in fetching data, handle it here
            console.error('Validation or data fetching error:', error)
        }
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const handleClientChange = (value: string | undefined): void => {
        if (value !== undefined) {
            const identifier = value.split('-')[1]
            if (identifier.length > 0) {
                setSearchValue(identifier)
                setClientIdentifier(parseInt(identifier, 10))
            }
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const generatePDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop // Adjust this value as needed to move the text up

            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('IYKONS LIMITED', textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('Service Client Report', textXPosition, subHeaderYPosition)

            const lineStartX =
                textXPosition + doc.getTextWidth('Service Client Report') + 5
            const lineEndX = lineStartX + 80
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)

            doc.setFontSize(addressFontSize)
            doc.setTextColor(0, 0, 0)
            const selectedClient = clientsericestausList?.clientService?.[0]
            const companyName = selectedClient?.companyName || 'Unknown Company'
            const clientNumber = selectedClient?.clientNumber || 'N/A'

            const clientInfoYPosition = lineY + 10
            const clientInfoText = `${companyName}  ${clientNumber}`
            doc.text(clientInfoText, textXPosition, clientInfoYPosition)

            const headers = [
                [
                    { content: 'Service', styles: { halign: 'left' } },
                    { content: 'Period', styles: { halign: 'left' } },
                    { content: 'Status', styles: { halign: 'left' } },
                    { content: 'Assignee', styles: { halign: 'left' } },
                    { content: 'Assign Date', styles: { halign: 'left' } },
                    { content: 'Complete Date', styles: { halign: 'left' } },
                ],
            ]

            const abledata =
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                data?.clientService?.map(
                    (item1: {
                        serviceName: any
                        periodStartDate: any
                        status: any
                        assignee: any
                        assignDate: any
                        completedDate: any
                    }) => [
                        item1.serviceName,
                        moment(item1.periodStartDate).format('DD/MM/YYYY'),
                        item1.status,
                        item1.assignee,
                        moment(item1.assignDate).format('DD/MM/YYYY'),
                        moment(item1.completedDate).format('DD/MM/YYYY'),
                    ]
                ) || []

            doc.autoTable({
                head: headers,
                body: abledata,
                startY: 50,
                theme: 'grid',
                tableWidth: 'auto',
                styles: {
                    cellPadding: 1,
                    fontSize: 10,
                    halign: 'left',
                    valign: 'middle',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineColor: [160, 160, 160],
                    lineWidth: 0.05,
                },
                columnStyles: {
                    0: { cellWidth: 'auto' },
                    1: { cellWidth: 'auto' },
                    2: { cellWidth: 'auto' },
                    3: { cellWidth: 'auto' },
                    4: { cellWidth: 'auto' },
                },
            })

            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${abledata?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }
            doc.save('Service_client_report.pdf')
        }
    }

    return (
        <>
            <Card title="Reports" style={{ marginTop: '20px' }}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={8} align="middle">
                            <Col span={24}>
                                {' '}
                                {/* Adjust maxWidth as needed */}
                                <Form.Item
                                    label="Client"
                                    name="contactName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Client!',
                                        },
                                    ]}
                                >
                                    <Select
                                        loading={isLoading}
                                        showSearch
                                        placeholder="To filter the clients by number / name"
                                        onChange={handleClientChange}
                                        onSearch={(value) => {
                                            setSearchValue(value || '') // Ensure value is a string
                                            if (value && value.length >= 3) {
                                                // Call your search function here, e.g., handleClientChange
                                            }
                                        }}
                                        filterOption={false}
                                        allowClear
                                        onClear={() => {
                                            setData([])
                                        }}
                                        optionLabelProp="label"
                                        options={data}
                                        dropdownRender={(menu) => {
                                            // Default message
                                            let message =
                                                'Please enter 3 or more characters'

                                            // Check the length of searchValue before determining the message
                                            if (searchValue.length === 1) {
                                                message =
                                                    'Please enter 2 more characters'
                                            } else if (
                                                searchValue.length === 2
                                            ) {
                                                message =
                                                    'Please enter 1 more character'
                                            } else if (
                                                searchValue.length >= 3
                                            ) {
                                                message = '' // No message if 3 or more characters are typed
                                            }

                                            return (
                                                <>
                                                    {menu}
                                                    {/* Dropdown footer */}
                                                    <div
                                                        style={{
                                                            padding: '8px 16px',
                                                            borderTop:
                                                                '1px solid #e8e8e8',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {message}
                                                        </span>
                                                    </div>
                                                </>
                                            )
                                        }}
                                        optionRender={(option) => (
                                            <>{option.data.desc}</>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={22} span={4}>
                                <Tooltip title="Download Report">
                                    <Button
                                        type="primary"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={getTabledata}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {noRecordsFound && (
                    <div style={{ textAlign: 'left', marginTop: '20px' }}>
                        <span
                            style={{
                                color: '#808080',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            No Records found
                        </span>
                    </div>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): clientserviceReportInterface => {
    return {
        userList: state.user.userList,
        clientsericestausList: state.reports.clientsericestausList,
    }
}

export default connect(mapStateToProps)(ServiceClientReport)
