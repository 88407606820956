import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Col, Row, Select } from 'antd'
import commonStyles from '../../Utils/Common.less'
import type { TableProps } from 'antd/es/table'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../Utils/CommontTable'
import { type TableParamsInterface } from '../../Types/CommonType'
import styles from './staffwise.less'
import { GetDetailReport } from '../../Services/StaffReport/staffReport'
import {
    type StaffTaskStatusFormData,
    type detailListInterface,
    type detailStateinterface,
} from '../../Types/StaffwiseReport/staffWiseReport'
import { useNavigate } from 'react-router-dom'

const DetailReport = (props: detailListInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { detailReportList } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [entriesPerPage, setEntriesPerPage] = useState<number>(50)
    const navigate = useNavigate()
    const options = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 40, label: '40' },
        { value: 50, label: '50' },
    ]

    const defaultValue = 50
    const hasFetchedOnce = useRef(false)
    const onChange = (value: number): void => {
        setEntriesPerPage(value)
    }

    const getTableData = useCallback(() => {
        const { pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetDetailReport(dispatch, {
            pageNo,
            pageSize: entriesPerPage,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        entriesPerPage,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const columns = [
        {
            title: 'Services',
            width: 200,
            children: [
                {
                    title: (
                        <div className={styles.servicesContainer}>Staff</div>
                    ),
                    dataIndex: 'staffName',
                },
            ],
        },
        {
            title: 'VAT',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'vatTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.vatTaskInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 1
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.vatTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.vatTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'vatTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.vatTaskOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 1
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.vatTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.vatTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Inprogress
                            </div>
                        </div>
                    ),
                    key: 'vatClientInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.vatClientInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 1
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.vatClientInProgress}
                                    </div>
                                ) : (
                                    <div>{record.vatClientInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Overdue
                            </div>
                        </div>
                    ),
                    key: 'vatClientOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.vatClientOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 1
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.vatClientOverdue}
                                    </div>
                                ) : (
                                    <div>{record.vatClientOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
        {
            title: 'AA & CT',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'aaTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.aaTaskInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.aaTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.aaTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'aaTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.aaTaskOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.aaTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.aaTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Inprogress
                            </div>
                        </div>
                    ),
                    key: 'aaClientInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.aaClientInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.aaClientInProgress}
                                    </div>
                                ) : (
                                    <div>{record.aaClientInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Overdue
                            </div>
                        </div>
                    ),
                    key: 'aaClientOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.aaClientOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.aaClientOverdue}
                                    </div>
                                ) : (
                                    <div>{record.aaClientOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
        {
            title: 'Confirmation Statement',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'csTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.csTaskInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.csTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.csTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'csTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.csTaskOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.csTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.csTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Inprogress
                            </div>
                        </div>
                    ),
                    key: 'csClientInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.csClientInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.csClientInProgress}
                                    </div>
                                ) : (
                                    <div>{record.csClientInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Overdue
                            </div>
                        </div>
                    ),
                    key: 'csClientOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.csClientOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 9
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.csClientOverdue}
                                    </div>
                                ) : (
                                    <div>{record.csClientOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
        {
            title: 'PAYE',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'payeTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.payeTaskInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 3
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.payeTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.payeTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'payeTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.payeTaskOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 3
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.payeTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.payeTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Inprogress
                            </div>
                        </div>
                    ),
                    key: 'payeClientInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.payeClientInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 3
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.payeClientInProgress}
                                    </div>
                                ) : (
                                    <div>{record.payeClientInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Overdue
                            </div>
                        </div>
                    ),
                    key: 'payeClientOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.payeClientOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 3
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.payeClientOverdue}
                                    </div>
                                ) : (
                                    <div>{record.payeClientOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
        {
            title: 'PENSION',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'pensionTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.pensionTaskInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 4
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.pensionTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.pensionTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'pensionTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.pensionTaskOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 4
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.pensionTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.pensionTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Inprogress
                            </div>
                        </div>
                    ),
                    key: 'pensionClientInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.pensionClientInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 4
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.pensionClientInProgress}
                                    </div>
                                ) : (
                                    <div>{record.pensionClientInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Overdue
                            </div>
                        </div>
                    ),
                    key: 'pensionClientOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.pensionClientOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 4
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.pensionClientOverdue}
                                    </div>
                                ) : (
                                    <div>{record.pensionClientOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
        {
            title: 'ADHOC',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'adHocTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.adHocTaskInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 100
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.adHocTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.adHocTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'adHocTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.adHocTaskOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 100
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.adHocTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.adHocTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Inprogress
                            </div>
                        </div>
                    ),
                    key: 'adHocClientInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.adHocClientInProgress !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 100
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.adHocClientInProgress}
                                    </div>
                                ) : (
                                    <div>{record.adHocClientInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Client Overdue
                            </div>
                        </div>
                    ),
                    key: 'adHocClientOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.adHocClientOverdue !== 0 ? (
                                    <div
                                        onClick={() => {
                                            const mode = 2
                                            const serviceFlag = 100
                                            localStorage.setItem(
                                                'mode',
                                                mode.toString()
                                            )
                                            localStorage.setItem(
                                                'serviceFlag',
                                                serviceFlag.toString()
                                            )

                                            navigate(`/thing-to-do`)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.adHocClientOverdue}
                                    </div>
                                ) : (
                                    <div>{record.adHocClientOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
        {
            title: 'My Task',
            children: [
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>
                                Task Inprogress
                            </div>
                        </div>
                    ),
                    key: 'myTaskInProgress',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.myTaskInProgress !== 0 ? (
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.myTaskInProgress}
                                    </div>
                                ) : (
                                    <div>{record.myTaskInProgress}</div>
                                )}
                            </div>
                        )
                    },
                },
                {
                    title: (
                        <div className={styles.bottomToTopContent}>
                            <div className={styles.taskover}>Task Overdue</div>
                        </div>
                    ),
                    key: 'myTaskOverdue',
                    render: (record: StaffTaskStatusFormData) => {
                        return (
                            <div>
                                {record.myTaskOverdue !== 0 ? (
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {record.myTaskOverdue}
                                    </div>
                                ) : (
                                    <div>{record.myTaskOverdue}</div>
                                )}
                            </div>
                        )
                    },
                },
            ],
        },
    ]

    const onChangeTableParams: TableProps<any>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        setTableParams({
            ...tableParams,
            pagination,
            sorter: {
                field: sorter?.field ?? '',
                order: sorter?.order ?? '',
            },
        })
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.corpList}>
                    <Col span={5}>
                        Show{' '}
                        <Select
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                            className={styles.dropstyle}
                        />{' '}
                        entries
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    className={styles.customTable}
                    columns={columns}
                    dataSource={detailReportList}
                    onChange={onChangeTableParams}
                    pagination={{
                        ...tableParams.pagination,
                        pageSize: entriesPerPage,
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </div>
        </Card>
    )
}

const mapStateToProps = (state: any): detailStateinterface => {
    return {
        detailReportList: state.staffReport.detailReportList,
    }
}

export default connect(mapStateToProps)(DetailReport)
