import { Col, Row, Card, Modal, Button, Flex } from 'antd'
import commonStyles from '../../Utils/Common.less'
import { useLocation, useNavigate } from 'react-router-dom'
import AddNewButton from '../../Components/AddNewButton'
import React, { useEffect, useState } from 'react'
import CreateTask from '../../Components/CreateTask'
import { EyeOutlined } from '@ant-design/icons'
import GlobelClientTaps from './clientOverViewDashbordTap'
import { connect, useDispatch } from 'react-redux'
import { type ClientOverviewDashBordStateInterface } from '../../Types/GlobelSearchClient/GlobelSearchClient'
import { GetCilent } from '../../Services/GlobelSearch'
import style from './Buttons/taps.less'
import PortfolioView from '../../Components/viewProfolioDetails'
const GlobelSearchClient = (
    props: ClientOverviewDashBordStateInterface
): React.ReactElement => {
    const { clientDashbordList } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const refernceNumber = location.state?.value
    const parts = refernceNumber.split('-')
    const businessId = parts[0]
    const refernceId = parts[1]
    const [isEditModalVisible, setEditModalVisible] = useState(false)
    const [portfolioModel, setportfolioModel] = useState(false)
    const [restData, setResetData] = useState('false')
    useEffect(() => {
        GetCilent(dispatch, businessId, refernceId)
    }, [refernceNumber])

    const openEditModal = (): void => {
        setEditModalVisible(true)
    }

    const closeEditModal = (): void => {
        setEditModalVisible(false)
    }

    const handleCloseModal = (): void => {
        setEditModalVisible(false)
    }

    const handleNavigation = (businessId: string): void => {
        localStorage.removeItem('corporateData')
        localStorage.removeItem('onbordingId')
        const corporateData = {
            corporateid: clientDashbordList?.referenceId,
            companyname: clientDashbordList?.clientName,
            companyregisternumber: clientDashbordList?.additionalDataOne,
            clientnumber: clientDashbordList?.clientNumber,
            activestatus: clientDashbordList?.companyStatus,
            emailAddress: clientDashbordList?.emailAddress,
            contactName: clientDashbordList?.contactName,
            mobilenumber: clientDashbordList?.contactNumber,
            alternativeEmailAddress: clientDashbordList?.altEmailAddress,
        }

        const SoleTraderData = {
            soleTraderId: clientDashbordList?.referenceId,
            businessTradingName: clientDashbordList?.clientName,
            clientNumber: clientDashbordList?.clientNumber,
            activestatus: clientDashbordList?.companyStatus,
            emailAddress: clientDashbordList?.emailAddress,
            contactName: clientDashbordList?.contactName,
            mobilenumber: clientDashbordList?.contactNumber,
            alternativeEmailAddress: clientDashbordList?.altEmailAddress,
            serviceIds: clientDashbordList?.serviceList,
        }
        const individualData = {
            individualId: clientDashbordList?.referenceId,
            tradingName: clientDashbordList?.clientName,
            clientNumber: clientDashbordList?.clientNumber,
            activestatus: clientDashbordList?.companyStatus,
            emailAddress: clientDashbordList?.emailAddress,
            name: clientDashbordList?.contactName,
            mobilenumber: clientDashbordList?.contactNumber,
            alternativeEmailAddress: clientDashbordList?.altEmailAddress,
        }
        const partnershipData = {
            partnershipId: clientDashbordList?.referenceId,
            tradingName: clientDashbordList?.clientName,
            clientNumber: clientDashbordList?.clientNumber,
            activestatus: clientDashbordList?.companyStatus,
            emailAddress: clientDashbordList?.emailAddress,
            name: clientDashbordList?.contactName,
            mobilenumber: clientDashbordList?.contactNumber,
            alternativeEmailAddress: clientDashbordList?.altEmailAddress,
            serviceIds: clientDashbordList?.serviceList,
            partnershipUTR: clientDashbordList?.additionalDataOne,
        }
        const onbordingId = 1
        switch (businessId) {
            case '1':
                localStorage.setItem(
                    'corporateData',
                    JSON.stringify(corporateData)
                )
                localStorage.setItem('onbordingId', onbordingId.toString())
                navigate(`/clients/registered-clients/corporate-view`)

                break
            case '2':
                navigate(`/clients/registered-clients/soletrader-view`, {
                    state: { SoleTraderData, onbordingId },
                })
                break
            case '3':
                navigate(`/clients/registered-clients/individual-view`, {
                    state: { individualData, onbordingId },
                })
                break
            case '4':
                navigate(`/clients/registered-clients/partnership-view`, {
                    state: { partnershipData, onbordingId },
                })
                break
            default:
                // Handle other cases or do nothing
                break
        }
    }
    return (
        <>
            <div>
                <h1>Client Dashboard</h1>
            </div>
            <Card
                title={`${clientDashbordList?.clientName}- ${clientDashbordList?.clientNumber}`}
                extra={
                    <>
                        <Flex gap={8}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setportfolioModel(true)
                                }}
                            >
                                Portfolio Info
                            </Button>
                            <Button
                                icon={<EyeOutlined />}
                                type="primary"
                                onClick={() => {
                                    handleNavigation(businessId)
                                }}
                            >
                                Client Info
                            </Button>
                            <AddNewButton
                                name="Create Task"
                                clickAction={() => {
                                    openEditModal()
                                    setResetData('true')
                                }}
                            />
                        </Flex>
                    </>
                }
                className={commonStyles.card}
            >
                {clientDashbordList?.missedInfo !== null &&
                    clientDashbordList?.missedInfo.length > 0 && (
                        <div className={style.misstingInfo}>
                            <b>Missing Information </b>:-{' '}
                            {clientDashbordList?.missedInfo}
                        </div>
                    )}
                <Flex vertical gap={15}>
                    <Row gutter={[16, 48]}>
                        <Col span={4}>Contact Name</Col>
                        <Col span={7}>: {clientDashbordList?.contactName}</Col>
                        <Col span={5}>Service List</Col>
                        <Col span={8}>: {clientDashbordList?.serviceList}</Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={4}>Contact Mail</Col>
                        <Col span={7}>: {clientDashbordList?.emailAddress}</Col>
                        {businessId === '1' && (
                            <>
                                <Col span={5}>Register Number</Col>
                                <Col span={8}>
                                    : {clientDashbordList?.additionalDataOne} -{' '}
                                    <a
                                        href={`https://find-and-update.company-information.service.gov.uk/company/${clientDashbordList?.additionalDataOne}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            marginLeft: '8px',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Link To Company House
                                    </a>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={4}>Contact No</Col>
                        <Col span={7}>
                            : {clientDashbordList?.contactNumber}
                        </Col>
                        {clientDashbordList?.serviceList.includes('AA') &&
                            businessId === '1' && (
                                <>
                                    <Col span={5}>Annual Accounts</Col>
                                    <Col span={8}>
                                        : Next accounts made up to{' '}
                                        {
                                            clientDashbordList?.aaNextStatementDate
                                        }{' '}
                                        due by {clientDashbordList?.aaDuebyDate}
                                    </Col>
                                </>
                            )}
                    </Row>

                    <Row>
                        <GlobelClientTaps
                            businessId={businessId}
                            refernceId={refernceId}
                        />
                    </Row>
                </Flex>

                <Modal
                    footer={null}
                    onCancel={closeEditModal}
                    width={'40%'}
                    title="Create Task"
                    open={isEditModalVisible}
                >
                    <CreateTask
                        onClose={handleCloseModal}
                        setResetData={setResetData}
                        resetdata={restData}
                    />
                </Modal>
                <Modal
                    title="Portfolio Details"
                    open={portfolioModel}
                    onCancel={() => {
                        setportfolioModel(!portfolioModel)
                    }}
                    footer={null}
                    width={'50%'}
                >
                    <PortfolioView
                        businessId={businessId}
                        referenceId={refernceId}
                        portfolioId={0}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ClientOverviewDashBordStateInterface => {
    return {
        clientDashbordList: state.golobelSearch.clientDashbordList,
    }
}

export default connect(mapStateToProps)(GlobelSearchClient)
