import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Flex, Modal, Timeline, Tooltip } from 'antd'
import AddNewButton from '../../../Components/AddNewButton'
import StaffComm from './StaffCommMode'
import {
    type ClientDashboardStaffCommLogStateIntrface,
    type CDStaffCommLogProps,
    type ChatMessage,
} from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import { connect, useDispatch } from 'react-redux'
import buttonstyle from './taps.less'
import { GetStaffCommLogList } from '../../../Services/GlobelSearch'
import ConverCodeToReact from './htmlTOReactCode'
const StaffComunicationList = (
    props: CDStaffCommLogProps
): React.ReactElement => {
    const { CDStaffCommLogList, businessTypeId, referenceId } = props
    const [mergedData, setMergedData] = useState<ChatMessage[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [isModalVisible, setModalVisible] = useState(false)
    const hasFetchedOnce = useRef(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentPage(1)
        setItemsPerPage(10)
        setMergedData([])
    }, [])

    const getTableData = useCallback(() => {
        GetStaffCommLogList(
            dispatch,

            businessTypeId,
            referenceId,
            '=NA=',
            currentPage,
            itemsPerPage
        )
    }, [businessTypeId, referenceId, currentPage, itemsPerPage])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    useEffect(() => {
        const newData = [...CDStaffCommLogList, ...mergedData]
        setMergedData(newData)
    }, [CDStaffCommLogList])

    const handlePageChange = (): void => {
        window.scrollTo(0, 0)
        setCurrentPage(currentPage + 1)
        setItemsPerPage(itemsPerPage + 10)
    }

    const openModal = (): void => {
        setModalVisible(true)
    }

    const closeModal = (): void => {
        setModalVisible(false)
    }

    const OvalImage = ({
        src,
        width,
        height,
    }: {
        src: string
        width: number
        height: number
    }): JSX.Element => {
        return (
            <div
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: '50%',
                    overflow: 'hidden',
                }}
            >
                <img src={src} alt="Oval" className={buttonstyle.comLogimage} />
            </div>
        )
    }

    return (
        <>
            <div style={{ marginTop: '20px', width: '100%' }}>
                <Flex justify="flex-end">
                    <AddNewButton
                        name="Staff Comm"
                        clickAction={() => {
                            openModal()
                        }}
                    />
                </Flex>

                <div style={{ marginRight: '50px', left: 0 }}>
                    <Timeline mode={'left'}>
                        {mergedData.map((chat, index) => (
                            <Timeline.Item
                                label={
                                    <div className={buttonstyle.setDateStyle}>
                                        {chat.chatDate}
                                        <br />
                                        {chat.msgType === 1 ? (
                                            <div>Service</div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                }
                                key={index}
                                dot={
                                    <Tooltip title={chat.chatBy}>
                                        <OvalImage
                                            src={`${chat.imageURL}`}
                                            width={35}
                                            height={35}
                                        />
                                    </Tooltip>
                                }
                                style={{ marginBottom: '10px' }}
                            >
                                <div className={buttonstyle.dotdivStyle}>
                                    <strong> {chat.chatDescription}</strong>
                                    <br />
                                    <ConverCodeToReact
                                        html={chat.chatMessage}
                                    />
                                </div>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                    <div className={buttonstyle.buttonDiv}>
                        <Button
                            size="large"
                            onClick={() => {
                                handlePageChange()
                            }}
                        >
                            Learn More
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                footer={null}
                onCancel={closeModal}
                width={'40%'}
                title="Staff Comm"
                open={isModalVisible}
            >
                <StaffComm
                    businessTypeId={businessTypeId}
                    referenceId={referenceId}
                    closeModal={closeModal}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (
    state: any
): ClientDashboardStaffCommLogStateIntrface => {
    return {
        CDStaffCommLogList: state.golobelSearch.CDStaffCommLogList,
    }
}

export default connect(mapStateToProps)(StaffComunicationList)
