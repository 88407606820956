import { Card, Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
import RegisterOfficeAddress from '../CompanyHouseRelatedThings/regitserOfficeAddress'
import { type CompanyDetailsprops } from '../../../Types/CompanyHouse/CompanyOverView'
import { formatdate } from '../../../Utils/dateFormat'
import EditSICCode from '../CompanyHouseRelatedThings/editSICcode'
const CompanyDetails = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId, companyHouseOverViewList, businessId, editId } =
        props
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [SICEditModalStatus, setSICEditModalStatus] = useState(false)
    const OpenEditAddressModel = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const OpenEditSICModel = (): void => {
        setSICEditModalStatus(!SICEditModalStatus)
    }
    const changSICEditModalStatus = (): void => {
        setSICEditModalStatus(!SICEditModalStatus)
    }

    const Address = `${companyHouseOverViewList?.cH_CompanyDetailsView?.regAddress1},${companyHouseOverViewList?.cH_CompanyDetailsView?.regAddress2},${companyHouseOverViewList?.cH_CompanyDetailsView?.regTown},${companyHouseOverViewList?.cH_CompanyDetailsView?.regPostCode},${companyHouseOverViewList?.cH_CompanyDetailsView?.regCountry}`
    return (
        <>
            <div>
                <h3>Company Details</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <div className={style.misstingInfo}>
                        If you want to do changes required in registered office
                        address should be filed using the
                        <b>&quot;Edit Registered Address&quot;</b>
                        before you file the confirmation statement.
                    </div>
                )}
                <Row gutter={[24, 8]}>
                    <Col span={8}>Company Type</Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.companyType
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>Date Of Incorporation</Col>
                    <Col span={16}>
                        :{' '}
                        {formatdate(
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.dateOfIncorporation
                        )}
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>Trading on market in period</Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.marketPeriod
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>
                        Registered Office Address{' '}
                        {companyHousId === 1 && (
                            <span>
                                (<a onClick={OpenEditAddressModel}>Edit</a>)
                            </span>
                        )}
                    </Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.regAddress1
                        }
                        ,
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.regAddress2
                        }
                        ,
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.regTown
                        }
                        ,
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.regPostCode
                        }
                        ,
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.regCountry
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 1</Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.sicCode1
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 2</Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.sicCode2
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 3</Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.sicCode3
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 4</Col>
                    <Col span={16}>
                        :{' '}
                        {
                            companyHouseOverViewList?.cH_CompanyDetailsView
                                ?.sicCode4
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>
                        {editId === 1 && (
                            <span onClick={OpenEditSICModel}>
                                (<a>Edit</a>)
                            </span>
                        )}
                    </Col>
                </Row>
            </Card>

            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={'Change of Registered Office Address'}
                open={createEditModalStatus}
            >
                <RegisterOfficeAddress
                    corporateId={companyHouseOverViewList?.corporateId ?? 0}
                    companyName={companyHouseOverViewList?.clientName ?? ''}
                    companyNumber={
                        companyHouseOverViewList?.companyRegNumber ?? ''
                    }
                    Address={Address}
                    oncloseModel={changeCreateEditModalStatus}
                    businessId={businessId ?? 0}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changSICEditModalStatus}
                width={'50%'}
                title={'Edit SIC Code'}
                open={SICEditModalStatus}
            >
                <EditSICCode
                    companyName={companyHouseOverViewList?.clientName ?? ''}
                    companyNumber={
                        companyHouseOverViewList?.companyRegNumber ?? ''
                    }
                    oncloseModel={changSICEditModalStatus}
                    editData={companyHouseOverViewList?.cH_CompanyDetailsView}
                    coroporateId={companyHouseOverViewList?.corporateId ?? 0}
                    businessId={businessId ?? 0}
                    serviceProcessId={0}
                />
            </Modal>
        </>
    )
}

export default CompanyDetails
