import { Button, Col, Collapse, Row, Switch } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import VATViewPage, { VatDefultData } from './VATEdit'
import PAYEviewPage, { Payedefaultdata } from './PAYEEdit'
import PensionviewPage, { PensionDefultData } from './PensionEdit'
import AnualAccountEdit from './CTForm'
import {
    type ParnersgipStateInteface,
    type PartnershipServiceMainInterface,
    type VatData,
    type AnnualAccountData,
    type PensionData,
    type PayeServiceDataSS,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import { connect, useDispatch } from 'react-redux'
import {
    GetService,
    getClientCompanyDetails,
} from '../../../../../../Services/SoleTrader'
import Servicesless from '../../../../../../Layouts/Less/style.less'
import VATEditForm from './VATForm'
import PensionEditForm from './PensionForm'
import PayeEditForm from './PayeForm'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
export const AnnualAccountDataDefalut = {
    annualAccountId: 0,
    companyType: 0,
    referenceId: 0,
    fromDate: '01/01/1900',
    toDate: '01/01/1900',
    deadlineDate: '01/01/1900',
    status: '',
}

const PartnershipService = (
    props: PartnershipServiceMainInterface & { activeTab: string }
): React.ReactElement => {
    const { soleTraderTaps, soletrderId, activeTab } = props
    const { Panel } = Collapse
    const dispatch = useDispatch()
    const [isEditMode, setIsEditMode] = useState(false)
    const [PayeServiceData, setPayeServiceData] = useState<PayeServiceDataSS>()
    const [VatServiceData, setVATServiceData] = useState<VatData>()
    const [AAServiceData, setAAServiceData] = useState<AnnualAccountData>()
    const [PensionServiceData, setPensionServiceData] = useState<PensionData>()
    const [editData, setEditData] = useState<AnnualAccountData>(
        AnnualAccountDataDefalut
    )
    const [vateditData, setVatEditData] = useState<VatData>(VatDefultData)
    const [isVatEditMode, setIsVatEditMode] = useState(false)
    const [isPayeeEditMode, setIsPayeeEditMode] = useState(false)
    const [isPenitionEditMode, setIsPenisionEditMode] = useState(false)
    const [PenistioneditData, setPenitionEditData] =
        useState<PensionData>(PensionDefultData)
    const [peyeeeditData, setpeyeeEditData] =
        useState<PayeServiceDataSS>(Payedefaultdata)
    const handleEditClick = (): void => {
        setIsEditMode(!isEditMode)
        setEditData(AAServiceData ?? AnnualAccountDataDefalut)
    }

    const getTableData = useCallback(() => {
        if (activeTab === '4') {
            if (Array.isArray(soleTraderTaps) && soleTraderTaps.length > 0) {
                const VATService = soleTraderTaps?.find(
                    (service) => service?.serviceName === 'VAT'
                )
                const VATServiceReferenceId =
                    VATService?.serviceReferenceId ?? 0

                const serviceID = VATService?.serviceId ?? 0
                if (VATServiceReferenceId !== 0 && serviceID !== 0) {
                    void GetService(
                        dispatch,
                        serviceID,
                        VATServiceReferenceId
                    ).then((data) => {
                        if (data !== null) {
                            setVATServiceData(data)
                        }
                    })
                }
            }
            if (Array.isArray(soleTraderTaps) && soleTraderTaps.length > 0) {
                const PAYEServiceData = soleTraderTaps?.find(
                    (service) => service?.serviceName === 'PAYE'
                )
                const serviceReferenceId =
                    PAYEServiceData?.serviceReferenceId ?? 0

                const serviceID = PAYEServiceData?.serviceId ?? 0

                if (serviceReferenceId != null && serviceID !== 0) {
                    void GetService(
                        dispatch,
                        serviceID,
                        serviceReferenceId
                    ).then((data) => {
                        if (data !== null) {
                            setPayeServiceData(data)
                        }
                    })
                }
            }
            if (Array.isArray(soleTraderTaps) && soleTraderTaps.length > 0) {
                const AAServiceData = soleTraderTaps?.find(
                    (service) => service?.serviceName === 'AA'
                )
                const AAServiceReferenceId =
                    AAServiceData?.serviceReferenceId ?? 0
                const serviceID = AAServiceData?.serviceId ?? 0
                if (AAServiceReferenceId !== 0 && serviceID !== 0) {
                    void GetService(
                        dispatch,
                        serviceID,
                        AAServiceReferenceId
                    ).then((data) => {
                        if (data !== null) {
                            setAAServiceData(data)
                        }
                    })
                }
            }

            if (Array.isArray(soleTraderTaps) && soleTraderTaps.length > 0) {
                const PensionServiceData = soleTraderTaps?.find(
                    (service) => service?.serviceName === 'Pension'
                )
                const PensionServiceReferenceId =
                    PensionServiceData?.serviceReferenceId ?? 0

                const serviceID = PensionServiceData?.serviceId ?? 0
                if (PensionServiceReferenceId > 0 && serviceID !== 0) {
                    void GetService(
                        dispatch,
                        serviceID,
                        PensionServiceReferenceId
                    ).then((data) => {
                        if (data !== null) {
                            setPensionServiceData(data)
                        }
                    })
                }
            }
        }
    }, [activeTab, soleTraderTaps])
    useEffect(() => {
        getTableData()
    }, [getTableData])

    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }

    const datareFetch = (): void => {
        void getClientCompanyDetails(dispatch, 2, soletrderId, 4)
    }
    const handleChildFormSave = (): void => {
        datareFetch()
        setIsEditMode(false)
    }

    const vathandleEditClick = (): void => {
        setIsVatEditMode(true)
        setVatEditData(VatServiceData ?? VatDefultData)
    }

    const vathandleChildFormSave = (): void => {
        setIsVatEditMode(false)
        datareFetch()
    }
    const PensionhandleEditClick = (): void => {
        setIsPenisionEditMode(true)
        setPenitionEditData(PensionServiceData ?? PensionDefultData)
    }

    const PensionhandleChildFormSave = (): void => {
        setIsPenisionEditMode(false)
        datareFetch()
    }
    const PayehandleEditClick = (): void => {
        setIsPayeeEditMode(true)
        setpeyeeEditData(PayeServiceData ?? Payedefaultdata)
    }

    const PayehandleChildFormSave = (): void => {
        setIsPayeeEditMode(false)
        datareFetch()
    }

    return (
        <>
            <Collapse
                expandIconPosition="end"
                style={{ width: '100%' }}
                onChange={() => {
                    setIsEditMode(false)
                }}
            >
                <Panel
                    header="Annual Accounts"
                    key="1"
                    extra={
                        <Switch
                            checked={AAServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" onClick={handleEditClick}>
                                {isEditMode ? (
                                    <EyeOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.UPDATE
                                    ) && <EditOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isEditMode ? (
                        <div>
                            <AnualAccountEdit
                                editData={editData}
                                soletrderId={soletrderId}
                                handleChildFormSave={handleChildFormSave}
                            />
                        </div>
                    ) : (
                        <Row gutter={[24, 8]}>
                            <Col span={5}>
                                <p>Period</p>
                            </Col>
                            <Col span={7}>
                                <p>
                                    : {formatdate(AAServiceData?.fromDate)}-
                                    {formatdate(AAServiceData?.toDate)}
                                </p>
                            </Col>
                            <Col span={5}>
                                <p>Deadline Date</p>
                            </Col>
                            <Col span={7}>
                                <p>
                                    : {formatdate(AAServiceData?.deadlineDate)}{' '}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Panel>
            </Collapse>

            <Collapse
                expandIconPosition="end"
                onChange={() => {
                    setIsVatEditMode(false)
                }}
            >
                <Panel
                    header="VAT"
                    key="2"
                    extra={
                        <Switch
                            checked={VatServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button type="primary" onClick={vathandleEditClick}>
                                {isVatEditMode ? (
                                    <EyeOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.UPDATE
                                    ) && <EditOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isVatEditMode ? (
                        <div>
                            <VATEditForm
                                soletrderId={soletrderId}
                                vateditdata={vateditData}
                                handleChildFormSave={vathandleChildFormSave}
                            />
                        </div>
                    ) : (
                        <VATViewPage VatServiceData={VatServiceData} />
                    )}
                </Panel>
            </Collapse>
            <Collapse
                expandIconPosition="end"
                onChange={() => {
                    setIsPayeeEditMode(false)
                }}
            >
                <Panel
                    header="PAYE"
                    key="3"
                    extra={
                        <Switch
                            checked={PayeServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button
                                type="primary"
                                onClick={PayehandleEditClick}
                            >
                                {isPayeeEditMode ? (
                                    <EyeOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.UPDATE
                                    ) && <EditOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isPayeeEditMode ? (
                        <div>
                            <PayeEditForm
                                PayeeditData={peyeeeditData}
                                handleChildFormSave={PayehandleChildFormSave}
                                soletrderId={soletrderId}
                            />
                        </div>
                    ) : (
                        <PAYEviewPage
                            PayeServiceData={PayeServiceData}
                            datareFetch={datareFetch}
                            soletrderId={soletrderId}
                        />
                    )}
                </Panel>
            </Collapse>
            <Collapse
                expandIconPosition="end"
                className={Servicesless.servicesform}
                onChange={() => {
                    setIsPenisionEditMode(false)
                }}
            >
                <Panel
                    header="Pension"
                    key="4"
                    extra={
                        <Switch
                            checked={PensionServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Button
                                type="primary"
                                onClick={PensionhandleEditClick}
                            >
                                {isPenitionEditMode ? (
                                    <EyeOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.UPDATE
                                    ) && <EditOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isPenitionEditMode ? (
                        <div>
                            <PensionEditForm
                                pensioneditData={PenistioneditData}
                                soletrderId={soletrderId}
                                handleChildFormSave={PensionhandleChildFormSave}
                            />
                        </div>
                    ) : (
                        <PensionviewPage
                            PensionServiceData={PensionServiceData}
                        />
                    )}
                </Panel>
            </Collapse>
        </>
    )
}

const mapStateToProps = (state: any): ParnersgipStateInteface => {
    return {
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(PartnershipService)
