import React from 'react'
import styles from './login.less'
import {
    type changePinInterface,
    type changePasswdDataInterface,
} from '../../Types/Auth'
import { Col, Row, Form, Button, Input } from 'antd'
import commonStyles from '../../Utils/Common.less'
import { ChangePasswdApi } from '../../Services/Auth'
import { useDispatch } from 'react-redux'
import InputField from '../../Components/InputField'
import InputFieldPassword from '../../Components/InputFieldPassword'
import { useNavigate } from 'react-router-dom'

const ChangePassword = (props: changePinInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { code } = props

    const onSubmit = (data: changePasswdDataInterface): void => {
        const newData = {
            resetCode: code,
            emailAddress: data.emailAddress,
            password: data.password,
            confirmPassword: data.confirmPassword,
        }
        ChangePasswdApi(dispatch, newData, () => {
            navigate(`/`)
            window.location.reload()
        })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>
                                Change Password
                            </div>
                            <div className={styles.loginTittleDesc}>
                                Reset your password to access your account
                            </div>
                        </div>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                        >
                            <InputField
                                name="emailAddress"
                                placeHolder="ex. email@domain.com"
                                label="EmailAddress"
                                reuireMessage="Please input your Email!"
                            />
                            <InputFieldPassword
                                name="password"
                                label="Password"
                                reuireMessage="Please input your password!"
                                placeHolder="Enter your password"
                            />
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        async validator(_, value: string) {
                                            const password = getFieldValue(
                                                'password'
                                            ) as string

                                            if (password !== value) {
                                                return await Promise.reject(
                                                    new Error(
                                                        'Passwords not match!'
                                                    )
                                                )
                                            }
                                            await Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm your password" />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.loginFormButton}
                                >
                                    SignIn
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className={styles.doNotHaveAccountText}>
                            Don’t have an account?
                            <a href="">SignIn</a>
                        </div>
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ChangePassword
