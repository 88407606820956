import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { type RegisterClientInterface } from '../../../Types/Client/RegisteredClients/registerClient'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import CommonButton from '../../../Components/Commonbutton'
import CorporteClientServiceList from './Corporate/corporateList'
import SoltraderClientServiceList from './SoleTrader/soletarderList'
import PartneshipClientServiceList from './Partneship/partneshiplist'
import InduvidualClientServiceList from './Induvidual/induvidualList'
import { GetRegisteClientDetails } from '../../../Services/Common'
const ClientServiceUserList = (
    props: RegisterClientInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { categoryDropDownList, serviceDropDownList, regiterClientDetails } =
        props
    const [activeButton, setActiveButton] = useState(1)
    const [showCoporateList, setShowCoporateList] = useState(true)
    const [showSoleTraderList, setShowSoleTrader] = useState(true)
    const [showPatnershipList, setShowIndividualList] = useState(true)
    const [showIndividualList, setShowPatnershipList] = useState(true)
    const [selectedId, setSelectedId] = useState(0)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        setShowSoleTrader(true)
    }, [])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetRegisteClientDetails(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 0 : Number(value))
    }

    const handleSelectChange1 = (value: string | number): void => {
        setSelectedServiceId(value === 'all' ? 0 : Number(value))
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setShowCoporateList(true)
            setShowSoleTrader(false)
        } else if (buttonName === 2) {
            setShowSoleTrader(true)
            setShowCoporateList(false)
        } else if (buttonName === 3) {
            setShowIndividualList(true)
            setShowCoporateList(false)
            setShowSoleTrader(false)
        } else if (buttonName === 4) {
            setShowPatnershipList(true)
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return (
                <CorporteClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        } else if (activeButton === 2) {
            return (
                <SoltraderClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        if (activeButton === 3) {
            return (
                <InduvidualClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        if (activeButton === 4) {
            return (
                <PartneshipClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        return <></>
    }

    return (
        <Card title="Client Service User" className={commonStyles.card}>
            <Select
                className={commonStyles.topdropdown2}
                placeholder="Please select"
                onChange={handleSelectChange}
                value={selectedId === 0 ? 'all' : selectedId}
                options={[
                    { value: 'all', label: 'All' },
                    ...categoryDropDownList,
                ]}
            />
            <Select
                className={commonStyles.topdropdown}
                placeholder="Please select"
                onChange={handleSelectChange1}
                value={selectedServiceId === 0 ? 'all' : selectedServiceId}
                options={[
                    { value: 'all', label: 'All' },
                    ...serviceDropDownList,
                ]}
            />
            {regiterClientDetails.map((client, index) => (
                <CommonButton
                    key={index}
                    name={client.label} // Assuming `buttonName` is a property in regiterClientDetails
                    isActive={activeButton === index + 1}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(index + 1)
                    }}
                />
            ))}
            {showCoporateList && activeButton === 1 && (
                <CorporteClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )}
            {showSoleTraderList && activeButton === 2 && (
                <SoltraderClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )}

            {showIndividualList && activeButton === 3 && (
                <InduvidualClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )}
            {showPatnershipList && activeButton === 4 && (
                <PartneshipClientServiceList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )}
        </Card>
    )
}

const mapStateToProps = (state: any): RegisterClientInterface => {
    return {
        parentCategoryList: state.initial.parentCategoryList,
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
    }
}

export default connect(mapStateToProps)(ClientServiceUserList)
