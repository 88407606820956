import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { TableListInterface } from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'
import {
    type PortfolioClientData,
    type PortfolioClientAddData,
    type PortfolioManagerDetail,
    type ProtfolioFormDataInterface,
    type AccountMangerFormData,
    type SaveRemarkData,
} from '../Types/Client/RegisteredClients/Portfolio'
import {
    portfolioList,
    portfolioRemarksListData,
    portfolioUnassignUserList,
} from '../Reducer/Portfolio'

export const GetPortfolioList = (
    dispatch: Dispatch,
    clientCategoryId: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Portfolio/GetPortfolioList/${clientCategoryId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: PortfolioManagerDetail) => {
                        return {
                            ...value,
                            key: value.portfolioId.toString(),
                        }
                    }
                )
                response.data.data = newData
                dispatch(portfolioList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveProtfolioData = async (
    dispatch: Dispatch,
    data: ProtfolioFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Portfolio/SetPortfolio`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const DeletePortfolioDetails = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/35/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const PortfolioDetailView = async (
    dispatch: Dispatch,
    portfolioId: number,
    businessTypeId: number,
    referenceId: number,
    getportfolioData: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Portfolio/GetPortfolioDetail/${portfolioId}/${businessTypeId}/${referenceId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const portfoliodata = response.data.data
                getportfolioData(portfoliodata)

                // SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const AddClient = async (
    dispatch: Dispatch,
    data: PortfolioClientAddData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Portfolio/SetPortfolioDetails`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const GetUnassignList = (
    dispatch: Dispatch,
    clientCategoryId: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `api/Portfolio/GetUnAssignedCompanyList/${clientCategoryId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: PortfolioClientData) => {
                        return {
                            ...value,
                            key: value.referenceId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(portfolioUnassignUserList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SavePorAccountManger = async (
    dispatch: Dispatch,
    data: AccountMangerFormData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/Portfolio/SetAccountsManager`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const setRamrksData = async (
    dispatch: Dispatch,
    data: SaveRemarkData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/Portfolio/SetPortfolioRemarks`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetProtfolioRemarksList = (
    dispatch: Dispatch,
    protfolioId: number,
    businesId: number,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .post(
            `api/Portfolio/GetPortfolioRemarksHistory/${protfolioId}/${businesId}/${referenceId}`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(portfolioRemarksListData(response.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteRemarksDetails = (
    dispatch: Dispatch,
    id: number,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/38/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
