// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_cAGA8 {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}
.contener_nhvbH {
  display: flex;
  align-items: center;
  height: 125px;
  width: 350px;
  background-color: #f7f2e8;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.imagemain_Ekca1 {
  flex: 1 1;
}
.image_LvrzR {
  width: 60px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.chattextMain_oPyJd {
  flex: 2 1;
  padding-left: 5px;
}
.chatheding_UhByY {
  font-size: 18px;
  font-weight: 700;
}
.chatsubheading_TZTGN {
  color: #7d8895;
  font-size: 10px;
}
.formspace_IeOJx {
  margin-top: auto;
}
.buttonimageIcon_OQrbY {
  transform: rotate(-45deg);
}
.scrollContainer_c_BS4 {
  max-height: 400px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/ProspectClients/prosepectClient.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,gBAAA;AACJ;AACA;EACI,SAAA;AACJ;AACA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,wCAAA;AACJ;AACA;EACI,SAAA;EACA,iBAAA;AACJ;AACA;EACI,eAAA;EACA,gBAAA;AACJ;AACA;EACI,cAAA;EACA,eAAA;AACJ;AACA;EACI,gBAAA;AACJ;AACA;EACI,yBAAA;AACJ;AAEA;EACI,iBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".main {\n    display: flex;\n    flex-direction: column;\n    min-height: 80vh;\n}\n.contener {\n    display: flex;\n    align-items: center;\n    height: 125px;\n    width: 350px;\n    background-color: #f7f2e8;\n    padding: 10px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    margin-top: 20px;\n}\n.imagemain {\n    flex: 1;\n}\n.image {\n    width: 60px;\n    height: 80px;\n    object-fit: cover;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n.chattextMain {\n    flex: 2;\n    padding-left: 5px;\n}\n.chatheding {\n    font-size: 18px;\n    font-weight: 700;\n}\n.chatsubheading {\n    color: #7d8895;\n    font-size: 10px;\n}\n.formspace {\n    margin-top: auto;\n}\n.buttonimageIcon {\n    transform: rotate(-45deg);\n}\n\n.scrollContainer {\n    max-height: 400px;\n    overflow-y: auto;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `main_cAGA8`,
	"contener": `contener_nhvbH`,
	"imagemain": `imagemain_Ekca1`,
	"image": `image_LvrzR`,
	"chattextMain": `chattextMain_oPyJd`,
	"chatheding": `chatheding_UhByY`,
	"chatsubheading": `chatsubheading_TZTGN`,
	"formspace": `formspace_IeOJx`,
	"buttonimageIcon": `buttonimageIcon_OQrbY`,
	"scrollContainer": `scrollContainer_c_BS4`
};
export default ___CSS_LOADER_EXPORT___;
