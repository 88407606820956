import React from 'react'
import { Row } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { type LeaveExpandViewType } from '../../../Types/Hr/LeavePermission'

const LeaveExpandView = (props: LeaveExpandViewType): React.ReactElement => {
    const { listsData } = props

    return (
        <div className={commonStyles.expandCard}>
            <Row className={commonStyles.expandRow}>
                <p>Approved History:</p>
                <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                    {listsData?.approvalHistoryList?.length != null ? (
                        listsData.approvalHistoryList?.map(
                            (approval: any, index: number) => (
                                <div key={index} style={{ display: 'block' }}>
                                    <p>{''}</p>
                                    <p>
                                        Workflow Name: {approval.workflowName}
                                    </p>
                                    <p>
                                        Approver Name: {approval.approverName}
                                    </p>
                                    <p>
                                        Approval Status:{' '}
                                        {approval.approvalStatus}
                                    </p>
                                </div>
                            )
                        )
                    ) : (
                        <p>No approved history available.</p>
                    )}
                </div>
            </Row>
        </div>
    )
}

export default LeaveExpandView
