import { Button, Card, Col, Collapse, Flex, Modal, Row } from 'antd'
import React, { useState } from 'react'
import roleStyle from '../../Clients/RegisteredClients/Corporate/Buttons/OfficerButton/ofiicer.less'
import style from '../../ThingToDoViewAll/tingtodo.less'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import RemovePscOfficer from '../CompanyHouseRelatedThings/removePscofficer'
import AddNewPSC from '../CompanyHouseRelatedThings/addnewPscDetails'
import {
    type CS_PSC,
    defaultPSCInfo,
    type CompanyDetailsprops,
} from '../../../Types/CompanyHouse/CompanyOverView'
import { formatdate } from '../../../Utils/dateFormat'

const PSCPage = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId, companyHouseOverViewList } = props
    const [TerminateModalStatus, setTerminateModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState(
        'Appointment of New PSC'
    )
    const [pscId, setPscId] = useState(0)
    const [editData, setEditData] = useState<CS_PSC>(defaultPSCInfo)
    const getRoleBackgroundColor = (role: string): React.ReactNode => {
        if (role !== '' && typeof role === 'string') {
            const roles = role.split(',')?.map((r) => r.trim())

            return (
                <div className={roleStyle.roleBadge}>
                    {roles?.map((r, index) => (
                        <span
                            key={index}
                            className={`${roleStyle.roleSpan} ${
                                roleStyle[r.toLowerCase()] ??
                                roleStyle.defaultColor
                            }`}
                        >
                            {r.substring(0, 3)}
                        </span>
                    ))}
                </div>
            )
        }
    }

    const openEditModal = (id: number): void => {
        const data = companyHouseOverViewList?.cS_PSCList?.find((data) => {
            return data.pscId === id
        })

        let tittle = 'Appointment of New PSC'
        if (id !== 0) {
            tittle = 'Edit PSC Details'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? defaultPSCInfo)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const OpenTerminateOfficer = (pscId: number): void => {
        setPscId(pscId)
        setTerminateModalStatus(!TerminateModalStatus)
    }

    const changeTerminateModalStatus = (): void => {
        setTerminateModalStatus(!TerminateModalStatus)
    }
    return (
        <>
            <div>
                <h3>PSC &apos; s</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <>
                        <div className={style.misstingInfo}>
                            If you want to do changes required in
                            PSC&apos;s&apos; should be filed using respectively
                            the{' '}
                            <b>
                                &quot;Edit / Remove / Add PSC&apos;s&apos;&quot;
                            </b>
                            pages before you file the confirmation statement.
                        </div>
                        <div
                            style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                            <Flex justify="flex-end" gap={5}>
                                <CusttomBackgroundButton
                                    color={'#175ca9'}
                                    name={'Add New PSC'}
                                    icon={<PlusOutlined />}
                                    clickAction={() => {
                                        openEditModal(0)
                                    }}
                                />
                            </Flex>
                        </div>
                    </>
                )}
                {companyHouseOverViewList?.cS_PSCList?.map((pscs, index) => (
                    <Collapse
                        defaultActiveKey={['']}
                        key={index + 1}
                        expandIconPosition="end"
                    >
                        <Collapse.Panel
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>{`${pscs.title} ${pscs.firstName} ${pscs.middleName} ${pscs.surName}`}</span>
                                    <span>{getRoleBackgroundColor('PSC')}</span>
                                </div>
                            }
                            key={1}
                        >
                            <>
                                {companyHousId === 1 && (
                                    <div
                                        style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        <Flex justify="flex-end" gap={5}>
                                            <Button
                                                type="primary"
                                                size="middle"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    OpenTerminateOfficer(
                                                        pscs.pscId
                                                    )
                                                }}
                                            >
                                                Remove PSC Officer
                                            </Button>

                                            <CusttomBackgroundButton
                                                color={'#175ca9'}
                                                name={'Edit PSC Details'}
                                                icon={<EditOutlined />}
                                                clickAction={() => {
                                                    openEditModal(pscs.pscId)
                                                }}
                                            />
                                        </Flex>
                                    </div>
                                )}
                                <Row>
                                    <Col>
                                        <h3>Initial Details</h3>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Residence Country</Col>
                                    <Col span={8}>
                                        : {pscs.countryofResidence}
                                    </Col>
                                    <Col span={5}>Date of Birth</Col>
                                    <Col span={6}>: {pscs.dateofBirth}</Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Nationality</Col>
                                    <Col span={8}>: {pscs.nationality}</Col>
                                    <Col span={5}>Corr.Address</Col>
                                    <Col span={6}>
                                        : {pscs.corrAddress1}
                                        {pscs.corrAddress2},{pscs.corrTown} ,{' '}
                                        {pscs.corrCountry},{pscs.corrPostcode}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Home Address</Col>
                                    <Col span={8}>
                                        : {pscs.resAddress1}
                                        {pscs.resAddress2},{pscs.resTown}
                                        {pscs.resCountry}
                                        {pscs.resPostcode}
                                    </Col>
                                    <Col span={5}>Notification Date</Col>
                                    <Col span={6}>
                                        : {formatdate(pscs.notificationDate)}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Register Entry Date</Col>
                                    <Col span={8}>
                                        : {formatdate(pscs.registerEntryDate)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>Ownership of shares</h2>
                                        </div>
                                        <div>
                                            {pscs.personHoldsShares !== 0 &&
                                                `The Person Holds, directly or indirectly, ${
                                                    pscs.personHoldsShares === 1
                                                        ? 'more than 25% but not more than 50%'
                                                        : pscs.personHoldsShares ===
                                                            2
                                                          ? 'more than 50% but less than 75%'
                                                          : '75% or more'
                                                } of shares`}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>Ownership of voting rights</h2>
                                        </div>
                                        <div>
                                            {pscs.personHoldsVotingRights !==
                                                0 &&
                                                `The Person Holds, directly or indirectly, ${
                                                    pscs.personHoldsVotingRights ===
                                                    1
                                                        ? 'more than 25% but not more than 50%'
                                                        : pscs.personHoldsVotingRights ===
                                                            2
                                                          ? 'more than 50% but less than 75%'
                                                          : '75% or more'
                                                } of voting
                                            rights`}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>
                                                Right to appoint or remove the
                                                majority of the board of
                                                directors
                                            </h2>
                                        </div>
                                        <div>
                                            The person has control over the firm
                                            and members of that firm (in their
                                            capacity as such) hold the right to
                                            appoint or remove directors of the
                                            company
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>
                                                Has significant influence or
                                                control
                                            </h2>
                                        </div>
                                        <div>
                                            The person has control over the firm
                                            and members of that firm (in their
                                            capacity as such) have significant
                                            influence or control over the
                                            company
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        </Collapse.Panel>
                    </Collapse>
                ))}
            </Card>
            <Modal
                footer={null}
                onCancel={changeTerminateModalStatus}
                width={'50%'}
                title={'Termination PSC'}
                open={TerminateModalStatus}
            >
                <RemovePscOfficer
                    onClosemodel={changeTerminateModalStatus}
                    corporateId={companyHouseOverViewList?.corporateId ?? 0}
                    businessId={companyHouseOverViewList?.clientTypeId ?? 0}
                    pscID={pscId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={editModalTittle}
                open={createEditModalStatus}
            >
                <AddNewPSC
                    companyName={companyHouseOverViewList?.clientName ?? ''}
                    companyNumber={
                        companyHouseOverViewList?.companyRegNumber ?? ''
                    }
                    onCloseModel={changeCreateEditModalStatus}
                    corporateId={companyHouseOverViewList?.corporateId ?? 0}
                    editData={editData}
                    businessId={companyHouseOverViewList?.clientTypeId ?? 0}
                />
            </Modal>
        </>
    )
}

export default PSCPage
