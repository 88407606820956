import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { GetServiceProcessHistory } from '../../../Services/ToDoViewAllList'
import {
    type ServiceProcessHistoryPageProp,
    type ServiceProcessHistoryState,
} from '../../../Types/ToDoViewAllList'
import { Col, Row } from 'antd'

const ServiceProcessHistory = (
    props: ServiceProcessHistoryPageProp
): React.ReactElement => {
    const { serviceprocessId, serviceProcessHistoryList } = props
    const dispatch = useDispatch()

    useEffect(() => {
        GetServiceProcessHistory(dispatch, serviceprocessId)
    }, [serviceprocessId])

    return (
        <div>
            <div>
                <h4>
                    Remarks:
                    {
                        serviceProcessHistoryList
                            ?.serviceProcessCheckListHistory?.remarks
                    }
                </h4>
            </div>
            {serviceProcessHistoryList?.serviceProcessActionGroupHistoryList?.map(
                (data, index) => (
                    <>
                        <div key={data.serviceActionGroupId}>
                            <u>
                                <h2>{data?.actionGroupName}:</h2>
                            </u>
                        </div>

                        <div>
                            {data?.serviceProcessActionHistoryList?.map(
                                (item) => (
                                    <>
                                        <h3 style={{ fontWeight: 400 }}>
                                            {item.actionName}:
                                        </h3>
                                        <div>
                                            <Row gutter={24}>
                                                <Col span={8}>
                                                    <p>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            Status :
                                                        </span>
                                                        {item.completeStatus}
                                                    </p>
                                                </Col>
                                                <Col span={8}>
                                                    {item?.processHistoryStatusList?.map(
                                                        (datas) => (
                                                            <>
                                                                <div>
                                                                    <p>
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            Assign
                                                                            Date
                                                                            :
                                                                        </span>
                                                                        {
                                                                            datas.assignDate
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={8}>
                                                    {item?.processHistoryStatusList?.map(
                                                        (datas) => (
                                                            <>
                                                                <div>
                                                                    <p>
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 700,
                                                                            }}
                                                                        >
                                                                            Remarks
                                                                            :
                                                                        </span>
                                                                        {
                                                                            datas.userRemarks
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </>
                )
            )}
        </div>
    )
}

const mapStateToProps = (state: any): ServiceProcessHistoryState => {
    return {
        serviceProcessHistoryList:
            state.dashboardInfo.serviceProcessHistoryList,
    }
}

export default connect(mapStateToProps)(ServiceProcessHistory)
