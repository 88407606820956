import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Col, Row, Select, Space, Table } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import {
    type CHFillingHistoryListDataTypeInterface,
    type CHFillingHistoryListInterface,
} from '../../../Types/CompanyHouse/CHFillinfHistory'
import {
    GetCHFillingHistoryList,
    GetFillingTypeList,
} from '../../../Services/CompanyHouse/CHFillingHostory'
import CHFillingHistoryExpandView from './CHFillingHistoryExpandView'
import { type AdvanceSearchInterface } from '../../../Types/Invoice/Invoicing'

const CHFillingHistory = (
    props: CHFillingHistoryListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { fillingTypeList, CHFillingHistoryList, CHFillingHistoryListCount } =
        props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [fillingTypeChange, setFillingTypeId] = useState(0)
    const [advanceSearchData, setAdvanceSearchData] =
        useState<AdvanceSearchInterface>()
    useEffect(() => {
        GetFillingTypeList(dispatch)
    }, [])
    const hasFetchedOnce = useRef(false)

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetCHFillingHistoryList(
            dispatch,
            {
                pageNo,
                pageSize,
                searchCriteriaList,
                sortOrderList,
            },
            fillingTypeChange,
            advanceSearchData ?? undefined
        )
    }, [
        fillingTypeChange,
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: CHFillingHistoryListCount,
            },
        })
    }, [CHFillingHistoryListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        CHFillingHistoryListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<CHFillingHistoryListDataTypeInterface> = [
        {
            title: 'Company Number & Name',
            key: 'action',
            render: (_, record: CHFillingHistoryListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <a
                            href={`https://find-and-update.company-information.service.gov.uk/company/${record.companyRegNumber}/filing-history`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline' }}
                        >
                            {record.companyRegNumber}
                        </a>{' '}
                        - {record.companyName}
                    </Space>
                )
            },
        },
        {
            title: 'Submission Type',
            dataIndex: 'submissionType',
        },
        {
            title: 'Submission #',
            dataIndex: 'submissionNumber',
        },
        {
            title: 'Status',
            dataIndex: 'companyHouseStatus',
        },
        {
            title: 'Submission Date',
            dataIndex: 'submissionDate',
        },
        {
            title: 'Submitted By',
            dataIndex: 'submittedBy',
        },
    ]

    const onChangeTableParams: TableProps<CHFillingHistoryListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'CompanyName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
        const advancesearch: AdvanceSearchInterface = {
            searchCriteriaList: [
                {
                    fieldName: 'CompanyRegNumber',
                    searchOperator: 10,
                    fieldValue1: data,
                    fieldValue2: '',
                },
                {
                    fieldName: 'CompanyName',
                    searchOperator: 10,
                    fieldValue1: data,
                    fieldValue2: '',
                },
            ],
            sortOrderList: [],
        }
        setAdvanceSearchData(advancesearch)
    }

    const onFillingTypeChange = (value: number): void => {
        setFillingTypeId(value)
    }
    const expandableData = (
        record: CHFillingHistoryListDataTypeInterface
    ): React.ReactElement => {
        return (
            <CHFillingHistoryExpandView expandData={record.rejectedComments} />
        )
    }
    return (
        <>
            <div>
                <h3>Filing History</h3>
            </div>
            <Card title="Filing History" className={commonStyles.card}>
                <Row>
                    <Col span={12}>
                        {' '}
                        <SearchInput
                            placeHolder={
                                'Search  by keywords Company Number & Name'
                            }
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="500"
                        />
                    </Col>
                    <Col span={12}>
                        <Select
                            style={{ width: '100%' }}
                            value={fillingTypeChange}
                            showSearch
                            allowClear
                            placeholder="Please select"
                            onChange={(value) => {
                                onFillingTypeChange(value)
                            }}
                            options={fillingTypeList}
                            filterOption={(input, option) => {
                                return (
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                )
                            }}
                        />
                    </Col>
                </Row>

                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={CHFillingHistoryList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): CHFillingHistoryListInterface => {
    return {
        CHFillingHistoryList: state.companyHouse.CHFillingHistoryList,
        CHFillingHistoryListCount: state.companyHouse.CHFillingHistoryListCount,
        fillingTypeList: state.companyHouse.fillingTypeList,
    }
}

export default connect(mapStateToProps)(CHFillingHistory)
