import {
    Button,
    Col,
    Input,
    Form,
    Row,
    Switch,
    DatePicker,
    Space,
    type DatePickerProps,
    Select,
    Tooltip,
} from 'antd'
import commonStyles from '../../../../../../Utils/Common.less'
import React, { useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { connect, useDispatch } from 'react-redux'
import {
    GetCountryList,
    GetInduvidualList,
    GetPartneshipContactInformation,
} from '../../../../../../Services/Common'
import {
    type PartnerTogilleAddress,
    type PartnersDataFormInterface,
    type PartnersDataInterface,
    type IndividualData,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import {
    GetInduvidualInfo,
    GetPartnerAddress,
    SavePartner,
    getClientCompanyDetails,
} from '../../../../../../Services/Parnership'
import moment from 'moment'
// import ActivePremission from '../../../../../../Utils/premissins'
// import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
const Partners = (props: PartnersDataFormInterface): React.ReactElement => {
    const {
        countryList,
        partnershipId,
        InduvidualMemberList,
        resetdata,
        setResetData,
        handleSave,
    } = props

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [addressDatas, setAddressData] =
        useState<PartnerTogilleAddress | null>(null)
    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })
    const [induvidualInfo, setInduvidualInfo] = useState<IndividualData | null>(
        null
    )
    const [buttonLoading, setButtonLoading] = useState(false)
    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
        }
        setResetData('false')
    }, [resetdata])

    useEffect(() => {
        GetCountryList(dispatch)
        GetInduvidualList(dispatch)
    }, [dispatch])

    const onChange = (dateOfBirth: DatePickerProps['value']): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDate)
    }, [selectedDate])

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetPartnerAddress(
                dispatch,
                partnershipId,
                switchId,
                (addressData: PartnerTogilleAddress) => {
                    setAddressData(addressData)
                }
            )
        } else {
            const newFieldValues = {
                partnerAddressOne: '',
                partnerAddressTwo: '',
                partnerTown: '',
                partnerCountry: '',
                partnerPostCode: '',
            }

            form.setFieldsValue(newFieldValues)
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        if (addressDatas !== null && typeof addressDatas === 'object') {
            const newFieldValues = {
                partnerAddressOne: addressDatas?.addressLineOne,
                partnerAddressTwo: addressDatas?.addressLineTwo,
                partnerTown: addressDatas?.town,
                partnerCountry: addressDatas?.country,
                partnerPostCode: addressDatas?.postCode,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])

    const handleIndividualChange = (value: number): void => {
        void GetInduvidualInfo(
            dispatch,
            value,
            (induviduaInformation: IndividualData) => {
                setInduvidualInfo(induviduaInformation)
            }
        )
    }
    useEffect(() => {
        if (induvidualInfo !== null && typeof induvidualInfo === 'object') {
            const newValues = {
                individualId: induvidualInfo?.individualId,
                title: induvidualInfo?.title,
                firstName: induvidualInfo?.firstName,
                middleName: induvidualInfo?.middleName,
                surName: induvidualInfo?.surName,
                dateOfBirth: moment(induvidualInfo?.dateOfBirth).format(
                    'DD/MM/YYYY'
                ),
                partnerAddressOne: induvidualInfo?.addressOne,
                partnerAddressTwo: induvidualInfo?.addressTwo,
                partnerTown: induvidualInfo?.town,
                partnerCountry: induvidualInfo?.country,
                partnerPostCode: induvidualInfo?.postCode,
                mobileNumber: induvidualInfo?.mobileNumber,
                homeNumber: induvidualInfo?.homeNumber,
                workNumber: induvidualInfo?.workNumber,
                emailAddress: induvidualInfo?.emailAddress,
                alternativeEmailAddress:
                    induvidualInfo?.alternativeEmailAddress,
                individualUTR: induvidualInfo?.individualUTR,
                niNumber: induvidualInfo?.niNumber,
                isActive: 'Active',
                isDelete: 'Inactive',
            }

            setSelectedDate(induvidualInfo?.dateOfBirth)
            form.setFieldsValue(newValues)
        }
    }, [induvidualInfo])
    const onPartnerEditData = (): void => {
        void onSaveData()
    }

    const onSaveData = async (): Promise<void> => {
        try {
            await form.validateFields()
            setButtonLoading(true)
            const formdata = form.getFieldsValue()
            const dataToSave = {
                partnerId: 0,
                partnershipId,
                individualId: formdata.individualId ?? 0,
                tagIndividualId: 0,
                individualClientNumber: '',
                title: formdata.title ?? '',
                firstName: formdata.firstName,
                middleName: formdata.middleName ?? '',
                surName: formdata.surName,
                dateOfBirth:
                    moment(formdata.dateOfBirth).format('DD/MM/YYYY') ===
                    'Invalid date'
                        ? ''
                        : moment(formdata.dateOfBirth).format('DD/MM/YYYY'),
                partnerAddressOne: formdata.partnerAddressOne,
                partnerAddressTwo: formdata.partnerAddressTwo ?? '',
                partnerTown: formdata.partnerTown,
                partnerCountry: formdata.partnerCountry ?? '',
                partnerPostCode: formdata.partnerPostCode,
                mobileNumber: formdata.mobileNumber,
                homeNumber: formdata.homeNumber ?? '',
                workNumber: formdata.workNumber ?? '',
                emailAddress: formdata.emailAddress,
                alternativeEmailAddress: formdata.alternativeEmailAddress ?? '',
                individualUTR: formdata.individualUTR ?? '',
                niNumber: formdata.niNumber ?? '',
                isActive: 'Active',
                isDelete: 'Inactive',
            }

            await SavePartner(dispatch, dataToSave).then((reult) => {
                if (reult !== null && reult !== undefined) {
                    form.resetFields()
                    setSelectedDate('')
                    void getClientCompanyDetails(dispatch, 4, partnershipId, 8)
                    setButtonLoading(false)
                    GetPartneshipContactInformation(dispatch, partnershipId)
                    handleSave()
                }
            })
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="ct-form"
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                layout="vertical"
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={24} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item label="Same as Business">
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={() => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Same as Correspondance">
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={() => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="Individual Member"
                            name="individualId"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleIndividualChange}
                                options={InduvidualMemberList}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Date of Birth" name="dateOfBirth">
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={onChange}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="Title"
                            name="title"
                            initialValue={'UNKNOWN'}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                showSearch
                            >
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Mobile Number"
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message: 'please enter valid mobile number',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="First Name "
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Home Number"
                            name="homeNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message: 'please enter valid mobile number',
                                },
                                {
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item label="Middle Name" name="middleName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Work Number"
                            name="workNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message: 'please enter valid Work Number',
                                },
                                {
                                    message: 'Please input your Work Number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 48]} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="SurName"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Surname!',
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="emailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 48]} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="Address 1"
                            name="partnerAddressOne"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Address One!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Alternative Email Address"
                            name="alternativeEmailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'Please input your Alternative Email Address!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item label="Address 2" name="partnerAddressTwo">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="NI Number" name="niNumber">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="Town"
                            name="partnerTown"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Town!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Individual UTR" name="individualUTR">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item label="Country" name="partnerCountry">
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.margin_Top20}>
                    <Col span={12}>
                        <Form.Item
                            label="PostCode"
                            name="partnerPostCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Post Code!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save Partner">
                            <Button
                                type="primary"
                                onClick={onPartnerEditData}
                                loading={buttonLoading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): PartnersDataInterface => {
    return {
        countryList: state.common.countryList,
        partnershipTaps: state.client.partnershipTaps,
        InduvidualMemberList: state.common.InduvidualMemberList,
    }
}
export default connect(mapStateToProps)(Partners)
