// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cpAddressMain_FavQ4 {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.collapseFirst_nhrhk {
  width: 50%;
  margin-right: 10px;
}
.collapseSecond_z1EL3 {
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAEA;EACI,UAAA;EACA,kBAAA;AAAJ;AAGA;EACI,UAAA;AADJ","sourcesContent":[".cpAddressMain {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 10px;\n}\n\n.collapseFirst {\n    width: 50%;\n    margin-right: 10px;\n}\n\n.collapseSecond {\n    width: 50%;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cpAddressMain": `cpAddressMain_FavQ4`,
	"collapseFirst": `collapseFirst_nhrhk`,
	"collapseSecond": `collapseSecond_z1EL3`
};
export default ___CSS_LOADER_EXPORT___;
