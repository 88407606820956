import React, { useState } from 'react'
import { Button, Upload, Image, Spin, Card } from 'antd'
import { uploadFileToS3 } from '../Config/AwsConfig'
import styles from './Less/s3Upload.less'
import layoutStyles from '../Layouts/Less/style.less'
import { DeleteOutlined } from '@ant-design/icons'
import { ErrorMessage } from '../Utils/Notification'
import FullWidthModal from './FullWidthModal'

interface S3UploadInterface {
    name: string
    value: string
    setFormData: (name: string, location: string) => void
    setBase64?: (base64: string | undefined) => void
    corporate: string
    corporateid: number
    documenttype: string
    documentbaseId: number
    memberpersonId: number
}

const S3Uploadimage = (props: S3UploadInterface): React.ReactElement => {
    const {
        name,
        value,
        setFormData,
        setBase64,
        corporate,
        corporateid,
        documenttype,
        memberpersonId,
        documentbaseId,
    } = props

    const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)

    const handleBeforeUpload = (): boolean => {
        return false
    }

    const dynamicPath = `Document${corporate !== '' ? `/${corporate}` : ''}${
        corporateid !== 0 ? `/${corporateid}` : ''
    }${documenttype !== '' ? `/${documenttype}` : ''}${
        memberpersonId !== 0 ? `/${memberpersonId}` : ''
    }${documentbaseId !== 0 ? `/${documentbaseId}` : ''}`

    const convertBase64 = async (file: File): Promise<string> => {
        return await new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result as string)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const fileOnChange = async (
        file: any,
        name: string,
        fileList: any
    ): Promise<void> => {
        setIsLoading(true)

        if (setBase64 != null) {
            const base64 = await convertBase64(fileList[0]?.originFileObj)
            setBase64(base64)
        }

        uploadFileToS3(file, dynamicPath)
            .then((response) => {
                if (response?.Location !== null) {
                    setFormData(name, response?.Location)
                    setIsImageUploaded(true)
                    setIsLoading(false)
                }
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const handleRemove = (): void => {
        setDeleteModalStatus(true)
    }

    const confirmDeletion = (): void => {
        setFormData(name, '')
        setIsImageUploaded(false)
        setDeleteModalStatus(false)
    }

    // const getFileExtension = (url: string | undefined): string | undefined => {
    //     if (url != null) {
    //         return url.split('.').pop()?.toLowerCase()
    //     }
    //     return undefined
    // }

    return (
        <>
            <Upload
                accept=".jpeg,.jpg,.png" // Only allow image files
                beforeUpload={handleBeforeUpload}
                onChange={(info) => {
                    const isImage =
                        info.file.type === 'image/jpeg' ||
                        info.file.type === 'image/png' ||
                        info.file.type === 'image/jpg'
                    if (!isImage) {
                        ErrorMessage(
                            'You can only upload JPG, JPEG, or PNG files!'
                        )
                        return Upload.LIST_IGNORE // Ignore non-image files
                    }
                    try {
                        void fileOnChange(info.file, name, info.fileList)
                    } catch (error) {
                        ErrorMessage(`File upload failed.`)
                    }
                    return false
                }}
                className={styles.uploadComponent}
                maxCount={1}
                showUploadList={{
                    showRemoveIcon: false,
                    showPreviewIcon: isImageUploaded,
                }}
                defaultFileList={[
                    {
                        uid: '1',
                        name: '',
                        status: 'uploading',
                        url: value,
                    },
                ]}
                itemRender={(): React.ReactElement =>
                    !(value === null || value === '' || value === undefined) ? (
                        <Card
                            style={{ width: 200, marginTop: 16 }}
                            cover={
                                <Image
                                    width={200}
                                    height={200}
                                    src={value}
                                    preview={isImageUploaded}
                                />
                            }
                            actions={[
                                <DeleteOutlined
                                    key="delete"
                                    onClick={() => {
                                        handleRemove()
                                    }}
                                />,
                            ]}
                        />
                    ) : (
                        <span>No Image Selected</span>
                    )
                }
            >
                <Button className={styles.fileUploadButton}>Choose File</Button>
                {isLoading && (
                    <div className={layoutStyles.overlay}>
                        <Spin />
                    </div>
                )}
            </Upload>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={() => {
                    setDeleteModalStatus(false)
                }}
                tittle="You are about to delete this file. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={confirmDeletion} // Delete the file if user confirms
            />
        </>
    )
}

export default S3Uploadimage
