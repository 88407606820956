import React, { useEffect, useRef } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type AccountMangerFormInterface,
    type AccountMangerState,
} from '../../../Types/Client/RegisteredClients/Portfolio'
import { GetUserList } from '../../../Services/User'

const AccountManager = (
    props: AccountMangerFormInterface
): React.ReactElement => {
    const { onSave, portfolioId, businessTypeId, referenceId, userList } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldValue('portfolioId', portfolioId)
        form.setFieldValue('businessTypeId', businessTypeId)
        form.setFieldValue('referenceId', referenceId)
    }, [])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [])

    const resetForm = (): void => {
        form.resetFields()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="portfolioId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="businessTypeId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="referenceId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Account Manger"
                            name="accountsManagerId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Account Manger!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                                allowClear
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): AccountMangerState => {
    return {
        userList: state.user.userList,
    }
}
export default connect(mapStateToProps)(AccountManager)
