import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type totalReportStatusFormData,
    type StaffTaskStatusFormData,
} from '../Types/StaffwiseReport/staffWiseReport'

interface StaffStateInterface {
    detailReportList: StaffTaskStatusFormData[]
    detailReportListCount: number
    totalReportList: totalReportStatusFormData[]
    totalReportListCount: number
}

const StaffReducer = createSlice({
    name: 'staffReport',
    initialState: {
        detailReportList: [],
        detailReportListCount: 0,
        totalReportList: [],
        totalReportListCount: 0,
    },
    reducers: {
        detailReportList(
            state: StaffStateInterface,
            action: PayloadAction<any>
        ) {
            state.detailReportList = action?.payload?.data
            state.detailReportListCount = action?.payload?.noOfRecords
        },
        totalReportList(
            state: StaffStateInterface,
            action: PayloadAction<any>
        ) {
            state.totalReportList = action?.payload?.data
            state.totalReportListCount = action?.payload?.noOfRecords
        },
    },
})

export const { detailReportList, totalReportList } = StaffReducer.actions
export default StaffReducer.reducer
