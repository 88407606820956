import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'

import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'

import {
    type ListBackendInterface,
    type ListFrontEndInterface,
    type TableListInterface,
} from '../Types/CommonType'

import type {
    ContactInfoData,
    ClientCompanyActiveService,
    ClientCompanyControlData,
    ClientCompanyVerification,
    GenerateEmails,
    DirectDebitCustomerInterface,
    PartnerTapData,
    PartnershipFormDataInterface,
    PartneshipHomeApi,
    PartneshipSupplierDetailsFormSaveData,
    AnnualAccountData,
    PayeServiceDataSS,
    PensionData,
    VatData,
} from '../Types/Client/RegisteredClients/Partnership'
import {
    partnershipTaps,
    partnershiplList,
    paymentOptionDropDownList,
    priorityDropDownList,
    registeredClientPartnershipConfiguration,
} from '../Reducer/Client'
import { serviceDropDownList } from '../Reducer/InitialReducer'
import { type CreateTaskInterface } from '../Types/Client/RegisteredClients/Corporate'

export const GetParnershipList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Partnership/GetPartnershipList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: PartnershipFormDataInterface, index: number) => {
                        return {
                            ...value,
                            key: index,
                        }
                    }
                )
                response.data.data = newData
                dispatch(partnershiplList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetServiceDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/18')
        .then((response) => {
            if (response.status === 200) {
                const serviceListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(serviceDropDownList(serviceListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const ActiveInactiveParnership = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetRecordActiveStatus/4/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeleteParnership = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/4/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPriorityList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/24')
        .then((response) => {
            if (response.status === 200) {
                const newPriority: ListFrontEndInterface[] =
                    response.data.data?.map((data: ListBackendInterface) => ({
                        value: data.stdId,
                        label: data.stdName,
                    }))
                dispatch(priorityDropDownList(newPriority))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveTask = async (
    dispatch: Dispatch,
    data: CreateTaskInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTask`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getClientCompanyDetails = async (
    dispatch: Dispatch,
    businessTypeId: number,
    partnershipId: number,
    tabIndex: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/corporate/GetClientCompanyDetail/${businessTypeId}/${partnershipId}/${tabIndex}`
        )

        if (response.status === 200) {
            dispatch(partnershipTaps(response.data))
            // SuccessMessage('Data fetched successfully')
            return response.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const setPartnershipHomeData = async (
    dispatch: Dispatch,
    data: PartneshipHomeApi
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Partnership/SetPartnership`,
            data
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SavePartnershipData = async (
    dispatch: Dispatch,
    data: PartneshipHomeApi,
    saveCallBack: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = {
        partnershipId: 0,
        clientCategoryId: data.clientCategoryId ?? 0,
        clientNumber: data.clientNumber ?? '0',
        tradingName: data.tradingName ?? '',
        partnershipUTR: data.partnershipUTR ?? '',
        busAddressOne: data.busAddressOne ?? '',
        busAddressTwo: data.busAddressTwo ?? '',
        busTown: data.busTown ?? '',
        busCountry: data.busCountry ?? '',
        busPostCode: data.busPostCode ?? '',
        corrAddressOne: data.corrAddressOne ?? '',
        corrAddressTwo: data.corrAddressTwo ?? '',
        corrTown: data.corrTown ?? '',
        corrCountry: data.corrCountry ?? '',
        corrPostCode: data.corrPostCode ?? '',
        isActive: 'Active',
        isDelete: 'Inactive',
    }
    try {
        const response = await axios.post(
            `api/Partnership/SetPartnership`,
            bodyData
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
            saveCallBack(response.data.data)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveSupperInfo = async (
    dispatch: Dispatch,
    data: PartneshipSupplierDetailsFormSaveData
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Service/SetClientCompanySupplier `, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SavePartner = async (
    dispatch: Dispatch,
    data: PartnerTapData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Partnership/SetPartnershipDetail `, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getClientPortalInvitation = async (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/ClientPortal/GetClientInvitation/${businessTypeId}/${referenceId}/1`
        )

        if (response.status === 200) {
            dispatch(partnershipTaps(response.data))
            SuccessMessage('Client portal invitation send successfully')
        } else {
            ErrorMessage('Failed to sent client portal invitation')
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetService = async (
    dispatch: Dispatch,
    serviceID: number,
    serviceReferenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Service/GetClientCompanyServiceDetail/${serviceID}/${serviceReferenceId}`
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        console.error('Error getting CT service details:', error)
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}

export const SaveAnnualData = async (
    dispatch: Dispatch,
    data: AnnualAccountData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServiceAnnualAccount`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetDirectDebitCustomer = (
    dispatch: Dispatch,
    companyTypeId: number,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/corporate/GetClientCompanyDetail/${companyTypeId}/${referenceId}/10`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(
                    registeredClientPartnershipConfiguration(response.data)
                )
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const EnableServiceForComplince = async (
    dispatch: Dispatch,
    partnershipId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/Scheduler/SchedulerPreparationByCompanyId/4/${partnershipId}`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage('Enable all active services successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetPartnerAddress = async (
    dispatch: Dispatch,
    partnershipId: number,
    switchId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetCompanyAddressInformation/4/${partnershipId}/${switchId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data.data
                getAddress(addressData)
                // SuccessMessage(response.data.operationMsg)
                SuccessMessage('Address Fetch Successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveVatData = async (
    dispatch: Dispatch,
    data: VatData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServiceVAT`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const PensionSaveData = async (
    dispatch: Dispatch,
    data: PensionData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServicePension`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const PayeSaveData = async (
    dispatch: Dispatch,
    data: PayeServiceDataSS
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServicePaye`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetAddress = async (
    dispatch: Dispatch,
    partnershipId: number,
    switchId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetCompanyAddressInformation/4/${partnershipId}/${switchId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data.data
                getAddress(addressData)
                // SuccessMessage(response.data.operationMsg)
                SuccessMessage('Address Fetch Successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPaymentOptionDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/56')
        .then((response) => {
            if (response.status === 200) {
                const paymentOptionListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(paymentOptionDropDownList(paymentOptionListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetDirectDebitCustomer = async (
    dispatch: Dispatch,
    data: DirectDebitCustomerInterface,
    cancelGoCardlessModal: CallableFunction
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/DirectDebit/SetDirectDebitCustomer/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                cancelGoCardlessModal()
                SetClientEmail(dispatch, data, response.data.identifierId)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetClientEmail = (
    dispatch: Dispatch,
    data: DirectDebitCustomerInterface,
    identifierId: string
): void => {
    const clientEmailData = {
        ClientEmailId: 0,
        TemplateId: 0,
        ServiceProcessId: -7,
        CompanyTypeId: data.BusinessTypeId,
        ReferenceId: data.ReferenceId,
        ServiceActionId: 0,
        ServiceActionStepId: 0,
        ToAddress: data.Email,
        CCAddress: '',
        BCCAddress: 'info@iykons.com',
        IykonsBCCAddress: 'info@iykons.com',
        FromAddress: 'info@iykons.com',
        Subject:
            'Iykons Limited would like authorisation to take payment from you',
        Description: data.Description,
    }
    axios
        .post(`api/Email/SetClientEmail`, clientEmailData)
        .then((response) => {
            if (response.status === 200) {
                SetClientEmailStatus(dispatch, identifierId)
            }
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            return false
        })
}

export const SetClientEmailStatus = (
    dispatch: Dispatch,
    identifierId: string
): void => {
    const ClientEmailStatus = {
        ClientEmailId: identifierId,
        EmailStatus: 'Success',
    }
    axios
        .post(`api/Email/SetClientEmailStatus`, ClientEmailStatus)
        .then((response) => {
            return true
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GenerateEmail = async (
    dispatch: Dispatch,
    data: GenerateEmails
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Email/SetClientEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SetClientEmailStatus(dispatch, response.data.identifierId)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const setPartnershipContactInfo = async (
    dispatch: Dispatch,
    data: ContactInfoData
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Partnership/SetContactPerson`,
            data
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetQuotationPreview = async (
    dispatch: Dispatch,
    partnershipId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/Parnership/GetQuotationPreview/4/${partnershipId}`
        )
        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveClientCompanyActiveService = async (
    dispatch: Dispatch,
    data: ClientCompanyActiveService
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyActiveService/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveClientCompanyVerification = async (
    dispatch: Dispatch,
    data: ClientCompanyVerification
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyVerification`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveCompanyControl = async (
    dispatch: Dispatch,
    data: ClientCompanyControlData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyControl`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetInduvidualInfo = async (
    dispatch: Dispatch,
    InduvidualId: number,
    getInformation: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Partnership/GetIndividualInfo/3/${InduvidualId}`)
        .then((response) => {
            if (response.status === 200) {
                const InduvidualData = response.data.data
                getInformation(InduvidualData)
                SuccessMessage('Induvidual Info Fetch Successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeletePartner = async (
    dispatch: Dispatch,
    ParnershipId: number,
    parnerId: number,
    getInformation: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `api/SetDeleteRecordWithOneParameter/4/${ParnershipId}/${parnerId}`
        )
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                getInformation()
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getPartnerContactPersonDetails = async (
    dispatch: Dispatch,
    referenceId: number,
    contactPersonId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/GetCompanyContactInformation/4/${referenceId}/${contactPersonId}`
        )

        if (response.status === 200) {
            dispatch(setLoadingStatus({ status: false }))
            return response?.data?.data
        } else {
            ErrorMessage('Failed to sent client portal invitation')
            dispatch(setLoadingStatus({ status: false }))
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
        dispatch(setLoadingStatus({ status: false }))
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
