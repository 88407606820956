import {
    Col,
    Row,
    Input,
    Form,
    Collapse,
    Select,
    Switch,
    DatePicker,
    Space,
    type DatePickerProps,
    Flex,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import soleTraderNewStyles from '../../../Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { GetCountryList, GetNationalityList } from '../../../Services/Common'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    type ListNationalityFrontEndInterface,
    type ListFrontEndInterface,
} from '../../../Types/CommonType'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import FullWidthModal from '../../../Components/FullWidthModal'
import {
    GetAddressData,
    GetCompanyOverViewData,
    getMemberDetails,
    SetOfficerCorporate,
    SetOfficerPerson,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { GetContactInfoPersonDropDown } from '../../../Services/Corporate'
import { type CompanyOfficerInformation } from '../../../Types/CompanyHouse/CompanyOverView'

import moment from 'moment'

export interface officerNewStateInterface {
    countryList: ListFrontEndInterface[]
    contactInfoPersonDropDownList: ListFrontEndInterface[]
    nationalityList: ListNationalityFrontEndInterface[]
}
const AddNewOfficerForm = (
    props: officerNewStateInterface & {
        memberRoleId: number
        memberTypeId: number
        onClosemodel: () => void
        corporateId: number
        businessId: number
        companyName: string
        companyNumber: string
        editData: CompanyOfficerInformation
    }
): React.ReactElement => {
    const {
        countryList,
        companyName,
        companyNumber,
        corporateId,
        contactInfoPersonDropDownList,
        nationalityList,
        editData,
        businessId,
        onClosemodel,
    } = props
    const [form] = Form.useForm()
    const [collapseOne, SetCollapseOne] = useState<string[] | string>(['1'])
    const [collapseTwo, SetCollapseTwo] = useState<string[] | string>([])
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const [selectedDateDOB, setSelectedDateDOB] = useState<string>('')
    const [MeberType, setMemberType] = useState(1)
    const [memberRoleType, setMemberRoleType] = useState(1)
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [isSubmitToCH, setIsSubmitToCH] = useState<number>(0)
    const [isSubmitToYes, setIsSubmitToCHYes] = useState<boolean>(false)
    const [isActSubmitToCH, setIsActSubmitToCH] = useState<number>(0)
    const [compayInUk, setCompayInUk] = useState(0)
    const [memberPersonId, setMemberPersonId] = useState(0)
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldsValue({
            divisiontype: editData.memberRoleId,
            membertype: editData.memberTypeId,
            title: editData.titleOrCompanyName,
            firstname: editData.firstNameOrBRNo,
            middlename: editData.middleName,
            surname: editData.surName,
            dateofbirth: editData.dateOfBirth,
            nationality: editData.nationality,
            occupation: editData.occupation,
            countryofresidence: editData.countryOfResidence,
            dateofappointment: editData.dateofAppointment,
            address1: editData.addressLineOne,
            address2: editData.addressLineTwo,
            town: editData.town,
            country: editData.country,
            postcode: editData.postCode,
            corrAddress1: editData.corrAddressLineOne,
            corrAddress2: editData.corrAddressLineTwo,
            corrTown: editData.corrTown,
            corrCountry: editData.corrCountry,
            corrPostcode: editData.corrPostCode,
        })
        setSelectedDateDOB(editData.dateOfBirth)
        setSelectedDateAp(editData.dateofAppointment)
    }, [editData])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCountryList(dispatch)
        GetNationalityList(dispatch)
    }, [dispatch, Form])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const collapseOneOnChange = (data: string | string[]): void => {
        SetCollapseOne(data)
    }

    const collapseTwoOnChange = (data: string | string[]): void => {
        SetCollapseTwo(data)
    }

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDateDOB)
    }, [selectedDateAp])

    const onChangeDateDOB = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateDOB(isoDate)
        }
    }
    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }
    const compayInUkChnage = (value: number): void => {
        setCompayInUk(value)
    }
    useEffect(() => {
        GetContactInfoPersonDropDown(dispatch, corporateId, MeberType)
    }, [corporateId, MeberType])

    const memberPersonOnchange = (value: number): void => {
        setMemberPersonId(value)
    }
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            if (memberPersonId !== 0) {
                const data = await getMemberDetails(
                    dispatch,
                    MeberType,
                    memberPersonId
                )
                form.setFieldsValue({
                    title: data?.title ?? '',
                    firstname: data?.firstName ?? '',
                    middlename: data?.middleName ?? '',
                    surname: data?.surName ?? '',
                })
            }
        }
        void fetchData()
    }, [memberPersonId, MeberType])

    const memberTypeChnage = (value: number): void => {
        setMemberType(value)
    }

    const memberRoleTypeChnage = (value: number): void => {
        setMemberRoleType(value)
    }

    const handleSwitchChange = (checked: boolean): void => {
        setSwithModelStatus(!swithModalStatus)
        setIsSubmitToCHYes(checked)
    }

    const handleActSwitchChange = (checked: boolean): void => {
        setIsActSubmitToCH(checked ? 1 : 0)
    }
    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        setIsSubmitToCH(isSubmitToYes ? 1 : 0)
        ChangeModalFunction()
    }
    const onSaveData = (): void => {
        void onDataTOSave()
    }
    const onDataTOSave = async (): Promise<void> => {
        try {
            const formData = form.getFieldsValue()
            // const formattedStartTime = dayjs(formData.startTime).format(
            //     'DD/MM/YYYY'
            // )
            SetCollapseOne(['1'])
            SetCollapseTwo(['1'])
            await form.validateFields()
            if (MeberType === 1) {
                await form.validateFields()

                const dataToSave = {
                    companyPersonId:
                        editData.referenceId === 0
                            ? memberPersonId
                            : editData.referenceId,
                    companyId: 0,
                    corporateId,
                    divisiontype: MeberType,
                    membertype: memberRoleType,
                    title: formData.title ?? '',
                    firstname: formData.firstname,
                    middlename: formData.middlename ?? '',
                    surname: formData.surname,
                    dateofbirth:
                        moment(selectedDateDOB).format('DD/MM/YYYY') ?? '',
                    address1: formData.address1,
                    address2: formData.address2 ?? '',
                    town: formData.town,
                    country: formData.country ?? '',
                    postcode: formData.postcode,
                    corrAddress1: formData.corrAddress1,
                    corrAddress2: formData.corrAddress2 ?? '',
                    corrTown: formData.corrTown,
                    corrCountry: formData.corrCountry ?? '',
                    corrPostcode: formData.corrPostcode,
                    mobilenumber: editData.mobileNo,
                    homenumber: editData.homeNo,
                    worknumber: editData.workNo,
                    emailaddress: editData.email,
                    altEmailaddress: editData.altEmailaddress,
                    ninumber: editData.niNumber,
                    nationality: formData.nationality,
                    occupation: formData.occupation ?? '',
                    countryofresidence: formData.countryofresidence ?? '',
                    dateofappointment:
                        moment(selectedDateAp).format('DD/MM/YYYY') ?? '',
                    isConsenttoAct: isActSubmitToCH,
                    isSubmitToCH,
                }

                void SetOfficerPerson(dispatch, dataToSave)
            } else {
                await form.validateFields()
                const dataTosend = {
                    companyCorporateId: editData.referenceId ?? memberPersonId,
                    companyId: 0,
                    corporateId,
                    divisionType: MeberType === 2 ? 'Corporate' : 'Person',
                    memberType: memberRoleType,
                    companyName: formData.companyName,
                    companyNumber: formData.companyNumber,
                    corpAddress1: formData.corpAddress1,
                    corpAddress2: formData.corpAddress2,
                    corpTown: formData.corrTown,
                    corpCountry: formData.corrCountry,
                    corpPostCode: formData.corrPostcode,
                    dateofappointment:
                        moment(selectedDateAp).format('DD/MM/YYYY'),
                    companyRegisteredIn: formData.companyRegisteredIn ?? '',
                    isUKLimitedCompany: compayInUk,
                    legalForm: formData.legalForm ?? 0,
                    lawGoverns: formData.lawGoverns ?? 0,
                    isConsenttoAct: isActSubmitToCH,
                    isSubmitToCH,
                }
                void SetOfficerCorporate(dispatch, dataTosend)
            }

            setTimeout(() => {
                GetCompanyOverViewData(dispatch, businessId, corporateId, 0, 2)
                onClosemodel()
            }, 1000)
            form.resetFields()
        } catch (error) {}
    }
    const initialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Member Person" name="companyPersonId">
                    <Select
                        placeholder="Please select"
                        options={contactInfoPersonDropDownList}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                        onChange={memberPersonOnchange}
                        allowClear
                        showSearch
                    />
                </Form.Item>
                <Form.Item label="Title" name="title">
                    <Select
                        allowClear
                        placeholder="Please select title"
                        onChange={() => {}}
                        defaultValue={'UNKNOWN'}
                        filterOption={(input, option) =>
                            typeof option?.children === 'string' && // Ensure option.children is a string
                            (option.children as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        showSearch
                    >
                        <Select.Option value="UNKNOWN">Unknown</Select.Option>
                        <Select.Option value="MR.">MR.</Select.Option>
                        <Select.Option value="MISS.">MISS.</Select.Option>
                        <Select.Option value="MRS.">MRS.</Select.Option>
                        <Select.Option value="MS.">MS.</Select.Option>
                        <Select.Option value="DR.">DR.</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstname"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter First Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Middle Name" name="middlename">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surename"
                    name="surname"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Surename',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const personalDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Date Of Birth" name="dateofbirth">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateDOB(date, dateString)
                            }}
                            value={
                                selectedDateDOB.length > 0
                                    ? dayjs(selectedDateDOB)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Nationality',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={nationalityList}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item label="Occupation" name="occupation">
                    <Input />
                </Form.Item>

                <Form.Item label="Country" name="countryofresidence">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item label="Date of Appointment" name="dateofappointment">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateAppoint(date, dateString)
                            }}
                            value={
                                selectedDateAp.length > 0
                                    ? dayjs(selectedDateAp)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
            </div>
        )
    }

    const homeAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Address 1"
                    name="address1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="address2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="town"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="country">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="postcode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const [busstineAddressSwitch, setBisinessAddressSwitch] = useState(false)
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                if (busstineAddressSwitch) {
                    const data = await GetAddressData(
                        dispatch,
                        businessId,
                        memberPersonId
                    )

                    form.setFieldValue('corrAddress1', data?.addressLineOne)
                    form.setFieldValue('corrAddress2', data?.addressLineTwo)
                    form.setFieldValue('corrTown', data?.town)
                    form.setFieldValue('corrCountry', data?.country)
                    form.setFieldValue('corrPostcode', data?.postCode)
                }
            } catch (error) {}
        }
        void fetchData()
    }, [busstineAddressSwitch, memberPersonId])
    const onchangeRegisterAddres = (checked: boolean): void => {
        setBisinessAddressSwitch(checked)
    }

    const correspondAddress = (): React.ReactElement => {
        return (
            <div>
                <div className={soleTraderNewStyles.cpAddressMain}>
                    <div className={commonStyles.margintRight100}>
                        <Switch
                            size="small"
                            checked={busstineAddressSwitch}
                            onChange={onchangeRegisterAddres}
                        />
                        &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Register Address
                        </label>
                    </div>
                </div>
                <Form.Item
                    label="Address 1"
                    name="corrAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="corrAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="corrTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="corrCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="corrPostcode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const corporateinitialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Member Corporate" name="companyCorporateId">
                    <Select
                        placeholder="Please select"
                        options={contactInfoPersonDropDownList}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                        allowClear
                        showSearch
                    />
                </Form.Item>
                <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Company Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Company Number"
                    name="companyNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Company Number',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Date of Appointment" name="dateofappointment">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateAppoint(date, dateString)
                            }}
                            value={
                                selectedDateAp.length > 0
                                    ? dayjs(selectedDateAp)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
            </div>
        )
    }

    const companyRegisterDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Company Register In"
                    name="companyRegisteredIn"
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="In the company a UK Limited Company?"
                    name="compayInUk"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Company In UK',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder="Please select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            typeof option?.children === 'string' && // Ensure option.children is a string
                            (option.children as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        value={compayInUk}
                        onChange={(value) => {
                            compayInUkChnage(value)
                        }}
                    >
                        <Select.Option key={0} value={0}>
                            Please Select
                        </Select.Option>
                        <Select.Option key={1} value={1}>
                            Yes
                        </Select.Option>
                        <Select.Option key={2} value={2}>
                            No
                        </Select.Option>
                    </Select>
                </Form.Item>
                {compayInUk === 2 && (
                    <>
                        <div>Non UK Companies or Firms</div>
                        <Form.Item
                            label="Legal form of this company"
                            name="legalForm"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                defaultValue={'test'}
                                onChange={() => {}}
                            >
                                <Select.Option key={'test'} value={'test'}>
                                    test
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Law which governs this company"
                            name="lawGoverns"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                defaultValue={'test'}
                            >
                                <Select.Option key={'test'} value={'test'}>
                                    test
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </>
                )}
            </div>
        )
    }

    const CompanyAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Address 1"
                    name="corpAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="corpAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="corrTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name="corrCountry"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Country',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="corrPostcode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    return (
        <>
            <div>
                <h5>
                    Company Name & Reg Number {companyName} - {companyNumber}
                </h5>
            </div>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
                onFinish={onSaveData}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Member Type" name="divisiontype">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    memberTypeChnage(value)
                                }}
                                defaultValue={1}
                            >
                                <Select.Option key={0} value={0}>
                                    Please select
                                </Select.Option>
                                <Select.Option key={1} value={1}>
                                    Person
                                </Select.Option>
                                <Select.Option key={2} value={2}>
                                    Corporate
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Appoint For" name="membertype">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    memberRoleTypeChnage(value)
                                }}
                                defaultValue={1}
                            >
                                <Select.Option key={0} value={0}>
                                    Please select
                                </Select.Option>
                                <Select.Option key={1} value={1}>
                                    Director
                                </Select.Option>
                                <Select.Option key={2} value={2}>
                                    Secorety
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={commonStyles.flexDirectionRow}>
                    <div className={soleTraderNewStyles.collapseFirst}>
                        <Collapse
                            activeKey={collapseOne}
                            onChange={collapseOneOnChange}
                            bordered={true}
                            items={
                                MeberType === 1
                                    ? [
                                          {
                                              key: '1',
                                              label: 'Initial Details',
                                              children: initialDetails(),
                                          },
                                          ...(memberRoleType === 1
                                              ? [
                                                    {
                                                        key: '2',
                                                        label: 'Personal Details',
                                                        children:
                                                            personalDetails(),
                                                    },
                                                ]
                                              : []),
                                      ]
                                    : [
                                          {
                                              key: '3',
                                              label: 'Initial Details',
                                              children:
                                                  corporateinitialDetails(),
                                          },
                                          {
                                              key: '4',
                                              label: 'Company Register Details',
                                              children:
                                                  companyRegisterDetails(),
                                          },
                                      ]
                            }
                        />
                    </div>
                    <div className={soleTraderNewStyles.collapseSecond}>
                        <Collapse
                            activeKey={collapseTwo}
                            onChange={collapseTwoOnChange}
                            bordered={true}
                            items={
                                MeberType === 1
                                    ? [
                                          {
                                              key: '1',
                                              label: 'Correspondance Address',
                                              children: correspondAddress(),
                                          },
                                          ...(memberRoleType === 1
                                              ? [
                                                    {
                                                        key: '2',
                                                        label: 'Home Address',
                                                        children: homeAddress(),
                                                    },
                                                ]
                                              : []),
                                      ]
                                    : [
                                          {
                                              key: '3',
                                              label: 'Company Address',
                                              children: CompanyAddress(),
                                          },
                                      ]
                            }
                        />
                    </div>
                </div>
                &nbsp;
                <Row>
                    <Col span={24}>
                        <Collapse
                            items={[
                                {
                                    key: '1',
                                    label: 'Consent to Act',
                                    children: (
                                        <p>
                                            <Switch
                                                checked={isActSubmitToCH === 1}
                                                onChange={handleActSwitchChange}
                                            />{' '}
                                            <span style={{ marginLeft: 10 }}>
                                                The Company confirms that the
                                                person named has consented to
                                                act as a director / secretary
                                            </span>
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col span={24}>
                        <Switch
                            checked={isSubmitToCH === 1}
                            onChange={handleSwitchChange}
                        />{' '}
                        <span style={{ marginLeft: 10 }}>
                            Select this option, If you are need to apply this
                            changes to companies house.
                        </span>
                    </Col>
                </Row>
                <div style={{ marginTop: '20px' }}>
                    <Flex justify="flex-end" gap={5}>
                        <CusttomBackgroundButton
                            color={'#868e96'}
                            name={'Cancel'}
                            icon={<CloseSquareOutlined />}
                            clickAction={onClosemodel}
                        />
                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'Submit Changes'}
                            icon={<CheckOutlined />}
                            htmlType="submit"
                        />
                    </Flex>
                </div>
            </Form>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

const mapStateToProps = (state: any): officerNewStateInterface => {
    return {
        countryList: state.common.countryList,
        contactInfoPersonDropDownList:
            state.client.contactInfoPersonDropDownList,
        nationalityList: state.common.nationalityList,
    }
}

export default connect(mapStateToProps)(AddNewOfficerForm)
