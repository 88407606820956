import React, { useEffect, useState } from 'react'
import CommonButton from '../../../Components/Commonbutton'
import CompanyDetails from '../../CompanyhouseandCSFillingButtons/ButtonFiles/companyDetails'
import Officers from '../../CompanyhouseandCSFillingButtons/ButtonFiles/ofiicer'
import SAILAddersss from '../../CompanyhouseandCSFillingButtons/ButtonFiles/sailAddress'
import ShareCapital from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareCapital'
import Shareholders from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareHolders'
import PSCPage from '../../CompanyhouseandCSFillingButtons/ButtonFiles/pscsPage'
import { Card, Col, Flex, Modal, Row, Switch } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {
    GetCompanyOverViewData,
    SyncCompanyHouseCH,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { type CompanyHouseOverviewSteteIntrface } from '../../../Types/CompanyHouse/CompanyOverView'
import NameChangePage from '../CompanyOverview/nameEditPage'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { CheckOutlined } from '@ant-design/icons'
const CSFEditButton = (
    props: CompanyHouseOverviewSteteIntrface
): React.ReactElement => {
    const { companyHouseOverViewList } = props
    const location = useLocation()
    const ClientId = location.state.ClientId
    const [activeButton, setActiveButton] = useState(1)
    const [CompanyDetailsBtn, setCompanyDetailsBtn] = useState(true)
    const [OfficerBtn, setOfficerBtn] = useState(false)
    const [SAILAddressBtn, setSAILAddressBtn] = useState(false)
    const [ShareCapitalBtn, setShareCapitalBtn] = useState(false)
    const [ShareholdersBtn, setShareholdersBtn] = useState(false)
    const [PscBtn, setPscBtn] = useState(false)
    const [NameChangeModel, setNameChangeModel] = useState(false)
    const dispatch = useDispatch()
    const splitValues = ClientId?.split('-')
    const businessId = splitValues[0]
    const referenceId = splitValues[1]
    const navigate = useNavigate()
    const [selectedValue, setSelectedValue] = useState<number | null>(null)
    const [showError, setShowError] = useState<boolean>(false)
    const [cardOpenmodal, setCardOpenModal] = useState(false)
    useEffect(() => {
        const serviceProcessId = 0
        GetCompanyOverViewData(
            dispatch,
            businessId,
            referenceId,
            serviceProcessId,
            activeButton
        )
    }, [ClientId, activeButton])

    const OpenNameChangeModel = (): void => {
        setNameChangeModel(!NameChangeModel)
    }

    const changeNameChangeModel = (): void => {
        setNameChangeModel(!NameChangeModel)
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)
        switch (buttonName) {
            case 1:
                setCompanyDetailsBtn(true)
                break
            case 2:
                setOfficerBtn(true)
                break
            case 3:
                setSAILAddressBtn(true)
                break
            case 4:
                setShareCapitalBtn(true)
                break
            case 5:
                setShareholdersBtn(true)
                break
            case 6:
                setPscBtn(true)
                break
            default:
                break
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return <></>
        } else if (activeButton === 2) {
            return <></>
        }
        if (activeButton === 3) {
            return <></>
        }
        if (activeButton === 4) {
            return <></>
        }
        if (activeButton === 5) {
            return <></>
        }
        if (activeButton === 6) {
            return <></>
        }
        return <></>
    }

    const handleSwitchChangeC2 = (value: number): void => {
        if (selectedValue === value) {
            setSelectedValue(null) // Deselect the switch if it is already selected
        } else {
            setSelectedValue(value) // Set the selected value
        }
        setShowError(false) // Reset error when a switch is selected
    }
    const ContineButtonFunction = (): void => {
        if (selectedValue === null) {
            setShowError(true)
        } else {
            // Proceed with your logic (e.g., submitting a form)
            const dataTosend = {
                companyNumber: Number(
                    companyHouseOverViewList?.companyRegNumber
                ),
                tabIndex: selectedValue,
                clientTypeId: companyHouseOverViewList?.clientTypeId,
            }
            void SyncCompanyHouseCH(dispatch, dataTosend)
            setCardOpenModal(false)
            if (selectedValue !== 1) {
                const pageIdentyFyId = 1
                navigate(`/confirmation-Statement/summary`, {
                    state: { pageIdentyFyId, ClientId },
                })
            } else {
                navigate(`/confirmation-Statement/details`, {
                    state: { ClientId },
                })
            }
        }
    }
    // const NavigateEditCSPage = (): void => {
    //     navigate(`/confirmation-Statement/history`, {
    //         state: { referenceId },
    //     })
    //     const url = (`/confirmation-Statement/history`,{
    //         state: { referenceId },
    //     })
    //     window.open(url, '_blank', 'noopener,noreferrer');
    // }
    const NavigateEditCSPage = (): void => {
        const url = `/confirmation-Statement/history?referenceId=${referenceId}`
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    return (
        <>
            {' '}
            <div>
                <h2>Confirmation Statement Filing</h2>
            </div>
            {!cardOpenmodal ? (
                <Card
                    title={
                        <>
                            {companyHouseOverViewList?.clientName} -{' '}
                            {companyHouseOverViewList?.companyRegNumber}
                            <span style={{ marginLeft: '10px' }}>
                                (<a onClick={OpenNameChangeModel}>Edit</a>)
                            </span>
                        </>
                    }
                    extra={
                        <div onClick={NavigateEditCSPage}>
                            <a> CS Submission Status</a>
                        </div>
                    }
                >
                    <Flex justify="space-between" gap={5}>
                        <div>
                            Statement Date : No transaction selected to view the
                            period.
                            <b>
                                If you file a confirmation today,it will show
                                the date as
                            </b>{' '}
                            : {companyHouseOverViewList?.reviewDate}
                        </div>
                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'View Summary & Submit All'}
                            icon={<CheckOutlined />}
                            clickAction={() => {
                                const pageIdentyFyId = 1
                                navigate(`/confirmation-Statement/summary`, {
                                    state: { pageIdentyFyId, ClientId },
                                })
                            }}
                        />
                    </Flex>

                    <CommonButton
                        name="Company Details"
                        isActive={activeButton === 1}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(1)
                        }}
                    />
                    <CommonButton
                        name="Officers"
                        isActive={activeButton === 2}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(2)
                        }}
                    />
                    <CommonButton
                        name="SAIL Address"
                        isActive={activeButton === 3}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(3)
                        }}
                    />
                    <CommonButton
                        name="Share Capital"
                        isActive={activeButton === 4}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(4)
                        }}
                    />
                    <CommonButton
                        name="Shareholders"
                        isActive={activeButton === 5}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(5)
                        }}
                    />
                    <CommonButton
                        name="PSC's"
                        isActive={activeButton === 6}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(6)
                        }}
                    />
                    {CompanyDetailsBtn && activeButton === 1 && (
                        <div>
                            <CompanyDetails
                                companyHousId={1}
                                businessId={businessId}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={1}
                            />
                        </div>
                    )}
                    {OfficerBtn && activeButton === 2 && (
                        <div>
                            <Officers
                                companyHousId={1}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={1}
                            />
                        </div>
                    )}
                    {SAILAddressBtn && activeButton === 3 && (
                        <div>
                            <SAILAddersss
                                businessId={businessId}
                                companyHousId={1}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={1}
                            />
                        </div>
                    )}
                    {ShareCapitalBtn && activeButton === 4 && (
                        <div>
                            <ShareCapital
                                companyHousId={1}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={1}
                            />
                        </div>
                    )}
                    {ShareholdersBtn && activeButton === 5 && (
                        <div>
                            <Shareholders
                                companyHousId={1}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={1}
                            />
                        </div>
                    )}
                    {PscBtn && activeButton === 6 && (
                        <div>
                            <PSCPage
                                companyHousId={1}
                                companyHouseOverViewList={
                                    companyHouseOverViewList
                                }
                                editId={1}
                            />
                        </div>
                    )}
                </Card>
            ) : (
                <Card
                    title={`
                ${companyHouseOverViewList?.clientName} -
                ${companyHouseOverViewList?.companyRegNumber}`}
                >
                    <h3>Would you like to:</h3>
                    <Row>
                        <Col span={2}>
                            <Switch
                                checked={selectedValue === 1}
                                onChange={() => {
                                    handleSwitchChangeC2(1)
                                }}
                            />
                        </Col>
                        <Col span={20}>
                            Check and confirm your company details are up to
                            date before submitting the confirmation statement
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={2}>
                            <Switch
                                checked={selectedValue === 2}
                                onChange={() => {
                                    handleSwitchChangeC2(2)
                                }}
                            />
                        </Col>
                        <Col span={20}>
                            File Confirmation statement without any changes
                        </Col>
                    </Row>
                    {showError && (
                        <p style={{ color: 'red' }}>
                            Please complete the below requirements to continue
                        </p>
                    )}

                    <Flex justify="flex-end" align="flex-end" gap={5}>
                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'Contine'}
                            icon={<CheckOutlined />}
                            clickAction={ContineButtonFunction}
                        />
                    </Flex>
                </Card>
            )}
            <Modal
                footer={null}
                onCancel={changeNameChangeModel}
                width={'50%'}
                title={'Name Change'}
                open={NameChangeModel}
            >
                <NameChangePage
                    corporateId={companyHouseOverViewList?.corporateId}
                    companyName={companyHouseOverViewList?.clientName}
                    companyNumber={companyHouseOverViewList?.companyRegNumber}
                    changeNameChangeModel={changeNameChangeModel}
                    businessId={businessId}
                    serviceProcessId={0}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): CompanyHouseOverviewSteteIntrface => {
    return {
        companyHouseOverViewList: state.companyHouse.companyHouseOverViewList,
    }
}

export default connect(mapStateToProps)(CSFEditButton)
