import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import {
    corporateList,
    corporateTaps,
    priorityDropDownList,
    paymentOptionDropDownList,
    registeredClientCorporateConfiguration,
    contactInfoPersonDropDownList,
} from '../Reducer/Client'
import {
    clientServiceDropDownList,
    serviceDropDownList,
} from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import {
    type CorporateFormDataInterface,
    type CorporateChatInterface,
    type TapFormDataInterface,
    type CreateTaskInterface,
    type officerAddShareholderCorporate,
    type officerAddShareholderPerson,
    type DirectDebitCustomerInterface,
    type ContactInfoInterface,
    type CorporateDocumentSave,
    type ClientCompanyActiveService,
    type ClientCompanyControlData,
    type ClientCompanyVerification,
    type GenerateEmails,
    type ServiceCorporatepension,
    type ServiceCorporatepaye,
    type ServiceCorporateTapssave,
    type CompanyCTDetails,
} from '../Types/Client/RegisteredClients/Corporate'
import {
    type ListFrontEndInterface,
    type ListBackendInterface,
    type TableListInterface,
} from '../Types/CommonType'
import { type AxiosResponse } from 'axios'
import { type Email, type ClientAttachment } from '../Types/Invoice/Invoicing'
import { SendEmailUseNodeJs } from './Common'

export const GetCorporateList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Corporate/GetCorporateCompanyList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CorporateFormDataInterface) => {
                        return {
                            ...value,
                            key: value.corporateid,
                        }
                    }
                )
                response.data.data = newData
                dispatch(corporateList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SyncCompanyHouse = async (
    dispatch: Dispatch,
    data: TapFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Corporate/SyncCompanyHouse/`,
            data
        )

        if (response.status === 200) {
            dispatch(corporateTaps(response.data))
            // SuccessMessage(response.data.operationMsg)
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error: any) {
        if (error?.response?.status === 404) {
            ErrorMessage(
                'Invalid Registration Number Please Enter valid Registration Number'
            )
        }
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
export const SaveChat = async (
    dispatch: Dispatch,
    data: CorporateChatInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/Overview/SetStaffOverviewChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetServiceDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/18')
        .then((response) => {
            if (response.status === 200) {
                const serviceListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(serviceDropDownList(serviceListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const getClientCompanyDetails = async (
    dispatch: Dispatch,
    businessTypeId: number,
    corporateid: number,
    tabIndex: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/corporate/GetClientCompanyDetail/${businessTypeId}/${corporateid}/${tabIndex}`
        )

        if (response.status === 200) {
            dispatch(corporateTaps(response.data))
            // SuccessMessage('Data fetched successfully')
            return response.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
        dispatch(setLoadingStatus({ status: false }))
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const DeleteCorporate = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/1/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ActiveInactiveCorporate = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetRecordActiveStatus/1/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const setBasic = async (
    dispatch: Dispatch,
    data: any
): Promise<AxiosResponse<any> | false> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Corporate/UpdateCorporateCompanyBasicInfo`,
            data
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
        return response
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}

export const GetPriorityList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/24')
        .then((response) => {
            if (response.status === 200) {
                const newPriority: ListFrontEndInterface[] =
                    response.data.data?.map((data: ListBackendInterface) => ({
                        value: data.stdId,
                        label: data.stdName,
                    }))
                dispatch(priorityDropDownList(newPriority))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveTask = async (
    dispatch: Dispatch,
    data: CreateTaskInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTask`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveClientCompanySupplier = async (
    dispatch: Dispatch,
    data: any
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Service/SetClientCompanySupplier/`,
            data
        )
        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response
        } else {
            ErrorMessage(response.data.operationMsg)
        }
        return response
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}

export const SaveOfficerDocument = async (
    dispatch: Dispatch,
    data: CorporateDocumentSave
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/document/SetClientCompanyDocument/`,
            data
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)

        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveCompanyControl = async (
    dispatch: Dispatch,
    data: ClientCompanyControlData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyControl`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SaveClientCompanyVerification = async (
    dispatch: Dispatch,
    data: ClientCompanyVerification
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyVerification`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SaveClientCompanyActiveService = async (
    dispatch: Dispatch,
    data: ClientCompanyActiveService
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyActiveService/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const EnableServiceForComplince = async (
    dispatch: Dispatch,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/Scheduler/SchedulerPreparationByCompanyId/1/${referenceId}`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage('Enable all active services successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetAddress = async (
    dispatch: Dispatch,
    corporateId: number,
    switchId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetCompanyAddressInformation/1/${corporateId}/${switchId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data.data
                getAddress(addressData)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetQuotationPreview = async (
    dispatch: Dispatch,
    corporateid: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/Corporate/GetQuotationPreview/1/${corporateid}`
        )
        if (response.status === 200) {
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveOfficerPerson = async (
    dispatch: Dispatch,
    data: officerAddShareholderPerson
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Corporate/UpdateMemberPersonInfo`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveOfficerCorporate = async (
    dispatch: Dispatch,
    data: officerAddShareholderCorporate
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Corporate/UpdateMemberCorporateInfo`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getClientPortalInvitation = async (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/ClientPortal/GetClientInvitation/${businessTypeId}/${referenceId}/1`
        )

        if (response.status === 200) {
            dispatch(corporateTaps(response.data))
            SuccessMessage('Client portal invitation send successfully')
        } else {
            ErrorMessage('Failed to sent client portal invitation')
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GenerateAccount = async (
    dispatch: Dispatch,
    referenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `/api/Individual/SetIndividualAccount/0/1/${referenceId}`
        )
        dispatch(setLoadingStatus({ status: false }))
        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
            return response?.data?.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        ErrorMessage('This person Have induvidual account')
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}
export const GetPaymentOptionDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/56')
        .then((response) => {
            if (response.status === 200) {
                const paymentOptionListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(paymentOptionDropDownList(paymentOptionListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetDirectDebitCustomer = async (
    dispatch: Dispatch,
    data: DirectDebitCustomerInterface,
    cancelGoCardlessModal: CallableFunction
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/DirectDebit/SetDirectDebitCustomer`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                cancelGoCardlessModal()
                SetClientEmail(dispatch, data, response.data.identifierId)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetClientEmail = (
    dispatch: Dispatch,
    data: DirectDebitCustomerInterface,
    identifierId: string
): void => {
    const clientEmailData = {
        ClientEmailId: 0,
        TemplateId: 0,
        ServiceProcessId: -7,
        CompanyTypeId: data.BusinessTypeId,
        ReferenceId: data.ReferenceId,
        ServiceActionId: 0,
        ServiceActionStepId: 0,
        ToAddress: data.Email,
        CCAddress: '',
        BCCAddress: 'info@iykons.com',
        IykonsBCCAddress: 'info@iykons.com',
        FromAddress: 'info@iykons.com',
        Subject:
            'Iykons Limited would like authorisation to take payment from you',
        Description: data.Description,
    }
    axios
        .post(`api/Email/SetClientEmail`, clientEmailData)
        .then((response) => {
            if (response.status === 200) {
                SetClientEmailStatus(dispatch, identifierId)
            }
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            return false
        })
}

export const SetClientEmailStatus = (
    dispatch: Dispatch,
    identifierId: string
): void => {
    const ClientEmailStatus = {
        ClientEmailId: identifierId,
        EmailStatus: 'Success',
    }
    axios
        .post(`api/Email/SetClientEmailStatus`, ClientEmailStatus)
        .then((response) => {})
        .catch((error) => {
            console.error('Error saving posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetDirectDebitCustomer = (
    dispatch: Dispatch,
    companyTypeId: number,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/corporate/GetClientCompanyDetail/${companyTypeId}/${referenceId}/10`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(registeredClientCorporateConfiguration(response.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const PopulateindualAccountList = async (
    dispatch: Dispatch,
    referenceId: number,
    getAccount: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/14/${referenceId}`)
        .then((response) => {
            if (response.status === 200) {
                const accountdata = response.data.data
                getAccount(accountdata)
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const AttachIndividual = async (
    dispatch: Dispatch,
    individualId: number,
    companyType: number,
    referenceId: number,
    status: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `/api/Individual/SetAttachIndividualAccount/${individualId}/${companyType}/${referenceId}/${status}`
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
            return response?.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return response?.data
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}

export const DeleteShareholder = async (
    dispatch: Dispatch,
    referenceId: number,
    corporateId: number,
    OfficerTypeid: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/SetDeleteRecordWithTwoParameter/1/${referenceId}/${corporateId}/${OfficerTypeid}`
        )
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveContactInfo = async (
    dispatch: Dispatch,
    data: ContactInfoInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Corporate/SetContactPerson`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GenerateEmail = async (
    dispatch: Dispatch,
    data: GenerateEmails
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Email/SetClientEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SetClientEmailStatus(dispatch, response.data.identifierId)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCTService = async (
    dispatch: Dispatch,
    serviceReferenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Service/GetClientCompanyServiceDetail/2/${serviceReferenceId}`
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        console.error('Error getting CT service details:', error)
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}

export const GetVATService = async (
    dispatch: Dispatch,
    serviceReferenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    return await axios
        .get(
            `/api/Service/GetClientCompanyServiceDetail/1/${serviceReferenceId}`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))

            if (response.status === 200) {
                return response.data
            } else {
                ErrorMessage(response.data.operationMsg)
                return null
            }
        })
        .catch((error) => {
            console.error('Error getting VAT service details:', error)
            dispatch(setLoadingStatus({ status: false }))
            return null
        })
}

export const GetPAYEService = async (
    dispatch: Dispatch,
    serviceReferenceId: number
): Promise<any | null> => {
    dispatch(setLoadingStatus({ status: true }))

    return await axios
        .get(
            `/api/Service/GetClientCompanyServiceDetail/3/${serviceReferenceId}`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))

            if (response.status === 200) {
                return response.data
            } else {
                ErrorMessage(response.data.operationMsg)
                return null
            }
        })
        .catch((error) => {
            console.error('Error getting PAYE service details:', error)
            dispatch(setLoadingStatus({ status: false }))
            return null
        })
}

export const GetPensionService = async (
    dispatch: Dispatch,
    serviceReferenceId: number
): Promise<any> => {
    // Replace 'any' with the type you expect to be returned
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Service/GetClientCompanyServiceDetail/4/${serviceReferenceId}`
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        console.error('Error getting PAYE service details:', error)
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}
export const SaveCTServices = async (
    dispatch: Dispatch,
    data: CompanyCTDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServiceCT600`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SaveVATServices = async (
    dispatch: Dispatch,
    data: ServiceCorporateTapssave
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServiceVAT`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SavepayeServices = async (
    dispatch: Dispatch,
    data: ServiceCorporatepaye
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServicePaye`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SavepensionServices = async (
    dispatch: Dispatch,
    data: ServiceCorporatepension
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServicePension`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const RemoveTag = async (
    dispatch: Dispatch,
    individualId: number,
    companyType: number,
    referenceId: number,
    status: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    // await axios
    // .post(`/api/Corporate/RemoveIndividual/${ReferenceId}/${InduvidualId}`)
    await axios
        .post(
            `/api/Individual/RemoveIndividualAccount/${individualId}/${companyType}/${referenceId}/${status}`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetServiceDropDownListBusinessType = (
    dispatch: Dispatch,
    busineeId: number,
    refernceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/77/${busineeId}/${refernceId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const serviceListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(clientServiceDropDownList(serviceListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getContactPersonDetails = async (
    dispatch: Dispatch,
    officerType: number,
    referenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Corporate/GetOfficerHeaderInfo/${officerType}/${referenceId}/`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to sent client portal invitation')
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetContactInfoPersonDropDown = (
    dispatch: Dispatch,
    corporateId: number,
    memberTypeId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/8/${corporateId}/${memberTypeId}`
        ) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(contactInfoPersonDropDownList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveEmailRemider = async (
    dispatch: Dispatch,
    data: Email,
    attachment: string[]
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    return await axios
        .post(`/api/Communication/SetCommunicationByInvoiceId`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)

                attachment.forEach((path): void => {
                    const dataToUpload = {
                        clientAttachmentId: response.data.identifierId,
                        clientEmailId: 0,
                        attachmentPath: path,
                        uniqueCode: '',
                    }
                    void SaveEmailAttachments(dispatch, dataToUpload)
                    // Perform any desired operation with each path
                })
                const emailSend = {
                    email: data.toAddress,
                    subject: data.subject,
                    htmlMessage: 'Email-Template',
                    description: data.description,
                    attachments: attachment,
                    ccemailAddress: data.ccAddress,
                    bccEmailAddress: data.bccAddress,
                    contactName: data.contactName,
                    employeeName: '',
                    actionUrl: '',
                }
                void SendEmailUseNodeJs(dispatch, emailSend)

                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveEmailAttachments = async (
    dispatch: Dispatch,
    data: ClientAttachment
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Email/SetClientEmailAttachment`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getClientCompanyContactDetails = async (
    dispatch: Dispatch,
    businessTypeId: number,
    corporateid: number,
    tabIndex: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/corporate/GetClientCompanyDetail/${businessTypeId}/${corporateid}/${tabIndex}`
        )

        if (response.status === 200) {
            // SuccessMessage('Data fetched successfully')
            return response.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
        dispatch(setLoadingStatus({ status: false }))
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
