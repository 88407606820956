/* eslint-disable react/react-in-jsx-scope */
import { Card, Flex, Modal, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type SubTaskView,
    type Task,
    type subtaskinterface,
} from '../../../Types/HelpDesk/Cretetask'

import commonStyles from '../../../Utils/Common.less'
import { useCallback, useEffect, useRef, useState, type Key } from 'react'
import {
    MarkAsCompleteSubTask,
    SubViewDetails,
} from '../../../Services/Helpdesk/CreteTask'
import TodoStyles from './createaskcss.less'
import {
    EditOutlined,
    FieldTimeOutlined,
    PaperClipOutlined,
    UserOutlined,
    CheckOutlined,
} from '@ant-design/icons'
import FullWidthModal from '../../../Components/FullWidthModal'
import CreateHours from './CreateHours'
import AttachementList from './Attachment'
const subtaskModal = (
    props: subtaskinterface & {
        taskId: string
        getTask: Task
        activeKey: string
        setActiveKey: React.Dispatch<React.SetStateAction<string>>
        setSubtaskData: React.Dispatch<React.SetStateAction<SubTaskView>>
    }
): React.ReactElement => {
    const { getTask, SubViewDetail, activeKey, setActiveKey, setSubtaskData } =
        props
    const dispatch = useDispatch()
    const [markasDoneModalStatus, setMarkasDoneStatus] = useState(false)
    const [modalVisibleCreateAttachment, setModalVisibleCreateAttachment] =
        useState(false)
    const [modalVisibleCreateHours, setModalVisibleCreateHours] =
        useState(false)
    const [attachmentTaskId, setAttachmentTaskId] = useState('')
    const [modalTitleCreate, setModalTitleCreate] = useState('Create')
    const [restData, setResetData] = useState('false')
    const [subTaskId, setSubTaskId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const formatDate = (dateStr: string | number | Date): string => {
        const date = new Date(dateStr)
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        })
    }

    const getTableData = useCallback(() => {
        const projectStatuId = getTask.projectStatusId?.toString()
        void SubViewDetails(dispatch, projectStatuId, '=NA=', 1, 10, 0, 0, 0, 0)
    }, [dispatch, activeKey, getTask.projectStatusId, SubViewDetails])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
        hasFetchedOnce.current = false
    }, [getTableData])

    const filterListTask = SubViewDetail.filter(
        (task) => task.taskId === getTask.taskId
    )

    const DonemodelModalFunction = (id: string): void => {
        setSubTaskId(Number(id))
        setMarkasDoneStatus(!markasDoneModalStatus)
    }
    const markasDoneStatus = (): void => {
        MarkAsCompleteSubTask(dispatch, 2, subTaskId, getTableData)
        DonemodelModalFunction('')
    }
    const handleModalOpenCreateHours = (taskId: string): void => {
        setModalVisibleCreateHours(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Spent Hours')
    }
    const onAttachment = (taskId: string): void => {
        setModalVisibleCreateAttachment(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Task Attachment')
    }

    const handleModalClose = (): void => {
        setModalVisibleCreateAttachment(false)
        setModalVisibleCreateHours(false)
    }

    return (
        <>
            {filterListTask?.map((task, index) =>
                task.subTaskViews.length !== 0 ? (
                    <div key={index}>
                        {Array.isArray(task.subTaskViews) &&
                            task.subTaskViews.map(
                                (
                                    subtask: SubTaskView,
                                    subIndex: Key | null | undefined
                                ) => {
                                    return (
                                        <Card
                                            key={subIndex}
                                            className={commonStyles.card}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '20px',
                                                    }}
                                                >
                                                    <div>
                                                        <span
                                                            style={{
                                                                display:
                                                                    'inline-block',
                                                                width: '200px',
                                                            }}
                                                        >
                                                            {
                                                                subtask.subTaskName
                                                            }
                                                        </span>
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    subtask.daysColourCode,
                                                                color: 'white',
                                                                padding:
                                                                    '5px 8px',
                                                                display:
                                                                    'inline-block',
                                                            }}
                                                        >
                                                            {formatDate(
                                                                subtask.toDate
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        <Tooltip
                                                            title={`Assigend by ${subtask.assignedByName} `}
                                                        >
                                                            <UserOutlined />
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip
                                                            title={`Assigend to ${subtask.assignedToName} `}
                                                        >
                                                            <UserOutlined />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            {subtask?.status !== 'Done' && (
                                                <div
                                                    className={
                                                        commonStyles.margingTop10
                                                    }
                                                >
                                                    <Flex gap={5}>
                                                        <Tooltip title="Edit Task">
                                                            <EditOutlined
                                                                className={
                                                                    TodoStyles.todoIcons
                                                                }
                                                                onClick={() => {
                                                                    setActiveKey(
                                                                        '2'
                                                                    )
                                                                    setSubtaskData(
                                                                        subtask
                                                                    )
                                                                }}
                                                            />
                                                        </Tooltip>

                                                        <Tooltip title="Add Spent Hours">
                                                            <FieldTimeOutlined
                                                                className={
                                                                    TodoStyles.todoIcons
                                                                }
                                                                onClick={() => {
                                                                    handleModalOpenCreateHours(
                                                                        subtask?.subTaskId?.toString()
                                                                    )
                                                                    setResetData(
                                                                        'true'
                                                                    )
                                                                }}
                                                            />
                                                        </Tooltip>

                                                        <Tooltip title="Add Task Attachment">
                                                            <PaperClipOutlined
                                                                className={
                                                                    TodoStyles.todoIcons
                                                                }
                                                                onClick={() => {
                                                                    onAttachment(
                                                                        subtask?.subTaskId?.toString()
                                                                    )
                                                                    setResetData(
                                                                        'true'
                                                                    )
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Mark as Done">
                                                            <CheckOutlined
                                                                className={
                                                                    TodoStyles.todoIcons
                                                                }
                                                                onClick={() => {
                                                                    DonemodelModalFunction(
                                                                        subtask?.subTaskId?.toString()
                                                                    )
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Flex>
                                                </div>
                                            )}
                                        </Card>
                                    )
                                }
                            )}
                    </div>
                ) : (
                    <h3 key={1}>No Data</h3>
                )
            )}
            <FullWidthModal
                modalStatus={markasDoneModalStatus}
                closeModal={DonemodelModalFunction}
                tittle="You are about to mark as completed this record. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={markasDoneStatus}
            />
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreateAttachment}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <AttachementList
                    taskId={attachmentTaskId}
                    setResetData={setResetData}
                    resetdata={restData}
                />
            </Modal>
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreateHours}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateHours
                    taskid={attachmentTaskId}
                    setResetData={setResetData}
                    resetdata={restData}
                    areaId={2}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): subtaskinterface => {
    return {
        userList: state.user.userList,
        userimageList: state.helpdesk.userimageList,
        subtaskDetailsList: state.helpdesk.subtaskDetailsList,
        subtaskDetailsListCount: state.helpdesk.subtaskDetailsListCount,
        SubViewDetail: state.helpdesk.SubViewDetail,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(subtaskModal)
