import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Switch, Row, Col, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCountryList } from '../../../../../../Services/Common'
import type {
    TogilleAddress,
    CtStateInterface,
    CompanyCTDetails,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import {
    GetAddress,
    SaveCTServices,
} from '../../../../../../Services/Corporate'
// import Servicesless from '../../../../../../Layouts/Less/style.less'
export const defaultCompanyDetails = {
    cT600Id: 0,
    companyType: 1, // Example value for Company Type
    referenceId: 0,
    companyUTR: '',
    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    status: 'Inactive', // Example status
}

const CTForm = ({
    ctServiceData,
    isEditMode,
    countryList,
    corporateId,
    onSave,
}: {
    ctServiceData: CompanyCTDetails
    isEditMode: boolean
    countryList: ListFrontEndInterface[]
    corporateId: number
    onSave: () => void
}): React.ReactElement => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [addressDatas] = useState<TogilleAddress | null>(null)

    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                corporateId,
                switchId,
                (addressData: TogilleAddress) => {
                    form.setFieldsValue({
                        addressLineOne: addressData.addressLineOne ?? '',
                    })
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }
    useEffect(() => {
        GetCountryList(dispatch)
    }, [dispatch])
    useEffect(() => {
        if ((ctServiceData ?? null) !== null && isEditMode) {
            const countryData = countryList?.find(
                (data) => data.label === ctServiceData?.country
            )

            const newData = {
                ...ctServiceData,
                country: countryData != null ? countryData.value : '',
            }

            form.setFieldsValue(newData)
        }
    }, [ctServiceData, isEditMode])

    useEffect(() => {
        if (Boolean(ctServiceData) && isEditMode) {
            form.setFieldsValue({
                companyUTR: ctServiceData?.companyUTR,
                addressLineOne: ctServiceData?.addressLineOne,
                addressLineTwo: ctServiceData?.addressLineTwo,
                town: ctServiceData?.town,
                country: ctServiceData?.country,
                postCode: ctServiceData?.postCode,
            })
        }
    }, [ctServiceData, isEditMode])

    useEffect(() => {
        if (addressDatas != null && addressDatas?.addressLineOne?.length > 0) {
            const newFieldValues = {
                addressLineOne: addressDatas?.addressLineOne,
                addressLineTwo: addressDatas?.addressLineTwo,
                town: addressDatas?.town,
                country: addressDatas?.country,
                postCode: addressDatas?.postCode,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])
    const [servicesEnabled, setServicesEnabled] = useState(false)
    const handleServiceSwitchChange = (checked: boolean): void => {
        setServicesEnabled(checked)
    }
    const onFinish = (): void => {
        const formData = form.getFieldsValue()
        const dataToSave = {
            cT600Id: formData.cT600Id ?? ctServiceData?.cT600Id,
            companyType: ctServiceData?.companyType ?? 1,
            referenceId: formData.referenceId ?? ctServiceData?.referenceId,
            companyUTR: formData.companyUTR ?? ctServiceData?.companyUTR,
            addressLineOne:
                formData.addressLineOne ?? ctServiceData?.addressLineOne,
            addressLineTwo:
                formData.addressLineTwo ?? ctServiceData?.addressLineTwo,
            town: formData.town ?? ctServiceData?.town,
            country: formData.country ?? ctServiceData?.country,
            postCode: formData.postCode ?? ctServiceData?.postCode,
            status: servicesEnabled ? 'Active' : 'Inactive',
        }

        void SaveCTServices(dispatch, dataToSave)
        onSave()
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                form={form}
                name="ct-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout={'vertical'}
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="officeSwitch">
                            <Row gutter={8} align="middle">
                                <Col span={24}>
                                    <span>Same as Office</span>
                                </Col>
                            </Row>
                            <Row gutter={8} align="middle">
                                <Col span={10}>
                                    <Switch
                                        id="1"
                                        checked={switchStates[1]}
                                        onChange={() => {
                                            handleSwitchChange(1)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="businessSwitch">
                            <Row gutter={8} align="middle">
                                <Col span={24}>
                                    <span>Same as Business</span>
                                </Col>
                            </Row>
                            <Row gutter={8} align="middle">
                                <Col span={10}>
                                    <Switch
                                        id="2"
                                        checked={switchStates[2]}
                                        onChange={() => {
                                            handleSwitchChange(2)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Row gutter={8} align="middle">
                                <Col span={24}>
                                    <span>Same as Correspondence</span>
                                </Col>
                            </Row>
                            <Row gutter={8} align="middle">
                                <Col span={10}>
                                    <Switch
                                        id="3"
                                        checked={switchStates[3]}
                                        onChange={() => {
                                            handleSwitchChange(3)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="cT600Id" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="referenceId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Company UTR"
                            name="companyUTR"
                            // rules={[
                            //     {
                            //         pattern: /^\d{10,15}$/,
                            //         message:
                            //             'Company UTR must be 10 - 15 digits!',
                            //     },
                            // ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Town" name="town">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Address 1" name="addressLineOne">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Country" name="country">
                            <Select
                                allowClear
                                placeholder="Please select"
                                showSearch
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Address 2" name="addressLineTwo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Post Code" name="postCode">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item>
                            <Row gutter={8} align="middle">
                                <Col span={24}>
                                    <span>Enable/Disable Services</span>
                                </Col>
                            </Row>
                            <Row gutter={8} align="middle">
                                <Col span={10}>
                                    <Switch
                                        onChange={handleServiceSwitchChange}
                                        checked={servicesEnabled}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button type="primary" onClick={onFinish}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): CtStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(CTForm)
