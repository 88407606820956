import { Input, Form, Row, Col } from 'antd'
import { connect } from 'react-redux'
import commonStyles from '../../../../../../Utils/Common.less'
import React, { useEffect } from 'react'
import { type CorporateConfigurationInterface } from '../../../../../../Types/Client/RegisteredClients/Individual'
const { TextArea } = Input

const GoCardlessForm = (
    props: CorporateConfigurationInterface
): React.ReactElement => {
    const { registeredClientIndividualConfiguration } = props
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(registeredClientIndividualConfiguration.data)
    }, [registeredClientIndividualConfiguration])

    const handleFinish = (): void => {
        const formData = form.getFieldsValue()
        console.log(formData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                autoComplete="off"
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Company Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Given Name"
                            name="givenName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Given Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Family Name"
                            name="familyName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Family Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Address Line1"
                            name="addressLine1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Address Line1',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item label="Address Line2" name="addressLine2">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item label="Address Line3" name="addressLine3">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[
                                {
                                    required: true,

                                    pattern: /^[a-zA-Z]+$/,
                                    message:
                                        'City cannot be a number or contain numbers!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Postal Code"
                            name="postalCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your PostCode!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Country Code"
                            name="countryCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Country Code',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Region"
                            name="region"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Region cannot be a number or contain numbers!',

                                    pattern: /^[a-zA-Z]+$/,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Language cannot be a number or contain numbers!',

                                    pattern: /^[a-zA-Z]+$/,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Phone Number"
                            name="phoneNumber"
                            rules={[
                                {
                                    pattern:
                                        /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                                    message: 'please enter valid mobile number',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Description',
                                },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        registeredClientCorporateConfiguration:
            state.client.registeredClientCorporateConfiguration,
    }
}

export default connect(mapStateToProps)(GoCardlessForm)
