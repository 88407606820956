// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonContainer_iiI1_ {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.previewButton_lCTXS {
  background-color: #0d6dbb;
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: x-large;
  color: white;
  width: 180px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.activeButton_Oix00 {
  border: 10px solid #002cf0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.btnSpace_IhLD6 {
  margin-left: 10px;
}
.rowSpace_uwYTT {
  margin-top: 10px;
}
.dateWidth_SoME8 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/HR/LeaveAndPermission/ApplyLeaveModule.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,oBAAA;AACJ;AAEA;EACI,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,4DAAA;AAAJ;AAKA;EACI,0BAAA;EACA,uCAAA;AAHJ;AAMA;EACI,iBAAA;AAJJ;AAOA;EACI,gBAAA;AALJ;AAQA;EACI,WAAA;AANJ","sourcesContent":[".buttonContainer {\n    display: flex;\n    flex-wrap: wrap;\n    margin-bottom: -10px;\n}\n\n.previewButton {\n    background-color: #0d6dbb;\n    padding: 10px 20px;\n    margin-right: 10px;\n    margin-bottom: 10px;\n    font-size: x-large;\n    color: white;\n    width: 180px;\n    height: 100px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 5px;\n    transition:\n        background-color 0.3s ease,\n        box-shadow 0.3s ease;\n}\n\n.activeButton {\n    border: 10px solid #002cf0;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n}\n\n.btnSpace {\n    margin-left: 10px;\n}\n\n.rowSpace {\n    margin-top: 10px;\n}\n\n.dateWidth {\n    width: 100%;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `buttonContainer_iiI1_`,
	"previewButton": `previewButton_lCTXS`,
	"activeButton": `activeButton_Oix00`,
	"btnSpace": `btnSpace_IhLD6`,
	"rowSpace": `rowSpace_uwYTT`,
	"dateWidth": `dateWidth_SoME8`
};
export default ___CSS_LOADER_EXPORT___;
