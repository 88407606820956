import React from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { type PhoneCallProps } from '../../../Types/ToDoViewAllList'
import { useDispatch } from 'react-redux'
import { SaveSkipAction } from '../../../Services/ToDoViewAllList'

const SkipActionModel = (props: PhoneCallProps): React.ReactElement => {
    const { serviceProcessId, serviceActionId, changePhoneCallModalStatus } =
        props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                serviceProcessId,
                serviceActionId: serviceActionId ?? 0,
                skippedReason: formData.description,
            }

            await SaveSkipAction(dispatch, dataToSave)

            form.setFieldsValue({
                description: '',
            })

            changePhoneCallModalStatus()
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Comments"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Comments!',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Skip Action">
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default SkipActionModel
