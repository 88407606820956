import React, { useEffect, useState } from 'react'
import { Card, Row, Col, List, Empty } from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './index.less'
import { ReactComponent as MobileRequestIconOne } from '../../Assest/Svg/MobileRequestIconOne.svg'
import { ReactComponent as MissingInfoTittle } from '../../Assest/Svg/MissingInfoTittleIcon.svg'
import { type ClientRequestInfo } from '../../Types/Dashboard'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { formatDateFromISO } from '../../Utils/dateFormat'

const MobileRequest = ({
    clientRequestList,
}: {
    clientRequestList: ClientRequestInfo[]
}): React.ReactElement => {
    const [data, setData] = useState<ClientRequestInfo[]>([])
    const navigate = useNavigate()
    useEffect(() => {
        setData(clientRequestList)
    }, [clientRequestList])

    return (
        <div className={`${styles.marginRight10} ${styles.marginLeft10}`}>
            <Card className={`${styles.card}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <div className={styles.mobileRequest__tittle}>
                                    Mobile Request
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                className={styles.mobileRequest__viewall}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate('/help-desk/mobile-request')
                                }}
                            >
                                View All
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.marginTop10}>
                        <Col span={24}>
                            {data.length > 0 ? (
                                <List>
                                    <VirtualList
                                        data={data}
                                        height={220}
                                        itemKey="clientRequestId"
                                    >
                                        {(
                                            item: ClientRequestInfo,
                                            index: number
                                        ) => (
                                            <List.Item
                                                key={index}
                                                className={
                                                    styles.moblieRequest__list__item
                                                }
                                                style={{
                                                    borderRadius: '10px',
                                                    border: '0px',
                                                }}
                                            >
                                                <Row>
                                                    <Col
                                                        span={24}
                                                        className={
                                                            styles.moblieRequest__list__item__tittle__main
                                                        }
                                                    >
                                                        <MobileRequestIconOne />
                                                        &nbsp;&nbsp;
                                                        <div
                                                            className={
                                                                styles.moblieRequest__list__item__tittle
                                                            }
                                                        >
                                                            {item.businessName}
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <MissingInfoTittle />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    className={
                                                        styles.moblieRequest__list__item__subtsittle__main
                                                    }
                                                    align={'stretch'}
                                                    justify={'space-between'}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        ID: &nbsp;
                                                        {item.requestNo}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        Date: &nbsp; <br />
                                                        {dayjs(
                                                            item.requestDate
                                                        ).format(
                                                            'DD/MM/YYYY'
                                                        ) !== 'Invalid Date' &&
                                                        dayjs(
                                                            item.requestDate
                                                        ).format(
                                                            'DD/MM/YYYY'
                                                        ) !== undefined
                                                            ? formatDateFromISO(
                                                                  item.requestDate
                                                              )
                                                            : ''}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        className={
                                                            styles.moblieRequest__list__item__subtsittle__col
                                                        }
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: `${item.colorCode}`,
                                                            }}
                                                            className={
                                                                styles.moblieRequest__list__item__dot
                                                            }
                                                        />

                                                        <div
                                                            className={
                                                                styles.moblieRequest__list__item__subtsittle
                                                            }
                                                            style={{
                                                                paddingLeft:
                                                                    '5px',
                                                            }}
                                                        >
                                                            {item.serviceName}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    )
}

export default MobileRequest
