import React, { useState } from 'react'
import { Col, Row, Input, Form, Button, Card, Tooltip } from 'antd'
import commonStyles from '../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { SaveChangePassword } from '../../Services/Profile'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import profileCss from './profile.less'

const ChangePassword = (): React.ReactElement => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null
    const [criteria, setCriteria] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
    })
    const [passwordInput, setPasswordInput] = useState('')
    const onSave = (): void => {
        void onSavePassword()
    }
    const onSavePassword = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            if (formData.conformPassword === formData.newPassword) {
                SuccessMessage('Password Match')
                const dataTosave = {
                    userId: tokenDetails?.userId,
                    newPassword: formData.newPassword,
                }
                await SaveChangePassword(dispatch, dataTosave)

                form.resetFields()
            } else {
                ErrorMessage('The two passwords do not match!')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const handlePasswordChange = (e: { target: { value: any } }): void => {
        const value = e.target.value
        setPasswordInput(value)
        setCriteria({
            length: value.length >= 8,
            lowercase: /[a-z]/.test(value),
            uppercase: /[A-Z]/.test(value),
            number: /\d/.test(value),
            specialChar: /[!@#$%^&*":|<>]/.test(value),
        })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Card title={'Change Password'}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Current Password"
                                    name="currentPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Current Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Enter your current password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="New Password"
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your New Password!',
                                        },
                                        {
                                            pattern:
                                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*.?"|<>]).{8,}$/,
                                            message:
                                                'Must contain at least one number and one uppercase and lowercase letter and one special characters , and at least 8 or more characters',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Enter your password"
                                        onChange={handlePasswordChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Confirm Password"
                                    name="conformPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Confirm Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Re-type your password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col offset={22} span={2}>
                                <Tooltip title="Change Password">
                                    <Button type="primary" onClick={onSave}>
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        {passwordInput.length > 0 && (
                            <Row>
                                <Col span={12}>
                                    <div
                                        className={profileCss.validatemssageCom}
                                    >
                                        <h3 style={{ fontSize: '13px' }}>
                                            Password must contain the following:
                                        </h3>
                                        <p
                                            className={
                                                criteria.lowercase
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>lowercase</b> letter
                                        </p>
                                        <p
                                            className={
                                                criteria.uppercase
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>capital (uppercase)</b> letter
                                        </p>
                                        <p
                                            className={
                                                criteria.number
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>number</b>
                                        </p>
                                        <p
                                            className={
                                                criteria.length
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            Minimum <b>8 characters</b>
                                        </p>
                                        <p
                                            className={
                                                criteria.specialChar
                                                    ? `${profileCss.valid}`
                                                    : `${profileCss.invalid}`
                                            }
                                        >
                                            A <b>special character</b>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
            </Card>
        </>
    )
}

export default ChangePassword
