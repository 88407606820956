import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    Card,
    Tooltip,
    Table,
    type DatePickerProps,
    Modal,
    type TableProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'

import commonStyles from '../../../Utils/Common.less'
import {
    SaveOutlined,
    HistoryOutlined,
    UnorderedListOutlined,
    DeleteOutlined, // Import DeleteOutlined icon
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetRegisteClientDetails } from '../../../Services/Common'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import { formatdate } from '../../../Utils/dateFormat'
import { type ColumnsType } from 'antd/es/table'
import {
    type MonthwiseProps,
    type monthwiseListDataTypeInterface,
} from '../../../Types/Hr/Reports'
import { Getmonthwise } from '../../../Services/Reports'
import InvoiceHistory from '../../../Pages/ThingToDoViewAll/CommunicationModel/CommunicationHistory'
import ServiceProcessHistory from '../../../Pages/ThingToDoViewAll/ProcessModel/serviceProcessHistoryModel'
import SearchInput from '../../../Components/SearchInput'
import calculatePagination from '../../../Utils/Pagination'

const MonthwiseReport = (props: MonthwiseProps): React.ReactElement => {
    const {
        categoryDropDownList,
        serviceDropDownList,
        monthwiseList,
        regiterClientDetails,
        monthwiseListCount,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selectedSatusId, setSelectedStatusId] = useState(0)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const [selectedCompanyGroupId, setSelectedCompanyGroupsId] = useState(0)
    const [selectedConsiderId, setSelectedConsiderId] = useState(2)
    const [showTable, setShowTable] = useState(false)
    const [comHistorymodel, setCommunicationHistorymodel] = useState(false)
    const [Historymodel, setHistorymodel] = useState(false)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const [selectedBusinessId, setSelectedBusinessId] = useState(0)
    const [serviceProcessId, setServiceProcessId] = useState<number>(0)
    const [searchString, setSearchstring] = useState('=NA=')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(1000)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetRegisteClientDetails(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const extractMonthAndYear = (
        dateString: string
    ): { month: number; year: number } => {
        const dateObject = new Date(dateString)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        return { month, year }
    }

    const handleSelectChangeStatus = (value: number): void => {
        setSelectedStatusId(value)
    }

    const handleSelectChangeService = (value: number): void => {
        setSelectedServiceId(value)
    }

    const handleSelectChangeCompanyGroup = (value: number): void => {
        setSelectedCompanyGroupsId(value)
    }

    const handleSelectConsiderDate = (value: number): void => {
        setSelectedConsiderId(value)
    }
    const openHistoryModel = (id: number): void => {
        setServiceProcessId(id)
        setCommunicationHistorymodel(!comHistorymodel)
    }

    const changeCommunictionHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setCommunicationHistorymodel(!comHistorymodel)
    }
    const onHistoryModelOpen = (serviceprocesId: number): void => {
        setServiceProcessId(serviceprocesId)
        setHistorymodel(!Historymodel)
    }
    const changeHistoryModalStatus = (): void => {
        setServiceProcessId(0)
        setHistorymodel(!Historymodel)
    }

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const handleSelectChangeBusinesType = (value: number): void => {
        setSelectedBusinessId(value)
    }
    const StatustDropdowlist = [
        { value: 0, label: 'In Progress' },
        { value: 1, label: 'Overdue' },
        { value: 2, label: 'Completed' },
    ]

    const DateFlagDropdowlist = [
        { value: 2, label: 'Duedate' },
        { value: 1, label: 'Period' },
    ]

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        if (data.length > 0) {
            setSearchstring(data)
        } else {
            setSearchstring('=NA')
        }
    }

    const getTableData = useCallback(() => {
        const { month, year } = extractMonthAndYear(selectedDate)
        const searchData = {
            searchString: searchString ?? '=NA=',
            pageNo: currentPage,
            pageSize: itemsPerPage,
            flagId: selectedConsiderId,
            serviceId: selectedServiceId ?? 0,
            statusId: selectedSatusId ?? 0,
            clientCategoryId: selectedCompanyGroupId ?? 0,
            clientTypeId: selectedBusinessId ?? 0,
            month,
            year,
        }
        void Getmonthwise(dispatch, searchData)
        setShowTable(true)
    }, [
        searchString,
        currentPage,
        itemsPerPage,
        selectedConsiderId,
        selectedServiceId,
        selectedSatusId,
        selectedCompanyGroupId,
        selectedBusinessId,
    ])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        if (showTable) {
            getTableData()
        }
    }, [getTableData])

    const dataSource = monthwiseList?.map((item) => ({
        ...item,
        key: item.clientNumber,
    }))

    const columns: ColumnsType<monthwiseListDataTypeInterface> = [
        { title: 'Number', dataIndex: 'clientNumber', width: '88px' },
        {
            title: 'Client',

            render: (record) => (
                <div>
                    {record.clientName}
                    <br /> Period of {formatdate(
                        record.periodStartDate
                    )} to {formatdate(record.periodEndDate)} in {record.year}
                </div>
            ),
        },

        {
            title: 'Action Status',
            dataIndex: 'actionName',
            ellipsis: true,
            width: '180px',
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <span>{record.actionName}</span>
                    <span
                        style={{
                            backgroundColor: 'orange',
                            padding: '2px 4px',
                            marginTop: '4px',
                        }}
                    >
                        {record.currentStatus}
                    </span>
                    <div style={{ marginTop: '4px' }}>
                        <Tooltip title="Service Process History">
                            <UnorderedListOutlined
                                onClick={() => {
                                    onHistoryModelOpen(record.serviceProcessId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </div>
                </div>
            ),
        },

        {
            title: 'Communication',
            dataIndex: 'lastCommunicationStatus',
            ellipsis: true,
            render: (text, record) => (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <span>{record.lastCommunicationStatus}</span>
                        <div style={{ marginTop: '4px' }}>
                            <Tooltip title="Communication History">
                                <HistoryOutlined
                                    onClick={() => {
                                        openHistoryModel(
                                            record.serviceProcessId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </>
            ),
        },
    ]
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        monthwiseListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const onChangeTableParams: TableProps<monthwiseListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        }

    return (
        <>
            <Card title="Month wise Report" style={{ marginTop: '20px' }}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Month">
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChange}
                                            value={
                                                selectedDate.length > 0
                                                    ? dayjs(selectedDate)
                                                    : null
                                            }
                                            className={commonStyles.dateWidth}
                                            picker="month"
                                            format="MMMM-YYYY"
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Considered Date">
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Please select"
                                        onChange={handleSelectConsiderDate}
                                        value={selectedConsiderId}
                                        options={DateFlagDropdowlist}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="Status">
                                    <Select
                                        placeholder="Please select"
                                        allowClear
                                        showSearch
                                        onChange={handleSelectChangeStatus}
                                        value={selectedSatusId}
                                        options={StatustDropdowlist}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Company Group">
                                    <Select
                                        placeholder="Please select"
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={
                                            handleSelectChangeCompanyGroup
                                        }
                                        value={selectedCompanyGroupId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Services" name="serviceId">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeService}
                                        allowClear
                                        showSearch
                                        value={selectedServiceId}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...serviceDropDownList,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            {(selectedServiceId === 9 ||
                                selectedServiceId === 10 ||
                                selectedServiceId === 2) && (
                                <Col span={8}>
                                    <Form.Item
                                        label="Business Type"
                                        name="Business TypeId"
                                    >
                                        <Select
                                            placeholder="Please select"
                                            onChange={
                                                handleSelectChangeBusinesType
                                            }
                                            value={selectedBusinessId}
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={[
                                                {
                                                    value: 0,
                                                    label: 'All',
                                                },
                                                ...regiterClientDetails,
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>

                        <Row gutter={15} justify="end">
                            <Col>
                                <Tooltip title="Reset">
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            form.resetFields()
                                            setSelectedDate('')
                                            setSelectedStatusId(0)
                                            setSelectedServiceId(0)
                                            setSelectedCompanyGroupsId(0)
                                            setSelectedConsiderId(2)
                                            setSelectedBusinessId(0)
                                            setSearchstring('=NA=')
                                            setCurrentPage(1)
                                            setItemsPerPage(10)
                                            setShowTable(false)
                                        }}
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip title="Search">
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            getTableData()
                                            setShowTable(true)
                                        }}
                                    >
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {showTable &&
                    (dataSource.length > 0 ? (
                        <>
                            <Row>
                                <SearchInput
                                    placeHolder={'Search your keywords'}
                                    onSearch={onChangeFilter}
                                    onChange={onChangeText}
                                    width="478"
                                />
                            </Row>
                            <div style={{ width: '100%', marginTop: '10px' }}>
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination={pagination}
                                    onChange={onChangeTableParams}
                                    bordered
                                />
                            </div>
                        </>
                    ) : (
                        <h3>No Record Found</h3>
                    ))}
            </Card>
            <Modal
                footer={null}
                onCancel={changeCommunictionHistoryModalStatus}
                width={'50%'}
                title={'Communication History'}
                open={comHistorymodel}
            >
                <InvoiceHistory
                    seletedButton={selectedButtonId}
                    setButtonID={setSelectedButtonId}
                    serviceProcessId={serviceProcessId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeHistoryModalStatus}
                width={'60%'}
                title={'Service Process History'}
                open={Historymodel}
            >
                <ServiceProcessHistory serviceprocessId={serviceProcessId} />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): MonthwiseProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
        monthwiseList: state.reports.monthwiseList,
        monthwiseListCount: state.reports.monthwiseListCount,
    }
}

export default connect(mapStateToProps)(MonthwiseReport)
