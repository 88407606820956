import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Flex,
    Space,
    DatePicker,
    type DatePickerProps,
    Input,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import dayjs from 'dayjs'
import { type ShareHolderDetails } from '../../../Types/CompanyHouse/CompanyOverView'
import { useDispatch } from 'react-redux'
import {
    GetCompanyOverViewData,
    GetShareHolderTransfreDetails,
    UpdateCompanyShareholder,
} from '../../../Services/CompanyHouse/CompanyOverView'
import moment from 'moment'
interface terminatePSCData {
    onClosemodel: () => void
    businessId: number
    divisionId: number
    companyReferncId: number
    corporateId: number
    serviceProcessId: number
}
const defaultShareHolderDetails = {
    companyReferenceId: 0,
    divisionType: 0,
    companyName: '',
    companyNumber: '',
    shareHolderName: '',
    shareClass: '',
    sharesAvailable: '',
    noofShares: 0,
    transferDate: '', // Use a placeholder like '1/1/1900 12:00:00 AM' if that’s your default
}
const TransferShares = (props: terminatePSCData): React.ReactElement => {
    const {
        onClosemodel,
        divisionId,
        companyReferncId,
        businessId,
        corporateId,
        serviceProcessId,
    } = props
    const [form] = Form.useForm()
    const [ceasedTransferDate, setCeasedPSCDate] = useState<string>('')
    const dispatch = useDispatch()
    const [shareHolderData, setshareHolderData] = useState<ShareHolderDetails>(
        defaultShareHolderDetails
    )
    useEffect(() => {
        const fetchAuditData = async (): Promise<void> => {
            try {
                const data = await GetShareHolderTransfreDetails(
                    dispatch,
                    divisionId,
                    companyReferncId
                )
                setshareHolderData(data)
                form.setFieldsValue(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        void fetchAuditData()
    }, [companyReferncId])
    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string[] | string
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setCeasedPSCDate(isoDate)
        }
    }

    const handelDatasave = (): void => {
        void submitData()
    }
    const submitData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                companyReferenceId: shareHolderData.companyReferenceId,
                divisionType: shareHolderData.divisionType,
                companyName: shareHolderData.companyName,
                companyNumber: shareHolderData.companyNumber,
                shareHolderName: shareHolderData.shareHolderName,
                shareClass: shareHolderData.shareClass,
                sharesAvailable: shareHolderData.sharesAvailable,
                noofShares: Number(formData.noofShares),
                transferDate: moment(ceasedTransferDate).format('DD/MM/YYYY'),
            }
            void UpdateCompanyShareholder(dispatch, dataToSave)
            setTimeout(() => {
                GetCompanyOverViewData(
                    dispatch,
                    businessId,
                    corporateId,
                    serviceProcessId,
                    5
                )
                onClosemodel()
                form.resetFields()
                setCeasedPSCDate('')
            }, 1000)
        } catch (error) {}
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={8}>Company & Reg Number</Col>
                        <Col span={2}>:</Col>
                        <Col span={12}>
                            {shareHolderData.companyName} -{' '}
                            {shareHolderData.companyNumber}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>Shareholder Name</Col>
                        <Col span={2}>:</Col>
                        <Col span={12}>{shareHolderData.shareHolderName}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>Share Class</Col>
                        <Col span={2}>:</Col>
                        <Col span={12}>{shareHolderData.shareClass}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>Shares available for transfer</Col>
                        <Col span={2}>:</Col>
                        <Col span={12}>{shareHolderData.sharesAvailable}</Col>
                    </Row>

                    <br />
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="No of Shares" name="noofShares">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Date of Transfer"
                                name="transferDate"
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            ceasedTransferDate.length > 0
                                                ? dayjs(ceasedTransferDate)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={onClosemodel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Update'}
                                icon={<CheckOutlined />}
                                clickAction={handelDatasave}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default TransferShares
