import React from 'react'
import notification from '../Notification/notification.less'
import { Flex } from 'antd'
import { ReactComponent as Meeting } from '../../Assest/Svg/meeting.svg'
const DashboarMeetingPopup = (): React.ReactElement => {
    return (
        <>
            <div className={notification.contrinert}>
                <p className={notification.notificationCount}>Meetings</p>
                <p>Daily and Admin Meetings</p>
            </div>
            <hr />
            <div className={notification.contentDisplay}>
                <div style={{ marginTop: '30px' }}>
                    <Flex justify="space-between" gap={10}>
                        <div>
                            <a
                                href="https://us02web.zoom.us/j/85042852813?pwd=cXZiYW5sSkpTYzdYT1FoekVQMmU2UT09"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <Meeting width="30px" height="30px" />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            color: 'black',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        Development
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://us02web.zoom.us/j/82797441652?pwd=OFFBT1kxMlR0MFhCWldqakJocGtwQT09"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <Meeting width="30px" height="30px" />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            color: 'black',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        IT
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://us02web.zoom.us/j/81033704558?pwd=YU9abm90MVBCenFSUnBVVGJ4Zk9wdz09"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <Meeting width="30px" height="30px" />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            color: 'black',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        {' '}
                                        Digital Marketing
                                    </span>
                                </div>
                            </a>
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px', marginBottom: '20px' }}>
                    <Flex justify="space-evenly" gap={10}>
                        <div>
                            <a
                                href="https://us02web.zoom.us/j/83080321709?pwd=MWNpdGdiNGQ0V3BpOG9kYm9INDNLQT09"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <Meeting width="30px" height="30px" />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            color: 'black',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        Daily
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://us02web.zoom.us/j/84951481184?pwd=VWYzSm9vZnpXL0ZJTlRFMmk3d3lWdz09"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <div style={{ marginRight: '10px' }}>
                                    <Meeting width="30px" height="30px" />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            color: 'black',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        Admin
                                    </span>
                                </div>
                            </a>
                        </div>
                    </Flex>
                </div>
            </div>
        </>
    )
}

export default DashboarMeetingPopup
