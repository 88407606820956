import { Card, Flex, Modal, Table } from 'antd'
import React, { useState } from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
import {
    type CompanyDetailsprops,
    type CS_ShareHolder,
} from '../../../Types/CompanyHouse/CompanyOverView'
import { formatdate } from '../../../Utils/dateFormat'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { PlusOutlined, SwapOutlined, EditOutlined } from '@ant-design/icons'
import TransferShares from '../CompanyHouseRelatedThings/transferShares'
import AddShareHoldes from '../CompanyHouseRelatedThings/addnewShareholders'

const defaultShareholder = {
    companyReferenceId: 0,
    companyId: 0,
    corporateId: 0,
    divisionType: 0,
    memberType: 0,
    name: '',
    shareClass: '',
    noofShares: 0,
    transferDate: '', // If possible, use a valid date string or `new Date().toISOString()`
}
const Shareholders = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId, companyHouseOverViewList, editId, businessId } =
        props
    const dataSource = companyHouseOverViewList?.cS_ShareHolderList ?? []
    const [transferSharesModel, setTransferSharesModel] = useState(false)
    const [editData, setEditData] = useState<CS_ShareHolder>(defaultShareholder)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Add Shareholder')
    const [companyReferncId, setCompanyRefernceId] = useState(0)
    const [divisionTypeId, setDevisionTypeId] = useState(0)
    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (record: CS_ShareHolder) => {
                return (
                    <Flex gap={10}>
                        {editId === 1 && (
                            <CusttomBackgroundButton
                                color={'#175ca9'}
                                name={''}
                                icon={<EditOutlined />}
                                clickAction={() => {
                                    openEditModal(record.companyReferenceId)
                                }}
                            />
                        )}
                        <div> {record.name}</div>
                    </Flex>
                )
            },
        },
        {
            title: 'Share Class',
            dataIndex: 'shareClass',
            key: 'shareClass',
        },
        {
            title: 'No of Shares',
            dataIndex: 'noofShares',
            key: 'noofShares',
        },
        {
            title: 'Transfers',
            key: 'transferDate',
            render: (record: CS_ShareHolder) => {
                return (
                    <Flex gap={10}>
                        {editId === 1 && (
                            <CusttomBackgroundButton
                                color={'#175ca9'}
                                name={''}
                                icon={<SwapOutlined />}
                                clickAction={() => {
                                    onClickShare(
                                        record.companyReferenceId,
                                        record.divisionType
                                    )
                                }}
                            />
                        )}
                        {record.transferDate !== '1/1/1900 12:00:00 AM' ? (
                            <div> Transfered</div>
                        ) : (
                            <div> No transfers have been made</div>
                        )}
                    </Flex>
                )
            },
        },
        {
            title: 'Transfers Date',
            key: 'transferDate',
            render: (record: CS_ShareHolder) => {
                return (
                    <div>
                        {record.transferDate === '1/1/1900 12:00:00 AM'
                            ? '-'
                            : formatdate(record.transferDate)}
                    </div>
                )
            },
        },
    ]
    const onClickShare = (id: number, divisionType: number): void => {
        setCompanyRefernceId(id)
        setDevisionTypeId(divisionType)

        setTransferSharesModel(!transferSharesModel)
    }

    const changeModalStatus = (): void => {
        setTransferSharesModel(!transferSharesModel)
    }
    const openEditModal = (id: number): void => {
        setCompanyRefernceId(id)
        const data = dataSource.find((data) => {
            return data.companyReferenceId === id
        })

        let tittle = 'Add Shareholder'
        if (id !== 0) {
            tittle = 'Edit Shareholder'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? defaultShareholder)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    return (
        <>
            <div>
                <h3>Shareholders</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <div className={style.misstingInfo}>
                        If you want to do changes required in shareholder
                        details, should be filed using respectively the{' '}
                        <b>&quot;Add New Shareholders&quot;</b>,{' '}
                        <b>&quot;Edit Personal Details&quot;</b>,
                        <b>&quot;Transfer Shares&quot;</b> pages before you file
                        the confirmation statement.
                    </div>
                )}

                {editId === 1 && (
                    <>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#175ca9'}
                                name={'Add New Shareholders'}
                                icon={<PlusOutlined />}
                                clickAction={() => {
                                    openEditModal(0)
                                }}
                            />
                        </Flex>
                        <br />
                    </>
                )}
                {dataSource?.length !== 0 ? (
                    <Table dataSource={dataSource} columns={columns} />
                ) : (
                    <div>
                        {' '}
                        <h3>No Record Found</h3>
                    </div>
                )}
            </Card>
            <Modal
                footer={null}
                onCancel={changeModalStatus}
                width={'50%'}
                title={'Transfer Shares'}
                open={transferSharesModel}
            >
                <TransferShares
                    onClosemodel={changeModalStatus}
                    businessId={businessId ?? 0}
                    divisionId={divisionTypeId}
                    companyReferncId={companyReferncId}
                    corporateId={companyHouseOverViewList?.corporateId ?? 0}
                    serviceProcessId={0}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={editModalTittle}
                open={createEditModalStatus}
            >
                <AddShareHoldes
                    onClosemodel={changeCreateEditModalStatus}
                    editData={editData}
                    CorporateId={companyHouseOverViewList?.corporateId ?? 0}
                    serviceProcessId={0}
                    businessId={businessId ?? 0}
                />
            </Modal>
        </>
    )
}

export default Shareholders
