import axios, { type AxiosError, type AxiosResponse } from 'axios'
import { notification } from 'antd'
// import store from '../Store/store'
// import { setLoginState } from '../Reducer/AuthReducer'
import handleError from './AxiosResponceStatus'

type NotificationType = 'success' | 'error' | 'info' | 'warning'

const showNotification = (
    type: NotificationType,
    message: string,
    description: string
): void => {
    notification[type]({
        message,
        description,
    })
}
const checkLocalNetwork = (): boolean => {
    return navigator.onLine
}

// Function to check server health status
// const checkServerHealth = async (): Promise<boolean> => {
//     const baseURL = process.env.REACT_APP_BASE_URL
//     try {
//         const healthResponse = await axios.get(`${baseURL}/health`)

//         if (healthResponse.status === 200) {
//             const { status } = healthResponse.data
//             return status === 'Healthy'
//         } else {
//             return false
//         }
//     } catch (error) {
//         return false
//     }
// }
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // timeout: 7000,
})

// const isServerActive = true
instance.interceptors.request.use(
    async (config) => {
        // 1. Check network connection first
        if (!checkLocalNetwork()) {
            // // If the device is offline, show an error and prevent the request
            // showNotification(
            //     'error',
            //     'No Internet Connection',
            //     'You have lost your internet connection. Please reconnect and try again.'
            // )
        } else {
            // 2. If the device is online, check the server health
            // isServerActive = await checkServerHealth()

            // if (!isServerActive) {
            //     // If the server is down, show an error and prevent the request
            //     showNotification(
            //         'error',
            //         'Server Error',
            //         'The server is currently down. Please try again later.'
            //     )
            //     localStorage.removeItem('jwtToken')
            //     store.dispatch(setLoginState({ status: false }))
            //     location.reload()
            //     return await Promise.reject(new Error())
            // } else {
            // 3. If both checks pass, check for a valid token
            const token = localStorage.getItem('jwtToken')
            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`
                // if (await validateToken(token)) {
                //     // Add the Authorization header if token is valid

                // } else {
                //     // If the token is invalid, show an error and prevent the request
                //     showNotification('error', '', 'Please log in again.')
                //     localStorage.removeItem('jwtToken')
                //     store.dispatch(setLoginState({ status: false }))
                // }

                // 4. If everything is fine, proceed with the API call
            }
        }
        return config
    },
    async (error) => {
        showNotification('error', 'Request Error', 'Error sending request.')
        return await Promise.reject(error)
    }
)

instance.interceptors.response.use(
    async (response: AxiosResponse) => {
        return response
    },
    async (error: AxiosError) => {
        // 1. Handle client-side network issues
        if (!checkLocalNetwork()) {
            // When the client has lost connection during the request
            showNotification(
                'error',
                '',
                'You have lost your internet connection. Please reconnect and try again.'
            )
        }
        // 2. Handle server response errors (like 404, 500)
        else if (
            error.response?.status !== undefined &&
            error.response?.status !== null
        ) {
            showNotification('error', '', handleError(error.response.status))
            console.log(error.response?.status, '000')
        }
        // 3. Handle request timeout errors
        else if (error.code === 'ECONNABORTED') {
            showNotification(
                'error',
                '',
                'The request took too long to process. Please try again later.'
            )
        }
        // 4. Handle general network errors
        else if (error.message.includes('Network Error')) {
            showNotification(
                'error',
                '',
                'There was a network issue during the request. Please check your connection and try again.'
            )
        }
        // 5. Handle all other errors
        else {
            // showNotification(
            //     'error',
            //     '',
            //     'An unexpected error occurred. Please try again later.'
            // )
        }

        // Always reject the error so that the calling function knows the request failed
        return await Promise.reject(error)
    }
)
export default instance

// const validateToken = async (token: string | null): Promise<boolean> => {
//     if (token !== null) {
//         const baseURL = process.env.REACT_APP_BASE_URL
//         try {
//             const healthResponse = await axios.get(
//                 `${baseURL}/api/Authenticate/GetTokenStatus`,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`, // Pass the token in the header
//                     },
//                 }
//             )

//             if (healthResponse.status === 200) {
//                 return true
//             } else {
//                 return false
//             }
//         } catch (error) {
//             return false
//         }
//     }
//     return false
// }
