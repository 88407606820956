import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import type { TableListInterface } from '../Types/CommonType'
import { type LeaveRequest } from '../Types/ToApproveViewAll/LeaveApproval'
import {
    leaveApproveList,
    permissionApproveList,
    timeSheetApproveList,
} from '../Reducer/DashboardReducer'
import { type TimeSheetData } from '../Types/ToApproveViewAll/Timesheet'

export const GetLeaveApproveList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Employee/GetLeaveApprovalList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: LeaveRequest) => {
                        return {
                            ...value,
                            key: value.referenceId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(leaveApproveList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPermissionApproveList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Employee/GetPermissionApprovalList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: LeaveRequest) => {
                        return {
                            ...value,
                            key: value.referenceId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(permissionApproveList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetTimeSheetApproveList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/Timesheet/GetTimesheetList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: TimeSheetData) => {
                        return {
                            ...value,
                            key: value.approvalRequestId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(timeSheetApproveList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
