import { useLocation } from 'react-router-dom'
import { getClientCompanyDetails } from '../../../../Services/Parnership'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SupplierInforForm from './Buttons/SupplerInfo/SupplierInforForm'
import { partnershipTaps } from '../../../../Reducer/Client'
import ContactViewPage from './Buttons/ContactInfo/ContactViewPage'
import Compliance from './Buttons/Compliance/Compliance'
import PartnershipConfiguration from './Buttons/Configuration/partnershipConfiguration'
import PartnershipService from './Buttons/Partneship services/PartnershipServiceMain'
import Documentmain from './Buttons/Documents/documentMain'
import PartnerMainPage from './Buttons/Partners/partnerMainPage'
import HomeList from './Buttons/Home/HomeList'
const PartnershipTabs = (): React.ReactElement => {
    const location = useLocation()
    const { partnershipData, onbordingId } = location.state
    const PartnershipData = partnershipData
    const dispatch = useDispatch()
    const businessTypeId = 4
    const partnerTapId = localStorage.getItem('partnerTapId')
    const [activeTab, setActiveTab] = useState(partnerTapId ?? '1')
    const partnershipId = PartnershipData?.partnershipId
    const [companyservicedetails, setCompanyServiceDetails] = useState<any>({})
    const onChange = (key: string): void => {
        localStorage.setItem('partnerTapId', key)
        setActiveTab(key)
    }

    console.log('onbordingId', onbordingId)
    useEffect(() => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            partnershipId,
            parseInt(activeTab, 10)
        )
    }, [activeTab, businessTypeId, dispatch])

    const fetchServiceData = async (): Promise<void> => {
        try {
            const data = await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                partnershipId,
                4
            )
            setCompanyServiceDetails(data)
        } catch (error) {}
    }

    useEffect(() => {
        if (activeTab === '3') {
            void fetchServiceData()
        }
    }, [activeTab])
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Home',
            children: <HomeList partnershipId={partnershipId} />,
        },
        {
            key: '8',
            label: 'Partner',
            children: <PartnerMainPage partnershipId={partnershipId} />,
        },
        {
            key: '4',
            label: 'Services',
            children: <PartnershipService partnershipId={partnershipId} />,
        },
        {
            key: '7',
            label: 'Supplier Info',
            children: (
                <SupplierInforForm
                    partnershipTaps={partnershipTaps}
                    partnershipId={partnershipId}
                />
            ),
        },
        {
            key: '5',
            label: 'Contact Info',
            children: <ContactViewPage partnershipId={partnershipId} />,
        },
        {
            key: '3',
            label: 'Documents',
            children: (
                <Documentmain
                    partnershipId={partnershipId}
                    companyservicedetails={companyservicedetails}
                />
            ),
        },
        {
            key: '6',
            label: 'Compliance',
            children: (
                <Compliance
                    partnershipId={partnershipId}
                    // contactInfo={contactInfo}
                    onbordingId={onbordingId}
                />
            ),
        },
        {
            key: '10',
            label: 'Configuration',
            children: (
                <PartnershipConfiguration
                    registeredClientPartnershipConfiguration={''}
                    partnershipId={partnershipId}
                />
            ),
        },
    ]

    return (
        <div className={commonStyles.width100}>
            <Tabs activeKey={activeTab} onChange={onChange} items={items} />
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(PartnershipTabs)
