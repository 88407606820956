import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Button, Table, type TableProps } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { PhoneOutlined, MessageOutlined, MailOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import SearchInput from '../../../../Components/SearchInput'
import { type TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import calculatePagination from '../../../../Utils/Pagination'
import { GetProspectClientHistoryDetailsList } from '../../../../Services/ProspectClient'
import sendemail from './send_email.less'
import { type ColumnsType } from 'antd/es/table'
import {
    type ProspectHistoryProps,
    type ProspectClientDetailsListCommunicationHistory,
    type ProspectHistorystateProps,
} from '../../../../Types/Client/ProspectClients/prospectClient'

const ProspectHistory = (props: ProspectHistoryProps): React.ReactElement => {
    const {
        prospectClientId,
        prospectClientHistory,
        prospectClientHistoryCount,
        setButtonID,
        seletedButton,
    } = props

    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)

    const handleButtonClick = (buttonId: number): void => {
        setButtonID(buttonId === seletedButton ? null : buttonId)
    }
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        if (seletedButton !== null) {
            const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
                setCommonTableParamsData(tableParams)

            GetProspectClientHistoryDetailsList(
                dispatch,
                seletedButton ?? 0,
                prospectClientId,
                seletedButton ?? 0,
                {
                    pageNo,
                    pageSize,
                    searchCriteriaList,
                    sortOrderList,
                }
            )
        }
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        seletedButton,
        prospectClientId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [seletedButton, getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: prospectClientHistoryCount,
            },
        })
    }, [prospectClientHistoryCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        prospectClientHistoryCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<ProspectClientDetailsListCommunicationHistory> =
        [
            {
                title: 'Description',
                dataIndex: 'description',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Date',
                dataIndex: 'historyDate',
            },
        ]

    const onChangeTableParams: TableProps<ProspectClientDetailsListCommunicationHistory>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'description',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Row gutter={25} justify="start">
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={sendemail.historyButton}
                        onClick={() => {
                            handleButtonClick(1)
                        }}
                    >
                        <span className={sendemail.buttontext}>Email</span>
                        <span>
                            <MailOutlined />
                        </span>
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={sendemail.historyButton}
                        onClick={() => {
                            handleButtonClick(2)
                        }}
                    >
                        <span className={sendemail.buttontext}>SMS</span>
                        <span>
                            <MessageOutlined />
                        </span>
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={sendemail.historyButton}
                        onClick={() => {
                            handleButtonClick(3)
                        }}
                    >
                        <span className={sendemail.buttontext}>Phone </span>
                        <span>
                            <PhoneOutlined />
                        </span>
                    </Button>
                </Col>
            </Row>

            {seletedButton != null && prospectClientHistory != null && (
                <>
                    <Row>
                        <SearchInput
                            placeHolder={'Search your keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Row>
                    {prospectClientHistory != null ? (
                        <div className={commonStyles.table}>
                            <Table
                                columns={columns}
                                dataSource={prospectClientHistory}
                                onChange={onChangeTableParams}
                                pagination={pagination}
                            />
                        </div>
                    ) : (
                        <div>No data found</div>
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state: any): ProspectHistorystateProps => {
    return {
        prospectClientHistory: state.client.prospectClientHistory ?? [],
        prospectClientHistoryCount: state.client.prospectClientHistoryCount,
    }
}

export default connect(mapStateToProps)(ProspectHistory)
