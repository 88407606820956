import React from 'react'
import { Provider } from 'react-redux'
import store from './Store/store'
import { BrowserRouter } from 'react-router-dom'
import MainLayout from './Layouts/mainLayout'
import { ConfigProvider, theme } from 'antd'
import ErrorBoundary from './Config/ErrorResponce'

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    algorithm: theme.defaultAlgorithm,
                    token: {
                        colorPrimary: '#306BFF',
                        colorBgLayout: '#2FB153',
                    },
                }}
            >
                <ErrorBoundary>
                    <BrowserRouter>
                        <MainLayout />
                    </BrowserRouter>
                </ErrorBoundary>
            </ConfigProvider>
        </Provider>
    )
}
export default App
