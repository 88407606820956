// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.miscol_RS9lF {
  margin-top: 13px;
}
.point__l0SG {
  cursor: pointer;
}
.divmiss_O98MO {
  padding: 20px;
  margin-top: 60px;
}
.divmissed_Q7Ro3 {
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.databasse_B7Mce {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Less/missininfo.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACA;EACI,eAAA;AACJ;AACA;EACI,aAAA;EACA,gBAAA;AACJ;AACA;EACI,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACA;EACI,iBAAA;AACJ","sourcesContent":[".miscol {\n    margin-top: 13px;\n}\n.point {\n    cursor: pointer;\n}\n.divmiss {\n    padding: 20px;\n    margin-top: 60px;\n}\n.divmissed {\n    margin-bottom: 20px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n}\n.databasse {\n    margin-right: 8px;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miscol": `miscol_RS9lF`,
	"point": `point__l0SG`,
	"divmiss": `divmiss_O98MO`,
	"divmissed": `divmissed_Q7Ro3`,
	"databasse": `databasse_B7Mce`
};
export default ___CSS_LOADER_EXPORT___;
