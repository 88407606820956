import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Select,
    Tooltip,
    type SelectProps,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { ToTopOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import { ErrorMessage } from '../../../Utils/Notification'
import axios from '../../../Config/AxiosConfig'
import styles from '../../../Layouts/Less/style.less'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { GetCompanyOverViewData } from '../../../Services/CompanyHouse/CompanyOverView'
import { type CompanyHouseOverviewSteteIntrface } from '../../../Types/CompanyHouse/CompanyOverView'
const ConformationStatementFilling = (
    props: CompanyHouseOverviewSteteIntrface
): React.ReactElement => {
    const { companyHouseOverViewList } = props
    const [form] = Form.useForm()
    const [searchValue, setSearchValue] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<SelectProps['options']>([])
    const navigate = useNavigate()
    const [ClientId, setClientId] = useState('')
    const dispatch = useDispatch()
    const splitValues = ClientId?.split('-')
    const businessId = splitValues[0]
    const referenceId = splitValues[1]
    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/9/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={styles.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage('Record Not Found')
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const onChangeSearch = (value: string): void => {
        if (value !== undefined) {
            setSearchValue('')
            setClientId(value)
        }
    }

    const HandelButtonCLick = async (): Promise<void> => {
        GetCompanyOverViewData(
            dispatch,
            Number(businessId),
            Number(referenceId),
            0,
            1
        )
        try {
            await form.validateFields()
            if (ClientId.length > 0 && ClientId !== null) {
                const pageIdentyFyId = 1

                if (companyHouseOverViewList?.isFileWithChanges !== 1) {
                    navigate(`/confirmation-Statement/summary`, {
                        state: { pageIdentyFyId, ClientId },
                    })
                } else {
                    navigate(`/confirmation-Statement/details`, {
                        state: { ClientId },
                    })
                }
            }
        } catch (error) {}
    }
    const OnClickNamevalidate = (): void => {
        void HandelButtonCLick()
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <>
            <div>
                <h2> Confirmation Statement Filing</h2>
            </div>

            <Card title=" Confirmation Statement Filing">
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Please select the client to the filing"
                                    name="clientNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select client',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        loading={isLoading}
                                        showSearch
                                        placeholder="To filter the clients by number /name"
                                        onChange={(value) => {
                                            onChangeSearch(value)
                                        }}
                                        onSearch={(value) => {
                                            setSearchValue(value)
                                        }}
                                        filterOption={false}
                                        allowClear
                                        onClear={() => {
                                            setData([])
                                        }}
                                        optionLabelProp="label"
                                        options={data}
                                        optionRender={(option) => (
                                            <>{option.data.desc}</>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col offset={19} span={2}>
                                <Tooltip title="Confirmation Statement Filing">
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            OnClickNamevalidate()
                                        }}
                                    >
                                        <ToTopOutlined
                                            style={{
                                                transform: 'rotate(90deg)',
                                            }}
                                        />{' '}
                                        Confirmation Statement Filing
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): CompanyHouseOverviewSteteIntrface => {
    return {
        companyHouseOverViewList: state.companyHouse.companyHouseOverViewList,
    }
}

export default connect(mapStateToProps)(ConformationStatementFilling)
