import { type Dispatch } from '@reduxjs/toolkit'
import { setLoadingStatus } from '../../Reducer/Common'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { companyHouseOverViewList } from '../../Reducer/CompanyHouseReducer'
import {
    type CsSailAddress,
    type CompanyAddress,
    type OfficerDetails,
    type PSCDetails,
    type CompanyCorporateDetails,
    type CS_PSC,
    type csSubmitData,
    type CompanyOverview,
    type ShareCapital,
    type CS_ShareCapitalDetail,
    type setCompanyhouse,
    type CompanyShareholder,
    type ShareHolderDetails,
    type SetCompanyDetailsData,
    type CompanyPersonDetailsSaveData,
} from '../../Types/CompanyHouse/CompanyOverView'
export const GetCompanyOverViewData = (
    dispatch: Dispatch,
    businessId: number,
    referenceId: number,
    serviceProcesId: number,
    tabIndex: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `api/ConfirmationStatement/GetCompanyDetail/${businessId}/${referenceId}/${serviceProcesId}/${tabIndex}`
        ) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                dispatch(companyHouseOverViewList(response.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCompanyResolutionData = async (
    dispatch: Dispatch,
    corporateId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/ConfirmationStatement/GetCompanyName/${corporateId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetRegitserAddress = async (
    dispatch: Dispatch,
    corporateId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/ConfirmationStatement/GetBasicDetail/${corporateId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SetCompanyDetailAddress = async (
    dispatch: Dispatch,
    data: CompanyAddress
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetCompanyDetail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetCompanySailAddress = async (
    dispatch: Dispatch,
    data: CsSailAddress
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetSailAddress`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetTerminateOffocerDetails = async (
    dispatch: Dispatch,
    refernceId: number,
    memberRoleId: number,
    memberTypeId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ConfirmationStatement/GetOfficerResignationDetail/${memberTypeId}/${memberRoleId}/${refernceId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SetTeminateOfficer = async (
    dispatch: Dispatch,
    data: OfficerDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetOfficerResignation`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetTerminatePSCOffocerDetails = async (
    dispatch: Dispatch,
    pscID: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ConfirmationStatement/GetPSCTerminationDetail/${pscID}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SetTeminatePSCOfficer = async (
    dispatch: Dispatch,
    data: PSCDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetPSCTermination`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetCompanyName = async (
    dispatch: Dispatch,
    data: SetCompanyDetailsData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetCompanyName`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetOfficerCorporate = async (
    dispatch: Dispatch,
    data: CompanyCorporateDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetOfficerCorporate`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetOfficerPerson = async (
    dispatch: Dispatch,
    data: CompanyPersonDetailsSaveData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetOfficerPerson`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getMemberDetails = async (
    dispatch: Dispatch,
    divisionId: number,
    referenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ConfirmationStatement/GetCSOfficerDetail/${divisionId}/${referenceId}/`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to sent client portal invitation')
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SetPSCsOfficer = async (
    dispatch: Dispatch,
    data: CS_PSC
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetPSC`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetShareHolderDetails = async (
    dispatch: Dispatch,
    divisonId: number,
    companyRefernceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ConfirmationStatement/GetShareHolderDetail/${divisonId}/${companyRefernceId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetShareHolderCorporateDetails = async (
    dispatch: Dispatch,
    divisonId: number,
    companyRefernceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ConfirmationStatement/GetCSOfficerDetail/${divisonId}/${companyRefernceId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const RevetAll = async (
    dispatch: Dispatch,
    corporateId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetRevertAll/${corporateId}`)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetCSSummary = async (
    dispatch: Dispatch,
    data: csSubmitData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetCSSummary`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetCompanySicCode = async (
    dispatch: Dispatch,
    data: CompanyOverview
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetSICCode`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetShareCapital = async (
    dispatch: Dispatch,
    data: ShareCapital
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetShareCapital`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetShareCapitalDetals = async (
    dispatch: Dispatch,
    data: CS_ShareCapitalDetail
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetShareCapitalDetail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeleteShareDetails = (dispatch: Dispatch, id: number): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/32/${id}`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SyncCompanyHouseCH = async (
    dispatch: Dispatch,
    data: setCompanyhouse
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Corporate/SyncCompanyHouse`, data)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setLoadingStatus({ status: false }))
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetCompanyShareholder = async (
    dispatch: Dispatch,
    data: CompanyShareholder
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/SetShareHolder`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const UpdateCompanyShareholder = async (
    dispatch: Dispatch,
    data: ShareHolderDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ConfirmationStatement/UpdateSharesTransfer`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetShareHolderTransfreDetails = async (
    dispatch: Dispatch,
    divisonId: number,
    companyRefernceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ConfirmationStatement/GetSharesTransferDetail/${divisonId}/${companyRefernceId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetAddressData = async (
    dispatch: Dispatch,
    businessId: number,
    referenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/GetCompanyRegAddressInformation/${businessId}/${referenceId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
