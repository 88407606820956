import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Flex, Input, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { connect, useDispatch } from 'react-redux'
import {
    GetCompanyOverViewData,
    SetShareCapital,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import { GetCurrencyList } from '../../../Services/Common'
interface AddnewCurrencyState {
    currencyList: ListFrontEndInterface[]
}
interface terminatePSCData extends AddnewCurrencyState {
    onClosemodel: () => void
    companyName: string
    companyNumber: string
    companyId: number
    corporateId: number
    serviceProcessId: number
    businessId: number
}

const AddNewCurrencyList = (props: terminatePSCData): React.ReactElement => {
    const {
        onClosemodel,
        companyName,
        companyNumber,
        businessId,
        corporateId,
        serviceProcessId,
        companyId,
        currencyList,
    } = props
    const [form] = Form.useForm()
    const [currencyId, setCurrencyType] = useState(1)
    const dispatch = useDispatch()
    useEffect(() => {
        GetCurrencyList(dispatch)
    }, [])
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const currencyTypeChnage = (value: number): void => {
        setCurrencyType(value)
    }
    const handelDatasave = (): void => {
        void submitData()
    }
    const submitData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                shareCapitalId: 0,
                companyId,
                corporateId,
                currencyId,
                shareClass: formData.shareClass,
                noofShares: Number(formData.numberofShares),
                aggregateNominalValue: Number(formData.aggregateValue),
                particulars: formData.Particulars,
            }
            void SetShareCapital(dispatch, dataToSave)
            setTimeout(() => {
                GetCompanyOverViewData(
                    dispatch,
                    businessId,
                    corporateId,
                    serviceProcessId,
                    4
                )
                onClosemodel()
            }, 1000)
        } catch (error) {}
    }
    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={10}>Company & Reg Number</Col>
                        <Col span={2}>:</Col>
                        <Col span={12}>
                            {companyName} - {companyNumber}
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Currency" name="currencyType">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Please select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    }
                                    onChange={(value) => {
                                        currencyTypeChnage(value)
                                    }}
                                    options={currencyList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Share Class" name="shareClass">
                                <Select
                                    allowClear
                                    placeholder="Please select ShareClass"
                                    onChange={() => {}}
                                    defaultValue={0}
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' && // Ensure option.children is a string
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    showSearch
                                >
                                    <Select.Option value={0}>
                                        PLEASE SELECT
                                    </Select.Option>
                                    <Select.Option value="Preference">
                                        Preference
                                    </Select.Option>
                                    <Select.Option value="Ordinary">
                                        Ordinary
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Number of Shares "
                                name="numberofShares"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Number of Shares ',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Aggregate Nominal Value "
                                name="aggregateValue"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Aggregate Nominal Value ',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Prescribed Particulars "
                                name="Particulars"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Particulars',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>

                    <br />

                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={onClosemodel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Add Share Class'}
                                icon={<CheckOutlined />}
                                clickAction={handelDatasave}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): AddnewCurrencyState => {
    return {
        currencyList: state.common.currencyList,
    }
}

export default connect(mapStateToProps)(AddNewCurrencyList)
