import React, { useEffect, useRef, useState } from 'react'
import { Calendar } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import { type CalendarMode } from 'antd/es/calendar/generateCalendar'
import {
    GetPersonalCalender,
    GetTeamCalender,
} from '../../../Services/HR/LeavePermission'
import { connect, useDispatch } from 'react-redux'
import {
    type CalendarComponentStateType,
    type CalendarComponentPropsType,
    type CalendarEventType,
} from '../../../Types/Hr/LeavePermission'

const CalendarComponent = (
    props: CalendarComponentPropsType
): React.ReactElement => {
    const { TeamCalenderList, PersonalCalenderList, setActive } = props
    const dispatch = useDispatch()
    const [currentMode, setCurrentMode] = useState<CalendarMode>('month')
    const hasFetchedOnce = useRef(false)

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetTeamCalender(dispatch)
        GetPersonalCalender(dispatch)
    }, [dispatch])

    const onPanelChange = (
        value: Dayjs | undefined,
        mode: CalendarMode
    ): void => {
        setCurrentMode(mode)
    }

    const dateCellRender = (value: Dayjs): React.ReactNode => {
        const dateString = value.format('YYYY-MM-DD')
        const events = TeamCalenderList.filter(
            (event: CalendarEventType) =>
                dateString >= event.start && dateString <= event.end
        )

        if (events.length > 0) {
            return (
                <div>
                    {events?.map((event: CalendarEventType, index: number) => (
                        <div key={index}>
                            <p>{event.title}</p>
                            <p>{event.className}</p>
                        </div>
                    ))}
                </div>
            )
        }
        return null
    }
    const dateCellRenderPersonal = (value: Dayjs): React.ReactNode => {
        const selectedDate = value.startOf('day').valueOf()
        const endDate = value.endOf('day').valueOf()
        const dayName = value.locale('en').format('ddd') // Ensure locale is set to 'en'
        const personalCalendarData = PersonalCalenderList

        const events = personalCalendarData.filter(
            (event: CalendarEventType) => {
                const eventStartDate = dayjs(event.start, 'MMM DDD YYYY HH:mmA')
                    .startOf('day')
                    .valueOf()
                const eventEndDate = dayjs(event.end, 'MMM DDD YYYY HH:mmA')
                    .endOf('day')
                    .valueOf()

                if (eventStartDate === eventEndDate) {
                    return false
                }

                return eventStartDate <= endDate && eventEndDate >= selectedDate
            }
        )

        if (events.length > 0) {
            return (
                <div>
                    {events?.map((event: CalendarEventType, index: number) => (
                        <div key={index}>
                            <p>{dayName}</p>{' '}
                            {/* Now showing 'Mon', 'Tue', etc. */}
                            <p>
                                {dayjs(
                                    event.start,
                                    'MM DDD YYYY HH:mmA'
                                ).format('MM DDD YYYY')}
                            </p>
                            <p>{event.title}</p>
                        </div>
                    ))}
                </div>
            )
        }
        return null
    }

    return (
        <div>
            {setActive && (
                <Calendar
                    onSelect={(value) => {
                        onPanelChange(dayjs(value), currentMode)
                    }}
                    onPanelChange={onPanelChange}
                    mode={currentMode}
                    dateCellRender={dateCellRender}
                />
            )}
            {!setActive && (
                <Calendar
                    onSelect={(value) => {
                        onPanelChange(dayjs(value), currentMode)
                    }}
                    onPanelChange={onPanelChange}
                    mode={currentMode}
                    dateCellRender={dateCellRenderPersonal}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state: any): CalendarComponentStateType => {
    return {
        TeamCalenderList: state.hr.TeamCalenderList,
        PersonalCalenderList: state.hr.PersonalCalenderList,
    }
}

export default connect(mapStateToProps)(CalendarComponent)
