import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Flex,
    Select,
    Space,
    type TableProps,
    Tooltip,
    Table,
    Modal,
    Drawer,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import {
    type ClientServiceProcessTableIntrafce,
    type ClientDashBoardState,
    type serviceProcessPageProps,
} from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import { connect, useDispatch } from 'react-redux'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import { type ColumnsType } from 'antd/es/table'
import calculatePagination from '../../../Utils/Pagination'
import { GetCilentServiseProcessList } from '../../../Services/GlobelSearch'
import {
    UnorderedListOutlined,
    CommentOutlined,
    HistoryOutlined,
} from '@ant-design/icons'
import style from './taps.less'
import InvoiceHistory from '../../../Components/history'
import { type DataCHAT } from '../../../Types/Client/ProspectClients/prospectClient'
import { GetChat } from '../../../Services/Common'
import StaffCommunication from '../../../Components/StaffChat'
import ServiceProcessHistory from '../../ThingToDoViewAll/ProcessModel/serviceProcessHistoryModel'
const ServiceButton = (props: serviceProcessPageProps): React.ReactElement => {
    const {
        serviceDropDownList,
        CDashboradServiceProcessList,
        CDashboradServiceProcessListCount,
        businessTypeId,
        referenceId,
    } = props
    const dispatch = useDispatch()
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [processId, setProcessId] = useState(0)
    const [Historymodel, setHistorymodel] = useState(false)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const [serviceProcessId, setServiProcessId] = useState(0)
    const [open, setOpen] = useState(false)
    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [ProcessHistorymodel, setProcessHistorymodel] = useState(false)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [resetData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        void GetChat(
            dispatch,
            1,
            serviceProcessId,
            0,
            (chatdatass: DataCHAT) => {
                setChatData(chatdatass)
            }
        )
    }, [serviceProcessId])

    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const data = [
        {
            value: 0,
            label: 'In Progress',
        },
        {
            value: 1,
            label: 'OverDue',
        },
        {
            value: 2,
            label: 'Complete',
        },
    ]

    const handleSelectChange1 = (value: number): void => {
        setSelectedServiceId(value)
    }

    const getTableData = useCallback(() => {
        GetCilentServiseProcessList(
            dispatch,
            businessTypeId,
            referenceId,
            selectedServiceId,
            processId,
            '=NA=',
            currentPage,
            itemsPerPage
        )
    }, [
        businessTypeId,
        referenceId,
        selectedServiceId,
        processId,
        currentPage,
        itemsPerPage,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        CDashboradServiceProcessListCount as number,
        itemsPerPage,
        handlePageSizeChange
    )

    const formatdate = (date: string): string => {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()
        return `${month}/${day}/${year}`
    }

    const columns: ColumnsType<ClientServiceProcessTableIntrafce> = [
        {
            title: 'Services',
            dataIndex: 'serviceName',
        },
        {
            title: 'Period',
            key: 'Period',
            render: (_, record) => {
                return (
                    <>
                        <div>
                            Period of {record.periodStartDate} to{' '}
                            {record.periodEndDate}
                            <br />
                            {processId === 0 ? (
                                <span style={{ color: 'green' }}>
                                    {record.dueDays} days more remaining to
                                    submit the file.
                                </span>
                            ) : (
                                <span style={{ color: 'red' }}>
                                    {record.dueDays} days passed from due date
                                    to submit the file
                                </span>
                            )}
                        </div>
                    </>
                )
            },
        },
        {
            title: 'Assign Info',
            key: 'assignTo',
            render: (_, record) => {
                return (
                    <div>
                        Assign by : {record.assignBy} <br /> Assign to :{' '}
                        {record.assignTo} <br /> Due Date :{' '}
                        {formatdate(record.dueDate)}
                    </div>
                )
            },
        },
        {
            title: 'Action Status',
            key: 'action',
            render: (_, record) => {
                return (
                    <>
                        <div>{record.actionName}</div>
                        <div className={style.currentstatus}>
                            {record.currentStatus}
                        </div>
                        <Space size="middle">
                            <Tooltip title="Service Process History">
                                <UnorderedListOutlined
                                    onClick={() => {
                                        openProcessHistoryModel(
                                            record.serviceProcessId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>

                            <Tooltip title="Staff Communication">
                                <CommentOutlined
                                    onClick={() => {
                                        showDrawer(record.serviceProcessId)
                                        setResetData('true')
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        </Space>
                    </>
                )
            },
        },
        {
            title: 'Client Com log',
            key: 'action',
            render: (_, record: ClientServiceProcessTableIntrafce) => {
                return (
                    <>
                        <div>{record.lastCommunicationStatus}</div>
                        <Space size="middle">
                            <Tooltip title="Communication History">
                                <HistoryOutlined
                                    onClick={() => {
                                        openHistoryModel(
                                            record.serviceProcessId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        </Space>
                    </>
                )
            },
        },
    ]

    const openHistoryModel = (id: number): void => {
        setServiProcessId(id)
        setHistorymodel(!Historymodel)
    }

    const changeHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setHistorymodel(!Historymodel)
    }

    const openProcessHistoryModel = (id: number): void => {
        setServiProcessId(id)
        setProcessHistorymodel(!ProcessHistorymodel)
    }

    const ProcesschangeHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setProcessHistorymodel(!ProcessHistorymodel)
    }

    const onChangeTableParams: TableProps<ClientServiceProcessTableIntrafce>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        }

    const mappedData = CDashboradServiceProcessList?.map((item) => ({
        ...item,
        key: item.serviceProcessId,
    }))

    const handelProcessIdChange = (value: number): void => {
        setProcessId(value)
    }
    const showDrawer = (id: number): void => {
        setServiProcessId(id)
        setOpen(true)
    }

    const onClose = (): void => {
        setOpen(false)
    }
    return (
        <>
            <div
                style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    width: '100%',
                }}
            >
                <Flex justify="flex-end" gap={10}>
                    <Select
                        className={commonStyles.topdropdown2}
                        placeholder="Please select"
                        options={data}
                        onChange={handelProcessIdChange}
                        value={processId}
                    />
                    <Select
                        className={commonStyles.topdropdown}
                        placeholder="Please select"
                        onChange={handleSelectChange1}
                        value={selectedServiceId}
                        options={[
                            { value: 0, label: 'All' },
                            ...serviceDropDownList,
                        ]}
                    />
                </Flex>

                {(CDashboradServiceProcessListCount as number) > 0 ? (
                    <div className={commonStyles.table}>
                        <div className={style.table}>
                            <Table
                                columns={columns}
                                dataSource={mappedData}
                                onChange={onChangeTableParams}
                                pagination={pagination}
                            />
                        </div>
                    </div>
                ) : (
                    <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        No Record Found
                    </div>
                )}
            </div>
            <Modal
                footer={null}
                onCancel={changeHistoryModalStatus}
                width={'50%'}
                title={'Communication History'}
                open={Historymodel}
            >
                <InvoiceHistory
                    invoiceIds={serviceProcessId}
                    seletedButton={selectedButtonId}
                    setButtonID={setSelectedButtonId}
                />
            </Modal>
            <Drawer
                title={ChatDatas?.clientName}
                width={500}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={serviceProcessId}
                    messageTypeId={1}
                    businessId={businessTypeId}
                    resetdata={resetData}
                    setResetData={setResetData}
                />
            </Drawer>
            <Modal
                footer={null}
                onCancel={ProcesschangeHistoryModalStatus}
                width={'60%'}
                title={'Service Process History'}
                open={ProcessHistorymodel}
            >
                <ServiceProcessHistory serviceprocessId={serviceProcessId} />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): ClientDashBoardState => {
    return {
        serviceDropDownList: state.initial.serviceDropDownList,
        CDashboradServiceProcessList:
            state.golobelSearch.CDashboradServiceProcessList,
        CDashboradServiceProcessListCount:
            state.golobelSearch.CDashboradServiceProcessListCount,
    }
}

export default connect(mapStateToProps)(ServiceButton)
