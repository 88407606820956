import type { Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import {
    setAuthState,
    setLoginState,
    pageReloadAuth,
    pageLoginPinAuth,
} from '../Reducer/AuthReducer'
import type {
    ResetPinResponse,
    StoreUser,
    changePasswdDataInterface,
    changePinDataInterface,
    forgotPin,
    loginDataInterface,
    pinDataInterface,
} from '../Types/Auth'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { departmentId, flag } from '../Reducer/DashboardFlagReducer'
import { setLoadingStatus } from '../Reducer/Common'
import handleError from '../Config/AxiosResponceStatus'
import { type AxiosError } from 'axios'

export const LoginApi = (
    dispatch: Dispatch,
    data: loginDataInterface,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    setLoading(true)
    axios
        .post('/api/Authenticate/AuthenticateUser', data) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                if (response.data.identifierId === 200) {
                    dispatch(setLoginState({ status: true, ...data }))
                    localStorage.setItem(
                        'loginSessionCode',
                        response?.data?.loginSessionCode
                    )
                } else {
                    ErrorMessage('Invalid Username or Password')
                }
            }
            setLoading(false)
        })
        .catch((error: AxiosError) => {
            if (error.response !== undefined) {
                // ErrorMessage(handleError(error?.response?.status))
            }
            setLoading(false)
        })
}

export const ChangePinApi = (
    dispatch: Dispatch,
    data: changePinDataInterface,
    onSuccess: () => void
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .post('/api/Authenticate/SetUserResetPIN', data)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage('Pin Change Successfully')
                onSuccess()
            } else {
                ErrorMessage(handleError(response.status))
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch(() => {
            ErrorMessage('Invalid Username or Password')
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ChangePasswdApi = async (
    dispatch: Dispatch,
    data: changePasswdDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post('/api/Authenticate/SetUserResetPassword', data)
        .then((response) => {
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(handleError(response.status))
            }
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
        .catch(() => {
            ErrorMessage('Invalid Username or Password')
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const PinApi = (
    dispatch: Dispatch,
    data: pinDataInterface,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    setLoading(true)
    dispatch(setLoadingStatus({ status: true }))
    axios
        .post('/api/Authenticate/AuthenticateViaPIN', data) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.data.statusCode === 200) {
                const accessToken = response?.data?.data?.accessToken
                if (accessToken !== null) {
                    localStorage.setItem('jwtToken', accessToken)
                    const storeUser: StoreUser = {
                        userId: response?.data?.data?.userId,
                        userName: response?.data?.data?.userName,
                        firstName: response?.data?.data?.firstName,
                        middleName: response?.data?.data?.middleName,
                        surName: response?.data?.data?.surName,
                        imageUrl: response?.data?.data?.imageUrl,
                        emailAddress: response?.data?.data?.emailAddress,
                        contactNo: response?.data?.data?.contactNo,
                        accessToken: response?.data?.data?.accessToken,
                        userGroupId: response?.data?.data?.userGroupId,
                        clientCategoryId:
                            response?.data?.data?.clientCategoryId,
                        isParentCompany: response?.data?.data?.isParentCompany,
                        departmentId: response?.data?.data?.departmentId,
                        designationId: response?.data?.data?.designationId,
                        userRights: response?.data?.data?.userRights,
                        departments: response?.data?.data?.departments,
                        departmentReports:
                            response?.data?.data?.departmentReports,
                        departmentMenus: response?.data?.data?.departmentMenus,
                        clientDynCategoryId:
                            response?.data?.data?.clientDynCategoryId,
                    }
                    localStorage.setItem(
                        'userRights',
                        JSON.stringify(response?.data?.data?.userRights)
                    )
                    localStorage.setItem('user', JSON.stringify(storeUser))
                    localStorage.setItem(
                        'departmetId',
                        response?.data?.data?.clientDynCategoryId
                    )
                    localStorage.removeItem('loginSessionCode')

                    dispatch(flag(1))
                    location.reload()
                    dispatch(
                        departmentId(
                            Number(response?.data?.data?.clientDynCategoryId)
                        )
                    )
                } else {
                    ErrorMessage('Invalid PIN')
                }
            } else {
                ErrorMessage(response.data.error.message)
                dispatch(setAuthState({ status: false }))
            }
            setLoading(false)
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch(() => {
            ErrorMessage('Invalid PIN')
            setLoading(false)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ResetPin = (
    dispatch: Dispatch,
    data: forgotPin,
    onSuccess: (response: ResetPinResponse) => void
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .post('/api/Authenticate/ResetPIN', data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                const data = response.data
                onSuccess(data)
            } else {
                ErrorMessage(handleError(response.status))
            }
        })
        .catch(() => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage('Invalid Pin')
        })
}

export const ResetPassword = async (
    dispatch: Dispatch,
    data: forgotPin,
    onSuccess: (response: ResetPinResponse) => void
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post('/api/Authenticate/ResetPassword', data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage('Email Sent Successfully')
                const data = response.data
                onSuccess(data)
            } else {
                ErrorMessage(handleError(response.status))
            }
        })
        .catch(() => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage('Invalid Email')
        })
}

export const authenticateWithJwt = (dispatch: Dispatch): void => {
    // TODO need to check the jwt token avaibale from localstorage if so return to home page otherwise login
    dispatch(pageReloadAuth())
}

export const chetPinVidation = (dispatch: Dispatch): void => {
    dispatch(pageLoginPinAuth())
}

export const GetDepartmetRightsDetails = (
    dispatch: Dispatch,
    userId: number,
    depatmentId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Authenticate/GetDepartmentMenu/${userId}/${depatmentId}`)
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem(
                    'userRights',
                    JSON.stringify(response?.data?.data?.userRights)
                )
                location.reload()
            } else {
                ErrorMessage(response?.data?.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
