import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Layout, Breadcrumb, Spin, Flex } from 'antd'
import SiderMenu from './siderMenu'
import LayoutBanner from './layoutBanner'
import styles from './Less/style.less'
import Login from '../Pages/User/login'
import LoginPin from '../Pages/User/loginPin'
import { authenticateWithJwt } from '../Services/Auth'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import RoutingList, { breadCrumbNameRouteMapping } from '../Routes/routingList'
import type { MainLayoutProps } from '../Types/CommonType'
import CopyrightTexts from '../Utils/CopyrightText'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Footer } from 'antd/es/layout/layout'
import { ReactComponent as Fullscreen } from '../Assest/Svg/fullscreen-dash.svg'
const { Content } = Layout

interface ItemInterface {
    key: string
    title: string | JSX.Element
}

function MainLayout(props: MainLayoutProps): React.ReactElement {
    const { isLoading, isAuthenticate, isLogin, fixedMenus, fixedSize } = {
        ...props,
    }
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(false)
    const [items, setItems] = useState<ItemInterface[]>([])
    const [isDarkMode] = useState(true)
    const [fixedMenu, setMenu] = useState<number>(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        return storedMenu != null ? JSON.parse(storedMenu) : 0
    })
    const navigate = useNavigate()
    useEffect(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        if (storedMenu != null) {
            setMenu(JSON.parse(storedMenu))
        }
    }, [fixedMenus])

    const themeClass = isDarkMode ? 'dark-theme' : ''

    const getZoomLevel = (): string => {
        switch (fixedSize) {
            case 0:
                return '0.8'
            case 1:
                return '1'
            case 2:
                return '1.2'
            case 3:
                return '1.4'
            default:
                return '1'
        }
    }

    const layoutStyle: React.CSSProperties = {
        ...(fixedMenu === 6 ? { backgroundColor: 'white' } : {}),
        zoom: getZoomLevel(),
    }

    const location = useLocation()

    const handleOnCollapse = (): void => {
        setCollapsed((prevState) => !prevState)
    }

    useEffect(() => {
        if (fixedMenu === 2) {
            setCollapsed(true)
        }
        if (fixedMenu === 0) {
            setCollapsed(false)
        }
        if (fixedMenu === 3) {
            setCollapsed(true)
        }
    }, [fixedMenu])

    useEffect(() => {
        const token = localStorage.getItem('jwtToken')
        if (token !== null) {
            authenticateWithJwt(dispatch)
        } else {
            navigate('/')
        }
    }, [])

    const enterFullScreen = (): void => {
        if (document.fullscreenElement == null) {
            // Enter fullscreen
            document.documentElement.requestFullscreen().catch((err) => {
                console.error('Failed to enter fullscreen mode:', err)
            })
        } else {
            // Exit fullscreen
            document.exitFullscreen().catch((err) => {
                console.error('Failed to exit fullscreen mode:', err)
            })
        }
    }

    useEffect(() => {
        const pathSnippets = location.pathname.split('/')?.filter((i) => i)
        const items = pathSnippets?.map((data: any, index) => {
            let url = `/${pathSnippets.slice(0, index + 1).join('/')}`
            const key = url

            switch (url) {
                case '/system-configuration':
                    url = '/system-configuration/company-group'
                    break
                case '/service-setup':
                    url = '/service-setup/service-user'
                    break
                case '/clients':
                    url = '/clients/registered-clients'
                    break
                case '/missing-information':
                    url = '/missing-information/awaiting-approval'
                    break
                case '/invoicing':
                    url = '/invoicing/invoice-preparation'
                    break
                case '/invoicing/credit-control':
                    url = '/invoicing/credit-control/status-report'
                    break
                case '/help-desk':
                    url = '/help-desk/create-task'
                    break
                case '/hr':
                    url = '/hr/leave-permission'
                    break
                case '/hr/reports':
                    url = '/hr/reports/time-sheet'
                    break
                case '/user-management':
                    url = '/user-management/manage-user'
                    break
                case '/report':
                    url = '/report/service-status'
                    break
                case '/client-comunication':
                    url = '/client-comunication/bulk-mail'
                    break
                case '/wiki':
                    url = '/wiki/article-group'
                    break
                case '/company-house':
                    url = '/company-house/company-eligibility'
                    break
                case '/hrmc':
                    url = '/hrmc/hrmc-64-8'
                    break
                default:
                    break
            }

            const name = breadCrumbNameRouteMapping[data]
            return {
                key,
                title:
                    pathSnippets?.length - 1 === index ? (
                        name
                    ) : (
                        <Link to={url}>{name}</Link>
                    ),
            }
        })
        setItems(items)
    }, [location.pathname])

    if (!isLogin) {
        return <Login />
    }

    if (!isAuthenticate) {
        return <LoginPin />
    }

    return (
        <Layout className={`${styles.main} ${themeClass}`}>
            <LayoutBanner
                collapsed={collapsed}
                handleOnCollapse={handleOnCollapse}
            />

            {fixedMenu === 4 && (
                <>
                    <SiderMenu
                        collapsed={collapsed}
                        handleOnCollapse={handleOnCollapse}
                    />
                    <div className={styles.divider}>
                        <div
                            className={styles.iconButton}
                            onClick={handleOnCollapse}
                        >
                            {collapsed ? <RightOutlined /> : <LeftOutlined />}
                        </div>
                    </div>
                </>
            )}

            <Layout className={styles.layout} hasSider style={layoutStyle}>
                {fixedMenu !== 4 && (
                    <>
                        <SiderMenu
                            collapsed={collapsed}
                            handleOnCollapse={handleOnCollapse}
                        />
                        <div className={styles.divider}>
                            <div
                                className={styles.iconButton}
                                onClick={handleOnCollapse}
                            >
                                {collapsed ? (
                                    <RightOutlined />
                                ) : (
                                    <LeftOutlined />
                                )}
                            </div>
                        </div>
                    </>
                )}

                <Content className={styles.contentLayout}>
                    <Flex justify="space-between" style={{ marginLeft: 20 }}>
                        <Breadcrumb items={items} />
                        {items?.length > 0 && (
                            <Fullscreen
                                style={{
                                    width: 20,
                                    height: 20,
                                    cursor: 'pointer',
                                }}
                                onClick={enterFullScreen}
                            />
                        )}
                    </Flex>
                    {isLoading && (
                        <div className={styles.overlay}>
                            <Spin size="large" />{' '}
                        </div>
                    )}
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                flex: '1',
                            }}
                        >
                            <RoutingList />
                        </div>
                        <Footer
                            className={
                                fixedMenu !== 5
                                    ? styles.footerTextStyle
                                    : styles.footerTextDefaultStyle
                            }
                        >
                            {items?.length > 0 && <CopyrightTexts />}
                        </Footer>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

const mapStateToProps = (state: any): MainLayoutProps => {
    return {
        isLogin: state.auth.isLogin,
        isAuthenticate: state.auth.isAuthenticate,
        isLoading: state.common.isLoading,
        fixedMenus: state.dashboardFlag.fixedMenu,
        fixedSize: state.dashboardFlag.fixedSize,
    }
}

export default connect(mapStateToProps)(MainLayout)
