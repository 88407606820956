// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tablestyle_HyAh7 {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 230px;
}
.thstyle_fhD5Y {
  height: 50px;
  text-align: left;
  padding-left: 10px;
}
.trstyle_m1GYj {
  border: 1px solid #ddd;
}
.tdlabel_acMUq {
  height: 40px;
  width: 80%;
  padding-left: 10px;
}
.tdCount_wTtOz {
  height: 40px;
  width: 20%;
  text-align: right;
  padding-right: 5px;
  color: #3981e4;
  cursor: pointer;
}
.tablestyleE_Qb0Jw {
  border-collapse: collapse;
  width: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/DashboardClientOverview/overviewless.less"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACA;EACI,sBAAA;AACJ;AACA;EACI,YAAA;EACA,UAAA;EACA,kBAAA;AACJ;AACA;EACI,YAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEA;EACI,yBAAA;EACA,YAAA;AAAJ","sourcesContent":[".tablestyle {\n    border-collapse: collapse;\n    border: 1px solid #ddd;\n    width: 230px;\n}\n.thstyle {\n    height: 50px;\n    text-align: left;\n    padding-left: 10px;\n}\n.trstyle {\n    border: 1px solid #ddd;\n}\n.tdlabel {\n    height: 40px;\n    width: 80%;\n    padding-left: 10px;\n}\n.tdCount {\n    height: 40px;\n    width: 20%;\n    text-align: right;\n    padding-right: 5px;\n    color: #3981e4;\n    cursor: pointer;\n}\n\n.tablestyleE {\n    border-collapse: collapse;\n    width: 250px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablestyle": `tablestyle_HyAh7`,
	"thstyle": `thstyle_fhD5Y`,
	"trstyle": `trstyle_m1GYj`,
	"tdlabel": `tdlabel_acMUq`,
	"tdCount": `tdCount_wTtOz`,
	"tablestyleE": `tablestyleE_Qb0Jw`
};
export default ___CSS_LOADER_EXPORT___;
