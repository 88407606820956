import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../../Config/AxiosConfig'
import { type TableListInterface } from '../../Types/CommonType'
import { setLoadingStatus } from '../../Reducer/Common'
import { type CompanyFormationTableDataInterface } from '../../Types/CompanyHouse/CompanyFormation'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { ComapnyDetailsList } from '../../Reducer/CompanyHouseReducer'

export const GetCompanyFomationCompanyList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/CompaniesHouse/GetCHCompanyList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CompanyFormationTableDataInterface) => {
                        return {
                            ...value,
                            key: value.companyId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(ComapnyDetailsList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteCompanyDetails = (
    dispatch: Dispatch,
    id: number,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/41/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
