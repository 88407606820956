import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Col, Row, Select } from 'antd'
import commonStyles from '../../Utils/Common.less'
import type { TableProps } from 'antd/es/table'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../Utils/CommontTable'
import { type TableParamsInterface } from '../../Types/CommonType'
import SearchInput from '../../Components/SearchInput'
import styles from './staffwise.less'
import { GetTotalReport } from '../../Services/StaffReport/staffReport'
import {
    type totalListInterface,
    type totalStateinterface,
} from '../../Types/StaffwiseReport/staffWiseReport'

const TotalReport = (props: totalListInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { totalReportList } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [entriesPerPage, setEntriesPerPage] = useState<number>(50)
    const [searchValue, setSearchValue] = useState<string>('=NA=')

    const options = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 40, label: '40' },
        { value: 50, label: '50' },
    ]

    const defaultValue = 50

    const onChange = (value: number): void => {
        setEntriesPerPage(value)
    }

    const user = localStorage.getItem('user')
    const userId = user !== null ? JSON.parse(user)?.userId : ''
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        const { pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        const finalSearchValue =
            searchValue.trim() === '' ? '=NA=' : searchValue
        GetTotalReport(
            dispatch,
            {
                pageNo,
                pageSize: entriesPerPage,
                searchCriteriaList,
                sortOrderList,
            },
            finalSearchValue,
            userId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        entriesPerPage,
        searchValue,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const columns = [
        {
            title: 'Staff',
            dataIndex: 'staffName',
        },
        {
            title: 'VAT',
            dataIndex: 'vat',
        },
        {
            title: 'PAYE',
            dataIndex: 'paye',
        },
        {
            title: 'Pension',
            dataIndex: 'pension',
        },
        {
            title: 'AA & CT',
            dataIndex: 'aa',
        },
        {
            title: 'CS',
            dataIndex: 'cs',
        },
        {
            title: 'Adhoc',
            dataIndex: 'adHoc',
        },
        {
            title: 'MyTask',
            dataIndex: 'myTask',
        },
    ]

    const onChangeTableParams: TableProps<any>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        setTableParams({
            ...tableParams,
            pagination,
            sorter: {
                field: sorter?.field ?? '',
                order: sorter?.order ?? '',
            },
        })
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchValue(data.target.value)
        if (data.target.value.length !== 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        const fieldValue = data.trim() === '' ? '=NA=' : data.trim()
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: fieldValue,
                fieldValue2: '',
            },
        })
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.corpList}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={'Search CompanyName by keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Col>
                    <Col span={5}>
                        Show{' '}
                        <Select
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                            className={styles.dropstyle}
                        />{' '}
                        entries
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    className={styles.customTable}
                    columns={columns}
                    dataSource={totalReportList}
                    onChange={onChangeTableParams}
                    pagination={{
                        ...tableParams.pagination,
                        pageSize: entriesPerPage,
                    }}
                />
            </div>
        </Card>
    )
}

const mapStateToProps = (state: any): totalStateinterface => {
    return {
        totalReportList: state.staffReport.totalReportList,
    }
}

export default connect(mapStateToProps)(TotalReport)
