import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// Function to create custom buttons with tooltips

// Register custom toolbar buttons with tooltips
export const CustomToolbar = (): JSX.Element => (
    <div id="toolbar">
        <span className="ql-formats">
            <select
                className="ql-header"
                defaultValue=""
                onChange={(e) => {
                    e.persist()
                }}
            >
                <option value="1" title="Heading 1">
                    Heading 1
                </option>
                <option value="2" title="Heading 2">
                    Heading 2
                </option>
                <option value="3" title="Heading 3">
                    Heading 3
                </option>
                <option value="4" title="Heading 4">
                    Heading 4
                </option>
                <option value="5" title="Heading 5">
                    Heading 5
                </option>
                <option value="6" title="Heading 6">
                    Heading 6
                </option>
                <option value="" title="Normal">
                    Normal
                </option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" title="Bold"></button>
            <button className="ql-italic" title="Italic"></button>
            <button className="ql-underline" title="Underline"></button>
            <button className="ql-strike" title="Strike"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-blockquote" title="Blockquote"></button>
            <button className="ql-code-block" title="Code Block"></button>
        </span>
        <span className="ql-formats">
            <button
                className="ql-list"
                value="ordered"
                title="Ordered List"
            ></button>
            <button
                className="ql-list"
                value="bullet"
                title="Bullet List"
            ></button>
        </span>
        <span className="ql-formats">
            <button
                className="ql-script"
                value="sub"
                title="Subscript"
            ></button>
            <button
                className="ql-script"
                value="super"
                title="Superscript"
            ></button>
        </span>
        <span className="ql-formats">
            <button
                className="ql-indent"
                value="-1"
                title="Decrease Indent"
            ></button>
            <button
                className="ql-indent"
                value="+1"
                title="Increase Indent"
            ></button>
        </span>
        <span className="ql-formats">
            <button className="ql-image" title="Insert Image"></button>
            <button className="ql-video" title="Insert Video"></button>
            <button className="ql-formula" title="Insert Formula"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-clean" title="Remove Formatting"></button>
            <button className="ql-link" title="Insert Link"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-color" title="Text Color"></button>
            <button className="ql-background" title="Background Color"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-align" title="Align"></button>
        </span>
    </div>
)

const modules = {
    toolbar: {
        container: '#toolbar',
    },
    clipboard: {
        matchVisual: false,
    },
}

const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'script',
    'indent',
    'size',
    'color',
    'background',
    'align',
    'image',
    'video',
    'formula',
    'link',
    'clean',
]
const Inline = Quill.import('blots/inline')
class BoldBlot extends Inline {}
BoldBlot.blotName = 'bold'
BoldBlot.tagName = 'strong'
Quill.register(BoldBlot, true)

class ItalicBlot extends Inline {}
ItalicBlot.blotName = 'italic'
ItalicBlot.tagName = 'em'
Quill.register(ItalicBlot, true)
interface AddNewButtonInterface {
    value: string
    onChange: (value: string) => void
    className?: string
    heights?: string | number
}
const ReactQuillComponet = (
    props: AddNewButtonInterface
): React.ReactElement => {
    const { value, onChange, className, heights } = props

    return (
        <div>
            <CustomToolbar />
            <ReactQuill
                theme="snow"
                value={value}
                onChange={onChange}
                modules={modules}
                formats={formats}
                className={className}
                style={{
                    height: heights,

                    overflow: 'auto', // Corrected overflow property
                }}
            />
        </div>
    )
}

export default ReactQuillComponet
