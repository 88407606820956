import React, { useCallback, useEffect, useRef, useState } from 'react'
import commonStyles from '../Utils/Common.less'
import { Space, Table, type TableProps, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type PortfolioRemarksListTable,
    type PortfolioRemarksListPropsInterface,
    type PortfolioRemarksListStateInterface,
} from '../Types/Client/RegisteredClients/Portfolio'
import { type ColumnsType } from 'antd/es/table'
import {
    DeleteRemarksDetails,
    GetProtfolioRemarksList,
} from '../Services/Portfolio'
import { DeleteOutlined } from '@ant-design/icons'
import FullWidthModal from './FullWidthModal'
const RemarksHistory = (
    props: PortfolioRemarksListPropsInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const [OpenModalStatus, setOpenStatus] = useState(false)
    const { portfolioRemarksListData, portfolioId, businessId, referenceId } =
        props
    const [remarksId, setRemarksId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        GetProtfolioRemarksList(dispatch, portfolioId, businessId, referenceId)
    }, [portfolioId, businessId, referenceId])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const columns: ColumnsType<PortfolioRemarksListTable> = [
        {
            title: 'Remarks',
            dataIndex: 'remarks',
        },
        {
            title: 'Create Date',
            dataIndex: 'createdDate',
        },
        {
            title: 'Creater Name',
            dataIndex: 'createdName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: PortfolioRemarksListTable) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Delete Remarks">
                            <DeleteOutlined
                                key="delete"
                                onClick={() => {
                                    openModalFunction(record.portfolioRemarksId)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PortfolioRemarksListTable>['onChange'] =
        (pagination, filters, sorter: any) => {}

    const RemarksList =
        portfolioRemarksListData?.clientPortfolioCompanyRemarksView?.map(
            (item) => ({
                ...item,
                key: item.portfolioRemarksId, // or any unique identifier for the key
            })
        )

    const openModalFunction = (portfolioRemarksId: number): void => {
        setRemarksId(portfolioRemarksId)
        setOpenStatus(!OpenModalStatus)
    }

    const RemoveFunction = (): void => {
        DeleteRemarksDetails(dispatch, remarksId, getTableData)
        setOpenStatus(!OpenModalStatus)
    }
    return (
        <>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={RemarksList}
                    onChange={onChangeTableParams}
                />
            </div>
            <FullWidthModal
                modalStatus={OpenModalStatus}
                closeModal={() => {
                    openModalFunction(0)
                }}
                tittle="You are about to Remove Remarks. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={RemoveFunction}
            />
        </>
    )
}

const mapStateToProps = (state: any): PortfolioRemarksListStateInterface => {
    return {
        portfolioRemarksListData: state.portfolio.portfolioRemarksListData,
    }
}

export default connect(mapStateToProps)(RemarksHistory)
