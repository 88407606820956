import { Input, Form, Select, Button, Row, Col } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../../../../Utils/Common.less'
import React, { useEffect } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import {
    GetPaymentOptionDropDownList,
    SetDirectDebitCustomer,
} from '../../../../../../../Services/Parnership'
import { type PartnershipConfigurationInviteClientInterface } from '../../../../../../../Types/Client/RegisteredClients/Partnership'
import { useLocation } from 'react-router-dom'

const { TextArea } = Input

const GoCardlessInviteClient = (
    props: PartnershipConfigurationInviteClientInterface
): React.ReactElement => {
    const { paymentOptionDropDownList, cancelGoCardlessModal, partnershipId } =
        props
    const dispatch = useDispatch()

    const location = useLocation()
    const partnershipData = location.state?.partnershipData
    const [form] = Form.useForm()

    useEffect(() => {
        GetPaymentOptionDropDownList(dispatch)
    }, [])

    const handleFinish = (): void => {
        void handleSubmit()
    }
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const data = {
                CustomerId: 0,
                ApplicationId: 0,
                ReferenceId: partnershipId,
                ClientCategoryId: partnershipData.clientcategoryid,
                BusinessTypeId: 4,
                CompanyName: '',
                GivenName: '',
                FamilyName: '',
                Email: formData.email,
                AddressLine1: '',
                AddressLine2: '',
                AddressLine3: '',
                City: '',
                PostalCode: '',
                CountryCode: '',
                Region: '',
                Language: '',
                PhoneNumber: '',
                DanishIdentityNumber: '',
                SwedishIdentityNumber: '',
                Description: formData.description ?? '',
                RegisterToken: '',
                PaymentOptionId: formData.goCardlessAccount,
            }

            void SetDirectDebitCustomer(dispatch, data, cancelGoCardlessModal)
            form.resetFields()
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
                className={commonStyles.flexDirectionColumn}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please Enter Email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Gocardless Account"
                    name="goCardlessAccount"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Gocardless Account',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        placeholder="Select your payment option"
                        onChange={() => {}}
                        options={paymentOptionDropDownList}
                    />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <TextArea rows={4} />
                </Form.Item>
                <Row gutter={16}>
                    <Col offset={21} span={3}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        paymentOptionDropDownList: state.client.paymentOptionDropDownList,
    }
}

export default connect(mapStateToProps)(GoCardlessInviteClient)
