// import { useLocation } from 'react-router-dom'
import {
    getClientCompanyContactDetails,
    getClientCompanyDetails,
} from '../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import type {
    CorporateFormDataInterface,
    CorporateTap,
} from '../../../../Types/Client/RegisteredClients/Corporate'
import Compliance from './Buttons/Compliance/Compliance'
import HomeList from './Buttons/Home/HomeList'
import SupplierInfo from './Buttons/SupplierInfo'
import CoporateConfiguration from './corporateConfiguration'
import CoporateTabsLess from '../../../../Less/Clients/RegisteredClients/Corporate/CorporateTabs.less'
import ContactInfoDetails from './Buttons/ContactInfo/ContactInfoDetails'
import Documentmain from './Buttons/Doucument/documentMain'
import Officer from './Buttons/OfficerButton/Officer'
import ServiceMain from './Buttons/ServicesTab/ServicesMain'
import { corporateTaps } from '../../../../Reducer/Client'
import { defaultCorporateData } from './corporateView'

const CorporateTabs = (): React.ReactElement => {
    const onbordingId = Number(localStorage.getItem('onbordingId'))
    const [corporateData, setCorporateData] =
        useState<CorporateFormDataInterface>(defaultCorporateData)
    const dispatch = useDispatch()
    const businessTypeId = 1
    const corporateTapId = localStorage.getItem('corporateTapId')
    const [activeTab, setActiveTab] = useState(corporateTapId ?? '1')
    const [corporateId, setCorprateId] = useState(0)
    const [companyservicedetails, setCompanyServiceDetails] = useState<any>({})
    console.log('onbordingId', onbordingId)
    useEffect(() => {
        const storedCorporateData = localStorage.getItem('corporateData')
        if (storedCorporateData !== null) {
            const parsedData: CorporateFormDataInterface =
                JSON.parse(storedCorporateData)
            setCorporateData(parsedData)
            setCorprateId(Number(parsedData.corporateid))
        }
    }, [])
    const onChange = (key: string): void => {
        localStorage.setItem('corporateTapId', key)
        setActiveTab(key)
    }
    const getTapData = useCallback(() => {
        if (corporateId !== 0) {
            void getClientCompanyDetails(
                dispatch,
                businessTypeId,
                corporateId,
                parseInt(activeTab, 10)
            )
        }
    }, [dispatch, businessTypeId, activeTab, corporateId])

    useEffect(() => {
        getTapData()
    }, [getTapData])

    // const fetchDataContactInfo = async (): Promise<void> => {
    //     try {
    //         const data = await getClientCompanyDetails(
    //             dispatch,
    //             businessTypeId,
    //             corporateId,
    //             contactTap
    //         )
    //         setCompanycontactInfo(data)
    //     } catch (error) {}
    // }

    const fetchServiceData = async (): Promise<void> => {
        try {
            const data = await getClientCompanyContactDetails(
                dispatch,
                businessTypeId,
                corporateId,
                4
            )
            setCompanyServiceDetails(data)
        } catch (error) {}
    }

    useEffect(() => {
        if (activeTab === '3') {
            void fetchServiceData()
        }
    }, [activeTab])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Home',
            children: <HomeList corporateId={corporateId} />,
        },
        {
            key: '2',
            label: 'Officer',
            children: (
                <Officer
                    corporateTaps={corporateTaps}
                    corporateId={corporateId}
                    companyName={corporateData?.companyname}
                    companyNumber={corporateData?.companyregisternumber}
                />
            ),
        },
        {
            key: '4',
            label: 'Services',
            children: <ServiceMain corporateid={corporateId} />,
        },
        {
            key: '7',
            label: 'Supplier Info',
            children: <SupplierInfo corporateId={corporateId} />,
        },
        {
            key: '5',
            label: 'Contact Info',
            children: <ContactInfoDetails corporateid={corporateId} />,
        },
        {
            key: '3',
            label: 'Documents',
            children: (
                <Documentmain
                    corporateId={corporateId}
                    companyservicedetails={companyservicedetails}
                />
            ),
        },
        {
            key: '6',
            label: 'Compliance',
            children: (
                <Compliance
                    onbordingId={onbordingId}
                    corporateId={corporateId}
                />
            ),
        },
        {
            key: '10',
            label: 'Configuration',
            children: (
                <CoporateConfiguration
                    registeredClientCorporateConfiguration={''}
                />
            ),
        },
    ]

    return (
        <div className={CoporateTabsLess.tabsWidth}>
            <Tabs activeKey={activeTab} onChange={onChange} items={items} />
        </div>
    )
}

const mapStateToProps = (state: any): CorporateTap => {
    return {
        corporateTaps: state.client.corporateTaps,
        corporateList: state.client.corporateList,
    }
}

export default connect(mapStateToProps)(CorporateTabs)
