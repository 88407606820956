import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Button,
    Switch,
    Form,
    Select,
    Input,
    Card,
    Tooltip,
} from 'antd'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { EditOutlined, EyeFilled, SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import contactInfoStyle from '../../../../../../Less/Clients/RegisteredClients/SoleTrader/ContactInforEditForm.less'
import {
    type ContactInfoInterface,
    type ContactInfoProps,
    type ContactInfoViewProps,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import {
    getSoleTraderHomeDetails,
    SaveContactInfo,
    getClientCompanyDetails,
} from '../../../../../../Services/SoleTrader'
import { GetLanguageList } from '../../../../../../Services/Common'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const businessTypeId = 2

const ContactInfoEditForm = (props: ContactInfoProps): React.ReactElement => {
    const { soleTraderTaps, soleTraderHomeData, languageList } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const location = useLocation()
    const SoleTraderData = location.state?.SoleTraderData
    const soleTraderId = SoleTraderData?.soleTraderId

    useEffect(() => {
        GetLanguageList(dispatch)

        void getSoleTraderHomeDetails(
            dispatch,
            businessTypeId,
            soleTraderId,
            parseInt('1', 10)
        )
    }, [])

    useEffect(() => {
        if (soleTraderTaps?.firstname?.length > 0) {
            const preferredlanguage =
                soleTraderTaps?.preferredlanguage === 0
                    ? ''
                    : soleTraderTaps?.preferredlanguage
            form.setFieldsValue(soleTraderTaps)
            form.setFieldValue('preferredlanguage', preferredlanguage)
        }
    }, [soleTraderTaps])

    const handleClick = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                ...formData,
                preferredlanguage:
                    formData?.preferredlanguage === ''
                        ? 0
                        : formData?.preferredlanguage,
                contactpersonid: soleTraderTaps?.contactpersonid ?? 0,
                registerid: soleTraderId,
                registertype: soleTraderTaps?.registertype,
                memberperson: soleTraderTaps?.memberperson,
            }
            await SaveContactInfo(dispatch, dataToSave)
            await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                soleTraderId,
                5
            )
            editOrViewOnClick()
        } catch (error) {
            console.error('Error validating fields:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const editOrViewOnClick = (): void => {
        setIsEdit(!isEdit)
    }

    const sameAsPersonOnClick = (check: boolean): void => {
        if (check) {
            const data = {
                title: soleTraderHomeData?.soleTraderInitialDetails?.title,
                mobilenumber:
                    soleTraderHomeData?.soleTraderPersonalDetails?.mobileNumber,
                firstname:
                    soleTraderHomeData?.soleTraderInitialDetails?.firstName,
                homenumber:
                    soleTraderHomeData?.soleTraderPersonalDetails?.homeNumber,
                middlename:
                    soleTraderHomeData?.soleTraderInitialDetails?.middleName,
                worknumber:
                    soleTraderHomeData?.soleTraderPersonalDetails?.workNumber,
                surname: soleTraderHomeData?.soleTraderInitialDetails?.surName,
                emailaddress:
                    soleTraderHomeData?.soleTraderPersonalDetails?.emailAddress,
                preferredname: '',
                altemailaddress:
                    soleTraderHomeData?.soleTraderPersonalDetails
                        ?.alternativeEmailAddress,
                preferredlanguage: '',
            }
            form.setFieldsValue(data)
        } else {
            const preferredlanguage =
                soleTraderTaps?.preferredlanguage === 0
                    ? ''
                    : soleTraderTaps?.preferredlanguage
            form.setFieldsValue(soleTraderTaps)
            form.setFieldValue('preferredlanguage', preferredlanguage)
        }
    }

    const ViewPage = (props: ContactInfoViewProps): React.ReactElement => {
        const { soleTraderTaps } = props

        const languageObject = languageList.filter((data: any) => {
            return data.value === soleTraderTaps?.preferredlanguage
        })

        const preferredlanguage =
            soleTraderTaps?.preferredlanguage === 0
                ? ''
                : languageObject[0]?.label

        return (
            <>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Title </Col>
                    <Col span={12}>: {soleTraderTaps?.title}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Mobile No </Col>
                    <Col span={12}>: {soleTraderTaps?.mobilenumber}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>First Name </Col>
                    <Col span={12}>: {soleTraderTaps?.firstname}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Home No </Col>
                    <Col span={12}>: {soleTraderTaps?.homenumber}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Middle Name </Col>
                    <Col span={12}>: {soleTraderTaps?.middlename}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Work No </Col>
                    <Col span={12}>: {soleTraderTaps?.worknumber}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Surname </Col>
                    <Col span={12}>: {soleTraderTaps?.surname}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Email </Col>
                    <Col span={12}>: {soleTraderTaps?.emailaddress}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Preferred Name </Col>
                    <Col span={12}>: {soleTraderTaps?.preferredname}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Alternative Email </Col>
                    <Col span={12}>: {soleTraderTaps?.altemailaddress}</Col>
                </Row>
                <Row className={commonStyles.margingTop10}>
                    <Col span={6}>Preferred Language </Col>
                    <Col span={12}>: {preferredlanguage}</Col>
                </Row>
            </>
        )
    }

    const EditPage = (): React.ReactElement => {
        return (
            <div className={commonStyles.margingTop10}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <div>
                        <Switch size="small" onClick={sameAsPersonOnClick} />
                        &nbsp; &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Person
                        </label>
                    </div>
                    <div className={contactInfoStyle.editMain}>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Title "
                                name="title"
                            >
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    defaultValue={'MR.'}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' &&
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {' '}
                                    <Select.Option value="UNKNOWN">
                                        Unknown
                                    </Select.Option>
                                    <Select.Option value="MR.">
                                        MR.
                                    </Select.Option>
                                    <Select.Option value="MISS.">
                                        MISS.
                                    </Select.Option>
                                    <Select.Option value="MRS.">
                                        MRS.
                                    </Select.Option>
                                    <Select.Option value="MS.">
                                        MS.
                                    </Select.Option>
                                    <Select.Option value="DR.">
                                        DR.
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Mobile Number"
                                name="mobilenumber"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please set the Mobile Number!',
                                    },
                                    {
                                        pattern: /^\d{10,15}$/,
                                        message:
                                            'Please send Valid Mobile number',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={contactInfoStyle.editSecond}>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="First Name"
                                name="firstname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please set the first name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Home Number"
                                name="homenumber"
                                rules={[
                                    {
                                        pattern: /^\d{10,15}$/,
                                        message:
                                            'Mobile Number must be 10 -15 digits!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={contactInfoStyle.editSecond}>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Middle Name"
                                name="middlename"
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Work Number"
                                name="worknumber"
                                rules={[
                                    {
                                        pattern: /^\d{15}$/,
                                        message:
                                            'Work Number must be 15 digits!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={contactInfoStyle.editSecond}>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Surname"
                                name="surname"
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Email Address"
                                name="emailaddress"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please set the Email Address!',
                                    },
                                    {
                                        pattern:
                                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message:
                                            'Please enter a valid Email Address!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={contactInfoStyle.editSecond}>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Preferred Name"
                                name="preferredname"
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Alternative Email Address"
                                name="altemailaddress"
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={contactInfoStyle.editSecond}>
                        <div className={contactInfoStyle.editMainWidth}>
                            <Form.Item<ContactInfoInterface>
                                label="Prefered Language"
                                name="preferredlanguage"
                            >
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    options={languageList}
                                    defaultValue={1}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    }
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {ActivePremission('3000', PermissionType.SAVE) && (
                        <Row>
                            <Col offset={22} span={2}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleClick()
                                            .then(() => {
                                                console.log(
                                                    'Form submitted successfully'
                                                )
                                            })
                                            .catch((error: any) => {
                                                console.error(
                                                    'Error submitting form:',
                                                    error
                                                )
                                            })
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Form>
            </div>
        )
    }

    return (
        <Card className={commonStyles.card} style={{ width: '100%' }}>
            <Row>
                <Col span={23}>
                    <label className={contactInfoStyle.header}>
                        <b>Contact Details</b>
                    </label>
                </Col>
                <Col span={1}>
                    {!isEdit &&
                        ActivePremission('3000', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Contact Information.">
                                <Button
                                    type="primary"
                                    onClick={editOrViewOnClick}
                                >
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                        )}
                    {isEdit && (
                        <Tooltip title="View Contact Information">
                            <Button type="primary" onClick={editOrViewOnClick}>
                                <EyeFilled />
                            </Button>
                        </Tooltip>
                    )}
                </Col>
                &nbsp;&nbsp;
            </Row>
            {!isEdit && <ViewPage soleTraderTaps={soleTraderTaps} />}
            {isEdit && <EditPage />}
        </Card>
    )
}

const mapStateToProps = (state: any): ContactInfoProps => {
    return {
        soleTraderTaps: state.client.soleTraderTaps,
        soleTraderHomeData: state.client.soleTraderHomeData,
        languageList: state.common.languageList,
    }
}

export default connect(mapStateToProps)(ContactInfoEditForm)
