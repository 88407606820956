import React, { useState, type ReactElement, useEffect, useRef } from 'react'
import {
    Form,
    Select,
    DatePicker,
    Input,
    Space,
    type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type initialDetailsInterface,
    type CorporateHomeFormDataInterface,
    type initialState,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCategoryDropDownList } from '../../../../../../Services/GroupAndPartners'

const InitialDetailsForm = (props: initialDetailsInterface): ReactElement => {
    const {
        categoryDropDownList,
        corporateTaps,
        onCategorySelect,
        onDateChange,
    } = props
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selectedDate2, setSelectedDate2] = useState<string>('')
    const dispatch = useDispatch()
    useEffect(() => {
        onDateChange(dayjs(selectedDate).format('DD/MM/YYYY'))
    }, [selectedDate])

    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [])

    useEffect(() => {
        const initialDate = corporateTaps?.initialDetails?.tradecommencementdate
        if (initialDate !== undefined) {
            setSelectedDate(initialDate)
        }
        const date = corporateTaps?.initialDetails?.dateofincorporation
        if (date !== undefined) {
            setSelectedDate2(date)
        }
    }, [corporateTaps?.initialDetails?.tradecommencementdate])

    const handleDateChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }
    const handleDateChange2 = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate2(isoDate)
        }
    }
    return (
        <>
            <Form.Item<CorporateHomeFormDataInterface>
                name="corporateid"
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item<CorporateHomeFormDataInterface>
                name="clienttypeid"
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item<CorporateHomeFormDataInterface>
                label="Client Category"
                name="clientCategory"
            >
                <Select
                    allowClear
                    showSearch
                    onChange={(selectedId) => {
                        if (typeof onCategorySelect === 'function') {
                            onCategorySelect(selectedId)
                        }
                    }}
                    value={corporateTaps?.initialDetails?.clientcategoryid}
                    filterOption={(input, option) =>
                        option?.label
                            ?.toLowerCase()
                            .includes(input.toLowerCase()) ?? false
                    }
                    options={categoryDropDownList}
                />
            </Form.Item>

            <Form.Item<CorporateHomeFormDataInterface>
                label="Date of Incorporation"
                name="DateofIncorporation"
            >
                <Space direction="vertical" size={12}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        className={commonStyles.dateWidth}
                        value={
                            selectedDate2?.length > 0
                                ? dayjs(selectedDate2)
                                : null
                        }
                        onChange={(date, dateString) => {
                            handleDateChange2(date, dateString)
                        }}
                    />
                </Space>
            </Form.Item>
            <Form.Item<CorporateHomeFormDataInterface>
                label="Company Trading Name"
                name="companytradingname"
                initialValue={corporateTaps?.initialDetails?.companytradingname}
            >
                <Input />
            </Form.Item>
            <Form.Item<CorporateHomeFormDataInterface>
                label="Trade Commencement Date"
                name="tradecommencementdate"
            >
                <Space direction="vertical" size={12}>
                    <DatePicker
                        value={
                            selectedDate?.length > 0
                                ? dayjs(selectedDate)
                                : null
                        }
                        format="DD/MM/YYYY"
                        className={commonStyles.dateWidth}
                        onChange={(date, dateString) => {
                            handleDateChange(date, dateString)
                        }}
                    />
                </Space>
            </Form.Item>

            <Form.Item<CorporateHomeFormDataInterface>
                label="Expected Turn Over"
                name="turnover"
            >
                <Input />
            </Form.Item>
            <Form.Item<CorporateHomeFormDataInterface>
                label="Authentication Code"
                name="authenticationcode"
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): initialState => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
    }
}

export default connect(mapStateToProps)(InitialDetailsForm)
