import { Form, Button, Col, Row, Select } from 'antd'
// import commonStyles from '../../../../../../Utils/Common.less'
import React, { useEffect, useState } from 'react'
import {
    AttachIndividual,
    PopulateindualAccountList,
} from '../../../../../../Services/Corporate'
import { useDispatch } from 'react-redux'
import { type ListBackendInterface } from '../../../../../../Types/CommonType'
const OfficerPersonModelForm = (props: {
    referencId: number
    onCancel: () => void
    companyType?: number
}): React.ReactElement => {
    const { referencId, onCancel, companyType = 1 } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [accountName, setaccountName] = useState<ListBackendInterface[]>([])
    const [individualId, setSelectedInduvidual] = useState(0)

    useEffect(() => {
        hadelShowList(referencId)
    }, [referencId])

    const hadelShowList = (referencid: number): void => {
        void PopulateindualAccountList(
            dispatch,
            referencid,
            (getAccount: ListBackendInterface[]) => {
                setaccountName(getAccount)
            }
        )
    }

    const handelAttachIndividual = (status: number): void => {
        void handelAttachIndividualData(status)
    }
    const handelAttachIndividualData = async (
        status: number
    ): Promise<void> => {
        await AttachIndividual(
            dispatch,
            individualId,
            companyType,
            referencId,
            status
        )

        onCancel()
        form.resetFields(['tagname'])
    }
    const handeleDataChange = (value: number): void => {
        setSelectedInduvidual(value)
    }
    return (
        <Form
            name="complex-form"
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form}
        >
            <Row style={{ marginBottom: '20px' }}>
                <Col span={18}>
                    <Form.Item name="tagname">
                        <Select
                            allowClear
                            placeholder="Please select"
                            onChange={handeleDataChange}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                typeof option?.children === 'string' && // Ensure option.children is a string
                                (option.children as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                        >
                            {accountName?.map((item: any) => (
                                <Select.Option
                                    key={item?.stdId}
                                    value={item?.stdId}
                                >
                                    {item.stdName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} justify="end">
                <Col>
                    <Button
                        type="primary"
                        onClick={(): void => {
                            handelAttachIndividual(1)
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col span={2}>
                    <Button
                        type="primary"
                        onClick={(): void => {
                            handelAttachIndividual(0)
                        }}
                    >
                        OK
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default OfficerPersonModelForm
