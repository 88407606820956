import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'

import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { documenttypeList, setLoadingStatus } from '../Reducer/Common'

import {
    type ListBackendInterface,
    type ListFrontEndInterface,
    type TableListInterface,
} from '../Types/CommonType'

import type {
    ClientCompanyActiveService,
    ClientCompanyControlData,
    ClientCompanyVerification,
    DirectDebitCustomerInterface,
    GenerateEmails,
    IndividualCreateFormDataInterface,
    IndividualFormDataInterface,
    ServiceCorporateTaps,
} from '../Types/Client/RegisteredClients/Individual'
import {
    individualBusinessTag,
    individualList,
    individualTaps,
    paymentOptionDropDownList,
    registeredClientCorporateConfiguration,
} from '../Reducer/Client'
import { serviceDropDownList } from '../Reducer/InitialReducer'
import { type AxiosResponse } from 'axios'

export const GetIndividualList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Individual/GetIndividualList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: IndividualFormDataInterface) => {
                        return {
                            ...value,
                            key: value.individualId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(individualList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetServiceDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/18')
        .then((response) => {
            if (response.status === 200) {
                const serviceListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(serviceDropDownList(serviceListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const ActiveInactiveIndividual = (
    dispatch: Dispatch,
    id: number,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetRecordActiveStatus/3/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeleteIndividual = (
    dispatch: Dispatch,
    id: number,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/3/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getClientCompanyDetails = async (
    dispatch: Dispatch,
    businessTypeId: number,
    corporateid: number,
    tabIndex: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/corporate/GetClientCompanyDetail/${businessTypeId}/${corporateid}/${tabIndex}`
        )

        if (response.status === 200) {
            dispatch(individualTaps(response.data))
            return response.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
export const GetCTService = async (
    dispatch: Dispatch,
    serviceId: number,
    serviceReferenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Service/GetClientCompanyServiceDetail/${serviceId}/${serviceReferenceId}`
        )

        if (response.status === 200) {
            dispatch(setLoadingStatus({ status: false }))
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        console.error('Error getting CT service details:', error)
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}
export const GetAddress = async (
    dispatch: Dispatch,
    corporateId: number,
    switchId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetCompanyAddressInformation/1/${corporateId}/${switchId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data.data
                getAddress(addressData)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveAnualServices = async (
    dispatch: Dispatch,
    data: ServiceCorporateTaps
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetServiceAnnualAccount`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetDirectDebitCustomer = (
    dispatch: Dispatch,
    companyTypeId: number,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/corporate/GetClientCompanyDetail/${companyTypeId}/${referenceId}/10`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(registeredClientCorporateConfiguration(response.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const getClientPortalInvitation = async (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/ClientPortal/GetClientInvitation/${businessTypeId}/${referenceId}/1`
        )

        if (response.status === 200) {
            dispatch(individualTaps(response.data))
            SuccessMessage('Client portal invitation send successfully')
        } else {
            ErrorMessage('Failed to sent client portal invitation')
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
export const GetPaymentOptionDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/56')
        .then((response) => {
            if (response.status === 200) {
                const paymentOptionListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(paymentOptionDropDownList(paymentOptionListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching services:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SetDirectDebitCustomer = async (
    dispatch: Dispatch,
    data: DirectDebitCustomerInterface,
    cancelGoCardlessModal: CallableFunction
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`api/DirectDebit/SetDirectDebitCustomer`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                cancelGoCardlessModal()
                SetClientEmail(dispatch, data, response.data.identifierId)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SetClientEmail = (
    dispatch: Dispatch,
    data: DirectDebitCustomerInterface,
    identifierId: string
): void => {
    const clientEmailData = {
        ClientEmailId: 0,
        TemplateId: 0,
        ServiceProcessId: -7,
        CompanyTypeId: data.BusinessTypeId,
        ReferenceId: data.ReferenceId,
        ServiceActionId: 0,
        ServiceActionStepId: 0,
        ToAddress: data.Email,
        CCAddress: '',
        BCCAddress: 'info@iykons.com',
        IykonsBCCAddress: 'info@iykons.com',
        FromAddress: 'info@iykons.com',
        Subject:
            'Iykons Limited would like authorisation to take payment from you',
        Description: data.Description,
    }
    axios
        .post(`api/Email/SetClientEmail`, clientEmailData)
        .then((response) => {
            if (response.status === 200) {
                SetClientEmailStatus(dispatch, identifierId)
            }
        })
        .catch((error) => {
            console.error('Error saving posts:', error)
            return false
        })
}
export const SetClientEmailStatus = (
    dispatch: Dispatch,
    identifierId: string
): void => {
    const ClientEmailStatus = {
        ClientEmailId: identifierId,
        EmailStatus: 'Success',
    }
    axios
        .post(`api/Email/SetClientEmailStatus`, ClientEmailStatus)
        .then((response) => {})
        .catch((error) => {
            console.error('Error saving posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const setBasic = async (
    dispatch: Dispatch,
    data: IndividualCreateFormDataInterface
): Promise<AxiosResponse<any> | false> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(`api/Individual/SetIndividual`, data)

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
        return response
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}
export const EnableServiceForComplince = async (
    dispatch: Dispatch,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/Scheduler/SchedulerPreparationByCompanyId/1/${referenceId}`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage('Enable all active services successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GenerateEmail = async (
    dispatch: Dispatch,
    data: GenerateEmails
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Email/SetClientEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SetClientEmailStatus(dispatch, response.data.identifierId)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetQuotationPreview = async (
    dispatch: Dispatch,
    corporateid: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/Corporate/GetQuotationPreview/3/${corporateid}`
        )
        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveClientCompanyActiveService = async (
    dispatch: Dispatch,
    data: ClientCompanyActiveService
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyActiveService/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveClientCompanyVerification = async (
    dispatch: Dispatch,
    data: ClientCompanyVerification
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyVerification`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveCompanyControl = async (
    dispatch: Dispatch,
    data: ClientCompanyControlData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyControl`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetBusinessTagList = (
    dispatch: Dispatch,
    data: TableListInterface,
    individualId: number,
    searchString: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize } = data

    axios
        .get(
            `/api/Individual/GetPersonInformationForIndAcc/${individualId}/${searchString}/${pageNo}/${pageSize}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: IndividualFormDataInterface) => {
                        return {
                            ...value,
                            key: value.individualId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(individualBusinessTag({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveBusinesstag = async (
    dispatch: Dispatch,
    IndividualId: number,
    ReferenceId: number,
    CompanyType: number,
    Status: number
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `/api/Individual/SetAttachIndividualAccount/${IndividualId}/${CompanyType}/${ReferenceId}/${Status}`
        )

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
            dispatch(setLoadingStatus({ status: false }))
            return true
        } else {
            ErrorMessage(response.data.operationMsg)
            dispatch(setLoadingStatus({ status: false }))
            return false
        }
    } catch (error) {
        //
        console.error('Error saving data:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}

export const GetDocumenttypeList = (
    dispatch: Dispatch,
    p0: number,
    p1: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListingWithTwoParameter/3/1/3')
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(documenttypeList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const saveInduvidual = async (
    dispatch: Dispatch,
    data: IndividualCreateFormDataInterface,
    saveCallBack: CallableFunction
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    const saveDate = {
        individualId: 0,
        clientCategoryId: data.clientCategoryId ?? 0,
        clientNumber: data.clientNumber ?? '',
        typeOfSelfEmployment: data.typeOfSelfEmployment ?? '',
        tradingName: data.tradingName ?? '',
        title: data.title ?? '',
        firstName: data.firstName ?? '',
        middleName: data.middleName ?? '',
        surName: data.surName ?? '',
        dateOfBirth: data.dateOfBirth ?? '',
        addressOne: data.addressOne ?? '',
        addressTwo: data.addressTwo ?? '',
        town: data.homeTown ?? '',
        country: data.homeCountry ?? '',
        postCode: data.homePostCode ?? '',
        mobileNumber: data.mobileNumber ?? '',
        workNumber: data.workNumber ?? '',
        homeNumber: data.homeNumber ?? '',
        emailAddress: data.emailAddress ?? '',
        alternativeEmailAddress: data.alternativeEmailAddress ?? '',
        individualUTR: data.individualUTR ?? '',
        niNumber: data.niNumber ?? '',
        dueDate: data.duedate ?? '',
        isActive: 'Active',
        isDelete: 'Inactive',
    }

    return await axios
        .post(`/api/Individual/SetIndividual`, saveDate)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                saveCallBack(response.data.data)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
