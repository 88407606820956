import React, { useEffect } from 'react'
import { Col, Row, Form, Flex, Input, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { useDispatch } from 'react-redux'
import {
    GetCompanyOverViewData,
    SetShareCapitalDetals,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { type CS_ShareCapitalDetail } from '../../../Types/CompanyHouse/CompanyOverView'
interface terminatePSCData {
    onClosemodel: () => void
    corporateId: number
    serviceProcessId: number
    businessId: number
    editData: CS_ShareCapitalDetail
    currencyName: string
    shareCapitalDetailId: number
    shareCapitalId: number
}

const AddNewShareClass = (props: terminatePSCData): React.ReactElement => {
    const {
        onClosemodel,
        businessId,
        corporateId,
        serviceProcessId,
        editData,
        currencyName,
        shareCapitalDetailId,
        shareCapitalId,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    useEffect(() => {
        form.setFieldValue('currencyType', currencyName)
        form.setFieldsValue(editData)
    }, [editData])
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const handelDatasave = (): void => {
        void submitData()
    }
    const submitData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                shareCapitalDetailId,
                shareCapitalId,
                currencyName,
                shareClass: formData.shareClass,
                noofShares: formData.noofShares,
                aggregateNominalValue: formData.aggregateNominalValue,
                particulars: formData.particulars,
            }
            void SetShareCapitalDetals(dispatch, dataToSave)
            setTimeout(() => {
                GetCompanyOverViewData(
                    dispatch,
                    businessId,
                    corporateId,
                    serviceProcessId,
                    4
                )
                onClosemodel()
            }, 1000)
        } catch (error) {}
    }
    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Currency" name="currencyType">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Share Class" name="shareClass">
                                <Select
                                    allowClear
                                    placeholder="Please select ShareClass"
                                    onChange={() => {}}
                                    defaultValue={0}
                                    filterOption={(input, option) =>
                                        typeof option?.children === 'string' && // Ensure option.children is a string
                                        (option.children as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    showSearch
                                >
                                    <Select.Option value={0}>
                                        PLEASE SELECT
                                    </Select.Option>
                                    <Select.Option value="Preference">
                                        Preference
                                    </Select.Option>
                                    <Select.Option value="Ordinary">
                                        Ordinary
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Number of Shares "
                                name="noofShares"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Number of Shares ',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Aggregate Nominal Value "
                                name="aggregateNominalValue"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter Aggregate Nominal Value ',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Prescribed Particulars "
                                name="particulars"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Particulars',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>

                    <br />

                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={onClosemodel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Add Share Class'}
                                icon={<CheckOutlined />}
                                clickAction={handelDatasave}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default AddNewShareClass
