import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { Form, Input, Select, Switch } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type BussinessStateProps,
    type BussinessAddress,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCountryList } from '../../../../../../Services/Common'

const BussinessAddressForm = (props: BussinessAddress): ReactElement => {
    const { countryList, isEditing, toggleEditing } = props
    dayjs.locale('en')
    const [selectedCountry, setSelectedCountry] = useState<string>('')

    const dispatch = useDispatch()
    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        GetCountryList(dispatch)
    }, [])
    const handleCountryChange = (value: string): void => {
        setSelectedCountry(value)
        props.onDateChange(value)
    }

    return (
        <>
            <div className={commonStyles.bussinessToggle}>
                <Switch
                    size="small"
                    checked={isEditing}
                    onChange={toggleEditing}
                />
                <span className={commonStyles.bussinesToggleA}>
                    Same as Office
                </span>
            </div>
            <Form.Item
                label="Address 1"
                name="busaddress1"
                rules={[
                    {
                        required: true,
                        message: 'Please input your address one!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Address 2" name="busaddress2">
                <Input />
            </Form.Item>
            <Form.Item
                label="Town"
                name="bustown"
                rules={[
                    {
                        required: true,
                        message: 'Please input your town!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Country" name="buscountry">
                <Select
                    key={countryList?.length}
                    allowClear
                    showSearch
                    onChange={handleCountryChange}
                    options={countryList?.map((country) => ({
                        value: country.label,
                        label: country.label,
                    }))}
                    value={selectedCountry}
                />
            </Form.Item>
            <Form.Item
                label="Post Code"
                name="buspostcode"
                rules={[
                    {
                        required: true,
                        message: 'Please input your PostCode!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): BussinessStateProps => {
    return {
        countryList: state.common.countryList,
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(BussinessAddressForm)
