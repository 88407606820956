import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Tooltip, Modal } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { ReactComponent as Document } from '../../../Assest/Svg/DashboardTodoDocument.svg'
import { ReactComponent as CreateTask } from '../../../Assest/Svg/createtask.svg'
import {
    type MobileRequestTableInterface,
    type MobileReqestState,
} from '../../../Types/MobileRequest'
import calculatePagination from '../../../Utils/Pagination'
import { GetMobileRequest } from '../../../Services/MobileRequest'
import MobileRequestExpandView from './moblieRequestExpandView'
import MobileRequestCreateTask from './mobleRequestCreateTask'
import DocumentViewModel from './atachmentPopup'
const MobileRequestList = (props: MobileReqestState): React.ReactElement => {
    const dispatch = useDispatch()
    const { mobileReqestList } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [showTaskModel, setTaskModel] = useState(false)
    const [clientRequestId, setClientRequestId] = useState(0)
    const [companyType, setcompanyType] = useState(0)
    const [referenceId, setReferenceId] = useState(0)
    const [showDocumentModel, setshowDocumentModel] = useState(false)
    const [searchString, setSearchString] = useState('=NA=')
    const hasFetchedOnce = useRef(false)
    window.scrollTo(0, 0)
    const getTableData = useCallback(() => {
        GetMobileRequest(dispatch, currentPage, itemsPerPage, searchString)
    }, [currentPage, itemsPerPage, searchString])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        mobileReqestList.length,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<MobileRequestTableInterface> = [
        {
            title: 'Client ID',
            dataIndex: 'referenceId',
        },
        {
            title: 'Client',
            dataIndex: 'companyName',
        },
        {
            title: 'Request Date',
            key: 'clientRequestId',
            render: (_, record) => {
                return <div>{formatdate(record.requestDate)}</div>
            },
        },
        {
            title: 'Service',
            dataIndex: 'serviceName',
        },
        {
            title: 'Document',
            key: 'action',
            render: (_, record: MobileRequestTableInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="View Document">
                            <Document
                                onClick={() => {
                                    onShowDocumentModel(record.clientRequestId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
        {
            title: 'Create Task',
            key: 'action',
            render: (_, record: MobileRequestTableInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Create Task">
                            <CreateTask
                                onClick={() => {
                                    onShowCreateTaskModel(
                                        record.clientRequestId,
                                        record.companyType,
                                        record.referenceId
                                    )
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<MobileRequestTableInterface>['onChange'] =
        (pagination) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (value: string): void => {
        if (value.length > 0) {
            setSearchString(value)
        } else {
            setSearchString('=NA=')
        }
    }

    const onShowCreateTaskModel = (
        id: number,
        companyType: number,
        referId: number
    ): void => {
        setcompanyType(companyType)
        setClientRequestId(id)
        setReferenceId(referId)
        setTaskModel(!showTaskModel)
    }

    const changeTaskModelStatus = (): void => {
        setTaskModel(!showTaskModel)
    }

    const onShowDocumentModel = (id: number): void => {
        setClientRequestId(id)

        setshowDocumentModel(!showDocumentModel)
    }

    const changeDocumentModelStatus = (): void => {
        setshowDocumentModel(!showDocumentModel)
    }
    const mappdata = mobileReqestList?.map((item) => ({
        ...item,
        key: item.clientRequestId,
    }))

    const expandableData = (
        record: MobileRequestTableInterface
    ): React.ReactElement => {
        return <MobileRequestExpandView expandData={record} />
    }
    const formatdate = (date: string): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    return (
        <>
            <Card title="Mobile Request" className={commonStyles.card}>
                <SearchInput
                    placeHolder={'Search Project & Project Details by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={mappdata}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
            </Card>
            <Modal
                footer={null}
                onCancel={changeTaskModelStatus}
                width={'50%'}
                title={'Create Task'}
                open={showTaskModel}
            >
                <MobileRequestCreateTask
                    onClose={changeTaskModelStatus}
                    clientRequestId={clientRequestId}
                    companyType={companyType}
                    referenceId={referenceId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeDocumentModelStatus}
                width={'50%'}
                title={'Documents'}
                open={showDocumentModel}
            >
                <DocumentViewModel clientRequestId={clientRequestId} />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): MobileReqestState => {
    return {
        mobileReqestList: state.mobileRequest.mobileReqestList,
        mobileReqestListCount: state.mobileRequest.mobileReqestListCount,
    }
}

export default connect(mapStateToProps)(MobileRequestList)
