import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Select, Flex } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { GetSICCodeList } from '../../../Services/Common'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import {
    GetCompanyOverViewData,
    SetCompanySicCode,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { type CH_CompanyDetailsView } from '../../../Types/CompanyHouse/CompanyOverView'
interface AddressProps {
    sicCodeList: ListFrontEndInterface[]
}
const EditSICCode = (
    props: AddressProps & {
        companyName: string
        companyNumber: string
        oncloseModel: () => void
        editData: CH_CompanyDetailsView | null
        coroporateId: number
        businessId: number
        serviceProcessId: number
    }
): React.ReactElement => {
    const {
        sicCodeList,
        oncloseModel,
        companyName,
        companyNumber,
        editData,
        coroporateId,
        businessId,
        serviceProcessId,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    useEffect(() => {
        GetSICCodeList(dispatch)
    }, [])

    useEffect(() => {
        const combineData = `${companyName} - ${companyNumber}`
        form.setFieldValue('companyNameandReg', combineData)
        const sicCode1 = sicCodeList.find(
            (data) => data.label === editData?.sicCode1
        )
        const sicCode2 = sicCodeList.find(
            (data) => data.label === editData?.sicCode2
        )
        const sicCode3 = sicCodeList.find(
            (data) => data.label === editData?.sicCode3
        )
        const sicCode4 = sicCodeList.find(
            (data) => data.label === editData?.sicCode4
        )
        form.setFieldValue('sicCode1', sicCode1?.value)
        form.setFieldValue('sicCode2', sicCode2?.value)
        form.setFieldValue('sicCode3', sicCode3?.value)
        form.setFieldValue('sicCode4', sicCode4?.value)
    }, [editData, sicCodeList])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const handelDatasave = (): void => {
        void submitData()
    }
    const submitData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                companyId: editData?.companyId ?? 0,
                corporateId: coroporateId,
                sicCode1: formData.sicCode1,
                sicCode2: formData.sicCode2,
                sicCode3: formData.sicCode3,
                sicCode4: formData.sicCode4,
                isSubmitToCH: 0,
            }
            void SetCompanySicCode(dispatch, dataToSave)
            setTimeout(() => {
                GetCompanyOverViewData(
                    dispatch,
                    businessId,
                    coroporateId,
                    serviceProcessId,
                    1
                )
                oncloseModel()
            }, 1000)
        } catch (error) {}
    }
    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Company & Reg Number"
                                name="companyNameandReg"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="SIC Code 1"
                                name="sicCode1"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select SIC Code 1',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={sicCodeList}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="SIC Code 2" name="sicCode2">
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={sicCodeList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="SIC Code 3" name="sicCode3">
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={sicCodeList}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="SIC Code 4" name="sicCode4">
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={sicCodeList}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={oncloseModel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                                clickAction={handelDatasave}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): AddressProps => {
    return {
        sicCodeList: state.common.sicCodeList,
    }
}

export default connect(mapStateToProps)(EditSICCode)
