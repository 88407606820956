import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Card,
    Flex,
    Steps,
    Tooltip,
    Modal,
    Drawer,
    Image,
    Space,
    Button,
    Row,
    Col,
    Popover,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import {
    MessageOutlined,
    PushpinOutlined,
    UnorderedListOutlined,
    HistoryOutlined,
    MailOutlined,
    PhoneOutlined,
    CommentOutlined,
    UserSwitchOutlined,
    ArrowRightOutlined,
    ProfileOutlined,
} from '@ant-design/icons'
import style from '../tingtodo.less'
import ServiceProcessHistory from './serviceProcessHistoryModel'
import CommunicationHistory from '../CommunicationModel/CommunicationHistory'
import ToDoPhoneCallModel from '../CommunicationModel/phoneCallModel'
import MailTempalteModel from '../CommunicationModel/mailModel'
import SMSModelPage from '../CommunicationModel/smsModel'
import StaffCommunication from '../CommunicationModel/staffCommunication'
import {
    type ProcessCheckListItem,
    type PinedMessage,
    type ServiceProcessPageProps,
    type SupplierInfoData,
    type ServiceActionGroup,
} from '../../../Types/ToDoViewAllList'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    GetCheckListData,
    GetMissingInfrmation,
    GetPinnedChat,
    GetServiceProcessAtionGroup,
    GetServiceProcessInduvidual,
    GetSupplierInfrmation,
    RevertProcess,
    SetProcessCheckListFlag,
} from '../../../Services/ToDoViewAllList'
import SwitchUserPage from '../CommunicationModel/switchUserModel'
import ProcessRemarks from './processRemarksModel'
import ConverCodeToReact from './htmlConverter'
import ServiceProcessSwitchUserModel from './SwitchAssignUserModel'
import ChangeProgressModel from './changeProgressModel'
import { ReactComponent as Approved } from '../../../Assest/ServiceprocessSVG/approved.svg'
import { ReactComponent as Complete } from '../../../Assest/ServiceprocessSVG/completed.svg'
import { ReactComponent as Denied } from '../../../Assest/ServiceprocessSVG/denied.svg'
import { ReactComponent as Progress } from '../../../Assest/ServiceprocessSVG/progress.svg'
import { ReactComponent as Assigned } from '../../../Assest/ServiceprocessSVG/assign.svg'
import { ReactComponent as Skip } from '../../../Assest/ServiceprocessSVG/skip.svg'
import { ReactComponent as Wip } from '../../../Assest/ServiceprocessSVG/wip.svg'
import { ReactComponent as Infoicon } from '../../../Assest/ServiceprocessSVG/InfoI.svg'
import SkipActionModel from './skipActionModel'
import ChangeDataProcessModel from './ChangeDataProcessmodel'
import CheckListPage from './checkListpage'
import FullWidthModal from '../../../Components/FullWidthModal'
import VoidThisProcess from './voidthisProcessModel'
import PortfolioView from '../../../Components/viewProfolioDetails'
const ServiceProcessList = (
    props: ServiceProcessPageProps
): React.ReactElement => {
    const {
        serviceProcessInduvidualData,
        serviceProcessDetailsList,
        servicedataId,
    } = props
    localStorage.setItem('servicedataId', JSON.stringify(servicedataId))
    const serviceProcessIds = localStorage.getItem('servicedataId')
    const IdData =
        serviceProcessIds !== null ? JSON.parse(serviceProcessIds) : null
    const serviceprocessId =
        servicedataId?.serviceProcesssId ?? IdData.serviceProcesssId
    const serviceId = servicedataId?.serviceId ?? IdData.serviceId
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0)
    const [CurrentSeletValue, setCurrentSelectValue] = useState(1)
    const [Historymodel, setHistorymodel] = useState(false)
    const [comHistorymodel, setCommunicationHistorymodel] = useState(false)
    const [serviceProcessId, setServiceProcessId] = useState<number>(0)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const [PhoneCallModel, setPhoneCallmodel] = useState(false)
    const [SMSModel, setSMSmodel] = useState(false)
    const [mailmodel, setMailmodel] = useState(false)
    const [open, setOpen] = useState(false)
    const [switchUserModel, setSwitchUserModel] = useState(false)
    const [remarksModel, setRemarksModel] = useState(false)
    const [auditStateMentModel, setAuditStateMentModel] = useState(true)
    const [cardSwitchUserModel, setcardSwitchUserModel] = useState(false)
    const [changeProcessModel, setChangeProcessModel] = useState(false)
    const [auditdata, setAuditData] = useState<PinedMessage | null>(null)
    const [serviceActionId, setServiceActionId] = useState(0)
    const [skipActionModel, setSkipActionModel] = useState(false)
    const [CheckListData, setCheckListData] = useState<ProcessCheckListItem[]>()
    const [isCardVisible, setIsCard2Visible] = useState(false)
    const [missingInfo, setMissingInfo] = useState('')
    const [actionName, setActionName] = useState('')
    const [changeDataProcessModel, setChangeDataProcessModel] = useState(false)
    const [openRevetModel, setRevetModel] = useState(false)
    const navigate = useNavigate()
    const [voidThisModel, setVoidThisModel] = useState(false)
    const [openpop, setOpenpop] = useState<number | null>(null)
    const [SupplierInfo, setSupplierInfo] = useState<SupplierInfoData>()
    const [portfolioModel, setportfolioModel] = useState(false)
    const [referenceId, setreferenceId] = useState(0)
    const [businessId, setBusinessId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        GetServiceProcessAtionGroup(dispatch, serviceprocessId)
    }, [serviceprocessId])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    useEffect(() => {
        GetServiceProcessInduvidual(dispatch, serviceprocessId)

        const fetchData = async (): Promise<void> => {
            try {
                const data = await GetMissingInfrmation(
                    dispatch,
                    serviceprocessId
                )
                setMissingInfo(data)
            } catch (error) {}
        }
        const fetchAuditData = async (): Promise<void> => {
            try {
                const data = await GetPinnedChat(dispatch, serviceprocessId)
                setAuditData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        const fetchNillData = async (): Promise<void> => {
            try {
                const data = await GetCheckListData(dispatch, serviceprocessId)
                setCheckListData(data)
                if (data.length === 0) {
                    setIsCard2Visible(true)
                }
            } catch (error) {}
        }

        void fetchNillData()
        void fetchData()
        void fetchAuditData()
    }, [serviceprocessId])

    const onChangeStep = (value: number): void => {
        const selectedStep = serviceProcessDetailsList[value]

        if (selectedStep !== null) {
            const { serviceActionGroupId } = selectedStep
            setCurrentSelectValue(serviceActionGroupId)
            setCurrent(value)
        }
    }

    const onHistoryModelOpen = (serviceprocesId: number): void => {
        setHistorymodel(!Historymodel)
        setServiceProcessId(serviceprocesId)
    }

    const changeHistoryModalStatus = (): void => {
        setHistorymodel(!Historymodel)
        getTableData()
    }

    const openHistoryModel = (id: number): void => {
        setServiceProcessId(id)
        setCommunicationHistorymodel(!comHistorymodel)
    }

    const changeCommunictionHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setCommunicationHistorymodel(!comHistorymodel)
        getTableData()
    }

    const openPhoneCallModel = (id: number): void => {
        setServiceProcessId(id)
        setPhoneCallmodel(!PhoneCallModel)
    }

    const changePhoneCallModalStatus = (): void => {
        setPhoneCallmodel(!PhoneCallModel)
        getTableData()
    }

    const openSMSModel = (id: number): void => {
        setServiceProcessId(id)
        setSMSmodel(!SMSModel)
    }

    const changeSMSModalStatus = (): void => {
        setSMSmodel(!SMSModel)
        getTableData()
    }

    const openMailModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setMailmodel(!mailmodel)
    }

    const changeMailModalStatus = (): void => {
        setMailmodel(!mailmodel)
        getTableData()
    }

    const openSwitchUserModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setSwitchUserModel(!switchUserModel)
    }

    const changeSwitchUserModalStatus = (): void => {
        setSwitchUserModel(!switchUserModel)
        getTableData()
    }

    const showDrawer = (id: number): void => {
        setServiceProcessId(id)
        setOpen(true)
    }

    const onClose = (): void => {
        setOpen(false)
        getTableData()
    }

    const openRemarksModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setRemarksModel(!remarksModel)
    }

    const changeRemarksModalStatus = (): void => {
        setRemarksModel(!remarksModel)
        getTableData()
    }

    const changeAuditModalStatus = (): void => {
        setAuditStateMentModel(!auditStateMentModel)
    }

    const openCardAssignUserModel = (
        serviceProcessId: number,
        serviceActionId: number
    ): void => {
        setServiceProcessId(serviceProcessId)
        setServiceActionId(serviceActionId)
        setcardSwitchUserModel(!cardSwitchUserModel)
    }

    const CardAssignUserModelStatus = (): void => {
        setcardSwitchUserModel(!cardSwitchUserModel)
        getTableData()
    }

    const openChageProcessModel = (
        serviceProcessId: number,
        name: string,
        serviceActionId: number
    ): void => {
        if (CurrentSeletValue === 1) {
            setChangeDataProcessModel(!changeDataProcessModel)
        } else {
            setChangeProcessModel(!changeProcessModel)
        }
        setActionName(name)
        setServiceProcessId(serviceProcessId)
        setServiceActionId(serviceActionId)
    }

    const ChengeProcessModelStatus = (): void => {
        setChangeProcessModel(!changeProcessModel)
        getTableData()
    }

    const ChengeDataProcessModelStatus = (): void => {
        setChangeDataProcessModel(!changeDataProcessModel)
        getTableData()
    }

    const openSkipActionModel = (
        serviceProcessId: number,
        serviceActionId: number
    ): void => {
        setServiceProcessId(serviceProcessId)
        setServiceActionId(serviceActionId)
        setSkipActionModel(!skipActionModel)
    }

    const ChengeSkipAcitonModelStatus = (): void => {
        setSkipActionModel(!skipActionModel)
        getTableData()
    }

    const processListVisible = (): void => {
        getTableData()
        setIsCard2Visible(true)
    }
    const setCheckListfalg = (): void => {
        void onSaveCheckList()
    }
    const onSaveCheckList = async (): Promise<void> => {
        try {
            await SetProcessCheckListFlag(dispatch, serviceprocessId, 1)
        } catch (error) {}
    }

    const CloseRevetModel = (): void => {
        setRevetModel(!openRevetModel)
    }
    const setRevetProcess = (): void => {
        void onSaveRevetProcess()
    }
    const onSaveRevetProcess = async (): Promise<void> => {
        try {
            const referenceId = serviceProcessInduvidualData?.referenceId
            await RevertProcess(dispatch, serviceProcessId, 1, referenceId)
            setTimeout(() => {
                getTableData()
                setIsCard2Visible(false)
            }, 3000)

            CloseRevetModel()
        } catch (error) {}
    }
    const formatdate = (date: string): string => {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()
        return `${month}/${day}/${year}`
    }
    const filteredData = serviceProcessDetailsList?.filter(
        (item) =>
            item.completeStatusId === 3 ||
            item.completeStatusId === 4 ||
            item.completeStatusId === 7
    )

    const filterServiceProcessDetailsList = (
        list: ServiceActionGroup[]
    ): ServiceActionGroup[] => {
        return list.filter(
            (item) =>
                item?.serviceProcessActionList?.some(
                    (action) => action.completeStatusId !== 4
                ) && item.completeStatusId !== 4
        )
    }

    const filteredList = filterServiceProcessDetailsList(
        serviceProcessDetailsList
    )
    useEffect(() => {
        const activeIndex = filteredList.findIndex(
            (group) => group.activeStatusId === 1
        )
        if (activeIndex !== -1) {
            setCurrent(activeIndex)
            setCurrentSelectValue(activeIndex + 1)
        } else {
            setCurrent(activeIndex - 1)
        }
    }, [serviceProcessDetailsList])

    const handelEmailOnclick = (
        value: number,
        serviceActionId: number
    ): void => {
        const invoiceIds = serviceprocessId
        const tempId = value
        navigate(`/thingto-do/service-process/send-email`, {
            state: { tempId, invoiceIds, serviceId, serviceActionId },
        })
    }

    const openViodModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setRevetModel(!openRevetModel)
    }
    const changeVoidModalStatus = (): void => {
        setVoidThisModel(!voidThisModel)
        setIsCard2Visible(false)
    }

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const data = await GetSupplierInfrmation(
                    dispatch,
                    serviceprocessId,
                    serviceActionId
                )
                setSupplierInfo(data)
            } catch (error) {}
        }
        void fetchData()
    }, [serviceActionId])
    const content = (
        <div>
            <p>UserName: {SupplierInfo?.password}</p>
            <p>Password: {SupplierInfo?.password}</p>
            <p>consumerNo: {SupplierInfo?.consumerNo}</p>
            <p>url: {SupplierInfo?.url}</p>
        </div>
    )
    const handleOpenChange = (
        newOpen: boolean,
        actionId: number,
        index: number
    ): void => {
        setOpenpop(newOpen ? index : null)
        setServiceActionId(actionId)
    }

    const openProtfolio = (referenceId: number, BusinessId: number): void => {
        setreferenceId(referenceId)
        setBusinessId(BusinessId)
        setportfolioModel(!portfolioModel)
    }

    return (
        <>
            <div className={style.heder}>
                <div className={style.hederText}>
                    {serviceProcessInduvidualData?.companyName} -{' '}
                    {serviceProcessInduvidualData?.serviceName} Process
                </div>
                <div>
                    {serviceId !== 9 ? (
                        <>
                            {serviceProcessInduvidualData?.year} -{' '}
                            {serviceProcessInduvidualData?.serviceName} Process
                            for the period of to{' '}
                            {formatdate(
                                serviceProcessInduvidualData?.periodEndDate
                            )}
                        </>
                    ) : (
                        <>
                            Next accounts made up to{' '}
                            {formatdate(
                                serviceProcessInduvidualData?.periodStartDate
                            )}{' '}
                            due by{' '}
                            {formatdate(
                                serviceProcessInduvidualData?.periodEndDate
                            )}
                        </>
                    )}
                </div>
            </div>
            {!isCardVisible &&
                CheckListData !== undefined &&
                CheckListData.length !== 0 && (
                    <Card
                        title={`${serviceProcessInduvidualData?.businessType}-${serviceProcessInduvidualData?.clientNumber}`}
                        extra={
                            <>
                                <Flex
                                    gap={8}
                                    className={style.ServiceProcessCradButton}
                                >
                                    <Tooltip title="Remarks History">
                                        <ProfileOutlined
                                            onClick={() => {
                                                openProtfolio(
                                                    serviceProcessInduvidualData.businessTypeId,
                                                    serviceProcessInduvidualData.referenceId
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Staff Communication'}>
                                        <CommentOutlined
                                            onClick={() => {
                                                showDrawer(serviceprocessId)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Process Remarks'}>
                                        <PushpinOutlined
                                            onClick={() => {
                                                openRemarksModel(
                                                    serviceprocessId
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Service Process Hitory'}>
                                        <UnorderedListOutlined
                                            onClick={() => {
                                                onHistoryModelOpen(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Swith User'}>
                                        <UserSwitchOutlined
                                            onClick={() => {
                                                openSwitchUserModel(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Communication History'}>
                                        <HistoryOutlined
                                            onClick={() => {
                                                openHistoryModel(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Send Reminder Mail'}>
                                        <MailOutlined
                                            onClick={() => {
                                                openMailModel(serviceprocessId)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Send SMS'}>
                                        <MessageOutlined
                                            onClick={() => {
                                                openSMSModel(serviceprocessId)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Phone Calls'}>
                                        <PhoneOutlined
                                            onClick={() => {
                                                openPhoneCallModel(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                </Flex>
                            </>
                        }
                    >
                        <div>
                            <CheckListPage
                                CheckListData={CheckListData}
                                serviceProcessId={serviceprocessId}
                                processListVisible={processListVisible}
                            />
                        </div>
                        <Row gutter={16}>
                            <Col offset={20} span={2}>
                                <Button
                                    type="primary"
                                    size="middle"
                                    onClick={() => {
                                        setCheckListfalg()
                                        setIsCard2Visible(true)
                                    }}
                                >
                                    Contine
                                    <ArrowRightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}
            {isCardVisible && (
                <>
                    <Card
                        title={`${serviceProcessInduvidualData?.businessType}-${serviceProcessInduvidualData?.clientNumber}`}
                        extra={
                            <>
                                <Flex
                                    gap={8}
                                    className={style.ServiceProcessCradButton}
                                >
                                    <Tooltip title="Remarks History">
                                        <ProfileOutlined
                                            onClick={() => {
                                                openProtfolio(
                                                    serviceProcessInduvidualData.businessTypeId,
                                                    serviceProcessInduvidualData.referenceId
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                    {serviceId === 10 && (
                                        <Tooltip title={'CS Filling'}>
                                            <Button
                                                type="primary"
                                                size="middle"
                                                onClick={() => {
                                                    const pageIdentyFyId = 2
                                                    const ClientId = `${serviceProcessInduvidualData.businessTypeId}-${serviceProcessInduvidualData.referenceId}`
                                                    navigate(
                                                        `/confirmation-Statement/summary`,
                                                        {
                                                            state: {
                                                                pageIdentyFyId,
                                                                ClientId,
                                                            },
                                                        }
                                                    )
                                                }}
                                            >
                                                CS Filling
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={'Staff Communication'}>
                                        <CommentOutlined
                                            onClick={() => {
                                                showDrawer(serviceprocessId)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Process Remarks'}>
                                        <PushpinOutlined
                                            onClick={() => {
                                                openRemarksModel(
                                                    serviceprocessId
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Service Process Hitory'}>
                                        <UnorderedListOutlined
                                            onClick={() => {
                                                onHistoryModelOpen(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Swith User'}>
                                        <UserSwitchOutlined
                                            onClick={() => {
                                                openSwitchUserModel(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Communication History'}>
                                        <HistoryOutlined
                                            onClick={() => {
                                                openHistoryModel(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Send Reminder Mail'}>
                                        <MailOutlined
                                            onClick={() => {
                                                openMailModel(serviceprocessId)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Send SMS'}>
                                        <MessageOutlined
                                            onClick={() => {
                                                openSMSModel(serviceprocessId)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Phone Calls'}>
                                        <PhoneOutlined
                                            onClick={() => {
                                                openPhoneCallModel(
                                                    serviceprocessId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                </Flex>
                            </>
                        }
                        className={commonStyles.card}
                    >
                        {missingInfo.length > 0 && (
                            <div className={style.missingInfomation}>
                                <b>Missing Information </b>:-{missingInfo}
                            </div>
                        )}
                        {serviceProcessDetailsList?.length >
                        filteredData?.length ? (
                            <>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Card>
                                            <Steps
                                                current={current}
                                                onChange={onChangeStep}
                                                labelPlacement="vertical"
                                                items={serviceProcessDetailsList
                                                    .filter(
                                                        (data) =>
                                                            data.completeStatusId !==
                                                            4
                                                    )
                                                    .map((group) => ({
                                                        title: group.actionGroupName,
                                                        className: `${
                                                            group.activeStatusId ===
                                                            1
                                                                ? 'active'
                                                                : ''
                                                        } ${group.completeStatus.replace(
                                                            ' ',
                                                            ''
                                                        )}`,
                                                    }))}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className={style.cardContainer}>
                                            {serviceProcessDetailsList[
                                                current
                                            ]?.serviceProcessActionList
                                                ?.filter(
                                                    (x) =>
                                                        x.completeStatusId !== 4
                                                )
                                                ?.map((action, actionIndex) => (
                                                    <div
                                                        className={
                                                            style.cardWidth
                                                        }
                                                        key={actionIndex}
                                                    >
                                                        <div
                                                            className={
                                                                style.circle
                                                            }
                                                        >
                                                            {actionIndex + 1}
                                                        </div>
                                                        <div
                                                            className={
                                                                style.circle2
                                                            }
                                                        >
                                                            <Space size={10}>
                                                                {action.completeStatusId !==
                                                                    4 && (
                                                                    <>
                                                                        {action.completeStatusId !==
                                                                            8 && (
                                                                            <>
                                                                                {action.completeStatusId ===
                                                                                3 ? (
                                                                                    <Tooltip title="Completed">
                                                                                        <Complete
                                                                                            className={
                                                                                                style.iconStyleSvg
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                ) : action.completeStatusId ===
                                                                                  4 ? (
                                                                                    <Tooltip title="Not Required">
                                                                                        <Denied
                                                                                            className={
                                                                                                style.iconStyleSvg
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                ) : action.completeStatusId ===
                                                                                  7 ? (
                                                                                    <Tooltip title="Approved">
                                                                                        <Approved
                                                                                            className={
                                                                                                style.iconStyleSvg
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <>
                                                                                        {action.completeStatusId ===
                                                                                        3 ? (
                                                                                            <Tooltip title="Completed">
                                                                                                <Complete
                                                                                                    className={
                                                                                                        style.iconStyleSvg
                                                                                                    }
                                                                                                />
                                                                                            </Tooltip>
                                                                                        ) : action.completeStatusId ===
                                                                                          4 ? (
                                                                                            <Tooltip title="Not Required">
                                                                                                <Denied
                                                                                                    className={
                                                                                                        style.iconStyleSvg
                                                                                                    }
                                                                                                />
                                                                                            </Tooltip>
                                                                                        ) : action.completeStatusId ===
                                                                                          7 ? (
                                                                                            <Tooltip title="Approved">
                                                                                                <Approved
                                                                                                    className={
                                                                                                        style.iconStyleSvg
                                                                                                    }
                                                                                                />
                                                                                            </Tooltip>
                                                                                        ) : (
                                                                                            <>
                                                                                                {action.activeStatusId ===
                                                                                                1 ? (
                                                                                                    <>
                                                                                                        {action.actionType ===
                                                                                                        2 ? (
                                                                                                            <Tooltip title="Send Email">
                                                                                                                <Progress
                                                                                                                    onClick={() => {
                                                                                                                        handelEmailOnclick(
                                                                                                                            action.emailTemplateId,
                                                                                                                            action.serviceActionId
                                                                                                                        )
                                                                                                                    }}
                                                                                                                    className={
                                                                                                                        style.iconStyleSvg
                                                                                                                    }
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {action.completeStatusColorCode ===
                                                                                                                '#fad3be' ? (
                                                                                                                    <Tooltip title="Change your progress status">
                                                                                                                        <Wip
                                                                                                                            className={
                                                                                                                                style.iconStyleSvg
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </Tooltip>
                                                                                                                ) : (
                                                                                                                    <Tooltip title="Change your progress status">
                                                                                                                        <Progress
                                                                                                                            onClick={() => {
                                                                                                                                openChageProcessModel(
                                                                                                                                    serviceprocessId,
                                                                                                                                    action.actionName,
                                                                                                                                    action.serviceActionId
                                                                                                                                )
                                                                                                                            }}
                                                                                                                            className={
                                                                                                                                style.iconStyleSvg
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </Tooltip>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                        {action.statusId ===
                                                                                                            2 && (
                                                                                                            <Tooltip title="Skip Action">
                                                                                                                <Skip
                                                                                                                    onClick={() => {
                                                                                                                        openSkipActionModel(
                                                                                                                            serviceprocessId,
                                                                                                                            action.serviceActionId
                                                                                                                        )
                                                                                                                    }}
                                                                                                                    className={
                                                                                                                        style.iconStyleSvg
                                                                                                                    }
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Tooltip title="Not Assigned">
                                                                                                        <Denied
                                                                                                            className={
                                                                                                                style.iconStyleSvg
                                                                                                            }
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                )}

                                                                                                {action.canSwitchUser ===
                                                                                                    1 &&
                                                                                                    action.completeStatusId ===
                                                                                                        1 && (
                                                                                                        <Tooltip title="If you want to change the working user, then only use this option.">
                                                                                                            <Assigned
                                                                                                                onClick={() => {
                                                                                                                    openCardAssignUserModel(
                                                                                                                        serviceprocessId,
                                                                                                                        action.serviceActionId
                                                                                                                    )
                                                                                                                }}
                                                                                                                className={
                                                                                                                    style.iconStyleSvg
                                                                                                                }
                                                                                                            />
                                                                                                        </Tooltip>
                                                                                                    )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Space>
                                                        </div>
                                                        <div
                                                            className={
                                                                style.contentWrapper
                                                            }
                                                        >
                                                            <Image
                                                                className={
                                                                    style.imageStyle
                                                                }
                                                                width={40}
                                                                preview={false}
                                                                src={
                                                                    action.imgPath
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    style.textStyle
                                                                }
                                                            >
                                                                <p>
                                                                    <Row>
                                                                        <p
                                                                            className={
                                                                                style.pheding
                                                                            }
                                                                        >
                                                                            {
                                                                                action.actionName
                                                                            }
                                                                        </p>

                                                                        {action.actionType ===
                                                                            1 && (
                                                                            <Popover
                                                                                key={
                                                                                    actionIndex
                                                                                }
                                                                                content={
                                                                                    content
                                                                                }
                                                                                title="SupplierInfo"
                                                                                trigger="click"
                                                                                open={
                                                                                    openpop ===
                                                                                    actionIndex
                                                                                }
                                                                                onOpenChange={(
                                                                                    newOpen
                                                                                ) => {
                                                                                    handleOpenChange(
                                                                                        newOpen,
                                                                                        action.serviceActionId,
                                                                                        actionIndex
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <Infoicon
                                                                                    style={{
                                                                                        marginTop: 8,
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                />
                                                                            </Popover>
                                                                        )}
                                                                    </Row>
                                                                </p>
                                                                <p
                                                                    className={
                                                                        style.ptagStyle
                                                                    }
                                                                >
                                                                    {action.workingStaff !==
                                                                        '' &&
                                                                        (action.skippedRemarks !==
                                                                        '' ? (
                                                                            <>
                                                                                Skipped
                                                                                by
                                                                                {
                                                                                    action.workingStaff
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Assigned
                                                                                to{' '}
                                                                                {
                                                                                    action.workingStaff
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    <br />
                                                                    {action.assignedDate !==
                                                                        '1900-01-01' &&
                                                                        action.assignedDate !==
                                                                            null && (
                                                                            <>
                                                                                Assigned
                                                                                on
                                                                                {
                                                                                    action.assignedDate
                                                                                }
                                                                            </>
                                                                        )}

                                                                    {action.completeDate !==
                                                                        '1900-01-01' &&
                                                                        (action.skippedRemarks !==
                                                                        '' ? (
                                                                            <>
                                                                                <br />
                                                                                Skipped
                                                                                on{' '}
                                                                                {
                                                                                    action.completeDate
                                                                                }
                                                                                <Tooltip
                                                                                    title={
                                                                                        action.skippedRemarks
                                                                                    }
                                                                                >
                                                                                    <Infoicon />
                                                                                </Tooltip>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <br />
                                                                                Completed
                                                                                on{' '}
                                                                                {
                                                                                    action.completeDate
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    {action.remarks !==
                                                                        null &&
                                                                        action.remarks !==
                                                                            '' && (
                                                                            <Tooltip
                                                                                title={
                                                                                    action.remarks
                                                                                }
                                                                            >
                                                                                <Infoicon />
                                                                            </Tooltip>
                                                                        )}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {action.serviceProcessActionStepList?.map(
                                                                    (
                                                                        itemStep,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                itemStep.stepName
                                                                            }
                                                                            {itemStep.completeStatusId ===
                                                                            3 ? (
                                                                                <>
                                                                                    <Tooltip title="Completed">
                                                                                        <Complete
                                                                                            className={
                                                                                                style.iconStyleSvg
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                </>
                                                                            ) : itemStep.activeStatusId ===
                                                                              1 ? (
                                                                                <>
                                                                                    <Tooltip title="Change your progress status">
                                                                                        <Progress
                                                                                            onChange={() => {
                                                                                                openChageProcessModel(
                                                                                                    serviceprocessId,
                                                                                                    itemStep.stepName,
                                                                                                    itemStep.serviceActionStepId
                                                                                                )
                                                                                            }}
                                                                                            className={
                                                                                                style.iconStyleSvg
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                </>
                                                                            ) : (
                                                                                <Tooltip title="Not Assigned">
                                                                                    <Denied
                                                                                        className={
                                                                                            style.iconStyleSvg
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                            {itemStep.canSwitchUser ===
                                                                                1 &&
                                                                                itemStep.completeStatusId ===
                                                                                    1 && (
                                                                                    <Tooltip title="If you want to change the working user, then only use this option.">
                                                                                        <Assigned
                                                                                            onClick={() => {
                                                                                                openCardAssignUserModel(
                                                                                                    serviceprocessId,
                                                                                                    action.serviceActionId
                                                                                                )
                                                                                            }}
                                                                                            className={
                                                                                                style.iconStyleSvg
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} className={style.revertButtn}>
                                    <Col offset={21} span={2}>
                                        <div>
                                            <Button
                                                type="primary"
                                                size="large"
                                                danger
                                                onClick={() => {
                                                    openViodModel(
                                                        serviceprocessId
                                                    )
                                                }}
                                            >
                                                Revert All
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <div>
                                    {' '}
                                    <h3>This process has been completed. </h3>
                                    <p>
                                        You can check the
                                        <Tooltip title="Service History">
                                            <a
                                                onClick={() => {
                                                    onHistoryModelOpen(
                                                        serviceprocessId
                                                    )
                                                }}
                                            >
                                                history
                                            </a>
                                        </Tooltip>{' '}
                                        if need any clarification about this.
                                    </p>
                                    <input type="hidden" value="1" />
                                </div>
                            </>
                        )}
                    </Card>
                </>
            )}
            <Modal
                footer={null}
                onCancel={changeAuditModalStatus}
                width={'60%'}
                title={'Audit Statement'}
                open={auditStateMentModel}
            >
                <ConverCodeToReact
                    html={
                        auditdata?.description !== undefined
                            ? auditdata.description
                            : ' '
                    }
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeHistoryModalStatus}
                width={'60%'}
                title={'Service Process History'}
                open={Historymodel}
            >
                <ServiceProcessHistory serviceprocessId={serviceProcessId} />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCommunictionHistoryModalStatus}
                width={'50%'}
                title={'Commication History'}
                open={comHistorymodel}
            >
                <CommunicationHistory
                    serviceProcessId={serviceProcessId}
                    seletedButton={selectedButtonId}
                    setButtonID={setSelectedButtonId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changePhoneCallModalStatus}
                width={'35%'}
                title={'Phone Call'}
                open={PhoneCallModel}
            >
                <ToDoPhoneCallModel
                    serviceProcessId={serviceProcessId}
                    changePhoneCallModalStatus={changePhoneCallModalStatus}
                    businessTypeId={
                        serviceProcessInduvidualData?.businessTypeId
                    }
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeSMSModalStatus}
                width={'35%'}
                title={'Send SMS'}
                open={SMSModel}
            >
                <SMSModelPage
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeSMSModalStatus}
                />
            </Modal>

            <Modal
                footer={null}
                onCancel={changeMailModalStatus}
                width={'35%'}
                title={'Send Email'}
                open={mailmodel}
            >
                <MailTempalteModel
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeMailModalStatus}
                    serviceId={serviceId}
                    emailPageShow={0}
                />
            </Modal>
            <Drawer
                title={serviceProcessInduvidualData?.companyName}
                width={500}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication serviceProcessId={serviceProcessId} />
            </Drawer>
            <Modal
                footer={null}
                onCancel={changeSwitchUserModalStatus}
                width={'35%'}
                title={'Switch User'}
                open={switchUserModel}
            >
                <SwitchUserPage
                    serviceId={serviceId}
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeSwitchUserModalStatus}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeRemarksModalStatus}
                width={'45%'}
                title={'Process Remarks'}
                open={remarksModel}
            >
                {auditdata !== null && (
                    <ProcessRemarks
                        auditdata={auditdata}
                        serviceId={serviceId}
                        serviceProcessId={serviceProcessId}
                        changeModalStatus={changeRemarksModalStatus}
                        businessType={
                            serviceProcessInduvidualData?.businessTypeId
                        }
                        referenceId={serviceProcessInduvidualData?.referenceId}
                    />
                )}
            </Modal>
            <Modal
                footer={null}
                onCancel={CardAssignUserModelStatus}
                width={'45%'}
                title={'Assign User'}
                open={cardSwitchUserModel}
            >
                <ServiceProcessSwitchUserModel
                    serviceActionId={serviceActionId}
                    serviceProcessId={serviceProcessId}
                    changePhoneCallModalStatus={CardAssignUserModelStatus}
                    businessTypeId={
                        serviceProcessInduvidualData?.businessTypeId
                    }
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={ChengeProcessModelStatus}
                width={'45%'}
                title={`${actionName} - complete`}
                open={changeProcessModel}
            >
                <ChangeProgressModel
                    auditdata={auditdata}
                    ServiceProcesName={actionName}
                    serviceActionId={serviceActionId}
                    serviceProcessId={serviceProcessId}
                    changeModelState={ChengeProcessModelStatus}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={ChengeDataProcessModelStatus}
                width={'45%'}
                title={`${actionName} - complete`}
                open={changeDataProcessModel}
            >
                <ChangeDataProcessModel
                    ServiceProcesName={actionName}
                    serviceprocessId={serviceProcessId}
                    serviceActionId={serviceActionId}
                    changeModelState={ChengeDataProcessModelStatus}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={ChengeSkipAcitonModelStatus}
                width={'45%'}
                title={'Skip Action'}
                open={skipActionModel}
            >
                <SkipActionModel
                    serviceActionId={serviceActionId}
                    serviceProcessId={serviceProcessId}
                    changePhoneCallModalStatus={ChengeSkipAcitonModelStatus}
                    businessTypeId={
                        serviceProcessInduvidualData?.businessTypeId
                    }
                />
            </Modal>
            <FullWidthModal
                modalStatus={openRevetModel}
                closeModal={CloseRevetModel}
                tittle="You are about to reset all actions and steps status in this process. Click 'Yes' to continue."
                smallTittle="Warning: This action is not reversable. You will lose all your data."
                yesButtonFunction={setRevetProcess}
            />
            <Modal
                footer={null}
                onCancel={changeVoidModalStatus}
                width={'35%'}
                title={'Void this process'}
                open={voidThisModel}
            >
                <VoidThisProcess
                    changeModelState={changeVoidModalStatus}
                    serviceProcessId={serviceProcessId}
                />
            </Modal>
            <Modal
                title="Portfolio Details"
                open={portfolioModel}
                onCancel={() => {
                    setportfolioModel(!portfolioModel)
                }}
                footer={null}
                width={'50%'}
            >
                <PortfolioView
                    businessId={businessId}
                    referenceId={referenceId}
                    portfolioId={0}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): ServiceProcessPageProps => {
    return {
        serviceProcessInduvidualData:
            state.dashboardInfo.serviceProcessInduvidualData,
        serviceProcessDetailsList:
            state.dashboardInfo.serviceProcessDetailsList,
        servicedataId: state.dashboardFlag.servicedataId,
    }
}

export default connect(mapStateToProps)(ServiceProcessList)
