import React, { useState, useEffect } from 'react'
import commontStyles from '../../../../Utils/Common.less'
import CorporateConfigurationLess from '../../../../Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less'
import { Collapse, Switch, Row, Col, Modal } from 'antd'
import FullWidthModal from '../../../../Components/FullWidthModal'
import GoCardlessForm from './GoCardless/GoCardlessForm'
import GoCardlessInviteClient from './GoCardless/GoCardlessInviteClient'
import {
    getClientPortalInvitation,
    GetDirectDebitCustomer,
} from '../../../../Services/Corporate'
import { useDispatch, connect } from 'react-redux'
import {
    type CorporateFormDataInterface,
    type CorporateConfigurationInterface,
} from '../../../../Types/Client/RegisteredClients/Corporate'

const CoporateConfiguration = (
    props: CorporateConfigurationInterface
): React.ReactElement => {
    const { registeredClientCorporateConfiguration } = props
    const referenceId =
        registeredClientCorporateConfiguration?.data?.referenceId
    const enableGoCardlessFormStatus =
        referenceId !== null && referenceId !== undefined
    const [enableCustomerPortal, SetEnableCustomerPortal] = useState(false)
    const [enableGoCardlessForm, SetEnableGoCardlessForm] = useState(
        enableGoCardlessFormStatus
    )
    const [customerPortalModal, SetCustomerPortalModal] = useState(false)
    const [goCardlessModal, setGoCardLessModal] = useState(false)
    const dispatch = useDispatch()
    const businessTypeId = 1
    const [PortalStatusMessage, setCustomerPortalStatusMessage] = useState('')
    const [corporateId, setCorprateId] = useState(0)
    useEffect(() => {
        const storedCorporateData = localStorage.getItem('corporateData')
        if (storedCorporateData !== null) {
            const parsedData: CorporateFormDataInterface =
                JSON.parse(storedCorporateData)
            setCorprateId(Number(parsedData.corporateid))
        }
    }, [])
    useEffect(() => {
        if (corporateId !== 0 && corporateId !== undefined) {
            GetDirectDebitCustomer(dispatch, 1, corporateId)
        }
    }, [])

    useEffect(() => {
        setCustomerPortalStatusMessage(
            enableCustomerPortal ? 'Active' : 'Inactive'
        )
    }, [enableCustomerPortal])

    const onChangeEnableCustomerPortal = (): void => {
        SetEnableCustomerPortal(!enableCustomerPortal)
        SetCustomerPortalModal(true)
    }

    const closeCustomerPortalModal = (): void => {
        SetCustomerPortalModal(false)
        SetEnableCustomerPortal(false)
    }

    const acceptCustomerPortalModalEnable = (): void => {
        if (PortalStatusMessage === 'Active') {
            void getClientPortalInvitation(
                dispatch,
                businessTypeId,
                corporateId
            )
            SetCustomerPortalModal(false)
        } else {
            SetCustomerPortalModal(false)
        }
    }

    const onChangeGoCardlessForm = (): void => {
        SetEnableGoCardlessForm(!enableGoCardlessForm)
    }

    const cancelGoCardlessModal = (): void => {
        setGoCardLessModal(false)
    }

    const customerPortal = (): React.ReactElement => {
        return (
            <div>
                <Switch
                    onChange={onChangeEnableCustomerPortal}
                    checked={enableCustomerPortal}
                />
                &nbsp; Enable Customer Portal
            </div>
        )
    }

    const goCardless = (): React.ReactElement => {
        return (
            <div className={commontStyles.flexDirectionColumn}>
                <Row>
                    <Col span={12}>
                        <Switch
                            onChange={onChangeGoCardlessForm}
                            checked={enableGoCardlessForm}
                        />
                        &nbsp; Enable GoCardless
                    </Col>
                    {(referenceId === null || referenceId === undefined) && (
                        <Col
                            span={12}
                            className={CorporateConfigurationLess.inviteClient}
                            onClick={() => {
                                setGoCardLessModal(true)
                            }}
                        >
                            Invite Client
                        </Col>
                    )}
                </Row>
                {enableGoCardlessForm && (
                    <GoCardlessForm
                        registeredClientCorporateConfiguration={''}
                    />
                )}
            </div>
        )
    }

    return (
        <div className={commontStyles.flexDirectionRow}>
            <div
                className={
                    CorporateConfigurationLess.configurationRowWithMargin
                }
            >
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'Company House',
                            children: '',
                        },
                    ]}
                />
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'Customer Portal',
                            children: customerPortal(),
                        },
                    ]}
                />
            </div>
            <div className={CorporateConfigurationLess.configurationRow}>
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'HMRC',
                            children: '',
                        },
                    ]}
                />
                <Collapse
                    bordered={true}
                    items={[
                        {
                            key: '1',
                            label: 'GoCardless',
                            children: goCardless(),
                        },
                    ]}
                />
            </div>

            <FullWidthModal
                modalStatus={customerPortalModal}
                closeModal={closeCustomerPortalModal}
                tittle={`You are about to ${PortalStatusMessage} this client account. Click 'Yes' to continue.`}
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={acceptCustomerPortalModalEnable}
            />

            <Modal
                footer={null}
                onCancel={cancelGoCardlessModal}
                width={'30%'}
                title={'Gocardless Invite Client'}
                open={goCardlessModal}
            >
                <GoCardlessInviteClient
                    paymentOptionDropDownList={[]}
                    cancelGoCardlessModal={cancelGoCardlessModal}
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        registeredClientCorporateConfiguration:
            state.client.registeredClientCorporateConfiguration,
    }
}

export default connect(mapStateToProps)(CoporateConfiguration)
