import { Col, Radio, Row, Space, Switch, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    flag,
    isHeaderAlt,
    fixedMenu,
    fixedSize,
} from '../../Reducer/DashboardFlagReducer'

interface InitialStateInterface {
    flags: number
    isHeaderAlts: number
}

const settingsConfig = [
    {
        label: 'Fixed Header',
        description: 'header is in a fixed at all times',
        action: 'isHeaderAlt',
    },
    {
        label: 'Fixed Menu',
        description: 'left panel is fixed',
        action: 'fixedMenu',
    },
    {
        label: 'Minify Menu',
        description: 'skew nav to maximize space',
        action: 'minMenu',
    },
    {
        label: 'Hide Menu',
        description: 'roll mouse on edge to reveal',
        action: 'hideMenu',
    },
    {
        label: 'Top Menu',
        description: 'relocate left pane to top',
        action: 'topMenu',
    },
    {
        label: 'Fixed Footer',
        description: 'page footer is fixed',
        action: 'fixedFooter',
    },
]

const globalModsConfig = [
    {
        label: 'Clean Page Background',
        description: 'adds more whitespace',
        action: 'cleanPage',
    },
    {
        label: 'Hide Menu Icons',
        description: 'invisible menu icons',
        action: 'hideIcons',
    },
    { label: 'Hide Info Card', description: 'hides info card from left panel' },
    {
        label: 'Dark Menu',
        description: 'menu background is darkened',
        action: 'darkMenu',
    },
]

const SettingPage = (props: InitialStateInterface): React.ReactElement => {
    const { flags } = props
    const dispatch = useDispatch()
    const [isFlag, setFlag] = useState<boolean>(flags === 1)

    const [headerAlt, setHeaderAlt] = useState<number>(() => {
        const storedHeaderAlt = localStorage.getItem('isHeaderAlt')
        return storedHeaderAlt != null ? JSON.parse(storedHeaderAlt) : 0
    })

    const [menu, setMenu] = useState<number>(() => {
        const storedMenu = localStorage.getItem('fixedMenu')
        return storedMenu != null ? JSON.parse(storedMenu) : 0
    })

    useEffect(() => {
        localStorage.setItem('isHeaderAlt', JSON.stringify(headerAlt))
        localStorage.setItem('fixedMenu', JSON.stringify(menu))
    }, [isFlag, headerAlt, menu])

    const handleChange = (type: string, checked: boolean): void => {
        switch (type) {
            case 'flag': {
                setFlag(checked)
                dispatch(flag(checked ? 1 : 2))
                break
            }
            case 'isHeaderAlt': {
                setHeaderAlt(checked ? 0 : 1)
                dispatch(isHeaderAlt(checked ? 0 : 1))
                break
            }
            case 'fixedMenu': {
                const newMenuValue = checked ? 1 : 0
                setMenu(newMenuValue)
                dispatch(fixedMenu(newMenuValue))
                break
            }
            case 'minMenu': {
                const minMenuValue = checked ? 2 : 0
                setMenu(minMenuValue)
                dispatch(fixedMenu(minMenuValue))
                break
            }
            case 'hideMenu': {
                const hideMenuValue = checked ? 3 : 0
                setMenu(hideMenuValue)
                dispatch(fixedMenu(hideMenuValue))
                break
            }
            case 'topMenu': {
                const topMenuValue = checked ? 4 : 0
                setMenu(topMenuValue)
                dispatch(fixedMenu(topMenuValue))
                break
            }
            case 'fixedFooter': {
                const fixedFooterValue = checked ? 5 : 0
                setMenu(fixedFooterValue)
                dispatch(fixedMenu(fixedFooterValue))
                break
            }
            case 'cleanPage': {
                const cleanPageValue = checked ? 6 : 0
                setMenu(cleanPageValue)
                dispatch(fixedMenu(cleanPageValue))
                break
            }
            case 'hideIcons': {
                const hideIconsValue = checked ? 7 : 0
                setMenu(hideIconsValue)
                dispatch(fixedMenu(hideIconsValue))
                break
            }
            case 'darkMenu': {
                const darkMenuValue = checked ? 8 : 0
                setMenu(darkMenuValue)
                dispatch(fixedMenu(darkMenuValue))
                break
            }
            default:
                break
        }
    }

    const handleRadioChange = (p0: string, value: number): void => {
        dispatch(fixedSize(value))
    }

    const renderSwitchRow = (config: any, index: number): JSX.Element => (
        <Row gutter={[24, 24]} key={index}>
            <Col span={20}>
                <div style={{ fontWeight: 700 }}>{config.label}</div>
                {Boolean(config.description) && <p>{config.description}</p>}
            </Col>
            <Col span={2}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={(checked) => {
                        handleChange(config.action, checked)
                    }}
                />
            </Col>
        </Row>
    )

    return (
        <>
            <Tooltip
                placement="top"
                title={isFlag ? 'Year wise' : 'Month wise'}
            >
                <Switch
                    defaultChecked
                    onChange={(checked) => {
                        handleChange('flag', checked)
                    }}
                />
            </Tooltip>
            <h4>App Layout</h4>
            <Space direction="vertical" size={4}>
                {settingsConfig.map(renderSwitchRow)}
            </Space>

            <h4>Global Modifications</h4>
            <Space direction="vertical" size={4}>
                {globalModsConfig.map(renderSwitchRow)}
            </Space>

            <hr />

            <h4>Global Font Size</h4>
            <Row>
                <Col span={24}>
                    <Radio.Group
                        buttonStyle="solid"
                        style={{ width: '100%', textAlign: 'center' }}
                        size="small"
                        onChange={(e) => {
                            handleRadioChange('fontSize', e.target.value)
                        }}
                    >
                        <Radio.Button value={0} style={{ width: '25%' }}>
                            SM
                        </Radio.Button>
                        <Radio.Button value={1} style={{ width: '25%' }}>
                            MD
                        </Radio.Button>
                        <Radio.Button value={2} style={{ width: '25%' }}>
                            LG
                        </Radio.Button>
                        <Radio.Button value={3} style={{ width: '25%' }}>
                            XL
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>

            <div>
                <p>
                    Change root font size to affect rem values (resets on page
                    refresh)
                </p>
            </div>
            <hr />
        </>
    )
}

const mapStateToProps = (state: any): InitialStateInterface => ({
    flags: state.dashboardFlag.flag,
    isHeaderAlts: state.dashboardFlag.isHeaderAlt,
})

export default connect(mapStateToProps)(SettingPage)
