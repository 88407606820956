import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { type MobileReqestExpandView } from '../../../Types/MobileRequest'

const MobileRequestExpandView = (
    props: MobileReqestExpandView
): React.ReactElement => {
    const { expandData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Description -:{' '}
                <div className={commontStyles.expandRowValue}>
                    {expandData.description}
                </div>
            </Row>
        </div>
    )
}

export default MobileRequestExpandView
