import React, { useState } from 'react'
import { Col, Row, Input, Form, Button, Modal, Divider, Switch } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { type ProcessCheckListItem } from '../../../Types/ToDoViewAllList'
import { SetProcessCheckList } from '../../../Services/ToDoViewAllList'
import { useDispatch } from 'react-redux'
interface checclistDataProps {
    CheckListData: ProcessCheckListItem[]
    serviceProcessId: number
    processListVisible: () => void
}
const CheckListPage = (props: checclistDataProps): React.ReactElement => {
    const { CheckListData, serviceProcessId, processListVisible } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [switchValues, setSwitchValues] = useState<Record<number, boolean>>(
        {}
    )
    const [modalVisible, setModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [index, setIndex] = useState(0)
    const [active, setActive] = useState(false)
    const [buttonLoading, setButtonloading] = useState(false)
    const handleSwitchChange = (index: number, checked: boolean): void => {
        setSwitchValues({ ...switchValues, [index]: checked })
        const itemData = CheckListData[index]

        if (checked && (index === 0 || index === 1)) {
            setModalTitle(itemData.itemName)
            setIndex(index)
            setModalVisible(true)
            setActive(checked)
        } else {
            void onSavePhone(itemData, checked, index)
        }
    }
    const onSaveData = (): void => {
        const itemData = CheckListData[index]
        void onSavePhone(itemData, active, index)
    }

    const onSavePhone = async (
        datas: ProcessCheckListItem,
        checked: boolean,
        index: number
    ): Promise<void> => {
        try {
            setButtonloading(true)
            if (index === 0 || index === 1) {
                await form.validateFields()

                const formData = form.getFieldsValue()

                const dataToSave = {
                    processCheckListItemId: datas.processCheckListItemId,
                    serviceCheckListItemId: datas.serviceCheckListItemId,
                    serviceProcessId,
                    serviceCheckListStatus: checked ? 1 : 0,
                    itemType: 0,
                    remarks: formData.description ?? ' ',
                    itemName: '',
                    itemDescription: '',
                    processStatusId: 0,
                }
                await SetProcessCheckList(dispatch, dataToSave)
                    .then((result) => {
                        if (result !== null && result !== undefined) {
                            setButtonloading(false)
                        }
                    })
                    .catch(() => {})
                processListVisible()
                setModalVisible(false)
                form.setFieldsValue({
                    description: '',
                })
            } else {
                const dataToSave = {
                    processCheckListItemId: datas.processCheckListItemId,
                    serviceCheckListItemId: datas.serviceCheckListItemId,
                    serviceProcessId,
                    serviceCheckListStatus: checked ? 1 : 0,
                    itemType: 0,
                    remarks: '',
                    itemName: '',
                    itemDescription: '',
                    processStatusId: 0,
                }
                await SetProcessCheckList(dispatch, dataToSave)
                    .then((result) => {
                        if (result !== null && result !== undefined) {
                            setButtonloading(false)
                        }
                    })
                    .catch(() => {})
            }
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                >
                    {' '}
                    <div>
                        <>
                            <Row gutter={16} style={{ marginTop: '15px' }}>
                                {CheckListData?.slice(2)?.map((data, index) => (
                                    <Col span={4} key={index + 2}>
                                        <div>
                                            <Switch
                                                defaultChecked={
                                                    data?.serviceCheckListStatus ===
                                                    1
                                                }
                                                checked={
                                                    switchValues[index + 2]
                                                } // Adjusted for sliced data
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        index + 2,
                                                        checked
                                                    )
                                                }}
                                                disabled={
                                                    data?.serviceCheckListStatus ===
                                                    1
                                                }
                                            />{' '}
                                            <span
                                                style={{ marginLeft: '10px' }}
                                            >
                                                {data?.itemName}
                                            </span>
                                        </div>

                                        <div style={{ marginTop: '10px' }}>
                                            {/* Your ConverCodeToReact component can be added here */}
                                            {/* <ConverCodeToReact html={data?.itemDescription} /> */}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <div
                                style={{
                                    fontSize: '11px',
                                }}
                            >
                                This option will be automatically enabled when
                                services are active to this client. If you want
                                to remove this option you should disable the
                                service in client registration window.
                            </div>
                            {CheckListData?.map((data, index) => (
                                <>
                                    {/* For index 0 and 1, render in separate rows */}
                                    {index === 0 || index === 1 ? (
                                        <>
                                            <div style={{ marginTop: '15px' }}>
                                                <Row gutter={16} key={index}>
                                                    <Col span={1}>
                                                        <Switch
                                                            defaultChecked={
                                                                data.serviceCheckListStatus ===
                                                                1
                                                            }
                                                            checked={
                                                                switchValues[
                                                                    index
                                                                ]
                                                            }
                                                            onChange={(
                                                                checked
                                                            ) => {
                                                                handleSwitchChange(
                                                                    index,
                                                                    checked
                                                                )
                                                            }}
                                                            disabled={
                                                                data.serviceCheckListStatus ===
                                                                1
                                                            }
                                                        />
                                                    </Col>
                                                    <Col span={16}>
                                                        {data?.itemName}{' '}
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    '11px',
                                                            }}
                                                        >
                                                            {`(${convertHtmlToString(
                                                                data.itemDescription
                                                            )})`}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <Divider dashed />
                                        </>
                                    ) : null}
                                </>
                            ))}
                        </>
                    </div>
                    {/* <div>
                        This option will be automatically enabled when services
                        are active to this client. If you want to remove this
                        option you should disable the service in client
                        registration window.
                    </div> */}
                </Form>
            </div>
            <Modal
                footer={null}
                onCancel={() => {
                    setModalVisible(false)
                }}
                width={'45%'}
                title={modalTitle}
                open={modalVisible}
            >
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        size="small"
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Description "
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select your description!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Button
                                    type="primary"
                                    size="middle"
                                    onClick={onSaveData}
                                    loading={buttonLoading}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
export default CheckListPage

function convertHtmlToString(html: string): any {
    return html.replace(/<br\s*\/?>/gi, '\n').trim()
}
