/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    Card,
    Tooltip,
    Switch,
    type DatePickerProps,
} from 'antd'

import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CloudDownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetRegisteClientDetails } from '../../../Services/Common'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import hederimage from '../../../Assest/Svg/pattern-2.svg'
import {
    type ReportsData,
    type ServiceStatusReportInterface,
} from '../../../Types/Reports'
import { GetServiceStatusReport } from '../../../Services/Reports'
import { formatdate } from '../../../Utils/dateFormat'

const ServiceStatusReport = (
    props: ServiceStatusReportInterface
): React.ReactElement => {
    const { categoryDropDownList, serviceDropDownList, regiterClientDetails } =
        props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState('')
    const [noRecordsFound, setNoRecordsFound] = useState(false)
    const [selectedSatusId, setSelectedStatusId] = useState(0)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const [selectedCompanyGroupId, setSelectedCompanyGroupsId] = useState(0)
    const [selectedBusinessId, setSelectedBusinessId] = useState(0)
    const [selectedConsiderId, setSelectedConsiderId] = useState(2)
    const [isMonthwiseEnabled, setIsMonthwiseEnabled] = useState(true)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetRegisteClientDetails(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const extractMonthAndYear = (
        dateString: string
    ): {
        month: number
        year: number
    } => {
        let year = 0
        let month = 0
        if (dateString !== undefined && dateString.length > 0) {
            const dateObject = new Date(dateString)
            year = dateObject.getFullYear()
            month = dateObject.getMonth() + 1
            return { month, year }
        } else {
            return { month, year }
        }
    }

    const { month, year } = extractMonthAndYear(selectedDate)

    const getData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const datatoPost = {
                month: month ?? 0,
                year: year ?? 0,
                dateFlagId: selectedConsiderId,
                serviceId: selectedServiceId,
                flagId: selectedSatusId,
                companyTypeId: selectedBusinessId,
                clientCategoryId: selectedCompanyGroupId,
                isDateAllowed: isMonthwiseEnabled ? 1 : 0,
            }
            const reportData: ReportsData = await GetServiceStatusReport(
                dispatch,
                datatoPost
            )
            if (
                reportData?.serviceStatus !== null &&
                reportData?.serviceStatus !== undefined &&
                reportData?.serviceStatus.length > 0
            ) {
                generatePDF(reportData)
                setNoRecordsFound(false)
            } else {
                setNoRecordsFound(true)
            }
        } catch (error) {}
    }

    const handleSelectChangeStatus = (value: number): void => {
        setSelectedStatusId(value)
    }

    const handleSelectChangeService = (value: number): void => {
        setSelectedServiceId(value)
    }

    const handleSelectChangeCompanyGroup = (value: number): void => {
        setSelectedCompanyGroupsId(value)
    }

    const handleSelectChangeBusinesType = (value: number): void => {
        setSelectedBusinessId(value)
    }

    const handleSelectConsiderDate = (value: number): void => {
        setSelectedConsiderId(value)
    }
    useEffect(() => {
        form.setFieldValue('fromdate', selectedDate)
    }, [selectedDate])

    const handleSwitchChange = (checked: boolean): void => {
        setIsMonthwiseEnabled(checked)
        setSelectedDate('')
    }
    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const StatustDropdowlist = [
        {
            value: 0,
            label: 'In Progress',
        },
        {
            value: 1,
            label: 'Overdue',
        },
        {
            value: 2,
            label: 'Completed',
        },
    ]

    const DateFlagDropdowlist = [
        {
            value: 2,
            label: 'Duedate',
        },
        {
            value: 1,
            label: 'Period',
        },
    ]

    const getCompanyGroupLabel = (id: number): string => {
        if (id === 0) return 'All'
        const group = categoryDropDownList.find((item) => item.value === id)
        return group != null ? group.label : id.toString()
    }
    const generatePDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()
        const missingCompanyCategoryLabel = getCompanyGroupLabel(
            selectedCompanyGroupId
        )
        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const categoryFontSize = 10
        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop
            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text(missingCompanyCategoryLabel, textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('Service Status Report', textXPosition, subHeaderYPosition)

            const lineStartX =
                textXPosition + doc.getTextWidth('Service Status Report') + 5
            const lineEndX = lineStartX + 80
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)

            doc.setFontSize(categoryFontSize)
            doc.setTextColor(0, 0, 0)
            const formattedDate1 = formatSelectedDate(selectedDate)
            const dateYPosition = lineY + 10
            doc.text(`Month: ${formattedDate1}`, textXPosition, dateYPosition)
            const headers = [
                [
                    { content: 'Client Number', styles: { halign: 'left' } },
                    { content: 'Client', styles: { halign: 'left' } },
                    { content: 'Additional Info', styles: { halign: 'left' } },
                    { content: 'Assign To', styles: { halign: 'left' } },
                    { content: 'Assign Date', styles: { halign: 'left' } },
                    { content: 'Complete Date', styles: { halign: 'left' } },
                ],
            ]

            const rows = data?.serviceStatus?.map(
                (item: {
                    clientNumber: any
                    companyName: any
                    periodStartDate: string | undefined
                    periodEndDate: string | undefined
                    year: any
                    additionalDataTwo: any
                    assignee: any
                    assignDate: any
                    completedDate: any
                }) => [
                    item.clientNumber,
                    `${item.companyName} Period of ${formatdate(
                        item.periodStartDate
                    )} to ${formatdate(item.periodEndDate)} in ${item.year}`,
                    item.additionalDataTwo,
                    item.assignee,
                    formatdate(item.assignDate),
                    formatdate(item.completedDate),
                ]
            )

            autoTable(doc, {
                startY: subHeaderYPosition + 15,
                head: headers,
                body: rows,
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                },
                bodyStyles: {
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                },
            })
            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${rows?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }
            doc.save('service_status_report.pdf')
        }
    }
    useEffect(() => {
        form.setFieldValue('month', selectedDate ? dayjs(selectedDate) : null)
    }, [selectedDate])

    const formatSelectedDate = (dateString: string): string => {
        const date = dayjs(dateString)
        return date.format('MMMM YYYY')
    }

    return (
        <>
            <Card title=" Reports" style={{ marginTop: '20px' }}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    label={
                                        <>
                                            <div>Month</div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginTop: '0px',
                                                    marginLeft: '20px',
                                                }}
                                            >
                                                <Switch
                                                    checked={isMonthwiseEnabled}
                                                    size="small"
                                                    onChange={
                                                        handleSwitchChange
                                                    }
                                                />{' '}
                                                <span
                                                    style={{
                                                        marginLeft: '8px',
                                                    }}
                                                >
                                                    Enable Monthwise
                                                </span>
                                            </div>
                                        </>
                                    }
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={(date, dateString) => {
                                                onChange(date, dateString)
                                            }}
                                            value={
                                                selectedDate.length > 0
                                                    ? dayjs(selectedDate)
                                                    : null
                                            }
                                            className={commonStyles.dateWidth}
                                            picker="month"
                                            disabled={!isMonthwiseEnabled}
                                            format="MMMM-YYYY"
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Considered Date">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectConsiderDate}
                                        value={selectedConsiderId}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={DateFlagDropdowlist}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    label="Service"
                                    name="serviceId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select service',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeService}
                                        value={selectedServiceId}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...serviceDropDownList,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Status">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeStatus}
                                        value={selectedSatusId}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={StatustDropdowlist}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Company Group">
                                    <Select
                                        placeholder="Please select"
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={
                                            handleSelectChangeCompanyGroup
                                        }
                                        value={selectedCompanyGroupId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Business Type">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeBusinesType}
                                        value={selectedBusinessId}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...regiterClientDetails,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={22} span={2}>
                                <Tooltip title="Download Report">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={getData}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {noRecordsFound && (
                    <div style={{ textAlign: 'left', marginTop: '20px' }}>
                        <span
                            style={{
                                color: '#808080',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            No Records found
                        </span>
                    </div>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ServiceStatusReportInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
        reportStatusList: state.reports.reportStatusList,
    }
}

export default connect(mapStateToProps)(ServiceStatusReport)
