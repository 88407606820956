// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document_wHtAu {
  width: 100%;
  height: 600px;
  margin-bottom: 20px;
}
.documentuploadtext_voJuq {
  margin-top: 20px;
}
.documentupload_CDZwC {
  position: static;
  height: 200px;
}
.CorporteDocumentGeenreal_qPwiO {
  margin-bottom: 60px;
}
.CorporteDocument_mcdqs {
  margin-top: 10px;
}
.cardhight_sH14w {
  height: 350px;
}
.fileupload_IJ0J3 {
  position: absolute;
  top: 0;
  margin-top: 10px;
  margin-bottom: 40px !important;
  width: 100%;
}
.savebuttonless_DQ7QT {
  margin-top: 320px;
}
.dateWidth_pOoSB {
  position: absolute;
  width: 100%;
  top: 0px !important;
}
.imagewidth_NW6x0 {
  width: 100%;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Corporate/Buttons/Doucument/document.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAEA;EACI,gBAAA;EACA,aAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;AAFJ;AAKA;EACI,kBAAA;EACA,MAAA;EACA,gBAAA;EACA,8BAAA;EACA,WAAA;AAHJ;AAMA;EACI,iBAAA;AAJJ;AAMA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;AAJJ;AAOA;EACI,WAAA;EACA,gBAAA;AALJ","sourcesContent":[".document {\n    width: 100%;\n    height: 600px;\n    margin-bottom: 20px;\n}\n\n.documentuploadtext {\n    margin-top: 20px;\n}\n.documentupload {\n    position: static;\n    height: 200px;\n}\n\n.CorporteDocumentGeenreal {\n    margin-bottom: 60px;\n}\n.CorporteDocument {\n    margin-top: 10px;\n}\n\n.cardhight {\n    height: 350px;\n}\n\n.fileupload {\n    position: absolute;\n    top: 0;\n    margin-top: 10px;\n    margin-bottom: 40px !important;\n    width: 100%;\n}\n\n.savebuttonless {\n    margin-top: 320px;\n}\n.dateWidth {\n    position: absolute;\n    width: 100%;\n    top: 0px !important;\n}\n\n.imagewidth {\n    width: 100%;\n    margin-top: 20px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"document": `document_wHtAu`,
	"documentuploadtext": `documentuploadtext_voJuq`,
	"documentupload": `documentupload_CDZwC`,
	"CorporteDocumentGeenreal": `CorporteDocumentGeenreal_qPwiO`,
	"CorporteDocument": `CorporteDocument_mcdqs`,
	"cardhight": `cardhight_sH14w`,
	"fileupload": `fileupload_IJ0J3`,
	"savebuttonless": `savebuttonless_DQ7QT`,
	"dateWidth": `dateWidth_pOoSB`,
	"imagewidth": `imagewidth_NW6x0`
};
export default ___CSS_LOADER_EXPORT___;
