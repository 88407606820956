import { type PermissionType } from '../Types/Enum/PermissionType'
interface UserRightType {
    userGroupRightId: number
    rightId: number
    rightType: number
    rightCode: string
    saveFlag: boolean
    updateFlag: boolean
    deleteFlag: boolean
    viewFlag: boolean
    printFlag: boolean
}

const ActivePremission = (
    righcode: string | number,
    permissionType: PermissionType
): boolean => {
    const userRights = localStorage.getItem('userRights')
    const userRightsList: UserRightType[] =
        userRights !== null ? JSON.parse(userRights) : null
    // const list: any[] = [
    //     {
    //         userGroupRightId: 6361,
    //         rightId: 4,
    //         rightType: 0,
    //         rightCode: '1003',
    //         saveFlag: 1,
    //         updateFlag: 1,
    //         deleteFlag: 0,
    //         viewFlag: 1,
    //         printFlag: 1,
    //     },
    // ]

    const findPermisson =
        userRightsList?.length > 0
            ? userRightsList.find((x) => x.rightCode === righcode)
            : undefined

    if (
        findPermisson !== undefined &&
        findPermisson?.rightId !== 0 &&
        findPermisson?.rightId !== null &&
        findPermisson?.rightId !== undefined
    ) {
        return findPermisson[permissionType]
    } else {
        return false
    }
}
export default ActivePremission
