import { setLoadingStatus } from '../../Reducer/Common'
import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../../Config/AxiosConfig'
import { EventList } from '../../Reducer/HelpDesk'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { type EventlistFormDataInterface } from '../../Types/HelpDesk/ManageDiary'

export const GetEventList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Management/GetEventList`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: EventlistFormDataInterface) => {
                        return {
                            ...value,
                            key: value.eventId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(EventList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveEvent = async (
    dispatch: Dispatch,
    data: EventlistFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Management/SetEvent`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const DeleteEvent = (dispatch: Dispatch, ReferenceId: string): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/28/${ReferenceId}`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
