import React, { useEffect, useRef, useState } from 'react'
import { Button, Select, Form, Row, Col, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
import {
    type FirstPagePropss,
    type TaskFollowerRequestBody,
    type TaskFollowerRequestBodysform,
} from '../../../Types/HelpDesk/Cretetask'
import {
    Savefollowers,
    getfollwersdroplist,
} from '../../../Services/Helpdesk/CreteTask'
import { GetUserList } from '../../../Services/User'

const CreateFollowersModal = (
    props: FirstPagePropss & {
        taskid: string
        setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>
        selectedUsers: number[]
    }
): React.ReactElement => {
    const { taskid, userList, follwersList, selectedUsers, setSelectedUsers } =
        props
    const [taskfollwerId] = useState(0)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [])

    useEffect(() => {
        if (taskid !== null && taskid !== '') {
            void getfollwersdroplist(dispatch, taskid)
        }
    }, [taskid])

    useEffect(() => {
        if (Array.isArray(follwersList)) {
            const followerIds = follwersList
                ?.map(
                    (follower: TaskFollowerRequestBody) =>
                        follower?.taskFollowerId
                )
                .filter((id) => !isNaN(id)) // Ensure all IDs are valid numbers
            setSelectedUsers(followerIds)
        } else if (typeof follwersList === 'string') {
            const followerIdsStringArray = String(follwersList).split(',')
            const followerIds = followerIdsStringArray
                .map((id) => parseInt(id, 10))
                .filter((id) => !isNaN(id))
            setSelectedUsers(followerIds)
        }
    }, [follwersList, taskid])

    const handleSave = (): void => {
        form.validateFields()
            .then((values) => {
                const formattedValues = {
                    taskFollowerId: taskfollwerId,

                    taskId: taskid,
                    followerIds: selectedUsers,
                }

                Savefollowers(dispatch, formattedValues)
                    .then(() => {
                        form.resetFields()
                    })
                    .catch((error) => {
                        console.error('Failed to save followers:', error)
                    })
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo)
            })
    }

    return (
        <Form>
            <Form.Item name="followerIds">
                <Select
                    mode="multiple"
                    showSearch
                    placeholder="Please select"
                    value={selectedUsers}
                    onChange={(value) => {
                        setSelectedUsers(value)
                    }}
                    options={userList}
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                >
                    {/* {userList.map((user) => (
                        <Select.Option key={user.id} value={user.value}>
                            {user.label}
                        </Select.Option>
                    ))} */}
                </Select>

                <Row style={{ marginTop: '20px' }}>
                    <Col offset={22}>
                        <Tooltip title="Save Followers">
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={handleSave}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}

const mapStateToProps = (state: any): TaskFollowerRequestBodysform => {
    return {
        userList: state.user.userList,
        follwersList: state.helpdesk.follwersList,
        follwersListCount: state.helpdesk.follwersListCount,
    }
}

export default connect(mapStateToProps)(CreateFollowersModal)
