import { Card, Table } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import commonStyles from '../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../Utils/CommontTable'
import { connect, useDispatch } from 'react-redux'
import { type TableParamsInterface } from '../../Types/CommonType'
import calculatePagination from '../../Utils/Pagination'
import {
    type ListofHistoryPayloadInteerface,
    type ProfileHolidaytableList,
} from '../../Types/Profile'
import { GetHolidayList } from '../../Services/Profile'
import SearchInput from '../../Components/SearchInput'

const ListofHoliday = (
    props: ListofHistoryPayloadInteerface
): React.ReactElement => {
    const { userHolidayList, userHolidayListCount } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const dispatch = useDispatch()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetHolidayList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: userHolidayListCount,
            },
        })
    }, [userHolidayListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        userHolidayListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const formatdate = (date: string): string => {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()
        return `${month}/${day}/${year}`
    }
    const columns: ColumnsType<ProfileHolidaytableList> = [
        {
            title: '',
            dataIndex: 'activeStatus',
        },
        {
            title: 'Holiday',
            dataIndex: 'holidayName',
        },
        {
            title: ' Date',
            key: 'date',
            render: (record: ProfileHolidaytableList) => {
                return <div>{formatdate(record.date)}</div>
            },
        },
        {
            title: ' Description',
            dataIndex: 'description',
        },
    ]

    const onChangeTableParams: TableProps<ProfileHolidaytableList>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length !== 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'holidayName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    const userHolidayLists = userHolidayList?.map((item) => ({
        ...item,
        key: item.companyHolidayId,
    }))
    return (
        <>
            <Card title={'Holiday'}>
                <SearchInput
                    placeHolder={'Search by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={userHolidayLists}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ListofHistoryPayloadInteerface => {
    return {
        userHolidayList: state.profile.userHolidayList,
        userHolidayListCount: state.profile.userHolidayListCount,
    }
}

export default connect(mapStateToProps)(ListofHoliday)
