import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Card,
    Tooltip,
    Modal,
    Space,
    Table,
} from 'antd'
import {
    SaveOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
    EyeOutlined,
} from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import {
    SaveAttachment,
    getattachmentdroplist,
    deleteAttachment,
} from '../../../Services/Helpdesk/CreteTask'
import { type TaskAttachmentinterface } from '../../../Types/HelpDesk/Cretetask'
import S3UploadMultiple from '../../../Components/S3UploadMultiple'
import FullWidthModal from '../../../Components/FullWidthModal'
import PDFPreview from '../../../Components/PDFPreview'
import { SuccessMessage } from '../../../Utils/Notification'

export const AttachementDefaultData = {
    taskAttachmentId: '',
    taskId: '',
    attachmentFile: '',
    description: '',
}

const AttachementList = (
    props: TaskAttachmentinterface & {
        taskId: string
        resetdata: string
        setResetData: React.Dispatch<React.SetStateAction<string>>
    }
): React.ReactElement => {
    const { taskId, TaskAttachmentList, resetdata, setResetData } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const hasFetchedOnce = useRef(false)
    const [taskAttachmentId] = useState('0')
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [attachmentId, setAttachmentId] = useState('')
    const [visible, setVisible] = useState(false)
    const [url, setUrl] = useState('')
    const [previewPdf, setPdfPreview] = useState(false)
    useEffect(() => {
        form.setFieldsValue({
            taskAttachmentId: '',
            taskId: '',
            attachmentFile: '',
            description: '',
        })
    }, [taskId, dispatch])

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
        }

        setResetData('false')
    }, [resetdata])

    const getTableData = useCallback(() => {
        void getattachmentdroplist(dispatch, taskId)
    }, [taskId])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
        form.setFieldValue(name, locations)
    }

    const handleSave = (): void => {
        form.validateFields()
            .then((values) => {
                const formattedValuesArray = uploadFileList.map((file) => ({
                    ...values,
                    taskAttachmentId,
                    taskId,
                    attachmentFile: file,
                    description: values.description,
                }))
                Promise.all(
                    formattedValuesArray.map(
                        async (formattedValues) =>
                            await SaveAttachment(dispatch, formattedValues)
                    )
                )
                    .then(() => {
                        getTableData()
                        form.resetFields()
                        setUploadFileList([])
                        SuccessMessage('Task Attachment is saved successfully')
                    })
                    .catch((error) => {
                        console.error('Failed to save attachments:', error)
                    })
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo)
            })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let attachmentId = ''
        if (!deleteModalStatus) {
            attachmentId = id
        }
        setAttachmentId(attachmentId)
    }

    const deleteAttachmentDetails = (): void => {
        try {
            void deleteAttachment(dispatch, attachmentId, getTableData)
            getTableData()
        } catch (error) {
            console.error('Failed to delete attachment:', error)
        }
        deleteModalFunction('')
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const handleDownload = (fileUrl: any): void => {
        window.open(fileUrl, '_blank')
    }

    const handleViewImage = (imagePath: string): void => {
        const isPdf = imagePath !== '' && imagePath?.endsWith('.pdf')
        console.log('imagePath', imagePath)

        if (isPdf) {
            setUrl(imagePath)
            setPdfPreview(true)
            setVisible(false)
        } else {
            setUrl(imagePath)
            setVisible(true)
        }
    }
    const Servicecolumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'key',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => {
                return (
                    <Space size="middle">
                        <>
                            <Tooltip title="View">
                                <EyeOutlined
                                    onClick={() => {
                                        handleViewImage(record.attachmentFile)
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Delete">
                                <DeleteOutlined
                                    onClick={() => {
                                        deleteModalFunction(
                                            record.taskAttachmentId
                                        )
                                    }}
                                />
                            </Tooltip>
                        </>

                        <Tooltip title="Download Document">
                            <CloudDownloadOutlined
                                onClick={() => {
                                    handleDownload(record.attachmentFile)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const changeCreateEditModalStatus = (): void => {
        setPdfPreview(false)
    }
    return (
        <>
            <Card className={commonStyles.card}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        form={form}
                        onFinish={handleSave}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={12}>
                            <Col span={24}>
                                <Form.Item
                                    label="Task Attachment"
                                    name="attachmentFile"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please choose your task Attachment',
                                        },
                                    ]}
                                >
                                    <S3UploadMultiple
                                        name={'attachmentFile'}
                                        value={uploadFileList}
                                        setFormData={handleSetFormData}
                                        corporate={''}
                                        corporateid={0}
                                        documenttype={''}
                                        documentbaseId={0}
                                        memberpersonId={0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your Description!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Tooltip title="Save Attachment">
                                    <Button type="primary" htmlType="submit">
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className={commonStyles.table}>
                    {TaskAttachmentList?.length !== 0 ? (
                        <Table
                            columns={Servicecolumns}
                            dataSource={TaskAttachmentList}
                            pagination={false}
                        />
                    ) : (
                        <h4>No file uploaded.</h4>
                    )}
                </div>

                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Task Attachment Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteAttachmentDetails}
                />
                <Modal
                    width={'60%'}
                    open={visible}
                    onCancel={() => {
                        setVisible(false)
                    }}
                    footer={null}
                >
                    <img
                        src={url}
                        alt="View"
                        className={commonStyles.imagewidth}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'45%'}
                    title={''}
                    open={previewPdf}
                >
                    <PDFPreview pdfUrl={url} />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): TaskAttachmentinterface => {
    return {
        TaskAttachmentList: state.helpdesk.TaskAttachmentList,
        TaskAttachmentListCount: state.helpdesk.TaskAttachmentListCount,
    }
}

export default connect(mapStateToProps)(AttachementList)
