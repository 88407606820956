import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { type CHFillingHistoryExpandViewInterface } from '../../../Types/CompanyHouse/CHFillinfHistory'

const CHFillingHistoryExpandView = (
    props: CHFillingHistoryExpandViewInterface
): React.ReactElement => {
    const { expandData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Rejections -:{' '}
                <div className={commontStyles.expandRowValue}>{expandData}</div>
            </Row>
        </div>
    )
}

export default CHFillingHistoryExpandView
