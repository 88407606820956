import { Input, Form, Select, Button, Row, Col } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../../../Utils/Common.less'
import React, { useEffect } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import {
    GetPaymentOptionDropDownList,
    SetDirectDebitCustomer,
} from '../../../../../../Services/Individual'
import { type CorporateConfigurationInviteClientInterface } from '../../../../../../Types/Client/RegisteredClients/Individual'
import { useLocation } from 'react-router-dom'

const { TextArea } = Input

const GoCardlessInviteClient = (
    props: CorporateConfigurationInviteClientInterface
): React.ReactElement => {
    const { paymentOptionDropDownList, cancelGoCardlessModal } = props
    const dispatch = useDispatch()

    const location = useLocation()
    const individualData = location.state?.individualData
    const [form] = Form.useForm()

    useEffect(() => {
        GetPaymentOptionDropDownList(dispatch)
    }, [])

    const handleFinish = (): void => {
        const formData = form.getFieldsValue()
        const data = {
            CustomerId: 0,
            ApplicationId: 0,
            ReferenceId: individualData.indviduvalId,
            ClientCategoryId: individualData.clientcategoryid,
            BusinessTypeId: 3,
            CompanyName: '',
            GivenName: '',
            FamilyName: '',
            Email: formData.email,
            AddressLine1: '',
            AddressLine2: '',
            AddressLine3: '',
            City: '',
            PostalCode: '',
            CountryCode: '',
            Region: '',
            Language: '',
            PhoneNumber: '',
            DanishIdentityNumber: '',
            SwedishIdentityNumber: '',
            Description: formData.description ?? '',
            RegisterToken: '',
            PaymentOptionId: formData.goCardlessAccount,
        }

        void SetDirectDebitCustomer(dispatch, data, cancelGoCardlessModal)
        form.resetFields()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
                className={commonStyles.flexDirectionColumn}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please Enter Email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Gocardless Account"
                    name="goCardlessAccount"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Gocardless Account',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        placeholder="Select your payment option"
                        onChange={() => {}}
                        options={paymentOptionDropDownList}
                        showSearch
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <TextArea rows={4} />
                </Form.Item>
                <Row gutter={16}>
                    <Col offset={21} span={3}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        paymentOptionDropDownList: state.client.paymentOptionDropDownList,
    }
}

export default connect(mapStateToProps)(GoCardlessInviteClient)
