import React, { useEffect, useCallback, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Tooltip, Modal } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import type { ColumnsType } from 'antd/es/table'
import { TeamOutlined } from '@ant-design/icons'
import type {
    ServiceUserListDataTypeInterface,
    ServiceUserListInterface,
    ServiceUserPostData,
    SwitchUserFormData,
} from '../../../Types/ServiceSetup/ServiveUser'
import {
    GetServiceUserList,
    SetAssigneeUser,
    SetProcessLevelSwitchUser,
    SetWorkingUser,
} from '../../../Services/ServiceSetup'
import AddAssigneeForm from './defultAssigneeUser'
import AddWorkingUserForm from './workingUser'
import SwitchUserForm from './switchToOtherUser'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const ServiceUserList = (
    props: ServiceUserListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { serviceUserList } = props
    const [addUserModalStatus, setAddUserModalStatus] = useState(false)
    const [switchUserModalStatus, setSwitchUserModalStatus] = useState(false)
    const [createAssigneeUserStatus, setCreateAssigneeUserStatus] =
        useState(false)
    const [serviceId, setServiceId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        GetServiceUserList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const columns: ColumnsType<ServiceUserListDataTypeInterface> = [
        {
            title: 'Service Name',
            dataIndex: 'serviceName',
        },
        {
            title: 'Department / Communication',
            dataIndex: 'communicationEmail',
        },
        {
            title: 'Default Assignee User',
            key: 'action',
            render: (_, record: ServiceUserListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('2000', PermissionType.UPDATE) && (
                            <Tooltip title="Add Assignee">
                                <TeamOutlined
                                    onClick={() => {
                                        openAssigneeUserModal(record?.serviceId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                    style={{ fontSize: '20px' }}
                                />
                            </Tooltip>
                        )}
                        <div>
                            {record.defaultAssigneeUser?.map((assigneeUser) => (
                                <Tooltip
                                    title={assigneeUser.userName}
                                    key={assigneeUser.userId}
                                >
                                    <img
                                        src={assigneeUser.imageURL}
                                        alt={assigneeUser.userName}
                                        width={24}
                                        height={24}
                                    />
                                </Tooltip>
                            ))}
                        </div>
                    </Space>
                )
            },
        },
        {
            title: 'Working User',
            key: 'action',
            render: (_, record: ServiceUserListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('2000', PermissionType.UPDATE) && (
                            <Tooltip title="Add User">
                                <TeamOutlined
                                    onClick={() => {
                                        openAddWorkingUserModal(
                                            record?.serviceId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                    style={{ fontSize: '20px' }}
                                />
                            </Tooltip>
                        )}
                        <div>
                            {record.defaultUser?.map((workingUser) => (
                                <Tooltip
                                    title={workingUser.userName}
                                    key={workingUser.userId}
                                >
                                    <img
                                        src={workingUser.imageURL}
                                        alt={workingUser.userName}
                                        width={24}
                                        height={24}
                                    />
                                </Tooltip>
                            ))}
                        </div>
                    </Space>
                )
            },
        },
        {
            title: '',
            key: 'action',
            render: (_, record: ServiceUserListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Switch User">
                            <TeamOutlined
                                onClick={() => {
                                    openSwitchUserModal(record?.serviceId)
                                }}
                                className={commonStyles.clickableIcon}
                                style={{ fontSize: '20px' }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const openAddWorkingUserModal = (id: number): void => {
        setServiceId(id)
        changeAddUserModalStatus()
    }
    const changeAddUserModalStatus = (): void => {
        setAddUserModalStatus(!addUserModalStatus)
    }

    const openSwitchUserModal = (id: number): void => {
        setServiceId(id)
        switchAddUserModalStatus()
    }
    const switchAddUserModalStatus = (): void => {
        setSwitchUserModalStatus(!switchUserModalStatus)
    }

    const openAssigneeUserModal = (id: number): void => {
        setServiceId(id)
        changeAssigneeUserModalStatus()
    }

    const changeAssigneeUserModalStatus = (): void => {
        setCreateAssigneeUserStatus(!createAssigneeUserStatus)
    }

    const onSaveAssigneeUser = (
        data: ServiceUserPostData,
        callBack: CallableFunction
    ): void => {
        void onSaveAssigneeUserData(data, callBack)
    }

    const onSaveAssigneeUserData = async (
        data: ServiceUserPostData,
        callBack: CallableFunction
    ): Promise<void> => {
        await SetAssigneeUser(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeAssigneeUserModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    const onSaveAddUser = (
        data: ServiceUserPostData,
        callBack: CallableFunction
    ): void => {
        void onSaveAddUserData(data, callBack)
    }

    const onSaveAddUserData = async (
        data: ServiceUserPostData,
        callBack: CallableFunction
    ): Promise<void> => {
        await SetWorkingUser(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeAddUserModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    const onSaveSwitchUser = (
        data: SwitchUserFormData,
        callBack: CallableFunction
    ): void => {
        void onSaveSwitchUserData(data, callBack)
    }

    const onSaveSwitchUserData = async (
        data: SwitchUserFormData,
        callBack: CallableFunction
    ): Promise<void> => {
        await SetProcessLevelSwitchUser(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    switchAddUserModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card title="Service User" className={commonStyles.card}>
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={serviceUserList}
                        pagination={false}
                    />
                </div>

                <Modal
                    footer={null}
                    onCancel={changeAssigneeUserModalStatus}
                    width={'40%'}
                    title="Add Assignee"
                    open={createAssigneeUserStatus}
                >
                    <AddAssigneeForm
                        serviceId={serviceId}
                        onSave={onSaveAssigneeUser}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeAddUserModalStatus}
                    width={'40%'}
                    title="Add User"
                    open={addUserModalStatus}
                >
                    <AddWorkingUserForm
                        onSave={onSaveAddUser}
                        serviceId={serviceId}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={switchAddUserModalStatus}
                    width={'40%'}
                    title="Switch User"
                    open={switchUserModalStatus}
                >
                    <SwitchUserForm
                        onSave={onSaveSwitchUser}
                        serviceId={serviceId}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ServiceUserListInterface => {
    return {
        serviceUserList: state.serviceSetup.serviceUserList,
    }
}

export default connect(mapStateToProps)(ServiceUserList)
