import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    DatePicker,
    type DatePickerProps,
    Form,
    Input,
    Row,
    Space,
    Table,
    type TableProps,
    TimePicker,
    Tooltip,
} from 'antd'
import {
    DeleteDetails,
    GetSubTaskHours,
    SaveHours,
    SaveSubTaskHours,
    gethourssdroplist,
} from '../../../Services/Helpdesk/CreteTask'
import { connect, useDispatch } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import {
    type TaskHourssRequestBodysform,
    type TaskHours,
} from '../../../Types/HelpDesk/Cretetask'
import dayjs from 'dayjs'
import { type ColumnsType } from 'antd/es/table'
import { formatdate } from '../../../Utils/dateFormat'
import calculatePagination from '../../../Utils/Pagination'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import FullWidthModal from '../../../Components/FullWidthModal'
// import moment from 'moment'
import { CreateHoursExpandOption } from './CreateHoursExpandOption'
const defaultTaskHours = {
    taskHoursId: 0,
    taskId: 0,
    startDate: '',
    startTime: null,
    endTime: null,
    duration: '',
    description: '',
    subTaskHoursId: 0,
}

const HoursForm = (
    props: TaskHourssRequestBodysform & {
        taskid: string
        resetdata: string
        setResetData: React.Dispatch<React.SetStateAction<string>>
        areaId: number
    }
): React.ReactElement => {
    const dispatch = useDispatch()
    const { taskid, hoursList, resetdata, setResetData, areaId } = props
    const { TextArea } = Input
    const [form] = Form.useForm()
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [taskHoursId, setTaskHoursId] = useState('')
    const [editData, setEditData] = useState<TaskHours>(defaultTaskHours)
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null)
    // console.log('taskid', areaId)
    const [buttonLoading, setButtonLoading] = useState(false)

    useEffect(() => {
        void fetchHoursList()
    }, [taskid, areaId])

    useEffect(() => {
        if (editData !== null) {
            form.setFieldsValue({
                ...editData,
                startTime:
                    editData?.startTime !== null
                        ? dayjs(editData.startTime, 'HH:mm A')
                        : null, // Ensure valid format
                endTime:
                    editData?.endTime !== null
                        ? dayjs(editData.endTime, 'HH:mm A')
                        : null, // Ensure valid format
            })
            setStartTime(
                editData?.startTime !== null
                    ? dayjs(editData.startTime, 'HH:mm A')
                    : null
            ) // Ensure valid format)

            const formattedDate = dayjs(
                editData?.startDate,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('MM/DD/YYYY')
            setSelectedDate(formattedDate)
        }
    }, [editData])

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
            setSelectedDate('')
        }

        setResetData('false')
    }, [resetdata])
    const fetchHoursList = async (): Promise<void> => {
        if (areaId === 1) {
            await gethourssdroplist(dispatch, taskid, currentPage, itemsPerPage)
        } else {
            await GetSubTaskHours(dispatch, Number(taskid))
        }
    }

    const onSave = (): void => {
        void onSaveChat()
    }
    useEffect(() => {
        form.setFieldValue('startDate', selectedDate)
    }, [selectedDate])

    const onSaveChat = async (): Promise<void> => {
        try {
            await form.validateFields()
            setButtonLoading(true)
            const formData = form.getFieldsValue()

            const formattedStartTime = dayjs(formData.startTime).format(
                'hh:mm A'
            )
            const formattedEndTime = dayjs(formData.endTime).format('hh:mm A')
            const formatstartDate = dayjs(formData.startDate).format(
                'DD/MM/YYYY'
            )

            const startTime = dayjs(formData.startTime)
            const endTime = dayjs(formData.endTime)
            let durationInSeconds = endTime.diff(startTime, 'second')
            console.log('durationInSeconds', durationInSeconds)
            if (durationInSeconds < 0) {
                durationInSeconds += 24 * 3600 // Add 24 hours if end time is past midnight
            }
            let hours = Math.floor(durationInSeconds / 3600)
            let minutes = Math.floor((durationInSeconds % 3600) / 60)
            if (minutes === 59) {
                minutes = 0
                hours += 1
            }

            const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${'00'}`

            if (areaId === 1) {
                const formDataFormat = {
                    taskHoursId: formData.taskHoursId ?? 0,
                    taskId: Number(taskid),
                    startDate: formatstartDate,
                    startTime: formattedStartTime ?? '',
                    endTime: formattedEndTime ?? '',
                    duration: formattedDuration,
                    description: formData.description ?? '',
                }
                await SaveHours(dispatch, formDataFormat).then((result) => {
                    if (result !== null && result !== undefined) {
                        setButtonLoading(false)
                    }
                })
            } else {
                const DataToSave = {
                    subTaskHoursId: formData.subTaskHoursId ?? 0,
                    subTaskId: Number(taskid),
                    startDate: formatstartDate,
                    startTime: formattedStartTime ?? '',
                    endTime: formattedEndTime ?? '',
                    duration: formattedDuration,
                    description: formData.description ?? '',
                }
                await SaveSubTaskHours(dispatch, DataToSave).then((result) => {
                    if (result !== null && result !== undefined) {
                        setButtonLoading(false)
                    }
                })
            }
            form.resetFields()
            setSelectedDate('')

            await fetchHoursList()
        } catch (error) {
            console.error('Error in saving this one', error)
        }
    }
    const handleStartTimeChange = (time: dayjs.Dayjs | null): void => {
        setStartTime(time)
    }
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        hoursList.length,
        itemsPerPage,
        handlePageSizeChange
    )
    const openEditModal = (data: TaskHours): void => {
        setEditData(data)
    }

    const columns: ColumnsType<TaskHours> = [
        {
            title: 'Date',
            key: 'startDate',
            render: (records) => {
                return <div>{formatdate(records.startDate)}</div>
            },
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: any) => {
                return (
                    <Space size="small">
                        <>
                            <Tooltip title="Edit">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>

                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            areaId === 1
                                                ? record?.taskHoursId?.toString()
                                                : record?.subTaskHoursId?.toString()
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        </>
                    </Space>
                )
            },
        },
    ]
    const onChangeTableParams: TableProps<TaskHours>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let hourId = ''
        if (!deleteModalStatus) {
            hourId = id
        }
        setTaskHoursId(hourId)
    }

    const deleteDepartment = (): void => {
        const optionId = areaId === 1 ? 9 : 10
        DeleteDetails(dispatch, optionId, taskHoursId, fetchHoursList)
        deleteModalFunction('')
    }

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }
    const TaskForm = (): React.ReactElement => (
        <>
            <Form.Item name={'taskHoursId'} hidden={true}>
                <Input />
            </Form.Item>
            <Form.Item name={'subTaskHoursId'} hidden={true}>
                <Input />
            </Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="Due Date"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Due Date!',
                            },
                        ]}
                    >
                        <Space>
                            <DatePicker
                                onChange={(date, dateString) => {
                                    onChange(date, dateString)
                                }}
                                value={
                                    selectedDate.length > 0
                                        ? dayjs(selectedDate)
                                        : null
                                }
                                format="DD/MM/YYYY"
                                className={commonStyles.dateWidth}
                                disabledDate={(current) =>
                                    current !== null &&
                                    current < dayjs().startOf('day')
                                }
                            />
                        </Space>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Start Time"
                        name="startTime"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Start Time!',
                            },
                        ]}
                    >
                        <TimePicker
                            style={{ width: '100%' }}
                            format="h:mm A"
                            use12Hours
                            defaultOpenValue={dayjs()
                                .set('hour', 0)
                                .set('minute', 0)}
                            onChange={handleStartTimeChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="End Time"
                        name="endTime"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select the End Time!',
                            },
                            ({ getFieldValue }) => ({
                                async validator(_, value) {
                                    const startTime = getFieldValue('startTime')
                                    if (
                                        value !== null &&
                                        value !== undefined &&
                                        startTime !== null
                                    ) {
                                        if (
                                            value?.isAfter(startTime) === true
                                        ) {
                                            await Promise.resolve()
                                        } else {
                                            return await Promise.reject(
                                                new Error(
                                                    'End Time should be greater than Start Time!'
                                                )
                                            )
                                        }
                                    }
                                },
                            }),
                        ]}
                    >
                        <TimePicker
                            style={{ width: '100%' }}
                            format="h:mm A"
                            use12Hours
                            disabled={startTime === null}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Description',
                            },
                        ]}
                    >
                        <TextArea rows={2} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col offset={21} span={2}>
                    <Tooltip title="Save Hours">
                        <Button
                            type="primary"
                            onClick={onSave}
                            loading={buttonLoading}
                        >
                            <SaveOutlined />
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        </>
    )

    // const TaskList = (): React.ReactElement => (
    //     <div className={commonStyles.table}>
    //         <Table
    //             columns={columns}
    //             dataSource={hoursList}
    //             onChange={onChangeTableParams}
    //             pagination={pagination}
    //         />
    //     </div>
    // )

    const updateList = hoursList?.map((item) => ({
        ...item,
        key: item.taskHoursId?.toString(),
    }))

    const expandableData = (record: TaskHours): React.ReactElement => {
        return <CreateHoursExpandOption hourData={record} />
    }
    return (
        <>
            <div>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <TaskForm />
                </Form>
            </div>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={updateList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Spent Hour. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteDepartment}
            />
        </>
    )
}

const mapStateToProps = (state: any): TaskHourssRequestBodysform => {
    return {
        hoursList: state.helpdesk.hoursList,
        hoursListCount: state.helpdesk.hoursListCount,
    }
}
export default connect(mapStateToProps)(HoursForm)
