import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Table, Space, Modal, Tooltip, Switch } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { connect, useDispatch } from 'react-redux'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import FullWidthModal from '../../../Components/FullWidthModal'
import {
    type clientFormStateInterface,
    type adhocclientListDataTypeInterface,
    type ceteteclient,
} from '../../../Types/HelpDesk/Cretetask'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import {
    ActiveInactiveAdhoc,
    DeleteAdhocclientDetails,
    GetadhocclientList,
} from '../../../Services/Helpdesk/CreteTask'
import AddNewButton from '../../../Components/AddNewButton'
import ClientForm from '../../HelpDesk/createTask/CreateClient'

export const clientDefaultData: ceteteclient = {
    otherCompanyId: 0,
    title: '',
    firstName: '',
    middleName: '',
    surName: '',
    companyName: '',
    referredBy: '',
    mobileNumber: '',
    emailAddress: '',
    address1: '',
    address2: '',
    town: '',
    postCode: '',
    country: '',
    attachment: '',
    isActive: '',
}

const AdhocClientList = (
    props: clientFormStateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { createadocclientList, createadocclientListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Create Adhoc')
    const [editData, setEditData] = useState<ceteteclient>(clientDefaultData)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [otherCompanyId, setotherCompanyId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const hasFetchedOnce = useRef(false)

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetadhocclientList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: createadocclientListCount,
            },
        })
    }, [createadocclientListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        createadocclientListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let otherCompanyId = ''
        if (!activeInactiveModalStatus) {
            otherCompanyId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setotherCompanyId(otherCompanyId)
    }
    const updateActiveInactiveclient = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveAdhoc(dispatch, otherCompanyId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const columns: ColumnsType<adhocclientListDataTypeInterface> = [
        {
            title: 'Client Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Email Address',
            dataIndex: 'emailAddress',
        },
        {
            width: 300,
            title: 'Mobile Number',
            dataIndex: 'mobileNumber',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: adhocclientListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Client">
                            <EditIcon
                                className={commonStyles.clickableIcon}
                                onClick={() => {
                                    openEditModal(record.otherCompanyId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.otherCompanyId.toString(),
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete Client">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(
                                        record?.otherCompanyId.toString()
                                    )
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<adhocclientListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let otherCompanyId = ''
        if (!deleteModalStatus) {
            otherCompanyId = id
        }
        setotherCompanyId(otherCompanyId)
    }

    const deleteotherCompanyId = (): void => {
        DeleteAdhocclientDetails(dispatch, otherCompanyId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: number): void => {
        const data = createadocclientList?.find((data: ceteteclient) => {
            return data.otherCompanyId === id
        })

        let tittle = 'Create Client'
        if (id !== 0) {
            tittle = 'Edit Client'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? clientDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveUser = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        getTableData()
        callBack()
    }
    return (
        <>
            <Card
                title="Adhoc Clients"
                extra={
                    <>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                openEditModal(0)
                            }}
                        />
                    </>
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Clients by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />

                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={createadocclientList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Clients Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteotherCompanyId}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Client. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveclient}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <ClientForm onSave={onSaveUser} editData={editData} />
                </Modal>
            </Card>
        </>
    )
}
const mapStateToProps = (state: any): clientFormStateInterface => {
    return {
        countryList: state.initial.countryList,
        createadocclientList: state.helpdesk.createadocclientList,
        createadocclientListCount: state.helpdesk.createadocclientListCount,
        categoryDropDownList: state.initial.categoryDropDownList,
    }
}

export default connect(mapStateToProps)(AdhocClientList)
