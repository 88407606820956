import React from 'react'
import { Input, Form } from 'antd'
import styles from './Less/input.less'

const InputFieldPassword = (props: {
    name: string
    label: string
    placeHolder?: string
    reuireMessage: string
}): React.ReactElement => {
    const { name, label, reuireMessage, placeHolder = '' } = props
    const maxLength = label === 'PIN' ? 4 : undefined

    return (
        <Form.Item
            label={<label className={styles.inputLabel}>{label}</label>}
            name={name}
            rules={[{ required: true, message: reuireMessage }]}
        >
            <Input.Password maxLength={maxLength} placeholder={placeHolder} />
        </Form.Item>
    )
}

export default InputFieldPassword
