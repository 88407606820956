import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Col, Row, Tooltip, Tag } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { TeamOutlined } from '@ant-design/icons'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import {
    type ClientserviceCorporateList,
    type ClientServiceCorporateListStateInterface,
    type ClientserviceCeorporateTableInteface,
    type ServiceDetails,
} from '../../../../Types/ServiceSetup/ClientServiceUser'
import { type TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import SearchInput from '../../../../Components/SearchInput'
import FullWidthModal from '../../../../Components/FullWidthModal'
import {
    DeleteClientServiceUser,
    GetClientServiceCorporateList,
    SaveClientServiceUser,
} from '../../../../Services/ServiceSetup'
import calculatePagination from '../../../../Utils/Pagination'
import CorporateExpandView from './corporateListExpandView'
import AssigneeUser from './assigneeuser'
import SwitchWorkingUser from '../switchuserModel'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
const CorporteClientServiceList = (
    props: ClientserviceCorporateList
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        clientServicecorporateList,
        clientServicecorporateListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [corporateid, setCorporateId] = useState(0)
    const [flegId, setflegId] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [userType, setUserType] = useState('')
    const [createAssigneeUserStatus, setCreateAssigneeUserStatus] =
        useState(false)
    const [createWorkingUserStatus, setCreateWorkingUserStatus] =
        useState(false)
    const [refernceId, setRefernceId] = useState(0)
    const [createWorkingSwitchUserStatus, setCreateWorkingSwitchUserStatus] =
        useState(false)
    const [serviceName, setServiceName] = useState('')
    const [createAssigineSwitchUserStatus, setCreateAssigineSwitchUserStatus] =
        useState(false)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    const getColorForService = (index: number): string => {
        const colors = [
            'magenta',
            'red',
            'volcano',
            'orange',
            'gold',
            'lime',
            'green',
            'cyan',
            'blue',
            'geekblue',
            'purple',
        ]
        return colors[index % colors.length]
    }
    const chunkArray = <T,>(array: T[], size: number): T[][] => {
        const result: T[][] = []
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size))
        }
        return result
    }
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetClientServiceCorporateList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: clientServicecorporateListCount,
            },
        })
    }, [clientServicecorporateListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        clientServicecorporateListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<ClientserviceCeorporateTableInteface> = [
        {
            title: 'Client No',
            dataIndex: 'clientnumber',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Company Name',
            dataIndex: 'companyname',
        },
        {
            title: 'Services',
            dataIndex: 'serviceIds',
            render: (serviceIds: string) => {
                const services = serviceIds.split(',')
                const rows = chunkArray(services, 4)
                return serviceIds.length > 0 ? (
                    <div>
                        {rows?.map((row, rowIndex) => (
                            <div key={rowIndex}>
                                {row?.map((service, index) => (
                                    <Tag
                                        color={getColorForService(index)}
                                        key={index}
                                    >
                                        {service}
                                    </Tag>
                                ))}
                            </div>
                        ))}
                    </div>
                ) : (
                    ''
                )
            },
        },
        {
            title: 'Assignee User',
            key: 'action1',
            render: (_, record: ClientserviceCeorporateTableInteface) => {
                const services = record.defaultAssigneeUser.split(',')
                const rows = chunkArray(services, 4)
                return (
                    <>
                        {record.defaultAssigneeUser.length > 0 && (
                            <div>
                                {rows?.map((row, rowIndex) => (
                                    <div key={rowIndex}>
                                        {row?.map((service, index) => (
                                            <>
                                                <Tooltip title="swap Assignee user">
                                                    <Tag
                                                        color={getColorForService(
                                                            index
                                                        )}
                                                        onClick={() => {
                                                            openAssigineSwitchUserModal(
                                                                record?.corporateid,
                                                                service
                                                            )
                                                        }}
                                                        key={index}
                                                    >
                                                        {service}
                                                    </Tag>
                                                </Tooltip>
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                        <Space size="middle">
                            {ActivePremission(
                                '2001',
                                PermissionType.UPDATE
                            ) && (
                                <Tooltip title="Add Assignee User">
                                    <TeamOutlined
                                        onClick={() => {
                                            openAssigneeUserModal(
                                                record?.corporateid
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                        style={{ fontSize: '20px' }}
                                    />
                                </Tooltip>
                            )}
                            {ActivePremission('2001', PermissionType.DELETE) &&
                                record.defaultAssigneeUser.length > 0 && (
                                    <Tooltip title="Delete Assignee User">
                                        <DeleteIcon
                                            onClick={() => {
                                                deleteModalFunction(
                                                    record?.corporateid,
                                                    1
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                )}
                        </Space>
                    </>
                )
            },
        },
        {
            title: 'Working User',
            key: 'action1',
            render: (_, record: ClientserviceCeorporateTableInteface) => {
                const services = record.defaultUser.split(',')
                const rows = chunkArray(services, 4)
                return (
                    <>
                        {record.defaultUser.length > 0 && (
                            <div>
                                {rows?.map((row, rowIndex) => (
                                    <div key={rowIndex}>
                                        {row?.map((service, index) => (
                                            <>
                                                <Tooltip title="swap working user">
                                                    <Tag
                                                        color={getColorForService(
                                                            index
                                                        )}
                                                        key={index}
                                                        onClick={() => {
                                                            openWorkingSwitchUserModal(
                                                                record?.corporateid,
                                                                service
                                                            )
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {service}
                                                    </Tag>
                                                </Tooltip>
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                        <Space size="middle">
                            {ActivePremission(
                                '2001',
                                PermissionType.UPDATE
                            ) && (
                                <Tooltip title="Add Working User">
                                    <TeamOutlined
                                        onClick={() => {
                                            openWorkingUserModal(
                                                record?.corporateid
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                        style={{ fontSize: '20px' }}
                                    />
                                </Tooltip>
                            )}
                            {ActivePremission('2001', PermissionType.DELETE) &&
                                record.defaultUser.length > 0 && (
                                    <Tooltip title="Delete Working User">
                                        <DeleteIcon
                                            onClick={() => {
                                                deleteModalFunction(
                                                    record?.corporateid,
                                                    2
                                                )
                                            }}
                                        />
                                    </Tooltip>
                                )}
                        </Space>
                    </>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ClientserviceCeorporateTableInteface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }
    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: number, flegId: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let corporateid = 0
        if (!deleteModalStatus) {
            corporateid = id
        }
        setflegId(flegId)
        let title = 'Working User'
        if (flegId === 1) {
            title = 'Assignee User'
        }
        setUserType(title)
        setCorporateId(corporateid)
    }

    const deleteCorporate = (): void => {
        DeleteClientServiceUser(dispatch, flegId, 1, corporateid, getTableData)
        deleteModalFunction(0, 0)
    }

    const onSaveUser = (
        data: ServiceDetails,
        callBack: CallableFunction
    ): void => {
        void saveHolidayData(data, callBack)
    }

    const saveHolidayData = async (
        data: ServiceDetails,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveClientServiceUser(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    getTableData()
                    callBack()
                    setCreateAssigneeUserStatus(false)
                    setCreateWorkingUserStatus(false)
                    setCreateWorkingSwitchUserStatus(false)
                    setCreateAssigineSwitchUserStatus(false)
                }
            })
            .catch(() => {})
    }

    const openAssigneeUserModal = (id: number): void => {
        setRefernceId(id)
        changeAssigneeUserModalStatus()
    }

    const changeAssigneeUserModalStatus = (): void => {
        setCreateAssigneeUserStatus(!createAssigneeUserStatus)
    }

    const openWorkingUserModal = (id: number): void => {
        setRefernceId(id)
        changeworkingUserModalStatus()
    }

    const changeworkingUserModalStatus = (): void => {
        setCreateWorkingUserStatus(!createWorkingUserStatus)
    }

    const openWorkingSwitchUserModal = (id: number, name: string): void => {
        const namesplit = name.split('-')
        setServiceName(namesplit[0])
        setRefernceId(id)
        changeworkingSwitchUserModalStatus()
    }

    const changeworkingSwitchUserModalStatus = (): void => {
        setCreateWorkingSwitchUserStatus(!createWorkingSwitchUserStatus)
    }

    const openAssigineSwitchUserModal = (id: number, name: string): void => {
        const namesplit = name.split('-')
        setServiceName(namesplit[0])
        setRefernceId(id)
        setCreateAssigineSwitchUserStatus(!createAssigineSwitchUserStatus)
    }

    const changeAssigneSwitchUserModalStatus = (): void => {
        setCreateAssigineSwitchUserStatus(!createAssigineSwitchUserStatus)
    }
    const expandableData = (
        record: ClientserviceCeorporateTableInteface
    ): React.ReactElement => {
        return <CorporateExpandView CorporateData={record} />
    }
    const [showMessage, setShowMessage] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(true)
        }, 3000)

        // Clear the timer if the component unmounts
        return () => {
            clearTimeout(timer)
        }
    }, [])
    return (
        <>
            <Card className={commonStyles.card}>
                <Row gutter={16} align="middle">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Col span={16}>
                            <SearchInput
                                placeHolder={
                                    'Search CompanyName & Client No  by keywords'
                                }
                                onSearch={onChangeFilter}
                                onChange={onChangeText}
                                width="478"
                            />
                        </Col>
                    </div>
                </Row>
                <div className={commonStyles.table}>
                    {clientServicecorporateList.length > 0 ? (
                        <Table
                            columns={columns}
                            dataSource={clientServicecorporateList}
                            onChange={onChangeTableParams}
                            pagination={pagination}
                            expandable={{
                                expandedRowRender: expandableData,
                                rowExpandable: () => true,
                            }}
                        />
                    ) : (
                        <div>
                            {showMessage ? (
                                <h4>No matching data available</h4>
                            ) : (
                                <h4>Loading data...</h4>
                            )}
                        </div>
                    )}
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={() => {
                        deleteModalFunction(0, 0)
                    }}
                    tittle={`You are about to delete ${userType}. Click 'Yes' to continue.`}
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteCorporate}
                />
                <Modal
                    footer={null}
                    onCancel={changeAssigneeUserModalStatus}
                    width={'40%'}
                    title="Switch User"
                    open={createAssigneeUserStatus}
                >
                    <AssigneeUser
                        flegId={1}
                        referenceId={refernceId}
                        businessTypeId={1}
                        onSave={onSaveUser}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeworkingUserModalStatus}
                    width={'40%'}
                    title="Switch User"
                    open={createWorkingUserStatus}
                >
                    <AssigneeUser
                        flegId={2}
                        referenceId={refernceId}
                        businessTypeId={1}
                        onSave={onSaveUser}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeworkingSwitchUserModalStatus}
                    width={'40%'}
                    title="Switch User"
                    open={createWorkingSwitchUserStatus}
                >
                    <SwitchWorkingUser
                        flegId={2}
                        referenceId={refernceId}
                        businessTypeId={1}
                        onSave={onSaveUser}
                        serviceName={serviceName}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeAssigneSwitchUserModalStatus}
                    width={'40%'}
                    title="Switch User"
                    open={createAssigineSwitchUserStatus}
                >
                    <SwitchWorkingUser
                        flegId={1}
                        referenceId={refernceId}
                        businessTypeId={1}
                        onSave={onSaveUser}
                        serviceName={serviceName}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (
    state: any
): ClientServiceCorporateListStateInterface => {
    return {
        clientServicecorporateList:
            state.serviceSetup.clientServicecorporateList,
        clientServicecorporateListCount:
            state.serviceSetup.clientServicecorporateListCount,
    }
}

export default connect(mapStateToProps)(CorporteClientServiceList)
