import { Button, Col, Form, Row, Select, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    getBankInfo,
    SetInvoiceStatus,
} from '../../../../Services/Invoicing/Invoice'
// import Money from '../../../../Assest/Svg/DashboardTodoMoney.svg'
import { type ToBeRaisedForm } from '../../../../Types/Invoicing/Invoice'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'

const PaidByBank = (props: ToBeRaisedForm): React.ReactElement => {
    const [form] = Form.useForm()
    const { GetBankInfo, record, onTableChange } = props
    const dispatch = useDispatch()

    useEffect(() => {
        getBankInfo(dispatch)
    }, [dispatch])

    const onFinish = (values: any): void => {
        try {
            const data = {
                invoicePaymentStatusId: 0,
                serviceProcessId: record.serviceProcessId ?? 0,
                currentStatus: '5',
                Description: values.description ?? '',
                PaymentStatus: 'Paid',
                PaymentOptionId: parseInt(values.payment ?? '0', 10),
            }
            void SetInvoiceStatus(dispatch, data)
            onTableChange()
            form.resetFields()
        } catch (error) {
            void message.error('Failed to submit form: ')
        }
    }

    return (
        <div>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Payment Received" name="payment">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                dropdownRender={(menu) => <div>{menu}</div>}
                                options={GetBankInfo?.map(
                                    (bank: {
                                        id: number
                                        displayName: string
                                        additionalDataOne: string
                                        displayImageURL: string
                                    }) => ({
                                        value: bank.id,
                                        label: (
                                            <div>
                                                {/* <img
                                                    src={Money}
                                                    style={{
                                                        width: 20,
                                                        marginLeft: 10,
                                                    }}
                                                /> */}
                                                <span>{`${bank.displayName} - ${bank.additionalDataOne}`}</span>
                                            </div>
                                        ),
                                    })
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                {ActivePremission('6000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        GetBankInfo: state.invoice.GetBankInfo ?? [],
    }
}

export default connect(mapStateToProps)(PaidByBank)
