import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type ServicePayloadInterface,
    type ServiceListViewType,
} from '../Types/ServiceSetup/ServiveUser'
import {
    type ClientserviceSoltraderPayloadInteface,
    type ClientserviceCorporatePayloadInteface,
    type ClientServiceCorporateRegistrationViewDefaultUser,
    type SoleTraderData,
    type ClientservicePartnershipPayloadInteface,
    type ClientserviceIndividualPayloadInteface,
    type IndividualData,
    type PartnershipData,
} from '../Types/ServiceSetup/ClientServiceUser'
import { type ListFrontEndInterface } from '../Types/CommonType'

interface BusinessSetupStateInterface {
    serviceUserList: ServiceListViewType[]
    clientServicecorporateList: ClientServiceCorporateRegistrationViewDefaultUser[]
    clientServicecorporateListCount: number
    clientserviceSoltraderList: SoleTraderData[]
    clientserviceSoltraderListCount: number
    clientservicePartnershipList: PartnershipData[]
    clientservicePartnershipListCount: number
    clientserviceIndividualList: IndividualData[]
    clientserviceIndividualListCount: number
    ClientserviceUserList: ListFrontEndInterface[]
}

const BusinessSetup = createSlice({
    name: 'serviceSetup',
    initialState: {
        serviceUserList: [],
        clientServicecorporateList: [],
        clientServicecorporateListCount: 0,
        clientserviceSoltraderList: [],
        clientserviceSoltraderListCount: 0,
        clientservicePartnershipList: [],
        clientservicePartnershipListCount: 0,
        clientserviceIndividualList: [],
        clientserviceIndividualListCount: 0,
        ClientserviceUserList: [],
    },
    reducers: {
        serviceUserList(
            state: BusinessSetupStateInterface,
            action: PayloadAction<ServicePayloadInterface>
        ) {
            state.serviceUserList = action?.payload.data
        },
        clientServicecorporateList(
            state: BusinessSetupStateInterface,
            action: PayloadAction<ClientserviceCorporatePayloadInteface>
        ) {
            state.clientServicecorporateList = action?.payload?.data
            state.clientServicecorporateListCount = action?.payload?.noOfRecords
        },
        clientserviceSoltraderList(
            state: BusinessSetupStateInterface,
            action: PayloadAction<ClientserviceSoltraderPayloadInteface>
        ) {
            state.clientserviceSoltraderList = action?.payload?.data
            state.clientserviceSoltraderListCount = action?.payload?.noOfRecords
        },
        clientservicePartnershipList(
            state: BusinessSetupStateInterface,
            action: PayloadAction<ClientservicePartnershipPayloadInteface>
        ) {
            state.clientservicePartnershipList = action?.payload?.data
            state.clientservicePartnershipListCount =
                action?.payload?.noOfRecords
        },
        clientserviceIndividualList(
            state: BusinessSetupStateInterface,
            action: PayloadAction<ClientserviceIndividualPayloadInteface>
        ) {
            state.clientserviceIndividualList = action?.payload?.data
            state.clientserviceIndividualListCount =
                action?.payload?.noOfRecords
        },
        ClientserviceUserList(
            state: BusinessSetupStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.ClientserviceUserList = action?.payload
        },
    },
})

export const {
    serviceUserList,
    clientServicecorporateList,
    clientserviceSoltraderList,
    clientservicePartnershipList,
    clientserviceIndividualList,
    ClientserviceUserList,
} = BusinessSetup.actions

export default BusinessSetup.reducer
