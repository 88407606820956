import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    DeleteEmailTemplate,
    GetEmailTemplateDetailsList,
    ActiveInactiveEmailTemplate,
} from '../../../Services/EmailTemplateService'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import {
    type EmailTemplateDetailsListInterface,
    type EmailTemplateDetailsListDataTypeInterface,
} from '../../../Types/EmailTemplate'
import { useNavigate } from 'react-router-dom'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const EmailTemplateItemDefaultData = {
    templateId: '0',
    templateName: '',
    templateType: 0,
    templateTypeName: '',
    subject: '',
    emailContent: '',
    activeStatus: '',
    isCommunicationEmail: 0,
    isFormAmendmentEmail: 0,
}

const EmailTemplateList = (
    props: EmailTemplateDetailsListInterface
): React.ReactElement => {
    const columns: ColumnsType<EmailTemplateDetailsListDataTypeInterface> = [
        {
            title: 'Template Name',
            dataIndex: 'templateName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Template Type',
            dataIndex: 'templateTypeName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: EmailTemplateDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1008', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Email Template Details">
                                <EditIcon
                                    onClick={() => {
                                        addEditPage(record?.templateId)
                                    }}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record?.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.templateId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1008', PermissionType.DELETE) && (
                            <Tooltip title="Delete Email TemplateDetails">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(record?.templateId)
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { emailTemplateDetailsList, emailTemplateDetailsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [emailTemplateDetailsId, setEmailDetailsId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetEmailTemplateDetailsList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: emailTemplateDetailsListCount,
            },
        })
    }, [emailTemplateDetailsListCount])

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let templateId = ''
        if (!activeInactiveModalStatus) {
            templateId = id
        }

        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setEmailDetailsId(templateId)
    }

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const onChangeTableParams: TableProps<EmailTemplateDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'templateName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let templateDetailsId = ''
        if (!deleteModalStatus) {
            templateDetailsId = id
        }
        setEmailDetailsId(templateDetailsId)
    }

    const deleteData = (): void => {
        DeleteEmailTemplate(dispatch, emailTemplateDetailsId, getTableData)
        deleteModalFunction('')
    }

    const updateActiveInactiveEmailTemplate = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveEmailTemplate(
            dispatch,
            emailTemplateDetailsId,
            status,
            getTableData
        )
        activeInactiveModalFunction('', '')
    }

    const addEditPage = (id: string): void => {
        const emailTemplateId = id
        navigate(`/system-configuration/view-template`, {
            state: { emailTemplateId },
        })
    }

    return (
        <>
            <div>
                <h2>Email Template</h2>
            </div>
            <Card
                title="Email Template Details"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            addEditPage('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Email Template by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={emailTemplateDetailsList}
                        onChange={onChangeTableParams}
                        pagination={tableParams.pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Email-Template. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveEmailTemplate}
                />
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Email-Template Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteData}
                />
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): EmailTemplateDetailsListInterface => {
    return {
        emailTemplateDetailsList: state.initial.emailTemplateDetailsList,
        emailTemplateDetailsListCount:
            state.initial.emailTemplateDetailsListCount,
    }
}
export default connect(mapStateToProps)(EmailTemplateList)
