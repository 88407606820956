import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Button, Form, Input, Card, Tooltip } from 'antd'
import {
    SaveOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import {
    SaveAttachment,
    getattachmentdroplist,
    deleteAttachment,
} from '../../../Services/Helpdesk/CreteTask'
import { type TaskAttachmentinterface } from '../../../Types/HelpDesk/Cretetask'
import pdfPlaceholder from '../../../Assest/Svg/Pdf.svg'
import S3UploadMultiple from '../../../Components/S3UploadMultiple'

export const AttachementDefaultData = {
    taskAttachmentId: '',
    taskId: '',
    attachmentFile: '',
    description: '',
}

const AttachementList = (
    props: TaskAttachmentinterface & { taskId: string }
): React.ReactElement => {
    const { taskId, TaskAttachmentList } = props
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const hasFetchedOnce = useRef(false)
    const [taskAttachmentId] = useState('0')

    useEffect(() => {
        form.setFieldsValue({
            taskAttachmentId: '',
            taskId: '',
            attachmentFile: '',
            description: '',
        })
    }, [taskId, dispatch])

    const getTableData = useCallback(() => {
        void getattachmentdroplist(dispatch, taskId)
    }, [taskId])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
        form.setFieldValue(name, locations)
    }
    const toggleEditMode = (): void => {
        setEditMode(!editMode)
        form.resetFields()
    }
    const handleSave = (): void => {
        form.validateFields()
            .then((values) => {
                const formattedValuesArray = uploadFileList.map((file) => ({
                    ...values,
                    taskAttachmentId,
                    taskId,
                    attachmentFile: file,
                    description: values.description,
                }))
                Promise.all(
                    formattedValuesArray.map(
                        async (formattedValues) =>
                            await SaveAttachment(dispatch, formattedValues)
                    )
                )
                    .then(() => {
                        setEditMode(false)
                        getTableData()
                    })
                    .catch((error) => {
                        console.error('Failed to save attachments:', error)
                    })
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo)
            })
    }

    const handleDelete = (attachmentId: string): void => {
        try {
            void deleteAttachment(dispatch, attachmentId, getTableData)
            getTableData()
        } catch (error) {
            console.error('Failed to delete attachment:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const handleDownload = (fileUrl: any): void => {
        window.open(fileUrl, '_blank')
    }
    return (
        <>
            <Card
                extra={<AddNewButton clickAction={toggleEditMode} />}
                className={commonStyles.card}
            >
                {editMode ? (
                    <div className={commonStyles.formWrapper}>
                        <Form
                            form={form}
                            onFinish={handleSave}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            requiredMark={customizeRequiredMark}
                        >
                            <Row gutter={12}>
                                <Col span={24}>
                                    <Form.Item
                                        label="AttachmentFile"
                                        name="attachmentFile"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your AttachmentFile!',
                                            },
                                        ]}
                                    >
                                        <S3UploadMultiple
                                            name={'attachmentFile'}
                                            value={uploadFileList}
                                            setFormData={handleSetFormData}
                                            corporate={''}
                                            corporateid={0}
                                            documenttype={''}
                                            documentbaseId={0}
                                            memberpersonId={0}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your description!',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col offset={22} span={2}>
                                    <Tooltip title="Save Attachment">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            <SaveOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                ) : (
                    <Row gutter={[16, 16]}>
                        {TaskAttachmentList.map((attachment) => {
                            const isPdf =
                                attachment.attachmentFile !== '' &&
                                attachment.attachmentFile.endsWith('.pdf')
                            return (
                                <Col
                                    key={attachment.taskAttachmentId}
                                    // xs={14}
                                    // sm={12}
                                    // md={8}
                                    // lg={6}
                                    // xl={4}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Card
                                        hoverable
                                        className={commonStyles.cardMargin}
                                        style={{ width: 300, height: 250 }}
                                        cover={
                                            <img
                                                alt="attachment"
                                                src={
                                                    isPdf
                                                        ? pdfPlaceholder
                                                        : attachment.attachmentFile
                                                }
                                                style={{
                                                    maxWidth: '300px',
                                                    maxHeight: '200px',
                                                    height: 'auto',
                                                }}
                                            />
                                        }
                                    >
                                        <div style={{ textAlign: 'center' }}>
                                            {attachment.description}
                                            <Tooltip title="Download Attachment">
                                                <Button
                                                    type="link"
                                                    icon={
                                                        <CloudDownloadOutlined />
                                                    }
                                                    onClick={() => {
                                                        handleDownload(
                                                            attachment.attachmentFile
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Delete Attachment">
                                                <Button
                                                    type="link"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => {
                                                        handleDelete(
                                                            attachment.taskAttachmentId
                                                        )
                                                    }}
                                                    style={{ color: 'red' }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): TaskAttachmentinterface => {
    return {
        TaskAttachmentList: state.helpdesk.TaskAttachmentList,
        TaskAttachmentListCount: state.helpdesk.TaskAttachmentListCount,
    }
}

export default connect(mapStateToProps)(AttachementList)
