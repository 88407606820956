import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import { clientDropDownList } from '../Reducer/InitialReducer'
import {
    CDClientCommLogList,
    CDInvoiceList,
    CDStaffCommLogList,
    CDTaskList,
    CDashboradServiceProcessList,
    clientDashbordList,
} from '../Reducer/GlobelSearchClient'
import { type StaffChatData } from '../Types/GlobelSearchClient/GlobelSearchClient'

export const GetCilentList = (
    dispatch: Dispatch,
    searchString: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingTwo/1/${searchString}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(clientDropDownList(response.data.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCilent = (
    dispatch: Dispatch,
    businessId: number,
    refernceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Overview/GetClientDashboard/${businessId}/${refernceId}/1`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(clientDashbordList(response?.data?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCilentServiseProcessList = (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number,
    serviceId: number,
    processStatusId: number,
    searchContent: string,
    pageNo: number,
    pageSize: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Overview/GetClientServiceDetail/${businessTypeId}/${referenceId}/${serviceId}/${processStatusId}/${searchContent}/${pageNo}/${pageSize}/0`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(CDashboradServiceProcessList(response?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCilentInvoiceList = (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number,
    flagId: number,
    searchContent: string,
    pageNo: number,
    pageSize: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Overview/GetClientInvoiceDetail/${businessTypeId}/${referenceId}/${flagId}/${searchContent}/${pageNo}/${pageSize}/0`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(CDInvoiceList(response?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCilentTaskList = (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number,
    flagId: number,
    searchContent: string,
    pageNo: number,
    pageSize: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Overview/GetClientTaskDetail/${businessTypeId}/${referenceId}/${flagId}/${searchContent}/${pageNo}/${pageSize}/0`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(CDTaskList(response?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCilentCommLogList = (
    dispatch: Dispatch,
    communicationTypeId: number,
    businessTypeId: number,
    referenceId: number,
    searchContent: string,
    pageNo: number,
    pageSize: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Overview/GetClientCommunicationDetail/${communicationTypeId}/${businessTypeId}/${referenceId}/${searchContent}/${pageNo}/${pageSize}/0`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(CDClientCommLogList(response?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetStaffCommLogList = (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number,
    searchContent: string,
    pageNo: number,
    pageSize: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Overview/GetClientStaffCommunicationDetail/${businessTypeId}/${referenceId}/${searchContent}/${pageNo}/${pageSize}/0`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(CDStaffCommLogList(response?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveStaffCommData = async (
    dispatch: Dispatch,
    data: StaffChatData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Overview/SetStaffOverviewChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const GetEmailPreviewData = async (
    dispatch: Dispatch,
    emailId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/Overview/GetClientCommunicationAttachment/${emailId}`)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                return response?.data
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const GetContacPersonDetalils = async (
    dispatch: Dispatch,
    businessId: number,
    referenceId: number,
    getDetails: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetClientContactInfo/${businessId}/${referenceId}`)
        .then((response) => {
            if (response.status === 200) {
                const details = response.data.data
                getDetails(details)
                // SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveChatClientStaffChat = async (
    dispatch: Dispatch,
    data: StaffChatData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetProcessChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
