import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { type InvoicePrepartionExpandViewInterface } from '../../../Types/Invoicing/Invoice'

const InvoicePreparationExpandView = (
    props: InvoicePrepartionExpandViewInterface
): React.ReactElement => {
    const { InvoiceData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                <b>Description</b> :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {InvoiceData.description}
                </div>
            </Row>
        </div>
    )
}

export default InvoicePreparationExpandView
