import React from 'react'
import { Col, Row } from 'antd'
import { type PensionViewPagePropData } from '../../../../../../Types/Client/RegisteredClients/SoleTrader'

export const PensionDefultData = {
    pensionId: 0,
    companyType: 2,
    referenceId: 0,
    letterCode: '',
    stagingDate: '01/01/1900',
    pensionProvider: '',
    hmrcDate: '01/01/1900',
    nestEmployerId: '',
    nestUserName: '',
    nestPassword: '',
    employerPW: '',
    delegateId: '',
    primaryDelegateUserId: '',
    primaryDelegatePassword: '',
    primaryDelegatePIN: '',
    status: '',
}

const PensionviewPage = (
    props: PensionViewPagePropData
): React.ReactElement => {
    const { PensionServiceData } = props

    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Letter Code</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PensionServiceData?.letterCode}</p>
                </Col>
                <Col span={6}>
                    <p>Employer NEST Password</p>
                </Col>
                <Col span={6}>
                    <p>: {PensionServiceData?.employerPW}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Staging Date</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PensionServiceData?.stagingDate}</p>
                </Col>
                <Col span={6}>
                    <p>NEST Employer PW</p>
                </Col>
                <Col span={6}>
                    <p>: {PensionServiceData?.nestPassword}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Pension Providers</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PensionServiceData?.pensionProvider}</p>
                </Col>
                <Col span={6}>
                    <p>NEST Delegate ID</p>
                </Col>
                <Col span={6}>
                    <p>: {PensionServiceData?.delegateId}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>HMRC Date </p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PensionServiceData?.hmrcDate}</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>Primary Delegate ID</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{PensionServiceData?.primaryDelegateUserId}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>NEST Employer ID</p>
                </Col>
                <Col span={6}>
                    <p>: {PensionServiceData?.nestEmployerId}</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>Primary Delegate Password</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PensionServiceData?.primaryDelegatePassword}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Employer NEST Login</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {PensionServiceData?.nestUserName}</p>
                </Col>
                <Col span={6}>
                    <p>Primary Delegate Pin</p>
                </Col>
                <Col span={6}>
                    <p>: {PensionServiceData?.primaryDelegatePIN}</p>
                </Col>
            </Row>
        </>
    )
}

export default PensionviewPage
