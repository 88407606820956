import React from 'react'
import { Button } from 'antd'
import styles from './Less/Commonbutton.less'

interface AddNewButtonInterface {
    name?: string
    isActive: boolean
    clickAction: () => void
    additionalOnClick: () => void
}

const CommonButton = (props: AddNewButtonInterface): React.ReactElement => {
    const { name = 'Add New', clickAction, additionalOnClick, isActive } = props
    const handelClick = (): void => {
        clickAction()
        additionalOnClick()
    }

    return (
        <Button
            type={isActive ? 'primary' : 'default'}
            onClick={handelClick}
            className={`${styles.button} ${isActive ? styles.clicked : ''}`}
        >
            {name}
        </Button>
    )
}

export default CommonButton
