// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suplerdatabox_iYOMT {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.lable_kgNrE {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/Partnership/Buttons/SupplerInfo/supplerinfo.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AACA;EACI,iBAAA;AACJ","sourcesContent":[".suplerdatabox {\n    margin-top: 40px;\n    margin-bottom: 20px;\n    margin-left: 10px;\n}\n.lable {\n    margin-left: 10px;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suplerdatabox": `suplerdatabox_iYOMT`,
	"lable": `lable_kgNrE`
};
export default ___CSS_LOADER_EXPORT___;
