import {
    Button,
    Card,
    Col,
    ConfigProvider,
    Form,
    Row,
    Select,
    type SelectProps,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { ToTopOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import axios from '../../../Config/AxiosConfig'
import styles from '../../../Layouts/Less/style.less'
import { useNavigate } from 'react-router-dom'
const CompanyOverView = (): React.ReactElement => {
    const [form] = Form.useForm()
    const [searchValue, setSearchValue] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<SelectProps['options']>([])
    const navigate = useNavigate()
    const [ClientId, setClientId] = useState('')
    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/9/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={styles.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div className={styles.nameAndDetails}>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan2
                                            }
                                        >
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    // ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
                //
            })
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const onChangeSearch = (value: string): void => {
        if (value !== undefined) {
            setClientId(value)
            // setSearchValue('')
        }
    }
    const HandelButtonCLick = async (): Promise<void> => {
        try {
            await form.validateFields()
            if (ClientId.length > 0 && ClientId !== null) {
                navigate(`/confirmation-Statement/details-Individual`, {
                    state: { ClientId },
                })
            }
        } catch (error) {}
    }
    const OnClickNamevalidate = (): void => {
        void HandelButtonCLick()
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <>
            <div>
                <h2>Company overview</h2>
            </div>

            <Card title="Company overview">
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Please select the client to the filing"
                                    name="clientNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select client',
                                        },
                                    ]}
                                >
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                // Seed Token
                                                colorPrimary: '#0219E6',
                                            },
                                            components: {
                                                Select: {
                                                    optionSelectedBg: '#175ca9', // Background color when an option is selected
                                                    optionSelectedColor: '#fff',
                                                    // optionActiveBg: '#175ca9',
                                                },
                                            },
                                        }}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            loading={isLoading}
                                            showSearch
                                            placeholder="To filter the clients by number /name"
                                            onChange={(value) => {
                                                onChangeSearch(value)
                                            }}
                                            onSearch={(value) => {
                                                setSearchValue(value)
                                            }}
                                            filterOption={false}
                                            allowClear
                                            onClear={() => {
                                                setData([])
                                            }}
                                            optionLabelProp="label"
                                            options={data}
                                            optionRender={(option) => (
                                                <>{option.data.desc}</>
                                            )}
                                            popupClassName={
                                                styles.custom_dropdown
                                            }
                                        />
                                    </ConfigProvider>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        OnClickNamevalidate()
                                    }}
                                >
                                    <ToTopOutlined
                                        style={{
                                            transform: 'rotate(90deg)',
                                        }}
                                    />{' '}
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    )
}

export default CompanyOverView
