import { type Dispatch } from '@reduxjs/toolkit'
import { type TableListInterface } from '../../Types/CommonType'
import { setLoadingStatus } from '../../Reducer/Common'
import { ErrorMessage } from '../../Utils/Notification'
import { detailReportList, totalReportList } from '../../Reducer/StaffReport'
import axios from '../../Config/AxiosConfig'
import {
    type StaffTaskStatusFormData,
    type totalReportStatusFormData,
} from '../../Types/StaffwiseReport/staffWiseReport'

export const GetDetailReport = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/Dashboard/GetThingsToDoUserWise`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: StaffTaskStatusFormData) => {
                        return {
                            ...value,
                            key: value.userId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(detailReportList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetTotalReport = (
    dispatch: Dispatch,
    data: TableListInterface,
    search: string,
    userId: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `api/Dashboard/GetDashboardThingsToDoUserWise/${userId}/${search}/2`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: totalReportStatusFormData) => {
                        return {
                            ...value,
                            key: value.userId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(totalReportList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
