export const formatdate = (date: string | undefined): any => {
    if (date != null) {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()
        const formattedDate = `${day}/${month}/${year}`
        return formattedDate
    }
}

export const formatdatewithMonth = (date: string | undefined): any => {
    if (date != null) {
        // Replace with your timestamp
        const d = new Date(date)
        const formattedDate = `${d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        })}`

        return formattedDate
    }
}

export const formatDateFromISO = (isoDateStr: string): string => {
    const date = new Date(isoDateStr) // Parse the ISO date string into a Date object

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    }

    return date.toLocaleDateString('en-US', options)
}

export const ConvertTime = (minutes: any): string => {
    const hours = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0')
    const remainingMinutes = (minutes % 60).toString().padStart(2, '0')
    return `${hours} : ${remainingMinutes}`
}
