import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import Welcome from './welcome'
import HeadLines from './headlines'
import styles from './BPO_and_others_index.less'

import Calender from './calender'
// import MyTask from './myTask'
import { useDispatch, connect, useSelector } from 'react-redux'
import ToDOClients from './clients'
import TaskProgressChart from './ITStaffProgress'
import ProjectStatusList from './ProjectStatusList'
import { GetITDashboard } from '../../../Services/ITDashboard'
import { type ItdashboardIntialState } from '../../../Types/ITDashbord'
import ToApprovalAndApprove from '../ITDepartmetDashbord/Toapprove_Approval'

const BPOotherDashboard = (
    props: ItdashboardIntialState
): React.ReactElement => {
    const dispatch = useDispatch()

    const getFlag = useSelector((state: any) => state.dashboardFlag.flag)

    const token = localStorage.getItem('jwtToken')
    const user = localStorage.getItem('user')

    const userDetials = user !== null ? JSON.parse(user) : ''
    const departmentId = useSelector(
        (state: any) => state.dashboardFlag.departmentId
    )
    const [departmentIds, setDepartmetId] = useState(0)
    const clientDynCategoryId = Number(localStorage.getItem('departmetId'))
    useEffect(() => {
        // console.log(departmentId)
        setDepartmetId(clientDynCategoryId)
    }, [clientDynCategoryId, departmentId])
    useEffect(() => {
        if (
            token !== null &&
            token !== undefined &&
            departmentIds !== undefined
        ) {
            void GetITDashboard(dispatch, departmentIds)
        }
    }, [token, getFlag, departmentIds])
    const [getDashboardInfo, setDashboardInfo] = useState(props?.dashboard)
    const [getEventListByUserInfo, setEventListByUserInfo] = useState(
        props?.eventListByUser
    )
    const [getOverviewInfo, setOverviewInfo] = useState(props?.itdashboard)
    useEffect(() => {
        if (props?.dashboard !== undefined) {
            setDashboardInfo(props?.dashboard)
        }

        if (props?.eventListByUser !== undefined) {
            setEventListByUserInfo(props?.eventListByUser)
        }

        if (props?.itdashboard !== undefined) {
            setOverviewInfo(props?.itdashboard)
        }
    }, [props])

    return getDashboardInfo !== undefined && getDashboardInfo !== null ? (
        <>
            <Row>
                <Col span={16}>
                    <Welcome
                        lastLoginDate={
                            getOverviewInfo?.greetingInfo?.lastLoginDate != null
                                ? getOverviewInfo?.greetingInfo?.lastLoginDate
                                : ''
                        }
                    />
                    <HeadLines
                        backgroundImageUrl={
                            getOverviewInfo?.greetingInfo?.backgroundImageUrl !=
                            null
                                ? getOverviewInfo?.greetingInfo
                                      ?.backgroundImageUrl
                                : ''
                        }
                        greetingText={
                            getOverviewInfo?.greetingInfo?.greetingText != null
                                ? getOverviewInfo?.greetingInfo?.greetingText
                                : ''
                        }
                        dashboardNewsInfo={
                            getOverviewInfo?.dashboardNewsInfo != null
                                ? getOverviewInfo?.dashboardNewsInfo
                                : []
                        }
                    />
                    {userDetials?.userGroupId !== 4 && (
                        <Row>
                            <Col span={8} className={styles.col}>
                                <ToDOClients
                                    clientInfo={
                                        getOverviewInfo?.clientInfo != null
                                            ? getOverviewInfo?.clientInfo
                                            : []
                                    }
                                />
                            </Col>
                            <Col span={16} className={styles.col}>
                                <TaskProgressChart />
                            </Col>
                            {/* <Col span={8} className={styles.col}>
                                <InvoiceCreditControl
                                    paidInvoiceSummaries={
                                        getDashboardInfo?.paidInvoiceSummary !=
                                        null
                                            ? getDashboardInfo?.paidInvoiceSummary
                                            : []
                                    }
                                />
                            </Col> */}
                        </Row>
                    )}
                    <Row></Row>

                    <Row>
                        <Col span={24} className={styles.col}>
                            <ProjectStatusList />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Calender eventList={getEventListByUserInfo ?? []} />
                    {/* <MyTask /> */}
                    <ToApprovalAndApprove
                        leaveApprovalList={
                            getDashboardInfo?.leaveApprovalList != null
                                ? getDashboardInfo.leaveApprovalList
                                : []
                        }
                        approvalTimesheetList={
                            getDashboardInfo?.approvalTimesheetList != null
                                ? getDashboardInfo.approvalTimesheetList
                                : []
                        }
                        permissionApprovalList={
                            getDashboardInfo?.permissionApprovalList != null
                                ? getDashboardInfo.permissionApprovalList
                                : []
                        }
                        myApprovalTimesheetList={
                            getDashboardInfo?.myApprovalTimesheetList != null
                                ? getDashboardInfo.myApprovalTimesheetList
                                : []
                        }
                        myLeaveApprovalList={
                            getDashboardInfo?.myLeaveApprovalList != null
                                ? getDashboardInfo.myLeaveApprovalList
                                : []
                        }
                        myPermissionApprovalList={
                            getDashboardInfo?.myPermissionApprovalList != null
                                ? getDashboardInfo.myPermissionApprovalList
                                : []
                        }
                    />
                </Col>
            </Row>
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state: any): ItdashboardIntialState => {
    return {
        dashboard: state.dashboardInfo.dashboard,
        overview: state.dashboardInfo.overview,
        eventListByUser: state.dashboardInfo.eventListByUser,
        itdashboard: state.itdashboardInfo.itdashboard,
    }
}

export default connect(mapStateToProps)(BPOotherDashboard)
