import React from 'react'
import { Table } from 'antd'

import commontStyles from '../../../../Utils/Common.less'
import type { IndividualExpandViewInterface } from '../../../../Types/Client/RegisteredClients/Individual'

const IndividualExpandView = (
    props: IndividualExpandViewInterface
): React.ReactElement => {
    const { IndividualData } = props

    const columns = [
        {
            title: 'Contact No',
            dataIndex: 'mobilenumber',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Alter Email',
            dataIndex: 'alternativeEmailAddress',
        },
    ]

    const dataSource = [
        {
            key: '1',
            mobilenumber: IndividualData.mobileNumber,
            emailAddress: IndividualData.emailAddress,
            alternativeEmailAddress: IndividualData.alternativeEmailAddress,
        },
    ]

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default IndividualExpandView
