import type { Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'

import { setLoadingStatus } from '../Reducer/Common'
import { contactdepartmentDropDownList } from '../Reducer/InitialReducer'
import {
    type savebulkemial,
    type bulkmailFormDataInterface,
} from '../Types/BulkEmail'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'

export const GetContactDetail = (
    dispatch: Dispatch,
    clientCategoryId: number,
    flagId: number,
    statusId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(
            `/api/Management/GetContactDetail/${clientCategoryId}/${flagId}/${statusId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: bulkmailFormDataInterface) => {
                        return {
                            ...value,
                            key: value.contactName,
                        }
                    }
                )
                response.data.data = newData
                dispatch(contactdepartmentDropDownList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveBulkEmail = async (
    dispatch: Dispatch,
    data: savebulkemial
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Management/SetBulkEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
