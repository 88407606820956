import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Row, Col, Space, Modal } from 'antd'
import styles from './Less/userAvatar.less'
import { getColor } from '../Utils/Common'
import UserProfile from '../Pages/Profile/UserProfile'
import { GetProfileDetails } from '../Services/Profile'
import { type UserData } from '../Types/Profile'
import { useDispatch } from 'react-redux'

const UserAvatar = (
    username: string,
    size: number = 54,
    src: string
): React.ReactElement => {
    const [modalVisible, setModalVisible] = useState(false)
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null
    const dispatch = useDispatch()
    const [userData, setUserData] = useState<UserData | null>()
    const hasFetchedOnce = useRef(false)
    const handleNameClick = (): void => {
        setModalVisible(true)
    }

    const oncancelModel = (): void => {
        setModalVisible(false)
    }

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        void GetProfileDetails(
            dispatch,
            tokenDetails?.userId,
            (addressData: UserData) => {
                setUserData(addressData)
            }
        )
    }, [])

    return (
        <div>
            <Row onClick={handleNameClick}>
                <Col span={8}>
                    <Avatar
                        src={src}
                        style={{
                            backgroundColor: String(getColor(username)),
                            verticalAlign: 'top',
                        }}
                        size={size}
                    >
                        {!(src === null || src === '')
                            ? username !== null
                                ? username.charAt(0).toUpperCase()
                                : ''
                            : ''}
                    </Avatar>
                </Col>
                <Col span={16}>
                    <Space.Compact
                        direction="vertical"
                        size="small"
                        className={styles.nameContainer}
                    >
                        <div className={styles.firstName}>
                            {userData?.userName}
                        </div>
                        {/* <div className={styles.lastName}>
                            {tokenDetails?.emailAddress}
                        </div> */}
                    </Space.Compact>
                </Col>
            </Row>
            <Modal
                open={modalVisible}
                width={'20%'}
                footer={null}
                mask={false}
                closable={false}
                style={{ left: '39%', top: '10%' }}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >
                <UserProfile oncancelModel={oncancelModel} />
            </Modal>
        </div>
    )
}

export default UserAvatar
