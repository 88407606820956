import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Space, Tooltip, Switch } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import calculatePagination from '../../../Utils/Pagination'
import {
    type UnassignUserListInterface,
    type PortfolioClientDataTableData,
    type UnassignUserListStateInterface,
} from '../../../Types/Client/RegisteredClients/Portfolio'
import { AddClient, GetUnassignList } from '../../../Services/Portfolio'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import SearchInput from '../../../Components/SearchInput'

const UnassigneUserList = (
    props: UnassignUserListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        portfolioUnassignUserList,
        clientCategoryId,
        portfolioId,
        fetchclientData,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [BusinessId, setbusinessId] = useState(0)
    const [ReferenceId, setreferenceId] = useState(0)
    const [OpenModalStatus, setOpenStatus] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetUnassignList(dispatch, clientCategoryId, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: portfolioUnassignUserList?.length,
            },
        })
    }, [portfolioUnassignUserList?.length])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        portfolioUnassignUserList?.length,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<PortfolioClientDataTableData> = [
        {
            title: 'Client Number',
            dataIndex: 'clientNumber',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Business Type',
            dataIndex: 'businessType',
        },
        {
            title: 'Business Name',
            dataIndex: 'businessName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: PortfolioClientDataTableData) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Add User">
                            <Switch
                                onClick={() => {
                                    openModalFunction(
                                        record.businessTypeId,
                                        record.referenceId
                                    )
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PortfolioClientDataTableData>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const openModalFunction = (
        businessId: number,
        referenceId: number
    ): void => {
        setbusinessId(businessId)
        setreferenceId(referenceId)
        setOpenStatus(!OpenModalStatus)
    }

    const AddFunction = (): void => {
        const dataToSend = {
            portfolioId,
            businessTypeId: BusinessId,
            referenceId: ReferenceId,
            addOrRemoveFlag: 1,
        }
        void AddClient(dispatch, dataToSend)
        setTimeout(() => {
            getTableData()
            fetchclientData()
            setOpenStatus(!OpenModalStatus)
        }, 1000)
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    return (
        <>
            <div>
                <SearchInput
                    placeHolder={'Search Details by Business Name'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
            </div>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={portfolioUnassignUserList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                />
            </div>
            <FullWidthModal
                modalStatus={OpenModalStatus}
                closeModal={() => {
                    openModalFunction(0, 0)
                }}
                tittle="You are about to Add this Client. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={AddFunction}
            />
        </>
    )
}

const mapStateToProps = (state: any): UnassignUserListStateInterface => {
    return {
        portfolioUnassignUserList: state.portfolio.portfolioUnassignUserList,
        portfolioUnassignUserListCount:
            state.portfolio.portfolioUnassignUserListCount,
    }
}

export default connect(mapStateToProps)(UnassigneUserList)
