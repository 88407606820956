import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Select, Progress, Empty } from 'antd'
import styles from './index.less'
// import { ReactComponent as VerticalThreeDots } from '../../Assest/Svg/VerticalThreeDots.svg'
import { ReactComponent as MissingInfoTittle } from '../../Assest/Svg/MissingInfoTittleIcon.svg'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
import type {
    PaidInvoiceSummary,
    PaidInvoiceSummaryProps,
} from '../../Types/Dashboard'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage } from '../../Utils/Notification'
import { useNavigate } from 'react-router-dom'

Chart.register(ArcElement)

enum colors {
    PERFORM = '#695EEF',
    UNPAID = '#F4BA40',
    PAID = '#38C786',
}

interface GraphDataType {
    label: string
    value: number
    percentage: number
    color: string
}

interface InvoiceType {
    count: number
    description: string
}
const InvoiceCreditControl = ({
    paidInvoiceSummaries,
}: PaidInvoiceSummaryProps): React.ReactElement => {
    const user = localStorage.getItem('user')
    const clientCategoryId =
        user !== null ? JSON.parse(user)?.clientCategoryId : ''
    const [dropdownItem, setDropDownItem] = useState(3)
    const [paidInvoiceList, setpaidInvoiceSummaries] =
        useState<InvoiceType[]>(paidInvoiceSummaries)

    const [total, setTotal] = useState(0)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        const getTotal = (): number => {
            return paidInvoiceList !== undefined && paidInvoiceList?.length > 0
                ? paidInvoiceList?.reduce(function (acc, obj) {
                      return acc + Number(obj.count)
                  }, 0)
                : 0
        }
        setTotal(getTotal)
    }, [])

    useEffect(() => {
        setpaidInvoiceSummaries(paidInvoiceSummaries)
    }, [])
    const getTableData = async (id: number): Promise<void> => {
        setLoading(true)
        await axios
            .get(`/api/Dashboard/GetInvoiceSummary/${clientCategoryId}/${id}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const newData = response?.data?.data?.map(
                        (item: InvoiceType) => {
                            return {
                                ...item,
                            }
                        }
                    )
                    setpaidInvoiceSummaries(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
                //
            })
    }
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        if (dropdownItem !== undefined) {
            void getTableData(dropdownItem)
        }
    }, [dropdownItem])

    interface DataSetType {
        data: number[]
        backgroundColor: string[]
        display: boolean
        borderColor: string
    }

    interface ChartDataType {
        labels: string[]
        datasets: DataSetType[]
    }

    interface StandardType {
        stdId: number
        stdName: string
    }

    const [chartData, setChartData] = useState<ChartDataType>({
        labels: ['PERFORM', 'UNPAID', 'PAID'],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                display: true,
                borderColor: '#FFFFFF',
            },
        ],
    })
    const [data, setData] = useState<GraphDataType[] | null>([])

    const findValues = (label: string): { label: string; color: string } => {
        switch (label) {
            case 'Perform Invoices':
                return { label: 'PERFORM', color: colors.PERFORM }
            case 'Paid Invoices':
                return { label: 'PAID', color: colors.PAID }
            case 'Unpaid Invoices':
                return { label: 'UNPAID', color: colors.UNPAID }
            default:
                return { label: 'PERFORM', color: colors.PERFORM }
        }
    }

    const getGraphData = (
        list: PaidInvoiceSummary[]
    ): GraphDataType[] | null => {
        return list !== undefined && list?.length > 0
            ? list?.map((data: PaidInvoiceSummary) => {
                  const findObj: { label: string; color: string } = findValues(
                      data.description
                  )
                  return {
                      label: findObj?.label,
                      value: Number(data.count),
                      percentage: (Number(data.count) / total) * 100,
                      color: findObj?.color,
                  }
              })
            : null
    }

    useEffect(() => {
        if (paidInvoiceList !== undefined && paidInvoiceList?.length > 0) {
            const newData = getGraphData(paidInvoiceList)
            setData(newData)

            if (newData !== undefined && newData !== null) {
                setChartData({
                    labels: ['PERFORM', 'PAID', 'UNPAID'],
                    datasets: [
                        {
                            data: newData?.map(
                                (data: GraphDataType) => data.value
                            ),
                            backgroundColor: newData?.map(
                                (data: GraphDataType) => data.color
                            ),
                            display: true,
                            borderColor: '#FFFFFF',
                        },
                    ],
                })
            }
        }
    }, [paidInvoiceList])

    const [isLoading, setLoading] = useState<boolean>(false)
    const [selectOptions, setSelectOption] = useState([])
    const getStandardListing = async (): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListing/74`)
            .then((response: any) => {
                if (response.status === 200) {
                    const newData = response?.data?.data?.map(
                        (item: StandardType) => {
                            return {
                                label: item.stdName,
                                value: item.stdId,
                            }
                        }
                    )
                    setSelectOption(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage('Record Not Found')
                }
            })
            .catch(() => {
                setLoading(false)
                //
            })
    }

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        void getStandardListing()
    }, [])

    const onChangeDropDown = (data: any): void => {
        setDropDownItem(data)
    }

    const Chart = (): React.ReactElement => {
        return (
            <>
                <div className={styles.icController__chart__first}>
                    {data !== undefined && data !== null ? (
                        <Doughnut
                            data={chartData}
                            width={'100%'}
                            height={'auto'}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        enabled: true,
                                        usePointStyle: true,
                                    },
                                },
                                rotation: -90,
                                circumference: 180,
                                cutout: '80%',
                                maintainAspectRatio: false,
                                responsive: true,
                            }}
                        />
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No Data Available"
                        />
                    )}
                </div>

                <Row className={styles.icController__chart__second}>
                    {data !== undefined && data !== null
                        ? data?.map((data: any, index) => {
                              return (
                                  <Col
                                      span={6}
                                      className={
                                          styles.icController__chart__second__col
                                      }
                                      key={index}
                                  >
                                      <Progress
                                          percent={data.percentage}
                                          size="default"
                                          strokeColor={data.color}
                                          showInfo={false}
                                      />
                                      <div
                                          className={
                                              styles.icController__chart__second__col__secondDiv
                                          }
                                      >
                                          {data.value}
                                      </div>
                                      <div
                                          className={
                                              styles.icController__chart__second__col__firstDiv
                                          }
                                      >
                                          {data.label}
                                      </div>
                                  </Col>
                              )
                          })
                        : ''}
                </Row>
            </>
        )
    }

    const navigate = useNavigate()

    return (
        <Card className={`${styles.card} ${styles.icController__main__height}`}>
            <div className={styles.cardContent}>
                <Row>
                    <Col span={24} className={styles.icController__first}>
                        <div className={styles.icController__first__tittle}>
                            Invoicing & Credit Control{' '}
                            <MissingInfoTittle
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate('/invoicing/invoic-status')
                                }}
                            />
                        </div>
                    </Col>
                    {/* <Col span={2} className={styles.icController__third}>
                        <VerticalThreeDots />
                    </Col> */}
                    <Col
                        span={24}
                        className={styles.icController__second}
                        style={{ placeContent: 'end' }}
                    >
                        <Select
                            value={dropdownItem}
                            variant={undefined}
                            className={styles.icController__select}
                            onChange={onChangeDropDown}
                            options={selectOptions}
                            loading={isLoading}
                        />
                    </Col>
                </Row>
                <div style={{ marginTop: 30 }}>
                    <Chart />
                </div>
            </div>
        </Card>
    )
}

export default InvoiceCreditControl
