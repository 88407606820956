import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Col,
    DatePicker,
    type DatePickerProps,
    Form,
    Input,
    Row,
    Select,
    Space,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    GetsubTaskList,
    SavesubTask,
} from '../../../Services/Helpdesk/CreteTask'
import {
    type subtaskinterfaces,
    type subtaskinterface,
    type Task,
    type SubTaskView,
} from '../../../Types/HelpDesk/Cretetask'
import dayjs from 'dayjs'
import commontStyles from '../../../Utils/Common.less'
import { GetUserList } from '../../../Services/User'
import { GetPriorityList } from '../../../Services/Corporate'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import moment from 'moment'

const SubtaskModal = (
    props: subtaskinterfaces & {
        taskId: string
        getTask: Task
        resetdata: string
        setResetData: React.Dispatch<React.SetStateAction<string>>
        subtaskData: SubTaskView
        setActiveKey: React.Dispatch<React.SetStateAction<string>>
    }
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        getTask,
        priorityDropDownList,
        userList,
        resetdata,
        setResetData,
        subtaskData,
        setActiveKey,
    } = props
    const [SubTaskId] = useState('0')
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const hasFetchedOnce = useRef(false)
    const [buttonLoading, setButtonloading] = useState(false)
    const user = localStorage.getItem('user')
    const userDetials = user !== null ? JSON.parse(user) : ''
    useEffect(() => {
        const priorityData = priorityDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === subtaskData?.priorityName
        )
        const user = userList.find(
            (data: ListFrontEndInterface) =>
                data.label === subtaskData?.assignedToName
        )
        const newData = {
            ...subtaskData,
            priorityId: priorityData?.value ?? null,
            description: subtaskData?.description ?? '',
            assignedTo: user?.value ?? null,
        }
        form.setFieldsValue(newData)
        setSelectedDate(dayjs(subtaskData.toDate).format('MM/DD/YYYY'))
    }, [subtaskData])
    useEffect(() => {
        form.setFieldsValue(subtaskData)
    }, [subtaskData])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetPriorityList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
            setSelectedDate('')
        }

        setResetData('false')
    }, [resetdata])
    useEffect(() => {
        form.setFieldValue('toDate', selectedDate)
    }, [selectedDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }

    useEffect(() => {
        void GetsubTaskList(dispatch, SubTaskId)
    }, [SubTaskId, dispatch])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    const saveSubTask = async (): Promise<void> => {
        await form.validateFields()
        setButtonloading(true)
        const formData = form.getFieldsValue()
        const formatstartDate = dayjs(formData.toDate).format('DD/MM/YYYY')
        const taskDetails = getTask

        if (taskDetails !== undefined) {
            const DataTOSave = {
                subTaskId: formData.subTaskId ?? 0,
                subTaskNo: formData.subTaskNo ?? '',
                taskId: Number(taskDetails.taskId),
                taskNo: taskDetails.taskNo.toString(),
                subTaskName: formData.subTaskName ?? '',
                priorityId: formData.priorityId ?? 0,
                sortOrder: taskDetails.sortOrder,
                assignedBy: userDetials.userId ?? 0,
                assignedTo: formData.assignedTo,
                fromDate: dayjs(taskDetails.fromDate).format('DD/MM/YYYY'),
                toDate: formatstartDate,
                statusId: taskDetails.projectStatusId,
                notifyToStatusChange: taskDetails.notifyToStatusChange,
                description: formData.description ?? '',
                projectTierId: 0,
            }
            try {
                await SavesubTask(dispatch, DataTOSave)
                    .then((result) => {
                        form.resetFields()
                        setSelectedDate('')
                        setButtonloading(false)
                        setActiveKey('1')
                    })
                    .catch(() => {})
            } catch (error) {
                console.error('Failed to save subtask:', error)
            }
        }
    }

    const handleFinish = (): void => {
        void saveSubTask()
    }

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="subTaskId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Sub-Task Name"
                            name="subTaskName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Task Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Due Date"
                            name="toDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Due Date!',
                                },
                            ]}
                        >
                            <Space>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    className={commontStyles.dateWidth}
                                    format={'DD/MM/YYYY'}
                                    disabledDate={(current) =>
                                        current !== null &&
                                        current < moment().startOf('day')
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Priority"
                            name="priorityId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Priority',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...priorityDropDownList?.map(
                                        (service: ListFrontEndInterface) => ({
                                            value: service.value,
                                            label: service.label,
                                        })
                                    ),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assignedTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Assigned',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save SubTask">
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={buttonLoading}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): subtaskinterface => {
    return {
        userList: state.user.userList,
        userimageList: state.helpdesk.userimageList,
        subtaskDetailsList: state.helpdesk.subtaskDetailsList,
        subtaskDetailsListCount: state.helpdesk.subtaskDetailsListCount,
        SubViewDetail: state.helpdesk.SubViewDetail,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(SubtaskModal)
