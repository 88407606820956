import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Card,
    List,
    Empty,
    Modal,
    type TableProps,
    Table,
} from 'antd'
import styles from './index.less'
import { ReactComponent as MissingInfoHeader } from '../../Assest/Svg/MissingInfoHeaderIcon.svg'
// import { ReactComponent as VerticalThreeDots } from '../../Assest/Svg/VerticalThreeDots.svg'
import { ReactComponent as MissingInfoTittle } from '../../Assest/Svg/MissingInfoTittleIcon.svg'
import type {
    MissedInformationInfo,
    MissingInformationStateInterface,
    misingInfoTable,
} from '../../Types/Dashboard'
import VirtualList from 'rc-virtual-list'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { type propertyFormDataInterface } from '../../Types/MiisngInformation/PropertyView'
import { GetMissedField } from '../../Services/MissingInformation/PrpertyView'

const MissingInfo = ({
    missedInformationList,
    MissinginformationprpertyviewList,
}: {
    missedInformationList: MissedInformationInfo[]
    MissinginformationprpertyviewList: propertyFormDataInterface[]
}): React.ReactElement => {
    const navigate = useNavigate()
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [miisedfieldid, setmiisefieldid] = useState(1)
    const [modalTitle, setModalTitle] = useState('Modal Title')
    const hasFetchedOnce = useRef(false)

    const handleModalOpen = (
        id: number | undefined,
        label: string | undefined
    ): void => {
        if (id !== undefined && label !== undefined) {
            setModalVisible(true)
            setmiisefieldid(id)
            setModalTitle(label)
        }
    }

    const handleModalClose = (): void => {
        setModalVisible(false)
    }

    const getTableData = useCallback(() => {
        GetMissedField(dispatch, '=NA=', miisedfieldid, 0, 0, 1, 10)
    }, [miisedfieldid])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const columns: TableProps<misingInfoTable>['columns'] = [
        {
            title: 'Client',
            dataIndex: 'companyName',
            key: 'clientNumber',
        },
    ]

    const getTotal = (): number => {
        return missedInformationList.length > 0
            ? missedInformationList?.reduce(function (acc, obj) {
                  return acc + Number(obj.missingCount)
              }, 0)
            : 0
    }

    const limitedRecode: Array<MissedInformationInfo | undefined> =
        missedInformationList?.map(
            (missedInformation: MissedInformationInfo) => {
                if (
                    missedInformation.missingFieldName ===
                        'Authentication Code' ||
                    missedInformation.missingFieldName === 'ID Proof' ||
                    missedInformation.missingFieldName === 'Address Proof' ||
                    missedInformation.missingFieldName === 'Company UTR'
                ) {
                    return missedInformation
                }
                return undefined
            }
        )

    const data = {
        chartData: limitedRecode
            ?.map((missedInformation: MissedInformationInfo | undefined) => {
                if (missedInformation !== undefined) {
                    return {
                        label: missedInformation.missingFieldName,
                        value: missedInformation.missingCount,
                        id: missedInformation.missingFieldId,
                    }
                } else {
                    return undefined
                }
            })
            .filter((x) => x !== undefined) as Array<{
            label: string
            value: number
            id: number
        }>,
        total: getTotal(),
    }

    return (
        <div className={styles.marginRight10}>
            <Card
                className={`${styles.card} ${styles.missingInfo__main__height}`}
            >
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={18}>
                            <div
                                style={{
                                    display: 'flex', // Use flexbox to align items in a row
                                    alignItems: 'center', // Vertically center the items
                                    cursor: 'pointer',
                                }}
                            >
                                <MissingInfoHeader
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        navigate(
                                            '/missing-information/property-view'
                                        )
                                    }}
                                />
                                <div
                                    className={styles.missingInfo__first__left}
                                >
                                    <span>Missing Info</span>
                                    {/* <MissingInfoTittle /> */}
                                </div>
                            </div>
                        </Col>
                        <Col
                            span={6}
                            className={styles.missingInfo__first__middle}
                        >
                            {/* <div
                                className={
                                    styles.missingInfo__first__middle__text
                                }
                            >
                                Total
                            </div> */}
                            <div
                                className={
                                    styles.missingInfo__first__middle__count
                                }
                            >
                                {data.total}
                            </div>
                        </Col>
                        {/* <Col
                            span={2}
                            className={styles.missingInfo__first__right}
                        >
                            <VerticalThreeDots />
                        </Col> */}
                    </Row>
                    <div className={styles.missingInfo__second}>
                        {data.chartData.length > 0 ? (
                            <List style={{ width: '100%' }}>
                                <VirtualList
                                    data={data.chartData}
                                    height={200}
                                    itemKey="email"
                                >
                                    {(result, index: number) => (
                                        <List.Item
                                            style={{
                                                backgroundColor: '#A6DAB5',
                                                margin: '5px 0px',
                                                padding: '5px 10px',
                                                borderRadius: '10px',
                                                border: '0px',
                                            }}
                                            key={index}
                                        >
                                            <Row
                                                justify="space-between"
                                                align="stretch"
                                                key={index}
                                                className={
                                                    styles.missingInfo__second__row
                                                }
                                                style={{ width: '100%' }}
                                            >
                                                <Col
                                                    span={12}
                                                    className={
                                                        styles.missingInfo__second__row__first__col
                                                    }
                                                >
                                                    {result?.label}
                                                </Col>
                                                <Col
                                                    span={12}
                                                    className={
                                                        styles.missingInfo__second__row__second__col
                                                    }
                                                >
                                                    {result?.value} &nbsp;{' '}
                                                    <MissingInfoTittle
                                                        onClick={() => {
                                                            handleModalOpen(
                                                                result?.id,
                                                                result?.label
                                                            )
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                </VirtualList>
                            </List>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </div>
                </div>
            </Card>
            <Modal
                title={modalTitle}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
            >
                <Table
                    columns={columns}
                    dataSource={MissinginformationprpertyviewList}
                    rowKey="clientNumber"
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): MissingInformationStateInterface => {
    return {
        MissinginformationprpertyviewList:
            state.missing.MissinginformationprpertyviewList,
    }
}

export default connect(mapStateToProps)(MissingInfo)
