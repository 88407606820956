import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Carousel, Empty, Flex } from 'antd'
import styles from './Itindex.less'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip } from 'chart.js'
import { connect, useDispatch } from 'react-redux'
import { GetITStaffOverallTaskCount } from '../../../Services/ITDashboard'
import {
    type overalltasklistCout,
    type overalltaskProps,
} from '../../../Types/ITDashbord'
Chart.register(ArcElement, Tooltip)

const REOPEN = 'Re Open'
const DONE = 'Done'
const INPROGRESS = 'In Progress'
const TODO = 'To Do'

interface GraphDataType {
    label: string
    value: number
    percentage: number
    color: string
}

const OverAllTaskSummary = (props: overalltaskProps): React.ReactElement => {
    const { projectId, overalltaskListcoutdata } = props
    const dispatch = useDispatch()
    const [graphData, setGraphData] = useState<GraphDataType[] | null>(null)
    const [totalTasks, setTotalTasks] = useState(0)

    useEffect(() => {
        if (projectId !== undefined && projectId !== null) {
            GetITStaffOverallTaskCount(dispatch, projectId)
        }
    }, [projectId])
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const data = overalltaskListcoutdata[0]
                if (data !== null) {
                    const transformedData = [
                        {
                            description: 'To Do',
                            recordCount: data?.openTaskCount,
                        },
                        {
                            description: 'In Progress',
                            recordCount: data?.inProgressTaskCount,
                        },
                        {
                            description: 'Done',
                            recordCount: data?.doneTaskCount,
                        },
                        {
                            description: 'Archived',
                            recordCount: data?.archivedTaskCount,
                        },
                    ]

                    const total = transformedData?.reduce(
                        (acc, item) => acc + item.recordCount,
                        0
                    )
                    setTotalTasks(total)

                    const graph = transformedData?.map((item) => ({
                        label: item.description,
                        value: item.recordCount,
                        percentage: (item.recordCount / total) * 100,
                        color: findColor(item.description),
                    }))

                    setGraphData(graph)
                }
            } catch {}
        }
        void fetchData()
    }, [projectId, overalltaskListcoutdata])

    const findColor = (label: string): string => {
        switch (label) {
            case 'To Do':
                return '#3f6bef'
            case 'In Progress':
                return '#7d69e9'
            case 'Done':
                return '#79ddc0'
            case 'Archived':
                return '#a1262e'
            default:
                return '#695EEF'
        }
    }

    const projectNames = overalltaskListcoutdata?.map(
        (item) => item?.projectName
    )
    const data =
        overalltaskListcoutdata?.length !== 0 && graphData != null
            ? {
                  labels: graphData.map((data) => data.label),
                  datasets: [
                      {
                          data: graphData.map((data) => data?.value),
                          backgroundColor: graphData.map((data) => data?.color),
                          borderColor: '#FFFFFF',
                      },
                  ],
              }
            : null

    const Chart = ({
        data,
        total,
    }: {
        data: any
        total: number
    }): React.ReactElement => {
        return data !== null && data !== undefined ? (
            <div className={styles.toFollow__chart__main}>
                <Doughnut
                    data={data}
                    width={'100%'}
                    height={'auto'}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: true,
                                usePointStyle: true,
                                yAlign: 'bottom',
                                xAlign: 'center',
                            },
                        },
                        rotation: 0,
                        circumference: 360,
                        cutout: '60%',
                        maintainAspectRatio: false,
                        responsive: true,
                    }}
                />
                <div className={styles.toFollow__chart__text}>
                    <div className={styles.toFollow__chart__text_count}>
                        {total}
                    </div>
                    <div className={styles.toFollow__chart__text_count_text}>
                        Task
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.toFollow__chart__main}>
                <Empty
                    style={{ padding: '40px 0px' }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        )
    }

    return (
        <div className={styles.marginRight10}>
            <Card className={`${styles.card} ${styles.over__card__height}`}>
                <div className={styles.cardContent}>
                    <Row
                        style={{
                            background: 'white',
                        }}
                    >
                        <Col span={24}>
                            <span className={styles.clients__first__left}>
                                {projectNames !== null &&
                                projectNames !== undefined &&
                                projectNames.length !== 0
                                    ? projectNames
                                    : 'Over All Task Summary'}
                            </span>
                        </Col>
                    </Row>

                    <Carousel dots={false} ref={useRef(null)}>
                        <div>
                            <Chart data={data} total={totalTasks} />
                        </div>
                    </Carousel>
                    <Row className={styles.client__second}>
                        <Col span={24} style={{ marginTop: 25 }}>
                            <Flex justify="space-between">
                                <div>
                                    <div
                                        className={
                                            styles.client__second__number
                                        }
                                    >
                                        <div
                                            className={
                                                styles.myTask__list__item__dot
                                            }
                                            style={{
                                                backgroundColor: '#3f6bef',
                                            }}
                                        />
                                    </div>
                                    <a
                                        style={{
                                            fontSize: '12px',
                                            color: '#5F6D7E',
                                        }}
                                    >
                                        {TODO}
                                    </a>
                                </div>

                                <div>
                                    <div
                                        className={
                                            styles.client__second__number
                                        }
                                    >
                                        <div
                                            className={
                                                styles.myTask__list__item__dot
                                            }
                                            style={{
                                                backgroundColor: '#7d69e9',
                                            }}
                                        />
                                    </div>
                                    <a
                                        style={{
                                            fontSize: '12px',
                                            color: '#5F6D7E',
                                        }}
                                    >
                                        {INPROGRESS}
                                    </a>
                                </div>
                                <div>
                                    <div
                                        className={
                                            styles.client__second__number
                                        }
                                    >
                                        <div
                                            className={
                                                styles.myTask__list__item__dot
                                            }
                                            style={{
                                                backgroundColor: '#79ddc0',
                                            }}
                                        />
                                    </div>
                                    <a
                                        style={{
                                            fontSize: '12px',
                                            color: '#5F6D7E',
                                        }}
                                    >
                                        {DONE}
                                    </a>
                                </div>
                                <div>
                                    <div
                                        className={
                                            styles.client__second__number
                                        }
                                    >
                                        <div
                                            className={
                                                styles.myTask__list__item__dot
                                            }
                                            style={{
                                                backgroundColor: '#a1262e',
                                            }}
                                        />
                                    </div>
                                    <a
                                        style={{
                                            fontSize: '12px',
                                            color: '#5F6D7E',
                                        }}
                                    >
                                        {REOPEN}
                                    </a>
                                </div>
                            </Flex>
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = (state: any): overalltasklistCout => {
    return {
        overalltaskListcoutdata: state.itdashboardInfo.overalltaskListcoutdata,
    }
}

export default connect(mapStateToProps)(OverAllTaskSummary)
