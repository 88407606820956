import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Button, Table, type TableProps } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { PhoneOutlined, MessageOutlined, MailOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import sendemail from '../tingtodo.less'
import { type ColumnsType } from 'antd/es/table'

import {
    type HistoryModelState,
    type ToDoCommunicationHistoryTable,
    type TodotHistoryProps,
} from '../../../Types/ToDoViewAllList'
import { GetToDoHistoryDetailsList } from '../../../Services/ToDoViewAllList'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import SearchInput from '../../../Components/SearchInput'

const CommunicationHistory = (props: TodotHistoryProps): React.ReactElement => {
    const {
        serviceProcessId,
        communicationHistoryList,
        communicationHistoryListCount,
        seletedButton,
        setButtonID,
    } = props
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [commnicationID, setCommunicationId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const handleButtonClick = (buttonId: number): void => {
        setCommunicationId(buttonId)
        setButtonID(buttonId === seletedButton ? null : buttonId)
    }

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        if (seletedButton !== null) {
            GetToDoHistoryDetailsList(
                dispatch,
                serviceProcessId,
                seletedButton ?? 0,
                {
                    pageNo,
                    pageSize,
                    searchCriteriaList,
                    sortOrderList,
                }
            )
        }
    }, [dispatch, seletedButton, serviceProcessId, tableParams])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [seletedButton])

    const handleDataCountChange = useCallback(() => {
        setTableParams((prevParams) => ({
            ...prevParams,
            pagination: {
                ...prevParams.pagination,
                total: communicationHistoryListCount,
            },
        }))
    }, [communicationHistoryListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        communicationHistoryListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<ToDoCommunicationHistoryTable> = [
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date',
            dataIndex: 'historyDate',
        },
    ]

    const onChangeTableParams: TableProps<ToDoCommunicationHistoryTable>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'string',
                searchOperator: commnicationID,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Row gutter={25} justify="start">
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={sendemail.historyButton}
                        onClick={() => {
                            handleButtonClick(3)
                        }}
                    >
                        <span className={sendemail.buttontext}>Email</span>
                        <span>
                            <MailOutlined />
                        </span>
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={sendemail.historyButton}
                        onClick={() => {
                            handleButtonClick(1)
                        }}
                    >
                        <span className={sendemail.buttontext}>SMS</span>
                        <span>
                            <MessageOutlined />
                        </span>
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={sendemail.historyButton}
                        onClick={() => {
                            handleButtonClick(2)
                        }}
                    >
                        <span className={sendemail.buttontext}>Phone </span>
                        <span>
                            <PhoneOutlined />
                        </span>
                    </Button>
                </Col>
            </Row>

            {seletedButton != null && communicationHistoryList != null && (
                <>
                    <Row>
                        <SearchInput
                            placeHolder={'Search your keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Row>
                    {communicationHistoryList != null ? (
                        <div className={commonStyles.table}>
                            <Table
                                columns={columns}
                                dataSource={communicationHistoryList}
                                onChange={onChangeTableParams}
                                pagination={pagination}
                            />
                        </div>
                    ) : (
                        <div>No data found</div>
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state: any): HistoryModelState => {
    return {
        communicationHistoryList:
            state.dashboardInfo.communicationHistoryList ?? [],
        communicationHistoryListCount:
            state.dashboardInfo.communicationHistoryListCount,
    }
}

export default connect(mapStateToProps)(CommunicationHistory)
