// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomToTopContent_uLB8y {
  writing-mode: vertical-lr;
  transform: rotate(190deg);
  font-size: 10px;
  margin-bottom: 2px;
  white-space: nowrap;
  padding: 2px 4px;
}
.customTable_zs2vo th {
  font-size: 12px;
  border-right: none;
  border-top: 1px solid #beb8b888;
  border-left: 1px solid #beb8b888;
  border-bottom: 1px solid #beb8b888;
}
.customTable_zs2vo th:first-child {
  border-right: 1px solid #beb8b888;
  padding: 4px 8px;
}
.customTable_zs2vo td {
  border-left: 1px solid #beb8b888;
  border-top: 1px solid #beb8b888;
  font-size: 10px;
}
.servicesContainer__mEsP {
  writing-mode: horizontal-tb;
}
.bottomToTopWrapper_iYGoo {
  margin-left: -6px;
}
.taskover_nIaDR {
  margin-left: -10px;
  margin-right: -10px;
  text-decoration: underline;
}
.dropstyle_j1OFC {
  justify-content: end;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/DashboardStaffwiseReport/staffwise.less"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAMA;EAEI,eAAA;EACA,kBAAA;EACA,+BAAA;EACA,gCAAA;EACA,kCAAA;AALJ;AAQA;EACI,iCAAA;EACA,gBAAA;AANJ;AASA;EACI,gCAAA;EACA,+BAAA;EACA,eAAA;AAPJ;AAUA;EACI,2BAAA;AARJ;AAWA;EACI,iBAAA;AATJ;AAYA;EACI,kBAAA;EACA,mBAAA;EACA,0BAAA;AAVJ;AAaA;EACI,oBAAA;AAXJ","sourcesContent":[".bottomToTopContent {\n    writing-mode: vertical-lr;\n    transform: rotate(190deg);\n    font-size: 10px;\n    margin-bottom: 2px;\n    white-space: nowrap;\n    padding: 2px 4px;\n}\n\n// .customTable {\n//     // border: 1px solid #beb8b888;\n// }\n\n.customTable th {\n    border-bottom: 1px solid #beb8b888;\n    font-size: 12px;\n    border-right: none;\n    border-top: 1px solid #beb8b888;\n    border-left: 1px solid #beb8b888;\n    border-bottom: 1px solid #beb8b888;\n}\n\n.customTable th:first-child {\n    border-right: 1px solid #beb8b888;\n    padding: 4px 8px;\n}\n\n.customTable td {\n    border-left: 1px solid #beb8b888;\n    border-top: 1px solid #beb8b888;\n    font-size: 10px;\n}\n\n.servicesContainer {\n    writing-mode: horizontal-tb;\n}\n\n.bottomToTopWrapper {\n    margin-left: -6px;\n}\n\n.taskover {\n    margin-left: -10px;\n    margin-right: -10px;\n    text-decoration: underline;\n}\n\n.dropstyle {\n    justify-content: end;\n    // margin-left: 800px\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomToTopContent": `bottomToTopContent_uLB8y`,
	"customTable": `customTable_zs2vo`,
	"servicesContainer": `servicesContainer__mEsP`,
	"bottomToTopWrapper": `bottomToTopWrapper_iYGoo`,
	"taskover": `taskover_nIaDR`,
	"dropstyle": `dropstyle_j1OFC`
};
export default ___CSS_LOADER_EXPORT___;
