import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { Form, Collapse, Button, Card, Tooltip } from 'antd'
import type {
    PartneshipHomeProps,
    HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import { EditOutlined, EyeFilled, SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { GetCategoryDropDownList } from '../../../../../../Services/GroupAndPartners'
import { GetCountryList } from '../../../../../../Services/Common'
import commonStyles from '../../../../../../Utils/Common.less'
import InitialDetailsForm from './InitialDetailsForm'
import BussinessAddressForm from './BussinessAddressForm'
import CorresponseAddressForm from './CorresponseAddressForm'

import moment from 'moment'
import {
    setPartnershipHomeData,
    getClientCompanyDetails,
} from '../../../../../../Services/Parnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import SoleTraderConfigurationLess from '../../../../../../Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less'
const HomeList = (props: PartneshipHomeProps): ReactElement => {
    const { partnershipTaps, partnershipId } = props
    const [form] = Form.useForm()

    const [showHomeForm] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const [openPanel, setActivePanel] = useState<string | string[]>(['1'])
    const [isEditing, setIsEditing] = useState(false)
    const [isCorrBussEditing, setIsCorrBussEditing] = useState(false)
    const [termSignedDateformat, setFormatDate] = useState('')
    const [, setSelectedCountry] = useState<string>('')
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>()
    const hasFetchedOnce = useRef(false)
    const handleCategorySelect = (id: number): void => {
        setSelectedCategoryId(id)
    }

    const handleDateChangeCountry = (country: string): void => {
        setSelectedCountry(country)
    }

    const togglePanel = (key: string | string[]): void => {
        if (typeof key === 'string') {
            setActivePanel([key])
        } else {
            setActivePanel(key)
        }
    }

    const toggleEditing = (): void => {
        setIsEditing(!isEditing)
    }

    const coresBussToggleEditing = (): void => {
        setIsCorrBussEditing(!isCorrBussEditing)
    }

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch])

    const updatePage = (): void => {
        setEditMode(true)
        form.submit()
    }
    const viewPage = (): void => {
        setEditMode(false)
    }

    useEffect(() => {
        form.setFieldsValue({
            corraddress1: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.busaddress1
                : partnershipTaps?.correspondanceAddress?.corraddress1,
            corraddress2: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.busaddress2
                : partnershipTaps?.correspondanceAddress?.corraddress2,
            corrtown: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.bustown
                : partnershipTaps?.correspondanceAddress?.corrtown,
            corrcountry: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.buscountry
                : partnershipTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.buspostcode
                : partnershipTaps?.correspondanceAddress?.corrpostcode,
        })
    }, [isCorrBussEditing])

    useEffect(() => {
        form.setFieldsValue({
            partnershipId:
                partnershipTaps?.partnershipInitialDetails?.partnershipId ?? 0,
            clientCategoryId:
                partnershipTaps?.partnershipInitialDetails?.clientCategoryId,
            partnershipUTR:
                partnershipTaps?.partnershipInitialDetails?.partnershipUTR,
            tradingName:
                partnershipTaps?.partnershipInitialDetails?.tradingName,
            dateOfTermSigned:
                partnershipTaps?.partnershipInitialDetails?.dateOfTermSigned !=
                null
                    ? moment(
                          partnershipTaps?.partnershipInitialDetails
                              ?.dateOfTermSigned
                      )
                    : null,

            busaddress1: partnershipTaps?.businessAddress?.busaddress1,
            busaddress2: partnershipTaps?.businessAddress?.busaddress2,
            bustown: partnershipTaps?.businessAddress?.bustown,
            buscountry: partnershipTaps?.businessAddress?.buscountry,
            buspostcode: partnershipTaps?.businessAddress?.buspostcode,
            corraddress1: partnershipTaps?.correspondanceAddress?.corraddress1,
            corraddress2: partnershipTaps?.correspondanceAddress?.corraddress2,
            corrtown: partnershipTaps?.correspondanceAddress?.corrtown,
            corrcountry: partnershipTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: partnershipTaps?.correspondanceAddress?.corrpostcode,
        })
        const termSignedDate =
            partnershipTaps?.partnershipInitialDetails?.dateOfTermSigned

        if (termSignedDate != null) {
            const dateObject = new Date(termSignedDate)

            // Extracting date components
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1 // Month is zero-based
            const day = dateObject.getDate()

            // Formatting the date
            const formattedDate = `${month}/${day}/${year}`
            setFormatDate(formattedDate)
        }
    }, [partnershipTaps])

    const handleFormSubmit = async (): Promise<void> => {
        try {
            await form.validateFields()
            const values = await form.getFieldsValue()
            console.log('valus', values)
            const apiPayload = {
                partnershipId: values.partnershipId ?? 0,
                clientCategoryId: values.clientCategoryId ?? selectedCategoryId,
                clientNumber:
                    values.clientNumber ??
                    partnershipTaps?.partnershipInitialDetails?.clientNumber,
                tradingName:
                    values.tradingName ??
                    partnershipTaps?.partnershipInitialDetails?.tradingName,
                partnershipUTR:
                    values.partnershipUTR ??
                    partnershipTaps?.partnershipInitialDetails?.partnershipUTR,
                busAddressOne:
                    values.busaddress1 ??
                    partnershipTaps?.businessAddress?.busaddress1,
                busAddressTwo:
                    values.busaddress2 ??
                    partnershipTaps?.businessAddress?.busaddress2,
                busTown:
                    values.bustown ?? partnershipTaps?.businessAddress?.bustown,
                busCountry:
                    values.busCountry ??
                    partnershipTaps?.businessAddress?.buscountry,
                busPostCode:
                    values.buspostcode ??
                    partnershipTaps?.businessAddress?.buspostcode,
                corrAddressOne:
                    values.corraddress1 ??
                    partnershipTaps?.correspondanceAddress?.corraddress1,
                corrAddressTwo:
                    values.corraddress2 ??
                    partnershipTaps?.correspondanceAddress?.corraddress2,
                corrTown:
                    values.corrtown ??
                    partnershipTaps?.correspondanceAddress?.corrtown,
                corrCountry:
                    values.corrcountry ??
                    partnershipTaps?.correspondanceAddress?.corrcountry,
                corrPostCode:
                    values.corrpostcode ??
                    partnershipTaps?.correspondanceAddress?.corrpostcode,
                isActive: 'active',
                isDelete: 'inactive',
            }
            await setPartnershipHomeData(dispatch, apiPayload)
            await getClientCompanyDetails(dispatch, 4, partnershipId, 1)
            setEditMode(false)
        } catch {}
    }

    const dataToSend = (): void => {
        void handleFormSubmit()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const IntialDetails = partnershipTaps?.partnershipInitialDetails

    const InitialDetails = (): React.ReactElement => {
        return editMode ? (
            <InitialDetailsForm
                partneshipTaps={partnershipTaps}
                onCategorySelect={handleCategorySelect}
            />
        ) : (
            <div className={commonStyles.corporateInform}>
                <div className={commonStyles.corporateBasicDetails}>
                    <p>Client Category:</p>
                    <p>Trading Name:</p>
                    <p>Partnership UTR:</p>
                </div>
                <div className={commonStyles.corporateBasicDetails}>
                    <p>: {IntialDetails?.clientCategoryName}</p>
                    <p>: {IntialDetails?.tradingName}</p>
                    <p>: {IntialDetails?.partnershipUTR}</p>
                </div>
            </div>
        )
    }
    const bussinessAdd = partnershipTaps?.businessAddress
    const BussinessAddress = (): React.ReactElement => {
        return editMode ? (
            <>
                <BussinessAddressForm
                    isEditing={isEditing}
                    toggleEditing={toggleEditing}
                    onDateChange={handleDateChangeCountry}
                />
            </>
        ) : (
            <>
                <div className={commonStyles.corporateInform}>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>Address 1</p>
                        <p>Address 2</p>
                        <p>Town</p>
                        <p>Country</p>
                        <p>Post Code</p>
                    </div>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>
                            <p>: {bussinessAdd?.busaddress1}</p>
                            <p>: {bussinessAdd?.busaddress2}</p>
                            <p>: {bussinessAdd?.bustown}</p>
                            <p>: {bussinessAdd?.buscountry}</p>
                            <p>: {bussinessAdd?.buspostcode}</p>
                        </p>
                    </div>
                </div>
            </>
        )
    }
    const coreespondAdd = partnershipTaps?.correspondanceAddress
    const CorrespondenceAddress = (): React.ReactElement => {
        return editMode ? (
            <>
                <CorresponseAddressForm
                    toggleCorrBussEditing={coresBussToggleEditing}
                    edit={isCorrBussEditing}
                />
            </>
        ) : (
            <>
                <div className={commonStyles.corporateInform}>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>Address 1</p>
                        <p>Address 2</p>
                        <p>Town</p>
                        <p>Country</p>
                        <p>Post Code</p>
                    </div>
                    <div className={commonStyles.corporateBasicDetails}>
                        <p>
                            <p>: {coreespondAdd?.corraddress1}</p>
                            <p>: {coreespondAdd?.corraddress2}</p>
                            <p>: {coreespondAdd?.corrtown}</p>
                            <p>: {coreespondAdd?.corrcountry}</p>
                            <p>: {coreespondAdd?.corrpostcode}</p>
                        </p>
                    </div>
                </div>
            </>
        )
    }
    return (
        <Card className={commonStyles.card}>
            <Form form={form} requiredMark={customizeRequiredMark}>
                <div>
                    <div className={commonStyles.homeListBasic}>
                        <div>
                            <h3>Basic Information</h3>
                        </div>
                        <div>
                            {!showHomeForm &&
                                !editMode &&
                                ActivePremission(
                                    '3000',
                                    PermissionType.UPDATE
                                ) && (
                                    <Tooltip title={'Edit Basic Information'}>
                                        <Button
                                            type="primary"
                                            onClick={updatePage}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </Tooltip>
                                )}
                            {editMode && (
                                <Tooltip title={'View Basic Information'}>
                                    <Button type="primary" onClick={viewPage}>
                                        <EyeFilled />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    <div>
                        <h4>
                            Term signed is activated on {termSignedDateformat}{' '}
                            this company
                        </h4>
                    </div>
                </div>

                <div
                    className={commonStyles.flexDirectionRow}
                    style={{ width: '100%' }}
                >
                    <div
                        className={
                            SoleTraderConfigurationLess.configurationRowWithMargin
                        }
                    >
                        <Collapse
                            defaultActiveKey={1}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Initial Details',
                                    children: InitialDetails(),
                                },
                            ]}
                            activeKey={openPanel}
                            onChange={togglePanel}
                            accordion
                        />
                    </div>
                    <div
                        className={SoleTraderConfigurationLess.configurationRow}
                    >
                        <Collapse
                            bordered={true}
                            items={[
                                {
                                    key: '2',
                                    label: 'Bussiness Address',
                                    children: BussinessAddress(),
                                },
                            ]}
                            activeKey={openPanel}
                            onChange={togglePanel}
                            accordion
                        />
                        <Collapse
                            bordered={true}
                            items={[
                                {
                                    key: '3',
                                    label: 'Correspondence Address',
                                    children: CorrespondenceAddress(),
                                },
                            ]}
                            activeKey={openPanel}
                            onChange={togglePanel}
                            accordion
                        />
                    </div>
                </div>

                {editMode && (
                    <div className={commonStyles.homeListBasic}>
                        <div></div>
                        {ActivePremission('3000', PermissionType.SAVE) && (
                            <div>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        dataToSend()
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </Card>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(HomeList)
