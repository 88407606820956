import React from 'react'
import { Table } from 'antd'
import commontStyles from '../../../../Utils/Common.less'
import { type SoleTraderExpandViewInterface } from '../../../../Types/Client/RegisteredClients/SoleTrader'

const SoleTraderExpandView = (
    props: SoleTraderExpandViewInterface
): React.ReactElement => {
    const { soleTraderData } = props
    const columns = [
        {
            title: 'Name',
            dataIndex: 'contactName',
        },
        {
            title: 'Contact No',
            dataIndex: 'mobilenumber',
        },
        {
            title: 'Email ',
            dataIndex: 'emailAddress',
        },
    ]

    const dataSource = [
        {
            key: '1',
            contactName: soleTraderData.contactName,
            mobilenumber: soleTraderData.mobilenumber,
            emailAddress: soleTraderData.emailAddress,
        },
    ]

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default SoleTraderExpandView
