import {
    Button,
    Card,
    Flex,
    Space,
    Table,
    Tooltip,
    type TableProps,
} from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import SearchInput from '../../../Components/SearchInput'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {
    type CompanyDetailsListDataTypeInterface,
    type ComapnyListInterface,
} from '../../../Types/CompanyHouse/CompanyFormation'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import commonStyles from '../../../Utils/Common.less'
import { type ColumnsType } from 'antd/es/table'
import {
    DeleteCompanyDetails,
    GetCompanyFomationCompanyList,
} from '../../../Services/CompanyHouse/CompanyFormation'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import FullWidthModal from '../../../Components/FullWidthModal'
const CompanyFormation = (props: ComapnyListInterface): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { ComapnyDetailsList, CompanyDetailsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [companyId, setCompanyId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetCompanyFomationCompanyList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: CompanyDetailsListCount,
            },
        })
    }, [CompanyDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        CompanyDetailsListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<CompanyDetailsListDataTypeInterface> = [
        {
            title: 'Company Name',
            dataIndex: 'companyName',
        },
        {
            title: 'Company Type',
            dataIndex: 'companyTypeName',
        },
        {
            title: 'Country of Incorporation',
            dataIndex: 'countryofIncorporation',
        },
        {
            title: '',
            key: 'action',
            render: (_, record: CompanyDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Delete">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(record?.companyId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<CompanyDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'CompanyName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: number): void => {
        setCompanyId(id)
        setDeleteModalStatus(!deleteModalStatus)
    }

    const deleteCompanyDetails = (): void => {
        DeleteCompanyDetails(dispatch, companyId, getTableData)
        setDeleteModalStatus(!deleteModalStatus)
    }
    return (
        <>
            <div>
                <h2>Company List</h2>
            </div>
            <Card title="Company List">
                <Flex justify="space-between">
                    <SearchInput
                        placeHolder={'Search your keywords and entre'}
                        onSearch={onChangeFilter}
                        onChange={onChangeText}
                        width="478"
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`/company-house/company-eligibility`)
                        }}
                    >
                        <SearchOutlined />
                    </Button>
                </Flex>
                {ComapnyDetailsList?.length > 0 ? (
                    <div className={commonStyles.table}>
                        <Table
                            columns={columns}
                            dataSource={ComapnyDetailsList}
                            onChange={onChangeTableParams}
                            pagination={pagination}
                        />
                    </div>
                ) : (
                    <span>No Records Found</span>
                )}
            </Card>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={() => {
                    deleteModalFunction(0)
                }}
                tittle="You are about to delete this delete this company. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteCompanyDetails}
            />
        </>
    )
}

const mapStateToProps = (state: any): ComapnyListInterface => {
    return {
        ComapnyDetailsList: state.companyHouse.ComapnyDetailsList,
        CompanyDetailsListCount: state.companyHouse.CompanyDetailsListCount,
    }
}

export default connect(mapStateToProps)(CompanyFormation)
