import React from 'react'
import { Modal, Button, Row, Col } from 'antd'
import styles from './Less/fullWidthModal.less'

interface FullWidthModalInterface {
    modalStatus: boolean
    closeModal: (data: string) => void
    tittle: string
    smallTittle: string
    yesButtonFunction: () => void
}

const FullWidthModal = (props: FullWidthModalInterface): React.ReactElement => {
    const { modalStatus, closeModal, tittle, smallTittle, yesButtonFunction } =
        props

    return (
        <Modal
            centered
            open={modalStatus}
            width={'100%'}
            footer={null}
            closable={false}
            mask={true}
        >
            <p className={styles.paragraph}>
                <div className={styles.tittle}>{tittle}</div>
                <div>{smallTittle}</div>

                <Row gutter={16}>
                    <Col offset={18} span={4}>
                        <Button
                            size="large"
                            onClick={yesButtonFunction}
                            className={styles.yesButton}
                        >
                            Yes
                        </Button>
                        <Button
                            size="large"
                            onClick={() => {
                                closeModal('')
                            }}
                        >
                            No
                        </Button>
                    </Col>
                </Row>
            </p>
        </Modal>
    )
}

export default FullWidthModal
