// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rememberMeText_vf0Gp {
  color: #718096;
  font-weight: 400;
  font-size: 14px;
}
.inputLabel_hgS4C {
  color: #718096;
  font-size: 16px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/input.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;AACJ;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".rememberMeText {\n    color: #718096;\n    font-weight: 400;\n    font-size: 14px;\n}\n\n.inputLabel {\n    color: #718096;\n    font-size: 16px;\n    font-weight: 500;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rememberMeText": `rememberMeText_vf0Gp`,
	"inputLabel": `inputLabel_hgS4C`
};
export default ___CSS_LOADER_EXPORT___;
