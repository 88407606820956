import type { Dispatch } from '@reduxjs/toolkit'
import { setLoadingStatus } from '../../Reducer/Common'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { type TableListInterface } from '../../Types/CommonType'
import {
    TaskAttachmentList,
    follwersList,
    hoursList,
    sprintBackLogList,
    sprintList,
    sprintTaskList,
    userimageList,
} from '../../Reducer/Backlog'
import {
    type GetSprintListInterfaceType,
    type createSprintForm,
    type TaskAttachment,
    type GetSprintTaskForminterfaceType,
    type TaskFollowerRequestBody,
} from '../../Types/Backlog/Backlog'

export const setSprint = async (
    dispatch: Dispatch,
    data: createSprintForm
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Sprint/SetSprint`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetSprint = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/Sprint/GetSprintList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: GetSprintListInterfaceType) => {
                        return {
                            ...value,
                            key: value.sprintId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(sprintList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetSprintTask = (
    dispatch: Dispatch,
    data: GetSprintTaskForminterfaceType
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const {
        sprintId,
        flag,
        searchString,
        pageNo,
        pageSize,
        assignedBy,
        assignedTo,
    } = data

    axios
        .get(
            `/api/Task/GetSprintTaskList/${sprintId}/${flag}/${searchString}/${pageNo}/${pageSize}/${assignedBy}/${assignedTo}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: any) => {
                    return {
                        ...value,
                        key: value.taskId,
                    }
                })
                response.data.data = newData
                dispatch(sprintTaskList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetBacklog = (dispatch: Dispatch, data: any): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { flag, searchString, pageNo, pageSize, assignedBy, assignedTo } =
        data

    axios
        .get(
            `/api/Task/GetBackLogTaskList/${flag}/${searchString}/${pageNo}/${pageSize}/${assignedBy}/${assignedTo}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: any) => {
                    return {
                        ...value,
                        key: value.taskId,
                    }
                })
                response.data.data = newData
                dispatch(sprintBackLogList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getattachmentdroplist = async (
    dispatch: Dispatch,
    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/Task/GetTaskAttachmentListByTaskId/${taskId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(TaskAttachmentList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveAttachment = async (
    dispatch: Dispatch,
    data: TaskAttachment
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskAttachment `, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const TaskMoveToSprint = (dispatch: Dispatch, data: any): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { typeId, taskId, sprintId, flagId } = data

    axios
        .get(
            `api/Task/SetTaskToSprint/${typeId}/${taskId}/${sprintId}/${flagId}`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getusersdroplist = async (dispatch: Dispatch): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/GetUserDetails`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(userimageList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveHours = async (
    dispatch: Dispatch,
    data: any
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskHours`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const gethourssdroplist = async (
    dispatch: Dispatch,
    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/Task/GetTaskHoursListByTaskId/${taskId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(hoursList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getfollwersdroplist = async (
    dispatch: Dispatch,
    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/Task/GetTaskFollowerDetailsByTaskId/${taskId}`)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.data !== null) {
                    dispatch(follwersList({ ...response.data }))
                    return response.data
                } else {
                    dispatch(
                        follwersList({
                            data: [],
                            noOfRecords: 0,
                        })
                    )
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const Savefollowers = async (
    dispatch: Dispatch,
    data: TaskFollowerRequestBody
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskFollower `, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
