import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    Row,
    Col,
    List,
    Tag,
    Button,
    Select,
    Flex,
    Spin,
    Pagination,
    Empty,
    Modal,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './Itindex.less'
// import { ReactComponent as VerticalThreeDots } from '../../Assest/Svg/VerticalThreeDots.svg'
import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import axios from '../../../Config/AxiosConfig'
import { type TaskType } from '../../../Types/Task'
import { ErrorMessage } from '../../../Utils/Notification'
import { useNavigate } from 'react-router-dom'
import CreateTask from '../../../Components/CreateTask'

interface ListDataType {
    taskId: number
    title: string
    times: string
    priorityName: string
    priorityColourCode: string
    status: string
    statusColourCode: string
    completeStatus: string
}

const MyTask = (): React.ReactElement => {
    const navigate = useNavigate()
    const [data, setData] = useState<ListDataType[]>([])
    const [flag, setFlag] = useState(0)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [noOfRecords, setNoOfRecords] = useState(0)
    const [taskmodelOpen, setTsakModelOpen] = useState(false)
    const setTimeString = (date: string): string => {
        const dateList = date.split(/[ ,]+/)[1].split(':')
        const returnDate = dateList[0] + ':' + dateList[1]
        return returnDate
    }
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString !== null ? JSON.parse(tokenDetailsString) : null
    const [restData, setResetData] = useState('false')
    const setTimes = (from: string, to: string): string => {
        let str
        if (from !== undefined && from !== null && to !== null) {
            const fromDate: string = setTimeString(from)
            const toDate: string = setTimeString(to)
            if (fromDate === toDate) {
                str = fromDate
            } else {
                str = fromDate + ' - ' + toDate
            }
        } else {
            str = ''
        }
        return str
    }

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        void getTaskList()
    }, [])

    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        void getTaskList()
    }, [pageNumber, flag])

    const getTaskList = async (): Promise<void> => {
        setLoading(true)
        await axios
            .get(
                `api/Task/GetTaskListAssignedTo/${flag}/=NA=/${pageNumber}/10/${tokenDetails?.userId}`
            )
            .then((response: any) => {
                if (response.status === 200) {
                    setNoOfRecords(response?.data?.noOfRecords)
                    const newData = response?.data?.data?.map(
                        (item: TaskType) => {
                            return {
                                taskId: item?.taskId,
                                title: item?.taskName,
                                times: setTimes(item?.fromDate, item?.toDate),
                                priorityName: item.priorityName,
                                priorityColourCode: item.priorityColourCode,
                                status: item?.status,
                                statusColourCode: item.statusColourCode,
                                completeStatus:
                                    flag === 0
                                        ? 'To-DO'
                                        : flag === 1
                                          ? 'In-progress'
                                          : 'Completed',
                            }
                        }
                    )
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const openTask = (): void => {
        setTsakModelOpen(!taskmodelOpen)
    }
    const CloseTaskModel = (): void => {
        setTsakModelOpen(!taskmodelOpen)
    }
    return (
        <div className={`${styles.marginRight10} ${styles.marginLeft10}`}>
            <Card className={`${styles.card}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <div className={styles.myTask__tittle}>
                                    Tasks
                                </div>
                            </div>
                        </Col>
                        <Col span={12} className={styles.textAlignRight}>
                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                onClick={() => {
                                    openTask()
                                    setResetData('true')
                                }}
                            >
                                Add Task
                            </Button>
                        </Col>
                    </Row>

                    {isLoading ? (
                        <Flex
                            align="center"
                            justify="center"
                            style={{ padding: '20px 0px' }}
                        >
                            <Spin />
                        </Flex>
                    ) : (
                        <>
                            <Row className={styles.marginTop10}>
                                <Col span={20}>
                                    <Select
                                        defaultValue={flag}
                                        variant={undefined}
                                        className={styles.myTask__select}
                                        onChange={(e) => {
                                            setFlag(e)
                                        }}
                                        options={[
                                            {
                                                value: 0,
                                                label: 'To-DO',
                                            },
                                            {
                                                value: 1,
                                                label: 'In-progress',
                                            },
                                            {
                                                value: 2,
                                                label: 'Completed',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col span={4}>
                                    <div
                                        className={styles.myTask__viewall}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate('/help-desk/create-task')
                                        }}
                                    >
                                        View All
                                    </div>
                                </Col>
                            </Row>
                            <Row className={styles.marginTop10}>
                                <Col span={24}>
                                    {data.length > 0 ? (
                                        <List>
                                            <VirtualList
                                                data={data}
                                                height={300}
                                                itemKey="taskId"
                                            >
                                                {(
                                                    item: ListDataType,
                                                    index: number
                                                ) => (
                                                    <List.Item
                                                        key={index}
                                                        className={
                                                            styles.myTask__list__item
                                                        }
                                                    >
                                                        <Row>
                                                            <Col span={23}>
                                                                <div
                                                                    className={
                                                                        styles.myTask__list__item__tittle
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </div>
                                                            </Col>
                                                            {/* <Col
                                                                span={1}
                                                                className={
                                                                    styles.textAlignRight
                                                                }
                                                            >
                                                                <VerticalThreeDots />
                                                            </Col> */}
                                                        </Row>
                                                        <Row
                                                            className={
                                                                styles.marginTop10
                                                            }
                                                        >
                                                            <Col span={8}>
                                                                <Tag
                                                                    className={
                                                                        styles.myTask__list__item__tag__one
                                                                    }
                                                                    color={
                                                                        item.priorityColourCode
                                                                    }
                                                                >
                                                                    {
                                                                        item.priorityName
                                                                    }
                                                                </Tag>
                                                            </Col>
                                                            <Col span={9}>
                                                                <div
                                                                    className={
                                                                        styles.myTask__list__item__status
                                                                    }
                                                                >
                                                                    <div>
                                                                        Status:
                                                                    </div>
                                                                    &nbsp;
                                                                    <div
                                                                        className={
                                                                            styles.myTask__list__item__dot
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                item.statusColourCode,
                                                                        }}
                                                                    />
                                                                    &nbsp;
                                                                    <div>
                                                                        {
                                                                            item.status
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col
                                                                span={7}
                                                                className={
                                                                    styles.textAlignRight
                                                                }
                                                            >
                                                                <Tag
                                                                    className={
                                                                        styles.myTask__list__itme__complete__status
                                                                    }
                                                                    color={
                                                                        '#FFFFFF'
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <CheckOutlined />
                                                                        <span
                                                                            style={{
                                                                                paddingLeft:
                                                                                    '4px',
                                                                                paddingBottom:
                                                                                    '1px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.completeStatus
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </Tag>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            </VirtualList>
                                        </List>
                                    ) : (
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row
                                className={styles.marginTop10}
                                style={{ width: '100%' }}
                            >
                                <Col
                                    span={24}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <Pagination
                                        defaultCurrent={pageNumber}
                                        total={noOfRecords}
                                        onChange={(e) => {
                                            setPageNumber(e)
                                        }}
                                        showSizeChanger={false}
                                        showQuickJumper={false}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </Card>
            <Modal
                footer={null}
                onCancel={CloseTaskModel}
                width={'60%'}
                title={'Create Task'}
                open={taskmodelOpen}
            >
                <CreateTask
                    onClose={CloseTaskModel}
                    setResetData={setResetData}
                    resetdata={restData}
                />
            </Modal>
        </div>
    )
}

export default MyTask
