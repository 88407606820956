import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, Dropdown, Flex, type MenuProps, Modal, Row } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import ApplyLeave from './ApplyLeave'
import ApplyPermission from './ApplyPermission'
import CalenderComponent from './CalenderComponent'
import ApplyLeaveModule from './ApplyLeaveModule.less'
import { DownOutlined } from '@ant-design/icons'
const LeavePermissionList = (): React.ReactElement => {
    const dispatch = useDispatch()
    const [applyLeave, setApplyLeave] = useState(false)
    const [applyPermission, setApplyPermission] = useState(false)
    const [activeButton, setActiveButton] = useState(1)
    const [showCalender, setShowCalender] = useState(true)
    const hasFetchedOnce = useRef(false)
    const [restData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleApplyLeave = (): void => {
        setApplyLeave(true)
        setResetData('true')
    }

    const handleApplyPermission = (): void => {
        setApplyPermission(true)
        setResetData('true')
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setShowCalender(true)
        } else if (buttonName === 2) {
            setShowCalender(true)
        }
    }

    // const handleOtherFunction = (): React.ReactElement => {
    //     if (activeButton === 1) {
    //         return <CalenderComponent setActive={true} />
    //     } else if (activeButton === 2) {
    //         return <CalenderComponent setActive={false} />
    //     }

    //     return <></>
    // }

    const itemcompliance: MenuProps['items'] = [
        {
            key: '1',
            label: 'Apply Leave',
            onClick: handleApplyLeave,
        },
        {
            key: '2',
            label: 'Apply Permission',
            onClick: handleApplyPermission,
        },
    ]
    const itemview: MenuProps['items'] = [
        {
            key: '1',
            label: 'Personal Calendar',
            onClick: () => {
                handleButtonClick(1)
            },
        },
        {
            key: '2',
            label: 'Team Calendar',
            onClick: () => {
                handleButtonClick(2)
            },
        },
    ]
    return (
        <>
            <Card title="Leave Calender" className={commonStyles.card}>
                <Row>
                    <Flex gap={20}>
                        <Dropdown
                            arrow={{ pointAtCenter: true }}
                            menu={{ items: itemcompliance }}
                            trigger={['click']}
                        >
                            <Button>
                                Apply <DownOutlined />
                            </Button>
                        </Dropdown>
                        <Dropdown
                            arrow={{ pointAtCenter: true }}
                            menu={{ items: itemview }}
                            trigger={['click']}
                        >
                            <Button>
                                View <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Flex>
                </Row>
                {/* <Row>
                    <Col>
                        <DefaultButton
                            name="Apply Leave"
                            clickAction={() => {
                                handleApplyLeave()
                            }}
                        />
                    </Col>
                    <Col className={ApplyLeaveModule.btnSpace}>
                        <DefaultButton
                            name="Apply Permission"
                            clickAction={() => {
                                handleApplyPermission()
                            }}
                        />
                    </Col>
                </Row> */}
                <Row className={ApplyLeaveModule.rowSpace}>
                    {/* <CommonButton
                        name="Team Calender"
                        isActive={activeButton === 1}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(1)
                        }}
                    />
                    <CommonButton
                        name="Personal Calendar"
                        isActive={activeButton === 2}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(2)
                        }}
                    /> */}
                </Row>
                <Modal
                    title="Apply Leave"
                    open={applyLeave}
                    onCancel={() => {
                        setApplyLeave(false)
                        setResetData('true')
                    }}
                    footer={null}
                    width={'60%'}
                >
                    <ApplyLeave
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>
                <Modal
                    title="Apply Permission"
                    open={applyPermission}
                    onCancel={() => {
                        setApplyPermission(false)
                        setResetData('true')
                    }}
                    footer={null}
                    width={'60%'}
                >
                    <ApplyPermission
                        LeaveType={0}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>
                {showCalender && activeButton === 1 && (
                    <CalenderComponent setActive={true} />
                )}
                {showCalender && activeButton === 2 && (
                    <CalenderComponent setActive={false} />
                )}
            </Card>
        </>
    )
}

export default LeavePermissionList
