import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useDispatch, connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    type MailModelState,
    type ModelProps,
} from '../../../Types/ToDoViewAllList'
import { GetServiceProcessEmailTemplate } from '../../../Services/Common'

const MailTempalteModel = (props: ModelProps): React.ReactElement => {
    const {
        serviceProcessId,
        servicePrcessEmailTemplate,
        serviceId,
        emailPageShow,
    } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [form] = Form.useForm()
    const [tempId, setTempId] = useState(0)
    useEffect(() => {
        if (serviceId !== undefined) {
            GetServiceProcessEmailTemplate(dispatch, serviceId)
        }
    }, [serviceId])

    const handelgetTemplateId = (value: number): void => {
        setTempId(value)
    }

    const handelOnclick = (): void => {
        if (tempId !== 0) {
            const invoiceIds = serviceProcessId
            if (emailPageShow === 0) {
                navigate(`/thingto-do/service-process/send-email`, {
                    state: { tempId, invoiceIds, emailPageShow, serviceId },
                })
            } else {
                navigate(`/thingto-do/send-email`, {
                    state: { tempId, invoiceIds, emailPageShow, serviceId },
                })
            }
        } else {
            void form.validateFields()
        }
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item
                    name="invoiceId"
                    hidden={true}
                    initialValue={serviceProcessId}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email Template"
                            name="contactName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Email Template!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={(value) => {
                                    handelgetTemplateId(value)
                                }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={servicePrcessEmailTemplate}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={17} span={2}>
                        <Button
                            type="primary"
                            onClick={handelOnclick}
                            size="middle"
                        >
                            Continue <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): MailModelState => {
    return {
        servicePrcessEmailTemplate: state.common.servicePrcessEmailTemplate,
    }
}

export default connect(mapStateToProps)(MailTempalteModel)
