import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Select, Row, Card, Table } from 'antd'
import { DatabaseOutlined } from '@ant-design/icons'
import contactInfoStyle from '../../../Less/missininfo.less'
import {
    type propertyFormDataInterface,
    type propertyListInterface,
} from '../../../Types/MiisngInformation/PropertyView'
import {
    businesstypecatergoryList,
    missinglist,
    GetMissedField,
} from '../../../Services/MissingInformation/PrpertyView'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import SearchInput from '../../../Components/SearchInput'
import commonStyles from '../../../Utils/Common.less'
import { useNavigate } from 'react-router-dom'
import { getClientCompanyDetails } from '../../../Services/Corporate'

const DepartmentList = (props: propertyListInterface): React.ReactElement => {
    const {
        missingdroplist,
        businessstypedropdownlist,
        categoryDropDownList,
        MissinginformationprpertyviewList,
        soleTraderList,
        individualList,
        MissinginformationprpertyviewListCount,
    } = props

    const [catergoryid, setSelectedId] = useState(0)
    const [businesstypeid, setSelectedServiceId] = useState(0)
    const [miisedfieldid, setmiisefieldid] = useState(1)
    const [miisefieldname, setmiisefieldidname] = useState(
        'Authentication Code'
    )

    const [showAdditionalFields, setShowAdditionalFields] = useState(true)
    const [searchString, setSearchString] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        GetCategoryDropDownList(dispatch)
        businesstypecatergoryList(dispatch)
        missinglist(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        GetMissedField(
            dispatch,
            searchString,
            miisedfieldid,
            catergoryid,
            businesstypeid,
            currentPage,
            itemsPerPage
        )
    }, [
        searchString,
        miisedfieldid,
        catergoryid,
        businesstypeid,
        itemsPerPage,
        currentPage,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        if (data.length !== 0) {
            setSearchString(data)
        } else {
            setSearchString('')
        }
    }
    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 0 : Number(value))
    }

    const handleSelectChange1 = (value: string | number): void => {
        setSelectedServiceId(value === 'all' ? 0 : Number(value))
    }

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const createCorporate = (id: string): void => {
        void ViewCorporate(id)
    }
    const ViewCorporate = async (id: string): Promise<void> => {
        localStorage.removeItem('corporateData')
        localStorage.removeItem('onbordingId')
        const data = await getClientCompanyDetails(dispatch, 1, Number(id), 1)
        const corporateData = {
            clienttypeid: data?.data?.initialDetails?.clienttypeid ?? 1,
            clienttype: 'Corporate',
            corporateid: id,
            companyname: data?.data?.initialDetails.companyname,
            clientnumber: data?.data?.initialDetails.clientnumber,
            companyregisternumber: data?.data?.initialDetails.companyregnumber,
        }

        const onbordingId = 0
        localStorage.setItem('corporateData', JSON.stringify(corporateData))
        localStorage.setItem('onbordingId', onbordingId.toString())
        navigate(`/clients/registered-clients/corporate-view`)
    }

    const viewSoleTraderDetails = (id: string): void => {
        const SoleTraderDatas = soleTraderList.find(
            (item: { soleTraderId: string }) => item.soleTraderId === id
        )
        const SoleTraderData = {
            clientTypeId: SoleTraderDatas?.companyType ?? 2,
            companyTypeName: 'Soletrader',
            soleTraderId: id,
            companyName: SoleTraderDatas?.companyName,
            description: SoleTraderDatas?.description,
            remarks: SoleTraderDatas?.remarks,
        }
        navigate(`/clients/registered-clients/soletrader-view`, {
            state: { SoleTraderData },
        })
    }
    const viewIndividualDetails = (id: string): void => {
        const individualDatas = individualList.find(
            (item: { individualId: string }) => item.individualId === id
        )
        const individualData = {
            clientTypeId: individualDatas?.companyType ?? 3,
            companyTypeName: 'Individual',
            individualId: id,
            companyName: individualDatas?.companyName,
            description: individualDatas?.description,
            remarks: individualDatas?.remarks,
        }
        navigate(`/clients/registered-clients/individual-view`, {
            state: { individualData },
        })
    }

    const columns: ColumnsType<propertyFormDataInterface> = [
        {
            render: (text, record) => (
                <span
                    onClick={() => {
                        if (record.companyTypeName === 'Corporate') {
                            createCorporate(record.referenceId)
                        } else if (record.companyTypeName === 'SoleTrader') {
                            viewSoleTraderDetails(record.referenceId)
                        } else if (record.companyTypeName === 'Individual') {
                            viewIndividualDetails(record.referenceId)
                        }
                    }}
                    className={contactInfoStyle.point}
                >
                    {record.companyName}
                </span>
            ),
        },
    ]
    const onChangeTableParams: TableProps<propertyFormDataInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 20)
        }

    return (
        <>
            <>
                <Card title="Missing Information" className={commonStyles.card}>
                    <Row>
                        <Col>
                            <div className={contactInfoStyle.divmiss}>
                                {missingdroplist?.map((property, index) => (
                                    <div
                                        key={index}
                                        className={contactInfoStyle.divmissed}
                                        onClick={() => {
                                            setmiisefieldid(property.value)
                                            setmiisefieldidname(property.label)
                                            setShowAdditionalFields(true)
                                            onChangeFilter('')
                                        }}
                                    >
                                        <DatabaseOutlined
                                            className={
                                                contactInfoStyle.databasse
                                            }
                                        />{' '}
                                        {property.label}
                                    </div>
                                ))}
                            </div>
                        </Col>
                        {showAdditionalFields && (
                            <div>
                                <Row>
                                    <Col>{miisefieldname}</Col>
                                </Row>
                                <Row>
                                    <Col className={contactInfoStyle.miscol}>
                                        <SearchInput
                                            placeHolder={'Search  by keywords'}
                                            onChange={onChangeText}
                                            onSearch={onChangeFilter}
                                            width="478"
                                            value={searchString}
                                        />
                                    </Col>
                                </Row>
                                <div
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Select
                                        className={commonStyles.topdropdown2}
                                        placeholder="Please select"
                                        onChange={handleSelectChange}
                                        value={
                                            catergoryid === 0
                                                ? 'all'
                                                : catergoryid
                                        }
                                        options={[
                                            { value: 'all', label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                    />
                                    <Select
                                        className={commonStyles.topdropdown}
                                        placeholder="Please select"
                                        onChange={handleSelectChange1}
                                        value={
                                            businesstypeid === 0
                                                ? 'all'
                                                : businesstypeid
                                        }
                                        options={[
                                            { value: 'all', label: 'All' },
                                            ...businessstypedropdownlist,
                                        ]}
                                    />
                                </div>
                                <div style={{ marginTop: '50px' }}>
                                    <Table
                                        showHeader={false}
                                        columns={columns}
                                        dataSource={
                                            MissinginformationprpertyviewList
                                        }
                                        onChange={onChangeTableParams}
                                        pagination={
                                            MissinginformationprpertyviewListCount >
                                            20
                                                ? {
                                                      current: currentPage,
                                                      pageSize: itemsPerPage,
                                                      total: MissinginformationprpertyviewListCount,
                                                      onChange:
                                                          handlePageChange,
                                                  }
                                                : false
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </Row>
                </Card>
            </>
        </>
    )
}

const mapStateToProps = (state: any): propertyListInterface => {
    return {
        missingdroplist: state.missing.missingdroplist,
        businessstypedropdownlist: state.initial.businessstypedropdownlist,
        categoryDropDownList: state.initial.categoryDropDownList,
        MissinginformationprpertyviewList:
            state.missing.MissinginformationprpertyviewList,
        corporateList: state.client.corporateList,
        soleTraderList: state.client.soleTraderList,
        partnershipList: state.client.partnershipList,
        individualList: state.client.individualList,
        MissinginformationprpertyviewListCount:
            state.missing.MissinginformationprpertyviewListCount,
    }
}

export default connect(mapStateToProps)(DepartmentList)
