import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { type ArticalExpandViewInterface } from '../../../Types/wikiArtical'

const ArticalDetailsExpandView = (
    props: ArticalExpandViewInterface
): React.ReactElement => {
    const { articalExpandData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>Content</Row>
            <Row>
                <div
                    dangerouslySetInnerHTML={{
                        __html: articalExpandData.articleDescription,
                    }}
                    style={{ height: 'auto' }}
                />
            </Row>
        </div>
    )
}

export default ArticalDetailsExpandView
