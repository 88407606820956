import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import type {
    ListBackendInterface,
    ListFrontEndInterface,
    ListNationalityBackendInterface,
    ListNationalityFrontEndInterface,
    SignableDocsave,
} from '../Types/CommonType'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import {
    countryList,
    acessLevelList,
    languageList,
    setLoadingStatus,
    documenttypeList,
    addressproofList,
    InduvidualMemberList,
    servicePrcessEmailTemplate,
    regiterClientDetails,
    formtoCLientEmailList,
    appendFormList,
    getEnvelopData,
    nationalityList,
    sicCodeList,
    currencyList,
    officerdocumenttypeList,
    dynamicClientCategoryList,
    servicedocumenttypeList,
    partneshipContactInfoList,
} from '../Reducer/Common'
import { type ChatMessage } from '../Types/Client/ProspectClients/prospectClient'

import axioss from 'axios'
export const GetCountryList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/5') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(countryList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetAccessLevelList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/30') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const accessLevelList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(acessLevelList(accessLevelList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetLanguageList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListing/6`) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const languageListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(languageList(languageListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDocumenttypeList = (
    dispatch: Dispatch,
    optionId: number,
    addressId: number,
    companyTypeId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/${optionId}/${addressId}/${companyTypeId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(documenttypeList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetInduvidualList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListingWithOneParameter/14/0') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(InduvidualMemberList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetAddressproofList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithTwoParameter/3/2/1`)
        .then((response) => {
            if (response.status === 200) {
                const addressproofLists: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(addressproofList(addressproofLists))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetServiceProcessEmailTemplate = (
    dispatch: Dispatch,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/38/${serviceId}`)
        .then((response) => {
            if (response.status === 200) {
                const templateTypeList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(servicePrcessEmailTemplate(templateTypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetChat = async (
    dispatch: Dispatch,
    messagetypeId: number,
    referenceId: number,
    businessId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/ServiceProcess/GetChatList/${messagetypeId}/${referenceId}/${businessId}`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(setLoadingStatus({ status: false }))
                const chatdata = response.data.data
                getAddress(chatdata)
                // SuccessMessage(response.data.operationMsg)
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetRegisteClientDetails = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListing/68`) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const languageListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(regiterClientDetails(languageListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveChat = async (
    dispatch: Dispatch,
    data: ChatMessage
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetProcessChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetFormToCientEmail = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/52/0`)
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(formtoCLientEmailList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetFormAppendFormList = (
    dispatch: Dispatch,
    tempId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/51/${tempId}`)
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(appendFormList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetViaSignable = async (
    dispatch: Dispatch,
    data: SignableDocsave
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Signable/SetSignatureNonTemplate`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const GetEnvelopeDetails = (
    dispatch: Dispatch,
    businessId: number,
    referenceId: number,
    appconectId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Signable/GetEnvelopeInfo/${businessId}/${referenceId}/${appconectId}`
        ) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                dispatch(getEnvelopData(response?.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

// export const GetEmaiDetailsByReferenceID = async (
//     dispatch: Dispatch,
//     businessId: number,
//     referenceId: number,
//     tempId: number,
//     getDetails: CallableFunction
// ): Promise<void> => {
//     dispatch(setLoadingStatus({ status: true }))
//     axios
//         .get(
//             `api/Email/GetEmailDetailByReferenceId/${businessId}/${referenceId}/${tempId}`
//         )
//         .then((response) => {
//             if (response.status === 200) {
//                 const details = response.data.data
//                 getDetails(details)
//                 SuccessMessage(response.data.operationMsg)
//             } else {
//                 ErrorMessage(response.data.operationMsg)
//             }

//             dispatch(setLoadingStatus({ status: false }))
//         })
//         .catch((error) => {
//
//             console.error('Error fetching data:', error)
//             dispatch(setLoadingStatus({ status: false }))
//         })
// }

export const GetEmaiDetailsByReferenceID = async (
    dispatch: Dispatch,
    businessId: number,
    referenceId: number,
    tempId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/Email/GetEmailDetailByReferenceId/${businessId}/${referenceId}/${tempId}`
        )

        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetNationalityList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListingThree/100') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const nationalitylist: ListNationalityFrontEndInterface[] =
                    response?.data?.data?.map(
                        (data: ListNationalityBackendInterface) => {
                            return {
                                value: data.stdId,
                                label: data.stdName,
                            }
                        }
                    )
                dispatch(nationalityList(nationalitylist))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetSICCodeList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/59') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(sicCodeList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetCurrencyList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/61') // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                const newCountryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(currencyList(newCountryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SendEmailUseNodeJs = async (
    dispatch: Dispatch,
    data: any
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    // https://mailserver.iykons.com/send-email
    // http://localhost:5001/
    return await axioss
        .post(`https://mailserver.iykons.com/send-email`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage('Email Send SucessFully')
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SendMissingInformationEmail = (
    dispatch: Dispatch,
    businessId: number,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/MissedInfo/SendMissedInfoEmail/${businessId}/${referenceId}`) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetOfficerDocumenttypeList = (
    dispatch: Dispatch,
    optionId: number,
    addressId: number,
    companyTypeId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/${optionId}/${addressId}/${companyTypeId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(officerdocumenttypeList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDYNAMICClientDetails = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListing/79`)
        .then((response) => {
            if (response.status === 200) {
                const languageListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(dynamicClientCategoryList(languageListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetServiceDocumentType = (
    dispatch: Dispatch,
    optionId: number,
    addressId: number,
    companyTypeId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/${optionId}/${addressId}/${companyTypeId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(servicedocumenttypeList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPartneshipContactInformation = (
    dispatch: Dispatch,
    PartneshipId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/15/${PartneshipId}`)
        .then((response) => {
            if (response.status === 200) {
                const newProject: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(partneshipContactInfoList(newProject))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
