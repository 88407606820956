import React, { useEffect, useState } from 'react'
import {
    Card,
    Row,
    Col,
    DatePicker as AntdDatePicker,
    type DatePickerProps,
    List,
    Badge,
    Tag,
    Flex,
    Spin,
    Empty,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './Itindex.less'
import dayjs from 'dayjs'
// import { ReactComponent as VerticalThreeDots } from '../../Assest/Svg/VerticalThreeDots.svg'
import HorizontalCalendar from '../../../Components/DatePickerSlider'
import axios from '../../../Config/AxiosConfig'
import { ErrorMessage } from '../../../Utils/Notification'
import type { GeneralEvent, GetEventListProps } from '../../../Types/Event'
const { RangePicker } = AntdDatePicker

const dateFormat = 'YYYY/MM/DD'
const customFormat: DatePickerProps['format'] = (value) => {
    const date = new Date(value.format(dateFormat))
    const monthName = date.toLocaleString('default', { month: 'short' })
    const day = date.getDate()?.toString().padStart(2, '0')
    return `${monthName} ${day}`
}

interface CalenderDataType {
    eventId: number
    times: string
    title: string
    alert: string
    description: string
}

const Calender = ({ eventList }: GetEventListProps): React.ReactElement => {
    const today = new Date()
    const [data, setData] = useState<CalenderDataType[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [selectedDate, setSelectedDate] = useState<string>('')
    const monthName = today.toLocaleString('default', { month: 'long' })
    const day = today.getDate()?.toString().padStart(2, '0')

    const now = new Date()

    const setTimeString = (date: string): string => {
        const dateList = date.split(/[ ,]+/)[1].split(':')
        const returnDate = dateList[0] + ':' + dateList[1]
        return returnDate
    }
    const setTimes = (from: string, to: string): string => {
        let str
        if (from !== undefined && from !== null && to !== null) {
            const fromDate: string = setTimeString(from)
            const toDate: string = setTimeString(to)
            if (fromDate === toDate) {
                str = fromDate
            } else {
                str = fromDate + ' - ' + toDate
            }
        } else {
            str = ''
        }
        return str
    }

    const compareDate = (date: string): boolean => {
        const today = dayjs(now.toISOString()).format('DD/MM/YYYY')
        const eventDate = dayjs(new Date(date).toISOString()).format(
            'DD/MM/YYYY'
        )
        return today === eventDate
    }
    const getEventList = async (date: string): Promise<void> => {
        setLoading(true)
        await axios
            .post(`api/Management/GetEventListByUserId`, {
                searchCriteriaList: [
                    {
                        fieldName: 'taskdate',
                        searchOperator: 1,
                        fieldValue1: date,
                        fieldValue2: '',
                    },
                ],
                sortOrderList: [],
            })
            .then((response: any) => {
                if (response.status === 200) {
                    const newData = response?.data?.data?.map(
                        (item: GeneralEvent) => {
                            return {
                                eventId: item?.eventId,
                                times: setTimes(item?.startFrom, item?.endTo),
                                title: item?.eventTitle,
                                alert: compareDate(item?.endTo)
                                    ? 'Deadline: Today'
                                    : '',
                                description: item?.description,
                            }
                        }
                    )
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    function formatDate(inputDate: string): string {
        const date = new Date(inputDate)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0')

        return `${year}-${month}-${day}`
    }

    useEffect(() => {
        if (
            eventList !== undefined &&
            eventList !== null &&
            eventList.length > 0
        ) {
            const formattedDate = today.toISOString().split('T')[0]
            const todayEvents = eventList?.filter((item) => {
                const startFromDate = formatDate(item?.startFrom)
                return startFromDate === formattedDate
            })
            const newData = todayEvents?.map((item: GeneralEvent) => {
                return {
                    eventId: item?.eventId,
                    times: setTimes(item?.startFrom, item?.endTo),
                    title: item?.eventTitle,
                    alert: compareDate(item?.endTo) ? 'Deadline: Today' : '',
                    description: item?.description,
                }
            })
            setData(newData)
        }
    }, [eventList])

    useEffect(() => {
        if (
            selectedDate !== '' &&
            selectedDate !== undefined &&
            selectedDate !== null
        ) {
            void getEventList(selectedDate)
        }
    }, [selectedDate])

    const setDateValue = (value: string): void => {
        setSelectedDate(value)
    }

    const nowDate = dayjs(new Date().toISOString(), dateFormat)
    const currDate = new Date()
    currDate.setMonth(currDate.getMonth() + 1)

    const nextDate = dayjs(currDate.toISOString(), dateFormat)

    const defaultDateRange: string[] = [
        nowDate.toISOString(),
        nextDate.toISOString(),
    ]
    const [dateRange, setDateRange] = useState<string[]>(defaultDateRange)

    return (
        <div className={`${styles.marginRight10} ${styles.marginLeft10}`}>
            <Card className={styles.card}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={12}>
                            <div className={styles.calender__tittle__main}>
                                <div
                                    className={styles.calender__tittle__header1}
                                >
                                    Today,
                                </div>
                                &nbsp;
                                <div
                                    className={styles.calender__tittle__header2}
                                >
                                    {day} {monthName}
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <RangePicker
                                defaultValue={[nowDate, nextDate]}
                                format={customFormat}
                                onChange={(dates) => {
                                    setDateRange(
                                        dates !== null && dates !== undefined
                                            ? dates?.map((date) =>
                                                  dayjs(date).format(dateFormat)
                                              )
                                            : []
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className={styles.marginTop10}>
                            <HorizontalCalendar
                                setDateValue={setDateValue}
                                dateRange={dateRange}
                            />
                        </Col>
                    </Row>
                    <Row className={styles.marginTop10}>
                        <Col span={24}>
                            {isLoading ? (
                                <Flex align="center" justify="center">
                                    <Spin />
                                </Flex>
                            ) : data.length > 0 &&
                              data !== undefined &&
                              data !== null ? (
                                <List>
                                    <VirtualList
                                        data={data}
                                        height={250}
                                        itemKey="eventId"
                                    >
                                        {(
                                            item: CalenderDataType,
                                            index: number
                                        ) => (
                                            <List.Item
                                                key={index}
                                                className={
                                                    styles.calender__list__item
                                                }
                                                style={{
                                                    borderRadius: '12px',
                                                    border: '0px',
                                                }}
                                            >
                                                <Row>
                                                    {item?.times !== null &&
                                                        item?.times !== '' && (
                                                            <Col
                                                                span={23}
                                                                className={
                                                                    styles.calender__list__item__time__col
                                                                }
                                                            >
                                                                <Badge
                                                                    dot
                                                                    color={
                                                                        '#735BF2'
                                                                    }
                                                                />
                                                                &nbsp;
                                                                <div
                                                                    className={
                                                                        styles.calender__list__item__time__value
                                                                    }
                                                                >
                                                                    {item.times}
                                                                </div>
                                                            </Col>
                                                        )}
                                                    {(item?.title === null ||
                                                        item?.title === '') && (
                                                        <Col span={23}>
                                                            <div
                                                                className={
                                                                    styles.calender__list__item__tittle__value
                                                                }
                                                            >
                                                                {item.title}
                                                            </div>
                                                        </Col>
                                                    )}
                                                    {/* <Col
                                                        span={1}
                                                        className={
                                                            styles.textAlignRight
                                                        }
                                                    >
                                                        <VerticalThreeDots />
                                                    </Col> */}
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        {item?.times !== null &&
                                                            item?.times !==
                                                                '' && (
                                                                <div
                                                                    className={
                                                                        styles.calender__list__item__tittle__value
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </div>
                                                            )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        {item?.description !==
                                                            null &&
                                                            item?.description !==
                                                                '' && (
                                                                <div
                                                                    className={
                                                                        styles.calender__list__item__sub__tittle__value
                                                                    }
                                                                >
                                                                    {
                                                                        item.description
                                                                    }
                                                                </div>
                                                            )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        {item?.alert !== null &&
                                                            item?.alert !==
                                                                '' && (
                                                                <Tag
                                                                    className={
                                                                        styles.calender__tag
                                                                    }
                                                                    color="#FB7070"
                                                                >
                                                                    {item.alert}
                                                                </Tag>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List>
                            ) : (
                                <Flex align="center" justify="center">
                                    <Empty
                                        style={{ padding: '40px 0px' }}
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                            <span>No Event Found</span>
                                        }
                                    />
                                </Flex>
                            )}
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    )
}

export default Calender
