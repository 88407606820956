import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Switch,
    Flex,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import FullWidthModal from '../../../Components/FullWidthModal'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import dayjs from 'dayjs'
import { type OfficerDetails } from '../../../Types/CompanyHouse/CompanyOverView'
import { useDispatch } from 'react-redux'
import {
    GetCompanyOverViewData,
    GetTerminateOffocerDetails,
    SetTeminateOfficer,
} from '../../../Services/CompanyHouse/CompanyOverView'
interface terminateOfficerData {
    refernceId: number
    memberRoleId: number
    memberTypeId: number
    onClosemodel: () => void
    corporateId: number
    businessId: number
}
const TeminateOfficer = (props: terminateOfficerData): React.ReactElement => {
    const {
        refernceId,
        memberRoleId,
        memberTypeId,
        onClosemodel,
        corporateId,
        businessId,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const [isSubmitToCH, setIsSubmitToCH] = useState<number>(0)
    const [isSubmitToYes, setIsSubmitToCHYes] = useState<boolean>(false)
    const [officerData, setofficersData] = useState<OfficerDetails | null>(null)

    useEffect(() => {
        const fetchAuditData = async (): Promise<void> => {
            try {
                const data = await GetTerminateOffocerDetails(
                    dispatch,
                    refernceId,
                    memberRoleId,
                    memberTypeId
                )
                setofficersData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        void fetchAuditData()
    }, [refernceId, memberRoleId, memberTypeId])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const handleSwitchChange = (checked: boolean): void => {
        setSwithModelStatus(!swithModalStatus)
        setIsSubmitToCHYes(checked)
    }

    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        setIsSubmitToCH(isSubmitToYes ? 1 : 0)
        ChangeModalFunction()
    }

    const handelSaveData = (): void => {
        void handelSetData()
    }
    const handelSetData = async (): Promise<void> => {
        try {
            // const fotmData = form.getFieldsValue()
            await form.validateFields()
            const dataToSave = {
                companyReferenceId: refernceId,
                companyId: officerData?.companyId ?? 0,
                corporateId,
                typeId: memberTypeId,
                officerName: officerData?.officerName ?? '',
                officerRole: officerData?.officerRole ?? '',
                dateofAppointment: officerData?.dateofAppointment ?? ' ',
                resignationDate: selectedDateAp,
                isResignationSubmitToCH: isSubmitToCH,
                additionalDataOne: 0,
                additionalDataTwo: 0,
                additionalDataThree: 1,
            }
            void SetTeminateOfficer(dispatch, dataToSave)
            GetCompanyOverViewData(dispatch, businessId, corporateId, 0, 2)
            onClosemodel()
        } catch (error) {}
    }

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={10}>Officer Name</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}> {officerData?.officerName}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Role Type</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>{officerData?.officerRole}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Appointment Date</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}>{officerData?.dateofAppointment}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>
                            Date of Appointment Terminated
                            <span className={commonStyles.requireIcon}>*</span>
                        </Col>
                        <Col span={2}>:</Col>
                        <Col span={12}>
                            <Form.Item
                                name="dateOfBirth"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please choose terminated date',
                                    },
                                ]}
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedDateAp.length > 0
                                                ? dayjs(selectedDateAp)
                                                : null
                                        }
                                        format="M/D/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Switch
                                checked={isSubmitToCH === 1}
                                onChange={handleSwitchChange}
                            />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={onClosemodel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                                clickAction={handelSaveData}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

export default TeminateOfficer
