import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { serviceDropDownList } from '../Reducer/InitialReducer'
import {
    registeredClientSoleTraderConfiguration,
    soleTraderHomeData,
    soleTraderList,
    soleTraderTaps,
} from '../Reducer/Client'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import {
    type SoleTraderFormDataInterface,
    type SoleTraderChatInterface,
    type PayeServiceDataSS,
    type PensionData,
    type VatData,
    type AnnualAccountData,
    type SoleTraderCreateFormDataInterface,
    type ContactInfoInterface,
    type ClientCompanyControlData,
    type GenerateEmails,
    type ClientCompanyActiveService,
    type ClientCompanyVerification,
    type CorporateDocumentSave,
} from '../Types/Client/RegisteredClients/SoleTrader'
import {
    type TableListInterface,
    type ListFrontEndInterface,
    type ListBackendInterface,
} from '../Types/CommonType'
import { documenttypeList, setLoadingStatus } from '../Reducer/Common'
import { type AxiosResponse } from 'axios'

export const GetSoleTraderList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `api/SoleTrader/GetSoleTraderList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: SoleTraderFormDataInterface) => {
                        return {
                            ...value,
                            key: value.soleTraderId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(soleTraderList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetServiceDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('api/GetStandardListing/18')
        .then((response) => {
            if (response.status === 200) {
                const serviceListData: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(serviceDropDownList(serviceListData))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ActiveInactiveSoleTrader = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/2/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteSoleTrader = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/2/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveChat = async (
    dispatch: Dispatch,
    data: SoleTraderChatInterface
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Overview/SetStaffOverviewChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getClientCompanyDetails = async (
    dispatch: Dispatch,
    businessTypeId: number,
    corporateid: number,
    tabIndex: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/corporate/GetClientCompanyDetail/${businessTypeId}/${corporateid}/${tabIndex}`
        )

        if (response.status === 200) {
            dispatch(soleTraderTaps(response.data))
            return response.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetService = async (
    dispatch: Dispatch,
    serviceID: number,
    serviceReferenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Service/GetClientCompanyServiceDetail/${serviceID}/${serviceReferenceId}`
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
            return null
        }
    } catch (error) {
        dispatch(setLoadingStatus({ status: false }))
        return null
    }
}

export const SaveAnnualData = async (
    dispatch: Dispatch,
    data: AnnualAccountData
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Service/SetServiceAnnualAccount`,
            data
        )
        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveVatData = async (
    dispatch: Dispatch,
    data: VatData
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(`api/Service/SetServiceVAT/`, data)
        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const PensionSaveData = async (
    dispatch: Dispatch,
    data: PensionData
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Service/SetServicePension/`,
            data
        )
        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const PayeSaveData = async (
    dispatch: Dispatch,
    data: PayeServiceDataSS
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(`api/Service/SetServicePaye/`, data)
        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetAddress = async (
    dispatch: Dispatch,
    soleTraderId: number,
    switchId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetCompanyAddressInformation/2/${soleTraderId}/${switchId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data.data
                getAddress(addressData)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.error.message)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const saveSoleTrader = async (
    dispatch: Dispatch,
    data: SoleTraderCreateFormDataInterface,
    saveCallBack: CallableFunction
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    const bodyData = {
        soleTraderId: 0,
        clientNumber: data.clientNumber ?? '',
        title: data.title ?? '',
        firstName: data.firstName ?? '',
        middleName: data.middleName ?? '',
        surName: data.surName ?? '',
        dateOfBirth: data.dateOfBirth ?? '',
        natureOfBusiness: data.natureOfBusiness ?? '',
        businessTradingName: data.businessTradingName ?? '',
        homeAddressOne: data.homeAddressOne ?? '',
        homeAddressTwo: data.homeAddressTwo ?? '',
        homeTown: data.homeTown ?? '',
        homeCountry: data.homeCountry ?? '',
        homePostCode: data.homePostCode ?? '',
        mobileNumber: data.mobileNumber ?? '',
        homeNumber: data.homeNumber ?? '',
        workNumber: data.workNumber ?? '',
        emailAddress: data.emailAddress ?? '',
        alternativeEmailAddress: data.alternativeEmailAddress ?? '',
        niNumber: data.niNumber ?? '',
        nationality: data.nationality ?? '',
        busAddress1: data.busAddress1 ?? '',
        busAddress2: data.busAddress2 ?? '',
        busTown: data.busTown ?? '',
        busCountry: data.busCountry ?? '',
        busPostCode: data.busPostCode ?? '',
        corrAddress1: data.corrAddress1 ?? '',
        corrAddress2: data.corrAddress2 ?? '',
        corrTown: data.corrTown ?? '',
        corrCountry: data.corrCountry ?? '',
        corrPostCode: data.corrPostCode ?? '',
        clientCategoryId: data.clientCategoryId ?? 0,
        individualUTR: data.individualUTR ?? '',
        isActive: 'Active',
        isDelete: 'Inactive',
    }

    return await axios
        .post(`/api/SoleTrader/SetSoleTrader`, bodyData)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                saveCallBack(response.data.data)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SaveClientCompanySupplier = async (
    dispatch: Dispatch,
    data: any
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/Service/SetClientCompanySupplier/`,
            data
        )
        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            return response
        } else {
            ErrorMessage(response.data.operationMsg)
        }
        return response
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}
export const getSoleTraderHomeDetails = async (
    dispatch: Dispatch,
    businessTypeId: number,
    corporateid: number,
    tabIndex: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/corporate/GetClientCompanyDetail/${businessTypeId}/${corporateid}/${tabIndex}`
        )

        if (response.status === 200) {
            dispatch(soleTraderHomeData(response.data))
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveContactInfo = async (
    dispatch: Dispatch,
    data: ContactInfoInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Corporate/SetContactPerson/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const setBasic = async (
    dispatch: Dispatch,
    data: SoleTraderCreateFormDataInterface
): Promise<AxiosResponse<any> | false> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(`api/SoleTrader/SetSoleTrader`, data)

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
        return response
    } catch (error) {
        console.error('Error fetching posts:', error)
        dispatch(setLoadingStatus({ status: false }))
        return false
    }
}
export const EnableServiceForComplince = async (
    dispatch: Dispatch,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/Scheduler/SchedulerPreparationByCompanyId/1/${referenceId}`)
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage('Enable all active services successfully')
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GenerateEmail = async (
    dispatch: Dispatch,
    data: GenerateEmails
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .post(`/api/Email/SetClientEmail`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SetClientEmailStatus(dispatch, response.data.identifierId)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetQuotationPreview = async (
    dispatch: Dispatch,
    corporateid: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/Corporate/GetQuotationPreview/2/${corporateid}`
        )
        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveClientCompanyActiveService = async (
    dispatch: Dispatch,
    data: ClientCompanyActiveService
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyActiveService/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveClientCompanyVerification = async (
    dispatch: Dispatch,
    data: ClientCompanyVerification
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyVerification`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveCompanyControl = async (
    dispatch: Dispatch,
    data: ClientCompanyControlData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Service/SetClientCompanyControl`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SaveOfficerDocument = async (
    dispatch: Dispatch,
    data: CorporateDocumentSave
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    try {
        const response = await axios.post(
            `api/document/SetClientCompanyDocument/`,
            data
        )

        dispatch(setLoadingStatus({ status: false }))

        if (response.status === 200) {
            SuccessMessage(response.data.operationMsg)
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching posts:', error)

        dispatch(setLoadingStatus({ status: false }))
    }
}
export const GetDocumenttypeList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListingWithTwoParameter/3/1/2')
        .then((response) => {
            if (response.status === 200) {
                const DocumenttypeList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(documenttypeList(DocumenttypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const getClientPortalInvitation = async (
    dispatch: Dispatch,
    businessTypeId: number,
    referenceId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `api/ClientPortal/GetClientInvitation/${businessTypeId}/${referenceId}/1`
        )

        if (response.status === 200) {
            dispatch(soleTraderTaps(response.data))
            SuccessMessage('Client portal invitation send successfully')
        } else {
            ErrorMessage('Failed to sent client portal invitation')
        }
    } catch (error) {
        ErrorMessage('An error occurred while sending client portal invitation')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetDirectDebitCustomer = (
    dispatch: Dispatch,
    companyTypeId: number,
    referenceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/corporate/GetClientCompanyDetail/${companyTypeId}/${referenceId}/10`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(registeredClientSoleTraderConfiguration(response.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
