import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Select, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import {
    type newFormStateInterface,
    type newFormInterface,
} from '../../../Types/Client/RegisteredClients/registerClient'
import S3Upload from '../../../Components/S3Upload'
import { GetCountryList } from '../../../Services/Common'

const newForm = (props: newFormInterface): React.ReactElement => {
    const {
        onSave,
        editData,
        categoryDropDownList,
        countryList,
        resetdata,
        setResetData,
        isLoadingModal,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const setFormData = (name: string, location: string): void => {
        form.setFieldValue(name, location)
    }

    useEffect(() => {
        form.setFieldValue('clientDynId', '0')
        form.setFieldValue('clientNo', '#')
    }, [])
    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
            resetForm()
        }

        setResetData('false')
        form.setFieldValue('clientNo', '#')
    }, [resetdata])
    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    useEffect(() => {
        if (editData !== null && Object.keys(editData).length > 0) {
            const newData = {
                ...editData,
            }

            form.setFieldsValue(newData)
        } else {
            console.log('No editData found or editData is empty')
        }
    }, [editData, categoryDropDownList])

    const resetForm = (): void => {
        form.resetFields()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    console.log('data', data)
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                {' '}
                <Form.Item name="clientDynCategoryId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="clientDynId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Client ID" name="clientNo">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Client Category"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the clientCategory!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: 0 },
                                    ...categoryDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Title" name="title">
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the FirstName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Middle Name" name="middleName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Surname"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the SurName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Email Address"
                            name="emailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mobile No"
                            name="mobileNo"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the CompanyName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Company Registration No"
                            name="companyRegistrationNo"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Address One"
                            name="addressOne"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the AddressOne!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Address Two"
                            name="addressTwo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the AddressTwo!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Town" name="town">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Post Code" name="postCode">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Country" name="country">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Referred By" name="referredBy">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="ID Document" name="idDocument">
                            <S3Upload
                                name={'idDocument'}
                                value={form.getFieldValue('idDocument')}
                                // setFormData={setFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                                setFormData={setFormData}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Other" name="remarks">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title={'Save'}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoadingModal}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): newFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(newForm)
