import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Switch, Col, Row, Tooltip, Drawer } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import type {
    OnBoardingCorporateListInterface,
    OnBoardingCorporateListDataTypeInterface,
    OnBoardingCorporateListStateInterface,
} from '../../../../Types/Client/OnboardingClients/OnBoardingCoporate'
import { EyeOutlined, MessageOutlined } from '@ant-design/icons'
import {
    GetCorporateList,
    DeleteCorporate,
    ActiveInactiveCorporate,
} from '../../../../Services/OnbordingCoporate'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import CorporateExpandView from './onBoardingCoporateExpandView'
import AddNewButton from '../../../../Components/AddNewButton'
import calculatePagination from '../../../../Utils/Pagination'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
import { GetChat } from '../../../../Services/Common'
import StaffCommunication from '../../../../Components/StaffChat'
export const CorporateItemDefaultData = {
    corporateid: '0',
    companyname: '',
    companyregisternumber: '',
    clienttype: '',
    clientcategoryid: 0,
    clientcategory: '',
    clientnumber: '',
    activestatus: '',
    serviceIds: '',
    contactName: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const OnboardingcorporateList = (
    props: OnBoardingCorporateListInterface
): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        onboardingcorporateList,
        onboardingcorporateListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [corporateid, setCorporateId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const hasFetchedOnce = useRef(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    const hasFetchedOnce1 = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [resetData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (Number(corporateid) !== 0) {
            void GetChat(
                dispatch,
                6,
                Number(corporateid),
                1,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [corporateid])

    const openChatModal = (corporateid: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setCorporateId(corporateid)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetCorporateList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
        hasFetchedOnce.current = false
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: onboardingcorporateListCount,
            },
        })
    }, [onboardingcorporateListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const viewCorporateDetails = (id: string): void => {
        localStorage.removeItem('corporateData')
        localStorage.removeItem('onbordingId')
        const corporateData = onboardingcorporateList?.find(
            (item) => item.corporateid === id
        )
        const onbordingId = 1
        localStorage.setItem('corporateData', JSON.stringify(corporateData))
        localStorage.setItem('onbordingId', onbordingId.toString())
        navigate(`/clients/onboarding-clients/corporate-view`)
    }

    const createCorporate = (id: string): void => {
        const corporateData = onboardingcorporateList?.find(
            (item) => item.corporateid === id
        )
        const onbordingId = 1
        localStorage.setItem('onbordingId', onbordingId.toString())
        navigate(`/clients/onboarding-clients/corporate-new`, {
            state: { corporateData, onbordingId },
        })
    }
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        onboardingcorporateListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<OnBoardingCorporateListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientnumber',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyname',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Registration No',
            dataIndex: 'companyregisternumber',
        },
        {
            title: 'Type',
            dataIndex: 'clientType',
        },
        {
            title: 'Service',
            dataIndex: 'serviceIds',
        },
        {
            title: 'Action',
            key: 'action1',
            render: (_, record: OnBoardingCorporateListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.corporateid)
                                    setResetData('true')
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="View  Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    viewCorporateDetails(record?.corporateid)
                                    localStorage.setItem('corporateTapId', '1')
                                }}
                            />
                        </Tooltip>

                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.corporateid,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) ? (
                            <Tooltip title="Delete Corporate">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(record?.corporateid)
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<OnBoardingCorporateListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let corporateid = ''
        if (!deleteModalStatus) {
            corporateid = id
        }
        setCorporateId(corporateid)
    }

    const deleteCorporate = (): void => {
        DeleteCorporate(dispatch, corporateid, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let corporateid = ''
        if (!activeInactiveModalStatus) {
            corporateid = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setCorporateId(corporateid)
    }

    const updateActiveInactiveCorporate = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveCorporate(dispatch, corporateid, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const expandableData = (
        record: OnBoardingCorporateListDataTypeInterface
    ): JSX.Element => {
        return <CorporateExpandView corporateData={record} />
    }

    return (
        <>
            <Card className={commonStyles.card}>
                <Row gutter={16} align="middle">
                    <div className={commonStyles.corpList}>
                        <Col span={16}>
                            <SearchInput
                                placeHolder={'Search CompanyName by keywords'}
                                onSearch={onChangeFilter}
                                onChange={onChangeText}
                                width="478"
                            />
                        </Col>
                        <Col span={4}>
                            <AddNewButton
                                name="Add New"
                                clickAction={() => {
                                    createCorporate('')
                                }}
                            />
                        </Col>
                    </div>
                </Row>
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={onboardingcorporateList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Client. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteCorporate}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Client. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveCorporate}
                />
                {/* <Modal
                    title="Staff Communication"
                    open={chatModalOpen}
                    onCancel={() => {
                        setChatModalOpen(false)
                    }}
                    footer={null}
                    width={'50%'}
                >
                    {selectedCorporateId != null && (
                        <CorporateChat
                            corporateid={parseInt(selectedCorporateId, 10)}
                        />
                    )}
                </Modal> */}
                <Drawer
                    title={ChatDatas?.clientName}
                    width={550}
                    onClose={onClose}
                    open={open}
                >
                    <StaffCommunication
                        refernceId={Number(corporateid)}
                        messageTypeId={6}
                        businessId={1}
                        resetdata={resetData}
                        setResetData={setResetData}
                    />
                </Drawer>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): OnBoardingCorporateListStateInterface => {
    return {
        onboardingcorporateList: state.client.onboardingcorporateList,
        onboardingcorporateListCount: state.client.onboardingcorporateListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(OnboardingcorporateList)
