// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceName_wMhUM {
  text-align: left;
  margin-left: 10px;
}
.serviccNumInput_uBjYU {
  width: 70%;
}
.textAreaWide_APpYN {
  width: 50%;
  margin-left: 15px;
}
.datePicker_jEFgs {
  position: relative;
  width: 100%;
}
.TermsSigine_IFo_L {
  background-color: #f7c5c5;
}
.TermsSigineText_XNzzK {
  padding: 10px;
}
.enableService_0fFJx {
  align-self: right;
  text-align: right;
  color: #175ca9;
  right: 0;
  cursor: pointer;
  position: relative;
}
.right_alignButtonIcon_F9Tzq {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.controlItemWidth_UNHbs {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/RegisteredClients/SoleTrader/Buttons/Compliance/compilance.less"],"names":[],"mappings":"AAOA;EACI,gBAAA;EACA,iBAAA;AANJ;AASA;EACI,UAAA;AAPJ;AAUA;EACI,UAAA;EACA,iBAAA;AARJ;AAeA;EACI,kBAAA;EACA,WAAA;AAbJ;AAgBA;EACI,yBAAA;AAdJ;AAgBA;EACI,aAAA;AAdJ;AAgBA;EACI,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,QAAA;EACA,eAAA;EACA,kBAAA;AAdJ;AA2BA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;AAzBJ;AA2BA;EACI,WAAA;AAzBJ","sourcesContent":["// .serviceItem {\n//     display: flex;\n//     justify-content: space-between;\n//     align-items: left;\n//     margin-bottom: 10px;\n// }\n\n.serviceName {\n    text-align: left;\n    margin-left: 10px;\n}\n\n.serviccNumInput {\n    width: 70%;\n}\n\n.textAreaWide {\n    width: 50%;\n    margin-left: 15px;\n}\n\n// .datePickerLeft {\n//     margin-top: 10px;\n// }\n\n.datePicker {\n    position: relative;\n    width: 100%;\n}\n\n.TermsSigine {\n    background-color: #f7c5c5;\n}\n.TermsSigineText {\n    padding: 10px;\n}\n.enableService {\n    align-self: right;\n    text-align: right;\n    color: #175ca9;\n    right: 0;\n    cursor: pointer;\n    position: relative;\n}\n\n// // .totalbox {\n// //     margin-top: 20px;\n// //     margin-bottom: 20px;\n// // }\n\n// // .serviceSwitch {\n// //     margin-top: 20px !important;\n// //     margin-bottom: 20px !important;\n// // }\n\n.right_alignButtonIcon {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n.controlItemWidth {\n    width: 100%;\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceName": `serviceName_wMhUM`,
	"serviccNumInput": `serviccNumInput_uBjYU`,
	"textAreaWide": `textAreaWide_APpYN`,
	"datePicker": `datePicker_jEFgs`,
	"TermsSigine": `TermsSigine_IFo_L`,
	"TermsSigineText": `TermsSigineText_XNzzK`,
	"enableService": `enableService_0fFJx`,
	"right_alignButtonIcon": `right_alignButtonIcon_F9Tzq`,
	"controlItemWidth": `controlItemWidth_UNHbs`
};
export default ___CSS_LOADER_EXPORT___;
