import React, { useCallback, useEffect, useState } from 'react'
import { Table, Space, Tooltip, Switch, Row, Col, Modal } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import commonStyles from '../../../Utils/Common.less'
import {
    type PortfolioClinetAddPage,
    type PortfolioClientviewData,
    type PortfolioClientviewTableData,
    type ClientCompanyDetails,
    type AccountMangerFormData,
} from '../../../Types/Client/RegisteredClients/Portfolio'
import { useDispatch } from 'react-redux'
import {
    AddClient,
    PortfolioDetailView,
    SavePorAccountManger,
} from '../../../Services/Portfolio'
import { type TableParamsInterface } from '../../../Types/CommonType'
import { commonTableParams } from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import FullWidthModal from '../../../Components/FullWidthModal'
import UnassigneUserList from './unassignUserList'
import AccountManager from './accoutManagerPage'
import {
    UserAddOutlined,
    ProfileOutlined,
    FormOutlined,
} from '@ant-design/icons'
import { ReactComponent as Infoicon } from '../../../Assest/ServiceprocessSVG/InfoI.svg'
import AddRemarks from './addRemarks'
import RemarksHistory from '../../../Components/remarksHistoryPage'
const PortfolioClientList = (
    props: PortfolioClinetAddPage
): React.ReactElement => {
    const dispatch = useDispatch()
    const { portfolioId, clientCategoryId } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [clientViewData, setClientViewData] =
        useState<PortfolioClientviewData>()
    const [OpenModalStatus, setOpenStatus] = useState(false)
    const [BusinessId, setbusinessId] = useState(0)
    const [ReferenceId, setreferenceId] = useState(0)
    const businessId = 0
    const referenceId = 0
    const [accoutManagerModalStatus, setAccoutManagerModalStatus] =
        useState(false)
    const [remarksModel, setremarksModelStatus] = useState(false)
    const [remarksAddModel, setremarksAddModelStatus] = useState(false)
    useEffect(() => {
        fetchclientData()
    }, [portfolioId])

    const fetchclientData = (): void => {
        void PortfolioDetailView(
            dispatch,
            portfolioId,
            businessId,
            referenceId,
            (portfoliodata: PortfolioClientviewData) => {
                setClientViewData(portfoliodata)
            }
        )
    }
    const clientCompanyDetailsList = (
        clientViewData?.clientCompanyDetailsList != null
            ? clientViewData?.clientCompanyDetailsList?.map(
                  (item: ClientCompanyDetails) => ({
                      clientNumber: item.clientNumber,
                      name: item.name,
                      businessType: item.businessType,
                      businessTypeId: item.businessTypeId,
                      referenceId: item.referenceId,
                      key: item.referenceId,
                  })
              )
            : []
    ) as PortfolioClientviewTableData[]

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: clientCompanyDetailsList?.length,
            },
        })
    }, [clientCompanyDetailsList?.length])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        clientCompanyDetailsList?.length,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<PortfolioClientviewTableData> = [
        {
            title: 'Client Number',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            width: 300,
            title: 'Business Type',
            dataIndex: 'businessType',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: PortfolioClientviewTableData) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Remove This Client">
                            <Switch
                                checked
                                onClick={() => {
                                    openModalFunction(
                                        record.businessTypeId,
                                        record.referenceId
                                    )
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Add Account Manager">
                            <UserAddOutlined
                                style={{ fontSize: '20px' }}
                                onClick={() => {
                                    openAccountManageModal(
                                        record.businessTypeId,
                                        record.referenceId
                                    )
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Add Remark">
                            <FormOutlined
                                onClick={() => {
                                    OpenAddRenarks(
                                        record.referenceId,
                                        record.businessTypeId
                                    )
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Remarks History">
                            <ProfileOutlined
                                onClick={() => {
                                    RemarksModelOpen(
                                        record.referenceId,
                                        record.businessTypeId
                                    )
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PortfolioClientviewTableData>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const openModalFunction = (
        businessId: number,
        referenceId: number
    ): void => {
        setbusinessId(businessId)
        setreferenceId(referenceId)
        setOpenStatus(!OpenModalStatus)
    }

    const RemoveFunction = (): void => {
        void RemoveClientFunction()
    }
    const RemoveClientFunction = async (): Promise<void> => {
        const dataToSend = {
            portfolioId,
            businessTypeId: BusinessId,
            referenceId: ReferenceId,
            addOrRemoveFlag: 2,
        }
        await AddClient(dispatch, dataToSend).then((result) => {
            if (result !== null && result !== undefined) {
                fetchclientData()
                setOpenStatus(!OpenModalStatus)
            }
        })
    }

    const openAccountManageModal = (
        businessId: number,
        referenceId: number
    ): void => {
        setbusinessId(businessId)
        setreferenceId(referenceId)
        setAccoutManagerModalStatus(!accoutManagerModalStatus)
    }

    const changeAccountModalStatus = (): void => {
        setAccoutManagerModalStatus(!accoutManagerModalStatus)
    }

    const changeRemarksModalStatus = (): void => {
        setremarksModelStatus(!remarksModel)
    }
    const OpenAddRenarks = (clientId: number, businessId: number): void => {
        setreferenceId(clientId)
        setbusinessId(businessId)
        setremarksAddModelStatus(!remarksAddModel)
    }
    const changeRemarksAddModalStatus = (): void => {
        setremarksAddModelStatus(!remarksAddModel)
    }
    const onSaveAccountManger = (
        data: AccountMangerFormData,
        callBack: CallableFunction
    ): void => {
        void saveAccountManager(data, callBack)
    }
    const saveAccountManager = async (
        data: AccountMangerFormData,
        callBack: CallableFunction
    ): Promise<void> => {
        await SavePorAccountManger(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeAccountModalStatus()
                    callBack()
                    setTimeout(() => {
                        fetchclientData()
                    })
                }
            })
            .catch(() => {})
    }
    const RemarksModelOpen = (clientId: number, businessId: number): void => {
        setreferenceId(clientId)
        setbusinessId(businessId)
        setremarksModelStatus(!remarksModel)
    }
    const ClientHeder = (): React.ReactElement => {
        return (
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        <p>Portfolio Numebr</p>
                        <p>Portfolio Name</p>
                        <p>Portfolio Manager</p>
                        <p>Portfolio Account Manager</p>
                        <p>Portfolio Remarks</p>
                        <p>KMP Reference Label</p>
                    </Col>

                    <Col span={10}>
                        <p>: {clientViewData?.number}</p>
                        <p>: {clientViewData?.name}</p>
                        <p>: {clientViewData?.manager}</p>
                        <p>: {clientViewData?.accountsManager}</p>
                        <p>
                            : {clientViewData?.remarks}{' '}
                            <Tooltip title="Remark History">
                                <Infoicon
                                    style={{
                                        marginTop: 8,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        RemarksModelOpen(0, 0)
                                    }}
                                />
                            </Tooltip>
                        </p>
                        <p>: {clientViewData?.kmpReferenceLabel}</p>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <ClientHeder />
            <div>
                <b>Portfolio Client</b>
            </div>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={clientCompanyDetailsList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                />
            </div>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
                <b>Add Client</b>
            </div>

            <div className={commonStyles.table}>
                <UnassigneUserList
                    clientCategoryId={clientCategoryId}
                    portfolioId={portfolioId}
                    fetchclientData={fetchclientData}
                    OpenModal={OpenModalStatus}
                />
            </div>
            <FullWidthModal
                modalStatus={OpenModalStatus}
                closeModal={() => {
                    openModalFunction(0, 0)
                }}
                tittle="You are about to Remove Client. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={RemoveFunction}
            />
            <Modal
                footer={null}
                onCancel={changeAccountModalStatus}
                width={'40%'}
                title={'Add Account Manager'}
                open={accoutManagerModalStatus}
            >
                <AccountManager
                    portfolioId={portfolioId}
                    onSave={onSaveAccountManger}
                    businessTypeId={BusinessId}
                    referenceId={ReferenceId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeRemarksModalStatus}
                width={'40%'}
                title={'Remarks History'}
                open={remarksModel}
            >
                <RemarksHistory
                    portfolioId={portfolioId}
                    referenceId={ReferenceId}
                    businessId={BusinessId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeRemarksAddModalStatus}
                width={'40%'}
                title={'Add Remark'}
                open={remarksAddModel}
            >
                <AddRemarks
                    changeModalStatus={changeRemarksAddModalStatus}
                    portfolioId={portfolioId}
                    clientNumber={ReferenceId}
                    businessId={BusinessId}
                />
            </Modal>
        </>
    )
}
export default PortfolioClientList
