import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Row, Form, Button } from 'antd'
import { ResetPin } from '../../Services/Auth'
import styles from './login.less'
import InputField from '../../Components/InputField'
import { setLoginState } from '../../Reducer/AuthReducer'
import type {
    LoginPinInterface,
    ResetPinResponse,
    forgotPin,
} from '../../Types/Auth'
import commonStyles from '../../Utils/Common.less'
import SuccessPin from './successPin'
import LoginPin from './loginPin'

const ForgotPin = (): React.ReactElement => {
    const dispatch = useDispatch()
    const [isValidPin, setIsValidPin] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [openLoginPin, setLoginPage] = useState(false)
    const name = 'ResetPin'

    const onSubmit = (data: forgotPin): void => {
        const newData = {
            email: data.email,
        }
        ResetPin(dispatch, newData, (response: ResetPinResponse) => {
            const isValid = response.isValid as boolean
            if (isValid) {
                setIsValidPin(true)
                setErrorMessage(null)
            } else {
                setIsValidPin(false)
                setErrorMessage('Please enter a registered email.')
            }
        })
    }

    const backButtonClick = (): void => {
        dispatch(setLoginState({ status: false }))
        setLoginPage(true)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    if (isValidPin) {
        return <SuccessPin name={name} />
    }
    if (openLoginPin) {
        return <LoginPin />
    }

    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>
                                Recover Your Pin
                            </div>
                            <div className={styles.loginTittleDesc}>
                                Enter Your recovery email to recover the PIN
                            </div>
                        </div>
                        {errorMessage !== null && (
                            <div className={styles.errorMessage}>
                                {errorMessage}
                            </div>
                        )}
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                        >
                            <InputField
                                name="email"
                                label="Email"
                                placeHolder="Enter your recovery email"
                                reuireMessage="Please enter recovery email"
                            />
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.loginFormButton}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                        <Button
                            className={styles.backButton}
                            onClick={backButtonClick}
                        >
                            Back to SignIn
                        </Button>
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): LoginPinInterface => {
    return {
        username: state.auth.username,
    }
}

export default connect(mapStateToProps)(ForgotPin)
