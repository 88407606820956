import React, { useEffect, useRef, type ReactElement } from 'react'
import { Col, Form, Input, Row, Select, Switch } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    type HomeStateProps,
    type RegisterAddress,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCountryList } from '../../../../../../Services/Common'

const CorresponseAddressForm = (props: RegisterAddress): ReactElement => {
    const {
        countryList,
        isEditing,
        toggleEditing,
        edit,
        toggleCorrBussEditing,
    } = props
    const dispatch = useDispatch()
    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        GetCountryList(dispatch)
    }, [])
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <div className={commonStyles.bussinessToggle}>
                        <Switch
                            size="small"
                            checked={isEditing}
                            onChange={toggleEditing}
                        />
                        <span className={commonStyles.bussinesToggleA}>
                            Same as Office
                        </span>
                    </div>
                </Col>

                <Col span={12}>
                    <div className={commonStyles.bussinessToggle}>
                        <Switch
                            size="small"
                            checked={edit}
                            onChange={toggleCorrBussEditing}
                        />
                        <span className={commonStyles.bussinesToggleA}>
                            Same as Business
                        </span>
                    </div>
                </Col>
            </Row>

            <Form.Item
                label="Address 1"
                name="corraddress1"
                rules={[
                    {
                        required: true,
                        message: 'Please input your address one!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Address 2" name="corraddress2">
                <Input />
            </Form.Item>
            <Form.Item
                label="Town"
                name="corrtown"
                rules={[
                    {
                        required: true,
                        message: 'Please input your town!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Country" name="corrcountry">
                <Select
                    allowClear
                    showSearch
                    placeholder="Please select"
                    onChange={() => {}}
                    options={countryList?.map((country) => ({
                        value: country.label,
                        label: country.label,
                    }))}
                />
            </Form.Item>
            <Form.Item
                label="Post Code"
                name="corrpostcode"
                rules={[
                    {
                        required: true,
                        message: 'Please input your PostCode!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(CorresponseAddressForm)
