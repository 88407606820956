import {
    Form,
    Input,
    Button,
    Col,
    Row,
    Space,
    DatePicker,
    Select,
    InputNumber,
    type DatePickerProps,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import type {
    CorporateOfficerView,
    officerAddShareholderPersonDetails,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
// import { CorporateOfficerViewDefalutData } from './Officer'
const CorporateAddShareholderPersonForm = (
    props: officerAddShareholderPersonDetails
): React.ReactElement => {
    const { onSave, member } = props
    const [form] = Form.useForm()
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    useEffect(() => {
        form.setFieldValue('dateofAppointment', selectedDateAp)
    }, [selectedDateAp])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }
    useEffect(() => {
        if (member !== null) {
            setSelectedDateAp(member?.dateofAppointment)
            form.resetFields()
            form.setFieldsValue({
                titleOrCompanyName: member?.titleOrCompanyName,
                firstNameOrBRNo: member?.firstNameOrBRNo,
                middleName: member?.middleName,
                surName: member?.surName,
                dateofAppointment: member?.dateofAppointment,
            })
        }
    }, [member, form])
    const resetForm = (): void => {
        // form.setFieldsValue(CorporateOfficerViewDefalutData)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<CorporateOfficerView>
                    name="isNewShareHolder"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Title"
                            name="titleOrCompanyName"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                disabled={
                                    member?.titleOrCompanyName !== undefined
                                }
                                value={member?.titleOrCompanyName ?? ''}
                                defaultValue={'UNKNOWN'}
                            >
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Shareholding Percentage "
                            name="shareHoldingPercentage"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input Shareholding Percentage!',
                                },
                            ]}
                        >
                            <InputNumber className={commonStyles.InputNumber} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="First Name"
                            name="firstNameOrBRNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input First Name!',
                                },
                            ]}
                        >
                            <Input
                                disabled={member?.firstNameOrBRNo !== undefined}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Number of Shares"
                            name="noOfShares"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Number of Shares!',
                                },
                            ]}
                        >
                            <InputNumber className={commonStyles.InputNumber} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Middle Name"
                            name="middleName"
                        >
                            <Input
                                disabled={member?.middleName !== undefined}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Shareholder Type"
                            name="shareHolderTypeId"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={1}
                                optionFilterProp="children"
                            >
                                <Select.Option value={1}>
                                    Ordinary
                                </Select.Option>
                                <Select.Option value={2}>
                                    Preference
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Surname"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Surename!',
                                },
                            ]}
                        >
                            <Input disabled={member?.surName !== undefined} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commonStyles.officerButtonMargin}>
                    <Col span={12}>
                        <Form.Item<CorporateOfficerView>
                            label="Date Of Appointment"
                            name="dateofAppointment"
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    className={commonStyles.dateWidth}
                                    disabled={
                                        member?.dateofAppointment !== undefined
                                    }
                                    onChange={(date, dateString) => {
                                        onChangeDateAppoint(date, dateString)
                                    }}
                                    value={
                                        selectedDateAp !== ''
                                            ? dayjs(selectedDateAp)
                                            : null
                                    }
                                    format="M/D/YYYY"
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

export default CorporateAddShareholderPersonForm
