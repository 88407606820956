import {
    Card,
    Col,
    Spin,
    Upload,
    type UploadFile,
    Image,
    Flex,
    Row,
    Tooltip,
    Modal,
} from 'antd'
import {
    CloudUploadOutlined,
    DeleteOutlined,
    EyeOutlined,
} from '@ant-design/icons'
import { ErrorMessage } from '../Utils/Notification'
import React, { useState } from 'react'
import './upload.less'
import { uploadFileToS3 } from '../Config/AwsConfig'
import { type RcFile } from 'antd/es/upload/interface'
import layoutStyles from '../Layouts/Less/style.less'
import FullWidthModal from './FullWidthModal'
import styles from './Less/s3Upload.less'
import { ReactComponent as PDFImage } from '../Assest/Svg/pdfIm.svg'
import PDFPreview from './PDFPreview'
const { Dragger } = Upload

interface S3UploadInterface {
    name: string
    value?: string[]
    setFormData: (name: string, locations: string[]) => void
    setBase64?: (base64: string[] | undefined) => void
    corporate: string
    corporateid: number
    documenttype: string
    documentbaseId: number
    memberpersonId: number
    selectedDocumentType?: number
}

const S3UploadMultiple = (props: S3UploadInterface): React.ReactElement => {
    const {
        name,
        setFormData,
        setBase64,
        corporate,
        corporateid,
        documenttype,
        memberpersonId,
        documentbaseId,
        selectedDocumentType,
    } = props
    const [dragging, setDragging] = useState(false)
    const [value, setValue] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [fileUrl, setFileUrl] = useState('')
    const dynamicPath = `Document${corporate !== '' ? `/${corporate}` : ''}${
        corporateid !== 0 ? `/${corporateid}` : ''
    }${documenttype !== '' ? `/${documenttype}` : ''}${
        memberpersonId !== 0 ? `/${memberpersonId}` : ''
    }${documentbaseId !== 0 ? `/${documentbaseId}` : ''}`
    const [previewPdf, setPdfPreview] = useState(false)
    const [pdfURL, setUrl] = useState('')
    console.log('selectedDocumentType', selectedDocumentType)
    const convertBase64 = async (file: File): Promise<string> => {
        return await new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result as string)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const fileOnChange = async (fileList: UploadFile[]): Promise<void> => {
        setIsLoading(true)
        const base64List: string[] = []
        const uploadPromises = fileList?.map(async (file) => {
            if (setBase64 != null) {
                const base64 = await convertBase64(
                    // file?.originFileObj as RcFile
                    file as RcFile
                )
                base64List.push(base64)
            }
            return await uploadFileToS3(file as RcFile, dynamicPath)
        })

        try {
            const responses = await Promise.all(uploadPromises)
            const locations = responses?.map(
                (response) => response?.Location as string
            )
            setFormData(name, [...value, ...locations])
            if (setBase64 != null) {
                setBase64([...value, ...base64List])
            }
            setValue([...value, ...locations])
        } catch (error) {
            console.error('Upload error: ', error)
            throw error
        } finally {
            setIsLoading(false)
        }
    }
    const handleRemove = (fileUrl: string): void => {
        setFileUrl(fileUrl)
        setDeleteModalStatus(!deleteModalStatus)
    }

    const deleteotherCompanyId = (): void => {
        const updatedValue = value.filter((url) => url !== fileUrl)
        setValue(updatedValue)
        setFormData(name, updatedValue)
        handleRemove('')
    }
    const DraggerProps = {
        name: 'file',
        multiple: true,
        accept: '.pdf,.jpg,.jpeg,.png',
        showUploadList: false,
        beforeUpload: async (file: { type: string }, fileList: any) => {
            const isPdfOrImage =
                file.type === 'application/pdf' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/jpg'
            if (!isPdfOrImage) {
                ErrorMessage(
                    'You can only upload PDF, JPG, JPEG, or PNG files!'
                )
                return Upload.LIST_IGNORE
            }

            try {
                if (
                    selectedDocumentType === undefined &&
                    selectedDocumentType === null &&
                    selectedDocumentType === 0
                ) {
                    ErrorMessage(
                        'Please select a document type before uploading.'
                    )
                } else {
                    await fileOnChange(fileList)
                }
            } catch (error) {
                ErrorMessage(`file upload failed.`)
            }
            return false
        },
        onChange(info: any) {
            const { status } = info.file
            if (status === 'uploading') {
                info.fileList.forEach((file: any) => {
                    if (file.status !== 'done') {
                        file.status = 'done'
                    }
                })
            }
        },
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault()
        setDragging(true)
    }

    const handleDragLeave = (): void => {
        setDragging(false)
    }
    const changeCreateEditModalStatus = (): void => {
        setPdfPreview(false)
    }
    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault()
        setDragging(false)
        const files = e.dataTransfer.files
        if (files?.length > 0) {
            const isAllValid = Array.from(files).every((file) => {
                const isPdfOrImage =
                    file.type === 'application/pdf' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/jpg'
                if (!isPdfOrImage) {
                    ErrorMessage(
                        'You can only upload PDF, JPG, JPEG, or PNG files!'
                    )
                }
                return isPdfOrImage
            })

            if (isAllValid) {
                // Manually trigger the file input change event
                const fileList = Array.from(files)
                const uploadComponent = document.querySelector(
                    '.ant-upload input[type="file"]'
                ) as HTMLInputElement
                if (uploadComponent !== null) {
                    Object.defineProperty(uploadComponent, 'files', {
                        value: fileList,
                        writable: false,
                    })
                    uploadComponent.dispatchEvent(
                        new Event('change', { bubbles: true })
                    )
                }
            }
        }
    }

    const getFileExtension = (url: string | undefined): string | undefined => {
        if (url != null) {
            return url.split('.').pop()?.toLowerCase()
        }
        return undefined
    }
    const handleDownload = (url: string): void => {
        setPdfPreview(true)
        setUrl(url)
        // Create a temporary anchor element
        // const link = document.createElement('a')
        // link.href = url
        // link.download = url.split('/').pop() ?? 'document.pdf' // Set the default file name if not available in URL
        // link.target = '_blank'
        // // Append the link to the document body and trigger the download
        // document.body.appendChild(link)
        // link.click()
        // // Remove the link from the document body
        // document.body.removeChild(link)
    }
    const renderFilePreview = (url: string, index: number): JSX.Element => {
        const fileExtension = getFileExtension(url)
        if (fileExtension === 'pdf') {
            return (
                <Col key={index}>
                    <Card
                        style={{ width: 200, marginTop: 16 }}
                        cover={
                            <PDFImage
                                width={200}
                                height={200}
                                style={{ cursor: 'pointer' }}
                            />
                        }
                        actions={[
                            <DeleteOutlined
                                key="delete"
                                onClick={() => {
                                    handleRemove(url)
                                }}
                            />,
                            <Tooltip
                                key="view"
                                title="View and Download Document"
                            >
                                <EyeOutlined
                                    onClick={() => {
                                        handleDownload(url)
                                    }}
                                    key="view"
                                />
                            </Tooltip>,
                        ]}
                    />
                </Col>
            )
        } else {
            return (
                <Col key={index} style={{ marginLeft: '5px' }}>
                    <Card
                        style={{ width: 200, marginTop: 16 }}
                        cover={
                            <Image
                                src={url}
                                alt={`preview-${index}`}
                                className={styles.previewImage}
                                width={200}
                                height={200}
                            />
                        }
                        actions={[
                            <DeleteOutlined
                                key="delete"
                                onClick={() => {
                                    handleRemove(url)
                                }}
                            />,
                        ]}
                    />
                </Col>
            )
        }
    }
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div
                className={`upload-container ${dragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                // style={{
                //     position: 'sticky',
                // }}
            >
                <Dragger {...DraggerProps}>
                    <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Only PDF, JPG,
                        JPEG, or PNG files are allowed.
                    </p>
                    {isLoading && (
                        <div className={layoutStyles.overlay}>
                            <Spin />
                        </div>
                    )}
                </Dragger>
            </div>
            <Row
                gutter={[16, 16]}
                style={{
                    marginTop: 10,
                    height: 'auto',
                    width: 'auto',
                    overflow: 'auto',
                }}
            >
                <Flex gap={5}>
                    {value.map((url, index) => renderFilePreview(url, index))}
                </Flex>
            </Row>

            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={''}
                open={previewPdf}
            >
                <PDFPreview pdfUrl={pdfURL} />
            </Modal>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={handleRemove}
                tittle="Are you sure you want to remove this file. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteotherCompanyId}
            />
        </div>
    )
}

export default S3UploadMultiple
