import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal, Space, Table, type TableProps } from 'antd'
import { CloudDownloadOutlined, MailOutlined } from '@ant-design/icons'
import { GetEnvelopeDetails } from '../../../../../../Services/Common'
import {
    type GetEnvelopeStateIntrface,
    type GetEnvelopeTableData,
    type GetenvelopeProps,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { connect, useDispatch } from 'react-redux'
import { type ColumnsType } from 'antd/es/table'
import SearchInput from '../../../../../../Components/SearchInput'
import EmailReminder from './EmailRemider'
import calculatePagination from '../../../../../../Utils/Pagination'

const SignableHistory = (props: GetenvelopeProps): React.ReactElement => {
    const { getEnvelopData, businessId, referenceId } = props
    const dispatch = useDispatch()
    const [viewModalVisible, setViewModalVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [filteredData, setFilteredData] = useState<GetEnvelopeTableData[]>([])
    const hasFetchedOnce = useRef(false)
    const getTableData = useCallback(() => {
        GetEnvelopeDetails(dispatch, businessId, referenceId, 0)
    }, [businessId, referenceId])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])
    const getEnvelopDatawithKey: GetEnvelopeTableData[] = getEnvelopData?.map(
        (item, index) => ({
            ...item,
            key: index, // or any unique identifier for the key
        })
    )
    useEffect(() => {
        setFilteredData(getEnvelopDatawithKey)
    }, [getEnvelopData])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        filteredData?.length,
        itemsPerPage,
        handlePageSizeChange
    )

    const Servicecolumns: ColumnsType<GetEnvelopeTableData> = [
        {
            title: 'Title',
            dataIndex: 'envelopeTitle',
        },
        {
            title: 'Status',
            dataIndex: 'envelopeStatus',
        },
        {
            title: 'Created',
            dataIndex: 'envelopeCreated',
        },
        {
            title: 'Sent',
            dataIndex: 'envelopeSent',
        },
        {
            title: '',
            key: 'action',
            render: (_: any, record: GetEnvelopeTableData) => {
                return (
                    <Space size="middle">
                        {record.envelopeStatus === 'signed' ? (
                            <a
                                href={record.envelopeSignedPdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                            >
                                <CloudDownloadOutlined />
                            </a>
                        ) : (
                            <MailOutlined
                                onClick={() => {
                                    setViewModalVisible(true)
                                }}
                            />
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<GetEnvelopeTableData>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
    }

    const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const query = event.target.value.toLowerCase()
        const filtered = getEnvelopDatawithKey.filter(
            (item) =>
                item.envelopeTitle.toLowerCase().includes(query) ||
                item.envelopeStatus.toLowerCase().includes(query) ||
                item.envelopeCreated.toLowerCase().includes(query) ||
                item.envelopeSent.toLowerCase().includes(query)
        )
        setFilteredData(filtered)
    }

    const onEmailCloseModel = (): void => {
        setViewModalVisible(false)
    }
    return (
        <>
            <div>
                <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <SearchInput
                        placeHolder={'Search Your Keyword & enter'}
                        onChange={onChangeText}
                        width="278"
                    />
                </div>
                <Table
                    columns={Servicecolumns}
                    dataSource={filteredData}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                />
            </div>
            <Modal
                title="Email Reminder"
                onCancel={() => {
                    onEmailCloseModel()
                }}
                open={viewModalVisible}
                footer={null}
                width={'50%'}
            >
                <EmailReminder
                    referenceId={referenceId}
                    businessId={businessId}
                    onEmailCloseModel={onEmailCloseModel}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): GetEnvelopeStateIntrface => {
    return {
        getEnvelopData: state.common.getEnvelopData,
    }
}

export default connect(mapStateToProps)(SignableHistory)
