import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Row, Form, Table, Button, Input, Tooltip } from 'antd'
import commonStyles from '../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import 'react-quill/dist/quill.snow.css'
import { SaveOutlined } from '@ant-design/icons'
import {
    type BulkmailFormStateInterface,
    type BulkmailListDataTypeInterface,
} from '../../Types/Bulksms'
import { type TableParamsInterface } from '../../Types/CommonType'
import { commonTableParams } from '../../Utils/CommontTable'
import calculatePagination from '../../Utils/Pagination'
import { SaveBulkSms } from '../../Services/Bulksms'
import SearchInput from '../../Components/SearchInput'
import { ErrorMessage } from '../../Utils/Notification'
import ActivePremission from '../../Utils/premissins'
import { PermissionType } from '../../Types/Enum/PermissionType'

const SearchForm = (props: BulkmailFormStateInterface): React.ReactElement => {
    const {
        contactdepartmentDropDownList,
        contactdepartmentDropDownListCount,
    } = props

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedEmailAddresses, setSelectedEmailAddresses] = useState<
        string[]
    >([])
    const [text, setText] = useState('')

    const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
        setSelectedRowKeys(newSelectedRowKeys)

        // Extract and store selected email addresses
        const selectedEmails = contactdepartmentDropDownList
            ?.filter((item) => newSelectedRowKeys.includes(item.key))
            ?.map((item) => item.contactNumber)

        setSelectedEmailAddresses(selectedEmails)
    }
    const MAX_SMS_LENGTH = 324
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: contactdepartmentDropDownListCount,
            },
        })
    }, [contactdepartmentDropDownListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        contactdepartmentDropDownList?.length,
        itemsPerPage,
        handlePageSizeChange
    )
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const columns: ColumnsType<BulkmailListDataTypeInterface> = [
        {
            title: 'Name',
            dataIndex: 'contactName',
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
        },
    ]

    const onChangeTableParams: TableProps<BulkmailListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'contactName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const contactNumber = selectedEmailAddresses.join(', ')
            if (contactNumber?.length !== 0) {
                const formData = form.getFieldsValue()
                const dataToSave = {
                    clientSMSId: formData.clientSMSId,
                    serviceProcessId: formData.serviceProcessId,
                    description: text,
                    smsStatus: '',
                    contactNumber,
                }

                await SaveBulkSms(dispatch, dataToSave)

                form.resetFields()
            } else {
                ErrorMessage('Please Select Atleast One Contact')
            }
        } catch (error) {}
    }

    const handleChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        const newText = event.target.value
        if (newText?.length <= MAX_SMS_LENGTH) {
            setText(newText)
            form.setFieldsValue({ description: newText })
        }
    }
    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 28 }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
        >
            <Col>
                <Form.Item
                    label="SMS Content"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter SMS Content !',
                        },
                    ]}
                >
                    <Input.TextArea
                        value={text}
                        onChange={handleChange}
                        rows={5}
                    />
                    <div style={{ textAlign: 'left', marginTop: '5px' }}>
                        Remaining characters :{MAX_SMS_LENGTH - text?.length}
                    </div>
                </Form.Item>
            </Col>

            <Row gutter={16} align="middle">
                <div className={commonStyles.corpList}>
                    <Col>
                        <SearchInput
                            placeHolder={'Search CompanyName by keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={contactdepartmentDropDownList?.filter(
                        (item) => {
                            const fullName =
                                `${item.contactName} ${item.contactName}`.toLowerCase()
                            return fullName.includes(
                                tableParams?.filter.fieldValue1.toLowerCase()
                            )
                        }
                    )}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                />
            </div>

            {ActivePremission('12001', PermissionType.SAVE) ? (
                <Col offset={22} span={2}>
                    <Form.Item>
                        <Tooltip title="Save BulkSms">
                            <Button
                                type="primary"
                                onClick={onSave}
                                size="middle"
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Col>
            ) : (
                <></>
            )}
        </Form>
    )
}

const mapStateToProps = (state: any): BulkmailFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        contactdepartmentDropDownList:
            state.initial.contactdepartmentDropDownList,
        contactdepartmentDropDownListCount:
            state.initial.contactdepartmentDropDownListCount,
    }
}
export default connect(mapStateToProps)(SearchForm)
