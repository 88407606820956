import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type EvenetPayloadInteface,
    type EventlistFormDataInterface,
} from '../Types/HelpDesk/ManageDiary'
import {
    type taskPayloadInteface,
    type TaskData,
    type TaskFollowerRequestBody,
    type follwersPayloadInterface,
    type hourssPayloadInterface,
    type TaskHours,
    type User,
    type UserListPayloadInterface,
    type TaskAttachment,
    type TaskAttachmentPayloadInterface,
    type subTaskData,
    type subtaskPayloadInteface,
    type ceteteclient,
    type adhocclientPayloadInteface,
} from '../Types/HelpDesk/Cretetask'
import { type ListFrontEndInterface } from '../Types/CommonType'
import {
    type newclientPayloadInteface,
    type cetetewclient,
} from '../Types/Client/RegisteredClients/registerClient'

interface HrStateInterface {
    EventList: EventlistFormDataInterface[]
    EventListCount: number
    createadocclientList: ceteteclient[]
    createadocclientListCount: number
    creatnewclientList: cetetewclient[]
    creatnewclientListCount: number
    taskDetailsList: TaskData[]
    taskDetailsListCount: number
    subtaskDetailsList: subTaskData[]
    subtaskDetailsListCount: number
    follwersList: TaskFollowerRequestBody[]
    follwersListCount: number
    hoursList: TaskHours[]
    hoursListCount: number
    userimageList: User[]
    userimageListCount: number
    TaskAttachmentList: TaskAttachment[]
    TaskAttachmentListCount: number
    satusList: ListFrontEndInterface[]
    projectDropDownList: ListFrontEndInterface[]
    allDepartmentList: ListFrontEndInterface[]
    treeListOut: any[]
    ProjectManagmentStausListInprogress: any[]
    SubViewDetail: TaskData[]
}

const HelpdeskReducer = createSlice({
    name: 'helpdesk',
    initialState: {
        EventList: [],
        EventListCount: 0,
        createadocclientList: [],
        createadocclientListCount: 0,
        creatnewclientList: [],
        creatnewclientListCount: 0,
        taskDetailsList: [],
        taskDetailsListCount: 0,
        hoursList: [],
        hoursListCount: 0,
        subtaskDetailsList: [],
        subtaskDetailsListCount: 0,
        follwersList: [],
        follwersListCount: 0,
        userimageList: [],
        userimageListCount: 0,
        TaskAttachmentList: [],
        TaskAttachmentListCount: 0,
        satusList: [],
        projectDropDownList: [],
        allDepartmentList: [],
        treeListOut: [],
        ProjectManagmentStausListInprogress: [],
        SubViewDetail: [],
    },
    reducers: {
        EventList(
            state: HrStateInterface,
            action: PayloadAction<EvenetPayloadInteface>
        ) {
            state.EventList = action?.payload?.data
            state.EventListCount = action?.payload?.noOfRecords
        },
        createadocclientList(
            state: HrStateInterface,
            action: PayloadAction<adhocclientPayloadInteface>
        ) {
            state.createadocclientList = action?.payload?.data
            state.createadocclientListCount = action?.payload?.noOfRecords
        },
        creatnewclientList(
            state: HrStateInterface,
            action: PayloadAction<newclientPayloadInteface>
        ) {
            state.creatnewclientList = action?.payload?.data
            state.creatnewclientListCount = action?.payload?.noOfRecords
        },
        taskDetailsList(
            state: HrStateInterface,
            action: PayloadAction<taskPayloadInteface>
        ) {
            state.taskDetailsList = action?.payload?.data
            state.taskDetailsListCount = action?.payload?.noOfRecords
        },
        follwersList(
            state: HrStateInterface,
            action: PayloadAction<follwersPayloadInterface>
        ) {
            state.follwersList = action?.payload?.data
            state.follwersListCount = action?.payload?.noOfRecords
        },
        hoursList(
            state: HrStateInterface,
            action: PayloadAction<hourssPayloadInterface>
        ) {
            state.hoursList = action?.payload?.data
            state.hoursListCount = action?.payload?.noOfRecords
        },
        userimageList(
            state: HrStateInterface,
            action: PayloadAction<UserListPayloadInterface>
        ) {
            state.userimageList = action?.payload?.data
            state.userimageListCount = action?.payload?.noOfRecords
        },

        TaskAttachmentList(
            state: HrStateInterface,
            action: PayloadAction<TaskAttachmentPayloadInterface>
        ) {
            state.TaskAttachmentList = action?.payload?.data
            state.TaskAttachmentListCount = action?.payload?.noOfRecords
        },
        subtaskDetailsList(
            state: HrStateInterface,
            action: PayloadAction<subtaskPayloadInteface>
        ) {
            state.subtaskDetailsList = action?.payload?.data
            state.subtaskDetailsListCount = action?.payload?.noOfRecords
        },
        satusList(
            state: HrStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.satusList = action?.payload
        },
        projectDropDownList(
            state: HrStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.projectDropDownList = action?.payload
        },
        allDepartmentList(
            state: HrStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.allDepartmentList = action?.payload
        },
        treeListOut(
            state: HrStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.treeListOut = action?.payload
        },
        ProjectManagmentStausListInprogress(
            state: HrStateInterface,
            action: PayloadAction<taskPayloadInteface>
        ) {
            state.ProjectManagmentStausListInprogress = action?.payload?.data
        },
        SubViewDetail(
            state: HrStateInterface,
            action: PayloadAction<taskPayloadInteface>
        ) {
            state.SubViewDetail = action?.payload?.data
            state.taskDetailsListCount = action?.payload?.noOfRecords
        },
    },
})

export const {
    satusList,
    EventList,
    taskDetailsList,
    follwersList,
    hoursList,
    userimageList,
    TaskAttachmentList,
    subtaskDetailsList,
    projectDropDownList,
    allDepartmentList,
    treeListOut,
    ProjectManagmentStausListInprogress,
    SubViewDetail,
    creatnewclientList,
    createadocclientList,
} = HelpdeskReducer.actions
export default HelpdeskReducer.reducer
