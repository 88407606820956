import React, { useEffect, useRef, useState } from 'react'
import { Button, Select, Form, Row, Col, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
import {
    type FirstPagePropss,
    type TaskFollowerRequestBodysform,
} from '../../../Types/HelpDesk/Cretetask'
import {
    Savefollowers,
    getfollwersdroplist,
} from '../../../Services/Helpdesk/CreteTask'
import { GetUserList } from '../../../Services/User'
import commonStyles from '../../../Utils/Common.less'
const CreateFollowersModal = (
    props: FirstPagePropss & {
        taskid: string
        setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>
        selectedUsers: number[]
        resetdata: string
        setResetData: React.Dispatch<React.SetStateAction<string>>
    }
): React.ReactElement => {
    const {
        taskid,
        userList,
        follwersList,
        selectedUsers,
        setSelectedUsers,
        resetdata,
        setResetData,
        onSave,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const hasFetchedOnce = useRef(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [])

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
        }

        setResetData('false')
    }, [resetdata])

    useEffect(() => {
        if (taskid !== null && taskid !== '') {
            void getfollwersdroplist(dispatch, taskid)
        }
    }, [taskid])

    useEffect(() => {
        if (typeof follwersList === 'string') {
            const followerIdsStringArray = String(follwersList).split(',')
            const followerIds = followerIdsStringArray
                .map((id) => parseInt(id, 10))
                .filter((id) => !isNaN(id))
            setSelectedUsers(followerIds)
            form.setFieldValue('followerIds', followerIds)
        }
    }, [follwersList, taskid])
    const handleSave = (): void => {
        void handleSaveData()
    }

    const resetForm = (): void => {
        form.resetFields()
    }
    const handleSaveData = async (): Promise<void> => {
        try {
            await form.validateFields(['followerIds'])
            setButtonLoading(true)
            const formattedValues = {
                taskFollowerId: 0,
                taskId: Number(taskid),
                followerIds: selectedUsers,
            }

            await Savefollowers(dispatch, formattedValues)
                .then(() => {
                    form.resetFields()
                    setButtonLoading(false)
                    onSave(resetForm)
                })
                .catch((error) => {
                    console.error('Failed to save followers:', error)
                })
        } catch {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Followers"
                        name="followerIds"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Followers',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Please select"
                            value={selectedUsers}
                            onChange={(value) => {
                                setSelectedUsers(value)
                            }}
                            options={userList}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.label
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase()) ?? false
                            }
                        >
                            {/* {userList.map((user) => (
                        <Select.Option key={user.id} value={user.value}>
                            {user.label}
                        </Select.Option>
                    ))} */}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col offset={21}>
                    <Tooltip title="Save Followers">
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={handleSave}
                            loading={buttonLoading}
                        >
                            <SaveOutlined />
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        </Form>
    )
}

const mapStateToProps = (state: any): TaskFollowerRequestBodysform => {
    return {
        userList: state.user.userList,
        follwersList: state.helpdesk.follwersList,
        follwersListCount: state.helpdesk.follwersListCount,
    }
}

export default connect(mapStateToProps)(CreateFollowersModal)
