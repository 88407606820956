import { Col, Row } from 'antd'
import React from 'react'
import { type CorporateOfficerView } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { formatdate } from '../../../../../../Utils/dateFormat'

const PersonMemberView = (props: {
    officer: CorporateOfficerView
}): React.ReactElement => {
    const { officer } = props

    return (
        <>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Date of Birth</p>
                </Col>
                <Col span={6}>
                    <p>: {formatdate(officer?.dateOfBirth)}</p>
                </Col>

                <Col span={5}>
                    <p>Address </p>
                </Col>
                <Col span={7}>
                    <p>
                        :{officer?.addressLineOne}
                        {officer?.addressLineTwo}
                        {officer?.town}
                    </p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Home No</p>
                </Col>
                <Col span={6}>
                    <p>: {officer?.homeNo}</p>
                </Col>
                <Col span={5}>
                    <p>Post Code </p>
                </Col>
                <Col span={7}>
                    <p>: {officer?.postCode}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Mobile No</p>
                </Col>
                <Col span={6}>
                    <p>: {officer?.mobileNo}</p>
                </Col>
                <Col span={5}>
                    <p>Country </p>
                </Col>
                <Col span={7}>
                    <p>: {officer?.country}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Work </p>
                </Col>
                <Col span={6}>
                    <p>: {officer?.workNo}</p>
                </Col>
                <Col span={5}>
                    <p>NI No </p>
                </Col>
                <Col span={7}>
                    <p>: {officer?.niNumber}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Nationality</p>
                </Col>
                <Col span={6}>
                    <p>: {officer?.nationality}</p>
                </Col>
                <Col span={5}>
                    <p>Residence Country </p>
                </Col>
                <Col span={7}>
                    <p>:{officer?.countryOfResidence}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Email</p>
                </Col>
                <Col span={6}>
                    <p>: {officer?.email} </p>
                </Col>
                <Col span={5}>
                    <p>Alternative Email </p>
                </Col>
                <Col span={7}>
                    <p>:{officer?.alternativeEmail}</p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Occupation</p>
                </Col>
                <Col span={6}>
                    <p>: {officer?.occupation}</p>
                </Col>
                <Col span={5}>
                    <p>Shareholding Percentage</p>
                </Col>
                <Col span={7}>
                    <p>
                        :
                        {officer?.shareHoldingPercentage !== 0
                            ? officer?.shareHoldingPercentage
                            : ''}
                    </p>
                </Col>
            </Row>
            <Row gutter={[12, 8]}>
                <Col span={4}>
                    <p>Number of Shares</p>
                </Col>
                <Col span={6}>
                    <p>
                        : {officer?.noOfShares !== 0 ? officer?.noOfShares : ''}
                    </p>
                </Col>
                <Col span={5}>
                    <p>Shareholder Type</p>
                </Col>
                <Col span={7}>
                    <p>:{officer?.shareHolderTypeName}</p>
                </Col>
            </Row>

            {Array.isArray(officer.officerRoleInformationList) &&
                officer.officerRoleInformationList?.map(
                    (roleInfo: any, roleIndex: any) => (
                        <Row gutter={[12, 8]} key={roleIndex}>
                            <Col span={4}>
                                <p>
                                    Appoinment Date
                                    <br /> ({roleInfo?.memberType})
                                </p>
                            </Col>
                            <Col span={6}>
                                <p>:{roleInfo?.dateOfAppointment}</p>
                            </Col>
                        </Row>
                    )
                )}
        </>
    )
}

export default PersonMemberView
