import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    Card,
    Table,
    Space,
    Modal,
    Switch,
    Col,
    Row,
    Tooltip,
    Drawer,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import {
    MessageOutlined,
    EyeOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'
import type {
    IndividualListStateInterface,
    IndividualListDataTypeInterface,
    IndividualListInterface,
} from '../../../../Types/Client/RegisteredClients/Individual'
import {
    ActiveInactiveIndividual,
    DeleteIndividual,
    GetIndividualList,
} from '../../../../Services/Individual'
import AddNewButton from '../../../../Components/AddNewButton'
import IndividualExpandView from './IndividualExpandView'
import { useNavigate } from 'react-router-dom'
import PortfolioView from '../../../../Components/viewProfolioDetails'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import calculatePagination from '../../../../Utils/Pagination'
import StaffCommunication from '../../../../Components/StaffChat'
import { GetChat } from '../../../../Services/Common'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
export const IndividualItemDefaultData = {
    individualId: '0',
    name: '',
    tradingName: '',
    typeOfSelfEmployment: '',
    clientNumber: '',
    activestatus: '',
    serviceIds: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const IndividualList = (props: IndividualListInterface): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        individualList,
        individualListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [individualId, setIndividualId] = useState(0)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')

    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [portfolioModel, setportfolioModel] = useState(false)
    const [refernceId, setrefernceId] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (individualId !== 0) {
            void GetChat(
                dispatch,
                7,
                individualId,
                3,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [individualId])

    const openChatModal = (individualId: number): void => {
        setIndividualId(individualId)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetIndividualList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: individualListCount,
            },
        })
    }, [individualListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const viewIndividualDetails = (id: number): void => {
        const individualData = individualList.find(
            (item) => item.individualId === id
        )
        const onbordingId = 0
        navigate(`/clients/registered-clients/individual-view`, {
            state: { individualData, onbordingId },
        })
    }
    const createIndividual = (id: number): void => {
        const individualData = individualList.find(
            (item) => item.individualId === id
        )
        navigate(`/clients/registered-clients/individual-new`, {
            state: { individualData },
        })
    }

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }

    const pagination = calculatePagination(
        currentPage,
        individualListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const columns: ColumnsType<IndividualListDataTypeInterface> = [
        {
            width: 150,
            title: 'Client No',
            dataIndex: 'clientNumber',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            width: 150,
            title: 'Name',
            render: (_, record: IndividualListDataTypeInterface) => {
                return (
                    <div>
                        {record.name}
                        {record.activestatus !== 'Active' && (
                            <span
                                style={{
                                    backgroundColor: '#f7e9f1',
                                    padding: '3px',
                                    fontSize: '11px',
                                    color: '#fb61a9',
                                    borderRadius: 2,
                                    fontWeight: 500,
                                }}
                            >
                                {record.activestatus}
                            </span>
                        )}
                    </div>
                )
            },
        },
        {
            width: 150,
            title: 'Trading Name',
            dataIndex: 'tradingName',
        },
        {
            width: 200,
            title: ' Employment Type',
            dataIndex: 'typeOfSelfEmployment',
        },
        {
            width: 150,
            title: 'Services',
            dataIndex: 'serviceIds',
        },
        {
            width: 150,
            title: '',
            key: 'action1',
            render: (_, record: IndividualListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.individualId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Client Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    viewIndividualDetails(record?.individualId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.individualId,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) ? (
                            <Tooltip title="Delete Client">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.individualId
                                        )
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        <Tooltip title="View Portfolio Details">
                            <UnorderedListOutlined
                                onClick={() => {
                                    openProtfolio(record?.individualId)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<IndividualListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let individualId = 0
        if (!deleteModalStatus) {
            individualId = id
        }
        setIndividualId(individualId)
    }

    const deleteIndividual = (): void => {
        DeleteIndividual(dispatch, individualId, getTableData)
        deleteModalFunction(0)
    }

    const activeInactiveModalFunction = (
        id: number,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let individualId = 0
        if (!activeInactiveModalStatus) {
            individualId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setIndividualId(individualId)
    }

    const updateActiveInactiveIndividual = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveIndividual(dispatch, individualId, status, getTableData)
        activeInactiveModalFunction(0, '')
    }

    const openProtfolio = (refernceid: number): void => {
        setrefernceId(refernceid)
        setportfolioModel(!portfolioModel)
    }

    const expandableData = (
        record: IndividualListDataTypeInterface
    ): JSX.Element => {
        return <IndividualExpandView IndividualData={record} />
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.cardContainer}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={
                                'Search Individual  Details  by keywords'
                            }
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                            width="478"
                        />
                    </Col>
                    <Col span={4}>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                createIndividual(0)
                            }}
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={individualList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={() => {
                    deleteModalFunction(0)
                }}
                tittle="You are about to delete this Client. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteIndividual}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction(0, '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this Client. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactiveIndividual}
            />

            {/* <Modal
                title="Staff Comm"
                open={chatModalOpen}
                onCancel={() => {
                    setChatModalOpen(false)
                }}
                footer={null}
                width={'50%'}
            >
                {selectedIndividualId != null && (
                    <IndividualChat individualId={selectedIndividualId ?? 10} />
                )}
            </Modal> */}
            <Modal
                title="Portfolio Details"
                open={portfolioModel}
                onCancel={() => {
                    setportfolioModel(!portfolioModel)
                }}
                footer={null}
                width={'50%'}
            >
                <PortfolioView
                    businessId={3}
                    referenceId={refernceId}
                    portfolioId={0}
                />
            </Modal>
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={individualId}
                    messageTypeId={7}
                    businessId={3}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (state: any): IndividualListStateInterface => {
    return {
        individualList: state.client.individualList,
        individualListCount: state.client.individualListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(IndividualList)
