import React, { useState } from 'react'
import { Button } from 'antd'
import styles from '../Components/Less/SelectiveButton.less' // Import the CSS file

interface SelectiveButtonProps {
    name: string
    clickAction: () => void
    className?: string
}

const SelectiveButton: React.FC<SelectiveButtonProps> = ({
    name,
    clickAction,
    className,
}) => {
    const [isSelected, setIsSelected] = useState(false)

    const handleButtonClick = (): void => {
        setIsSelected(!isSelected)
        clickAction()
    }

    return (
        <Button
            type="primary"
            className={`${styles.previewButton} ${className} ${
                isSelected ? styles.selected : ''
            }`}
            onClick={handleButtonClick}
            size="small"
        >
            {name}
        </Button>
    )
}

export default SelectiveButton
