import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'

import { type ContactInfo } from '../../../Types/Invoice/Invoicing'
import { GetInvoiceDetails } from '../../../Services/Invoice'
import { type ModelProps } from '../../../Types/ToDoViewAllList'
import { SaveCommuniCationNormal } from '../../../Services/ToDoViewAllList'

const SMSModelPage = (props: ModelProps): React.ReactElement => {
    const { serviceProcessId, changeModalStatus } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [induvidualSmsDetails, setInduvidualSmsDetails] =
        useState<ContactInfo | null>(null)

    useEffect(() => {
        const fetchData = (): void => {
            void GetInvoiceDetails(
                dispatch,
                serviceProcessId,
                (details: ContactInfo) => {
                    setInduvidualSmsDetails(details)
                }
            )
        }
        fetchData()
    }, [serviceProcessId])

    useEffect(() => {
        if (
            induvidualSmsDetails !== null &&
            typeof induvidualSmsDetails === 'object'
        ) {
            const newFieldValues = {
                contactName: induvidualSmsDetails?.contactName,
                contactNumber: induvidualSmsDetails?.contactNumber,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [induvidualSmsDetails, form])

    const onSave = (): void => {
        void onSaveSms()
    }
    const onSaveSms = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataTosave = {
                communicationHistoryId: 0,
                communicationTypeId: 1,
                serviceProcessId,
                description: formData.description,
                historyDate: '',
                contactNumber: formData.contactNumber,
            }

            await SaveCommuniCationNormal(dispatch, dataTosave)

            form.setFieldsValue({
                description: '',
            })

            changeModalStatus()
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item
                    name="invoiceId"
                    hidden={true}
                    initialValue={serviceProcessId}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="contactName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your firstName!',
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Phone Number"
                            name="contactNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="SMS Content "
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Message',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save SMS">
                            <Button
                                type="primary"
                                onClick={onSave}
                                size="middle"
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default SMSModelPage
