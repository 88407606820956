import { Button, Card, Col, Form, Radio, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import SearchInputText from '../../../Components/SearchInputText'
import companyEligiblity from './companyEligiblity.less'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import { connect, useDispatch } from 'react-redux'
import {
    CheckCompanyEligiblity,
    GetCHCompanyType,
    GetCHEndingName,
    SaveCompanyEligiblityStatus,
} from '../../../Services/CompanyHouse/CompanyEligiblity'
import {
    type CompanyAvailabityStateInterface,
    type CompanyRegistrationResponse,
} from '../../../Types/CompanyHouse/CompanyEligiblity'

const CompanyEligiblity = (
    props: CompanyAvailabityStateInterface
): React.ReactElement => {
    const { chCompanyType, endingNameList } = props
    const [form] = Form.useForm()
    const [showsuccessCard, setShowsucessCrad] = useState(false)
    const dispatch = useDispatch()
    const [avalablityData, setAvailablityData] =
        useState<CompanyRegistrationResponse>()
    const [availableStatus, setAvailableStatus] = useState<number>(0)
    const [searchData, setSearchData] = useState('')
    const [companyTypeId, setCompanyTypeId] = useState(0)
    const [endingNameId, setEndingNameId] = useState(0)
    useEffect(() => {
        GetCHCompanyType(dispatch)
    }, [])
    useEffect(() => {
        if (showsuccessCard) {
            GetCHEndingName(dispatch, companyTypeId, availableStatus)
        }
    }, [companyTypeId, availableStatus])
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length !== 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        if (data.length > 0) {
            void AvailablityChecker(data.toString())
            setSearchData(data)
        }
    }

    const AvailablityChecker = async (datas: string): Promise<void> => {
        try {
            const data = await CheckCompanyEligiblity(dispatch, datas)
            setAvailablityData(data)
        } catch (error) {
            console.error('Error fetching audit data:', error)
        }
    }

    const onclick = (): void => {
        if (searchData.length > 0) {
            setShowsucessCrad(!showsuccessCard)
        }
    }

    const handleStatusChange = (e: any): void => {
        setAvailableStatus(e.target.value)
    }
    const onCompanyTypeChange = (value: number): void => {
        setCompanyTypeId(value)
    }
    const onchangeEndingName = (value: number): void => {
        setEndingNameId(value)
    }
    const handelSave = (): void => {
        const CompanyDetails = {
            companyId: 0,
            companyName: searchData,
            companyType: companyTypeId,
            countryofIncorporation: '',
            companyEndingId: endingNameId,
            companyEndingDetailId: 0,
            address1: '',
            address2: '',
            town: '',
            country: '',
            postCode: '',
            sicCode1: 0,
            sicCode2: 0,
            sicCode3: 0,
            sicCode4: 0,
            isCommunityIntersetCompany: availableStatus,
        }
        void SaveCompanyEligiblityStatus(dispatch, CompanyDetails)
    }
    return (
        <>
            <Card>
                <h1 className={companyEligiblity.titleText}>
                    Company name availability checker
                </h1>
                <span className={companyEligiblity.subText}>
                    Enter proposed company name
                </span>
                <Row>
                    <Col span={22}>
                        <SearchInputText
                            placeHolder={'Search'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                            height="50"
                            onclick={onclick}
                        />
                    </Col>
                </Row>
                {showsuccessCard && (
                    <Card style={{ marginTop: 20 }}>
                        <h4>{avalablityData?.message}</h4>
                        <div className={commonStyles.formWrapper}>
                            <Form
                                name="complex-form"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                layout={'vertical'}
                                autoComplete="off"
                                form={form}
                            >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Company Type"
                                            name="companyType"
                                        >
                                            <Select
                                                allowClear
                                                placeholder="Please select"
                                                onChange={(value) => {
                                                    onCompanyTypeChange(value)
                                                }}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option?.label
                                                        ?.toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        ) ?? false
                                                }
                                                options={[
                                                    {
                                                        label: 'Please select',
                                                        value: null,
                                                    },
                                                    ...chCompanyType?.map(
                                                        (service) => ({
                                                            value: service.value,
                                                            label: service.label,
                                                        })
                                                    ),
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <span>
                                                Will the company be a &quot;
                                                Community Interest
                                                Company&quot;?
                                            </span>
                                            <div style={{ marginTop: 10 }}>
                                                <Radio.Group
                                                    name="radiogroup"
                                                    value={availableStatus}
                                                    onChange={
                                                        handleStatusChange
                                                    }
                                                >
                                                    <Radio value={1}>YES</Radio>
                                                    <Radio value={0}>No</Radio>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Ending Name"
                                            name="endingName"
                                        >
                                            <Select
                                                allowClear
                                                placeholder="Please select"
                                                onChange={(value) => {
                                                    onchangeEndingName(value)
                                                }}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option?.label
                                                        ?.toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        ) ?? false
                                                }
                                                options={[
                                                    {
                                                        label: 'Please select',
                                                        value: null,
                                                    },
                                                    ...endingNameList?.map(
                                                        (service) => ({
                                                            value: service.value,
                                                            label: service.label,
                                                        })
                                                    ),
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {avalablityData?.availableStatus === 1 && (
                                    <Row gutter={16}>
                                        <Col span={2}>
                                            <Button
                                                type="primary"
                                                onClick={handelSave}
                                            >
                                                <SaveOutlined />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        </div>
                    </Card>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): CompanyAvailabityStateInterface => {
    return {
        chCompanyType: state.companyHouse.chCompanyType,
        endingNameList: state.companyHouse.endingNameList,
    }
}

export default connect(mapStateToProps)(CompanyEligiblity)
