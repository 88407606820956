import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    DatePicker,
    Switch,
    Radio,
    InputNumber,
    Tooltip,
    type SelectProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import styles from '../../../Layouts/Less/style.less'
import React, { useEffect, useRef, useState } from 'react'
import axios from '../../../Config/AxiosConfig'
import { GetUserList } from '../../../Services/User'
import {
    GetServiceDropDownList,
    GetPriorityList,
} from '../../../Services/Corporate'
import { ErrorMessage } from '../../../Utils/Notification'
import {
    type ClientRequestDetail,
    type MobileCreateTaskInterface,
    type MobileCreateTaskStateInterface,
} from '../../../Types/MobileRequest'
import {
    ConvertTask,
    GetInduvidualMobileRequest,
} from '../../../Services/MobileRequest'
import moment from 'moment'

type DaysOfWeek =
    | 'IsRepeatOnMon'
    | 'IsRepeatOnTue'
    | 'IsRepeatOnWed'
    | 'IsRepeatOnThu'
    | 'IsRepeatOnFri'
    | 'IsRepeatOnSat'
    | 'IsRepeatOnSun'

const MobileRequestCreateTask = (
    props: MobileCreateTaskInterface
): React.ReactElement => {
    const {
        serviceDropDownList,
        userList,
        priorityDropDownList,
        onClose,
        clientRequestId,
        companyType,
        referenceId,
    } = props

    const initialSwitchStates: Record<DaysOfWeek, number> = {
        IsRepeatOnMon: 0,
        IsRepeatOnTue: 0,
        IsRepeatOnWed: 0,
        IsRepeatOnThu: 0,
        IsRepeatOnFri: 0,
        IsRepeatOnSat: 0,
        IsRepeatOnSun: 0,
    }
    const RecurringFrequencyTypeOption = [
        { label: 'Day', value: '1' },
        { label: 'Week', value: '2' },
        { label: 'Month', value: '3' },
        { label: 'Year', value: '4' },
    ]

    const taskTypeOptions = [
        { label: 'Onetime', value: '0' },
        { label: 'Recurring', value: '1' },
    ]

    const reqrutingFrequencyOptions = [
        { label: 'Daily', value: '1' },
        { label: 'Custom', value: '5' },
    ]
    const [endsValue, setEndsValue] = useState(1)

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetServiceDropDownList(dispatch)
        GetPriorityList(dispatch)
    }, [dispatch, form])
    const [showInput, setShowInput] = useState(false)

    const handleSelectChange = (value: string): void => {
        if (value === '1') {
            setShowInput(true)
        } else {
            setShowInput(false)
        }
    }
    const [showCustomOptions, setShowCustomOptions] = useState(false)
    const [switchStates, setSwitchStates] = useState(initialSwitchStates)
    const [searchValue, setSearchValue] = useState<string>('')
    const [, setSelectValue] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<SelectProps['options']>([])
    const [induvidualMobileData, setInduvidualMobileData] =
        useState<ClientRequestDetail | null>(null)
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const data = await GetInduvidualMobileRequest(
                    dispatch,
                    clientRequestId
                )
                setInduvidualMobileData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }

        void fetchData()
    }, [clientRequestId])

    useEffect(() => {
        form.setFieldsValue({
            ClientName: induvidualMobileData?.clientName,
            ServiceId: induvidualMobileData?.serviceId,
            Description: induvidualMobileData?.description,
        })
        if (
            induvidualMobileData !== undefined &&
            induvidualMobileData !== null
        ) {
            setSearchValue(induvidualMobileData?.clientName)
        }
    }, [induvidualMobileData])

    const handleRecurringPlanChange = (value: string): void => {
        if (value === '5') {
            setShowCustomOptions(true)
        } else {
            setShowCustomOptions(false)
        }
    }
    const [showToggles, setShowToggles] = useState(false)

    const handleDropdownChange = (value: string): void => {
        if (value === '2') {
            setShowToggles(true)
        } else {
            setShowToggles(false)
        }
    }

    const handleFinish = (): void => {
        const formData = form.getFieldsValue()

        const currentDate = new Date()
        const formattedDate = currentDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        })

        const dataToSave = {
            taskId: 0,
            taskNo: '',
            taskName: formData.TaskName ?? '',
            priorityId: formData.PriorityId ?? 0,
            sortOrder: 0,
            taskType: 0,
            businessType: companyType,
            referenceId,
            serviceId: formData.ServiceId ?? induvidualMobileData?.serviceId,
            serviceReferenceId: 0,
            assignedBy: 1,
            assignedTo: formData.AssignedTo,
            fromDate: formattedDate,
            toDate: formData.ToDate.format('D/M/YYYY'),
            statusId: 0,
            notifyToStatusChange: 0,
            description:
                formData.Description ?? induvidualMobileData?.description,
            clientName: formData.ClientName ?? induvidualMobileData?.clientName,
            recurringFrequency: formData.RecurringFrequency ?? 0,
            recurringFrequencyType: formData.RecurringFrequencyType ?? 0,
            recurringFrequencyPeriodId:
                formData.RecurringFrequencyPeriodId ?? 0,
            recurringEnds: formData.RecurringEnds ?? 0,
            recurringOccurrence: formData.RecurringOccurrence ?? 0,
            recurringEndsOn: formData.RecurringEndsOn ?? 0,
            isRepeatOnSun: formData.IsRepeatOnSun ?? 0,
            isRepeatOnMon: formData.IsRepeatOnMon ?? 0,
            isRepeatOnTue: formData.IsRepeatOnTue ?? 0,
            isRepeatOnWed: formData.IsRepeatOnWed ?? 0,
            isRepeatOnThu: formData.IsRepeatOnThu ?? 0,
            isRepeatOnFri: formData.IsRepeatOnFri ?? 0,
            isRepeatOnSat: formData.IsRepeatOnSat ?? 0,
            projectTierId: 0,
        }

        if (endsValue === 2) {
            dataToSave.recurringEndsOn =
                formData.RecurringEndsOn !== undefined
                    ? formData.RecurringEndsOn.format('D/M/YYYY')
                    : 'default-value-if-undefined'
        } else {
            dataToSave.recurringEndsOn = ''
        }

        if (induvidualMobileData !== null) {
            void ConvertTask(dispatch, dataToSave, induvidualMobileData)
        }
        form.resetFields()
        setEndsValue(0)
        setShowInput(false)
        setShowCustomOptions(false)
        setShowToggles(false)
        setSwitchStates(initialSwitchStates)
        onClose()
    }

    const handleSwitchChange = (day: DaysOfWeek, checked: boolean): void => {
        setSwitchStates({ ...switchStates, [day]: checked ? 1 : 0 })
    }

    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={styles.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <br />
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const onChangeSearch = (value: string): void => {
        setSearchValue(value)
        setSelectValue(value)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 26 }}
                wrapperCol={{ span: 26 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Task Name"
                            name="TaskName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Task Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Client Name" name="ClientName">
                            <Select
                                loading={isLoading}
                                showSearch
                                placeholder="To filter the clients by number /name"
                                onChange={onChangeSearch}
                                onSearch={(value) => {
                                    setSearchValue(value)
                                }}
                                filterOption={false}
                                allowClear
                                onClear={() => {
                                    setData([])
                                }}
                                optionLabelProp="label"
                                options={data}
                                optionRender={(option) => (
                                    <>{option.data.desc}</>
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Services" name="ServiceId">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={null}
                                options={[
                                    { label: 'Please select', value: null },
                                    ...serviceDropDownList.map((service) => ({
                                        value: service.value,
                                        label: service.label,
                                    })),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Due Date"
                            name="ToDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Due Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                className={commonStyles.dateWidth}
                                format="D/M/YYYY"
                                showTime={false}
                                disabledDate={(current) =>
                                    current !== null &&
                                    current < moment().startOf('day')
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Priority"
                            name="PriorityId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Priority',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={null}
                                options={[
                                    { label: 'Please select', value: null },
                                    ...priorityDropDownList.map((priority) => ({
                                        value: priority.value,
                                        label: priority.label,
                                    })),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="AssignedTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Assigned',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={null}
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Task Type" name="TaskType">
                            <Select
                                onChange={handleSelectChange}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={taskTypeOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Description" name="Description">
                            <Input.TextArea rows={1} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {showInput && (
                        <Col span={12}>
                            <Form.Item
                                label="Recurring Plan"
                                name="RecurringFrequencyPeriodId"
                            >
                                <Select
                                    onChange={handleRecurringPlanChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    }
                                    options={reqrutingFrequencyOptions}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {showCustomOptions && (
                        <>
                            {' '}
                            <Col span={6}>
                                <Form.Item
                                    label="Repeat every"
                                    name="RecurringFrequency"
                                >
                                    <InputNumber
                                        min={0}
                                        max={10}
                                        step={1}
                                        defaultValue={0}
                                        className={commonStyles.repeatWidth}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="RecurringFrequencyType"
                                    label=" "
                                >
                                    <Select
                                        onChange={handleDropdownChange}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                        options={RecurringFrequencyTypeOption}
                                    />
                                </Form.Item>
                            </Col>
                            {showToggles && (
                                <Row gutter={24}>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Mon"
                                            name="IsRepeatOnMon"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnMon ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnMon',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Tue"
                                            name="IsRepeatOnTue"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnTue ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnTue',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Wed"
                                            name="IsRepeatOnWed"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnWed ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnWed',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Thu"
                                            name="IsRepeatOnThu"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnThu ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnThu',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Fri"
                                            name="IsRepeatOnFri"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnFri ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnFri',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Sat"
                                            name="IsRepeatOnSat"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnSat ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnSat',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Sun"
                                            name="IsRepeatOnSun"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnSun ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnSun',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={24}>
                                <Col span={3}>
                                    <Form.Item
                                        label="Ends"
                                        name="RecurringEnds"
                                    >
                                        <Radio
                                            value={1}
                                            checked={endsValue === 1}
                                            onChange={() => {
                                                setEndsValue(1)
                                            }}
                                        >
                                            never
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label=" " name="RecurringEnds">
                                        <Radio
                                            value={2}
                                            checked={endsValue === 2}
                                            onChange={() => {
                                                setEndsValue(2)
                                            }}
                                        >
                                            on
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label=" " name="RecurringEndsOn">
                                        <DatePicker
                                            disabled={endsValue !== 2}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label=" " name="RecurringEnds">
                                        <Radio
                                            value={3}
                                            checked={endsValue === 3}
                                            onChange={() => {
                                                setEndsValue(3)
                                            }}
                                        >
                                            After
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Row align="middle">
                                        <Col span={13}>
                                            <Form.Item
                                                name="RecurringOccurrence"
                                                label=" "
                                            >
                                                <InputNumber
                                                    min={0}
                                                    max={10}
                                                    step={1}
                                                    defaultValue={0}
                                                    className={
                                                        commonStyles.recureWidth
                                                    }
                                                    disabled={endsValue !== 3}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <span>Occurrences</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Tooltip title="Save Task">
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): MobileCreateTaskStateInterface => {
    return {
        userList: state.user.userList,
        serviceDropDownList: state.initial.serviceDropDownList,
        priorityDropDownList: state.client.priorityDropDownList,
    }
}

export default connect(mapStateToProps)(MobileRequestCreateTask)
