import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import CommonButton from '../../../Components/Commonbutton'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import FeedList from './feeds'
import MessageList from './meesage'
const NotificationList = (): React.ReactElement => {
    const dispatch = useDispatch()

    const [activeButton, setActiveButton] = useState(1)
    const [showMassegeList, setShowMeesageList] = useState(true)
    const [showFeedList, setShowFeed] = useState(true)
    const [selectedId, setSelectedId] = useState(0)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 3 : Number(value))
    }

    const handleSelectChange1 = (value: string | number): void => {
        setSelectedServiceId(value === 'all' ? 0 : Number(value))
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setShowMeesageList(true)
            setShowFeed(false)
        } else if (buttonName === 2) {
            setShowFeed(true)
            setShowMeesageList(false)
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return <div></div>
        } else if (activeButton === 2) {
            return <div></div>
        }

        return <></>
    }

    return (
        <>
            <Card title="Notification List" className={commonStyles.card}>
                <Select
                    className={commonStyles.topdropdown2}
                    placeholder="Please select"
                    onChange={handleSelectChange}
                    value={selectedId === 0 ? 'All' : selectedId}
                >
                    <Select.Option value={0}>All</Select.Option>
                    <Select.Option value={1}>Read</Select.Option>
                    <Select.Option value={2}>Unread</Select.Option>
                </Select>
                <Select
                    className={commonStyles.topdropdown}
                    placeholder="Please select"
                    onChange={handleSelectChange1}
                    value={selectedServiceId === 0 ? 'All' : selectedServiceId}
                >
                    <Select.Option value={0}>All</Select.Option>
                    <Select.Option value={1}>Service comm</Select.Option>
                    <Select.Option value={2}>Staff comm</Select.Option>
                </Select>
                <CommonButton
                    name="Message"
                    isActive={activeButton === 1}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(1)
                    }}
                />
                <CommonButton
                    name="Feed"
                    isActive={activeButton === 2}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(2)
                    }}
                />
                {showMassegeList && activeButton === 1 && (
                    <MessageList
                        buttonId={activeButton}
                        flegId={selectedId}
                        serviceId={selectedServiceId}
                    />
                )}
                {showFeedList && activeButton === 2 && (
                    <FeedList
                        buttonId={activeButton}
                        flegId={selectedId}
                        serviceId={selectedServiceId}
                    />
                )}
            </Card>
        </>
    )
}
export default NotificationList
