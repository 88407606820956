import React, { useState, type ReactElement } from 'react'
import { Form, Input, Select } from 'antd'
import { connect } from 'react-redux'
import {
    type HomeAddress,
    type HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Individual'

const HomeAddressForm = (props: HomeAddress): ReactElement => {
    const { countryList, onDateChange } = props
    const [selectedCountry, setSelectedCountry] = useState<string>('')

    const handleCountryChange = (value: string): void => {
        setSelectedCountry(value)
        onDateChange(value)
    }

    return (
        <>
            <Form.Item
                label="Address 1"
                name="addressOne"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Address one',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Address 2" name="addressTwo">
                <Input />
            </Form.Item>
            <Form.Item
                label="Town"
                name="town"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Town',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Country" name="country">
                <Select
                    allowClear
                    showSearch
                    onChange={handleCountryChange}
                    options={countryList?.map((country: { label: any }) => ({
                        value: country.label,
                        label: country.label,
                    }))}
                    value={selectedCountry}
                />
            </Form.Item>
            <Form.Item
                label="Post Code"
                name="postCode"
                rules={[
                    {
                        required: true,
                        message: 'Please input your PostCode!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        individualTaps: state.client.individualTaps,
    }
}

export default connect(mapStateToProps)(HomeAddressForm)
