import axios from '../Config/AxiosConfig'
import type { Dispatch } from '@reduxjs/toolkit'
import { ErrorMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import { overviewClientList } from '../Reducer/ClientOverviewReducer'
import { type OverviewDetail } from '../Types/Overview'

export const GetClientOverview = (
    dispatch: Dispatch,
    sequenceNo: number,
    clientCategoryId: number,
    pageNo: number,
    pageSize: number,
    search: string
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(
            `api/Overview/GetClientOverviewDetail/${sequenceNo}/${clientCategoryId}/${pageNo}/${pageSize}/${search}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: OverviewDetail) => {
                        return {
                            ...value,
                            key: value.clientNumber,
                        }
                    }
                )
                response.data.data = newData
                dispatch(overviewClientList({ ...response.data }))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(
                    overviewClientList({
                        data: [],
                        noOfRecords: 0,
                    })
                )
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch(() => {
            dispatch(
                overviewClientList({
                    data: [],
                    noOfRecords: 0,
                })
            )
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
