import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import {
    type ProfileHolidayData,
    type changePassword,
    type changePin,
    type updateProfile,
} from '../Types/Profile'
import { profiledataList, userHolidayList } from '../Reducer/userProfileReducer'
import { type TableListInterface } from '../Types/CommonType'

export const GetHolidayList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/General/GetHolidayListByUserId/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ProfileHolidayData) => {
                        return {
                            ...value,
                            key: value.companyHolidayId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(userHolidayList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveChangePassword = async (
    dispatch: Dispatch,
    data: changePassword
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Authenticate/ChangePassword`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const SaveChangePin = async (
    dispatch: Dispatch,
    data: changePin
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Authenticate/ChangePIN`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.message)
                return true
            } else {
                ErrorMessage(response.data.message)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error.response.data.message)
            console.error('Error fetching posts:', error)
            return false
        })
}

export const SaveProfile = async (
    dispatch: Dispatch,
    data: updateProfile
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Authenticate/ProfileUpdate`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage('Profile Update Successfully')
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))

            console.error('Error fetching posts:', error)
            return false
        })
}

export const GetProfileDetails = async (
    dispatch: Dispatch,
    UserId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Authenticate/GetUserProfileView/${UserId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data.data

                getAddress(addressData)
                dispatch(profiledataList({ ...response.data }))
                // SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
