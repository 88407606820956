import {
    Col,
    Row,
    Input,
    Form,
    Collapse,
    Select,
    Switch,
    DatePicker,
    Space,
    type DatePickerProps,
    Flex,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import soleTraderNewStyles from '../../../Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { GetCountryList, GetNationalityList } from '../../../Services/Common'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    type ListNationalityFrontEndInterface,
    type ListFrontEndInterface,
} from '../../../Types/CommonType'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { GetContactInfoPersonDropDown } from '../../../Services/Corporate'
import {
    GetAddressData,
    GetCompanyOverViewData,
    getMemberDetails,
    SetPSCsOfficer,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { type CS_PSC } from '../../../Types/CompanyHouse/CompanyOverView'
import moment from 'moment'

export interface PSCNewStateInterface {
    countryList: ListFrontEndInterface[]
    contactInfoPersonDropDownList: ListFrontEndInterface[]
    nationalityList: ListNationalityFrontEndInterface[]
}
const AddNewPSC = (
    props: PSCNewStateInterface & {
        companyName: string
        companyNumber: string
        onCloseModel: () => void
        corporateId: number
        editData: CS_PSC
        businessId: number
    }
): React.ReactElement => {
    const {
        countryList,
        companyName,
        companyNumber,
        onCloseModel,
        contactInfoPersonDropDownList,
        nationalityList,
        corporateId,
        editData,
        businessId,
    } = props
    const [form] = Form.useForm()
    const [collapseOne, SetCollapseOne] = useState<string[] | string>(['1'])
    const [collapseTwo, SetCollapseTwo] = useState<string[] | string>([])
    const [relevetLegalOne, SetRelevetLegalOne] = useState<string[] | string>([
        '1',
    ])
    const [relevetLegalTwo, SetrelevetLegalTwo] = useState<string[] | string>(
        []
    )
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const [selectedNotificationDate, setNotificationDate] = useState<string>('')
    const [selectedRegistEntryDate, setRegisterEntryDate] = useState<string>('')
    const [selectedDateRegiter, setSelectedDateRegiter] = useState<string>('')
    const dispatch = useDispatch()
    const [isAct1SubmitToCH, setIsAct1SubmitToCH] = useState<number>(0)
    const [isAct2SubmitToCH, setIsAct2SubmitToCH] = useState<number>(0)
    const [isAct3SubmitToCH, setIsAct3SubmitToCH] = useState<number>(0)
    const [isAct4SubmitToCH, setIsAct4SubmitToCH] = useState<number>(0)
    const [isAct5SubmitToCH, setIsAct5SubmitToCH] = useState<number>(0)
    const [isAct6SubmitToCH, setIsAct6SubmitToCH] = useState<number>(0)
    const [memberPersonId, setMemberPersonId] = useState(0)
    const [pscType, setPscType] = useState(0)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCountryList(dispatch)
        GetNationalityList(dispatch)
    }, [dispatch, Form])

    useEffect(() => {
        setPscType(editData.pscType)
        form.setFieldsValue(editData)
        setSelectedDateAp(editData.dateofBirth)
        setNotificationDate(editData.notificationDate)
        setRegisterEntryDate(editData.registerEntryDate)
        setSelectedDateRegiter(editData.registerEntryDate)
        setIsAct1SubmitToCH(editData.isRighttoAppointorRemovebyPerson)
        setIsAct2SubmitToCH(editData.isRighttoAppointorRemovebyFirm)
        setIsAct3SubmitToCH(editData.isRighttoAppointorRemovebyTrust)
        setIsAct4SubmitToCH(editData.hasSignificantInfluencebyPerson)
        setIsAct5SubmitToCH(editData.hasSignificantInfluencebyFirm)
        setIsAct6SubmitToCH(editData.hasSignificantInfluencebyTrust)
    }, [editData])
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const collapseOneOnChange = (data: string | string[]): void => {
        SetCollapseOne(data)
    }

    const collapseTwoOnChange = (data: string | string[]): void => {
        SetCollapseTwo(data)
    }

    const RelevetLegalOneChange = (data: string | string[]): void => {
        SetRelevetLegalOne(data)
    }

    const RelevetLegalChange = (data: string | string[]): void => {
        SetrelevetLegalTwo(data)
    }

    useEffect(() => {
        form.setFieldValue('dateofBirth', selectedDateAp)
        form.setFieldValue('notificationDate', selectedNotificationDate)
        form.setFieldValue('registerEntryDate', selectedDateRegiter)
    }, [selectedDateAp, selectedNotificationDate, selectedDateRegiter])

    useEffect(() => {
        GetContactInfoPersonDropDown(dispatch, corporateId, 1)
    }, [corporateId])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const onChangeDateRegiterDate = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateRegiter(isoDate)
        }
    }

    const onChangeNotificationDate = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setNotificationDate(isoDate)
        }
    }

    const onChangeRegistEntryDate = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setRegisterEntryDate(isoDate)
        }
    }
    const memberPersonOnchange = (value: number): void => {
        setMemberPersonId(value)
    }
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            if (memberPersonId !== 0) {
                const data = await getMemberDetails(dispatch, 1, memberPersonId)
                form.setFieldsValue({
                    title: data?.title ?? '',
                    firstName: data?.firstName ?? '',
                    middleName: data?.middleName ?? '',
                    surName: data?.surName ?? '',
                })
            }
        }
        void fetchData()
    }, [memberPersonId])
    const initialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Member Person" name="memberId">
                    <Select
                        placeholder="Please select"
                        options={contactInfoPersonDropDownList}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                        allowClear
                        showSearch
                        onChange={memberPersonOnchange}
                    />
                </Form.Item>
                <Form.Item label="Title" name="title">
                    <Select
                        allowClear
                        placeholder="Please select title"
                        onChange={() => {}}
                        defaultValue={'UNKNOWN'}
                        filterOption={(input, option) =>
                            typeof option?.children === 'string' && // Ensure option.children is a string
                            (option.children as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        showSearch
                    >
                        <Select.Option value="UNKNOWN">Unknown</Select.Option>
                        <Select.Option value="MR.">MR.</Select.Option>
                        <Select.Option value="MISS.">MISS.</Select.Option>
                        <Select.Option value="MRS.">MRS.</Select.Option>
                        <Select.Option value="MS.">MS.</Select.Option>
                        <Select.Option value="DR.">DR.</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter First Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Middle Name" name="middleName">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surename"
                    name="surName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Surename',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const personalDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Date Of Birth" name="dateofBirth">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateAppoint(date, dateString)
                            }}
                            value={
                                selectedDateAp.length > 0
                                    ? dayjs(selectedDateAp)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Nationality',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={nationalityList}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>

                <Form.Item
                    label="Country of Residence"
                    name="countryofResidence"
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Notification Date"
                    name="notificationDate"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter NotificationDate',
                        },
                    ]}
                >
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeNotificationDate(date, dateString)
                            }}
                            value={
                                selectedNotificationDate.length > 0
                                    ? dayjs(selectedNotificationDate)
                                    : null
                            }
                            format="DD/MM/YYYY"
                        />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Register Entry Date"
                    name="registerEntryDate"
                    rules={[
                        {
                            required: true,
                            message: 'Please Register Entry Date',
                        },
                    ]}
                >
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateRegiterDate(date, dateString)
                            }}
                            value={
                                selectedDateRegiter.length > 0
                                    ? dayjs(selectedDateRegiter)
                                    : null
                            }
                            format="DD/MM/YYYY"
                        />
                    </Space>
                </Form.Item>
            </div>
        )
    }

    const [busstineAddressSwitch, setBisinessAddressSwitch] = useState(false)
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                if (busstineAddressSwitch) {
                    const data = await GetAddressData(
                        dispatch,
                        businessId,
                        memberPersonId
                    )

                    form.setFieldValue('corrAddress1', data?.addressLineOne)
                    form.setFieldValue('corrAddress2', data?.addressLineTwo)
                    form.setFieldValue('corrTown', data?.town)
                    form.setFieldValue('corrCountry', data?.country)
                    form.setFieldValue('corrPostcode', data?.postCode)
                }
            } catch (error) {}
        }
        void fetchData()
    }, [busstineAddressSwitch, memberPersonId])
    const onchangeRegisterAddres = (checked: boolean): void => {
        setBisinessAddressSwitch(checked)
    }
    const correspondAddress = (): React.ReactElement => {
        return (
            <div>
                {pscType === 1 ? (
                    <div className={soleTraderNewStyles.cpAddressMain}>
                        <div className={commonStyles.margintRight100}>
                            <Switch
                                size="small"
                                onChange={onchangeRegisterAddres}
                            />
                            &nbsp;
                            <label className={commonStyles.colorGray}>
                                Same as Register Address
                            </label>
                        </div>
                    </div>
                ) : (
                    <Form.Item
                        label="Building Name/Number"
                        name="buildingNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Building Name/Number',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                )}
                <Form.Item
                    label="Address 1"
                    name="corrAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="corrAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="corrTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="corrCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="corrPostcode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const homeAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Address 1"
                    name="resAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="resAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="resTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="resCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="resPostcode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const initialDetailsPscs = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label={`${
                        pscType === 2
                            ? `Company /LLP Name`
                            : `Legal Person Name`
                    }`}
                    name="llpName"
                    rules={[
                        {
                            required: true,
                            message: `${
                                pscType === 2
                                    ? `Company /LLP Name`
                                    : `Legal Person Name`
                            }`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={`${
                        pscType === 2
                            ? `Legal form of the entity`
                            : `Legal form of the legal person`
                    }`}
                    name="legalFormoftheEntity"
                    rules={[
                        {
                            required: true,
                            message: `${
                                pscType === 2
                                    ? `Legal form of the entity`
                                    : `Legal form of the legal person`
                            }`,
                        },
                    ]}
                >
                    <Input
                        placeholder={`${
                            pscType === 2
                                ? `Limited Company`
                                : `Goverment Department`
                        }`}
                    />
                </Form.Item>
                <Form.Item
                    label={`${
                        pscType === 2
                            ? `Law which governs this entity`
                            : `Law which governs this legal person`
                    }`}
                    name="lawwhichgovernsthisEntity"
                    rules={[
                        {
                            required: true,
                            message: `${
                                pscType === 2
                                    ? `Law which governs this entity`
                                    : `Law which governs this legal person`
                            }`,
                        },
                    ]}
                >
                    <Input
                        placeholder={`${
                            pscType === 2
                                ? `Companies Act 2006`
                                : `United state Law`
                        }`}
                    />
                </Form.Item>
                <Form.Item
                    label="Notification Date"
                    name="notificationDate"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Notification Date',
                        },
                    ]}
                >
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeNotificationDate(date, dateString)
                            }}
                            value={
                                selectedNotificationDate.length > 0
                                    ? dayjs(selectedNotificationDate)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Register Entry Date"
                    name="registerEntryDate"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Register Entry Date',
                        },
                    ]}
                >
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeRegistEntryDate(date, dateString)
                            }}
                            value={
                                selectedRegistEntryDate.length > 0
                                    ? dayjs(selectedRegistEntryDate)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
            </div>
        )
    }

    const shares = [
        {
            value: 0,
            label: 'Please Select',
        },
        {
            value: 1,
            label: 'more than 25% but not more than 50% of shares',
        },
        {
            value: 2,
            label: 'more than 50% but less than 75% of shares',
        },
        {
            value: 3,
            label: '75% or more of shares',
        },
    ]
    const votingrights = [
        {
            value: 0,
            label: 'Please Select',
        },
        {
            value: 1,
            label: 'more than 25% but not more than 50% of voting rights',
        },
        {
            value: 2,
            label: 'more than 50% but less than 75% of voting rights',
        },
        {
            value: 3,
            label: '75% or more of voting rights',
        },
    ]
    const handleAct1SwitchChange = (checked: boolean): void => {
        setIsAct1SubmitToCH(checked ? 1 : 0)
    }
    const handleAct2SwitchChange = (checked: boolean): void => {
        setIsAct2SubmitToCH(checked ? 1 : 0)
    }
    const handleAct3SwitchChange = (checked: boolean): void => {
        setIsAct3SubmitToCH(checked ? 1 : 0)
    }
    const handleAct4SwitchChange = (checked: boolean): void => {
        setIsAct4SubmitToCH(checked ? 1 : 0)
    }
    const handleAct5SwitchChange = (checked: boolean): void => {
        setIsAct5SubmitToCH(checked ? 1 : 0)
    }
    const handleAct6SwitchChange = (checked: boolean): void => {
        setIsAct6SubmitToCH(checked ? 1 : 0)
    }

    const natureOfControl = (): React.ReactElement => {
        return (
            <div>
                <h4>Ownership of shares</h4>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={`The ${
                                pscType === 1
                                    ? `Person`
                                    : pscType === 2
                                      ? 'entity'
                                      : `legal person`
                            } Holds Shares`}
                            name="personHoldsShares"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                options={shares}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="The members of a firm hold shares"
                            name="firmHoldShares"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                options={shares}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="The trustees of a trust hold shares"
                            name="trustHoldShares"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                options={shares}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <h4>Ownership of voting rights</h4>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={`The ${
                                pscType === 1
                                    ? `Person`
                                    : pscType === 2
                                      ? 'entity'
                                      : `legal person`
                            } holds voting rights`}
                            name="personHoldsVotingRights"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                options={votingrights}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="The members of a firm hold voting rights"
                            name="firmHoldVotingRights"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                options={votingrights}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="The trustees of a trust hold voting rights"
                            name="trustHoldVotingRights"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                options={votingrights}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <h4>
                    Right to appoint or remove the majority of the board of
                    directors
                </h4>
                <Row gutter={[16, 40]} style={{ marginBottom: 20 }}>
                    <Col span={3}>
                        {/* isRighttoAppointorRemovebyPerson */}
                        <Switch
                            checked={isAct1SubmitToCH === 1}
                            onChange={handleAct1SwitchChange}
                        />
                    </Col>
                    <Col span={20}>
                        {' '}
                        The{' '}
                        {pscType === 1
                            ? `Person`
                            : pscType === 2
                              ? 'entity'
                              : `legal person`}{' '}
                        has the right to appoint or remove the majority of the
                        board of directors of the company
                    </Col>
                </Row>
                <Row gutter={[16, 40]} style={{ marginBottom: 20 }}>
                    <Col span={3}>
                        {/* isRighttoAppointorRemovebyFirm */}
                        <Switch
                            checked={isAct2SubmitToCH === 1}
                            onChange={handleAct2SwitchChange}
                        />
                    </Col>
                    <Col span={20}>
                        The{' '}
                        {pscType === 1
                            ? `Person`
                            : pscType === 2
                              ? 'entity'
                              : `legal person`}{' '}
                        has control over the firm and members of that firm (in
                        their capacity as such) hold the right to appoint or
                        remove directors of the company
                    </Col>
                </Row>
                <Row gutter={[16, 40]} style={{ marginBottom: 20 }}>
                    <Col span={3}>
                        {/* isRighttoAppointorRemovebyTrust */}
                        <Switch
                            checked={isAct3SubmitToCH === 1}
                            onChange={handleAct3SwitchChange}
                        />
                    </Col>
                    <Col span={20}>
                        The{' '}
                        {pscType === 1
                            ? `Person`
                            : pscType === 2
                              ? 'entity'
                              : `legal person`}{' '}
                        has control over the trust and the trustees of that
                        trust (in their capacity as such) hold the right to
                        appoint or remove directors of the company
                    </Col>
                </Row>

                <h4>Has significant influence or control</h4>
                <Row gutter={[16, 40]} style={{ marginBottom: 20 }}>
                    <Col span={3}>
                        {/* hasSignificantInfluencebyPerson */}
                        <Switch
                            checked={isAct4SubmitToCH === 1}
                            onChange={handleAct4SwitchChange}
                        />
                    </Col>
                    <Col span={20}>
                        {' '}
                        The{' '}
                        {pscType === 1
                            ? `Person`
                            : pscType === 2
                              ? 'entity'
                              : `legal person`}{' '}
                        has significant influence or control over the company
                    </Col>
                </Row>
                <Row gutter={[16, 40]} style={{ marginBottom: 20 }}>
                    <Col span={3}>
                        {/* hasSignificantInfluencebyFirm */}
                        <Switch
                            checked={isAct5SubmitToCH === 1}
                            onChange={handleAct5SwitchChange}
                        />
                    </Col>
                    <Col span={20}>
                        The{' '}
                        {pscType === 1
                            ? `Person`
                            : pscType === 2
                              ? 'entity'
                              : `legal person`}{' '}
                        has control over the firm and members of that firm (in
                        their capacity as such) have significant influence or
                        control over the company
                    </Col>
                </Row>
                <Row gutter={[16, 40]} style={{ marginBottom: 20 }}>
                    <Col span={3}>
                        {/* hasSignificantInfluencebyTrust */}
                        <Switch
                            checked={isAct6SubmitToCH === 1}
                            onChange={handleAct6SwitchChange}
                        />
                    </Col>
                    <Col span={20}>
                        The{' '}
                        {pscType === 1
                            ? `Person`
                            : pscType === 2
                              ? 'entity'
                              : `legal person`}{' '}
                        has control over the trust and the trustees of that
                        trust (in their capacity as such) have significant
                        influence or control over the company
                    </Col>
                </Row>
            </div>
        )
    }
    const onchangePscs = (value: number): void => {
        setPscType(value)
    }
    const handelSaveData = (): void => {
        void handelSetData()
    }
    const handelSetData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const fotmData = form.getFieldsValue()
            const dataSave = {
                pscId: editData.pscId ?? 0,
                pscType: editData.pscType ?? pscType,
                companyId: editData.companyId ?? 0,
                corporateId,
                title: fotmData.title ?? '',
                firstName: fotmData.firstName ?? '',
                middleName: fotmData.fotmData ?? '',
                surName: fotmData.fotmData ?? '',
                dateofBirth:
                    moment(fotmData.fotmData).format('DD/MM/YYYY') ?? '',
                nationality: fotmData.nationality ?? '',
                countryofResidence: fotmData.countryofResidence ?? '',
                isSameasOffice: 0,
                corrAddress1: fotmData.corrAddress1 ?? '',
                corrAddress2: fotmData.corrAddress2 ?? '',
                corrTown: fotmData.corrTown ?? '',
                corrCountry: fotmData.corrCountry ?? '',
                corrPostcode: fotmData.corrPostcode ?? '',
                isSameasCorrespondance: 0,
                resAddress1: fotmData.resAddress1 ?? '',
                resAddress2: fotmData.resAddress2 ?? '',
                resTown: fotmData.resTown ?? '',
                resCountry: fotmData.resTown ?? '',
                resPostcode: fotmData.resPostcode ?? '',
                disclosureExemptions: 0,
                personHoldsShares: fotmData.resPostcode ?? 0,
                firmHoldShares: fotmData.firmHoldShares ?? 0,
                trustHoldShares: fotmData.trustHoldShares ?? 0,
                personHoldsVotingRights: fotmData.personHoldsVotingRights ?? 0,
                firmHoldVotingRights: fotmData.firmHoldVotingRights ?? 0,
                trustHoldVotingRights: fotmData.trustHoldVotingRights ?? 0,
                isRighttoAppointorRemovebyPerson: isAct1SubmitToCH,
                isRighttoAppointorRemovebyFirm: isAct2SubmitToCH,
                isRighttoAppointorRemovebyTrust: isAct3SubmitToCH,
                hasSignificantInfluencebyPerson: isAct4SubmitToCH,
                hasSignificantInfluencebyFirm: isAct5SubmitToCH,
                hasSignificantInfluencebyTrust: isAct6SubmitToCH,
                notificationDate: moment(fotmData.notificationDate).format(
                    'DD/MM/YYYY'
                ),
                registerEntryDate: moment(fotmData.registerEntryDate).format(
                    'DD/MM/YYYY'
                ),
                isSubmitToCH: 0,
                llpName: fotmData.llpName ?? '',
                buildingNumber: fotmData.buildingNumber ?? '',
                legalFormoftheEntity: fotmData.legalFormoftheEntity ?? '',
                lawwhichgovernsthisEntity: fotmData.legalFormoftheEntity ?? '',
                ...fotmData,
            }
            void SetPSCsOfficer(dispatch, dataSave)
            setTimeout(() => {
                GetCompanyOverViewData(dispatch, businessId, corporateId, 0, 6)
                onCloseModel()
            }, 1000)
        } catch (error) {}
    }
    return (
        <>
            <div>
                <h5>
                    Company Name & Reg Number {companyName} - {companyNumber}
                </h5>
            </div>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
                onFinish={handelSaveData}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Type of PSC">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    onchangePscs(value)
                                }}
                                value={pscType}
                            >
                                <Select.Option key={0} value={0}>
                                    Please select
                                </Select.Option>
                                <Select.Option key={1} value={1}>
                                    Induvidual
                                </Select.Option>
                                <Select.Option key={2} value={2}>
                                    Relevent legal entity(Company/LLP)
                                </Select.Option>
                                <Select.Option key={3} value={3}>
                                    Goverment body,International Organitation
                                    Corporation sole
                                </Select.Option>
                                <Select.Option key={4} value={4}>
                                    Unknown Person
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {pscType !== 4 && (
                    <>
                        <div className={commonStyles.flexDirectionRow}>
                            {pscType === 1 && (
                                <>
                                    <div
                                        className={
                                            soleTraderNewStyles.collapseFirst
                                        }
                                    >
                                        <Collapse
                                            activeKey={collapseOne}
                                            onChange={collapseOneOnChange}
                                            bordered={true}
                                            items={[
                                                {
                                                    key: '1',
                                                    label: 'Initial Details',
                                                    children: initialDetails(),
                                                },
                                                {
                                                    key: '2',
                                                    label: `Personal Details & PSC's Date`,
                                                    children: personalDetails(),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={
                                            soleTraderNewStyles.collapseSecond
                                        }
                                        style={{ marginLeft: 20 }}
                                    >
                                        <Collapse
                                            activeKey={collapseTwo}
                                            onChange={collapseTwoOnChange}
                                            bordered={true}
                                            items={[
                                                {
                                                    key: '1',
                                                    label: 'Correspondance Address',
                                                    children:
                                                        correspondAddress(),
                                                },
                                                {
                                                    key: '2',
                                                    label: 'Home Address',
                                                    children: homeAddress(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </>
                            )}
                            {(pscType === 2 || pscType === 3) && (
                                <>
                                    <div
                                        className={
                                            soleTraderNewStyles.collapseSecond
                                        }
                                    >
                                        <Collapse
                                            activeKey={relevetLegalOne}
                                            onChange={RelevetLegalOneChange}
                                            bordered={true}
                                            items={[
                                                {
                                                    key: '1',
                                                    label: `Intial Details, Entity Details & PSC's Date`,
                                                    children:
                                                        initialDetailsPscs(),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div
                                        className={
                                            soleTraderNewStyles.collapseSecond
                                        }
                                        style={{ marginLeft: 20 }}
                                    >
                                        <Collapse
                                            activeKey={relevetLegalTwo}
                                            onChange={RelevetLegalChange}
                                            bordered={true}
                                            items={[
                                                {
                                                    key: '1',
                                                    label: `PSC's Service  Address`,
                                                    children:
                                                        correspondAddress(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        &nbsp;
                        {pscType !== 0 && (
                            <Row>
                                <Col span={24}>
                                    <Collapse
                                        items={[
                                            {
                                                key: '1',
                                                label: `${
                                                    pscType === 1
                                                        ? ` Nature of control`
                                                        : `RLE/Person's significant control criteria`
                                                }`,
                                                children: natureOfControl(),
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                &nbsp;
                <div style={{ marginTop: '20px' }}>
                    <Flex justify="flex-end" gap={5}>
                        <CusttomBackgroundButton
                            color={'#868e96'}
                            name={'Cancel'}
                            icon={<CloseSquareOutlined />}
                            clickAction={onCloseModel}
                        />
                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'Submit Changes'}
                            icon={<CheckOutlined />}
                            htmlType="submit"
                        />
                    </Flex>
                </div>
            </Form>
        </>
    )
}

const mapStateToProps = (state: any): PSCNewStateInterface => {
    return {
        countryList: state.common.countryList,
        contactInfoPersonDropDownList:
            state.client.contactInfoPersonDropDownList,
        nationalityList: state.common.nationalityList,
    }
}

export default connect(mapStateToProps)(AddNewPSC)
