import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { type comStatusPainInvoivc } from '../../../../Types/Invoice/Invoicing'
import { SavePaidInvoice } from '../../../../Services/Invoice'

const ComPaidInvoice = (props: comStatusPainInvoivc): React.ReactElement => {
    const { changePaidModalStatus, invoiceIds } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')

    useEffect(() => {
        form.setFieldValue('historyDate', selectedDate)
    }, [selectedDate])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                invoiceId: invoiceIds,
                paidRemarks: formData.description,
                paidDate: moment(formData.historyDate).format('DD/MM/YYYY'),
            }

            await SavePaidInvoice(dispatch, dataToSave)

            form.setFieldsValue({
                description: '',
                historyDate: '',
            })

            changePaidModalStatus()
            setSelectedDate('')
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item
                    name="prospectClientId"
                    hidden={true}
                    initialValue={invoiceIds}
                >
                    <Input />
                </Form.Item>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Paid Date"
                            name="historyDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input paid Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate?.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY "
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Remarks" name="description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" onClick={onSave} size="middle">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ComPaidInvoice
