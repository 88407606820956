import React, { useEffect, useState } from 'react'
import {
    MenuOutlined,
    UserOutlined,
    InfoCircleTwoTone,
} from '@ant-design/icons'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Popover, Space, Table, Tooltip } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    type CDClinetTaskDragTableProps,
    type TaskTableIntrface,
} from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import commonStyles from '../../../Utils/Common.less'
import calculatePagination from '../../../Utils/Pagination'
import style from './taps.less'
const columns: ColumnsType<TaskTableIntrface> = [
    {
        key: 'sort',
    },
    {
        title: 'TaskName',
        key: 'taskId',
        render: (_, record) => {
            return (
                <Space size="middle">
                    <div>
                        {record.taskName}-{record.serviceName}{' '}
                        {record.description.length > 0 && (
                            <Popover
                                placement="top"
                                title={'Remarks'}
                                content={record.description}
                            >
                                <InfoCircleTwoTone />
                            </Popover>
                        )}
                    </div>
                </Space>
            )
        },
    },
    {
        title: 'AssigneDetails',
        key: 'taskId',
        render: (_, record) => {
            return (
                <Space size="middle">
                    <Tooltip title={`Assigned by ${record.assignedByName}`}>
                        <UserOutlined className={commonStyles.clickableIcon} />
                    </Tooltip>

                    <Tooltip title={`Assigned to ${record.assignedToName}`}>
                        <UserOutlined className={commonStyles.clickableIcon} />
                    </Tooltip>
                </Space>
            )
        },
    },
    {
        title: 'Address',
        key: 'taskId',
        render: (_, record) => {
            return (
                <Space size="middle">
                    <Tooltip title="Priority Level">
                        <div
                            style={{
                                backgroundColor: `${record.priorityColourCode}`,
                            }}
                            className={style.tableButton}
                        >
                            {record.priorityName}
                        </div>
                    </Tooltip>

                    <Tooltip title="Status">
                        <div
                            style={{
                                backgroundColor: `${record.statusColourCode}`,
                            }}
                            className={style.tableButton}
                        >
                            {record.status}
                        </div>
                    </Tooltip>
                    <Tooltip title={`${record.daysCount} days overdue`}>
                        <div
                            style={{
                                backgroundColor: `${record.daysColourCode}`,
                            }}
                            className={style.tableButton}
                        >
                            {formatDate(record.toDate)}
                        </div>
                    </Tooltip>
                </Space>
            )
        },
    },
    {
        title: '',
        dataIndex: 'image',
    },
]

const formatDate = (dateString: string): string => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    const date = new Date(dateString)
    const month = months[date.getMonth()]
    const day = date.getDate()
    const formattedDate = `${month} ${day}`

    return formattedDate
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
}

const Row = ({ children, ...props }: RowProps): JSX.Element => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
        ...props.style,
        transform:
            transform !== null
                ? CSS.Transform.toString({ ...transform, scaleY: 1 })
                : undefined,
        transition,
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    })
                }
                return child
            })}
        </tr>
    )
}

const DraggableTable = (
    props: CDClinetTaskDragTableProps
): React.ReactElement => {
    const { CDTaskList, CDTaskListCount, setPageSize, setPageNo } = props
    const mappedData = CDTaskList?.map((item) => ({
        ...item,
        key: item.taskId,
    }))
    const [dataSource, setDataSource] = useState([...mappedData])
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const onDragEnd = ({ active, over }: DragEndEvent): void => {
        if (active.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex(
                    (i) => i.key === active.id
                )
                const overIndex = previous.findIndex((i) => i.key === over?.id)
                return arrayMove(previous, activeIndex, overIndex)
            })
        }
    }

    useEffect(() => {
        setDataSource([...mappedData])
        setPageSize(itemsPerPage)
        setPageNo(currentPage)
    }, [CDTaskList, itemsPerPage, currentPage])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        CDTaskListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const onChangeTableParams: TableProps<TaskTableIntrface>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
    }
    return (
        <>
            <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
            >
                <SortableContext
                    // rowKey array
                    items={dataSource.map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={pagination}
                        onChange={onChangeTableParams}
                        showHeader={false}
                    />
                </SortableContext>
            </DndContext>
        </>
    )
}

export default DraggableTable
