import React, { useState } from 'react'
import { Form, Input, Select } from 'antd'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import {
    type PartneshipBussinessAddress,
    type PartneshipBussinessStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'

const BussinessAddressForm = (
    props: PartneshipBussinessAddress
): React.ReactElement => {
    const { countryList } = props
    dayjs.locale('en')
    const [selectedCountry, setSelectedCountry] = useState<string>('')

    const handleCountryChange = (value: string): void => {
        setSelectedCountry(value)
        props.onDateChange(value)
    }

    return (
        <>
            <Form.Item
                label="Address 1"
                name="busaddress1"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Address One',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Address 2" name="busaddress2">
                <Input />
            </Form.Item>
            <Form.Item
                label="Town"
                name="bustown"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Town',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Country" name="buscountry">
                <Select
                    key={countryList.length}
                    allowClear
                    showSearch
                    onChange={handleCountryChange}
                    options={countryList?.map((country) => ({
                        value: country.label,
                        label: country.label,
                    }))}
                    value={selectedCountry}
                />
            </Form.Item>
            <Form.Item
                label="Post Code"
                name="buspostcode"
                rules={[
                    {
                        required: true,
                        message: 'Please input your PostCode!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): PartneshipBussinessStateProps => {
    return {
        countryList: state.common.countryList,
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(BussinessAddressForm)
