import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Select, Switch, Flex } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { GetCountryList } from '../../../Services/Common'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import FullWidthModal from '../../../Components/FullWidthModal'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import {
    GetCompanyOverViewData,
    GetRegitserAddress,
    SetCompanyDetailAddress,
} from '../../../Services/CompanyHouse/CompanyOverView'
import { type CompanyAddress } from '../../../Types/CompanyHouse/CompanyOverView'
interface AddressProps {
    countryList: ListFrontEndInterface[]
}
const RegisterOfficeAddress = (
    props: AddressProps & {
        corporateId: number
        companyName: string
        companyNumber: string
        Address: string
        oncloseModel: () => void
        businessId: number
    }
): React.ReactElement => {
    const {
        countryList,
        corporateId,
        companyName,
        companyNumber,
        Address,
        oncloseModel,
        businessId,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [addressData, setAddressData] = useState<CompanyAddress | null>(null)
    const [isSubmitToCH, setIsSubmitToCH] = useState<number>(0)
    const [isSubmitToYes, setIsSubmitToCHYes] = useState<boolean>(false)
    useEffect(() => {
        GetCountryList(dispatch)
    }, [])
    useEffect(() => {
        const fetchAuditData = async (): Promise<void> => {
            try {
                const data = await GetRegitserAddress(dispatch, corporateId)
                setAddressData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }
        void fetchAuditData()

        const combineData = `${companyName} - ${companyNumber}`
        form.setFieldValue('companyNameandReg', combineData)
        form.setFieldValue('currentAddress', Address)
    }, [corporateId])

    useEffect(() => {
        form.setFieldsValue(addressData)
    }, [addressData])
    const handleSwitchChange = (checked: boolean): void => {
        setSwithModelStatus(!swithModalStatus)
        setIsSubmitToCHYes(checked)
    }

    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        setIsSubmitToCH(isSubmitToYes ? 1 : 0)
        ChangeModalFunction()
    }
    const handelSaveData = (): void => {
        void handelSetData()
    }
    const handelSetData = async (): Promise<void> => {
        try {
            const fotmData = form.getFieldsValue()

            let countryName: string | undefined
            if (/^\d+$/.test(fotmData.country)) {
                const cName = countryList.find(
                    (dat) => dat.value === fotmData.country
                )
                countryName = cName?.label
            } else {
                countryName = fotmData.country
            }
            const dataSave = {
                companyId: addressData?.companyId ?? 0,
                corporateId,
                houseNumber: fotmData.houseNumber,
                postCode: fotmData.postCode,
                address1: fotmData.address1,
                address2: fotmData.address2,
                town: fotmData.town,
                region: fotmData.region,
                country: countryName ?? '',
                poBoxNumber: fotmData.poBoxNumber,
                isSubmitToCH,
            }
            void SetCompanyDetailAddress(dispatch, dataSave)
            setTimeout(() => {
                GetCompanyOverViewData(dispatch, businessId, corporateId, 0, 1)
                oncloseModel()
            }, 1000)
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Form.Item name="clientCategoryId" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Company & Reg Number"
                                name="companyNameandReg"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Current Address"
                                name="currentAddress"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="House Name or Number"
                                name="houseNumber"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input House Name or Number!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="PostCode"
                                name="postCode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your PostCode!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Address One"
                                name="address1"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input your address one!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Address Two" name="address2">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Town"
                                name="town"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your town!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Country / Region" name="region">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Country" name="country">
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={countryList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="PO Box Number" name="poBoxNumber">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Switch
                                checked={isSubmitToCH === 1}
                                onChange={handleSwitchChange}
                            />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                                clickAction={oncloseModel}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                                clickAction={handelSaveData}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

const mapStateToProps = (state: any): AddressProps => {
    return {
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(RegisterOfficeAddress)
