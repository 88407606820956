import React, { useEffect, useRef } from 'react'
import { Avatar, Card, Flex, Tooltip } from 'antd'
import TodoStyles from './backLogList.less'
import {
    FormOutlined,
    UsergroupDeleteOutlined,
    FieldTimeOutlined,
    UnorderedListOutlined,
    PaperClipOutlined,
    WechatOutlined,
} from '@ant-design/icons'
import {
    type todoSatteInterface,
    type GetSprintTaskListInterfaceType,
    type todoForminterface,
    type User,
} from '../../Types/Backlog/Backlog'
import { connect, useDispatch } from 'react-redux'
import { getusersdroplist } from '../../Services/Helpdesk/Backlog'

export const TaskdefaultData = {
    taskId: '0',
    taskNo: '',
    taskName: '',
    priorityId: 0,
    sortOrder: 0,
    taskType: 0,
    businessType: 0,
    referenceId: 0,
    serviceId: 0,
    serviceReferenceId: 0,
    assignedBy: 0,
    assignedTo: 0,
    fromDate: '',
    toDate: '',
    statusId: 0,
    notifyToStatusChange: 0,
    description: '',
    clientName: '',
    recurringFrequency: 0,
    recurringFrequencyType: 0,
    recurringFrequencyPeriodId: 0,
    recurringEnds: 0,
    recurringOccurrence: 0,
    recurringEndsOn: '',
    isRepeatOnSun: 0,
    isRepeatOnMon: 0,
    isRepeatOnTue: 0,
    isRepeatOnWed: 0,
    isRepeatOnThu: 0,
    isRepeatOnFri: 0,
    isRepeatOnSat: 0,
    projectTierId: 0,
    projectId: 0,
    departmentId: 0,
    sprintId: 0,
}

export const TaskItemDefaultData = {
    taskId: '',
    taskNo: 0,
    taskName: '',
    priorityName: '',
    priorityColourCode: '',
    taskTypeName: '',
    sortOrder: 0,
    businessTypeName: '',
    clientName: '',
    serviceName: '',
    description: '',
    assignedByName: '',
    assignedToName: '',
    fromDate: '',
    toDate: '',
    daysCount: 0,
    daysColourCode: '',
    status: '',
    statusColourCode: '',
    notifyToStatusChange: 0,
    subTaskViews: [],
    taskFollowerViews: [] as [],
    subTaskStatus: 0,
    isNewChatExist: 0,
    projectTierInfo: '',
    projectStatusId: 0,
    projectStatusLbl: '',
    userStatusId: 0,
    userStatusLbl: '',
}

const ToDo = (props: todoForminterface): React.ReactElement => {
    const { sprintTaskList, userimageList, currentStatus } = props
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        void getusersdroplist(dispatch)
    }, [])

    const truncateName = (name: string, maxLength: number): string => {
        if (name.length > maxLength) {
            return name.substring(0, maxLength)
        }
        return name
    }

    return (
        <div>
            {sprintTaskList.length === 0 ? (
                <Card className={TodoStyles.todoCard} title="TO-DO">
                    <div>No records available</div>
                </Card>
            ) : (
                <Card className={TodoStyles.todoCard} title="TO-DO">
                    {sprintTaskList
                        .filter((task) => {
                            if (currentStatus === '2') {
                                return (
                                    task.userStatusId === 2 &&
                                    task.projectStatusLbl === 'QA'
                                )
                            } else {
                                return task.userStatusId !== 1
                            }
                        })
                        .map((task: GetSprintTaskListInterfaceType) => (
                            <Card
                                key={task.taskId}
                                className={TodoStyles.todoItem}
                            >
                                <div className={TodoStyles.tasknameStyles}>
                                    <div>{task.taskName}</div>
                                    <div
                                        className={TodoStyles.taskAndColor}
                                    ></div>
                                </div>
                                <Flex justify="space-between">
                                    <div className={TodoStyles.iconContainer}>
                                        <Tooltip title="Edit Task">
                                            <FormOutlined
                                                className={TodoStyles.todoIcons}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Add Followers">
                                            <UsergroupDeleteOutlined
                                                className={TodoStyles.todoIcons}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Add Hours">
                                            <FieldTimeOutlined
                                                className={TodoStyles.todoIcons}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Add subtask">
                                            <UnorderedListOutlined
                                                className={TodoStyles.todoIcons}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Add Attachment">
                                            <PaperClipOutlined
                                                className={TodoStyles.todoIcons}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Staff Communication">
                                            <WechatOutlined
                                                className={TodoStyles.todoIcons}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {userimageList.map((user: any) => {
                                            if (
                                                user.userName ===
                                                task.assignedByName
                                            ) {
                                                return (
                                                    <div
                                                        key={task.taskId}
                                                        className={
                                                            TodoStyles.todoAssignAvatar
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                TodoStyles.todotaskassignedname
                                                            }
                                                        >
                                                            {truncateName(
                                                                task.assignedByName,
                                                                8
                                                            )}
                                                        </div>
                                                        <Avatar
                                                            key={user.userName}
                                                            size={30}
                                                            src={user.imageUrl}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}

                                        {userimageList.map((user: User) => {
                                            if (
                                                user.userName ===
                                                task.assignedToName
                                            ) {
                                                return (
                                                    <div
                                                        key={task.taskId}
                                                        className={
                                                            TodoStyles.todoAssignAvatar
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                TodoStyles.todotaskassignedname
                                                            }
                                                        >
                                                            {
                                                                task.assignedToName
                                                            }
                                                        </div>
                                                        <Avatar
                                                            key={user.userName}
                                                            size={30}
                                                            src={user.imageUrl}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                                    </div>
                                </Flex>
                            </Card>
                        ))}
                </Card>
            )}
        </div>
    )
}

const mapStateToProps = (state: any): todoSatteInterface => {
    return {
        userimageList: state.backlog.userimageList,
    }
}

export default connect(mapStateToProps)(ToDo)
