import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    DatePicker,
    Switch,
    Radio,
    InputNumber,
    type DatePickerProps,
    Space,
    type SelectProps,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import styles from '../../../Layouts/Less/style.less'
import React, { useEffect, useRef, useState } from 'react'
import { GetUserList } from '../../../Services/User'
import axios from '../../../Config/AxiosConfig'
import { GetPriorityList } from '../../../Services/Corporate'
import {
    type tasksatestate,
    type taskstate,
} from '../../../Types/HelpDesk/Cretetask'
import {
    SaveTask,
    getAllDepartment,
    getProjectlist,
} from '../../../Services/Helpdesk/CreteTask'
import dayjs, { type Dayjs } from 'dayjs'
import { GetTierList } from '../../../Services/ProjectManagement'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import { ErrorMessage } from '../../../Utils/Notification'
import { TaskdefaultData } from './toDoTasks'
import moment from 'moment'

type DaysOfWeek =
    | 'isRepeatOnMon'
    | 'isRepeatOnTue'
    | 'isRepeatOnWed'
    | 'isRepeatOnThu'
    | 'isRepeatOnFri'
    | 'isRepeatOnSat'
    | 'isRepeatOnSun'

const RecurringFrequencyTypeOption = [
    { label: 'Day', value: 1 },
    { label: 'Week', value: 2 },
    { label: 'Month', value: 3 },
    { label: 'Year', value: 4 },
]

const taskTypeOptions = [
    { label: 'Onetime', value: 0 },
    { label: 'Recurring', value: 1 },
]

const reqrutingFrequencyOptions = [
    { label: 'Daily', value: 1 },
    { label: 'Custom', value: 5 },
]

const CreateTask = (
    props: tasksatestate & { taskId: string }
): React.ReactElement => {
    const {
        onSave,
        userList,
        priorityDropDownList,
        editData,
        projectDropDownList,
        allDepartmentList,
        ProjectManagmentTierList,
        taskId,
        resetdata,
        setResetData,
    } = props
    const [data, setData] = useState<SelectProps['options']>([])
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const initialSwitchStates: Record<DaysOfWeek, number> = {
        isRepeatOnMon: 0,
        isRepeatOnTue: 0,
        isRepeatOnWed: 0,
        isRepeatOnThu: 0,
        isRepeatOnFri: 0,
        isRepeatOnSat: 0,
        isRepeatOnSun: 0,
    }
    const [endsValue, setEndsValue] = useState(1)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [showInput, setShowInput] = useState(false)
    const [showCustomOptions, setShowCustomOptions] = useState(false)
    const [switchStates, setSwitchStates] = useState(initialSwitchStates)
    const [showToggles, setShowToggles] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState<number>(1)
    const [selectedProjectID, setSelectedProjectID] = useState<number>(1)
    const [selectedTierID, setSelectedTierID] = useState<number>(1)
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null)
    const [clientNumber, setClientNumber] = useState('')
    const user = localStorage.getItem('user')
    const userId = user !== null ? JSON.parse(user)?.userId : ''
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetPriorityList(dispatch)
        getAllDepartment(dispatch)
    }, [dispatch, form])

    useEffect(() => {
        if (selectedDepartment !== null && selectedDepartment !== undefined) {
            getProjectlist(dispatch, selectedDepartment)
        }
    }, [selectedDepartment])

    useEffect(() => {
        if (selectedProjectID !== null && selectedProjectID !== undefined) {
            GetTierList(dispatch, selectedProjectID.toString())
        }
    }, [selectedProjectID])

    useEffect(() => {
        setSelectedDate(editData.toDate)
        form.setFieldsValue({
            ...editData,
            priorityId: null,
            projectTierId: null,
            projectId: null,
            departmentId: null,
            assignedTo: null,
        })
        setSearchValue(editData.clientName)
        setSelectedProjectID(editData?.projectId)
        setSelectedDepartment(editData?.departmentId)
        setSelectedEndDate(dayjs(editData.recurringEndsOn, 'DD/MM/YYYY'))
    }, [editData])

    useEffect(() => {
        if (resetdata === 'true') {
            form.resetFields()
            resetForm()
        }

        setResetData('false')
    }, [resetdata])
    useEffect(() => {
        form.setFieldValue('toDate', selectedDate)
        form.setFieldValue('recurringEndsOn', selectedEndDate)
    }, [selectedDate, selectedEndDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onChangeEndDate = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            if (date !== null && date !== undefined) {
                // Set the dayjs object directly
                setSelectedEndDate(dayjs(date))
            }
        }
    }

    const handleSelectChange = (value: number): void => {
        if (value === 1) {
            setShowInput(true)
        } else {
            setShowInput(false)
        }
    }

    const handleRecurringPlanChange = (value: number): void => {
        if (value === 5) {
            setShowCustomOptions(true)
        } else {
            setShowCustomOptions(false)
        }
    }

    const handleDropdownChange = (value: number): void => {
        if (value === 2) {
            setShowToggles(true)
        } else {
            setShowToggles(false)
        }
    }

    const resetForm = (): void => {
        form.setFieldsValue({
            TaskdefaultData,
        })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const handleClientChange = (value: string): void => {
        setClientNumber(value)
    }
    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            businessType: client.id.split('-')[0], // Assuming the businessType is the first part before the dash
                            referenceId: client.id.split('-')[1],
                            desc: (
                                <div>
                                    <div
                                        className={styles.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const handleFinish = (): void => {
        void handleFinishData()
    }
    const handleFinishData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const formattoDateEnd = dayjs(formData.recurringEndsOn).format(
                'DD/MM/YYYY'
            )
            const formattoDate = dayjs(formData.toDate).format('DD/MM/YYYY')
            const formatfromDate = dayjs(formData.fromDate).format('DD/MM/YYYY')
            const clientName = data?.find(
                (client) => client.value === formData.clientName
            )
            const namepart = clientNumber.split('-')
            let referenceId = 0
            if (namepart[1] !== null && namepart[1] !== undefined) {
                referenceId = Number(namepart[1])
            } else {
                referenceId = 0
            }
            const datatosave = {
                taskId: taskId === '' ? 0 : Number(taskId),
                taskNo: formData.taskNo ?? '',
                taskName: formData.taskName ?? '',
                sortOrder: formData.sortOrder ?? 0,
                taskType: formData.taskType ?? 0,
                clientName: clientName?.label?.toString() ?? '',
                priorityId: formData.priorityId ?? 0,
                businessType: namepart[0] === '' ? 0 : Number(namepart[0]),
                referenceId: referenceId ?? 0,
                serviceReferenceId: formData.serviceReferenceId ?? 0,
                assignedBy: userId ?? 0,
                fromDate: formatfromDate ?? '',
                statusId: formData.statusId ?? 0,
                notifyToStatusChange: formData.notifyToStatusChange ?? 0,
                description: formData.description ?? '',
                recurringFrequency: formData.recurringFrequency ?? 0,
                recurringFrequencyType: formData.recurringFrequencyType ?? 0,
                recurringFrequencyPeriodId:
                    formData.recurringFrequencyPeriodId ?? 0,
                recurringEnds: formData.recurringEnds ?? 0,
                recurringOccurrence: formData.recurringOccurrence ?? 0,
                recurringEndsOn: formattoDateEnd ?? '',
                serviceId: formData.serviceId ?? 0,
                assignedTo: formData.assignedTo ?? 0,
                toDate: formattoDate ?? '',
                projectId: formData.projectId ?? 0,
                departmentId: formData.departmentId ?? 0,
                projectTierId: selectedTierID,
                sprintId: 0,
                ...switchStates,
            }

            void SaveTask(dispatch, datatosave)
            onSave(resetForm)
        } catch {}
    }

    const handleSwitchChange = (day: DaysOfWeek, checked: boolean): void => {
        setSwitchStates({ ...switchStates, [day]: checked ? 1 : 0 })
    }

    const handleDepartmentChange = (value: number): void => {
        setSelectedDepartment(value)
    }

    const handleProjectChange = (value: number): void => {
        setSelectedProjectID(value)
    }

    const handleTierChange = (value: number): void => {
        setSelectedTierID(value)
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 26 }}
                wrapperCol={{ span: 26 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="TaskId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Task Name"
                            name="taskName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Task Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Client" name="clientName">
                            <Select
                                loading={isLoading}
                                showSearch
                                placeholder="To filter the clients by number /name"
                                onChange={(value) => {
                                    handleClientChange(value)
                                }}
                                onSearch={(value) => {
                                    setSearchValue(value)
                                }}
                                filterOption={false}
                                allowClear
                                onClear={() => {
                                    setData([])
                                }}
                                optionLabelProp="label"
                                options={data}
                                optionRender={(option) => (
                                    <>{option.data.desc}</>
                                )}
                                notFoundContent={null}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Due Date"
                            name="toDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Due Date!',
                                },
                            ]}
                        >
                            <Space>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    disabledDate={(current) =>
                                        current !== null &&
                                        current < moment().startOf('day')
                                    }
                                    className={commonStyles.dateWidth}
                                    placeholder="select due date"
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Priority"
                            name="priorityId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Priority',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    ...priorityDropDownList?.map(
                                        (service: ListFrontEndInterface) => ({
                                            value: service.value,
                                            label: service.label,
                                        })
                                    ),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assignedTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Assigned',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[...userList]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Description" name="description">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Department"
                            name="departmentId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Department Name',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please Select"
                                onChange={handleDepartmentChange}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    ...allDepartmentList.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                    })),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Project"
                            name="projectId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Project Name',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please Select"
                                onChange={handleProjectChange}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    ...projectDropDownList.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                    })),
                                ]}
                                disabled={selectedDepartment === 1}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Project Tier" name="projectTierId">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please Select"
                                onChange={handleTierChange}
                                filterOption={(input, option) => {
                                    if (typeof option?.label === 'string') {
                                        return option.label
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    return false
                                }}
                                options={[
                                    ...ProjectManagmentTierList.map((item) => ({
                                        label: item.projectTierName,
                                        value: item.projectTierId,
                                    })),
                                ]}
                                disabled={selectedProjectID === 1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Task Type" name="taskTypeName">
                            <Select
                                onChange={handleSelectChange}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={taskTypeOptions}
                            />
                        </Form.Item>
                    </Col>

                    {showInput && (
                        <Col span={12}>
                            <Form.Item
                                label="Recurring Plan"
                                name="recurringFrequencyPeriodId"
                            >
                                <Select
                                    onChange={handleRecurringPlanChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    }
                                    options={reqrutingFrequencyOptions}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {showCustomOptions && (
                        <>
                            {' '}
                            <Col span={5}>
                                <Form.Item
                                    label="Repeat every"
                                    name="recurringFrequency"
                                >
                                    <InputNumber
                                        min={0}
                                        max={10}
                                        step={1}
                                        defaultValue={0}
                                        className={commonStyles.repeatWidth}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    name="recurringFrequencyType"
                                    label=" "
                                >
                                    <Select
                                        onChange={handleDropdownChange}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                        options={RecurringFrequencyTypeOption}
                                    />
                                </Form.Item>
                            </Col>
                            {showToggles && (
                                <Row gutter={24}>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Mon"
                                            name="isRepeatOnMon"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnMon ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnMon',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Tue"
                                            name="isRepeatOnTue"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnTue ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnTue',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Wed"
                                            name="isRepeatOnWed"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnWed ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnWed',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Thu"
                                            name="isRepeatOnThu"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnThu ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnThu',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Fri"
                                            name="isRepeatOnFri"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnFri ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnFri',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Sat"
                                            name="isRepeatOnSat"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnSat ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnSat',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Sun"
                                            name="isRepeatOnSun"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.isRepeatOnSun ===
                                                    1
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    handleSwitchChange(
                                                        'isRepeatOnSun',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={24}>
                                <Col span={3}>
                                    <Form.Item label=" " name="recurringEnds">
                                        <Radio
                                            value={1}
                                            checked={endsValue === 1}
                                            onChange={() => {
                                                setEndsValue(1)
                                            }}
                                        >
                                            never
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label=" " name="recurringEnds">
                                        <Radio
                                            value={2}
                                            checked={endsValue === 2}
                                            onChange={() => {
                                                setEndsValue(2)
                                            }}
                                        >
                                            on
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label=" " name="recurringEndsOn">
                                        <DatePicker
                                            disabled={endsValue !== 2}
                                            format="DD/MM/YYYY"
                                            onChange={(date, dateString) => {
                                                onChangeEndDate(
                                                    date,
                                                    dateString
                                                )
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label=" " name="recurringEnds">
                                        <Radio
                                            value={3}
                                            checked={endsValue === 3}
                                            onChange={() => {
                                                setEndsValue(3)
                                            }}
                                        >
                                            After
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Row align="middle">
                                        <Col span={12}>
                                            <Form.Item
                                                name="recurringOccurrence"
                                                label=" "
                                            >
                                                <InputNumber
                                                    min={0}
                                                    max={10}
                                                    step={1}
                                                    defaultValue={0}
                                                    disabled={endsValue !== 3}
                                                    className={
                                                        commonStyles.recureWidth
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span>Occurrences</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Tooltip title="Save Task">
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleFinish()
                                }}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): taskstate => {
    return {
        userList: state.user.userList,
        clientDropDownList: state.invoice.clientDropDownList,
        priorityDropDownList: state.client.priorityDropDownList,
        ProjectManagmentList: state.initial.ProjectManagmentList,
        projectDropDownList: state.helpdesk.projectDropDownList,
        allDepartmentList: state.helpdesk.allDepartmentList,
        ProjectManagmentTierList: state.initial.ProjectManagmentTierList,
    }
}

export default connect(mapStateToProps)(CreateTask)
