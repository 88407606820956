import { Avatar, Col, Row, Select, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styles from './profile.less'
import { useNavigate } from 'react-router-dom'
import { pageReloadAuth } from '../../Reducer/AuthReducer'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../Types/CommonType'
import { departmentId } from '../../Reducer/DashboardFlagReducer'
import { GetDYNAMICClientDetails } from '../../Services/Common'
import { GetProfileDetails } from '../../Services/Profile'
import { type UserData } from '../../Types/Profile'

// import { type Department } from '../../Types/Auth'
// import { GetDepartmetRightsDetails } from '../../Services/Auth'

interface UserProfileState {
    dynamicClientCategoryList: ListFrontEndInterface[]
    profiledataList: UserData
}
interface UserProfileProps extends UserProfileState {
    oncancelModel: () => void
}

const UserProfile = (props: UserProfileProps): React.ReactElement => {
    const { oncancelModel, dynamicClientCategoryList, profiledataList } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null

    // const departmentData = tokenDetails?.departments
    // const clientCategoryId = tokenDetails?.clientCategoryId
    const userGroupId = tokenDetails?.userGroupId
    const [departmetId, setDepartmetId] = useState(0)
    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('departmetId')
        setDepartmetId(Number(tokenDetailsString))
    }, [])

    useEffect(() => {
        GetDYNAMICClientDetails(dispatch)
    }, [])

    const NaviagetToChangePin = (): void => {
        navigate(`/change-Pin`)
        oncancelModel()
    }
    const NaviagetToChangePassword = (): void => {
        navigate(`/change-Password`)
        oncancelModel()
    }
    const NaviagetToListofHistory = (): void => {
        navigate(`/change-holidayList`)
        oncancelModel()
    }
    const NaviagetManageProfile = (): void => {
        navigate(`/manage-Profile`)
        oncancelModel()
    }

    const enterFullScreen = (): void => {
        if (document.fullscreenElement == null) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error('Failed to enter fullscreen mode:', err)
            })
        } else {
            if (document.exitFullscreen == null) {
                document.exitFullscreen().catch((err) => {
                    console.error('Failed to exit fullscreen mode:', err)
                })
            }
        }
    }

    const handlePrint = (): void => {
        window.print()
    }

    const handleLogout = (): void => {
        localStorage.clear()
        dispatch(pageReloadAuth())
        setTimeout(() => {
            navigate(`/`)
            location.reload()
        }, 2000)
    }

    const onDepartmentChange = (value: number): void => {
        localStorage.setItem('departmetId', value.toString())
        dispatch(departmentId(value))
        setDepartmetId(value)
        // GetDepartmetRightsDetails(dispatch, UserId, value)
    }
    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        void fetchUserData()
    }, [tokenDetails?.userId])

    const fetchUserData = async (): Promise<void> => {
        await GetProfileDetails(dispatch, tokenDetails?.userId, () => {})
    }
    return (
        <>
            <div className={styles.container}>
                <Row>
                    <Col span={8}>
                        <Avatar
                            src={profiledataList?.imageurl}
                            size={54}
                            className={styles.avatarImage}
                        />
                    </Col>
                    <Col span={16}>
                        <Space.Compact direction="vertical" size="small">
                            <div className={styles.firstName}>
                                {profiledataList?.userName}
                            </div>
                            <div className={styles.lastName}>
                                {profiledataList?.emailAddress}
                            </div>
                        </Space.Compact>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                {userGroupId !== null &&
                    userGroupId !== undefined &&
                    userGroupId !== 4 &&
                    userGroupId !== 3 && (
                        <div>
                            <Select
                                placeholder="Select Department"
                                style={{ width: '100%' }}
                                onChange={onDepartmentChange}
                                value={departmetId}
                                options={[
                                    { label: 'Please select', value: 0 },
                                    { label: 'Accounting', value: 1 },
                                    ...(Array.isArray(dynamicClientCategoryList)
                                        ? dynamicClientCategoryList
                                        : []),
                                ]}
                            />
                        </div>
                    )}

                <div className={styles.div} onClick={enterFullScreen}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>Full Screen</div>
                        <i>F11</i>
                    </div>
                </div>
                <div className={styles.div} onClick={handlePrint}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>Print</div>
                        <i>Ctrl + P</i>
                    </div>
                </div>
            </div>
            <hr />
            <Space
                direction="vertical"
                size="large"
                className={styles.spaceStyle}
            >
                <div className={styles.div} onClick={NaviagetToChangePassword}>
                    Change Password
                </div>
                <div className={styles.div} onClick={NaviagetToChangePin}>
                    Change Pin
                </div>
                <div className={styles.div} onClick={NaviagetToListofHistory}>
                    List of Holiday
                </div>
                <div className={styles.div} onClick={NaviagetManageProfile}>
                    Manage Profile
                </div>
                <div className={styles.divLog} onClick={handleLogout}>
                    Log Out
                </div>
            </Space>
        </>
    )
}

const mapStateToProps = (state: any): UserProfileState => {
    return {
        dynamicClientCategoryList: state.common.dynamicClientCategoryList,
        profiledataList: state.profile.profiledataList,
    }
}

export default connect(mapStateToProps)(UserProfile)
