import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
} from 'antd'
import commontStyles from './document.less'
import { SaveOutlined } from '@ant-design/icons'
import { type CorporateDocumentSave } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import { connect, useDispatch } from 'react-redux'
import {
    GetContactInfoPersonDropDown,
    SaveOfficerDocument,
} from '../../../../../../Services/Corporate'
import moment from 'moment'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import { GetOfficerDocumenttypeList } from '../../../../../../Services/Common'
interface OfficerDumnets {
    OfficerData: any
    Corporateid: number
    fetchData: () => void
    contactInfoPersonDropDownList: ListFrontEndInterface[]
    officerdocumenttypeList: ListFrontEndInterface[]
}
interface officerDocumentsState {
    contactInfoPersonDropDownList: ListFrontEndInterface[]
    addressproofList: ListFrontEndInterface[]
    officerdocumenttypeList: ListFrontEndInterface[]
}
const OfficerDcumentForm = (props: OfficerDumnets): React.ReactElement => {
    const {
        Corporateid,
        OfficerData,
        fetchData,
        contactInfoPersonDropDownList,
        officerdocumenttypeList,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDatePE, setSelectedDatePE] = useState<string>('')
    const [selectedDateDL, setSelectedDateDL] = useState<string>('')
    const [selectedDatePR, setSelectedDatePR] = useState<string>('')
    const [selectedMemberType, setSelectedMemberType] = useState(1)
    const [selectedDocumentType, setSelectedDocumentType] = useState(4)
    const [showDatePicker, setShowDatePicker] = useState(true)
    const [selectedReferenceId, setSelectedReferenceId] = useState<number>(0)
    const officerDocumentHeaderList = OfficerData?.officerDocumentHeaderList
    const [uploadFileList, setUploadFileList] = useState<string[]>([])
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetOfficerDocumenttypeList(dispatch, 3, 2, 1)
    }, [])
    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const onChangePE = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDatePE(isoDate)
        }
    }

    const onChangeDL = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDateDL(isoDate)
        }
    }

    const onChangePR = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDatePR(isoDate)
        }
    }

    const handleDoumentTypeChange = (value: number): void => {
        setSelectedDocumentType(value)
        setShowDatePicker(value === 4)
        form.resetFields(['attachmentPath'])
        form.resetFields(['ppExpiryDate', 'dlExpiryDate', 'prExpiryDate'])
    }

    const handleMemberTypeChange = (value: number): void => {
        setSelectedMemberType(value)
        setSelectedReferenceId(0)
    }
    useEffect(() => {
        form.setFieldValue('ppExpiryDate', selectedDatePE)
        form.setFieldValue('dlExpiryDate', selectedDateDL)
        form.setFieldValue('prExpiryDate', selectedDatePR)
    }, [selectedDatePE, selectedDateDL, selectedDatePR])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetContactInfoPersonDropDown(dispatch, Corporateid, selectedMemberType)
    }, [Corporateid, selectedMemberType])
    useEffect(() => {
        form.setFieldValue(
            'referenceId',
            contactInfoPersonDropDownList[0]?.value
        )
    }, [Corporateid, contactInfoPersonDropDownList])
    const findCompanyAttachmentId = (
        officerDocumentHeaderList: any,
        selectedReferenceId: number,
        selectedDocumentType: number
    ): number => {
        if (!Array.isArray(officerDocumentHeaderList)) {
            return 0
        }
        for (const header of officerDocumentHeaderList) {
            if (header?.officerPersonOrCorporateId === selectedReferenceId) {
                const matchingDocument = header?.officerDocumentList?.find(
                    (document: any) =>
                        document?.documentBaseId === selectedDocumentType
                )

                return matchingDocument != null
                    ? matchingDocument?.companyAttachmentId
                    : 0
            }
        }

        return 0
    }

    const onPersonSelect = (value: number): void => {
        setSelectedReferenceId(value)
    }
    const handelSave = (): void => {
        const formdata = form.getFieldsValue()
        const membertype: string =
            selectedMemberType === 1 ? 'Person' : 'CORPORATE'

        const documentname =
            selectedDocumentType === 4 ? 'ID Proof' : 'Address Proof'

        const companyAttachmentId = findCompanyAttachmentId(
            officerDocumentHeaderList,
            selectedReferenceId,
            selectedDocumentType
        )
        const ppExpiryDate =
            formdata.ppExpiryDate != null
                ? moment(formdata.ppExpiryDate).format('DD/MM/YYYY')
                : ''

        const dlExpiryDate =
            formdata.dlExpiryDate != null
                ? moment(formdata.dlExpiryDate).format('DD/MM/YYYY')
                : ''

        const prExpiryDate =
            formdata.prExpiryDate != null
                ? moment(formdata.prExpiryDate).format('DD/MM/YYYY')
                : ''
        let attachmentPaths = ''
        if (uploadFileList?.length > 0) {
            attachmentPaths = uploadFileList[0]
        }
        const savedata = {
            companyAttachmentId: companyAttachmentId ?? 0,
            companyTypeId: 1,
            referenceId: Corporateid,
            referenceId2: selectedReferenceId,
            documentBaseId: selectedDocumentType ?? 0,
            documentBaseName: documentname,
            attachmentPath: attachmentPaths ?? '',
            corporateId: 0,
            type: membertype,
            ppExpiryDate: ppExpiryDate !== 'Invalid date' ? ppExpiryDate : '',
            dlExpiryDate: dlExpiryDate !== 'Invalid date' ? dlExpiryDate : '',
            prExpiryDate: prExpiryDate !== 'Invalid date' ? prExpiryDate : '',
        }
        void SaveOfficerDocument(dispatch, savedata)
        fetchData()
        form.resetFields()
    }

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<CorporateDocumentSave> label="Member Type">
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleMemberTypeChange}
                                value={selectedMemberType}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option key="PERSON" value={1}>
                                    Person
                                </Select.Option>
                                <Select.Option key="CORPORATE" value={2}>
                                    Corporate
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commontStyles.CorporteDocument}>
                    <Col span={24}>
                        <Form.Item<CorporateDocumentSave>
                            label={`Member ${
                                selectedMemberType === 2
                                    ? 'Corporate'
                                    : 'Person'
                            }`}
                            name="referenceId"
                        >
                            <Select
                                allowClear
                                placeholder={`Please select  ${
                                    selectedMemberType === 2
                                        ? 'Corporate'
                                        : 'Person'
                                }`}
                                onChange={(value: number) => {
                                    onPersonSelect(value)
                                }}
                                value={selectedReferenceId}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { value: 0, label: 'Please Select' },
                                    ...contactInfoPersonDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={commontStyles.CorporteDocument}>
                    <Col span={24}>
                        <Form.Item<CorporateDocumentSave>
                            label="Document Type"
                            name="documentBaseId"
                            className={`${
                                selectedDocumentType === 5
                                    ? commontStyles.CorporteDocumentGeenreal
                                    : ''
                            }`}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleDoumentTypeChange}
                                value={selectedDocumentType}
                                defaultValue={4}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={officerdocumenttypeList}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showDatePicker && (
                    <>
                        <Row
                            gutter={16}
                            className={commontStyles.CorporteDocument}
                        >
                            <Col span={24}>
                                <Form.Item<CorporateDocumentSave>
                                    label="Passport Expiry Date"
                                    name="ppExpiryDate"
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            format={'DD/MM/YYYY'}
                                            onChange={(date, dateString) => {
                                                onChangePE(date, dateString)
                                            }}
                                            value={
                                                selectedDatePE?.length > 0
                                                    ? dayjs(selectedDatePE)
                                                    : undefined
                                            }
                                            className={commontStyles.dateWidth}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            gutter={16}
                            className={commontStyles.CorporteDocument}
                        >
                            <Col span={24}>
                                <Form.Item<CorporateDocumentSave>
                                    label="Driving Licence Expiry Date"
                                    name="dlExpiryDate"
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            format={'DD/MM/YYYY'}
                                            onChange={(date, dateString) => {
                                                onChangeDL(date, dateString)
                                            }}
                                            value={
                                                selectedDateDL?.length > 0
                                                    ? dayjs(selectedDateDL)
                                                    : undefined
                                            }
                                            className={commontStyles.dateWidth}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            gutter={16}
                            className={commontStyles.CorporteDocument}
                        >
                            <Col span={24}>
                                <Form.Item<CorporateDocumentSave>
                                    label="PR Expiry Date"
                                    name="prExpiryDate"
                                    className={
                                        commontStyles.CorporteDocumentGeenreal
                                    }
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={(date, dateString) => {
                                                onChangePR(date, dateString)
                                            }}
                                            className={commontStyles.dateWidth}
                                            value={
                                                selectedDatePR?.length > 0
                                                    ? dayjs(selectedDatePR)
                                                    : null
                                            }
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<CorporateDocumentSave> name="attachmentPath">
                            <S3UploadMultiple
                                name={'attachmentPath'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={'Corporate'}
                                corporateid={Corporateid}
                                documenttype={
                                    selectedMemberType === 1
                                        ? 'Person'
                                        : 'Corporate'
                                }
                                documentbaseId={selectedDocumentType}
                                memberpersonId={selectedReferenceId}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16} className={commontStyles.CorporteDocument}>
                        <Col offset={20} span={2}>
                            <Button type="primary" onClick={handelSave}>
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): officerDocumentsState => {
    return {
        addressproofList: state.common.addressproofList,
        contactInfoPersonDropDownList:
            state.client.contactInfoPersonDropDownList,
        officerdocumenttypeList: state.common.officerdocumenttypeList,
    }
}

export default connect(mapStateToProps)(OfficerDcumentForm)
