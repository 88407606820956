// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload_container_iAUuo {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.upload_container_iAUuo.dragging_tSy8A {
  border: 2px dashed #1890ff;
  background-color: rgba(24, 144, 255, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/Components/upload.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,cAAA;AACJ;AAEA;EACI,0BAAA;EACA,yCAAA;AAAJ","sourcesContent":[".upload_container {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n}\n\n.upload_container.dragging {\n    border: 2px dashed #1890ff;\n    background-color: rgba(24, 144, 255, 0.1);\n}\n\n@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload_container": `upload_container_iAUuo`,
	"dragging": `dragging_tSy8A`
};
export default ___CSS_LOADER_EXPORT___;
