import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import { type ProspectClientFormDataInterface } from '../../../../Types/Client/ProspectClients/prospectClient'
import { SaveCommuniCationProspectClient } from '../../../../Services/ProspectClient'
import { GetContacPersonDetalils } from '../../../../Services/GlobelSearch'
import { useDispatch } from 'react-redux'
import {
    type ContactInfo,
    type CommunicationModelProps,
} from '../../../../Types/GlobelSearchClient/GlobelSearchClient'

const ClientSms = (props: CommunicationModelProps): React.ReactElement => {
    const { businessTypeId, referenceId, changeModalStatus } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [ConatctInfo, setConatctInfo] = useState<ContactInfo | null>(null)

    useEffect(() => {
        const fetchData = (): void => {
            void GetContacPersonDetalils(
                dispatch,
                businessTypeId,
                referenceId,
                (details: ContactInfo) => {
                    setConatctInfo(details)
                }
            )
        }
        fetchData()
    }, [businessTypeId, referenceId])

    useEffect(() => {
        if (ConatctInfo !== null && typeof ConatctInfo === 'object') {
            const newFieldValues = {
                firstName: ConatctInfo?.firstName,
                mobileNumber: ConatctInfo?.mobileNumber,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [ConatctInfo, form])

    const onSave = (): void => {
        void onSaveSms()
    }
    const onSaveSms = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataTosave = {
                otherCommunicationHistoryId: 0,
                communicationModeId: 2,
                communicationTypeId: 2,
                businessTypeId,
                referenceId,
                contactNumber: formData.mobileNumber,
                description: formData.description,
                historyDate: '',
            }

            await SaveCommuniCationProspectClient(dispatch, dataTosave)

            form.setFieldsValue({
                description: '',
            })

            changeModalStatus()
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<ProspectClientFormDataInterface>
                    name="prospectClientId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your firstName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Phone Number"
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="SMS Content "
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Message',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" size="middle" onClick={onSave}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ClientSms
