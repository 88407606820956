import React, { type ReactNode } from 'react'
import { Form, Checkbox } from 'antd'
import styles from './Less/input.less'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'

const InputFieldCheckBox = (props: {
    name: string
    label: string
    children: ReactNode
    checked: boolean
    onChange: (e: CheckboxChangeEvent) => void
}): React.ReactElement => {
    const { name, label, children, onChange, checked } = props

    return (
        <Form.Item name={name} valuePropName="checked">
            <>
                <Checkbox
                    className={styles.rememberMeText}
                    checked={checked}
                    onChange={onChange}
                >
                    {label}
                </Checkbox>
                {children}
            </>
        </Form.Item>
    )
}

export default InputFieldCheckBox
