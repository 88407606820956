import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type TemplateFormDataInterface,
    type TemplatePayloadInteface,
} from '../Types/TemplateTypes'
import {
    type GroupAndPartnerFormDataInterface,
    type GroupAndPartnersPayloadInterface,
} from '../Types/GroupAndPartners'
import {
    type BankDetailsFormDataInterface,
    type BankingDetailsPayloadInterface,
} from '../Types/BankDetails'
import {
    type SupplierFormDataInterface,
    type SupplierDetailsPayloadInteface,
} from '../Types/Supplier'
import {
    type DepartmentFormDataInterface,
    type DepartmentPayloadInterface,
} from '../Types/Department'
import {
    type StaffDesignationFormDataInterface,
    type StaffDesignationPayloadInterface,
} from '../Types/StaffDesignations'
import {
    type HolidayDetailsFormDataInterface,
    type HolidaysPayloadInteface,
} from '../Types/HolidayDetails'
import {
    type OtherPersonFormDataInterface,
    type OtherPersonPayloadInterface,
} from '../Types/OtherPerson'
import {
    type WorkflowFormDataInterface,
    type WorkflowPayloadInterface,
    type WorkflowDetailsDataPayloadInterface,
} from '../Types/Workflow'
import {
    type WorkflowDetailsFormDataInterface,
    type WorkflowDetailsPayloadInterface,
} from '../Types/WorkflowDetails'
import { type ListFrontEndInterface } from '../Types/CommonType'
import {
    type DashboardNewsFormDataInterface,
    type DashboardNewsPayloadInterface,
} from '../Types/DashboardNews'
import {
    type LeaveFormDataInterface,
    type LeavePayloadInterface,
} from '../Types/LeaveType'
import {
    type EmailTemplateFormDataInterface,
    type EmailTemplateDetailsPayloadInterface,
    type EmailContentTypeListFrontEndInterface,
    type EmailTemplateViewDetailsObjPayloadInterface,
} from '../Types/EmailTemplate'
import {
    type BulkEmailPayloadInterface,
    type bulkmailFormDataInterface,
} from '../Types/BulkEmail'
import {
    type ProjectManagmentPayloadInterface,
    type ProjectManagmentFormDataInterface,
    type ProjectManagmentSatusFormDataInterface,
    type ProjectManagmentStausPayloadInterface,
    type AttachmentProjectManagmentFormDataInterface,
    type AttachmentProjectProjectManagmentPayloadInterface,
    type ProjectManagmentTierPayloadInterface,
    type ProjectManagmentTierFormDataInterface,
} from '../Types/ProjectMangement'
import {
    type createCategoryParentInterface,
    type createCategoryParentPayloadInterface,
} from '../Types/CreateCategory'

interface InitailStateInterface {
    groupAndPartnerList: GroupAndPartnerFormDataInterface[]
    groupAndPartnerListCount: number
    ProjectManagmentList: ProjectManagmentFormDataInterface[]
    ProjectManagmentListCount: number
    ProjectManagmentStausList: ProjectManagmentSatusFormDataInterface[]
    ProjectManagmentStausListCount: number
    ProjectManagmentTierList: ProjectManagmentTierFormDataInterface[]
    ProjectManagmentTierListCount: number
    AttachmentDropDownList: AttachmentProjectManagmentFormDataInterface[]
    AttachmentDropDownListCount: number
    parentCategoryList: ListFrontEndInterface[]
    bankingDetailsList: BankDetailsFormDataInterface[]
    bankingDetailsListCount: number
    supplierDetailsList: SupplierFormDataInterface[]
    supplierDetailsListCount: number
    departmentList: DepartmentFormDataInterface[]
    departmentListCount: number
    leaveList: LeaveFormDataInterface[]
    leaveListCount: number
    holidaysDataList: HolidayDetailsFormDataInterface[]
    holidaysListCount: number
    templateList: TemplateFormDataInterface[]
    templateListCount: number
    staffDesignationList: StaffDesignationFormDataInterface[]
    staffDesignationListCount: number
    otherPersonList: OtherPersonFormDataInterface[]
    otherPersonListCount: number
    categoryDropDownList: ListFrontEndInterface[]
    businessstypedropdownlist: ListFrontEndInterface[]
    departmentDropDownList: ListFrontEndInterface[]
    emailTemplateDetailsList: EmailTemplateFormDataInterface[]
    emailTemplateDetails: EmailTemplateFormDataInterface[]
    emailTemplateDetailsListCount: number
    emailContentTypeList: EmailContentTypeListFrontEndInterface[]
    workflowList: WorkflowFormDataInterface[]
    workflowListCount: number
    workflowTypeDropDownList: ListFrontEndInterface[]
    workflowDetailsList: WorkflowDetailsFormDataInterface[]
    workflowDetailsListCount: number
    workflowDetail: any
    approveByDropDownList: ListFrontEndInterface[]
    dashboardNewsList: DashboardNewsFormDataInterface[]
    dashboardNewsListCount: number
    serviceDropDownList: ListFrontEndInterface[]
    clientDropDownList: ListFrontEndInterface[]
    contactdepartmentDropDownList: bulkmailFormDataInterface[]
    contactdepartmentDropDownListCount: number
    clientServiceDropDownList: ListFrontEndInterface[]
    clientParentCategoryDropDown: createCategoryParentInterface[]
    clientParentCategoryDropDownCount: number
    clientParentCategory: createCategoryParentInterface[]
    clientParentCategoryCount: number
}

const InitialSlice = createSlice({
    name: 'initial',
    initialState: {
        groupAndPartnerList: [],
        groupAndPartnerListCount: 0,
        ProjectManagmentList: [],
        ProjectManagmentListCount: 0,
        ProjectManagmentTierList: [],
        ProjectManagmentTierListCount: 0,
        ProjectManagmentStausList: [],
        ProjectManagmentStausListCount: 0,
        AttachmentDropDownList: [],
        AttachmentDropDownListCount: 0,
        parentCategoryList: [],
        parentCategoryListCount: 0,
        bankingDetailsList: [],
        bankingDetailsListCount: 0,
        supplierDetailsList: [],
        supplierDetailsListCount: 0,
        departmentList: [],
        departmentListCount: 0,
        contactdepartmentDropDownList: [],
        contactdepartmentDropDownListCount: 0,
        leaveList: [],
        leaveListCount: 0,
        templateList: [],
        templateListCount: 0,
        holidaysDataList: [],
        holidaysListCount: 0,
        staffDesignationList: [],
        staffDesignationListCount: 0,
        categoryDropDownList: [],
        businessstypedropdownlist: [],
        departmentDropDownList: [],
        emailTemplateDetailsList: [],
        emailTemplateDetails: [],
        emailTemplateDetailsListCount: 0,
        emailContentTypeList: [],
        otherPersonList: [],
        otherPersonListCount: 0,
        workflowList: [],
        workflowListCount: 0,
        workflowTypeDropDownList: [],
        workflowDetailsList: [],
        workflowDetailsListCount: 0,
        workflowDetail: {},
        approveByDropDownList: [],
        dashboardNewsList: [],
        dashboardNewsListCount: 0,
        serviceDropDownList: [],
        clientDropDownList: [],
        clientServiceDropDownList: [],
        clientParentCategoryDropDown: [],
        clientParentCategoryDropDownCount: 0,
        clientParentCategory: [],
        clientParentCategoryCount: 0,
    },
    reducers: {
        gorupAndPartnerList(
            state: InitailStateInterface,
            action: PayloadAction<GroupAndPartnersPayloadInterface>
        ) {
            state.groupAndPartnerList = action?.payload?.data
            state.groupAndPartnerListCount = action?.payload?.noOfRecords
        },
        ProjectManagmentList(
            state: InitailStateInterface,
            action: PayloadAction<ProjectManagmentPayloadInterface>
        ) {
            state.ProjectManagmentList = action?.payload?.data
            state.ProjectManagmentListCount = action?.payload?.noOfRecords
        },
        ProjectManagmentTierList(
            state: InitailStateInterface,
            action: PayloadAction<ProjectManagmentTierPayloadInterface>
        ) {
            state.ProjectManagmentTierList = action?.payload?.data
            state.ProjectManagmentTierListCount = action?.payload?.noOfRecords
        },
        AttachmentDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<AttachmentProjectProjectManagmentPayloadInterface>
        ) {
            state.AttachmentDropDownList = action?.payload?.data
            state.AttachmentDropDownListCount = action?.payload?.noOfRecords
        },

        ProjectManagmentStausList(
            state: InitailStateInterface,
            action: PayloadAction<ProjectManagmentStausPayloadInterface>
        ) {
            state.ProjectManagmentStausList = action?.payload?.data
            state.ProjectManagmentStausListCount = action?.payload?.noOfRecords
        },
        parentCategoryList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.parentCategoryList = action?.payload
        },
        bankingDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<BankingDetailsPayloadInterface>
        ) {
            state.bankingDetailsList = action?.payload?.data
            state.bankingDetailsListCount = action?.payload?.noOfRecords
        },
        supplierDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<SupplierDetailsPayloadInteface>
        ) {
            state.supplierDetailsList = action?.payload?.data
            state.supplierDetailsListCount = action?.payload?.noOfRecords
        },
        emailTemplateDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<EmailTemplateDetailsPayloadInterface>
        ) {
            state.emailTemplateDetailsList = action?.payload?.data
            state.emailTemplateDetailsListCount = action?.payload?.noOfRecords
        },
        emailTemplateDetails(
            state: InitailStateInterface,
            action: PayloadAction<EmailTemplateViewDetailsObjPayloadInterface>
        ) {
            state.emailTemplateDetails = action?.payload?.data
        },
        emailContentTypeList(
            state: InitailStateInterface,
            action: PayloadAction<EmailContentTypeListFrontEndInterface[]>
        ) {
            state.emailContentTypeList = action?.payload
        },
        departmentLists(
            state: InitailStateInterface,
            action: PayloadAction<DepartmentPayloadInterface>
        ) {
            state.departmentList = action?.payload?.data
            state.departmentListCount = action?.payload?.noOfRecords
        },
        leaveLists(
            state: InitailStateInterface,
            action: PayloadAction<LeavePayloadInterface>
        ) {
            state.leaveList = action?.payload?.data
            state.leaveListCount = action?.payload?.noOfRecords
        },
        templateList(
            state: InitailStateInterface,
            action: PayloadAction<TemplatePayloadInteface>
        ) {
            state.templateList = action?.payload?.data
            state.templateListCount = action?.payload?.noOfRecords
        },
        holidaysDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<HolidaysPayloadInteface>
        ) {
            state.holidaysDataList = action?.payload?.data
            state.holidaysListCount = action?.payload?.noOfRecords
        },
        staffDesignationList(
            state: InitailStateInterface,
            action: PayloadAction<StaffDesignationPayloadInterface>
        ) {
            state.staffDesignationList = action?.payload?.data
            state.staffDesignationListCount = action?.payload?.noOfRecords
        },
        categoryDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.categoryDropDownList = action?.payload
        },
        businessstypedropdownlist(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.businessstypedropdownlist = action?.payload
        },
        otherPersonList(
            state: InitailStateInterface,
            action: PayloadAction<OtherPersonPayloadInterface>
        ) {
            state.otherPersonList = action?.payload?.data
            state.otherPersonListCount = action?.payload?.noOfRecords
        },
        workflowList(
            state: InitailStateInterface,
            action: PayloadAction<WorkflowPayloadInterface>
        ) {
            state.workflowList = action?.payload?.data
            state.workflowListCount = action?.payload?.noOfRecords
        },
        workflowTypeDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.workflowTypeDropDownList = action?.payload
        },
        workflowDetailsList(
            state: InitailStateInterface,
            action: PayloadAction<WorkflowDetailsPayloadInterface>
        ) {
            state.workflowDetailsList = action?.payload?.data
            state.workflowDetailsListCount = action?.payload?.noOfRecords
        },
        workflowDetail(
            state: InitailStateInterface,
            action: PayloadAction<WorkflowDetailsDataPayloadInterface>
        ) {
            state.workflowDetail = { ...action?.payload?.data }
        },
        approveByDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.approveByDropDownList = action?.payload
        },
        dashboardNewsList(
            state: InitailStateInterface,
            action: PayloadAction<DashboardNewsPayloadInterface>
        ) {
            state.dashboardNewsList = action?.payload?.data
            state.dashboardNewsListCount = action?.payload?.noOfRecords
        },
        departmentDropDownLists(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.departmentDropDownList = action?.payload
        },
        contactdepartmentDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<BulkEmailPayloadInterface>
        ) {
            state.contactdepartmentDropDownList = action?.payload?.data
            state.contactdepartmentDropDownListCount =
                action?.payload?.noOfRecords
        },
        serviceDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.serviceDropDownList = action?.payload
        },
        clientDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.clientDropDownList = action?.payload
        },
        clientServiceDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.clientServiceDropDownList = action?.payload
        },
        clientParentCategoryDropDown(
            state: InitailStateInterface,
            action: PayloadAction<createCategoryParentPayloadInterface>
        ) {
            state.clientParentCategoryDropDown = action?.payload?.data
            state.clientParentCategoryDropDownCount =
                action?.payload?.noOfRecords
        },
        clientParentCategoryReducer(
            state: InitailStateInterface,
            action: PayloadAction<createCategoryParentPayloadInterface>
        ) {
            state.clientParentCategory = action?.payload?.data
            state.clientParentCategoryCount = action?.payload?.noOfRecords
        },
    },
})

export const {
    AttachmentDropDownList,
    ProjectManagmentTierList,
    gorupAndPartnerList,
    ProjectManagmentList,
    ProjectManagmentStausList,
    parentCategoryList,
    bankingDetailsList,
    departmentLists,
    leaveLists,
    templateList,
    supplierDetailsList,
    emailTemplateDetailsList,
    emailTemplateDetails,
    staffDesignationList,
    categoryDropDownList,
    businessstypedropdownlist,
    emailContentTypeList,
    holidaysDetailsList,
    otherPersonList,
    workflowList,
    workflowTypeDropDownList,
    workflowDetailsList,
    workflowDetail,
    approveByDropDownList,
    dashboardNewsList,
    departmentDropDownLists,
    serviceDropDownList,
    clientDropDownList,
    contactdepartmentDropDownList,
    clientServiceDropDownList,
    clientParentCategoryDropDown,
    clientParentCategoryReducer,
} = InitialSlice.actions

export default InitialSlice.reducer
