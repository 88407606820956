import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import {
    gorupAndPartnerList,
    parentCategoryList,
    categoryDropDownList,
} from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { GroupAndPartnerFormDataInterface } from '../Types/GroupAndPartners'
import type {
    TableListInterface,
    ListFrontEndInterface,
    ListBackendInterface,
} from '../Types/CommonType'
import { setLoadingStatus } from '../Reducer/Common'
import { clientTypeDropDownList } from '../Reducer/Client'

export const GetGroupAndPartnerList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/InitialSetup/GetClientCategoryList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: GroupAndPartnerFormDataInterface) => {
                        return {
                            ...value,
                            key: value.clientCategoryId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(gorupAndPartnerList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const saveGroupAndPartner = async (
    dispatch: Dispatch,
    data: GroupAndPartnerFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = {
        clientCategoryId: data?.clientCategoryId ?? '0',
        clientCategoryName: data?.clientCategoryName ?? '',
        addressOne: data?.addressOne ?? '',
        addressTwo: data?.addressTwo ?? '',
        town: data?.town ?? '',
        countryId: data?.country ?? '0',
        postCode: data?.postCode ?? '',
        mobileNumber: data?.mobileNumber ?? '',
        workNumber: data?.workNumber ?? '',
        homeNumber: data?.homeNumber ?? '',
        emailAddress: data?.emailAddress ?? '',
        alternativeEmailAddress: data?.alternativeEmailAddress ?? '',
        businessLogoPath: data?.businessLogoPath ?? '',
        signaturePath: data?.signaturePath ?? '',
        parentCategoryId: data?.parentCategoryId ?? '0',
        parentCategoryName: '',
        rubberStampLogo: data?.rubberStampLogo ?? '',
        website: data?.website ?? '',
        documentFooterText: data?.documentFooterText ?? '',
        isActive: 'Active',
        isDelete: false,
    }

    return await axios
        .post(`/api/InitialSetup/SetClientCategory`, bodyData)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeleteGroupAndPartner = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/6/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ActiveInactiveGroupAndPartner = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/5/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetParentCategoryList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/22')
        .then((response) => {
            if (response.status === 200) {
                const newParentCategoryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(parentCategoryList(newParentCategoryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCategoryDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/1')
        .then((response) => {
            if (response.status === 200) {
                const newCategoryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(categoryDropDownList(newCategoryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetClientTypeDropDownList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/2')
        .then((response) => {
            if (response.status === 200) {
                const newCategoryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(clientTypeDropDownList(newCategoryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
