import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import type { DashboardNewsExpandViewInterface } from '../../../Types/DashboardNews'

const DashboardNewsExpandView = (
    props: DashboardNewsExpandViewInterface
): React.ReactElement => {
    const { dashboardNewsData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Description -:{' '}
                <div className={commontStyles.expandRowValue}>
                    {dashboardNewsData.description}
                </div>
            </Row>
        </div>
    )
}

export default DashboardNewsExpandView
