import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Col, Row, Space, Table } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'

import calculatePagination from '../../../Utils/Pagination'
import {
    type CSFillingListDataTypeInterface,
    type CSFillingListInterface,
} from '../../../Types/CompanyHouse/CSFillingStatus'
import CHFillingHistoryExpandView from '../ConformationFillingHistory/CHFillingHistoryExpandView'
import { GetCSFillingStatusList } from '../../../Services/CompanyHouse/CHFillingHostory'
import { useLocation } from 'react-router-dom'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { type TableParamsInterface } from '../../../Types/CommonType'

const CSFillingStatusList = (
    props: CSFillingListInterface
): React.ReactElement => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const referenceId = params.get('referenceId')
    const dispatch = useDispatch()
    const { CSFillingList, CSFillingListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetCSFillingStatusList(
            dispatch,
            {
                pageNo,
                pageSize,
                searchCriteriaList,
                sortOrderList,
            },
            Number(referenceId)
        )
    }, [
        referenceId,
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])
    // const getTableData = useCallback(() => {
    //     GetCSFillingStatusList(
    //         dispatch,
    //         currentPage,
    //         itemsPerPage,
    //         Number(referenceId)
    //     )
    // }, [referenceId, currentPage, itemsPerPage])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: CSFillingListCount,
            },
        })
    }, [CSFillingListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        CSFillingListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<CSFillingListDataTypeInterface> = [
        {
            title: 'Company Number & Name',
            key: 'action',
            render: (_, record: CSFillingListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <a
                            href={`https://find-and-update.company-information.service.gov.uk/company/${record.companyRegNumber}/filing-history`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline' }}
                        >
                            {record.companyRegNumber}
                        </a>{' '}
                        - {record.companyName}
                    </Space>
                )
            },
        },
        {
            title: 'Submission Type',
            dataIndex: 'submissionType',
        },
        {
            title: 'Submission #',
            dataIndex: 'submissionNumber',
        },
        {
            title: 'Status',
            dataIndex: 'companyHouseStatus',
        },
        {
            title: 'Submission Date',
            dataIndex: 'submissionDate',
        },
        {
            title: 'Submitted By',
            dataIndex: 'submittedBy',
        },
    ]

    const onChangeTableParams: TableProps<CSFillingListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'string',
                searchOperator: 1,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const expandableData = (
        record: CSFillingListDataTypeInterface
    ): React.ReactElement => {
        return <CHFillingHistoryExpandView expandData={record.rejections} />
    }
    return (
        <>
            <div>
                <h3>CS Submission Status</h3>
            </div>
            <Card title="CS Submission Status" className={commonStyles.card}>
                <Row>
                    <Col span={12}>
                        {' '}
                        <SearchInput
                            placeHolder={'Search  by keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="500"
                        />
                    </Col>
                </Row>

                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={CSFillingList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): CSFillingListInterface => {
    return {
        CSFillingList: state.companyHouse.CSFillingList,
        CSFillingListCount: state.companyHouse.CSFillingListCount,
    }
}

export default connect(mapStateToProps)(CSFillingStatusList)
