import axios from '../Config/AxiosConfig'
import type { Dispatch } from '@reduxjs/toolkit'
import { ErrorMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import {
    BPOANDotherStaffProgressList,
    bpoTaskList,
    itdashboard,
    itprojectList,
    ItStaffProgressList,
    itTaskList,
    overalltaskListcoutdata,
} from '../Reducer/ITDashboardReducer'
// import { type ITProject } from '../Types/ITDashbord'
// import handleError from '../Config/AxiosResponceStatus'

export const GetITDashboard = async (
    dispatch: Dispatch,
    clientCategoryId: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    await axios
        .get(`/api/Dashboard/GetDashboardITDepartment/${clientCategoryId}/0`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(itdashboard(response.data.data))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetITProjectDetails = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ITDashboard/GetITClientProjectsList`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(itprojectList(response.data.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetITTaskDetails = (dispatch: Dispatch, flag: number): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ITDashboard/GetITMyTasksList/${flag}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(itTaskList(response.data.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetITStaffProgressDetails = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ITDashboard/GetITStaffProjectsList`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(ItStaffProgressList(response.data.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetITStaffOverallTaskCount = (
    dispatch: Dispatch,
    projectId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ITDashboard/GetITProjectTasksCount/${projectId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(overalltaskListcoutdata(response.data.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetBPOStaffProgressDetails = (
    dispatch: Dispatch,
    clientDynamicCategory: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/BPODashboard/GetBPOProjectTasksCount/${clientDynamicCategory}`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(BPOANDotherStaffProgressList(response.data.data))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCommonTaskDetails = async (
    dispatch: Dispatch,
    flegId: number,
    clientCategoryId: number,
    StatusLable: string
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    await axios
        .get(
            `/api/BPODashboard/GetBPOMyTasksList/${flegId}/${clientCategoryId}/${StatusLable}`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(bpoTaskList(response.data.data))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
