import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    LeaveFormDataInterface,
    LeaveFormInterface,
} from '../../../Types/LeaveType'
import { LeaveItemDefaultData } from './leaveList'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const LeaveForm = (props: LeaveFormInterface): React.ReactElement => {
    const { onSave, editData, isLoadingModal } = props
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(editData ?? LeaveItemDefaultData)
    }, [editData, form])

    const resetForm = (): void => {
        form.setFieldsValue(LeaveItemDefaultData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<LeaveFormDataInterface>
                    name="leaveTypeId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<LeaveFormDataInterface>
                            label="Leave Type"
                            name="leaveType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your leave type!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1005', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Tooltip title={'Save'}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={Boolean(isLoadingModal)}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

export default LeaveForm
