import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Select, Row, Card, List, Flex, Pagination } from 'antd'
import {
    type prpertyListDataTypeInterface,
    type propertyListInterface,
} from '../../../Types/MiisngInformation/PropertyView'
import {
    businesstypecatergoryList,
    GetMissedField,
} from '../../../Services/MissingInformation/PrpertyView'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import SearchInput from '../../../Components/SearchInput'
import commonStyles from '../../../Utils/Common.less'
import { ReactComponent as Corporate } from '../../../Assest/ClientTypeSvg/copo.svg'
import { ReactComponent as Induvidual } from '../../../Assest/ClientTypeSvg/indi-icon.svg'
import { ReactComponent as Soltrader } from '../../../Assest/ClientTypeSvg/sole-icon.svg'
import { ReactComponent as Partnership } from '../../../Assest/ClientTypeSvg/part-icon.svg'
import { ReactComponent as Others } from '../../../Assest/ClientTypeSvg/othe-icon.svg'
import styles from './ClientView.less'
import { CloseOutlined, MailOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { getClientCompanyDetails } from '../../../Services/Corporate'
import { GetSoleTraderList } from '../../../Services/SoleTrader'
import { GetIndividualList } from '../../../Services/Individual'
import { GetParnershipList } from '../../../Services/Parnership'
import FullWidthModal from '../../../Components/FullWidthModal'
import { SendMissingInformationEmail } from '../../../Services/Common'

const ClientViewMissingInfo = (
    props: propertyListInterface
): React.ReactElement => {
    const {
        businessstypedropdownlist,
        categoryDropDownList,
        MissinginformationprpertyviewList,
        MissinginformationprpertyviewListCount,
        individualList,
        soleTraderList,
        partnershipList,
    } = props

    const [catergoryid, setSelectedId] = useState(0)
    const [businesstypeid, setSelectedServiceId] = useState(0)
    const [searchString, setSearchString] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [missingInfoList, setMissingInfoList] = useState<
        prpertyListDataTypeInterface[]
    >([])
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [businessId, setBusinessId] = useState(0)
    const [referenceId, setReferenceId] = useState(0)
    useEffect(() => {
        const updateListData = MissinginformationprpertyviewList?.map(
            (item, index) => ({
                ...item,
                key: index,
            })
        )
        setMissingInfoList(updateListData)
    }, [MissinginformationprpertyviewList])

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        businesstypecatergoryList(dispatch)
        GetSoleTraderList(
            dispatch,
            {
                pageNo: 1,
                pageSize: 1000,
                searchCriteriaList: [],
                sortOrderList: [],
            },
            0,
            0
        )
        GetIndividualList(
            dispatch,
            {
                pageNo: 1,
                pageSize: 1000,
                searchCriteriaList: [],
                sortOrderList: [],
            },
            0,
            0
        )
        GetParnershipList(
            dispatch,
            {
                pageNo: 1,
                pageSize: 1000,
                searchCriteriaList: [],
                sortOrderList: [],
            },
            0,
            0
        )
    }, [])

    const getTableData = useCallback(() => {
        GetMissedField(
            dispatch,
            searchString,
            100,
            catergoryid,
            businesstypeid,
            currentPage,
            itemsPerPage
        )
    }, [searchString, catergoryid, businesstypeid, itemsPerPage, currentPage])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        if (data.length !== 0) {
            setSearchString(data)
        } else {
            setSearchString('=NA=')
        }
    }
    const handleSelectChange = (value: number): void => {
        setSelectedId(value)
    }

    const handleSelectChange1 = (value: number): void => {
        setSelectedServiceId(value)
    }

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const ViewCorporate = async (id: string): Promise<void> => {
        localStorage.removeItem('corporateData')
        localStorage.removeItem('onbordingId')
        const data = await getClientCompanyDetails(dispatch, 1, Number(id), 1)
        const corporateData = {
            clienttypeid: data?.data?.initialDetails?.clienttypeid ?? 1,
            clienttype: 'Corporate',
            corporateid: id,
            companyname: data?.data?.initialDetails.companyname,
            clientnumber: data?.data?.initialDetails.clientnumber,
            companyregisternumber: data?.data?.initialDetails.companyregnumber,
        }

        const onbordingId = 0
        localStorage.setItem('corporateData', JSON.stringify(corporateData))
        localStorage.setItem('onbordingId', onbordingId.toString())
        navigate(`/clients/registered-clients/corporate-view`)
    }

    const viewSoleTraderDetails = (id: string): void => {
        const SoleTraderData = soleTraderList.find(
            (item: { soleTraderId: string }) => item.soleTraderId === id
        )
        navigate(`/clients/registered-clients/soletrader-view`, {
            state: { SoleTraderData },
        })
    }
    const viewIndividualDetails = (id: string): void => {
        const individualData = individualList.find(
            (item: { individualId: string }) => item.individualId === id
        )

        const onbordingId = 0
        navigate(`/clients/registered-clients/individual-view`, {
            state: { individualData, onbordingId },
        })
    }

    const viewPartnerShipDetails = (id: string): void => {
        const partnershipData = partnershipList.find(
            (item) => item.partnershipId === id
        )

        const onbordingId = 0
        navigate(`/clients/registered-clients/partnership-view`, {
            state: { partnershipData, onbordingId },
        })
    }
    const NavigateClient = (path: string, id: string): void => {
        if (path === 'Corporate') {
            void ViewCorporate(id)
        } else if (path === 'Individual') {
            viewIndividualDetails(id)
        } else if (path === 'SoleTrader') {
            viewSoleTraderDetails(id)
        } else if (path === 'Partnership') {
            viewPartnerShipDetails(id)
        }
    }

    const image = (path: string): JSX.Element => {
        switch (path) {
            case 'Corporate':
                return <Corporate />
            case 'Individual':
                return <Induvidual />
            case 'SoleTrader':
                return <Soltrader />
            case 'Partnership':
                return <Partnership />
            default:
                return <Others />
        }
    }

    const handleRemove = (companyName: string): void => {
        const updatedList = missingInfoList.filter(
            (item) =>
                item.companyName.trim().toLowerCase() !==
                companyName.trim().toLowerCase()
        )
        setMissingInfoList(updatedList)
    }
    const openFullwidthModel = (
        businessId: number,
        referenceId: string
    ): void => {
        setReferenceId(Number(referenceId))
        setBusinessId(businessId)
        setDeleteModalStatus(!deleteModalStatus)
    }
    const closeFullwidthModel = (): void => {
        setDeleteModalStatus(!deleteModalStatus)
    }
    const SendEmailMissingInfo = (): void => {
        SendMissingInformationEmail(dispatch, businessId, referenceId)
        closeFullwidthModel()
    }

    return (
        <>
            <div>
                <h2>Missing Information</h2>
            </div>
            <Card title="Missing Information" className={commonStyles.card}>
                <Row gutter={24}>
                    <Flex justify="space-between">
                        <SearchInput
                            placeHolder={'Search  by keywords'}
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                        />
                        <div>
                            <Select
                                className={commonStyles.topdropdown2}
                                placeholder="Please select"
                                onChange={handleSelectChange}
                                value={catergoryid}
                                options={[
                                    { value: 0, label: 'All' },
                                    ...categoryDropDownList,
                                ]}
                            />

                            <Select
                                className={commonStyles.topdropdown}
                                placeholder="Please select"
                                onChange={handleSelectChange1}
                                value={businesstypeid}
                                options={[
                                    { value: 0, label: 'All' },
                                    ...businessstypedropdownlist,
                                ]}
                            />
                        </div>
                    </Flex>
                </Row>
                <Row gutter={24} style={{ marginTop: 30 }}>
                    <List
                        grid={{ gutter: 16, column: 2 }}
                        dataSource={missingInfoList}
                        style={{ width: '100%' }}
                        renderItem={(item, index) => (
                            <List.Item key={item.companyName}>
                                <Card key={index} style={{ height: 160 }}>
                                    <Flex gap={20}>
                                        <div>
                                            {image(item?.companyTypeName)}
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <Flex justify="space-between">
                                                <div
                                                    className={
                                                        styles.clintDropdownListDiv
                                                    }
                                                >
                                                    <span
                                                        onClick={() => {
                                                            NavigateClient(
                                                                item.companyTypeName,
                                                                item.referenceId
                                                            )
                                                        }}
                                                    >
                                                        {item.companyName}
                                                    </span>{' '}
                                                    &nbsp;&nbsp;
                                                    <MailOutlined
                                                        onClick={() => {
                                                            openFullwidthModel(
                                                                item.companyType,
                                                                item.referenceId
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <CloseOutlined
                                                    style={{
                                                        fontSize: '16px',
                                                        color: '#b0b1b1',
                                                    }}
                                                    onClick={() => {
                                                        handleRemove(
                                                            item.companyName
                                                        )
                                                    }}
                                                />
                                            </Flex>
                                            <div>
                                                <span
                                                    className={
                                                        styles.clientDropdownListSpan1
                                                    }
                                                >
                                                    {item.companyTypeName}
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={
                                                        styles.clientDropdownListSpan
                                                    }
                                                >
                                                    {item.description}
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={
                                                        styles.clientDropdownListSpan2
                                                    }
                                                >
                                                    {item.remarks}
                                                </span>
                                            </div>
                                        </div>
                                    </Flex>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Row>
                <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={MissinginformationprpertyviewListCount}
                    onChange={handlePageChange}
                    style={{ marginTop: '16px', textAlign: 'right' }}
                    align="end"
                />
            </Card>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={closeFullwidthModel}
                tittle="You are about to send this missing info. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={SendEmailMissingInfo}
            />
        </>
    )
}

const mapStateToProps = (state: any): propertyListInterface => {
    return {
        missingdroplist: state.missing.missingdroplist,
        businessstypedropdownlist: state.initial.businessstypedropdownlist,
        categoryDropDownList: state.initial.categoryDropDownList,
        MissinginformationprpertyviewList:
            state.missing.MissinginformationprpertyviewList,
        corporateList: state.client.corporateList,
        soleTraderList: state.client.soleTraderList,
        partnershipList: state.client.partnershipList,
        individualList: state.client.individualList,
        MissinginformationprpertyviewListCount:
            state.missing.MissinginformationprpertyviewListCount,
    }
}

export default connect(mapStateToProps)(ClientViewMissingInfo)
