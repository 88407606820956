import React from 'react'
import { Input, Form } from 'antd'
import styles from './Less/input.less'

const InputFieldEmail = (props: {
    name: string
    label: string
    placeHolder: string
    reuireMessage: string
    validationType?: 'text' | 'email'
}): React.ReactElement => {
    const { name, label, placeHolder, reuireMessage } = props

    const validationRules: any = [
        { required: true, message: reuireMessage },
        { type: 'email', message: 'The input is not a valid email!' },
        {
            // The regex below ensures that only emails ending with @iykons.com are accepted
            pattern: /^[a-zA-Z0-9._%+-]+@iykons\.com$/,
            message: 'The email is not a valid IYKONS email!',
        },
        {
            min: 12,
            message:
                'Email address seems too short. Please ensure it is complete.',
        },
    ]

    return (
        <Form.Item
            label={<label className={styles.inputLabel}>{label}</label>}
            name={name}
            rules={validationRules}
            validateTrigger="onSubmit"
        >
            <Input placeholder={placeHolder} />
        </Form.Item>
    )
}

export default InputFieldEmail
