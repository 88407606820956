import React from 'react'
import { Button, Input } from 'antd'
import styles from './Less/searchInput.less'
import { SearchOutlined } from '@ant-design/icons'
const { Search } = Input

interface SearchInputInterface {
    placeHolder?: string
    width?: string
    onSearch?: (data: any) => void
    onChange?: (data: any) => void
    height?: string
    onclick: () => void
}

const SearchInputText = (props: SearchInputInterface): React.ReactElement => {
    const {
        placeHolder = 'To filter the clients by number /name',
        onSearch,
        onChange,
        onclick,
    } = props

    const suffix = (
        <Button
            type="primary"
            className={styles.customSearchButton}
            style={{ background: '#1890ff' }}
            onClick={onclick}
        >
            <SearchOutlined />
            Search
        </Button>
    )
    return (
        <div className={styles.searchDiv}>
            <Search
                placeholder={placeHolder}
                onChange={onChange}
                onSearch={onSearch}
                style={{ width: '100%' }}
                enterButton={suffix}
                className={styles.searchStyles}
                size="large"
            />
        </div>
    )
}

export default SearchInputText
