import React, { type ReactElement } from 'react'
import { Form, Select, Input } from 'antd'
import { connect } from 'react-redux'
import {
    type initialDetailsInterface,
    type IndividualHomeFormDataInterface,
    type initialState,
} from '../../../../../../Types/Client/RegisteredClients/Individual'

const InitialDetailsForm = (props: initialDetailsInterface): ReactElement => {
    const { categoryDropDownList, onCategorySelect } = props

    return (
        <>
            <Form.Item<IndividualHomeFormDataInterface>
                name="individualId"
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                name="clientNumber"
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="Client Category"
                name="clientCategoryId"
            >
                <Select
                    allowClear
                    showSearch
                    onChange={(value) => {
                        onCategorySelect(value)
                    }}
                    filterOption={(input, option) =>
                        option?.label
                            ?.toLowerCase()
                            .includes(input.toLowerCase()) ?? false
                    }
                    options={categoryDropDownList}
                />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                name="title"
                label="Title"
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="First Name"
                name="firstName"
                rules={[
                    {
                        required: true,
                        message: 'Please input your First Name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="Middle Name"
                name="middleName"
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="Surname"
                name="surName"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Sure Name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="Type Of SelfEmployement"
                name="typeOfSelfEmployment"
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="TradingName"
                name="tradingName"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Trading Name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item<IndividualHomeFormDataInterface>
                label="Individual UTR"
                name="individualUTR"
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): initialState => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
    }
}

export default connect(mapStateToProps)(InitialDetailsForm)
