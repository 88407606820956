import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import { connect } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    OtherPersonFormDataInterface,
    OtherPersonFormInterface,
    OtherPersonFormStateInterface,
} from '../../../Types/OtherPerson'
import { OtherPersonItemDefaultData } from './otherPersonList'
import type { ListFrontEndInterface } from '../../../Types/CommonType'

const OtherPersonForm = (
    props: OtherPersonFormInterface
): React.ReactElement => {
    const { onSave, editData, categoryDropDownList } = props
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldValue('otherPersonId', '0')
    }, [])

    useEffect(() => {
        const clientCategoryData = categoryDropDownList?.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.clientCategoryName
        )
        const newData = {
            ...editData,
            clientCategoryId: clientCategoryData?.value ?? null,
        }
        form.setFieldsValue(newData)
    }, [editData])

    const resetForm = (): void => {
        form.setFieldsValue({
            OtherPersonItemDefaultData,
            clientCategoryId: undefined,
            contactName: undefined,
            contactNumber: undefined,
            emailAddress: undefined,
        })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<OtherPersonFormDataInterface>
                    name="otherPersonId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<OtherPersonFormDataInterface>
                            label="Company Group"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the company group!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Please select"
                                allowClear
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...categoryDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<OtherPersonFormDataInterface>
                            label="Contact Name"
                            name="contactName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<OtherPersonFormDataInterface>
                            label="Contact Number"
                            name="contactNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message: 'please enter valid mobile number',
                                },
                                {
                                    required: true,
                                    message:
                                        'Please input your Contact number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<OtherPersonFormDataInterface>
                            label="Contact Email"
                            name="emailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): OtherPersonFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
    }
}

export default connect(mapStateToProps)(OtherPersonForm)
