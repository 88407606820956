import { configureStore } from '@reduxjs/toolkit'
import InitialReducer from '../Reducer/InitialReducer'
import AuthReducer from '../Reducer/AuthReducer'
import CommonReducer from '../Reducer/Common'
import UserReducer from '../Reducer/User'
import ClientReducer from '../Reducer/Client'
import UserRightsReducer from '../Reducer/UserRightsReducer'
import Invoice from '../Reducer/Invoice'
import HelpDesk from '../Reducer/HelpDesk'
import DashboardReducer from '../Reducer/DashboardReducer'
import ClientOverviewReducer from '../Reducer/ClientOverviewReducer'
import DashboardFlagReducer from '../Reducer/DashboardFlagReducer'
import MiisingInformationReducer from '../Reducer/MiisingInformation'
import BusinessSetup from '../Reducer/ServiceSetup'
import profileUser from '../Reducer/userProfileReducer'
import NotificationReducer from '../Reducer/NotificationReducer'
import StaffReport from '../Reducer/StaffReport'
import BacklogReducer from '../Reducer/Backlog'
import PortfolioReducer from '../Reducer/Portfolio'
import HrReducer from '../Reducer/Hr'
import GlobelReducer from '../Reducer/GlobelSearchClient'
import MobileRequest from '../Reducer/MoblieRequest'
import WikiPage from '../Reducer/Wiki'
import Reports from '../Reducer/Reports'
import CompanyHouseReducer from '../Reducer/CompanyHouseReducer'
const store = configureStore({
    reducer: {
        initial: InitialReducer,
        auth: AuthReducer,
        common: CommonReducer,
        user: UserReducer,
        client: ClientReducer,
        userrights: UserRightsReducer,
        invoice: Invoice,
        helpdesk: HelpDesk,
        dashboardInfo: DashboardReducer,
        clientOverviewInfo: ClientOverviewReducer,
        dashboardFlag: DashboardFlagReducer,
        missing: MiisingInformationReducer,
        profile: profileUser,
        notification: NotificationReducer,
        serviceSetup: BusinessSetup,
        staffReport: StaffReport,
        portfolio: PortfolioReducer,
        hr: HrReducer,
        backlog: BacklogReducer,
        golobelSearch: GlobelReducer,
        mobileRequest: MobileRequest,
        wiki: WikiPage,
        reports: Reports,
        companyHouse: CompanyHouseReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>

export default store
