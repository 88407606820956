// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected_syXco {
  background-color: blue;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Less/SelectiveButton.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;AACJ","sourcesContent":[".selected {\n    background-color: blue;\n    color: white;\n}\n\n@primary-color: #2FB153;@link-color: #1DA57A;@border-radius-base: 2px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": `selected_syXco`
};
export default ___CSS_LOADER_EXPORT___;
