import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Typography,
    Divider,
    List,
    Segmented,
    Flex,
    Pagination,
    Spin,
    Empty,
    Tooltip,
    Modal,
    Drawer,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './Itindex.less'
import type {
    GetITTofollowProps,
    GetITTofollowState,
} from '../../../Types/Dashboard'
import axios from '../../../Config/AxiosConfig'
import { ErrorMessage } from '../../../Utils/Notification'
import { useNavigate } from 'react-router-dom'
import { randomColor } from '../../../Utils/randomColorGen'
import {
    type Task,
    type setTasks,
    type TaskData,
} from '../../../Types/HelpDesk/Cretetask'
import {
    FormOutlined,
    UsergroupDeleteOutlined,
    FieldTimeOutlined,
    UnorderedListOutlined,
    PaperClipOutlined,
    CommentOutlined,
} from '@ant-design/icons'
import CreateTaskForm from '../../HelpDesk/createTask/CreateTaskForm'
import {
    TaskdefaultData,
    TaskItemDefaultData,
} from '../../HelpDesk/createTask/toDoTasks'
import { GetSatusList, GetTaskData } from '../../../Services/Helpdesk/CreteTask'
import { connect, useDispatch } from 'react-redux'
import CreateattachmentModal from '../../HelpDesk/createTask/Attachment'
import CreateFollowersModal from '../../HelpDesk/createTask/CreateFoolwers'
import CreatesubtaskModal from '../../HelpDesk/createTask/CreteSubtask'
import CreateHours from '../../HelpDesk/createTask/CreateHours'
import ChatClient from '../../HelpDesk/createTask/chatClient'

const { Text } = Typography

interface HeaderDataType {
    label: string
    color: string
    count: number
    stdId: number
}

interface StandardType {
    projectId: number
    projectName: string
}

const Todo = (props: GetITTofollowProps): React.ReactElement => {
    const { projectInfo, ProjectManagmentStausList } = props
    const [data, setData] = useState<TaskData[]>([])
    const [headerData, setHeaderData] = useState<HeaderDataType[]>([])
    const [selectedTab, setTab] = useState<number>(0)
    const [flag, setFlag] = useState(0)
    const [stdId, setStdId] = useState(0)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [noOfRecords, setNoOfRecords] = useState(0)
    const navigate = useNavigate()
    const [editModelvisibel, setEditModelvisibel] = useState(false)
    const [editData, setEditData] = useState<setTasks>(TaskdefaultData)
    const [attachmentTaskId, setAttachmentTaskId] = useState('')
    const [attachmentModel, setAttachmentModelvisibel] = useState(false)
    const [modalVisibleCreateFollowers, setModalVisibleCreateFollowers] =
        useState(false)
    const [modalVisibleCreateSubTask, setModalVisibleCreateSubTask] =
        useState(false)
    const [selectedUsers, setSelectedUsers] = useState<number[]>([])
    const [getTask, setTask] = useState<Task>(TaskItemDefaultData)
    const [modalVisibleCreateHours, setModalVisibleCreateHours] =
        useState(false)
    const [modalVisibleCreateChat, setModalVisibleCreateChat] = useState(false)
    const [referenceId, setRefernceId] = useState(0)
    const [businessId, setBusinessId] = useState(0)
    const dispatch = useDispatch()
    const hasFetchedOnce = useRef(false)

    const onChangeTab = (index: number): void => {
        setTab(index)
        setFlag(index)
    }

    const getStandardListing = async (): Promise<void> => {
        if (projectInfo !== undefined) {
            const newData = projectInfo?.map((item: StandardType) => {
                return {
                    label: item.projectName,
                    color: randomColor(),
                    count: 0,
                    stdId: item?.projectId,
                }
            })

            setStdId(newData[0]?.stdId)
            setHeaderData(newData)
        }
    }
    useEffect(() => {
        GetSatusList(dispatch, stdId?.toString(), {
            pageNo: 1,
            pageSize: 10,
            searchCriteriaList: [],
            sortOrderList: [],
        })
    }, [stdId])
    const assignTo = 3

    useEffect(() => {
        void getStandardListing()
    }, [projectInfo])
    // `/api/Task/GetTaskList/${flagId}/${searchString}/${pageNo}/${pageSize}/${assignedBy}/${assignedTo}`
    const setTabData = async (): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/Task/GetTaskList/${selectedTab}/=NA=/1/10/0/${assignTo}`)
            .then((response: any) => {
                if (response.status === 200) {
                    setNoOfRecords(response?.data?.noOfRecords)
                    const newData: TaskData[] = response?.data?.data
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        setPageNumber(1)

        void setTabData()
    }, [flag, stdId])

    useEffect(() => {
        if (pageNumber > 1) {
            void setTabData()
        }
    }, [pageNumber])

    const handleModalClose = (): void => {
        setEditModelvisibel(!editModelvisibel)
    }

    const onSaveTask = (callBack: CallableFunction): void => {
        handleModalClose()
        callBack()
    }

    const openEditModal = (id: string): void => {
        setTimeout(() => {
            setEditModelvisibel(!editModelvisibel)
        }, 1000)

        setAttachmentTaskId(id)
        void openEditData(id)
    }

    const openEditData = async (id: string): Promise<void> => {
        try {
            const data = await GetTaskData(dispatch, id)
            setEditData(data ?? TaskdefaultData)
        } catch (err) {}
    }

    const handleAttachmentModalClose = (): void => {
        setAttachmentModelvisibel(!attachmentModel)
    }
    const onAttachment = (id: string): void => {
        setAttachmentTaskId(id)
        setAttachmentModelvisibel(!attachmentModel)
    }
    const handleModalOpenCreateFollowers = (taskId: string): void => {
        setModalVisibleCreateFollowers(true)
        setAttachmentTaskId(taskId)
    }
    const closeAttachment = (): void => {
        setModalVisibleCreateFollowers(false)
    }

    const handleModalOpenSubTasks = (task: Task): void => {
        setModalVisibleCreateSubTask(true)
        setAttachmentTaskId(task.taskId)
        setTask(task)
    }
    const closeSubtaskModel = (): void => {
        setModalVisibleCreateSubTask(true)
    }

    const handleModalOpenCreateHours = (taskId: string): void => {
        setModalVisibleCreateHours(true)
        setAttachmentTaskId(taskId)
    }
    const closeAddHoursModel = (): void => {
        setModalVisibleCreateHours(false)
    }

    const onChat = (
        taskId: string,
        referenceId: number,
        businessId: number
    ): void => {
        setRefernceId(referenceId)
        setBusinessId(businessId)
        setModalVisibleCreateChat(true)
        setAttachmentTaskId(taskId)
    }
    const closeChatmodel = (): void => {
        setModalVisibleCreateChat(false)
    }
    const options = [
        ...headerData.map((value: HeaderDataType) => {
            return {
                value: value.stdId,
                icon: (
                    <Flex
                        vertical
                        align="center"
                        justify="center"
                        style={{ padding: '8px 20px', userSelect: 'none' }}
                    >
                        <Flex justify="center" align="center" gap={4}>
                            <div
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '999px',
                                    backgroundColor: value.color,
                                }}
                            ></div>
                            <Text>{value.label}</Text>
                        </Flex>
                    </Flex>
                ),
            }
        }),
    ]

    return (
        <div className={styles.marginRight10}>
            <Card className={`${styles.card}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <div className={styles.mobileRequest__tittle}>
                                    To Do
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.todo__tab__one}>
                        <Segmented
                            style={{
                                overflowX: 'scroll',
                                whiteSpace: 'nowrap',
                                scrollbarWidth: 'none' /* For Firefox */,
                                msOverflowStyle:
                                    'none' /* For Internet Explorer and Edge */,
                                width: '100%',
                            }}
                            options={options}
                            value={stdId}
                            onChange={(value) => {
                                setStdId(Number(value))
                            }}
                        />
                    </Row>
                    <Divider style={{ margin: '10px 0px' }} />
                    <Row>
                        {/* <Col
                            span={6}
                            onClick={() => {
                                onChangeTab(0)
                            }}
                        >
                            <div
                                className={
                                    selectedTab === 0
                                        ? styles.todo__tab__two__main__active
                                        : styles.todo__tab__two__main__inactive
                                }
                            >
                                To-Do
                            </div>
                        </Col>
                        <Col
                            span={6}
                            onClick={() => {
                                onChangeTab(1)
                            }}
                        >
                            <div
                                className={
                                    selectedTab === 1
                                        ? styles.todo__tab__two__main__active
                                        : styles.todo__tab__two__main__inactive
                                }
                            >
                                In-progress
                            </div>
                        </Col>
                        <Col
                            span={6}
                            onClick={() => {
                                onChangeTab(2)
                            }}
                        >
                            <div
                                className={
                                    selectedTab === 2
                                        ? styles.todo__tab__two__main__active
                                        : styles.todo__tab__two__main__inactive
                                }
                            >
                                Completed
                            </div>
                        </Col> */}
                        {ProjectManagmentStausList?.map((status, index) => (
                            <Col
                                key={status.projectStatusId}
                                span={6}
                                onClick={() => {
                                    onChangeTab(Number(status.projectStatusId))
                                }}
                            >
                                <div
                                    className={
                                        selectedTab ===
                                        Number(status.projectStatusId)
                                            ? styles.todo__tab__two__main__active
                                            : styles.todo__tab__two__main__inactive
                                    }
                                >
                                    {status.projectStatusName}
                                </div>
                            </Col>
                        ))}
                        <Col span={6}>
                            <div
                                className={styles.todo__tab__two__main__right}
                                onClick={() => {
                                    const mode = 1
                                    navigate(`/thingto-do`, {
                                        state: { mode },
                                    })
                                }}
                            >
                                View All
                            </div>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <Flex
                            align="center"
                            justify="center"
                            style={{ padding: '20px 0px' }}
                        >
                            <Spin />
                        </Flex>
                    ) : (
                        <>
                            {data.length > 0 ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <List>
                                                <VirtualList
                                                    data={data}
                                                    height={600}
                                                    itemKey="serviceProcessId"
                                                    className={
                                                        styles.todo__list__main
                                                    }
                                                >
                                                    {(
                                                        item: TaskData,
                                                        index: number
                                                    ) => (
                                                        <List.Item key={index}>
                                                            <Row
                                                                className={
                                                                    styles.todo__list__main__item__main
                                                                }
                                                            >
                                                                <Col span={19}>
                                                                    <div
                                                                        className={
                                                                            styles.todo__list__main__item__main__tittle
                                                                        }
                                                                    >
                                                                        {
                                                                            item.taskName
                                                                        }
                                                                        &nbsp;&nbsp;
                                                                        {/* <ItemLinkIcon /> */}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.todo__list__second__item__tittle
                                                                        }
                                                                    >
                                                                        {
                                                                            item.clientName
                                                                        }
                                                                    </div>
                                                                    <Row>
                                                                        <Col
                                                                            span={
                                                                                6
                                                                            }
                                                                            className={
                                                                                styles.todo__list__third__item__first__tittle
                                                                            }
                                                                        >
                                                                            Assign
                                                                            To:{' '}
                                                                            {
                                                                                item.assignedToName
                                                                            }{' '}
                                                                            <br />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col
                                                                    span={5}
                                                                    className={
                                                                        styles.todo__last__item__main
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.todo__last__item__icons
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        <Tooltip
                                                                            title={
                                                                                'Edit Task'
                                                                            }
                                                                        >
                                                                            <FormOutlined
                                                                                onClick={() => {
                                                                                    openEditModal(
                                                                                        item.taskId
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Add Followers'
                                                                            }
                                                                        >
                                                                            &nbsp;&nbsp;
                                                                            <UsergroupDeleteOutlined
                                                                                onClick={() => {
                                                                                    handleModalOpenCreateFollowers(
                                                                                        item.taskId
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Add Hours'
                                                                            }
                                                                        >
                                                                            <FieldTimeOutlined
                                                                                onClick={() => {
                                                                                    handleModalOpenCreateHours(
                                                                                        item.taskId
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Staff Communication'
                                                                            }
                                                                        >
                                                                            <CommentOutlined
                                                                                onClick={() => {
                                                                                    onChat(
                                                                                        item.taskId,
                                                                                        item.referenceId,
                                                                                        item.businessTypeId
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip title="Add Attachment">
                                                                            <PaperClipOutlined
                                                                                onClick={() => {
                                                                                    onAttachment(
                                                                                        item.taskId
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Add SubTask'
                                                                            }
                                                                        >
                                                                            <UnorderedListOutlined
                                                                                onClick={() => {
                                                                                    handleModalOpenSubTasks(
                                                                                        item
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </List.Item>
                                                    )}
                                                </VirtualList>
                                            </List>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            width: '100%',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Col
                                            span={24}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'stretch',
                                            }}
                                        >
                                            <Pagination
                                                defaultCurrent={pageNumber}
                                                total={noOfRecords}
                                                onChange={(e) => {
                                                    setPageNumber(e)
                                                }}
                                                showSizeChanger={false}
                                                showQuickJumper={false}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Empty
                                    style={{ padding: '40px 0px' }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>No tasks to complete.</span>
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            </Card>

            <Modal
                title={'Edit Task'}
                open={editModelvisibel}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateTaskForm
                    taskId={attachmentTaskId?.toString()}
                    onSave={onSaveTask}
                    editData={editData}
                />
            </Modal>
            <Modal
                title={'Add Attachment'}
                open={attachmentModel}
                onCancel={handleAttachmentModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateattachmentModal taskId={attachmentTaskId} />
            </Modal>
            <Modal
                title={'Add Followers'}
                open={modalVisibleCreateFollowers}
                onCancel={closeAttachment}
                footer={null}
                width={'50%'}
            >
                <CreateFollowersModal
                    onSave={onSaveTask}
                    taskid={attachmentTaskId?.toString()}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                />
            </Modal>
            <Modal
                title={'Add SubTask'}
                open={modalVisibleCreateSubTask}
                onCancel={closeSubtaskModel}
                footer={null}
                width={'50%'}
            >
                <CreatesubtaskModal
                    taskId={attachmentTaskId}
                    getTask={getTask}
                />
            </Modal>
            <Modal
                title={'Add Hours'}
                open={modalVisibleCreateHours}
                onCancel={closeAddHoursModel}
                footer={null}
                width={'50%'}
            >
                <CreateHours taskid={attachmentTaskId} />
            </Modal>
            <Drawer
                title={
                    <span style={{ color: 'white' }}>Staff Communication</span>
                }
                open={modalVisibleCreateChat}
                onClose={closeChatmodel}
                footer={null}
                width={550}
                styles={{
                    header: {
                        backgroundColor: '#085394',
                        padding: '20px',
                        marginBottom: '0',
                        color: 'white',
                    },
                    content: { padding: '0' },
                    body: { backgroundColor: 'yello' },
                }}
            >
                <ChatClient
                    taskId={Number(attachmentTaskId)}
                    messageTypeId={2}
                    referenceId={referenceId}
                    businessId={businessId}
                />
            </Drawer>
        </div>
    )
}

const mapStateToProps = (state: any): GetITTofollowState => {
    return {
        ProjectManagmentStausList: state.initial.ProjectManagmentStausList,
    }
}

export default connect(mapStateToProps)(Todo)
