import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Row, Col, Button, Form, Checkbox, Input, Tooltip } from 'antd'
import { SaveOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import AddNewButton from '../../../../Components/AddNewButton'
import commonStyles from '../../../../Utils/Common.less'
import { type AttachmentDropDownListInterface } from '../../../../Types/ProjectMangement'
import {
    Addattach,
    GetServiceSDropDownList,
} from '../../../../Services/ProjectManagement'
import S3UploadMultiple from '../../../../Components/S3UploadMultiple'
import { ReactComponent as PDFImage } from '../../../../Assest/Svg/pdfIm.svg'

export const AttachementDefaultData = {
    projectAttachmentId: 0,
    projectId: '',
    attachmentFile: '',
    description: '',
    canAccessByTaskLevel: 0,
    canAccessBySubTaskLevel: 0,
}

const AttachementList = (
    props: AttachmentDropDownListInterface & { projectId: string }
): React.ReactElement => {
    const { projectId, AttachmentDropDownList } = props

    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [editMode, setEditMode] = useState(false)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldsValue({
            attachmentFile: '',
            description: '',
            canAccessByTaskLevel: 0,
            canAccessBySubTaskLevel: 0,
        })
    }, [projectId, dispatch])

    const getTableData = useCallback(() => {
        void GetServiceSDropDownList(projectId, dispatch)
    }, [projectId, dispatch])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    function getValidBoolean(input: unknown): number {
        return input === true ? 1 : 0
    }

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
        form.setFieldValue(name, locations)
    }

    const handleSave = (): void => {
        form.validateFields()
            .then((values) => {
                const formattedValuesArray = uploadFileList.map((file) => ({
                    ...values,
                    projectId: props.projectId,
                    attachmentFile: file,
                    canAccessByTaskLevel: getValidBoolean(
                        values.canAccessByTaskLevel
                    ),
                    canAccessBySubTaskLevel: getValidBoolean(
                        values.canAccessBySubTaskLevel
                    ),
                }))

                Promise.all(
                    formattedValuesArray.map(
                        async (formattedValues) =>
                            await Addattach(dispatch, formattedValues)
                    )
                )
                    .then(() => {
                        setEditMode(false)
                        getTableData()
                    })
                    .catch((error) => {
                        console.error('Failed to save attachments:', error)
                    })
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo)
            })
    }

    const toggleEditMode = (): void => {
        setEditMode(!editMode)
        form.resetFields()
    }

    const handleDownload = (fileUrl: any): void => {
        window.open(fileUrl, '_blank')
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const renderAttachment = (attachment: string): JSX.Element => {
        const isPdf = attachment.toLowerCase().endsWith('.pdf')

        if (isPdf) {
            return (
                <div style={{ textAlign: 'center', padding: '20px 0' }}>
                    <PDFImage
                        style={{
                            width: '50px',
                            height: '50px',
                            color: '#ff4d4f',
                        }}
                    />
                </div>
            )
        } else {
            return (
                <img
                    alt="attachment"
                    src={attachment}
                    style={{
                        width: '100%',
                        maxHeight: '200px',
                        height: 'auto',
                        objectFit: 'cover',
                    }}
                />
            )
        }
    }

    return (
        <>
            <Card
                title="Project Management"
                extra={<AddNewButton clickAction={toggleEditMode} />}
                className={commonStyles.card}
            >
                {editMode ? (
                    <div className={commonStyles.formWrapper}>
                        <Form
                            form={form}
                            onFinish={handleSave}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            requiredMark={customizeRequiredMark}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Attachment File"
                                        name="attachmentFile"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your attachmentFile!',
                                            },
                                        ]}
                                    >
                                        <S3UploadMultiple
                                            name={'attachmentFile'}
                                            value={uploadFileList}
                                            setFormData={handleSetFormData}
                                            corporate={''}
                                            corporateid={0}
                                            documenttype={''}
                                            documentbaseId={0}
                                            memberpersonId={0}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your Description!',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    <Form.Item
                                        name="canAccessByTaskLevel"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            Can Access By Task Level
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        name="canAccessBySubTaskLevel"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            Can Access By SubTask Level
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col offset={22} span={2}>
                                    <Button type="primary" htmlType="submit">
                                        <Tooltip title="Save Attachment">
                                            <SaveOutlined />
                                        </Tooltip>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                ) : (
                    <Row>
                        {AttachmentDropDownList.map((attachment) => (
                            <Col
                                key={attachment.projectAttachmentId}
                                style={{ marginRight: '10px' }}
                            >
                                <Card
                                    hoverable
                                    className={commonStyles.cardMargin}
                                    style={{ width: 300, height: 250 }}
                                    cover={renderAttachment(
                                        attachment.attachmentFile
                                    )}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        {attachment.description}

                                        <Button
                                            type="link"
                                            icon={<CloudDownloadOutlined />}
                                            onClick={() => {
                                                handleDownload(
                                                    attachment.attachmentFile
                                                )
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): AttachmentDropDownListInterface => {
    return {
        AttachmentDropDownList: state.initial.AttachmentDropDownList,
        AttachmentDropDownListCount: state.initial.AttachmentDropDownListCount,
    }
}

export default connect(mapStateToProps)(AttachementList)
