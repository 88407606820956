import React, { useState } from 'react'
import { Row, Col, Button, Card } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './Itindex.less'
import type { HeadlineProps } from '../../../Types/Dashboard'
import Link from 'antd/es/typography/Link'

const HeadLines = ({
    greetingText,
    backgroundImageUrl,
    dashboardNewsInfo,
}: HeadlineProps): React.ReactElement => {
    // const user = localStorage.getItem('user')
    // const name = user !== null ? JSON.parse(user)?.userName : ''
    const [headlineNumber, SetHeadlineNumber] = useState(0)
    const onChangeHeadLine = (number: number): void => {
        SetHeadlineNumber(number)
    }

    return (
        <Card style={{ width: '100%' }}>
            <Row justify="center" align="middle">
                <Col
                    span={10}
                    className={styles.headLines__left}
                    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                >
                    <Row>
                        <Col span={20}>
                            <div className={styles.headLines__left__textDivOne}>
                                <p
                                    className={
                                        styles.headLines__left__textDivOne_text
                                    }
                                >
                                    Welcome to IYKONECT 3.0!
                                    {/* <span
                                        className={
                                            styles.headLines__left__textDivOne__text__name
                                        }
                                    >
                                        {name}
                                    </span>
                                    , to Our Community! Join Us for an Amazing
                                    Journey. */}
                                </p>
                            </div>
                            <div className={styles.headLines__left__textDivTwo}>
                                <p
                                    className={
                                        styles.headLines__left__textDivTwo_text
                                    }
                                >
                                    {greetingText ?? ''}
                                </p>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div
                                className={
                                    styles.headLines__left__textDiv__backgroundImage
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={14}>
                    <Row className={styles.headLines__right__row}>
                        <p className={styles.headLines__right__row__headline}>
                            {/* Latest Headlines */}
                        </p>
                        <div
                            className={
                                styles.headLines__right__row__headline__arrow
                            }
                        >
                            <Button
                                onClick={() => {
                                    onChangeHeadLine(headlineNumber - 1)
                                }}
                                disabled={headlineNumber === 0}
                                className={
                                    styles.headLines__right__row__headline__arrow__button
                                }
                            >
                                <LeftOutlined />
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                onClick={() => {
                                    onChangeHeadLine(headlineNumber + 1)
                                }}
                                disabled={
                                    dashboardNewsInfo?.length - 1 ===
                                    headlineNumber
                                }
                                className={
                                    styles.headLines__right__row__headline__arrow__button
                                }
                            >
                                <RightOutlined />
                            </Button>
                        </div>
                    </Row>
                    <Row className={styles.headLines__right__row}>
                        <p className={styles.headLines__right__row__tittle}>
                            {dashboardNewsInfo[headlineNumber]?.title}
                        </p>
                        <p
                            className={
                                styles.headLines__right__row__description
                            }
                        >
                            {dashboardNewsInfo[headlineNumber]?.description
                                .length > 150 ? (
                                <>
                                    <span>
                                        {dashboardNewsInfo[
                                            headlineNumber
                                        ]?.description.slice(0, 151) + '...'}
                                    </span>
                                    <Link
                                        href={
                                            dashboardNewsInfo[headlineNumber]
                                                ?.newsURL
                                        }
                                        target="_blank"
                                    >
                                        Read more
                                    </Link>
                                </>
                            ) : (
                                <span>
                                    {
                                        dashboardNewsInfo[headlineNumber]
                                            ?.description
                                    }
                                </span>
                            )}
                        </p>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default HeadLines
